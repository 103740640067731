import { getMappedComponent, Constants, AEMPage, Utils } from '@custom/aem-vue-editable-components';
import { PathUtils } from '@adobe/aem-spa-page-model-manager';

import TEMPLATE_NAME_TO_LAYOUT_MAP from '~/components-mapping/mapping-data-layout.js';
const TEMPLATE_NAME_ERROR_404 = 'spa-404-page-template';

// const rootPagePath = PathUtils.getMetaPropertyValue('cq:pagemodel_root_url').replace(/\.model\.json$/, '');

const generateRoutes = ({ cqChildren, rootPath, currentPath, pageModel }) => {
	if (!cqChildren) return [];
	
	const routes = (
		Object
			.entries(cqChildren)
			.map(([path, contentObj]) => {
				const itemProps = Utils.modelToProps(contentObj);
				const ItemComponent = getMappedComponent(itemProps.cqType);
				
				let pathRelativeToRoot = itemProps.cqPath.replace(new RegExp(`^${rootPath}/`, 'i'), '');
				let routeName = pathRelativeToRoot;
				
				// console.log('path = ', path);
				// console.log('itemProps = ', itemProps);
				// console.log('itemProps.cqType = ', itemProps.cqType);
				// console.log('contentObj = ', contentObj);
				// console.log('pathRelativeToRoot = ', pathRelativeToRoot);
				// console.log('ItemComponent = ', ItemComponent);

				if (!ItemComponent) return null;

				// For SPA -> Use the below code for defining 'routePath'
				// before we removed the 'AppRoot' component, path used to be nested under 'root', hence we use relative 'children' path instead
				// let routePath = `/:pathMatch(.*)*/:pageName(${pathRelativeToRoot}\\.html(?!.*\\/\\).*)` || '';
				
				// For MPA -> Use the below code for defining 'routePath'
				let routePath = window.location.pathname;

				let metaLayout = TEMPLATE_NAME_TO_LAYOUT_MAP[contentObj.templateName] || null;
				// debugger;

				if (!metaLayout) {
					console.warn(`Unknown templateName: "${contentObj.templateName}". Using the default template instead.`);
					metaLayout = TEMPLATE_NAME_TO_LAYOUT_MAP['spa-content-page-template'];
				}
				
				if (contentObj.templateName === TEMPLATE_NAME_ERROR_404) {
					// This is the 404 page, change its route to "catch-all" route
					routePath = '/:pathMatch(.*)*';
				}

				// console.log('routePath = ', routePath);
				// console.log('metaLayout = ', metaLayout);
				// console.log('----------');

				return {
					name: routeName,
					path: routePath,
					component: ItemComponent,
					props: {
						...itemProps,
						injectPropsOnInit: false,
						key: pathRelativeToRoot,
					},
					meta: {
						layout: metaLayout,
						patchCleanPath: true,
					},
				};
			})
			.filter(Boolean)
	);
	
	return routes;
};


export {
	generateRoutes,
};
