import { unrefElement, useActiveElement, computedAsync } from '@vueuse/core';
import { sleep } from '~/logic/helpers/utils.js';

/**
 * Track if focus is contained within the target element
 * NOTE: This implementation is slightly different from vueuse's, that why it is custom.
 * Using computedAsync because the browser switches document.activeElement to <body> breifly when changing focus.
 * 
 * @param target The target element to track
 * @param options Focus within options
 */
export function useFocusWithin (target, options = {}) {
	const activeElement = useActiveElement(options);
	const targetElement = computed(() => unrefElement(target));
	
	const focused = computedAsync(
		async () => {
			// eslint-disable-next-line
			targetElement.value;
			// eslint-disable-next-line
			activeElement.value;
			await sleep(15);
			return targetElement.value && activeElement.value ? targetElement.value.contains(activeElement.value) : false;
		},
		false,
	);
	
	return { focused, activeElement };
}
