<script>
export default {
	name: 'HomepageLayout',
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';
import { gsap } from 'gsap';
import { useEventBus } from '@vueuse/core';

import { overwriteMappingWithSyncComponent } from '~/components-mapping/mapping-logic.js';

// since Banner is so common, we directly overwrite the async mapping to sync
import Carousel from '~/aem-components/Carousel.vue';
// import Banner from '~/aem-components/Banner.vue';

// -- booking widget & its sub-components --
import BookingWidget from '~/aem-components/booking/BookingWidget.vue';
import CheckIn from '~/aem-components/booking/CheckIn.vue';
import FlightHotelSearch from '~/aem-components/booking/FlightHotelSearch.vue';
import FlightSchedule from '~/aem-components/booking/FlightSchedule.vue';
import FlightSearch from '~/aem-components/booking/FlightSearch.vue';
import FlightStatus from '~/aem-components/booking/FlightStatus.vue';
import ManageBooking from '~/aem-components/booking/ManageBooking.vue';
import { waitFor } from '~/logic/helpers/utils.js';


// TODO: The follow statement works, but due to "Layout" is not async, all of the below get included in the final bundle, which is not what we want. Disabling below statements temporary until the Layout is async.
// overwriteMappingWithSyncComponent('mh/components/carousel', Carousel);
// overwriteMappingWithSyncComponent('mh/components/banner', Banner);
// overwriteMappingWithSyncComponent('mh/components/booking/booking-widget', BookingWidget);
// overwriteMappingWithSyncComponent('mh/components/booking/check-in', CheckIn);
// overwriteMappingWithSyncComponent('mh/components/booking/flight-hotel-search', FlightHotelSearch);
// overwriteMappingWithSyncComponent('mh/components/booking/flight-schedule', FlightSchedule);
// overwriteMappingWithSyncComponent('mh/components/booking/flight-search', FlightSearch);
// overwriteMappingWithSyncComponent('mh/components/booking/flight-status', FlightStatus);
// overwriteMappingWithSyncComponent('mh/components/booking/manage-booking', ManageBooking);



const rootEl = ref(null);


const fadeOutAnimationWrapper = async () => {
	const animationWrapper = document.querySelector('#homepage-animation');
	// const animationContent = document.querySelector('#homepage-animation .animation-content');
	
	if (!animationWrapper) return;
	
	document.querySelector('#homepage-animation .animation-graphic')?.classList.add('scale-fade-out');
	// gsap.to(animationContent, { duration: 0.5, scale: 0.25});
	await gsap.to(animationWrapper, { duration: 0.5, delay: 0.2, opacity: 0, display: 'none' }).then();
	
	animationWrapper.setAttribute('hidden', true);
};

const { isEditorMode } = useGlobalAEMState();


const updateTranslateY = (flag) => {
	// flag = 'hide' | 'show'
	
	// const spacerMobileDlApp = document.querySelector('.sticky-spacer.h-\\$mobileDlAppNotificationHeight');
	// const spacerHeaderNoti = document.querySelector('.sticky-spacer.h-\\$headerNotificationHeight');
	
	if (flag === 'show') {
		document.documentElement.style.setProperty('--pinnedElementTransitionDuration', '0.3s');
		document.documentElement.style.setProperty('--headerTranslateY', '0%');
		document.documentElement.style.setProperty('--headerNotificationTranslateY', '0%');
		document.documentElement.style.setProperty('--mobileDlAppNotificationTranslateY', '0%');
		document.documentElement.classList.remove('scroll-dir-down');
		document.documentElement.classList.add('scroll-dir-up');
		
		setTimeout(() => {
			document.documentElement.style.setProperty('--pinnedElementTransitionDuration', '0.2s');
		}, 320);
	} else {
		document.documentElement.style.setProperty('--headerTranslateY', 'calc( ( (var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight)) ) * -1 )');
		document.documentElement.style.setProperty('--headerNotificationTranslateY', 'calc( (100% + var(--mobileDlAppNotificationHeight) ) * -1 )');
		document.documentElement.style.setProperty('--mobileDlAppNotificationTranslateY', '-100%');
		document.documentElement.classList.remove('scroll-dir-up');
		document.documentElement.classList.add('scroll-dir-down');
	}
};

const homepageTransitionInBus = useEventBus('homepage-transition-in');


const setHomepageCarouselBannerHeight = async () => {
	const firstCarouselSwiperSelector = '.banner-carousel-swiper';
	
	const wait = waitFor(() => {
		return (
			document.querySelector(firstCarouselSwiperSelector) &&
			document.querySelector(firstCarouselSwiperSelector).querySelector('.HeroBanner-inner') &&
			document.querySelector('.BookingWidget')
		);
	}, 400, 30); // <-- 400ms * 30 = 12 seconds
	// wait.start() will throw after 12 seconds, if condition is still unfulfilled
	
	await wait.start();
	
	const firstCarouselSwiper = document.querySelector(firstCarouselSwiperSelector);
	const viewportHeight = window.innerHeight;
	const viewportHeightCapped = Math.min(1080, viewportHeight);
	const bookingWidgetEl = document.querySelector('.BookingWidget');
	
	const bookingWidgetTabHeight = 48;
	const bookingWidgetNegativeMargin = parseInt(window.getComputedStyle(bookingWidgetEl).marginTop);
	
	const bannerPreAnimationHeight = viewportHeightCapped - bookingWidgetNegativeMargin - bookingWidgetTabHeight;
	const bannerPostAnimationHeight = bannerPreAnimationHeight - (Math.min(viewportHeightCapped * 0.3, 300));
	
	gsap.set(
		firstCarouselSwiper.querySelectorAll(`
			.banner-carousel-parsys-wrapper,
			[data-component="AsyncComponentWrapper"],
			[data-component="Banner"],
			.Banner,
			.HeroBanner,
			.HeroBanner-inner
		`),
		{ height: '100%' },
	);
	
	gsap.set(firstCarouselSwiper, { height: bannerPostAnimationHeight });
};

const containBannerHeight = ref(null);
const windowWidth = ref(window.innerWidth);
const initialWindowWidth = window.innerWidth;

const getContainBannerHeight = () => {
	const bannerList = [...document.querySelector('.banner-carousel-swiper').querySelectorAll('.HeroBanner-inner')];
	bannerList.shift();
	bannerList.pop();
	windowWidth.value = window.innerWidth;
	bannerList.forEach((banner) => {
		if (banner.classList.contains('bg-size-contain') && !containBannerHeight.value) {
			containBannerHeight.value = banner.offsetHeight;
		}
	});
	if (containBannerHeight.value) {
		document.querySelector('.banner-carousel-swiper').style.height = `${containBannerHeight.value * (windowWidth.value / initialWindowWidth)}px`;
	}
};

onMounted(async () => {
	// return;
	const PRELOADER_MIN_RUN_TIME = 1500;
	const startTime = window.homepageAnimationStartTime;
	const now = performance.now();
	
	if (isEditorMode.value) {
		fadeOutAnimationWrapper();
		return;
	}
	if (!startTime) {
		// setHomepageCarouselBannerHeight();
		fadeOutAnimationWrapper();
		return;
	}
	
	if (window.homepageTransitionInInitiated) {
		fadeOutAnimationWrapper();
		return;
	}
	
	// return;
	
	// --------------------------------------------------------------------
	try {
		window.homepageTransitionInInitiated = true;
		
		const viewportHeight = window.innerHeight;
		const viewportHeightCapped = Math.min(1080, viewportHeight);
		const viewportWidth = window.innerWidth;
		const firstCarouselSwiperSelector = '.banner-carousel-swiper';
		
		const wait = waitFor(() => {
			return (
				document.querySelector(firstCarouselSwiperSelector) &&
				document.querySelector(firstCarouselSwiperSelector).querySelector('.HeroBanner-inner') &&
				document.querySelector('.BookingWidget')
			);
		}, 400, 30); // <-- 400ms * 30 = 12 seconds
		// wait.start() will throw after 12 seconds, if condition is still unfulfilled
		
		if (!document.querySelector(firstCarouselSwiperSelector)) {
			await wait.start();
		}
		
		updateTranslateY('hide');
		
		
		const topOffset = (
			(document.querySelector('.HeaderNotification')?.offsetHeight ?? 0)
			+
			(document.querySelector('.MobileDownloadAppNotification')?.offsetHeight ?? 0)
		);
		
		const bookingWidgetEl = document.querySelector('.BookingWidget');
		const headerEl = document.querySelector('.global-header .AppHeader');
		const bookingWidgetNegativeMargin = parseInt(window.getComputedStyle(bookingWidgetEl).marginTop);
		const bookingWidgetTabHeight = 48;
		
		let bannerPreAnimationHeight = viewportHeightCapped - bookingWidgetNegativeMargin - bookingWidgetTabHeight;
		let bannerPostAnimationHeight = bannerPreAnimationHeight - (Math.min(viewportHeightCapped * 0.3, 300));
		
		const firstCarouselSwiper = document.querySelector(firstCarouselSwiperSelector);
		const firstCarouselSwiperPagination = firstCarouselSwiper.querySelector('.swiper-pagination');

		getContainBannerHeight();

		if (containBannerHeight.value) {
			bannerPostAnimationHeight = containBannerHeight.value;
			bannerPreAnimationHeight = containBannerHeight.value;
		}
		
		gsap.set(firstCarouselSwiper, { height: bannerPreAnimationHeight, marginTop: -topOffset });
		
		gsap.set(
			firstCarouselSwiper.querySelectorAll(`
				.banner-carousel-parsys-wrapper,
				[data-component="AsyncComponentWrapper"],
				[data-component="Banner"],
				.Banner,
				.HeroBanner,
				.HeroBanner-inner
			`),
			{ height: '100%' },
		);
		
		
		if (firstCarouselSwiper.querySelector('.swiper-button-next')) {
			gsap.set([
				firstCarouselSwiper.querySelector('.swiper-button-prev'),
				firstCarouselSwiper.querySelector('.swiper-button-next'),
			], { y: '-=12px' });
		}
		
		if (firstCarouselSwiperPagination) {
			gsap.set(firstCarouselSwiperPagination, { bottom: 0 });
		}
		
		
		let firstCarouselSlide = firstCarouselSwiper.querySelector('.swiper-slide-active');
		if (!firstCarouselSlide) {
			// maybe carousel only has 1 slide, which causes swiper to never initialize
			firstCarouselSlide = firstCarouselSwiper.querySelector('.swiper-slide');
		}
		
		const firstCarouselSlideBannerParsys = firstCarouselSlide.querySelector('.banner-content-placeholder');
		
		const bookingWidgetContentParsys = bookingWidgetEl.querySelectorAll('.SwitchTabContent .switch-tab-content-wrapper');
		
		
		const tl = gsap.timeline({});
		
		tl.add([
			gsap.from([firstCarouselSwiper, bookingWidgetEl, headerEl], {
				duration: 0.5,
				opacity: 0,
			}),
		], '+=0.55');
		
		tl.add([
			gsap.from(firstCarouselSlideBannerParsys, {
				duration: 0.4,
				opacity: 0,
				y: '+=60px',
			}),
		]);

		tl.add([
			gsap.to(firstCarouselSwiper, {
				duration: 0.6,
				height: bannerPostAnimationHeight,
			}),
		], '+=0.25');

		tl.add([
			gsap.from(bookingWidgetContentParsys, {
				duration: 0.4,
				opacity: 0,
				y: '+=60px',
			}),
		], '+=0.25');
		
		tl.add([
			gsap.to(firstCarouselSwiper, {
				duration: 0.3,
				marginTop: 0,
				onStart: () => {
					updateTranslateY('show');
				},
			}),
		], '+=0.4');
		
		tl.add([
			gsap.from(document.querySelector('.FloatingWidget'), {
				duration: 0.2,
				bottom: '-=20%',
				onComplete: () => {
					homepageTransitionInBus.emit('animation-complete');
				},
			}),
			...( firstCarouselSwiperPagination ?
				[gsap.to(
					firstCarouselSwiperPagination,
					{ duration: 0.3, bottom: '120px' },
				)] : []
			),
		], '+=0.4');
		
		
		setTimeout(async () => {
			await fadeOutAnimationWrapper();
			// animateHomepageElements();
		}, (now - startTime <= PRELOADER_MIN_RUN_TIME) ? (now - startTime) : 0);
	} catch (err) {
		console.log('homepage animation err = ', err);
		// setHomepageCarouselBannerHeight();
		fadeOutAnimationWrapper();
	}

	window.addEventListener('resize', getContainBannerHeight);
});

onUnmounted(() => {
	window.addEventListener('resize', getContainBannerHeight);
});

</script>


<template>
<div ref="rootEl" class="HomepageLayout flex flex-col min-h-screen" data-layout="Homepage">
	<ExperienceFragment
		class="global-mobile-download-app-notification"
		xfPath="/content/experience-fragments/mh/$country/$lang/site/mobile-download-app-notification/master"
	/>
	<div class="sticky-spacer h-$mobileDlAppNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header-notification"
		xfPath="/content/experience-fragments/mh/$country/$lang/site/header-notification/master"
	/>
	<div class="sticky-spacer h-$headerNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/mh/$country/$lang/site/header/master"
	/>
	
	<div id="content"></div>
	<div id="booking-subpage-container"></div>
	<main>
		<slot></slot>
	</main>
	
	<div class="floating-bottom-right-container">
		<ExperienceFragment
			class="global-floating-widget"
			xfPath="/content/experience-fragments/mh/$country/$lang/site/floating-widget/master"
		/>
		<BtnBackToTop />
	</div>
	
	<ExperienceFragment
		class="global-cookies-notification"
		xfPath="/content/experience-fragments/mh/$country/$lang/site/cookies-notification/master"
	/>
	<ExperienceFragment
		class="global-footer"
		xfPath="/content/experience-fragments/mh/$country/$lang/site/footer/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

</style>
