import { merge as _merge, isEmpty as _isEmpty } from 'lodash';
import { useGlobalCookieSettings } from '~/logic/global-state/cookie-settings.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { waitForDocumentReadyState } from '~/logic/helpers/dom.js';
import { waitFor, toKebabCase } from '~/logic/helpers/utils.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { useFireflyAuthStore } from '~/logic/auth/firefly-auth-store.js';
import FuncQueue from '~/logic/helpers/FuncQueue.js';

window.adobeDataLayer = window.adobeDataLayer || [];

if (window.location.hostname === 'localhost') window.logAAPayload = true;

const siteKey = (window.siteName === 'MAG') ? '_magcorporate' : '_malaysiaairlines';

const siteName = window.siteName;
const isAmal = siteName === 'amal';
const isTemplate404 = (templateName) => {
	if (
		templateName === 'spa-404-page-template' ||
		templateName === 'mag-spa-404-page-template' ||
		templateName === 'mhh-spa-404-page-template' ||
		templateName === 'firefly-spa-404-page-template' ||
		templateName === 'amal-spa-404-page-template'
	) {
		return true;
	}
	return false;
};

// ============================== PUSH AA MASTER FUNCTION ==============================
export const pushToAdobeDataLayer = async (eventName, dataToMerge = {}) => {
	const { isEnabledCookieStatistics } = useGlobalCookieSettings();
	const { rootPath, pageProperties, pageModel } = useGlobalAEMState();
	const country = unref(pageProperties).rootCountry;
	const language = unref(pageProperties).rootLanguage;
	
	const currentRoute = window.router.currentRoute.value;
	const pathSegments = (
		currentRoute
			.path
			.replace(new RegExp(`^${rootPath.value}`), '') // remove the root path (e.g. /content/my/en)
			.replace(/\.html$/, '') // removing the ending .html
			.split('/')
			.filter(Boolean)
	);
	const currentPageName = pathSegments.at(-1);
	
	const cidValue = currentRoute?.query?.cid ?? '';
	
	const marketingObj = cidValue ? {
		marketing: {
			trackingCode: cidValue,
		},
	} : null;
	
	const {
		isLoggedIn,
		userInfo,
		isAuthStateReady,
	} = (siteName === 'firefly' ? useFireflyAuthStore() : useAuthStore());
	
	if (!isAuthStateReady.value) {
		const waitForAuthReady = waitFor(() => !!isAuthStateReady.value, 400, 120); // <-- 400ms * 120 = 48 seconds
		await waitForAuthReady.start();
	}

	const userIdValue = (window.siteName !== 'firefly' ? userInfo.value?.userId : userInfo.value?.accountNumber);
	
	const userInfoObj = {
		userID: userIdValue || '',
		status: isLoggedIn.value ? 'Authenticated' : 'notAuthenticated',
		// membershipTier can be 'Platinum' | 'Gold' | 'Silver' (might be in all uppercase)
		...((isLoggedIn.value && window.site === 'MH') ? { membershipTier: userInfo.value?.userTierLevel } : null),
	};
	
	const payload = _merge({
		event: eventName ?? '',
		[siteKey]: {
			pageName: currentPageName,
			pageURL: window.location.href,
			siteSection: pathSegments.at(0),
			siteSubSection: pathSegments.at(1) || '',
			pageType: pageProperties.value?.pageTypeAA || 'others',
			siteName: `${ window.siteName } Revamp`,
			language: language,
			...(window.siteName !== 'MAG' ? { market: country } : null),
			...((window.siteName === 'MH' || window.siteName === 'firefly') ? { userInfo: userInfoObj } : null),
		},
		...marketingObj,
		web: {
			webPageDetails: {
				URL: window.location.href,
				name: currentPageName,
				server: document.location.hostname,
				siteSection: pathSegments.at(0),
				pageViews: {
					value: 1,
				},
				...(isTemplate404(pageModel.value.templateName) ? { isErrorPage: 'y' } : null),
			},
		},
	}, dataToMerge);
	
	if (window.logAAPayload) console.log(`pushToAdobeDataLayer() payload = `, payload);
	
	// if (!isEnabledCookieStatistics.value) {
	// 	if (window.logAAPayload) console.log(`Skipping adobeDataLayer.push(). No consent.`);
	// 	return false;
	// }
	
	try {
		window.adobeDataLayer.push(payload);
		return true;
	} catch (err) {
		console.group('AA pushToAdobeDataLayer() failed');
		console.log('dataToMerge = ', dataToMerge);
		console.log('payload = ', payload);
		console.log('err = ', err);
		console.groupEnd();
	}
};

// ============================== PUSH AA MASTER GLOBAL FUNCTION ==============================
export const pushToAdobeDataLayerGlobal = async (eventName, dataToMerge = {}) => {
	const { rootPath, pageProperties, pageModel } = useGlobalAEMState();
	const country = unref(pageProperties).rootCountry;
	const language = unref(pageProperties).rootLanguage;
	
	const currentRoute = window.router.currentRoute.value;
	const pathSegments = (
		currentRoute
			.path
			.replace(new RegExp(`^${rootPath.value}`), '') // remove the root path (e.g. /content/my/en)
			.replace(/\.html$/, '') // removing the ending .html
			.split('/')
			.filter(Boolean)
	);
	const currentPageName = pathSegments.at(-1);

	const cidValue = currentRoute?.query?.cid ?? '';
	
	const {
		isLoggedIn,
		userInfo,
		isAuthStateReady,
	} = (siteName === 'firefly' ? useFireflyAuthStore() : useAuthStore());
	
	if (!isAuthStateReady.value) {
		const waitForAuthReady = waitFor(() => !!isAuthStateReady.value, 400, 120); // <-- 400ms * 120 = 48 seconds
		await waitForAuthReady.start();
	}

	const userIdValue = (window.siteName !== 'firefly' ? userInfo.value?.userId : userInfo.value?.accountNumber);
	
	const userInfoObj = {
		enrichID: userIdValue || '',
		authenticatedState: isLoggedIn.value ? 'authenticated' : 'loggedOut',
		...(cidValue ? { ecid: cidValue } : null),
		// enrichID
		// membershipTier can be 'Platinum' | 'Gold' | 'Silver' (might be in all uppercase)
		// ...((isLoggedIn.value && window.site === 'MH') ? { membershipTier: userInfo.value?.userTierLevel } : null),
	};
	
	const payload = _merge({
		event: eventName ?? '',
		[siteKey]: {
			pageInfo: {
				pageName: `${siteName}:${pathSegments.at(0)}:${language}:${currentPageName}`,
				pageURL: window.location.href,
				siteSection: siteName,
				language: language,
				market: country,
			},
			...((window.siteName === 'MH' || window.siteName === 'firefly' || isAmal) ? { userInfo: userInfoObj } : null),
		},
		web: {
			webPageDetails: {
				URL: window.location.href,
				name: `${siteName}:${pathSegments.at(0)}:${language}:${currentPageName}`,
				server: document.location.hostname,
				siteSection: siteName,
				pageViews: {
					value: 1,
				},
				...(isTemplate404(pageModel.value.templateName) ? { isErrorPage: true } : null),
			},
		},
	}, dataToMerge);
	
	if (window.logAAPayload) console.log(`pushToAdobeDataLayer() payload = `, payload);
	// if (!isEnabledCookieStatistics.value) {
	// 	if (window.logAAPayload) console.log(`Skipping adobeDataLayer.push(). No consent.`);
	// 	return false;
	// }
	
	try {
		window.adobeDataLayer.push(payload);
		return true;
	} catch (err) {
		console.group('AA pushToAdobeDataLayer() failed');
		console.log('dataToMerge = ', dataToMerge);
		console.log('payload = ', payload);
		console.log('err = ', err);
		console.groupEnd();
	}
};


// ============================== PUSH AA SUB-FUNCTION ==============================
// ~~~~~~ Sub Function (Search) ~~~~~~
export const pushAA_search = ([ searchInfo = {}, webInteractions = {}, data = {} ] = []) => {
	return pushToAdobeDataLayer('search', _merge({
		[siteKey]: {
			searchInfo: {
				...searchInfo,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: '',
				type: 'other',
				...webInteractions,
			},
		},
	}, data));
};
export const pushAA_filterApplied = ([ searchInfo = {}, webInteractions = {}, data = {} ] = []) => {
	return pushToAdobeDataLayer('filterApplied', _merge({
		[siteKey]: {
			searchInfo: {
				...searchInfo,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: '',
				type: 'other',
				...webInteractions,
			},
		},
	}, data));
};
export const pushAA_flightSearchStart = (nameOfClick = '', data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightSearch',
					type: `analytics:${formatedNameOfClick}`,
					status: 'started',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightSearchStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};
export const pushAA_flightSearchEnd = (nameOfClick = '', data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightSearch',
					type: `analytics:${formatedNameOfClick}`,
					status: 'completed',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightSearchEnd', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};
export const pushAA_flightPlusHotelStart = (nameOfClick = '', data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightPlusHotel',
					type: `analytics:${formatedNameOfClick}`,
					status: 'started',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightPlusHotelStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};
export const pushAA_flightPlusHotelEnd = (nameOfClick = '', data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightPlusHotel',
					type: `analytics:${formatedNameOfClick}`,
					status: 'completed',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightPlusHotelEnd', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};
export const pushAA_flightStatusStart = (nameOfClick = '', data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightStatus',
					type: `analytics:${formatedNameOfClick}`,
					status: 'started',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightStatusStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};
export const pushAA_flightStatusEnd = (flightSelections = {}, nameOfClick = {}, data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightStatus',
					type: `analytics:${formatedNameOfClick}`,
					status: 'completed',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightStatusEnd', _merge({
		[siteKey]: {
			flightSelections: {
				...flightSelections,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_searchError = ([ errorInfo = {}, webInteractions = {}, data = {} ] = []) => {
	if (isAmal) {
		return pushToAdobeDataLayerGlobal('error', _merge({
			[siteKey]: {
				errorInfo: {
					...errorInfo,
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: '',
					type: 'other',
					...webInteractions,
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('error', _merge({
		[siteKey]: {
			errorInfo: {
				...errorInfo,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: '',
				type: 'other',
				...webInteractions,
			},
		},
	}, data));
};

export const pushAA_MHHFlightPlusHotelStart = (nameOfClick = '', data = {}) => {
	return pushToAdobeDataLayer('flightPlusHotelStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_MHHFlightPlusHotelEnd = (nameOfClick = '', data = {}, formData = {}) => {
	return pushToAdobeDataLayer('flightPlusHotelEnd', _merge({
		_malaysiaairlines: {
			holidayBookingInfo: {
				flight: {
					...formData,
				},
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_MHHHotelStart = (nameOfClick = '', data = {}) => {
	return pushToAdobeDataLayer('hotelStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_MHHHotelEnd = (nameOfClick = '', data = {}, formData = {}) => {
	return pushToAdobeDataLayer('hotelEnd', _merge({
		_malaysiaairlines: {
			holidayBookingInfo: {
				hotel: {
					...formData,
				},
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_tripsAndToursStart = (nameOfClick = '', data = {}) => {
	return pushToAdobeDataLayer('tripsAndToursStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_tripsAndToursEnd = (tripsAndTours = {}, nameOfClick = {}, data = {}) => {
	return pushToAdobeDataLayer('tripsAndToursEnd', _merge({
		_malaysiaairlines: {
			holidayBookingInfo: {
				tripsAndTours: {
					...tripsAndTours,
				},
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_fireflyBookAirCargoSearchStart = (nameOfClick = '', data = {}) => {
	return pushToAdobeDataLayer('bookAirCargoStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_fireflyBookAirCargoSearchEnd = (flightSelectionsData = {}, nameOfClick = '', data = {}) => {
	return pushToAdobeDataLayer('bookAirCargoEnd', _merge({
		_malaysiaairlines: {
			flightSelections: {
				...flightSelectionsData,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_fireflyBookAirCargoSearchError = ([ errorInfo = {}, webInteractions = {}, data = {} ] = []) => {
	return pushToAdobeDataLayer('error', _merge({
		[siteKey]: {
			errorInfo: {
				...errorInfo,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: '',
				type: 'other',
				...webInteractions,
			},
		},
	}, data));
};

export const pushAA_clickFilter = (
	{
		clickName = '',
		componentID = '',
		componentName = '',
		webInteractionName = '',
	} = {},
	data = {},
) => {
	return pushToAdobeDataLayer('clickFilter', _merge({
		_malaysiaairlines: {
			clickInfo: {
				clickName: clickName,
				clickComponentType: 'button',
				componentName: componentName,
				componentID: componentID,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: webInteractionName,
				type: 'other',
			},
		},
	}, data));
};

// ~~~~~~ Sub Function (Form) ~~~~~~
export const pushAA_formStart = ([ { formName = '' }, webInteractions = {}, data = {} ]) => {
	return pushToAdobeDataLayer('formStart', _merge({
		[siteKey]: {
			form: {
				formName,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: '',
				type: 'other',
				...webInteractions,
			},
		},
	}, data));
};

export const pushAA_formComplete = ([ { formName = '' }, webInteractions = {}, data = {} ]) => {
	return pushToAdobeDataLayer('formComplete', _merge({
		[siteKey]: {
			form: {
				formName,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: '',
				type: 'other',
				...webInteractions,
			},
		},
	}, data));

};

export const pushAA_formError = ([{ formName = '', errorName = '' }, webInteractions = {}, data = {} ]) => {
	return pushToAdobeDataLayer('error', _merge({
		[siteKey]: {
			errorInfo: {
				errorName,
			},
			form: {
				formName,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: '',
				type: 'other',
				...webInteractions,
			},
		},
	}, data));
};

// ~~~~~~ Sub Function (Subscribe / Unsubscribe) ~~~~~~
export const pushAA_subscribe = (nameOfClick = '', data = {}) => {
	return pushToAdobeDataLayer('subscribeClick', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};
export const pushAA_manageProfile = (nameOfClick = '', data = {}) => {
	return pushToAdobeDataLayer('manageProfile', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

export const pushAA_flightDisruptionStart = (nameOfClick = '', data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightDisruption',
					type: `analytics:${formatedNameOfClick}`,
					status: 'started',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightDisruptionStart', _merge({
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};
export const pushAA_flightDisruptionEnd = (flightSelections = {}, nameOfClick = {}, data = {}) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(nameOfClick);
		return pushToAdobeDataLayerGlobal('userEvent', _merge({
			[siteKey]: {
				eventInfo: {
					name: 'flightDisruption',
					type: `analytics:${formatedNameOfClick}`,
					status: 'completed',
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					name: formatedNameOfClick,
					type: 'other',
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('flightDisruptionEnd', _merge({
		[siteKey]: {
			flightSelections: {
				...flightSelections,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				name: nameOfClick,
				type: 'other',
			},
		},
	}, data));
};

// ~~~~~~ Sub Function (Click) ~~~~~~
export const pushAA_click = ([clickInfo = {}, webInteractions = {}, data = {}] = []) => {
	if (isAmal) {
		let formatedNameOfClick = toKebabCase(clickInfo.clickName);
		let cInfo = {
			...clickInfo,
			componentType: clickInfo.clickComponentType,
			clickName: `interaction:click:element:button:name:${formatedNameOfClick}`,
		};
		delete cInfo['clickComponentType'];
		webInteractions['name'] = `interaction:click:element:button:name:${formatedNameOfClick}`;
		return pushToAdobeDataLayerGlobal('userInteraction', _merge({
			[siteKey]: {
				clickInfo: {
					...cInfo,
				},
			},
			web: {
				webInteractions: {
					linkClicks: { value: 1 },
					...webInteractions,
				},
			},
		}, data));
	}
	return pushToAdobeDataLayer('Click', _merge({
		[siteKey]: {
			clickInfo: {
				...clickInfo,
			},
		},
		web: {
			webInteractions: {
				linkClicks: { value: 1 },
				...webInteractions,
			},
		},
	}, data));
};



// ============================== PAGE VIEW ==============================
const pageViewQueue = new FuncQueue();
const sentPageViews = [];

const actualSendPageView = () => {
	console.log('Firing actualSendPageView(). sentPageViews = ', sentPageViews);
	const currentPathname = window.location.pathname;
	if (sentPageViews.includes(currentPathname)) return; // already sent, do nothing
	
	const {
		isEnabledCookiePreferences,
		isEnabledCookieStatistics,
		isEnabledCookieMarketing,
	} = useGlobalCookieSettings();
	if (isAmal) {
		return pushToAdobeDataLayerGlobal('pageLoaded', {
			[siteKey]: {
				consent: {
					c_preference: String(isEnabledCookiePreferences.value),
					c_statistics: String(isEnabledCookieStatistics.value),
					c_marketing: String(isEnabledCookieMarketing.value),
				},
			},
		});
	}
	return pushToAdobeDataLayer('pageLoaded', {
		[siteKey]: {
			consent: {
				c_preference: String(isEnabledCookiePreferences.value),
				c_statistics: String(isEnabledCookieStatistics.value),
				c_marketing: String(isEnabledCookieMarketing.value),
			},
		},
	});
	
	/* if (pushToAdobeDataLayer('pageLoaded', {
		[siteKey]: {
			consent: {
				c_preference: String(isEnabledCookiePreferences.value),
				c_statistics: String(isEnabledCookieStatistics.value),
				c_marketing: String(isEnabledCookieMarketing.value),
			},
		},
	})) {
		sentPageViews.push(currentPathname);
	} */
};
export const sendPageView = () => {
	pageViewQueue.enqueue({
		func: actualSendPageView,
		thenFunc: (result) => {
			if (result) {
				console.log(`Success actualSendPageView(). Adding ${window.location.pathname} to sentPageViews Array.`);
				sentPageViews.push(window.location.pathname);
			} else {
				console.log('Failed actualSendPageView()');
			}
		},
	});
};

// Attempt to send page view on page 'ready'.
// If user haven't consent, it would fail, but could be triggered again on consent accepted.
(async () => {
	const wait = waitFor(() => !!window.router, 400, 120); // <-- 400ms * 120 = 48 seconds
	await wait.start();
	
	await waitForDocumentReadyState('interactive');
	
	const { isAuthStateReady } = (siteName === 'firefly' ? useFireflyAuthStore() : useAuthStore());
	const waitForAuthReady = waitFor(() => !!isAuthStateReady.value);
	await waitForAuthReady.start();
	sendPageView();
})();



// ============================== DIRECTIVE ==============================
export const installDirectiveAA = (app) => {
	// Reference: https://vuejs.org/guide/reusability/custom-directives.html#directive-hooks
	/* 
		// when aaFlag = 'disabled', AA will not fire. Otherwise, it will fire.
		// if this feature is not needed, can use v-aa="[]" as usual.
		const aaFlag = computed(() => { return 'disabled'; }); // <-- or return 'enabled'
		
		<Testing
			v-aa:[aaFlag]="[{ clickName: 'testing' }, { type: 'exit' }]"
		></Testing>
		
		-----
		
		// value can be reactive. For e.g.
		const aaWebInteractionType = computed(() => { return 'exit' });
		<Testing
			v-aa="[{ clickName: 'testing' }, { type: aaWebInteractionType }]"
		></Testing>
		
		-----
		
		If any inner descendents of el has attribute: data-aa-target,
		Then click listener will be attached to that el instead.
		
		-----
		
		Supports multiple event via modifiers.
		For e.g.
		v-aa.click.mouseover=""
		
		If no modifiers is provided, defaults to just 'click' event.
	*/
	app.directive('aa', {
		mounted (el, binding, vnode, prevVnode) {
			const eventNames = Object.keys(binding.modifiers);
			if (eventNames.length === 0) eventNames.push('click');
			
			el.__aa_events = eventNames;
			el.__aa_disabled = binding.arg === 'disabled';
			el.__aa_value = binding.value;
			
			el.__aa_target = el.querySelector('[data-aa-target]') || el;
			
			el.__aa_listener = (event) => {
				if (el.__aa_disabled || el.__aa_value === null || el.__aa_value === undefined) return;
				pushAA_click(el.__aa_value);
			};
			el.__aa_events.forEach((eventName) => {
				el.__aa_target.addEventListener(eventName, el.__aa_listener, { capture: true });
			});
		},
		updated (el, binding, vnode, prevVnode) {
			el.__aa_disabled = binding.arg === 'disabled';
			el.__aa_value = binding.value;
		},
		unmounted (el, binding, vnode, prevVnode) {
			if (!el.__aa_target) return;
			el.__aa_events.forEach((eventName) => {
				el.__aa_target.removeEventListener(eventName, el.__aa_listener, { capture: true });
			});
		},
	});
};