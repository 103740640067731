<script>
export default {
	name: 'OverlayBtmSticky',
	inheritAttrs: false,
};
</script>


<script setup>
import useDisableScroll from '~composables/useDisableScroll.js';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { Transition } from 'vue';

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	backdropClosable: { type: Boolean, default: true },
	
	overlayContainerAttrs: { type: Object, default: () => ({}) },
	overlayWrapperAttrs: { type: Object, default: () => ({}) },
	overlayContentAttrs: { type: Object, default: () => ({}) },
	
	enableFocusTrap: { type: Boolean, default: true },
	disableCalendarPickerAnimation: { type: Boolean, default: false },
});
const emit = defineEmits([
	'update:isVisible',
]);

const rootEl = ref(null);

const internalVisible = ref(props.isVisible);


useDisableScroll(internalVisible);

watch(() => props.isVisible, (newValue) => {
	internalVisible.value = newValue;
});

watch(internalVisible, (newValue) => {
	emit('update:isVisible', newValue);
});

const handleBackdropClick = (event) => {
	if (props.backdropClosable) {
		hideOverlay();
	}
};
const hideOverlay = () => {
	internalVisible.value = false;
};

// watch(internalVisible, async (newValue) => {
// 	if (newValue === false) {
// 		// overlay hidden
// 	} else {
// 		// overlay shown
// 		await nextTick();
// 		rootEl.value.focus();
// 	}
// }, { immediate: true, flush: 'post' });


const { activate: activeFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(rootEl);

const handleRootElOnMounted = async () => {
	if (props.enableFocusTrap) {
		rootEl.value?.focus();
		await nextTick(); // oddly enough, we have to use nextTick in order for focus trap to work 🤔
	
		activeFocusTrap();
	}
};
const handleRootElBeforeUnmount = () => {
	if (props.enableFocusTrap) {
		deactivateFocusTrap();
	}
};

const siteName = window.siteName;

</script>



<template>
<Teleport to="body">
	<component
		:is="props.disableCalendarPickerAnimation ? 'div' : Transition"
		name="overlay-bottom"
		appear
	>
		<div
			v-if="isVisible"
			ref="rootEl"
			tabindex="-1"
			class="OverlayBtmSticky backdrop"
			:data-use-theme="siteName"
			v-bind="$attrs"
			@keydown.esc.capture="hideOverlay"
			@vue:mounted="handleRootElOnMounted"
			@vue:beforeUnmount="handleRootElBeforeUnmount"
			@click.self="handleBackdropClick"
		>
			<div class="overlay-container" v-bind="props.overlayContainerAttrs">
				<div class="overlay-wrapper" v-bind="props.overlayWrapperAttrs">
					<div
						class="overlay-line"
						:class="{
							'opacity-0': !props.backdropClosable,
						}"
						@click="props.backdropClosable ? hideOverlay : null"
					></div>
					<div
						class="overlay-content flex-grow overflow-y-auto styled-scrollbar mr-2 mb-2"
						v-bind="props.overlayContentAttrs"
					>
						<slot></slot>
					</div>
					<footer class="overlay-footer empty:hidden">
						<slot name="footer"></slot>
					</footer>
				</div>
			</div>
		</div>
	</component>
</Teleport>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.OverlayBtmSticky {
	
}

.backdrop {
	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: color.change(black, $alpha: 0.8);
	
	display: flex;
	align-items: flex-end;
	justify-content: center;
	transition: opacity 0.3s ease;
}

.overlay-container {
	background-color: white;
	width: 100%;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
	height: auto;
	max-height: 80vh;
	overflow: hidden;
	@apply pb-4;
}

.overlay-wrapper {
	height: 100%;
	max-height: inherit;
	display: flex;
	flex-direction: column;
}

.overlay-line {
	@apply pb-7 pt-4;
	
	&::after {
		content: '';
		width: 48px;
		height: 2px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		background-color: var(--neutral-grey-base);
		@apply rounded;
	}
}

// animations
.overlay-bottom-enter-from {
	opacity: 0;
}

.overlay-bottom-enter-active,
.overlay-bottom-leave-active {
	transition: opacity 200ms;
}

.overlay-bottom-leave-to {
	opacity: 0;
}

.overlay-bottom-enter-from,
.overlay-bottom-leave-to {
	.overlay-container {
		transform: translateY(75%);
	}
}

.overlay-bottom-enter-active,
.overlay-bottom-leave-active {
	.overlay-container {
		transition: transform 200ms;
	}
}

.overlay-bottom-enter-to,
.overlay-bottom-leave-from {
	.overlay-container {
		transform: translateY(0%);
	}
}

[data-use-theme="MAG"] {
	.styled-scrollbar::-webkit-scrollbar-thumb {
		background-color: var(--primary-midnightgreen-base);
	}
}

</style>

