
const isPromise = (p) => {
	if (typeof p === 'object' && typeof p.then === 'function') {
		return true;
	}
	return false;
};

class FuncQueue {
	constructor (initialQueue = []) {
		this._queueList = initialQueue.map(({ func, thenFunc, catchFunc, finallyFunc }) => {
			return {
				func,
				symbol: Symbol(),
				thenFunc,
				catchFunc,
				finallyFunc,
			};
		});
		this._isBusy = false;
		this._currentlyProcessing = null;
		this.processQueue();
	}
	
	processQueue () {
		const firstItem = this._queueList.at(0);
		if (!firstItem) {
			this._isBusy = false;
			this._currentlyProcessing = null;
			return;
		}
		if (this._isBusy) {
			return;
		}
		this._isBusy = true;
		const result = firstItem.func();
		this._currentlyProcessing = result;
		if (isPromise(result)) {
			if (typeof firstItem.thenFunc === 'function') {
				result.then(firstItem.thenFunc);
			}
			if (typeof firstItem.catchFunc === 'function') {
				result.catch(firstItem.catchFunc);
			}
			if (typeof firstItem.finallyFunc === 'function') {
				result.finally(firstItem.finallyFunc);
			}
			result.finally(() => {
				this._isBusy = false;
				this.dequeue();
				this.processQueue();
			});
		} else {
			// func is not a promise, directly process the next one
			this._isBusy = false;
			this.dequeue();
			this.processQueue();
		}
	}
	
	enqueue ({ func, thenFunc, catchFunc, finallyFunc }) {
		if (typeof func !== 'function') {
			throw new Error(`Argument is not a function`);
		}
		
		this._queueList.push({
			func,
			symbol: Symbol(),
			thenFunc,
			catchFunc,
			finallyFunc,
		});
		this.processQueue();
	}
	
	dequeue (sym) {
		const index = sym ? this._queueList.findIndex((item) => item.symbol === sym) : 0;
		this._queueList.splice(index, 1);
	}
	
	get queueList () {
		return this._queueList;
	}
	get isBusy () {
		return this._isBusy;
	}
	get currentlyProcessing () {
		return this._currentlyProcessing;
	}
	
}

export default FuncQueue;