<script>
export default {
	name: 'ChatBot',
};
</script>

<script setup>
import HtmlComponent from '~/aem-components/HtmlComponent.vue';

const props = defineProps({
	urlCss: { type: String, default: 'https://www.malaysiaairlines.com/content/dam/mas/chatbot/prod/main.css' },
	urlJs: { type: String, default: 'https://www.malaysiaairlines.com/content/dam/mas/chatbot/prod/main.js' },
});

const emit = defineEmits([
	'on-chatbot-close',
]);

const onChatBotRootClick = (event) => {
	if ((event.target).classList.contains('close-icon')) {
		emit('on-chatbot-close', false);
	}
};

onMounted(() => {
	if (document.querySelector('[href="https://www.malaysiaairlines.com/content/dam/mas/chatbot/prod/main.css"]')) {
		return;
	}
	const head = document.getElementsByTagName('head')[0];
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.type = 'text/css';
	link.href = props.urlCss;
	head.appendChild(link);
});

</script>

<template>
<div id="mag-bot" @click="onChatBotRootClick">
	<HtmlComponent
		:externalScript="[
			props.urlJs,
		]"
	></HtmlComponent>
</div>
</template>


<style lang="scss" scoped>
@use '~/styles/partials/_var.scss';

#mag-bot {
	position: relative;
	top: 20px;

	:deep(.botIcon-container) {
		position: relative;
		bottom: 20px;
		right: 1px;
		width: 48px;
		height: 48px;
		
		@media #{var.$query-max-sm} {
			width: 40px;
			height: 40px;
			right: 0px;
		}

		img {
			max-width: 17px;
		}
	}
}

</style>

