import { createGlobalState } from '@vueuse/core';
import { getCookie, deleteCookie } from '~/logic/helpers/cookies.js';

const SHADOW_FY_COOKIE = 'shadow-fy';

export const useFireflyAuthStore = createGlobalState(
	() => {
		/* userInfo
			{
				accountNumber: '562000',
				userId: 'username@email.com',
				username: 'Jung Yong Xi',
			}
		*/
		const userInfo = ref(null);
		const _getIsLoggedInStatus = () => {
			return getCookie(SHADOW_FY_COOKIE) && userInfo.value;
		};
		const isLoggedIn = ref(_getIsLoggedInStatus());
		const isAuthStateReady = ref(false);
		
		const isFYCookiePresent = () => {
			return getCookie(SHADOW_FY_COOKIE);
		};
		
		const login = (_userInfo) => {
			const cookieData = getCookie(SHADOW_FY_COOKIE);
			if (!cookieData) {
				console.log('Attempting to login but login cookie not present');
				return;
			}
			const cookieDataSplit = cookieData.split('|');
			userInfo.value = {
				accountNumber: cookieDataSplit.at(0),
				userId: cookieDataSplit.at(1),
				username: cookieDataSplit.at(2),
			};
			refreshAuthStatus();
		};
		const logout = () => {
			deleteCookie(SHADOW_FY_COOKIE);
			userInfo.value = null;
			refreshAuthStatus();
		};
		const refreshAuthStatus = () => {
			isLoggedIn.value = _getIsLoggedInStatus();
		};
		
		
		return {
			// data
			isLoggedIn,
			userInfo,
			isAuthStateReady,
			
			// method
			login,
			logout,
			refreshAuthStatus,
			isFYCookiePresent,
		};
	},
);

window.useFireflyAuthStore = useFireflyAuthStore;

