<script>
export default {
	name: 'CrossSellBanner',
	inheritAttrs: false,
};
</script>

<script setup>
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';


const props = defineProps({
	desktopImgSrc: { type: String, default: '' },
	mobileImgSrc: { type: String, default: '' },
	desktopImageAltText: { type: String, default: '' },
	mobileImageAltText: { type: String, default: '' },
	desktopBannerHeight: { type: String, default: '' }, // <--- 'small' | 'large'
	mobileBannerHeight: { type: String, default: '' }, // <--- 'small' | 'large'
	bgStyle: { type: String, default: '' }, // <--- 'blue' | 'teal'
	keyVisualSrc: { type: String, default: '' },
	keyVisualAlignment: { type: String, default: 'left' }, // <--- 'left' | 'right'
	keyVisualAlt: { type: String, default: '' },
	gradientValue: { type: String, default: '' }, // <--- 'blue' 
	title: { type: String, default: '' },
	description: { type: String, default: '' },
	url: { type: String, default: '' },
	openNewTab: { type: Boolean, default: false },
	componentIdAA: { type: String, default: '' },
});

const { isEditorMode } = useGlobalAEMState();


const computedGradientValue = computed(() => {
	if (isMobileViewport.value) {
		return 'to bottom, rgba(0, 94, 103, 0) 0%, rgba(0, 94, 103, 90) 40%, rgba(0, 94, 103, 100) 100%';
	}
	return `to ${props.keyVisualAlignment === 'left' ? 'right' : 'left'}, rgba(0, 94, 103, 0) 0%, rgba(0, 94, 103, 90) 40%, rgba(0, 94, 103, 100) 100%`;
});

const bannerSizeDesktopMap = {
	small: '250',
	large: '382',
};

const bannerSizeMobileMap = {
	small: '382',
	large: '569',
};

const computedImgSrcBg = computed(() => {
	if (isMobileViewport.value && props.mobileImgSrc) return props.mobileImgSrc;
	return props.desktopImgSrc;
});

const computedBgStyle = computed(() => {
	if (props.bgStyle === 'teal') return 'var(--secondary-teal-base)';
	return 'var(--primary-midnightgreen-dark)';
});

const aaFlag = computed(() => {
	return props.url ? 'enabled' : 'disabled';
});

const wrapperAttrs = computed(() => {
	if (props.url) {
		// <a> tag
		return {
			is: 'a',
			href: props.url,
			target: props.openNewTab ? '_blank' : '_self',
		};
	} else {
		// <div> tag
		return {
			is: 'div',
		};
	}
});

</script>



<template>
<component
	:is="props.url ? 'a' : 'div'"
	v-aa:[aaFlag]="[
		{
			clickName: keyVisualAlt,
			clickComponentType: 'URL',
			componentName: 'Banner',
			componentID: props.componentIdAA,
		},
		{
			name: keyVisualAlt,
			type: isUrlMatchCurrentHostname(props.url) ? 'other' : 'exit',
		},
	]"
	v-bind="wrapperAttrs"
	class="CrossSellBanner generic-container pt-6"
	:class="{ 'use-gradient-overlay-full-image': props.gradientValue }"
	:style="{
		'--BannerSizeDesktop': `${bannerSizeDesktopMap[props.desktopBannerHeight]}px`,
		'--BannerSizeMobile': `${bannerSizeMobileMap[props.mobileBannerHeight]}px`,
		'--batik-bg-colour': bgStyle ? computedBgStyle : 'var(--primary-midnightgreen-dark)',
	}"
>
	<div
		class="CrossSellBanner-inner"
		:class="{
			'flex-row-reverse': props.keyVisualAlignment === 'right',
			'!h-auto': isEditorMode,
		}"
		:style="{
			'flex-direction': props.keyVisualAlignment === 'right' ? 'row-reverse' : '',
		}"
	>
		<div class="img-key-visual relative z-30 mx-10 >md:mb-6">
			<img
				v-if="keyVisualSrc"
				:src="keyVisualSrc"
				:alt="keyVisualAlt"
			/>
		</div>
		<div
			class="banner-text-placeholder "
			:class="[
				props.keyVisualAlignment === 'right' ? 'ml-10' : 'mr-10',
			]"
		>
			<!-- <img
				class="w-[117px] mb-3"
				src="@/assets/logo-mh.svg"
				:alt="logoAlt"
			/>
			<h6 class="title >md:line-clamp-3">{{ title }}</h6>
			<p class="line-clamp-4">{{ description }}</p>
			<a class="cta-btn" :href="url" :target="openNewTab ? '_blank' : '_self'"><span>Learn More</span><icon-far-arrow-right class="cta-btn-arrow text-[16px] inline-block fill-white" /></a> -->
			<slot></slot>
		</div>
		<div class="absolute z-10 rounded-3xl overflow-hidden w-full h-full">
			<div
				v-if="gradientValue"
				class="absolute top-0 left-0 z-20 w-full h-full"
				:style="{
					backgroundImage: `linear-gradient(${computedGradientValue})`,
				}"
			></div>
			<div
				v-if="gradientValue"
				class="banner-background"
				:style="{
					'transform': `${ props.keyVisualAlignment === 'right' ? 'scaleX(-1)' : '' }`,
					...(computedImgSrcBg ? { 'background-image': `url(${computedImgSrcBg})` } : {}),
				}"
				:title="isMobileViewport ? mobileImageAltText : desktopImageAltText"
			>
				<span v-if="desktopImageAltText || mobileImageAltText" class="sr-only"> {{ $t('Image of banner') }}: <span v-if="desktopImageAltText" class="md:hidden">{{ desktopImageAltText }}</span> <span v-if="mobileImageAltText" class=">md:hidden">{{ mobileImageAltText }}</span></span>
			</div>
			<div
				v-if="bgStyle"
				class="absolute top-0 left-0 z-10 w-full h-full"
				:class="[
					bgStyle === 'blue' ? 'use-batik-pattern use-theme-blue' : 'use-batik-pattern use-theme-teal',
				]"
			></div>
		</div>
	</div>
</component>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.CrossSellBanner {
	text-decoration: none;
}
.CrossSellBanner-inner {
	height: var(--BannerSizeDesktop);
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	@apply rounded-3xl bg-gray-700;
	
	@media #{var.$query-max-md} {
		height: auto;
		min-height: var(--BannerSizeMobile);
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
	}
}

.use-gradient-overlay-full-image {
	.CrossSellBanner-inner {
		--mobileBgKeyVisualSize: 220px;
	}

	.banner-text-placeholder {
		width: calc(100% - 342px);
		position: relative;
		z-index: 30;
		color: white;
		@apply my-8;
		
		@media #{var.$query-max-md} {
			padding-top: var(--mobileBgKeyVisualSize);
			width: 100%;
			@apply mx-6 mt-0 mb-8;
		}
	}

	.banner-background {
		position: absolute;
		background-position: left center;
		top: 0;
		left: 0;
		z-index: 12;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		@apply transform;
		
		@media #{var.$query-max-md} {
			background-position: center;
			background-size: cover;
			height: calc(var(--mobileBgKeyVisualSize) + 1rem);
		}
	}
}

.img-key-visual {
	width: 342px;
	height: 342px;
	
	img {
		transform: translateY(-24px);
		width: 100%;
		@apply origin-top;
	}
	
	@media #{var.$query-max-md} {
		width: 100%;
		height: auto;
		
		img {
			width: 220px;
			@apply mx-auto;
		}
	}
}

.title {
	font-size: 32px;
	@apply leading-10 mb-4;
}

.cta-btn {
	transition: all ease 200ms;
	display: inline-block;
	@apply mt-6 mb-8;
	
	.cta-btn-arrow {
		transition: all ease 200ms;
		@apply ml-2;
	}
	
	&:hover {
		color: var(--primary-blue-extralight);
		
		.cta-btn-arrow {
			fill: var(--primary-blue-extralight);
			@apply ml-3;
		}
	}
}

.use-batik-pattern {
	&.use-theme-teal {
		position: relative;
		--batik-pattern-img: url('~/assets/batik-pattern.svg');
		background-color: var(--batik-bg-colour);
		background-image: var(--batik-pattern-img);
		background-repeat: round;
		
		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 80%;
			background-image: url('~/assets/batik-pattern-floral-2.svg');
			mix-blend-mode: luminosity;
			opacity: 0.12;
			@apply z-10;
		}
		&::before {
			top: 0;
		}
		&::after {
			left: -4%;
			bottom: -50%;
		}
	}
	
	&.use-theme-blue {
		background-color: var(--batik-bg-colour);
		
		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 204px;
			height: 183px;
			background-image: url('~/assets/batik-pattern-floral-1.svg');
			background-repeat: no-repeat;
			mix-blend-mode: luminosity;
			z-index: 10;
			opacity: 0.4;
			@apply transform;
		}
		&::before {
			top: 0;
			left: -2.5rem;
			@apply rotate-180;
		}
		&::after {
			bottom: 0;
			right: -2.5rem;
			@apply -rotate-45;
		}
	}
}

[data-use-theme="MAG"] {
	.use-batik-pattern {
		--batik-pattern-img: url('~/assets/batik-pattern.svg');
		
		&.use-theme-teal,
		&.use-theme-blue {
			&::before,
			&::after {
				content: none;
			}
		}
	}
}

.banner-text-placeholder {
	width: calc(100% - 342px);
	position: relative;
	z-index: 30;
	color: white;
	
	@media #{var.$query-max-md} {
		width: 100%;
		@apply mx-6;
	}
}

</style>
