import dayjs from 'dayjs';


/**
 * @param {int} The year, not zero based
 * @param {int} The month number, 0 based
 * @return {Date[]} List with date objects for each day of the month
 */
function _getDaysInMonth (year, month) {
	const date = new Date(year, month, 1);
	const days = [];
	while (date.getMonth() === month) {
		days.push(new Date(date));
		date.setDate(date.getDate() + 1);
	}
	return days;
}

function _parseYearSyntax (refYear, str, fallback) {
	let result = Number.NaN;
	
	if (!Number.isNaN(parseInt(str, 10))) {
		result = parseInt(str, 10);
		return result;
	}
	
	// relative value
	if (str.startsWith('t-')) {
		result = refYear - parseInt(str.replace(/^t-/, ''), 10);
	} else if (str.startsWith('t+')) {
		result = refYear + parseInt(str.replace(/^t\+/, ''), 10);
	} else if (str.startsWith('t')) {
		result = refYear;
	}
	
	if (Number.isNaN(result)) {
		if (fallback) {
			console.warn(`Unable to understand "${str}". Fallback to "${fallback}".`);
			result = _parseYearSyntax(refYear, fallback);
		} else {
			console.warn(`Unable to understand "${str}". No fallback given, defaults to reference year.`);
			result = refYear;
		}
	}
	
	return result;
}

function _createYearListArray (yearFrom, yearTo, inclusive = true) {
	if (inclusive) return Array.from({ length: yearTo - yearFrom + 1 }, (_, i) => i + yearFrom);
	// exclusive mode, meaning exclude the from year and to year itself
	return Array.from({ length: yearTo - yearFrom - 1 }, (_, i) => i + yearFrom + 1);
}


export function calendarDMY (currentViewDayJsRef, _options = {}) {
	const options = {
		computedMinDate: null,
		computedMaxDate: null,
		computedDisabledDates: null,
		checkIsDisabledFunc: () => false,
		..._options,
	};
	
	const _currentViewYearIndex = computed(() => {
		return unref(currentViewDayJsRef).year();
	});
	const _currentViewMonthIndex = computed(() => {
		return unref(currentViewDayJsRef).month();
	});
	/* const _currentViewDateIndex = computed(() => {
		return unref(currentViewDayJsRef).date();
	}); */
	
	const _dayjsToday = dayjs();
	
	const _days = computed(() => {
		const d = new Date();
		d.setFullYear(_currentViewYearIndex.value);
		d.setMonth(_currentViewMonthIndex.value);
		d.setDate(1);
		
		const result = _getDaysInMonth(_currentViewYearIndex.value, _currentViewMonthIndex.value);
		return result;
	});
	
	const _daysFrontPadding = computed(() => {
		const result = [];
		const dayOfFirst = _days.value[0].getDay();
		let count = dayOfFirst;
		while (count !== 0) {
			result.push(null);
			count--;
		}
		return result;
	});
	
	
	// exports below
	// --------------------------
	
	const dayList = computed(() => {
		return [
			..._daysFrontPadding.value,
			..._days.value,
		].map((dayItem, index) => {
			if (dayItem === null) return { isVoid: true };
			const _dayjsInstance = dayjs(dayItem);
			return {
				day: dayItem,
				dayjsInstance: _dayjsInstance,
				dateNumber: dayItem.getDate(),
				isToday: _dayjsInstance.isToday(),
				dataDate: _dayjsInstance.format('YYYY-MM-DD'),
				isDisabled: options.checkIsDisabledFunc.value(_dayjsInstance, 'day'),
			};
		});
	});
	
	const monthList = computed(() => {
		return dayjs.monthsShort().map((mName, index) => {
			const _dayjsInstance = dayjs(unref(currentViewDayJsRef)).year(_currentViewYearIndex.value).month(index).date(1);
			
			return {
				month: mName,
				monthNumber: index,
				dayjsInstance: _dayjsInstance,
				isToday: _dayjsInstance.isSame(_dayjsToday, 'month'),
				dataDate: _dayjsInstance.format('YYYY-MM-DD'),
				isDisabled: options.checkIsDisabledFunc.value(_dayjsInstance, 'month'),
			};
		});
	});
	
	const yearList = computed(() => {
		const yearOfToday = dayjs().year();
		
		const yearFrom = options.displayYear.value[0];
		const yearTo = options.displayYear.value[1];
		
		let finalYearFrom = _parseYearSyntax(yearOfToday, yearFrom, 't-120');
		let finalYearTo = _parseYearSyntax(yearOfToday, yearTo, 't+1');
		
		if (finalYearFrom > finalYearTo) {
			console.error(`"Year from" cannot be later than "year to". Forcing "Year from" to be "year to" minus 1.`);
			finalYearFrom = finalYearTo - 1;
		}
		
		const yearArr = _createYearListArray(finalYearFrom, finalYearTo);
		
		return yearArr.map((yearNumber, index) => {
			const _dayjsInstance = dayjs(unref(currentViewDayJsRef)).year(yearNumber).month(_currentViewMonthIndex.value).date(1);
			
			return {
				year: yearNumber,
				yearNumber: yearNumber,
				dayjsInstance: _dayjsInstance,
				isToday: _dayjsInstance.isSame(_dayjsToday, 'year'),
				dataDate: _dayjsInstance.format('YYYY-MM-DD'),
				isDisabled: options.checkIsDisabledFunc.value(_dayjsInstance, 'year'),
			};
		});
	});
	
	
	const currentViewMonthName = computed(() => {
		return unref(currentViewDayJsRef).format('MMMM');
	});
	const currentViewYearName = computed(() => {
		return unref(currentViewDayJsRef).format('YYYY');
	});
	
	return {
		currentViewMonthName,
		currentViewYearName,
		
		dayList,
		monthList,
		yearList,
	};
}
