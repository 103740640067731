<script>
export default {
	name: 'ImageComponent',
	editConfig: {
		emptyLabel: 'Image Component',
		isEmpty (props) {
			switch (props.imageType) {
				case 'icon':
					return !props.iconId;
				case 'image':
					return !props.fileURL;
				default:
					return !props.iconId && !props.fileURL;
			}
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';

const props = defineProps({
	imageType: { type: String, default: 'image' },
	fileURL: { type: String, default: '' },
	alt: { type: String, default: '' },
	isRoundedEdge: { type: Boolean, default: false },
	caption: { type: String, default: '' },
	imgURL: { type: String, default: '' },
	isNewTab: { type: Boolean, default: false },
	alignment: { type: String, default: 'left' },
	dimension: { type: String, default: 'original' },
	dimensionWidth: { type: String, default: '' },
	dimensionHeight: { type: String, default: '' },
	iconId: { type: String, default: '' },
	iconStyle: { type: String, default: 'regular' },
	iconColor: { type: String, default: '' },
	componentIdAA: { type: String, default: '' },
	
	desktopDimensionWidth: { type: String, default: '' }, // <-- for desktop icon width only
	desktopDimensionHeight: { type: String, default: '' }, // <-- for desktop icon height only
	
	mobileDimensionWidth: { type: String, default: '' }, // <-- for mobile icon width only
	mobileDimensionHeight: { type: String, default: '' }, // <-- for mobile icon height only
});

const manualDimension = computed(() => {
	if (props.dimension == 'manual' && props.imageType == 'image') {
		if (props.dimensionWidth && props.dimensionHeight) {
			return {
				width: `${props.dimensionWidth}px`,
				height: `${props.dimensionHeight}px`,
			};
		} else if (!props.dimensionHeight) {
			return {
				width: `${props.dimensionWidth}px`,
				height: 'auto',
			};
		}
	}
	return;
});

const iconDimension = computed(() => {
	
	const desktopDimensionWidth = !isNaN(props.desktopDimensionWidth) ? `${props.desktopDimensionWidth}px` : null;
	const desktopDimensionHeight = !isNaN(props.desktopDimensionHeight) ? `${props.desktopDimensionHeight}px` : null;
	
	const mobileDimensionWidth = !isNaN(props.mobileDimensionWidth) ?
		`${props.mobileDimensionWidth}px`
		: null;
	const mobileDimensionHeight = !isNaN(props.mobileDimensionHeight) ?
		`${props.mobileDimensionHeight}px`
		: null;
	
	if (!isMobileViewport.value) {
		return {
			width: desktopDimensionWidth,
			height: desktopDimensionHeight,
		};
	} else {
		return {
			width: mobileDimensionWidth,
			height: mobileDimensionHeight,
		};
	}
});

const iconStyle = ref({ fill: props.iconColor });

</script>

<template>
<div
	class="ImageComponent flex flex-col"
	:class="{
		'items-start': props.alignment == 'left' && props.imageType == 'image',
		'items-center': props.alignment == 'center' && props.imageType == 'image',
		'items-end': props.alignment == 'right' && props.imageType == 'image',
	}"
>
	<div
		v-if="props.imageType == 'image'"
		class="flex-col overflow-hidden"
		:class="{
			'inline-flex': props.dimension == 'original',
			'flex w-full': props.dimension == 'snap',
		}"
	>
		<AppHyperlink
			v-if="props.imgURL"
			v-aa="[
				{
					clickName: props.alt,
					clickComponentType: 'URL',
					componentName: 'ImageComponent',
					componentID: props.componentIdAA,
				},
				{
					name: props.alt,
					type: isUrlMatchCurrentHostname(props.imgURL) ? 'other' : 'exit',
				},
			]"
			:href="props.imgURL"
			:target="props.isNewTab ? '_blank' : '_self'"
		>
			<img
				:src="props.fileURL"
				:alt="props.alt"
				:class="{
					'rounded-3xl md:rounded-lg': props.isRoundedEdge,
					'w-full': props.dimension == 'snap',
				}"
				:style="manualDimension"
			/>
		</AppHyperlink>
		<img
			v-else
			:src="props.fileURL"
			:alt="props.alt"
			:class="{
				'rounded-3xl md:rounded-lg': props.isRoundedEdge,
				'w-full': props.dimension == 'snap',
			}"
			:style="manualDimension"
		/>
	</div>
	<p
		v-if="props.caption && props.imageType == 'image'"
		class="text-grey-ultradark font-sm mt-3"
		:class="{
			'text-center': props.alignment == 'center',
			'text-right': props.alignment == 'right',
		}"
	>
		{{ props.caption }}
	</p>
	<FontAwesomeLazy
		v-else
		class="icon w-full"
		:style="{
			...iconStyle,
			...iconDimension,
		}"
		:iconId="props.iconId"
		:iconStyle="props.iconStyle"
	/>

</div>

</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';
.ImageComponent {

}
</style>
