import { toRefs, getCurrentInstance, ref, computed, watch, nextTick, onMounted, Teleport, resolveDirective, openBlock, createElementBlock, Fragment, createCommentVNode, createElementVNode, mergeProps, withModifiers, normalizeClass, renderList, renderSlot, withKeys, createTextVNode, toDisplayString, withDirectives, createBlock, normalizeStyle } from 'vue';

function isNullish (val) {
	return [null, undefined].indexOf(val) !== -1;
}

function useData (props, context, dep) {
	const { object, valueProp, mode } = toRefs(props);

	const $this = getCurrentInstance().proxy;

	// ============ DEPENDENCIES ============

	const iv = dep.iv;

	// =============== METHODS ==============

	const update = (val) => {
		// Setting object(s) as internal value
		iv.value = makeInternal(val);

		// Setting object(s) or plain value as external 
		// value based on `option` setting
		const externalVal = makeExternal(val);

		context.emit('change', externalVal, $this);
		context.emit('input', externalVal);
		context.emit('update:modelValue', externalVal);
	};

	// no export
	const makeExternal = (val) => {
		// If external value should be object
		// no transformation is required
		if (object.value) {
			return val;
		}

		// No need to transform if empty value
		if (isNullish(val)) {
			return val;
		}

		// If external should be plain transform
		// value object to plain values
		return !Array.isArray(val) ? val[valueProp.value] : val.map((v) => v[valueProp.value]);
	};

	// no export
	const makeInternal = (val) => {
		if (isNullish(val)) {
			return mode.value === 'single' ? {} : [];
		}

		return val;
	};

	return {
		update,
	};
}

function useValue (props, context) {
	const { value, modelValue, mode, valueProp } = toRefs(props);

	// ================ DATA ================

	// internalValue
	const iv = ref(mode.value !== 'single' ? [] : {});

	// ============== COMPUTED ==============

	/* istanbul ignore next */
	// externalValue
	const ev = modelValue.value && modelValue.value !== undefined ? modelValue : value;

	const plainValue = computed(() => {
		return mode.value === 'single' ? iv.value[valueProp.value] : iv.value.map((v) => v[valueProp.value]);
	});

	const textValue = computed(() => {
		return mode.value !== 'single' ? iv.value.map((v) => v[valueProp.value]).join(',') : iv.value[valueProp.value];
	});

	return {
		iv,
		internalValue: iv,
		ev,
		externalValue: ev,
		textValue,
		plainValue,
	};
}

function useSearch (props, context, dep) {
	const { regex } = toRefs(props);

	const $this = getCurrentInstance().proxy;

	// ============ DEPENDENCIES ============

	const isOpen = dep.isOpen;
	const open = dep.open;

	// ================ DATA ================

	const search = ref(null);

	const input = ref(null);

	// =============== METHODS ==============

	const clearSearch = () => {
		search.value = '';
	};

	const handleSearchInput = (e) => {
		search.value = e.target.value;
	};

	const handleKeypress = (e) => {
		if (regex.value && regex.value) {
			let regexp = regex.value;

			if (typeof regexp === 'string') {
				regexp = new RegExp(regexp);
			}

			if (!e.key.match(regexp)) {
				e.preventDefault();
			}
		}
	};

	const handlePaste = (e) => {
		if (regex.value && regex.value) {
			let clipboardData = e.clipboardData || /* istanbul ignore next */ window.clipboardData;
			let pastedData = clipboardData.getData('Text');

			let regexp = regex.value;

			if (typeof regexp === 'string') {
				regexp = new RegExp(regexp);
			}
			
			if (!pastedData.split('').every((c) => !!c.match(regexp))) {
				e.preventDefault();
			}
		}

		context.emit('paste', e, $this);
	};

	// ============== WATCHERS ==============

	watch(search, (val) => {
		if (!isOpen.value && val) {
			open();
		}

		context.emit('search-change', val, $this);
	});

	return {
		search,
		input,
		clearSearch,
		handleSearchInput,
		handleKeypress,
		handlePaste,
	};
}

function usePointer$1 (props, context, dep) {
	const { groupSelect, mode, groups, disabledProp } = toRefs(props);

	// ================ DATA ================

	const pointer = ref(null);

	// =============== METHODS ==============

	const setPointer = (option) => {
		if (option === undefined || (option !== null && option[disabledProp.value])) {
			return;
		}

		if (groups.value && option && option.group && (mode.value === 'single' || !groupSelect.value)) {
			return;
		}

		pointer.value = option;
	};

	const clearPointer = () => {
		setPointer(null);
	};

	return {
		pointer,
		setPointer,
		clearPointer,
	};
}

function normalize (str, strict = true) {
	return strict
		? String(str).toLowerCase().trim()
		: String(str).normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase().trim();
}

function isObject (variable) {
	return Object.prototype.toString.call(variable) === '[object Object]';
}

function arraysEqual (array1, array2) {
	const array2Sorted = array2.slice().sort();

	return array1.length === array2.length && array1.slice().sort().every(function (value, index) {
		return value === array2Sorted[index];
	});
}

function useOptions (props, context, dep) {
	const {
		options, mode, trackBy: trackBy_, limit, hideSelected, createTag, createOption: createOption_, label,
		appendNewTag, appendNewOption: appendNewOption_, multipleLabel, object, loading, delay, resolveOnLoad,
		minChars, filterResults, clearOnSearch, clearOnSelect, valueProp,
		canDeselect, max, strict, closeOnSelect, groups: groupped, reverse, infinite,
		groupOptions, groupHideEmpty, groupSelect, onCreate, disabledProp, searchStart,
	} = toRefs(props);

	const $this = getCurrentInstance().proxy;

	// ============ DEPENDENCIES ============

	const iv = dep.iv;
	const ev = dep.ev;
	const search = dep.search;
	const clearSearch = dep.clearSearch;
	const update = dep.update;
	const pointer = dep.pointer;
	const clearPointer = dep.clearPointer;
	const focus = dep.focus;
	dep.activate;
	const deactivate = dep.deactivate;
	const close = dep.close;
	dep.multiselect;
	const input = dep.input;
	const focusRootWithoutOpen = dep.focusRootWithoutOpen;
	const focusInputWithoutOpen = dep.focusInputWithoutOpen;

	// ================ DATA ================

	// no export
	// appendedOptions
	const ap = ref([]);

	// no export
	// resolvedOptions
	const ro = ref([]);

	const resolving = ref(false);

	// no export
	const searchWatcher = ref(null);

	const offset = ref(infinite.value && limit.value === -1 ? 10 : limit.value);

	// ============== COMPUTED ==============

	// no export
	const createOption = computed(() => {
		return createTag.value || createOption_.value || false;
	});

	// no export
	const appendNewOption = computed(() => {
		if (appendNewTag.value !== undefined) {
			return appendNewTag.value;
		} else if (appendNewOption_.value !== undefined) {
			return appendNewOption_.value;
		}

		return true;
	});

	// no export
	// extendedOptions
	const eo = computed(() => {
		if (groupped.value) {
			let groups = ro.value || /* istanbul ignore next */ [];

			let eo = [];

			groups.forEach((group) => {
				optionsToArray(group[groupOptions.value]).forEach((option) => {
					eo.push({ ...option, ...(group[disabledProp.value] ? { [disabledProp.value]: true } : {}) });
				});
			});

			return eo;
		} else {
			let eo = optionsToArray(ro.value || /* istanbul ignore next */ []);

			if (ap.value.length) {
				eo = eo.concat(ap.value);
			}

			return eo;
		}
	});

	const fg = computed(() => {
		if (!groupped.value) {
			return [];
		}

		return filterGroups((ro.value || /* istanbul ignore next */ []).map((group, index) => {
			const arrayOptions = optionsToArray(group[groupOptions.value]);

			return {
				...group,
				index,
				group: true,
				[groupOptions.value]: filterOptions(arrayOptions, false).map((o) => ({ ...o, ...(group[disabledProp.value] ? { [disabledProp.value]: true } : {}) })),
				__VISIBLE__: filterOptions(arrayOptions).map((o) => ({ ...o, ...(group[disabledProp.value] ? { [disabledProp.value]: true } : {}) })),
			};
			// Difference between __VISIBLE__ and {groupOptions}: visible does not contain selected options when hideSelected=true
		}));
	});

	// preFilteredOptions
	const pfo = computed(() => {
		let options = eo.value;

		if (reverse.value) {
			options = options.reverse();
		}

		if (createdOption.value.length) {
			options = createdOption.value.concat(options);
		}

		return filterOptions(options);
	});

	// filteredOptions
	const fo = computed(() => {
		let options = pfo.value;

		if (offset.value > 0) {
			options = options.slice(0, offset.value);
		}

		return options;
	});

	const hasSelected = computed(() => {
		switch (mode.value) {
			case 'single':
				return !isNullish(iv.value[valueProp.value]);

			case 'multiple':
			case 'tags':
				return !isNullish(iv.value) && iv.value.length > 0;
		}
		
		return false;
	});

	const multipleLabelText = computed(() => {
		return multipleLabel.value !== undefined && multipleLabel.value !== undefined
			? multipleLabel.value(iv.value, $this)
			: (iv.value && iv.value.length > 1 ? `${iv.value.length} options selected` : `1 option selected`);
	});

	const noOptions = computed(() => {
		return !eo.value.length && !resolving.value && !createdOption.value.length;
	});


	const noResults = computed(() => {
		return eo.value.length > 0 && fo.value.length == 0 && ((search.value && groupped.value) || !groupped.value);
	});

	// no export
	const createdOption = computed(() => {
		if (createOption.value === false || !search.value) {
			return [];
		}

		return getOptionByTrackBy(search.value) !== -1 ? [] : [{
			[valueProp.value]: search.value,
			[label.value]: search.value,
			[trackBy.value]: search.value,
			__CREATE__: true,
		}];
	});

	const trackBy = computed(() => {
		return trackBy_.value || label.value;
	});

	// no export
	const nullValue = computed(() => {
		switch (mode.value) {
			case 'single':
				return null;

			case 'multiple':
			case 'tags':
				return [];
		}
		
		return undefined;
	});

	const busy = computed(() => {
		return loading.value || resolving.value;
	});

	// =============== METHODS ==============

	/**
   * @param {array|object|string|number} option 
   */
	const select = (_option) => {
		let option = _option;
		if (typeof option !== 'object') {
			option = getOption(option);
		}

		switch (mode.value) {
			case 'single':
				update(option);
				break;

			case 'multiple':
			case 'tags':
				update((iv.value).concat(option));
				break;
		}

		context.emit('select', finalValue(option), option, $this);
	};

	const deselect = (_option) => {
		let option = _option;
		if (typeof option !== 'object') {
			option = getOption(option);
		}

		switch (mode.value) {
			case 'single':
				clear();
				break;

			case 'tags':
			case 'multiple':
				update(Array.isArray(option)
					? iv.value.filter((v) => option.map((o) => o[valueProp.value]).indexOf(v[valueProp.value]) === -1)
					: iv.value.filter((v) => v[valueProp.value] != option[valueProp.value]));
				break;
		}

		context.emit('deselect', finalValue(option), option, $this);
	};

	// no export
	const finalValue = (option) => {
		return object.value ? option : option[valueProp.value];
	};

	const remove = (option) => {
		deselect(option);
	};

	const handleTagRemove = (option, e) => {
		if (e.button !== 0) {
			e.preventDefault();
			return;
		}

		remove(option);
	};

	const clear = () => {
		context.emit('clear', $this);
		update(nullValue.value);
	};

	const isSelected = (option) => {
		if (option.group !== undefined) {
			return mode.value === 'single' ? false : areAllSelected(option[groupOptions.value]) && option[groupOptions.value].length;
		}

		switch (mode.value) {
			case 'single':
				return !isNullish(iv.value) && iv.value[valueProp.value] == option[valueProp.value];

			case 'tags':
			case 'multiple':
				return !isNullish(iv.value) && iv.value.map((o) => o[valueProp.value]).indexOf(option[valueProp.value]) !== -1;
		}
	};

	const isDisabled = (option) => {
		return option[disabledProp.value] === true;
	};

	const isMax = () => {
		if (max.value === undefined || max.value === -1 || (!hasSelected.value && max.value > 0)) {
			return false;
		}
	
		return iv.value.length >= max.value;
	};

	const handleOptionClick = (_option) => {
		let option = _option;
		
		if (isDisabled(option)) {
			return;
		}

		if (onCreate.value && onCreate.value && !isSelected(option) && option.__CREATE__) {
			option = { ...option };
			delete option.__CREATE__;

			option = onCreate.value(option, $this);

			if (option instanceof Promise) {
				resolving.value = true;
				option.then((result) => {
					resolving.value = false;
					handleOptionSelect(result);
				});

				return;
			}
		}

		handleOptionSelect(option);
		if (input.value) {
			focusInputWithoutOpen();
		} else {
			focusRootWithoutOpen();
		}
	};

	const handleOptionSelect = (_option) => {
		let option = _option;
		
		if (option.__CREATE__) {
			option = { ...option };
			delete option.__CREATE__;
		}
	
		switch (mode.value) {
			case 'single':
				if (option && isSelected(option)) {
					if (canDeselect.value) {
						deselect(option);
					}
					return;
				}

				if (option) {
					handleOptionAppend(option);
				}

				/* istanbul ignore else */
				if (clearOnSelect.value) {
					clearSearch();
				}

				if (closeOnSelect.value) {
					clearPointer();
					close();
				}

				if (option) {
					select(option);
				}
				break;

			case 'multiple':
				if (option && isSelected(option)) {
					deselect(option);
					return;
				}

				if (isMax()) {
					return;
				}

				if (option) {
					handleOptionAppend(option);
					select(option);
				}

				if (clearOnSelect.value) {
					clearSearch();
				}

				if (hideSelected.value) {
					clearPointer();
				}

				if (closeOnSelect.value) {
					close();
				}
				break;

			case 'tags':
				if (option && isSelected(option)) {
					deselect(option);
					return;
				}

				if (isMax()) {
					return;
				}

				if (option) {
					handleOptionAppend(option);
				}

				if (clearOnSelect.value) {
					clearSearch();
				}

				if (option) {
					select(option);
				}

				if (hideSelected.value) {
					clearPointer();
				}

				if (closeOnSelect.value) {
					close();
				}
				break;
		}

		if (!closeOnSelect.value) {
			focus();
		}
	};

	const handleGroupClick = (group) => {
		if (isDisabled(group) || mode.value === 'single' || !groupSelect.value) {
			return;
		}

		switch (mode.value) {
			case 'multiple':
			case 'tags':
				if (areAllEnabledSelected(group[groupOptions.value])) {
					deselect(group[groupOptions.value]);
				} else {
					select(group[groupOptions.value]
						.filter((o) => iv.value.map((v) => v[valueProp.value]).indexOf(o[valueProp.value]) === -1)
						.filter((o) => !o[disabledProp.value])
						.filter((o, k) => iv.value.length + 1 + k <= max.value || max.value === -1)
					);
				}
				break;
		}

		if (closeOnSelect.value) {
			deactivate();
		}
	};

	const handleOptionAppend = (option) => {
		if (getOption(option[valueProp.value]) === undefined && createOption.value) {
			context.emit('tag', option[valueProp.value], $this);
			context.emit('option', option[valueProp.value], $this);

			if (appendNewOption.value) {
				appendOption(option);
			}

			clearSearch();
		}
	};

	const selectAll = () => {
		if (mode.value === 'single') {
			return;
		}

		select(fo.value);
	};

	// no export
	const areAllEnabledSelected = (options) => {
		return options.find((o) => !isSelected(o) && !o[disabledProp.value]) === undefined;
	};

	// no export
	const areAllSelected = (options) => {
		return options.find((o) => !isSelected(o)) === undefined;
	};

	const getOption = (val) => {
		return eo.value[eo.value.map((o) => String(o[valueProp.value])).indexOf(String(val))];
	};

	// no export
	const getOptionByTrackBy = (val, norm = true) => {
		return eo.value.map((o) => (parseInt(o[trackBy.value]) == o[trackBy.value] ? parseInt(o[trackBy.value]) : o[trackBy.value])).indexOf( parseInt(val) == val ? parseInt(val) : val );
	};

	// no export
	const shouldHideOption = (option) => {
		return ['tags', 'multiple'].indexOf(mode.value) !== -1 && hideSelected.value && isSelected(option);
	};

	// no export
	const appendOption = (option) => {
		ap.value.push(option);
	};

	// no export
	const filterGroups = (groups) => {
		// If the search has value we need to filter among 
		// he ones that are visible to the user to avoid
		// displaying groups which technically have options
		// based on search but that option is already selected.
		return groupHideEmpty.value
			? groups.filter((g) => (search.value
				? g.__VISIBLE__.length
				: g[groupOptions.value].length)
			)
		: groups.filter((g) => (search.value ? g.__VISIBLE__.length : true));
	};

	// no export
	const filterOptions = (options, excludeHideSelected = true) => {
		let fo = options;
	
		if (search.value && filterResults.value) {
			fo = fo.filter((option) => {
				return searchStart.value
					? normalize(option[trackBy.value], strict.value).startsWith(normalize(search.value, strict.value))
					: normalize(option[trackBy.value], strict.value).indexOf(normalize(search.value, strict.value)) !== -1;
			});
		}

		if (hideSelected.value && excludeHideSelected) {
			fo = fo.filter((option) => !shouldHideOption(option));
		}

		return fo;
	};

	// no export
	const optionsToArray = (options) => {
		let uo = options;
	
		// Transforming an object to an array of objects
		if (isObject(uo)) {
			uo = Object.keys(uo).map((key) => {
				let val = uo[key];

				return { [valueProp.value]: key, [trackBy.value]: val, [label.value]: val };
			});
		}

		// Transforming an plain arrays to an array of objects
		uo = uo.map((val) => {
			return typeof val === 'object' ? val : { [valueProp.value]: val, [trackBy.value]: val, [label.value]: val };
		});

		return uo;
	};

	// no export
	const initInternalValue = () => {
		if (!isNullish(ev.value)) {
			iv.value = makeInternal(ev.value);
		}
	};

	const resolveOptions = (callback) => {
		resolving.value = true;

		return new Promise((resolve, reject) => {
			options.value(search.value, $this).then((response) => {
				ro.value = response || [];

				if (typeof callback == 'function') {
					callback(response);
				}

				resolving.value = false;
			}).catch((e) => {
				console.error(e);

				ro.value = [];

				resolving.value = false;
			}).finally(() => {
				resolve();
			});
		});
	};

	// no export
	const refreshLabels = () => {
		if (!hasSelected.value) {
			return;
		}

		if (mode.value === 'single') {
			let option = getOption(iv.value[valueProp.value]);

			/* istanbul ignore else */
			if (option !== undefined) {
				let newLabel = option[label.value];

				iv.value[label.value] = newLabel;

				if (object.value && ev.value) {
					ev.value[label.value] = newLabel;
				}
			}
		} else {
			iv.value.forEach((val, i) => {
				let option = getOption(iv.value[i][valueProp.value]);

				/* istanbul ignore else */
				if (option !== undefined) {
					let newLabel = option[label.value];

					iv.value[i][label.value] = newLabel;

					if (object.value) {
						ev.value[i][label.value] = newLabel;
					}
				}
			});
		}
	};

	const refreshOptions = (callback) => {
		resolveOptions(callback);
	};

	// no export
	const makeInternal = (val) => {
		if (isNullish(val)) {
			return mode.value === 'single' ? {} : [];
		}

		if (object.value) {
			return val;
		}

		// If external should be plain transform
		// value object to plain values
		return mode.value === 'single' ? getOption(val) || {} : val.filter((v) => !!getOption(v)).map((v) => getOption(v));
	};

	// no export
	const initSearchWatcher = () => {
		searchWatcher.value = watch(search, (query) => {
			if (query.length < minChars.value || (!query && minChars.value !== 0)) {
				return;
			}

			resolving.value = true;

			if (clearOnSearch.value) {
				ro.value = [];
			}
			setTimeout(() => {
				if (query != search.value) {
					return;
				}

				options.value(search.value, $this).then((response) => {
					if (query == search.value || !search.value) {
						ro.value = response;
						pointer.value = fo.value.filter((o) => o[disabledProp.value] !== true)[0] || null;
						resolving.value = false;
					}
				}).catch( /* istanbul ignore next */ (e) => {
					console.error(e);
				});
			}, delay.value);

		}, { flush: 'sync' });
	};

	// ================ HOOKS ===============

	if (mode.value !== 'single' && !isNullish(ev.value) && !Array.isArray(ev.value)) {
		throw new Error(`v-model must be an array when using "${mode.value}" mode`);
	}

	if (options.value && typeof options.value == 'function') {
		if (resolveOnLoad.value) {
			resolveOptions(initInternalValue);
		} else if (object.value == true) {
			initInternalValue();
		}
	} else {
		ro.value = options.value;

		initInternalValue();
	}
  
	// ============== WATCHERS ==============

	if (delay.value > -1) {
		initSearchWatcher();
	}

	watch(delay, (value, old) => {
		/* istanbul ignore else */
		if (searchWatcher.value) {
			searchWatcher.value();
		}

		if (value >= 0) {
			initSearchWatcher();
		}
	});

	watch(ev, (newValue) => {
		if (isNullish(newValue)) {
			iv.value = makeInternal(newValue);
			return;
		}

		switch (mode.value) {
			case 'single':
				if (object.value ? newValue[valueProp.value] != iv.value[valueProp.value] : newValue != iv.value[valueProp.value]) {
					iv.value = makeInternal(newValue);
				}
				break;

			case 'multiple':
			case 'tags':
				if (!arraysEqual(object.value ? newValue.map((o) => o[valueProp.value]) : newValue, iv.value.map((o) => o[valueProp.value]))) {
					iv.value = makeInternal(newValue);
				}
				break;
		}
	}, { deep: true });

	watch(options, (n, o) => {
		if (typeof props.options === 'function') {
			if (resolveOnLoad.value && (!o || (n && n.toString() !== o.toString()))) {
				resolveOptions();
			}
		} else {
			ro.value = props.options;

			if (!Object.keys(iv.value).length) {
				initInternalValue();
			}

			refreshLabels();
		}
	});

	watch(label, refreshLabels);

	return {
		pfo,
		fo,
		filteredOptions: fo,
		hasSelected,
		multipleLabelText,
		eo,
		extendedOptions: eo,
		fg,
		filteredGroups: fg,
		noOptions,
		noResults,
		resolving,
		busy,
		offset,
		select,
		deselect,
		remove,
		selectAll,
		clear,
		isSelected,
		isDisabled,
		isMax,
		getOption,
		handleOptionClick,
		handleGroupClick,
		handleTagRemove,
		refreshOptions,
		resolveOptions,
		refreshLabels,
	};
}

function usePointer (props, context, dep) {
	const {
		valueProp, showOptions, searchable, groupLabel,
		groups: groupped, mode, groupSelect, disabledProp,
	} = toRefs(props);

	// ============ DEPENDENCIES ============

	const fo = dep.fo;
	const fg = dep.fg;
	const handleOptionClick = dep.handleOptionClick;
	const handleGroupClick = dep.handleGroupClick;
	const search = dep.search;
	const pointer = dep.pointer;
	const setPointer = dep.setPointer;
	const clearPointer = dep.clearPointer;
	const multiselect = dep.multiselect;
	const isOpen = dep.isOpen;
	const dropdownEl = dep.dropdownEl;

	// ============== COMPUTED ==============

	// no export
	const options = computed(() => {
		return fo.value.filter((o) => !o[disabledProp.value]);
	});

	const groups = computed(() => {
		return fg.value.filter((o) => !o[disabledProp.value]);
	});

	const canPointGroups = computed(() => {
		return mode.value !== 'single' && groupSelect.value;
	});

	const isPointerGroup = computed(() => {
		return pointer.value && pointer.value.group;
	});

	const currentGroup = computed(() => {
		return getParentGroup(pointer.value);
	});

	const prevGroup = computed(() => {
		const group = isPointerGroup.value ? pointer.value : /* istanbul ignore next */ getParentGroup(pointer.value);
		const groupIndex = groups.value.map((g) => g[groupLabel.value]).indexOf(group[groupLabel.value]);
		let prevGroup = groups.value[groupIndex - 1];

		if (prevGroup === undefined) {
			prevGroup = lastGroup.value;
		}

		return prevGroup;
	});
  
	const nextGroup = computed(() => {
		let nextIndex = groups.value.map((g) => g.label).indexOf(isPointerGroup.value
			? pointer.value[groupLabel.value]
			: getParentGroup(pointer.value)[groupLabel.value]) + 1;

		if (groups.value.length <= nextIndex) {
			nextIndex = 0;
		}

		return groups.value[nextIndex];
	});

	const lastGroup = computed(() => {
		return [...groups.value].slice(-1)[0];
	});
  
	const currentGroupFirstEnabledOption = computed(() => {
		return pointer.value.__VISIBLE__.filter((o) => !o[disabledProp.value])[0];
	});

	const currentGroupPrevEnabledOption = computed(() => {
		const options = currentGroup.value.__VISIBLE__.filter((o) => !o[disabledProp.value]);
		return options[options.map((o) => o[valueProp.value]).indexOf(pointer.value[valueProp.value]) - 1];
	});
  
	const currentGroupNextEnabledOption = computed(() => {
		const options = getParentGroup(pointer.value).__VISIBLE__.filter((o) => !o[disabledProp.value]);
		return options[options.map((o) => o[valueProp.value]).indexOf(pointer.value[valueProp.value]) + 1];
	});

	const prevGroupLastEnabledOption = computed(() => {
		return [...prevGroup.value.__VISIBLE__.filter((o) => !o[disabledProp.value])].slice(-1)[0];
	});

	const lastGroupLastEnabledOption = computed(() => {
		return [...lastGroup.value.__VISIBLE__.filter((o) => !o[disabledProp.value])].slice(-1)[0];
	});

	// =============== METHODS ==============

	const isPointed = (option) => {
		return (!!pointer.value && (
			(!option.group && pointer.value[valueProp.value] == option[valueProp.value]) ||
			(option.group !== undefined && pointer.value[groupLabel.value] == option[groupLabel.value])
		)) ? true : undefined;
	};

	const setPointerFirst = () => {
		setPointer(options.value[0] || null);
	};

	const selectPointer = () => {
		if (!pointer.value || pointer.value[disabledProp.value] === true) {
			return;
		}

		if (isPointerGroup.value) {
			handleGroupClick(pointer.value);
		} else {
			handleOptionClick(pointer.value);
		}
	};

	const forwardPointer = () => {
		if (pointer.value === null) {
			setPointer((groupped.value && canPointGroups.value ? groups.value[0] : options.value[0]) || null);
		} else if (groupped.value && canPointGroups.value) {
			let nextPointer = isPointerGroup.value ? currentGroupFirstEnabledOption.value : currentGroupNextEnabledOption.value;

			if (nextPointer === undefined) {
				nextPointer = nextGroup.value;
			}

			setPointer(nextPointer || /* istanbul ignore next */ null);
		} else {
			let next = options.value.map((o) => o[valueProp.value]).indexOf(pointer.value[valueProp.value]) + 1;

			if (options.value.length <= next) {
				next = 0;
			}

			setPointer(options.value[next] || null);
		}

		nextTick(() => {
			adjustWrapperScrollToPointer();
		});
	};

	const backwardPointer = () => {
		if (pointer.value === null) {
			let prevPointer = options.value[options.value.length - 1];

			if (groupped.value && canPointGroups.value) {
				prevPointer = lastGroupLastEnabledOption.value;

				if (prevPointer === undefined) {
					prevPointer = lastGroup.value;
				}
			}

			setPointer(prevPointer || null);
		} else if (groupped.value && canPointGroups.value) {
			let prevPointer = isPointerGroup.value ? prevGroupLastEnabledOption.value : currentGroupPrevEnabledOption.value;

			if (prevPointer === undefined) {
				prevPointer = isPointerGroup.value ? prevGroup.value : currentGroup.value;
			}

			setPointer(prevPointer || /* istanbul ignore next */ null);
		} else {
			let prevIndex = options.value.map((o) => o[valueProp.value]).indexOf(pointer.value[valueProp.value]) - 1;

			if (prevIndex < 0) {
				prevIndex = options.value.length - 1;
			}

			setPointer(options.value[prevIndex] || null);
		}

		nextTick(() => {
			adjustWrapperScrollToPointer();
		});
	};

	const getParentGroup = (option) => {
		return groups.value.find((group) => {
			return group.__VISIBLE__.map((o) => o[valueProp.value]).indexOf(option[valueProp.value]) !== -1;
		});
	};

	// no export
	/* istanbul ignore next */
	const adjustWrapperScrollToPointer = () => {
		let pointedOption = multiselect.value.querySelector(`[data-pointed]`);

		if (!pointedOption) {
			return;
		}

		let wrapper = pointedOption.parentElement.parentElement;

		if (groupped.value) {
			wrapper = isPointerGroup.value
		? pointedOption.parentElement.parentElement.parentElement
		: pointedOption.parentElement.parentElement.parentElement.parentElement;
		}

		if (pointedOption.offsetTop + pointedOption.offsetHeight > wrapper.clientHeight + wrapper.scrollTop) {
			wrapper.scrollTop = pointedOption.offsetTop + pointedOption.offsetHeight - wrapper.clientHeight;
		}
	
		if (pointedOption.offsetTop < wrapper.scrollTop) {
			wrapper.scrollTop = pointedOption.offsetTop;
		}
	};

	// ============== WATCHERS ==============

	watch(search, (val) => {
		if (searchable.value) {
			if (val.length && showOptions.value) {
				setPointerFirst();
			} else {
				clearPointer();
			}
		}
	});

	watch(isOpen, (val) => {
		if (val) {
			let firstSelected = multiselect.value.querySelectorAll(`[data-selected]`)[0];

			if (!firstSelected) {
				return;
			}

			let wrapper = firstSelected.parentElement.parentElement;
			
			nextTick(() => {
				/* istanbul ignore next */
				if (wrapper.scrollTop > 0) {
					return;
				}

				wrapper.scrollTop = firstSelected.offsetTop;
			});
		}
	});
	
	const scrollToPointer = () => {
		const el = dropdownEl.value.querySelector(`.multiselect-option[data-value="${pointer.value[valueProp.value]}"]`);
		
		// Alternative way to resolve scrollIntoView(), where the issue it does not scroll to selected option
		scrollToMiddleOfItem(el);
	};
	
	const scrollToMiddleOfItem = (targetElementOption) => {
		const dropdown = dropdownEl.value.querySelector('.multiselect-options');
		
		const itemOffsetTop = targetElementOption.offsetTop;
		const itemHeight = targetElementOption.clientHeight;
		const dropdownHeight = dropdown.clientHeight;
		
		// Calculate to get to middle of position of scroll container.
		const scrollTo = itemOffsetTop - (dropdownHeight / 2) + (itemHeight / 2);
		
		if (scrollTo < 0) {
			dropdown.scrollTop = 0;
			
		} else if (scrollTo > dropdown.scrollHeight) {
			dropdown.scrollTop = dropdown.scrollHeight;

		} else {
			dropdown.scrollTop = scrollTo;
		}
	};

	return {
		pointer,
		canPointGroups,
		isPointed,
		setPointerFirst,
		selectPointer,
		forwardPointer,
		backwardPointer,
		scrollToPointer,
	};
}

function useDropdown (props, context, dep) {
	const { disabled } = toRefs(props);

	const $this = getCurrentInstance().proxy;

	// ================ DATA ================

	const isOpen = ref(false);

	// =============== METHODS ==============

	const open = () => {
		if (isOpen.value || disabled.value) {
			return;
		}

		isOpen.value = true;
		context.emit('open', $this);
	};

	const close = () => {
		if (!isOpen.value) {
			return;
		}

		isOpen.value = false;
		context.emit('close', $this);
	};

	return {
		isOpen,
		open,
		close,
	};
}

function useMultiselect (props, context, dep) {
	const { searchable, disabled, clearOnBlur } = toRefs(props);


	// ============ DEPENDENCIES ============

	const input = dep.input;
	const open = dep.open;
	const close = dep.close;
	const clearSearch = dep.clearSearch;
	const isOpen = dep.isOpen;

	// ================ DATA ================

	const multiselect = ref(null);
	
	const dropdownEl = ref(null);

	const tags = ref(null);

	const isActive = ref(false);

	const mouseClicked = ref(false);
	let _nextFocusToNotOpen = false;

	// ============== COMPUTED ==============

	const tabindex = computed(() => {
		return searchable.value || disabled.value ? -1 : 0;
	});

	// =============== METHODS ==============

	const blur = () => {
		if (searchable.value) {
			input.value.blur();
		}

		multiselect.value.blur();
	};

	const focus = () => {
		if (searchable.value && !disabled.value) {
			input.value.focus();
		}
	};

	const activate = (shouldOpen = true) => {
		if (disabled.value) {
			return;
		}

		isActive.value = true;

		if (shouldOpen) {
			open();
		}
	};

	const deactivate = () => {
		isActive.value = false;

		setTimeout(() => {
			if (!isActive.value) {
				close();
				if (clearOnBlur.value) {
					clearSearch();
				}
			}
		}, 1);
	};

	const handleFocusIn = () => {
		if (_nextFocusToNotOpen) {
			_nextFocusToNotOpen = false;
			activate(false);
		} else {
			const shouldOpen = input.value ? mouseClicked.value : true;
			activate(shouldOpen);
		}
	};

	const handleFocusOut = () => {
		deactivate();
	};

	const handleCaretClick = () => {
		deactivate();
		blur();
	};

	/* istanbul ignore next */
	const handleMousedown = (e) => {
		mouseClicked.value = true;

		if (isOpen.value && (e.target.isEqualNode(multiselect.value) || e.target.isEqualNode(tags.value))) {
			setTimeout(() => {
				deactivate();
			}, 0);
		} else if (document.activeElement.isEqualNode(multiselect.value) && !isOpen.value) {
			activate();
		}

		setTimeout(() => {
			mouseClicked.value = false;
		}, 0);
	};
	
	const handleInputMousedown = (event) => {
		mouseClicked.value = true;

		if (isOpen.value) {
			setTimeout(() => {
				deactivate();
			}, 0);
		} else if (!isOpen.value) {
			activate();
		}

		setTimeout(() => {
			mouseClicked.value = false;
		}, 0);
	};
	
	const focusRootWithoutOpen = () => {
		_nextFocusToNotOpen = true;
		multiselect.value?.focus();
	};
	const focusInputWithoutOpen = () => {
		_nextFocusToNotOpen = true;
		input.value?.focus();
	};

	return {
		multiselect,
		dropdownEl,
		tags,
		tabindex,
		isActive,
		mouseClicked,
		blur,
		focus,
		activate,
		deactivate,
		handleFocusIn,
		handleFocusOut,
		handleCaretClick,
		handleMousedown,
		handleInputMousedown,
		
		focusRootWithoutOpen,
		focusInputWithoutOpen,
	};
}

function useKeyboard (props, context, dep) {
	const {
		mode, addTagOn, openDirection, searchable,
		showOptions, valueProp, groups: groupped,
		addOptionOn: addOptionOn_, createTag, createOption: createOption_,
		reverse,
	} = toRefs(props);

	const $this = getCurrentInstance().proxy;

	// ============ DEPENDENCIES ============

	const iv = dep.iv;
	const update = dep.update;
	const search = dep.search;
	const setPointer = dep.setPointer;
	const selectPointer = dep.selectPointer;
	const backwardPointer = dep.backwardPointer;
	const forwardPointer = dep.forwardPointer;
	const scrollToPointer = dep.scrollToPointer;
	const multiselect = dep.multiselect;
	const tags = dep.tags;
	const isOpen = dep.isOpen;
	const open = dep.open;
	const blur = dep.blur;
	const fo = dep.fo;
	const noOptions = dep.noOptions;

	// ============== COMPUTED ==============

	// no export
	const createOption = computed(() => {
		return createTag.value || createOption_.value || false;
	});

	// no export
	const addOptionOn = computed(() => {
		if (addTagOn.value !== undefined) {
			return addTagOn.value;
		} else if (addOptionOn_.value !== undefined) {
			return addOptionOn_.value;
		}

		return ['enter'];
	});

	// =============== METHODS ==============

	// no export
	const preparePointer = () => {
		// When options are hidden and creating tags is allowed
		// no pointer will be set (because options are hidden).
		// In such case we need to set the pointer manually to the 
		// first option, which equals to the option created from
		// the search value.
		if (mode.value === 'tags' && !showOptions.value && createOption.value && searchable.value && !groupped.value) {
			setPointer(fo.value[fo.value.map((o) => o[valueProp.value]).indexOf(search.value)]);
		}
	};

	const handleKeydown = (e) => {
		context.emit('keydown', e, $this);

		let tagList;
		let activeIndex;

		if (['ArrowLeft', 'ArrowRight', 'Enter'].indexOf(e.key) !== -1 && mode.value === 'tags') {
			tagList = [...(multiselect.value.querySelectorAll(`[data-tags] > *`))].filter((e) => e !== tags.value);
			activeIndex = tagList.findIndex((e) => e === document.activeElement);
		}

		switch (e.key) {
			case 'Backspace':
				if (mode.value === 'single') {
					return;
				}

				if (searchable.value && [null, ''].indexOf(search.value) === -1) {
					return;
				}

				if (iv.value.length === 0) {
					return;
				}
		
				update([...iv.value].slice(0, -1));
				break;

			case 'Enter':
				if (!noOptions.value) {
					e.preventDefault();
				}

				if (activeIndex !== -1 && activeIndex !== undefined) {
					update([...iv.value].filter((v, k) => k !== activeIndex));

					if (activeIndex === tagList.length - 1) {
						if (tagList.length - 1) {
							tagList[tagList.length - 2].focus();
						} else if (searchable.value) {
							tags.value.querySelector('input').focus();
						} else {
							multiselect.value.focus();
						}
					}
					return;
				}

				if (addOptionOn.value.indexOf('enter') === -1 && createOption.value) {
					return;
				}
		
				preparePointer();
				selectPointer();
				break;

			case ' ':
				if (!createOption.value && !searchable.value) {
					if (!noOptions.value) {
						e.preventDefault();
					}
					
					preparePointer();
					selectPointer();
					return;
				}

				if (!createOption.value) {
					return false;
				}

				if (addOptionOn.value.indexOf('space') === -1 && createOption.value) {
					return;
				}

				e.preventDefault();
		
				preparePointer();
				selectPointer();
				break;
				
			case 'Tab':
			case ';':
			case ',':
				if (addOptionOn.value.indexOf(e.key.toLowerCase()) === -1 || !createOption.value) {
					return;
				}

				preparePointer();
				selectPointer();
				e.preventDefault();
				break;

			case 'Escape':
				blur();
				break;

			case 'ArrowUp':
				e.preventDefault();

				if (!showOptions.value) {
					return;
				}

				/* istanbul ignore else */
				if (!isOpen.value) {
					open();
				}
		
				backwardPointer();
				scrollToPointer();
				break;

			case 'ArrowDown':
				e.preventDefault();

				if (!showOptions.value) {
					return;
				}

				/* istanbul ignore else */
				if (!isOpen.value) {
					open();
				}

				forwardPointer();
				scrollToPointer();
				break;

			case 'ArrowLeft':
				if ((searchable.value && tags.value.querySelector('input').selectionStart) || e.shiftKey || mode.value !== 'tags' || !iv.value || !iv.value.length) {
					return;
				}

				e.preventDefault();

				if (activeIndex === -1) {
					tagList[tagList.length - 1].focus();
				} else if (activeIndex > 0) {
					tagList[activeIndex - 1].focus();
				}
				break;

			case 'ArrowRight':
				if (activeIndex === -1 || e.shiftKey || mode.value !== 'tags' || !iv.value || !iv.value.length) {
					return;
				}

				e.preventDefault();
		
				/* istanbul ignore else */
				if (tagList.length > activeIndex + 1) {
					tagList[activeIndex + 1].focus();
				} else if (searchable.value) {
					tags.value.querySelector('input').focus();
				} else if (!searchable.value) {
					multiselect.value.focus();
				}
		
				break;
		}
	};

	const handleKeyup = (e) => {
		context.emit('keyup', e, $this);
	};

	return {
		handleKeydown,
		handleKeyup,
		preparePointer,
	};
}

function useClasses (props, context, dependencies) {
	const {
		classes: classes_, disabled, openDirection, showOptions,
	} = toRefs(props);

	// ============ DEPENDENCIES ============

	const isOpen = dependencies.isOpen;
	const isPointed = dependencies.isPointed;
	const isSelected = dependencies.isSelected;
	const isDisabled = dependencies.isDisabled;
	const isActive = dependencies.isActive;
	const canPointGroups = dependencies.canPointGroups;
	const resolving = dependencies.resolving;
	const fo = dependencies.fo;

	const classes = computed(() => ({
		container: 'multiselect',
		containerDisabled: 'is-disabled',
		containerOpen: 'is-open',
		containerOpenTop: 'is-open-top',
		containerActive: 'is-active',
		singleLabel: 'multiselect-single-label',
		singleLabelText: 'multiselect-single-label-text',
		multipleLabel: 'multiselect-multiple-label',
		search: 'multiselect-search',
		tags: 'multiselect-tags',
		tag: 'multiselect-tag',
		tagDisabled: 'is-disabled',
		tagRemove: 'multiselect-tag-remove',
		tagRemoveIcon: 'multiselect-tag-remove-icon',
		tagsSearchWrapper: 'multiselect-tags-search-wrapper',
		tagsSearch: 'multiselect-tags-search',
		tagsSearchCopy: 'multiselect-tags-search-copy',
		placeholder: 'multiselect-placeholder',
		caret: 'multiselect-caret',
		caretOpen: 'is-open',
		clear: 'multiselect-clear',
		clearIcon: 'multiselect-clear-icon',
		spinner: 'multiselect-spinner',
		inifinite: 'multiselect-inifite',
		inifiniteSpinner: 'multiselect-inifite-spinner',
		dropdown: 'multiselect-dropdown',
		dropdownTop: 'is-top',
		dropdownHidden: 'is-hidden',
		options: 'multiselect-options',
		optionsTop: 'is-top',
		group: 'multiselect-group',
		groupLabel: 'multiselect-group-label',
		groupLabelPointable: 'is-pointable',
		groupLabelPointed: 'is-pointed',
		groupLabelSelected: 'is-selected',
		groupLabelDisabled: 'is-disabled',
		groupLabelSelectedPointed: 'is-selected is-pointed',
		groupLabelSelectedDisabled: 'is-selected is-disabled',
		groupOptions: 'multiselect-group-options',
		option: 'multiselect-option',
		optionPointed: 'is-pointed',
		optionSelected: 'is-selected',
		optionDisabled: 'is-disabled',
		optionSelectedPointed: 'is-selected is-pointed',
		optionSelectedDisabled: 'is-selected is-disabled',
		noOptions: 'multiselect-no-options',
		noResults: 'multiselect-no-results',
		fakeInput: 'multiselect-fake-input',
		spacer: 'multiselect-spacer',
		...classes_.value,
	}));

	// ============== COMPUTED ==============

	const showDropdown = computed(() => {
		return !!(isOpen.value && showOptions.value && (!resolving.value || (resolving.value && fo.value.length)));
	});

	const classList = computed(() => {
		const c = classes.value;

		return {
			container: [c.container]
				.concat(disabled.value ? c.containerDisabled : [])
				.concat(showDropdown.value && openDirection.value === 'top' ? c.containerOpenTop : [])
				.concat(showDropdown.value && openDirection.value !== 'top' ? c.containerOpen : [])
				.concat(isActive.value ? c.containerActive : []),
			spacer: c.spacer,
			singleLabel: c.singleLabel,
			singleLabelText: c.singleLabelText,
			multipleLabel: c.multipleLabel,
			search: c.search,
			tags: c.tags,
			tag: [c.tag]
				.concat(disabled.value ? c.tagDisabled : []),
			tagRemove: c.tagRemove,
			tagRemoveIcon: c.tagRemoveIcon,
			tagsSearchWrapper: c.tagsSearchWrapper,
			tagsSearch: c.tagsSearch,
			tagsSearchCopy: c.tagsSearchCopy,
			placeholder: c.placeholder,
			caret: [c.caret]
				.concat(isOpen.value ? c.caretOpen : []),
			clear: c.clear,
			clearIcon: c.clearIcon,
			spinner: c.spinner,
			inifinite: c.inifinite,
			inifiniteSpinner: c.inifiniteSpinner,
			dropdown: [c.dropdown]
				.concat(openDirection.value === 'top' ? c.dropdownTop : [])
				.concat(!isOpen.value || !showOptions.value || !showDropdown.value ? c.dropdownHidden : []),
			options: [c.options]
				.concat(openDirection.value === 'top' ? c.optionsTop : []),
			group: c.group,
			groupLabel: (g) => {
				let groupLabel = [c.groupLabel];

				if (isPointed(g)) {
					groupLabel.push(isSelected(g) ? c.groupLabelSelectedPointed : c.groupLabelPointed);
				} else if (isSelected(g) && canPointGroups.value) {
					groupLabel.push(isDisabled(g) ? c.groupLabelSelectedDisabled : c.groupLabelSelected);
				} else if (isDisabled(g)) {
					groupLabel.push(c.groupLabelDisabled);
				}

				if (canPointGroups.value) {
					groupLabel.push(c.groupLabelPointable);
				}

				return groupLabel;
			},
			groupOptions: c.groupOptions,
			option: (o, g) => {
				let option = [c.option];

				if (isPointed(o)) {
					option.push(isSelected(o) ? c.optionSelectedPointed : c.optionPointed);
				} else if (isSelected(o)) {
					option.push(isDisabled(o) ? c.optionSelectedDisabled : c.optionSelected);
				} else if (isDisabled(o) || (g && isDisabled(g))) {
					option.push(c.optionDisabled);
				}

				return option;
			},
			noOptions: c.noOptions,
			noResults: c.noResults,
			fakeInput: c.fakeInput,
		};
	});

	return {
		classList,
		showDropdown,
	};
}

function useScroll$1 (props, context, dep) {
	const {
		limit, infinite,
	} = toRefs(props);

	// ============ DEPENDENCIES ============

	const isOpen = dep.isOpen;
	const offset = dep.offset;
	const search = dep.search;
	const pfo = dep.pfo;
	const eo = dep.eo;

	// ================ DATA ================

	// no export
	const observer = ref(null);

	const infiniteLoader = ref(null);

	// ============== COMPUTED ==============

	const hasMore = computed(() => {
		return offset.value < pfo.value.length;
	});

	// =============== METHODS ==============

	// no export
	/* istanbul ignore next */
	const handleIntersectionObserver = (entries) => {
		const { isIntersecting, target } = entries[0];

		if (isIntersecting) {
			const parent = target.offsetParent;
			const scrollTop = parent.scrollTop;

			offset.value += limit.value == -1 ? 10 : limit.value;

			nextTick(() => {
				parent.scrollTop = scrollTop;
			});
		}
	};

	const observe = () => {
		/* istanbul ignore else */
		if (isOpen.value && offset.value < pfo.value.length) {
			observer.value.observe(infiniteLoader.value);
		} else if (!isOpen.value && observer.value) {
			observer.value.disconnect();
		}
	};

	// ============== WATCHERS ==============

	watch(isOpen, () => {
		if (!infinite.value) {
			return;
		}

		observe();
	});

	watch(search, () => {
		if (!infinite.value) {
			return;
		}

		offset.value = limit.value;

		observe();
	}, { flush: 'post' });

	watch(eo, () => {
		if (!infinite.value) {
			return;
		}

		observe();
	}, { immediate: false, flush: 'post' });

	// ================ HOOKS ===============

	onMounted(() => {
		/* istanbul ignore else */
		if (window && window.IntersectionObserver) {
			observer.value = new IntersectionObserver(handleIntersectionObserver);
		}
	});

	return {
		hasMore,
		infiniteLoader,
	};
}

function useScroll (props, context, dep) {
	const { placeholder, id, valueProp, label: labelProp, mode, groupLabel } = toRefs(props);

	// ============ DEPENDENCIES ============

	const pointer = dep.pointer;
	dep.iv;
	dep.hasSelected;
	dep.multipleLabelText;
	dep.isOpen;

	// ================ DATA ================

	const label = ref(null);
	const randomNumber = Math.round(Math.random() * 100000000);

	// ============== COMPUTED ==============

	const ariaOwns = computed(() => {
		let texts = [];

		if (id.value && id.value) {
			texts.push(id.value);
		} else {
			texts.push(String(randomNumber));
		}

		texts.push('multiselect-options');

		return texts.join('-');
	});

	const ariaActiveDescendant = computed(() => {
		let texts = [];

		if (id.value && id.value) {
			texts.push(id.value);
		}

		if (pointer.value) {
			texts.push(pointer.value.group ? 'multiselect-group' : 'multiselect-option');

			texts.push(pointer.value.group ? pointer.value.index : pointer.value[valueProp.value]);

			return texts.join('-');
		}
		
		return undefined;
	});



	const ariaPlaceholder = computed(() => {
		return placeholder.value;
	});

	const ariaMultiselectable = computed(() => {
		return mode.value !== 'single';
	});

	// =============== METHODS ==============

	const ariaOptionId = (option) => {
		let texts = [];

		if (id.value && id.value) {
			texts.push(id.value);
		}

		texts.push('multiselect-option');

		texts.push(option[valueProp.value]);

		return texts.join('-');
	};

	const ariaGroupId = (option) => {
		let texts = [];

		if (id.value && id.value) {
			texts.push(id.value);
		}

		texts.push('multiselect-group');

		texts.push(option.index);

		return texts.join('-');
	};

	const ariaOptionLabel = (option) => {
		let texts = [];

		texts.push(option[labelProp.value]);

		return texts.join(' ');
	};

	const ariaGroupLabel = (group) => {
		let texts = [];

		texts.push(group[groupLabel.value]);

		return texts.join(' ');
	};

	const ariaTagLabel = (label) => {
		return `${label} ❎`;
	};

	// =============== HOOKS ================

	onMounted(() => {
		/* istanbul ignore next */
		if (id.value && id.value && document && document.querySelector) {
			let forTag = document.querySelector(`[for="${id.value}"]`);
			label.value = forTag ? forTag.innerText : null;
		}
	});

	return {
		ariaOwns,
		ariaPlaceholder,
		ariaMultiselectable,
		ariaActiveDescendant,
		ariaOptionId,
		ariaOptionLabel,
		ariaGroupId,
		ariaGroupLabel,
		ariaTagLabel,
	};
}

/**
 * !!! Adapted !!!
 * Returns the amount of pixels for any unit (for e.g. em, rem, etc...)
 */
// https://stackoverflow.com/questions/10463518/converting-em-to-px-in-javascript-and-getting-default-font-size
const getComputedSizeToPixel = ({ cssValue = '1em', parent = document.body, axis = 'y' } = {}) => {
	const tempEl = document.createElement('div');
	tempEl.style.visibility = 'hidden';
	tempEl.style.boxSize = 'content-box';
	tempEl.style.position = 'absolute';
	tempEl.style.maxHeight = 'none';
	tempEl.style.maxWidth = 'none';
	
	if (axis === 'x') {
		tempEl.style.width = cssValue;
	} else if (axis === 'y') {
		tempEl.style.height = cssValue;
	}
	parent.appendChild(tempEl);
	let result;
	
	if (axis === 'x') {
		result = tempEl.clientWidth;
	} else if (axis === 'y') {
		result = tempEl.clientHeight;
	}
	tempEl.remove();
	return result;
};



/*
	extraOffset = {
		left: any number value from 0 - 1,
		top: any number value from 0 - 1,
	}
	
	Plan is to enhance this to:
	extraOffset = {
		left: '100%',
		top: '0%',
	}
	extraOffset = {
		left: '30px',
		top: '100px',
	}
	extraOffset = {
		left: '100% + 2px',
		top: '50% + 20px',
	}
	
*/

const getElOffset = (el, extraOffset = {}) => {
	const box = el.getBoundingClientRect();
	
	const left = box.left + window.pageXOffset - document.documentElement.clientLeft;
	const top = box.top + window.pageYOffset - document.documentElement.clientTop;
	
	const offsetXInPixel = getComputedSizeToPixel({
		cssValue: extraOffset.x ?? '0',
		parent: extraOffset.el || el,
		axis: 'x',
	});
	const offsetYInPixel = getComputedSizeToPixel({
		cssValue: extraOffset.y ?? '0',
		parent: extraOffset.el || el,
		axis: 'y',
	});
	
	const finalX = left + offsetXInPixel;
	const finalY = top + offsetYInPixel;
	
	return {
		x: finalX,
		y: finalY,
	};
};

function useTeleport (props, context, dep) {
	// const { useTeleport: isUsingTeleport } = toRefs(props);

	// ============ DEPENDENCIES ============

	dep.input;
	const multiselect = dep.multiselect;
	dep.dropdownEl;

	const getTeleportElStyle = () => {
		console.warn('Note: Mouse interaction when `props.useTeleport` is true is broken. Do not use.');
		const multiselectEl = multiselect.value;
		if (!multiselectEl) return {};
		
		const offset = getElOffset(multiselectEl, {
			y: 'calc(100% - 8px)',
		});
		
		return {
			transform: `translate(${offset.x}px, ${offset.y}px)`,
			width: `${multiselectEl.offsetWidth ?? 0}px`,
			
			/* 
				The problem is once dropdown has been teleported to under body, all inheritance of the var() are gone.
				
				Use this method to copy the var of the parent to dropdown child.
			*/
			...Object.fromEntries([
				'--ms-dropdown-border-color',
				'--ms-dropdown-border-width',
				'--ms-dropdown-radius',
				
				'--ms-option-bg-pointed',
				'--ms-option-color-pointed',
				'--ms-option-bg-selected',
				'--ms-option-bg-selected-pointed',
			].map((property) => {
				return [
					property,
					window.getComputedStyle(multiselectEl).getPropertyValue(property),
				];
			})),
		};
	};
	

	return {
		getTeleportElStyle,
	};
}

function resolveDeps (props, context, features, deps = {}) {
	features.forEach((composable) => {
		/* istanbul ignore else */
		if (composable) {
			// eslint-disable-next-line
			deps = {
				...deps,
				...composable(props, context, deps),
			};
		}

	});
	
	return deps;
}

/* 
	Note: This component was heavily customized for MH.
	It also has a dependancy on our custom directive, "v-html-sanitize".
*/

var script = {
	name: 'Multiselect',
	components: {
		Teleport,
	},
	props: {
		value: {
			type: [String, Object, Array],
			required: false,
			default: null,
		},
		modelValue: {
			type: [String, Object, Array],
			required: false,
			default: null,
		},
		options: {
			type: [Array, Object, Function],
			required: false,
			default: () => ([]),
		},
		id: {
			type: [String, Number],
			required: false,
			default: null,
		},
		name: {
			type: [String, Number],
			required: false,
			default: 'multiselect',
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
		label: {
			type: String,
			required: false,
			default: 'label',
		},
		trackBy: {
			type: String,
			required: false,
			default: undefined,
		},
		valueProp: {
			type: String,
			required: false,
			default: 'value',
		},
		placeholder: {
			type: String,
			required: false,
			default: null,
		},
		mode: {
			type: String,
			required: false,
			default: 'single', // single|multiple|tags
		},
		searchable: {
			type: Boolean,
			required: false,
			default: false,
		},
		limit: {
			type: Number,
			required: false,
			default: -1,
		},
		hideSelected: {
			type: Boolean,
			required: false,
			default: true,
		},
		createTag: {
			type: Boolean,
			required: false,
			default: undefined,
		},
		createOption: {
			type: Boolean,
			required: false,
			default: undefined,
		},
		appendNewTag: {
			type: Boolean,
			required: false,
			default: undefined,
		},
		appendNewOption: {
			type: Boolean,
			required: false,
			default: undefined,
		},
		addTagOn: {
			type: Array,
			required: false,
			default: undefined,
		},
		addOptionOn: {
			type: Array,
			required: false,
			default: undefined,
		},
		caret: {
			type: Boolean,
			required: false,
			default: true,
		},
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		noOptionsText: {
			type: String,
			required: false,
			default: 'The list is empty',
		},
		noResultsText: {
			type: String,
			required: false,
			default: 'No results found',
		},
		multipleLabel: {
			type: Function,
			required: false,
			default: null,
		},
		object: {
			type: Boolean,
			required: false,
			default: false,
		},
		delay: {
			type: Number,
			required: false,
			default: -1,
		},
		minChars: {
			type: Number,
			required: false,
			default: 0,
		},
		resolveOnLoad: {
			type: Boolean,
			required: false,
			default: true,
		},
		filterResults: {
			type: Boolean,
			required: false,
			default: true,
		},
		clearOnSearch: {
			type: Boolean,
			required: false,
			default: false,
		},
		clearOnSelect: {
			type: Boolean,
			required: false,
			default: true,
		},
		canDeselect: {
			type: Boolean,
			required: false,
			default: true,
		},
		canClear: {
			type: Boolean,
			required: false,
			default: true,
		},
		max: {
			type: Number,
			required: false,
			default: -1,
		},
		showOptions: {
			type: Boolean,
			required: false,
			default: true,
		},
		required: {
			type: Boolean,
			required: false,
			default: false,
		},
		openDirection: {
			type: String,
			required: false,
			default: 'bottom',
		},
		nativeSupport: {
			type: Boolean,
			required: false,
			default: false,
		},
		classes: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		strict: {
			type: Boolean,
			required: false,
			default: true,
		},
		closeOnSelect: {
			type: Boolean,
			required: false,
			default: true,
		},
		autocomplete: {
			type: String,
			required: false,
			default: null,
		},
		groups: {
			type: Boolean,
			required: false,
			default: false,
		},
		groupLabel: {
			type: String,
			required: false,
			default: 'label',
		},
		groupOptions: {
			type: String,
			required: false,
			default: 'options',
		},
		groupHideEmpty: {
			type: Boolean,
			required: false,
			default: false,
		},
		groupSelect: {
			type: Boolean,
			required: false,
			default: true,
		},
		inputType: {
			type: String,
			required: false,
			default: 'text',
		},
		attrs: {
			required: false,
			type: Object,
			default: () => ({}),
		},
		onCreate: {
			required: false,
			type: Function,
			default: null,
		},
		disabledProp: {
			type: String,
			required: false,
			default: 'disabled',
		},
		searchStart: {
			type: Boolean,
			required: false,
			default: false,
		},
		reverse: {
			type: Boolean,
			required: false,
			default: false,
		},
		regex: {
			type: [Object, String, RegExp],
			required: false,
			default: undefined,
		},
		rtl: {
			type: Boolean,
			required: false,
			default: false,
		},
		infinite: {
			type: Boolean,
			required: false,
			default: false,
		},
		aria: {
			required: false,
			type: Object,
			default: () => ({}),
		},
		// Note: Mouse interaction when `useTeleport` is true is broken. Do not use.
		useTeleport: {
			required: false,
			type: Boolean,
			default: false,
		},
		clearOnBlur: {
			required: false,
			type: Boolean,
			default: true,
		},
	},
	emits: [
		'paste', 'open', 'close', 'select', 'deselect',
		'input', 'search-change', 'tag', 'option', 'update:modelValue',
		'change', 'clear', 'keydown', 'keyup',
	],
	setup (props, context) {
		return {
			...resolveDeps(props, context, [
				useValue,
				usePointer$1,
				useDropdown,
				useSearch,
				useData,
				useMultiselect,
				useOptions,
				useScroll$1,
				usePointer,
				useKeyboard,
				useClasses,
				useScroll,
				useTeleport,
			]),
		};
	},
	// declare expose
	// expose: [],
};

const _hoisted_1 = ["id", "tabindex", "dir", "aria-owns", "aria-placeholder", "aria-expanded", "aria-activedescendant", "aria-multiselectable", "role"];
const _hoisted_2 = { class: "multiselect-search-wrapper flex items-center absolute w-full h-full" };
const _hoisted_3 = ["id", "type", "modelValue", "value", "autocomplete", "aria-owns", "aria-placeholder", "aria-expanded", "aria-activedescendant", "aria-multiselectable"];
const _hoisted_4 = ["aria-label", "onKeyup"];
const _hoisted_5 = ["onClick"];
const _hoisted_6 = ["id", "type", "modelValue", "value", "autocomplete", "aria-owns", "aria-placeholder", "aria-expanded", "aria-activedescendant", "aria-multiselectable"];
const _hoisted_7 = ["id"];
const _hoisted_8 = ["id", "aria-label", "aria-selected"];
const _hoisted_9 = ["data-pointed", "onMouseenter", "onClick"];
const _hoisted_10 = ["aria-label"];
const _hoisted_11 = ["id", "data-pointed", "data-selected", "aria-selected", "aria-label", "data-value", "onMouseenter", "onClick"];
const _hoisted_12 = ["id", "data-pointed", "data-selected", "aria-selected", "aria-label", "data-value", "onMouseenter", "onClick"];
const _hoisted_13 = ["value"];
const _hoisted_14 = ["name", "value"];
const _hoisted_15 = ["name", "value"];

function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_html_sanitize = resolveDirective("html-sanitize");

  return (openBlock(), createElementBlock(Fragment, null, [
    createCommentVNode(" eslint-disable "),
    createElementVNode("div", mergeProps({
      id: $props.searchable ? undefined : $props.id,
      ref: "multiselect",
      tabindex: _ctx.tabindex,
      class: _ctx.classList.container,
      dir: $props.rtl ? 'rtl' : undefined,
      "aria-owns": !$props.searchable ? _ctx.ariaOwns : undefined,
    //   "aria-placeholder": !$props.searchable ? _ctx.ariaPlaceholder : undefined,
      "aria-expanded": !$props.searchable ? _ctx.isOpen : undefined,
      "aria-activedescendant": !$props.searchable ? _ctx.ariaActiveDescendant : undefined,
    //   "aria-multiselectable": !$props.searchable ? _ctx.ariaMultiselectable : undefined,
      role: !$props.searchable ? 'combobox' : undefined
    }, {
		...(!$props.searchable ? $props.aria : {}),
		..._ctx.$attrs,
	}, {
      onFocusin: _cache[10] || (_cache[10] = (...args) => (_ctx.handleFocusIn && _ctx.handleFocusIn(...args))),
      onFocusout: _cache[11] || (_cache[11] = (...args) => (_ctx.handleFocusOut && _ctx.handleFocusOut(...args))),
      onKeydown: _cache[12] || (_cache[12] = (...args) => (_ctx.handleKeydown && _ctx.handleKeydown(...args))),
      onKeyup: _cache[13] || (_cache[13] = (...args) => (_ctx.handleKeyup && _ctx.handleKeyup(...args))),
      onMousedown: _cache[14] || (_cache[14] = (...args) => (_ctx.handleMousedown && _ctx.handleMousedown(...args)))
    }), [
      createCommentVNode(" Search "),
      createElementVNode("div", _hoisted_2, [
        ($props.mode !== 'tags' && $props.searchable && !$props.disabled)
          ? (openBlock(), createElementBlock("input", mergeProps({
              key: 0,
              id: $props.searchable ? $props.id : undefined,
              ref: "input",
              type: $props.inputType,
              modelValue: _ctx.search,
              value: _ctx.search,
              class: _ctx.classList.search,
              autocomplete: $props.autocomplete,
              "aria-owns": _ctx.ariaOwns,
            //   "aria-placeholder": _ctx.ariaPlaceholder,
              "aria-expanded": _ctx.isOpen,
              "aria-activedescendant": _ctx.ariaActiveDescendant,
            //   "aria-multiselectable": _ctx.ariaMultiselectable,
              role: "combobox"
            }, {
					...$props.attrs,
					...$props.aria,
				}, {
              onInput: _cache[0] || (_cache[0] = (...args) => (_ctx.handleSearchInput && _ctx.handleSearchInput(...args))),
              onMousedown: _cache[1] || (_cache[1] = withModifiers((...args) => (_ctx.handleInputMousedown && _ctx.handleInputMousedown(...args)), ["stop"])),
              onKeypress: _cache[2] || (_cache[2] = (...args) => (_ctx.handleKeypress && _ctx.handleKeypress(...args))),
              onPaste: _cache[3] || (_cache[3] = withModifiers((...args) => (_ctx.handlePaste && _ctx.handlePaste(...args)), ["stop"]))
            }), null, 16 /* FULL_PROPS */, _hoisted_3))
          : createCommentVNode("v-if", true),
        createCommentVNode(" Tags (with search) "),
        ($props.mode == 'tags')
          ? (openBlock(), createElementBlock("div", {
              key: 1,
              class: normalizeClass(_ctx.classList.tags),
              "data-tags": ""
            }, [
              (openBlock(true), createElementBlock(Fragment, null, renderList(_ctx.iv, (option, i, key) => {
                return renderSlot(_ctx.$slots, "tag", {
                  option: option,
                  handleTagRemove: _ctx.handleTagRemove,
                  disabled: $props.disabled
                }, () => [
                  (openBlock(), createElementBlock("span", {
                    key: key,
                    class: normalizeClass(_ctx.classList.tag),
                    tabindex: "-1",
                    "aria-label": _ctx.ariaTagLabel(option[$props.label]),
                    onKeyup: withKeys($event => (_ctx.handleTagRemove(option, $event)), ["enter"])
                  }, [
                    createTextVNode(toDisplayString(option[$props.label]) + " ", 1 /* TEXT */),
                    (!$props.disabled)
                      ? (openBlock(), createElementBlock("span", {
                          key: 0,
                          class: normalizeClass(_ctx.classList.tagRemove),
                          onClick: $event => (_ctx.handleTagRemove(option, $event))
                        }, [
                          createElementVNode("span", {
                            class: normalizeClass(_ctx.classList.tagRemoveIcon)
                          }, null, 2 /* CLASS */)
                        ], 10 /* CLASS, PROPS */, _hoisted_5))
                      : createCommentVNode("v-if", true)
                  ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_4))
                ])
              }), 256 /* UNKEYED_FRAGMENT */)),
              createElementVNode("div", {
                ref: "tags",
                class: normalizeClass(_ctx.classList.tagsSearchWrapper)
              }, [
                createCommentVNode(" Used for measuring search width "),
                createElementVNode("span", {
                  class: normalizeClass(_ctx.classList.tagsSearchCopy)
                }, toDisplayString(_ctx.search), 3 /* TEXT, CLASS */),
                createCommentVNode(" Actual search input "),
                ($props.searchable && !$props.disabled)
                  ? (openBlock(), createElementBlock("input", mergeProps({
                      key: 0,
                      id: $props.searchable ? $props.id : undefined,
                      ref: "input",
                      type: $props.inputType,
                      modelValue: _ctx.search,
                      value: _ctx.search,
                      class: _ctx.classList.tagsSearch,
                      autocomplete: $props.autocomplete,
                      "aria-owns": _ctx.ariaOwns,
                    //   "aria-placeholder": _ctx.ariaPlaceholder,
                      "aria-expanded": _ctx.isOpen,
                      "aria-activedescendant": _ctx.ariaActiveDescendant,
                    //   "aria-multiselectable": _ctx.ariaMultiselectable,
                      role: "combobox"
                    }, {
							...$props.attrs,
							...$props.aria,
						}, {
                      onInput: _cache[4] || (_cache[4] = (...args) => (_ctx.handleSearchInput && _ctx.handleSearchInput(...args))),
                      onKeypress: _cache[5] || (_cache[5] = (...args) => (_ctx.handleKeypress && _ctx.handleKeypress(...args))),
                      onPaste: _cache[6] || (_cache[6] = withModifiers((...args) => (_ctx.handlePaste && _ctx.handlePaste(...args)), ["stop"]))
                    }), null, 16 /* FULL_PROPS */, _hoisted_6))
                  : createCommentVNode("v-if", true)
              ], 2 /* CLASS */)
            ], 2 /* CLASS */))
          : createCommentVNode("v-if", true),
        createCommentVNode(" Single label "),
        ($props.mode == 'single' && _ctx.hasSelected && !_ctx.search && _ctx.iv)
          ? renderSlot(_ctx.$slots, "singlelabel", {
              key: 2,
              value: _ctx.iv
            }, () => [
              createElementVNode("div", {
                class: normalizeClass(_ctx.classList.singleLabel),
                "aria-hidden": "true"
              }, [
                withDirectives(createElementVNode("span", {
                  class: normalizeClass(_ctx.classList.singleLabelText)
                }, null, 2 /* CLASS */), [
                  [_directive_html_sanitize, _ctx.iv[$props.label]]
                ])
              ], 2 /* CLASS */)
            ])
          : createCommentVNode("v-if", true),
        createCommentVNode(" Multiple label "),
        ($props.mode == 'multiple' && _ctx.hasSelected && !_ctx.search)
          ? renderSlot(_ctx.$slots, "multiplelabel", {
              key: 3,
              values: _ctx.iv
            }, () => [
              withDirectives(createElementVNode("div", {
                class: normalizeClass(_ctx.classList.multipleLabel),
                "aria-hidden": "true"
              }, null, 2 /* CLASS */), [
                [_directive_html_sanitize, _ctx.multipleLabelText]
              ])
            ])
          : createCommentVNode("v-if", true),
        createCommentVNode(" Placeholder "),
        ($props.placeholder && !_ctx.hasSelected && !_ctx.search)
          ? renderSlot(_ctx.$slots, "placeholder", { key: 4 }, () => [
              createElementVNode("div", {
                class: normalizeClass(_ctx.classList.placeholder),
                "aria-hidden": "true"
              }, toDisplayString($props.placeholder), 3 /* TEXT, CLASS */)
            ])
          : createCommentVNode("v-if", true),
        createCommentVNode(" Spinner "),
        ($props.loading || _ctx.resolving)
          ? renderSlot(_ctx.$slots, "spinner", { key: 5 }, () => [
              createElementVNode("span", {
                class: normalizeClass(_ctx.classList.spinner),
                "aria-hidden": "true"
              }, null, 2 /* CLASS */)
            ])
          : createCommentVNode("v-if", true),
        createCommentVNode(" Clear "),
        (_ctx.hasSelected && !$props.disabled && $props.canClear && !_ctx.busy)
          ? renderSlot(_ctx.$slots, "clear", {
              key: 6,
              clear: _ctx.clear
            }, () => [
              createElementVNode("span", {
                tabindex: "0",
                role: "button",
                "aria-label": "❎",
                class: normalizeClass(_ctx.classList.clear),
                onClick: _cache[7] || (_cache[7] = (...args) => (_ctx.clear && _ctx.clear(...args))),
                onKeyup: _cache[8] || (_cache[8] = withKeys((...args) => (_ctx.clear && _ctx.clear(...args)), ["enter"]))
              }, [
                createElementVNode("span", {
                  class: normalizeClass(_ctx.classList.clearIcon)
                }, null, 2 /* CLASS */)
              ], 34 /* CLASS, HYDRATE_EVENTS */)
            ])
          : createCommentVNode("v-if", true),
        createCommentVNode(" Caret "),
        ($props.caret && $props.showOptions)
          ? renderSlot(_ctx.$slots, "caret", { key: 7 }, () => [
              createElementVNode("span", {
                class: normalizeClass(_ctx.classList.caret),
                "aria-hidden": "true",
                onClick: _cache[9] || (_cache[9] = (...args) => (_ctx.handleCaretClick && _ctx.handleCaretClick(...args)))
              }, null, 2 /* CLASS */)
            ])
          : createCommentVNode("v-if", true)
      ]),
      createCommentVNode(" Options "),
      (openBlock(), createBlock(Teleport, {
        to: "body",
        disabled: !$props.useTeleport
      }, [
        createElementVNode("div", {
          ref: "dropdownEl",
          class: normalizeClass(
			[
				..._ctx.classList.dropdown,
				{
					'is-teleported': $props.useTeleport,
					'is-no-options': _ctx.noOptions,
				},
			]),
          style: normalizeStyle($props.useTeleport ? _ctx.getTeleportElStyle() : null),
          tabindex: "-1"
        }, [
          renderSlot(_ctx.$slots, "beforelist", { options: _ctx.fo }),
          createElementVNode("ul", {
            id: _ctx.ariaOwns,
            class: normalizeClass(_ctx.classList.options)
          }, [
            renderSlot(_ctx.$slots, "prependToList"),
            ($props.groups)
              ? (openBlock(true), createElementBlock(Fragment, { key: 0 }, renderList(_ctx.fg, (group, i, key) => {
                  return (openBlock(), createElementBlock("li", {
                    id: _ctx.ariaGroupId(group),
                    key: key,
                    class: normalizeClass(_ctx.classList.group),
                    "aria-label": _ctx.ariaGroupLabel(group),
                    "aria-selected": _ctx.isSelected(group),
                    role: "option"
                  }, [
                    createElementVNode("div", {
                      class: normalizeClass(_ctx.classList.groupLabel(group)),
                      "data-pointed": _ctx.isPointed(group),
                      onMouseenter: $event => (_ctx.setPointer(group, i)),
                      onClick: $event => (_ctx.handleGroupClick(group))
                    }, [
                      renderSlot(_ctx.$slots, "grouplabel", {
                        group: group,
                        isSelected: _ctx.isSelected,
                        isPointed: _ctx.isPointed
                      }, () => [
                        withDirectives(createElementVNode("span", null, null, 512 /* NEED_PATCH */), [
                          [_directive_html_sanitize, group[$props.groupLabel]]
                        ])
                      ])
                    ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_9),
                    createElementVNode("ul", {
                      class: normalizeClass(_ctx.classList.groupOptions),
                      "aria-label": _ctx.ariaGroupLabel(group),
                      role: "group"
                    }, [
                      (openBlock(true), createElementBlock(Fragment, null, renderList(group.__VISIBLE__, (option, i, key) => {
                        return (openBlock(), createElementBlock("li", {
                          id: _ctx.ariaOptionId(option),
                          key: key,
                          class: normalizeClass(_ctx.classList.option(option, group)),
                          "data-pointed": _ctx.isPointed(option),
                          "data-selected": _ctx.isSelected(option) || undefined,
                          "aria-selected": _ctx.isSelected(option),
                          "aria-label": _ctx.ariaOptionLabel(option),
                          "data-value": option[$props.valueProp],
                          role: "option",
                          onMouseenter: $event => (_ctx.setPointer(option)),
                          onClick: $event => (_ctx.handleOptionClick(option))
                        }, [
                          renderSlot(_ctx.$slots, "option", {
                            option: option,
                            isSelected: _ctx.isSelected,
                            isPointed: _ctx.isPointed,
                            search: _ctx.search
                          }, () => [
                            withDirectives(createElementVNode("span", null, null, 512 /* NEED_PATCH */), [
                              [_directive_html_sanitize, option[$props.label]]
                            ])
                          ])
                        ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_11))
                      }), 128 /* KEYED_FRAGMENT */))
                    ], 10 /* CLASS, PROPS */, _hoisted_10)
                  ], 10 /* CLASS, PROPS */, _hoisted_8))
                }), 128 /* KEYED_FRAGMENT */))
              : (openBlock(true), createElementBlock(Fragment, { key: 1 }, renderList(_ctx.fo, (option, i, key) => {
                  return (openBlock(), createElementBlock("li", {
                    id: _ctx.ariaOptionId(option),
                    key: key,
                    class: normalizeClass(_ctx.classList.option(option)),
                    "data-pointed": _ctx.isPointed(option),
                    "data-selected": _ctx.isSelected(option) || undefined,
                    "aria-selected": _ctx.isSelected(option),
                    "aria-label": _ctx.ariaOptionLabel(option),
                    "data-value": option[$props.valueProp],
                    role: "option",
                    onMouseenter: $event => (_ctx.setPointer(option)),
                    onClick: $event => (_ctx.handleOptionClick(option))
                  }, [
                    renderSlot(_ctx.$slots, "option", {
                      option: option,
                      isSelected: _ctx.isSelected,
                      isPointed: _ctx.isPointed,
                      search: _ctx.search
                    }, () => [
                      withDirectives(createElementVNode("span", null, null, 512 /* NEED_PATCH */), [
                        [_directive_html_sanitize, option[$props.label]]
                      ])
                    ])
                  ], 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_12))
                }), 128 /* KEYED_FRAGMENT */)),
            renderSlot(_ctx.$slots, "appendToList")
          ], 10 /* CLASS, PROPS */, _hoisted_7),
          (_ctx.noOptions)
            ? renderSlot(_ctx.$slots, "nooptions", { key: 0 }, () => [
                withDirectives(createElementVNode("div", {
                  class: normalizeClass(_ctx.classList.noOptions)
                }, null, 2 /* CLASS */), [
                  [_directive_html_sanitize, $props.noOptionsText]
                ])
              ])
            : createCommentVNode("v-if", true),
          (_ctx.noResults)
            ? renderSlot(_ctx.$slots, "noresults", { key: 1 }, () => [
                withDirectives(createElementVNode("div", {
                  class: normalizeClass(_ctx.classList.noResults)
                }, null, 2 /* CLASS */), [
                  [_directive_html_sanitize, $props.noResultsText]
                ])
              ])
            : createCommentVNode("v-if", true),
          ($props.infinite && _ctx.hasMore)
            ? (openBlock(), createElementBlock("div", {
                key: 2,
                ref: "infiniteLoader",
                class: normalizeClass(_ctx.classList.inifinite)
              }, [
                renderSlot(_ctx.$slots, "infinite", {}, () => [
                  createElementVNode("span", {
                    class: normalizeClass(_ctx.classList.inifiniteSpinner)
                  }, null, 2 /* CLASS */)
                ])
              ], 2 /* CLASS */))
            : createCommentVNode("v-if", true),
          renderSlot(_ctx.$slots, "afterlist", { options: _ctx.fo })
        ], 6 /* CLASS, STYLE */)
      ], 8 /* PROPS */, ["disabled"])),
      createCommentVNode(" Hacky input element to show HTML5 required warning "),
      ($props.required)
        ? (openBlock(), createElementBlock("input", {
            key: 0,
            class: normalizeClass(_ctx.classList.fakeInput),
            tabindex: "-1",
            value: _ctx.textValue,
            required: ""
          }, null, 10 /* CLASS, PROPS */, _hoisted_13))
        : createCommentVNode("v-if", true),
      createCommentVNode(" Native input support "),
      ($props.nativeSupport)
        ? (openBlock(), createElementBlock(Fragment, { key: 1 }, [
            ($props.mode == 'single')
              ? (openBlock(), createElementBlock("input", {
                  key: 0,
                  type: "hidden",
                  name: $props.name,
                  value: _ctx.plainValue !== undefined ? _ctx.plainValue : ''
                }, null, 8 /* PROPS */, _hoisted_14))
              : (openBlock(true), createElementBlock(Fragment, { key: 1 }, renderList(_ctx.plainValue, (v, i) => {
                  return (openBlock(), createElementBlock("input", {
                    key: i,
                    type: "hidden",
                    name: `${$props.name}[]`,
                    value: v
                  }, null, 8 /* PROPS */, _hoisted_15))
                }), 128 /* KEYED_FRAGMENT */))
          ], 64 /* STABLE_FRAGMENT */))
        : createCommentVNode("v-if", true),
      createCommentVNode(" Create height for empty input "),
      createElementVNode("div", {
        class: normalizeClass(_ctx.classList.spacer)
      }, null, 2 /* CLASS */)
    ], 16 /* FULL_PROPS */, _hoisted_1)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}

script.render = render;
script.__file = "src/Multiselect.vue";

export { script as default };
