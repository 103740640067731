import { getCurrentInstance } from 'vue';
import { useField } from 'vee-validate';
import yup from 'mh-yup';

export default function useFieldValidation (options, _validatorOptions = {}) {
	const instance = getCurrentInstance();
	// const emit = instance.emit;
	// const props = instance.props;
	
	const {
		name,
		validation,
		modelValue,
		valueTransform = (v) => v,
	} = options;
	
	const validatorOptions = {
		bails: false,
		initialValue: modelValue.value,
		..._validatorOptions,
	};
	
	const {
		meta,
		errors,
		value,
		setTouched,
		handleChange,
		validate,
		setErrors,
		resetField,
	} = useField(name, ((typeof validation === 'function') ? validation : validation.value), validatorOptions);
	
	watch(modelValue, (newValue) => {
		const transformed = valueTransform(newValue);
		if (transformed !== value.value) handleChange(valueTransform(newValue));
	});
	
	const hasValidationError = computed(() => errors.value.length > 0);
	
	const isRequired = computed(() => {
		return validation.value?.spec?.presence === 'required';
	});
	
	const onInput = (event) => {
		const newValue = valueTransform(event.target.value);
		if (unref(name)) {
			// has name prop, proceed as usual
			handleChange(newValue);
			setTouched(true);
		} else {
			// no name prop, meaning no validation is needed
			instance.emit('update:modelValue', newValue);
		}
	};
	const onCheckboxChange = (event) => {
		const newValue = valueTransform(event.target.checked);
		handleChange(newValue);
		setTouched(true);
	};
	
	if (validatorOptions.initialValue !== value.value) {
		// bug with vee-validate's useField not respecting initialValue
		// manually set it to the correct value
		handleChange(validatorOptions.initialValue);
	}
	
	const internalValue = computed({
		get () {
			value.value; // eslint-disable-line
			instance.props.modelValue; // eslint-disable-line
			
			if (unref(name)) {
				// has name prop, proceed as usual
				return value.value;
			} else {
				// no name prop, meaning no validation is needed
				return instance.props.modelValue;
			}
		},
		set (newValue) {
			const newValueTransformed = valueTransform(newValue);
			if (unref(name)) {
				// has name prop, proceed as usual
				handleChange(newValueTransformed);
				setTouched(true);
			} else {
				// no name prop, meaning value is handled here
				instance.emit('update:modelValue', newValueTransformed);
			}
		},
	});
	
	return {
		onInput,
		onCheckboxChange,
		
		isRequired,
		hasValidationError,
		
		meta,
		errors,
		setTouched,
		validate,
		setErrors,
		resetField,
		
		internalValue,
	};
}
