
export const getCookie = (cname) => {
	let name = `${ cname }=`;
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

export const setCookie = (cname, cvalue, expires, path = '/') => {
	/* var d = new Date();
	var midnight = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59); // set hours to today's 11.59pm
	var expires = `expires=${ midnight.toUTCString()}`; */
	document.cookie = `${ cname }=${ cvalue };expires=${ expires };path=${ path }`;
};


export const deleteCookie = (cname) => {
	document.cookie = `${ cname }=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

	if (cname === 'sapphire') {
		document.cookie = `${ cname }=${ getCookie(cname) };domain=.malaysiaairlines.com;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
	}
};