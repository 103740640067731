<script>
export default {
	name: 'HeaderBST',
};
</script>

<script setup>
import { useHeaderFooterStore } from '~/logic/header-footer/header-footer-store.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';

const props = defineProps({
	// modelValue: { type: String, default: '' },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const { header: headerStore } = useHeaderFooterStore();

const siteName = window.siteName;

</script>

<template>
<header
	class="HeaderBST"
	:data-use-theme="siteName"
>
	<div class="generic-container flex">
		<!-- Markup structure: copy pasta from AppHeader. Any changes there need to reflect here. -->
		<div class="logo-container flex items-center xl:gap-4 gap-8 py-2">
			<div
				v-if="(isMobileViewport && !headerStore.hideInMobileLogo1) || (!isMobileViewport && !headerStore.hideInDesktopLogo1)"
			>
				<AppHyperlink
					:href="headerStore.logo1DarkUrl"
					:target="headerStore.openInNewTabLogo1Dark ? '_blank' : '_self'"
					:showExternalLinkIcon="false"
					rel="noopener noreferrer"
					class="is-dark-variant rounded-md"
				>
					<img
						class="logo-primary-img"
						:src="headerStore.logo1Dark"
						:alt="headerStore.logo1DarkAtlText"
					/>
				</AppHyperlink>
			</div>
			<div
				v-if="(isMobileViewport && !headerStore.hideInMobileLogo2) || (!isMobileViewport && !headerStore.hideInDesktopLogo2)"
			>
				<AppHyperlink
					:href="headerStore.logo2DarkUrl"
					:target="headerStore.openInNewTabLogo2Dark ? '_blank' : '_self'"
					:showExternalLinkIcon="false"
					rel="noopener noreferrer"
					class="is-dark-variant lg:hidden rounded-md"
				>
					<img
						class="logo-secondary-img"
						:src="headerStore.logo2Dark"
						:alt="headerStore.logo2DarkAtlText"
					/>
				</AppHyperlink>
			</div>
		</div>
	</div>
</header>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.HeaderBST {
	border-bottom: 2px solid var(--neutral-grey-light);
	height: var(--headerHeight);
	display: flex;
}

.logo-primary-img {
	width: 144px;

	@media #{var.$query-max-lg} {
		width: 98px;
	}
}
.logo-secondary-img {
	width: 40px;

	@media #{var.$query-max-lg} {
		display: none !important;
	}
}


[data-use-theme="amal"] {
	.logo-primary-img {
		width: 77px;

		@media #{var.$query-max-lg} {
			width: 60px;
		}
	}
}
</style>
