<script>
export default {
	name: 'FireflyHiddenSeamlessBookingWidget',
	inheritAttrs: false,
};
</script>

<script setup>
import FireflyFlightSearch from '~/aem-components/firefly/booking/FireflyFlightSearch.vue';
import FireflyAirCargo from '~/aem-components/firefly/booking/FireflyAirCargo.vue';
import FireflyManageBooking from '~/aem-components/firefly/booking/FireflyManageBooking.vue';
import FireflyCheckIn from '~/aem-components/firefly/booking/FireflyCheckIn.vue';
import axios from 'axios';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { waitFor } from '~/logic/helpers/utils.js';

const flightSearchPropsFromAPI = ref(null);
const airCargoSearchPropsFromAPI = ref(null);
const manageBookingPropsFromAPI = ref(null);
const checkInPropsFromAPI = ref(null);




const bookingWidgetArrayList = [
	{
		componentName: 'flight-search',
		dataRef: flightSearchPropsFromAPI,
	},
	{
		componentName: 'air-cargo',
		dataRef: airCargoSearchPropsFromAPI,
	},
	{
		componentName: 'manage-booking',
		dataRef: manageBookingPropsFromAPI,
	},
	{
		componentName: 'check-in',
		dataRef: checkInPropsFromAPI,
	},
];

// Methods definitions

function transformObjectProps (keyProps = 'isHideInDesktop', dataProps = {}) {
	const dataPropsKey = Object.keys(dataProps);

	for (const key of dataPropsKey) {
	
		if (Object.hasOwn(dataProps, keyProps)) {
			dataProps[keyProps] = true;
		}
		
		if (typeof dataProps[key] === 'object') {
			transformObjectProps(keyProps, dataProps[key]);
		}
	}
	
	return dataProps;
}

const setupProps = async (subBookingWidgetArray = []) => {
	const { pageProperties } = useGlobalAEMState();
	const country = pageProperties.value.rootCountry;
	const language = pageProperties.value.rootLanguage;
	
	try {
		
		subBookingWidgetArray.forEach(async (item, index) => {
			const resp = await axios.get(`/content/firefly/${country}/${language}/home/jcr:content/root/embedded_booking_widget/${item.componentName}.model.json`);
			item.dataRef.value = resp.data;
		});
		
		
	} catch (error) {
		console.error(error);
	}
};


// Computed properties

const flightSearchProps = computed(() => {
	
	if (flightSearchPropsFromAPI.value === null) return null;
	
	return {
		...transformObjectProps('isHideInDesktop', flightSearchPropsFromAPI.value),
		isHideContinueYourSearchSection: true,
	};
});

const airCargoSearchProps = computed(() => {
	if (airCargoSearchPropsFromAPI.value === null) return null;
	
	return {
		...transformObjectProps('isHideInDesktop', airCargoSearchPropsFromAPI.value),
	};
});

const manageBookingProps = computed(() => {
	
	if (manageBookingPropsFromAPI.value === null) return null;
	
	return {
		...transformObjectProps('isHideInDesktop', manageBookingPropsFromAPI.value),
	};
});

const checkInProps = computed(() => {
	
	if (checkInPropsFromAPI.value === null) return null;
	
	return {
		...checkInPropsFromAPI.value,
		isHideInDesktop: true,
	};
});

const getElementIfExist = async (selector) => {
	// await nextTick();
	try {
		const wait = waitFor(() => document.querySelector(selector) !== null, 15, 6);
		await wait.start();
		
		const el = document.querySelector(selector);
		if (el) return true;
		
	} catch (error) {
		// if (error) return;
	}
	
	return false;
};

const isFlightSearchMounted = ref(true);
const isAirCargoSearchMounted = ref(true);
const isManageBookingMounted = ref(true);
const isCheckInMounted = ref(true);

onMounted(async () => {
	setupProps(bookingWidgetArrayList);
	
	isFlightSearchMounted.value = await getElementIfExist('.FireflyFlightSearch');
	isAirCargoSearchMounted.value = await getElementIfExist('.FireflyAirCargo');
	isManageBookingMounted.value = await getElementIfExist('.FireflyManageBooking');
	isCheckInMounted.value = await getElementIfExist('.FireflyCheckIn');
});

</script>

<template>
<div v-if="isMobileViewport" class="FireflyHiddenSeamlessBookingWidget hidden">
	<template v-if="isMobileViewport">
		<FireflyFlightSearch
			v-if="!isFlightSearchMounted && flightSearchProps !== null"
			v-bind="flightSearchProps"
		/>
		<FireflyAirCargo
			v-if="!isAirCargoSearchMounted && airCargoSearchProps !== null"
			v-bind="airCargoSearchProps"
		/>
		<FireflyManageBooking
			v-if="!isManageBookingMounted && manageBookingProps !== null"
			v-bind="manageBookingProps"
		/>
		<FireflyCheckIn
			v-if="!isCheckInMounted && checkInProps !== null"
			v-bind="checkInProps"
		/>
	</template>
</div>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FireflyHiddenSeamlessBookingWidget {
	
}

</style>