<script>
export default {
	name: 'PageProperties',
	inheritAttrs: false,
};
</script>

<script setup>
// import someComponents from '~/components/someComponents.vue';

const props = defineProps({
	pageProperties: { type: [String, Object], default: null },
});
const emit = defineEmits([
	// 'update:modelValue',
]);


</script>



<template>
<div class="PageProperties"></div>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

</style>
