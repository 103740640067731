<script>
export default {
	name: 'AppHyperlink',
};
</script>

<script setup>
import ArrowUpRightFromSquare from '~icons/far/arrow-up-right-from-square';
import { ensureHtmlExtension, addRetainingQueryToUrl } from '~/logic/helpers/string.js';
import { Url as ReURL, RawUrl as ReRawURL } from 'reurl';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
	href: { type: String, default: '' },
	
	forceATag: { type: Boolean, default: true },
	forceRouterTag: { type: Boolean, default: false },
	
	autoHtmlExtension: { type: Boolean, default: true }, // <-- when true, will automatically add .html extension at the end, taking any url query or url hash into account
	addHtmlExtension: { type: Boolean, default: null }, // <-- null = follow 'props.autoHtmlExtension'. true | false overwrites 'props.autoHtmlExtension'.
	
	externalLinkIcon: { type: [String, Object], default: ArrowUpRightFromSquare },
	autoExternalLinkIcon: { type: Boolean, default: false }, // <-- when true, external link icon will show if origin mismatch is true
	showExternalLinkIcon: { type: Boolean, default: null }, // <-- overwrites 'props.autoExternalLinkIcon'
});


const isOriginMatchCurrentLocation = computed(() => {
	if (!props.href) return true;
	
	if (!props.href.startsWith('http')) {
		// safe to assume it is relative link, no origin mismatch issue here
		return true;
	}
	
	try {
		let url = new URL(props.href);
		if (url.origin === window.location.origin) return true;
		return false;
	} catch (err) {
		// fail silently, just treat this as relative link
		return true;
	}
});


/* const addRetainingQueryToUrl = (url) => {
	const RETAINING_QUERIES = ['wcmmode', 'vite', 'empty'];
	const currentRouteQuery = router.currentRoute.value.query;
	const queryToAdd = {};
	
	// find the overlap in current url query, and the queries we want to append ('retain') to the url agrument
	for (const q of RETAINING_QUERIES) {
		if (q in currentRouteQuery) {
			queryToAdd[q] = currentRouteQuery[q];
		}
	}

	const _reUrl = new ReRawURL(url);
	const oriQuery = _reUrl.query ? parseQuery(_reUrl.query) : null;
	
	const finalQuery = {
		...queryToAdd,
		...oriQuery,
	};
	
	let _reUrl_queryMerged = _reUrl;
	if (Object.keys(finalQuery).length > 0) {
		_reUrl_queryMerged = _reUrl.set({ query: stringifyQuery(finalQuery) });
	}
	return _reUrl_queryMerged.toString().replaceAll('%20', '+');
}; */

const computedLink = computed(() => {
	const hrefWithRetainingQuery = addRetainingQueryToUrl(props.href);
	
	if (typeof props.addHtmlExtension === 'boolean') {
		if (props.addHtmlExtension) {
			return ensureHtmlExtension(hrefWithRetainingQuery);
		} else {
			return hrefWithRetainingQuery;
		}
	}
	
	// 👇 props.addHtmlExtension is null
	
	if (props.autoHtmlExtension) {
		if (isOriginMatchCurrentLocation.value) {
			
			// for below logic: hardcode only for firefly, do NOT append .html if href path starts with this string
			if (window.siteName === 'firefly') {
				let urlObj = new ReRawURL(hrefWithRetainingQuery);
				if (urlObj?.dirs?.join('/')?.startsWith('flights/en')) {
					return hrefWithRetainingQuery;
				}
			}
			// ---- the end of firefly-only logic ----
			
			return ensureHtmlExtension(hrefWithRetainingQuery);
		}
	}
	
	// 👇 (props.addHtmlExtension is null) AND (props.autoHtmlExtension is falsy)
	
	return hrefWithRetainingQuery;
});

const isExternalLinkIconVisible = computed(() => {
	isOriginMatchCurrentLocation.value; // eslint-disable-line
	
	if (typeof props.showExternalLinkIcon === 'boolean') {
		return props.showExternalLinkIcon;
	}
	
	if (props.autoExternalLinkIcon) {
		// auto compute, so this is tie to 'isOriginMatchCurrentLocation'
		return !isOriginMatchCurrentLocation.value;
	}
	
	return false;
});

const computedTag = computed(() => {
	if (props.forceATag) return 'a';
	if (props.forceRouterTag) return 'router-link';
	if (computedLink.value.startsWith('/content/mas')) {
		// handle for cases where url starts with '/content/mas', that is not part of our SPA, so we force browser to page reload
		return 'a';
	}
	return isOriginMatchCurrentLocation.value ? 'router-link' : 'a';
});

const componentProps = computed(() => {
	const hrefKey = (computedTag.value === 'router-link') ? 'to' : 'href';
	
	return {
		[hrefKey]: computedLink.value,
	};
});

</script>

<template>
<component
	:is="computedTag"
	class="AppHyperlink inline-flex items-center flex-wrap"
	v-bind="componentProps"
	rel="noopener noreferrer"
>
	<slot></slot>
	<component
		:is="props.externalLinkIcon"
		v-if="isExternalLinkIconVisible"
		class="ml-2 rtl:mr-2 rtl:ml-0 fill-current"
	/>
</component>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.AppHyperlink {
	--a-tag-color: var(--primary-blue-base);
	--a-tag-color-hover: var(--primary-blue-dark);
}

[data-use-theme="MAG"] {
	.AppHyperlink {
		--a-tag-color: var(--primary-midnightgreen-base);
		--a-tag-color-hover: var(--primary-midnightgreen-dark);
	}
}
</style>
