import { ref, computed, onMounted, onUnmounted, readonly, getCurrentInstance } from 'vue';
import { Model, ModelManager, PathUtils, AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import Utils from '../../Utils.js';
import { Constants } from '../../Constants.js';
import { getMappedComponent } from '../../ComponentMapping.jsx';
import getUuid from 'uuid-by-string';
import { isNonAsciiString } from '~/logic/helpers/ascii.js';



const getCompositeComponent = (parsysConfig) => {
	if (!parsysConfig.type) throw new Error(`parsysConfig.type is mandatory.`);
	if (!parsysConfig.componentKey) throw new Error(`parsysConfig.componentKey is mandatory.`);
	
	const instance = getCurrentInstance();
	const { aemProperties } = instance.attrs;
	
	const vNode = getMappedComponent(parsysConfig.type);
	const componentKey = isNonAsciiString(parsysConfig.componentKey) ? getUuid(parsysConfig.componentKey) : parsysConfig.componentKey;
	
	const vNodeProps = {
		...Utils.modelToProps(aemProperties.cqItems?.[componentKey] ?? {}),
		cqPath: parsysConfig.cqPath || `${aemProperties.cqPath}/${componentKey}`,
		cqType: parsysConfig.type,
	};
	
	return {
		vNode,
		vNodeProps,
	};
};

export {
	getCompositeComponent,
};
