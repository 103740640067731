<script>
export default {
	name: 'Banner',
	editConfig: {
		emptyLabel: 'Banner',
		isEmpty (props) {
			switch (props.type) {
				case 'generalHeroBanner': {
					if (props.ghDesktopImage || props.ghBackgroundStyle || props.ghGradientOverlay || props.ghBottomCurve) {
						return false;
					}
					return true;
				}
				case 'crossSellBanner': {
					if (props.csDesktopBackgroundImage || props.csKeyVisualImage || props.csBackgroundStyle || props.csGradientOverlay) {
						return false;
					}
					return true;
				}
				case 'imageTileBanner': {
					return !props.itDesktopBackgroundImage;
				}
				case 'videoBanner': {
					return !props.vDesktopVideo;
				}
				default: {
					return true;
				}
			}
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>
import HeroBanner from '~/components/Banner/HeroBanner.vue';
import CrossSellBanner from '~/components/Banner/CrossSellBanner.vue';
import ImageTileBanner from '~/components/Banner/ImageTileBanner.vue';
import VideoBanner from '~/components/Banner/VideoBanner.vue';
import { getCompositeComponent } from '@custom/aem-vue-editable-components';
import Parsys from '~/components/Parsys.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';

const props = defineProps({
	type: { type: String, default: '' },
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
	ghDesktopImage: { type: String, default: '' },
	ghMobileImage: { type: String, default: '' },
	ghDesktopPlaceholderHorizontalAlignment: { type: String, default: '' },
	ghDesktopPlaceholderVerticalAlignment: { type: String, default: '' },
	ghBackgroundStyle: { type: String, default: '' },
	ghTagMultiList: { type: Array, default: () => [] },
	ghDesktopBannerHeight: { type: String, default: '' },
	ghMobileBannerHeight: { type: String, default: '' },
	ghGradientOverlay: { type: String, default: '' },
	ghGradientOverlayOpacity: { type: String, default: '' },
	ghBottomCurve: { type: Boolean, default: true },
	ghTagStyle: { type: String, default: '' },
	
	// used when props.ghTagStyle === 'mhh-white-text-pink-background'
	ghMhhTagMultiList: { type: Array, default: () => [] }, // <-- { ghTagName: '' }
	// used when props.ghTagStyle === 'mhh-white-text-pink-background'
	ghTagPlaceholderPosition: { type: String, default: '' }, // <-- 'above-component-placeholder' | 'below-component-placeholder'
	
	isDisableBanner: { type: Boolean, default: false },
	desktopImageAltText: { type: String, default: '' },
	mobileImageAltText: { type: String, default: '' },
	ghBackgroundColour: { type: String, default: '' },
	ghBannerUrl: { type: String, default: '' },
	ghOpenInNewTab: { type: Boolean, default: false },
	ghCoverOrContain: { type: String, default: '' }, // <--- 'cover' | 'contain'

	csDesktopBackgroundImage: { type: String, default: '' },
	csMobileBackgroundImage: { type: String, default: '' },
	csKeyVisualImage: { type: String, default: '' },
	csImagePosition: { type: String, default: '' },
	csBackgroundStyle: { type: String, default: '' },
	csDesktopBannerHeight: { type: String, default: '' },
	csMobileBannerHeight: { type: String, default: '' },
	csGradientOverlay: { type: String, default: '' },
	csBannerUrl: { type: String, default: '' },
	csOpenInNewTab: { type: Boolean, default: false },

	itDesktopBackgroundImage: { type: String, default: '' },
	itMobileBackgroundImage: { type: String, default: '' },
	itDesktopBannerHeight: { type: String, default: '' },
	itMobileBannerHeight: { type: String, default: '' },
	itImagePosition: { type: String, default: 'left' },
	itBannerUrl: { type: String, default: '' },
	itOpenInNewTab: { type: Boolean, default: false },

	vDesktopVideo: { type: String, default: '' },
	vMobileVideo: { type: String, default: '' },
	vDesktopImage: { type: String, default: '' },
	vMobileImage: { type: String, default: '' },
	vDesktopAltText: { type: String, default: '' },
	vMobileAltText: { type: String, default: '' },
	vDesktopBannerHeight: { type: String, default: '' },
	vMobileBannerHeight: { type: String, default: '' },
	vDesktopPlaceholderHorizontalAlignment: { type: String, default: '' },
	vDesktopPlaceholderVerticalAlignment: { type: String, default: '' },
	vGradientOverlay: { type: String, default: '' },
	vGradientOverlayOpacity: { type: String, default: '' },
	vBottomCurve: { type: Boolean, default: true },
	vTagMultiList: { type: Array, default: () => [] },
	vTagStyle: { type: String, default: '' },
	vDisableAudio: { type: Boolean, default: false },

	componentIdAA: { type: String, default: '' },
	sortingOrder: { type: String, default: '1' },


});

const { isEditorMode } = useGlobalAEMState();
const siteName = window.siteName;

const bannerParsys = getCompositeComponent({
	type: 'mh/components/mh-layout-container',
	componentKey: 'banner-parsys',
});

const root = ref(null);

</script>

<template>
<div ref="root" class="Banner" :class="{ '>md:hidden': isHideInDesktop, 'hidden >md:block': isHideInMobile }" :data-use-theme="siteName">
	<!-- Banner Variant 1: HeroBanner -->
	<HeroBanner
		v-if="type === 'generalHeroBanner'"
		:desktopImgSrc="ghDesktopImage"
		:mobileImgSrc="ghMobileImage"
		:desktopImageAltText="desktopImageAltText"
		:mobileImageAltText="mobileImageAltText"
		:imgSize="props.ghCoverOrContain"
		:bgStyle="ghBackgroundStyle"
		:desktopImgHeight="ghDesktopBannerHeight"
		:mobileImgHeight="ghMobileBannerHeight"
		:placeholderHorizontalAlignment="ghDesktopPlaceholderHorizontalAlignment"
		:placeholderVerticalAlignment="ghDesktopPlaceholderVerticalAlignment"
		:useOverlay="ghGradientOverlay"
		:overlayOpacityLevel="ghGradientOverlayOpacity"
		:bottomCurve="ghBottomCurve"
		:tagList="ghTagMultiList"
		:useTagTheme="ghTagStyle"
		:bgColor="ghBackgroundColour"
		:mhhTagMultiList="props.ghMhhTagMultiList"
		:mhhTagPlaceholderPosition="props.ghTagPlaceholderPosition"
		:url="props.ghBannerUrl"
		:openNewTab="props.ghOpenInNewTab"
	>
		<Parsys
			:component="bannerParsys"
			label="Hero Banner Content"
		/>
	</HeroBanner>

	<!-- Banner Variant 2: CrossSellBanner -->
	<CrossSellBanner
		v-if="type === 'crossSellBanner'"
		:desktopImgSrc="csDesktopBackgroundImage"
		:mobileImgSrc="csMobileBackgroundImage"
		:desktopImageAltText="desktopImageAltText"
		:mobileImageAltText="mobileImageAltText"
		:keyVisualSrc="csKeyVisualImage"
		:bgStyle="csBackgroundStyle"
		:keyVisualAlignment="csImagePosition"
		:desktopBannerHeight="csDesktopBannerHeight"
		:mobileBannerHeight="csMobileBannerHeight"
		:gradientValue="csGradientOverlay"
		:url="csBannerUrl"
		:openNewTab="csOpenInNewTab"
	>
		<Parsys
			:component="bannerParsys"
			label="CrossSell Banner Content"
		/>
	</CrossSellBanner>

	<!-- Banner Variant 3: ImageTileBanner -->
	<ImageTileBanner
		v-if="type === 'imageTileBanner'"
		:desktopImgSrc="itDesktopBackgroundImage"
		:mobileImgSrc="itMobileBackgroundImage"
		:desktopImageAltText="desktopImageAltText"
		:mobileImageAltText="mobileImageAltText"
		:keyVisualAlignment="itImagePosition"
		:desktopBannerHeight="itDesktopBannerHeight"
		:mobileBannerHeight="itMobileBannerHeight"
		:url="itBannerUrl"
		:openNewTab="itOpenInNewTab"
	>
		<Parsys
			:component="bannerParsys"
			label="Image Tile Banner Content"
		/>
	</ImageTileBanner>


	<!-- Banner Variant 4: VideoBanner -->
	<VideoBanner
		v-if="type === 'videoBanner'"
		:desktopVideoSrc="vDesktopVideo"
		:mobileVideoSrc="vMobileVideo"
		:desktopImgSrc="vDesktopImage"
		:mobileImgSrc="vMobileImage"
		:desktopAltText="vDesktopAltText"
		:mobileAltText="vMobileAltText"
		:desktopBannerHeight="vDesktopBannerHeight"
		:mobileBannerHeight="vMobileBannerHeight"
		:placeholderHorizontalAlignment="vDesktopPlaceholderHorizontalAlignment"
		:placeholderVerticalAlignment="vDesktopPlaceholderVerticalAlignment"
		:useOverlay="vGradientOverlay"
		:overlayOpacityLevel="vGradientOverlayOpacity"
		:bottomCurve="vBottomCurve"
		:tagList="vTagMultiList"
		:useTagTheme="vTagStyle"
		:disableAudio="vDisableAudio"
	>
		<Parsys
			:component="bannerParsys"
			label="Video Banner Content"
		/>
	</VideoBanner>

</div>

<div v-if="isEditorMode && isHideInDesktop" class="generic-container py-10 text-center">
	<p>Hidden in: <span v-if="isHideInDesktop">Desktop</span> <span v-if="isHideInMobile">, Mobile</span></p>
</div>


</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

</style>
