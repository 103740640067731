<script>
export default {
	name: 'ToggleSwitch',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import useFieldValidation from '~/logic/composables/useFieldValidation.js';
import useGenerateIdentifier from '~/logic/composables/useGenerateIdentifier.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';


const props = defineProps({
	name: { type: String, required: true },
	ariaLabel: { type: String, required: true },
	
	modelValue: { type: Boolean, default: null },
	defaultChecked: { type: Boolean, default: false },
	
	required: { type: Boolean, default: false },
	requiredErrorMsg: { type: String, default: i18nGlobal.t('This field is required') },
	
	disabled: { type: Boolean, default: false },
	
	rootAttrs: { type: Object, default: () => ({}) },
});
const emit = defineEmits([
	'update:modelValue',
]);

const siteName = window.siteName;

const { isRTL } = useIsRTL();

const inputEl = ref(null);

const focus = () => {
	inputEl.value?.focus();
};


const validation = computed(() => {
	if (!props.required) return null;
	return yup.boolean().equals([true], props.requiredErrorMsg);
});

const {
	onCheckboxChange,
	meta,
	errors,
	hasValidationError,
	internalValue,
} = useFieldValidation({
	name: toRef(props, 'name'),
	validation: validation,
	modelValue: toRef(props, 'modelValue'),
}, {
	initialValue: props.modelValue ?? props.defaultChecked ?? null,
});


const {
	errorMsgId,
} = useGenerateIdentifier(props.name);


defineExpose({
	focus,
});

</script>

<template>
<div
	class="ToggleSwitch"
	:class="[
		{
			'is-disabled': props.disabled,
			'has-validation-error': hasValidationError && meta.touched,
		},
	]"
	:data-use-theme="siteName"
	v-bind="props.rootAttrs"
>
	<label
		class="inline-flex cursor-pointer"
		:class="{
			'is-disabled': props.disabled,
		}"
		v-bind="$attrs"
	>
		<div class="label-before mr-4 empty:mr-0 select-none">
			<slot name="label-before"></slot>
		</div>
		<input
			ref="inputEl"
			class="input"
			type="checkbox"
			
			:required="props.required"
			:checked="internalValue"
			:name="props.name"
			:disabled="props.disabled"
			
			:aria-label="props.ariaLabel"
			:aria-errormessage="errorMsgId"
			:aria-invalid="hasValidationError ? 'true' : null"
			
			@change="onCheckboxChange"
		/>
		<span class="switch" aria-hidden="true"></span>
		<div
			class="label-after font-semibold empty:ml-0 select-none"
			:class="{
				'mr-4': isRTL,
				'ml-4': !isRTL,
			}"
		>
			<slot>
				<template v-if="!$slots['label-before']">
					{{ props.ariaLabel }}
				</template>
			</slot>
		</div>
	</label>
	
	<div
		:id="errorMsgId"
		class="error-msg-container leading-tight text-semantic-red-base text-sm empty:hidden"
	>
		<slot
			v-if="hasValidationError && meta.touched"
			name="error-messages"
			v-bind="{ meta, errors, internalValue }"
		><span class="">{{ errors[0] }}</span></slot>
	</div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.ToggleSwitch {
	--switchWidth: 48px;
	--switchHeight: 24px;
	--checkedColor: var(--primary-blue-base);
	--uncheckBgColor: var(--neutral-grey-dark);
	align-items: flex-start;
	cursor: pointer;
	
	&.is-disabled {
		color: var(--neutral-grey-dark);
		
		.switch {
			opacity: 0.6;
		}
	}
}

.switch {
	position: relative;
	display: block;
	/* box-shadow: inset 0 0 0px 2px #e4e4e4; */
	background-color: var(--uncheckBgColor);
	border-radius: calc(var(--switchHeight) / 2);
	width: var(--switchWidth);
	cursor: pointer;
	flex: 0 0 auto;
	align-self: flex-start;
	
	&::before {
		// the background (track)
		content: '';
		display: block;
		height: var(--switchHeight);
		width: var(--switchHeight);
		top: 0;
		left: 0;
		border-radius: calc(var(--switchHeight) / 2);
		background-color: transparent;
		transition: 0.2s cubic-bezier(.24, 0, 0.5, 1);
	}

	&::after {
		// the dot
		--size: calc(var(--switchHeight) - 8px);
		content: '';
		position: absolute;
		display: block;
		height: var(--size);
		width: var(--size);
		top: 4px;
		left: 4px;
		border-radius: 100%;
		background-color: #fff;
		/* box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.05), 0 4px 0px 0 hsla(0, 0%, 0%, 0.02), 0 4px 9px hsla(0, 0%, 0%, 0.07), 0 3px 3px hsla(0, 0%, 0%, 0.025); */
		transition: 0.35s cubic-bezier(.54, 1.60, 0.5, 1);
	}
}

.input {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	// left: -9999px; <-- Disable this due to RTL bug causing whole site horizontal scrolling
	
	&:checked ~ .switch {
		/* box-shadow: inset 0 0 0px 25px #e4e4e4;
		transition: box-shadow 2.5s cubic-bezier(0, 1.2, .94, .95); */
		
		&::before {
			width: var(--switchWidth);
			background-color: var(--checkedColor);
			transition: width .2s cubic-bezier(0, 0, 0, 0.1);
		}
		&::after {
			left: calc(var(--switchWidth) - var(--size) - 4px);
		}
	}
}


@supports not selector(.parent:has(.child)) {
	.ToggleSwitch {
		&:focus-within {
			.switch {
				outline-width: var(--focus-visible-outline-width, 2.5px);
				outline-offset: var(--focus-visible-outline-offset, 2px);
				outline-color: var(--semantic-blue-base);
				outline-style: solid;
			}
		}
	}
}

@supports selector(.parent:has(.child)) {
	.ToggleSwitch {
		&:has(:focus-visible) {
			.switch {
				outline-width: var(--focus-visible-outline-width, 2.5px);
				outline-offset: var(--focus-visible-outline-offset, 2px);
				outline-color: var(--semantic-blue-base);
				outline-style: solid;
			}
		}
	}
}

[data-use-theme="MAG"] {
	--checkedColor: var(--primary-midnightgreen-base);
}

[data-use-theme="MHH"] {
	
	&.ToggleSwitch {
		--checkedColor: var(--primary-mhh-teal-base);
	}
}

[data-use-theme="firefly"] {
	
	&.ToggleSwitch {
		--checkedColor: var(--primary-firefly-orange-base);
	}
}
</style>
