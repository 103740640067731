<script>
export default {
	name: 'MHEFlightSearch',
	inheritAttrs: false,
};
</script>

<script setup>
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import VueTabs from '~/components/VueTabs.vue';
import SubTabBookFlight from '~/components/mhe/BookingWidget/FlightSearch/SubTabBookFlight.vue';
import ContinueYourSearch from '~/components/mhe/BookingWidget/FlightSearch/ContinueYourSearch.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { onClickOutside, useEventBus } from '@vueuse/core';
import { gsap } from 'gsap';
import { getAncestorBySelector } from '~/logic/helpers/dom.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { pushAA_flightSearchStart, pushAA_flightSearchEnd, pushAA_click } from '~/logic/adobe-analytic.js';
import { useHeaderScroll } from '~/logic/composables/useHeaderScroll.js';
import { sleep } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string';
import { useAuthStore } from '~/logic/auth/auth-store';
import usePersistencyWithExpiry from '~/logic/composables/usePersistencyWithExpiry.js';
import { encryptionHelper } from '~/logic/composables/encryptionHelper.js';

const props = defineProps({
	bookFlight: { type: Object, default: null },
	labels: { type: Object, default: null },
	mobileAddOns: { type: Object, default: null },
	isInBookingWidget: { type: Boolean, default: false },
	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },
	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },

	// Props to hide on other page than homepage.
	isHideContinueYourSearchSection: { type: Boolean, default: false },

	enrichAlwaysOnCountriesData: { type: Array, default: () => [] },

});

const emit = defineEmits([
	'update:active-sub-tab',
]);

const rootEl = ref(null);
const subTabBookFlightEl = ref(null);
const vueTabsComponent = ref(null);
const { retrieveLocalStorage } = usePersistencyWithExpiry('userInfo');
const { decrypt } = encryptionHelper();

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['mhe-flight-search', 'mhe-flight-search-tab'],
	rootEl,
});

const { isEditorMode } = useGlobalAEMState();

const activeSubTab = ref('book-flight');
const activeSubTabPreStick = ref('book-flight');
const isSticked = ref(false);
const isFlysiswaUser = ref(false);

const handleRootFocusWithin = () => {
	if (isSticked.value) stickyExpand();
};

const handleRootBlurWithin = () => {
	//
};

const bookingWidgetBus = useEventBus('booking-widget');

bookingWidgetBus.on((event, payload) => {
	switch (event) {
		case 'sticky:sticked': {
			isSticked.value = true;
			handleOnStick(payload);
			break;
		}
		case 'sticky:unsticked': {
			isSticked.value = false;
			handleOnUnstick(payload);
			break;
		}
		default: {
			break;
		}
	}
});

const handleOnStick = async (payload) => {
	activeSubTabPreStick.value = activeSubTab.value;
	payload.resizeObserver.unobserve(payload.triggerEl);
	stickyCollapse({ skipAnimation: true });
	const { paused: headerScrollPaused } = useHeaderScroll();
	headerScrollPaused.value = true;
	await sleep(200);
	payload.triggerEl.classList.remove('pre-stick');
	
	gsap.fromTo(payload.triggerEl, {
		y: '-100%',
	}, {
		y: 0,
		duration: 0.3,
		overwrite: true,
		onComplete: () => {
			headerScrollPaused.value = false;
		},
	});
};

const handleOnUnstick = async (payload) => {
	activeSubTab.value = activeSubTabPreStick.value;
	stickyExpand({ skipAnimation: true });
	await nextTick();
	await sleep(200);
	payload.resizeObserver.observe(payload.triggerEl);
};

const stickyExpand = ({ skipAnimation = false } = {}) => {
	const tabRootEl = rootEl.value.querySelector('.VueTabs');
	const expandableWrapperEL = tabRootEl.querySelector('.SubTabBookFlight .TransitionHeight');
	const continueYourSearchEl = rootEl.value.querySelector('.continue-your-search-container');
	
	gsap.to(expandableWrapperEL, {
		duration: skipAnimation ? 0 : 0.2,
		height: 'auto',
		onComplete: () => {
			gsap.set(expandableWrapperEL, {
				overflow: 'visible',
			});
		},
		overwrite: true,
	});
	
	if (continueYourSearchEl) {
		gsap.to(continueYourSearchEl, {
			duration: skipAnimation ? 0 : 0.2,
			height: 'auto',
			overwrite: true,
		});
	}
	gsap.to(tabRootEl, {
		duration: skipAnimation ? 0 : 0.2,
		marginTop: 0,
		overwrite: true,
	});
};

const stickyCollapse = async ({ skipAnimation = false } = {}) => {
	const CONTAINER_PADDING_TOP = 32;
	const tabRootEl = rootEl.value.querySelector('.VueTabs');
	const tabSwiperEl = tabRootEl.querySelector('.VueTabs .VueSwiper');
	const TAB_HEIGHT = tabSwiperEl.offsetHeight;
	const OFFSET_TOP = CONTAINER_PADDING_TOP + TAB_HEIGHT;
	const expandableWrapperEL = tabRootEl.querySelector('.SubTabBookFlight .TransitionHeight');
	const continueYourSearchEl = rootEl.value.querySelector('.continue-your-search-container');
	
	activeSubTab.value = 'book-flight';
	
	gsap.set(expandableWrapperEL, {
		overflow: 'hidden',
	});
	gsap.to(expandableWrapperEL, {
		duration: skipAnimation ? 0 : 0.2,
		height: 0,
		overwrite: true,
	});
	if (continueYourSearchEl) {
		gsap.to(continueYourSearchEl, {
			duration: skipAnimation ? 0 : 0.2,
			height: 0,
			overwrite: true,
		});
	}

	gsap.to(tabRootEl, {
		duration: skipAnimation ? 0 : 0.2,
		marginTop: `-${OFFSET_TOP}`,
		overwrite: true,
	});
};


const continueYourSearchComponent = ref(null);

const handleSaveSearchData = (data) => {
	continueYourSearchComponent.value.saveSearch(data);
};

const restorePastSearch = async (searchData) => {
	if (isMobileViewport.value) {
		// activate the tab...
		isMobileSubpageVisible.value = true;
		await nextTick();
	} else {
		activeSubTab.value = 'book-flight';
		await nextTick();
		vueTabsComponent.value.scrollToTab('book-flight');
		subTabBookFlightEl.value.restorePastSearch(searchData);
		subTabBookFlightEl.value.isExpanded = true;
	}
};

const handleFillUpForm = async (payload) => {
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
		await nextTick();
	}
	
	activeSubTab.value = 'book-flight';
	activeSubTabPreStick.value = 'book-flight';
	await nextTick();
	vueTabsComponent.value.scrollToTab('book-flight');
	subTabBookFlightEl.value.updateLocationFromTo(payload);
};


const globalBookFlightBus = useEventBus('booking-widget:book-flight');

globalBookFlightBus.on(async (event, payload) => {
	switch (event) {
		case 'active-tab-and-fill-up-form':
			handleFillUpForm(payload);
			await nextTick();
			break;
		default:
			console.log(`Unknown event: ${event}. Ignore it.`);
			break;
	}
});

//  AA for sub tab click tracking
watch(activeSubTab, (newTabName) => {
	pushAA_click([
		{
			clickName: newTabName,
			clickComponentType: 'URL',
			componentName: 'MHEFlightSearch',
			componentID: props.componentIdAA,
		},
		{
			name: newTabName,
			type: 'other',
		},
	]);
});

let AAHasSentStart = false;
let AAHasSentEnd = false;

onMounted(() => {
	
	const switchTabContent = getAncestorBySelector(rootEl.value, '.SwitchTabContent');
	onClickOutside(switchTabContent?.[0], (event) => {
		if (isSticked.value) stickyCollapse();
	});

	const aaFormStartHandler = (event) => {
		if (AAHasSentStart) return;
		if (event.target.tagName === 'FORM') return;
		
		AAHasSentStart = true;
		pushAA_flightSearchStart(activeSubTab.value);
		rootEl.value.removeEventListener('focus', aaFormStartHandler, { capture: true });
	};
	rootEl.value.addEventListener('focus', aaFormStartHandler, { capture: true });
});

onMounted(async () => {
	const storedData = await retrieveLocalStorage();
	if (storedData.userInfo) {
		try {
			const decryptedData = await decrypt(storedData.userInfo);
			const data = JSON.parse(decryptedData);
			isFlysiswaUser.value = data ? data.isFlysiswaUser : false;
		} catch (error) {
			console.error('Failed to decrypt user data:', error);
		}
	}
});

const sendFormCompleteAA = (subtabName) => {
	if (AAHasSentEnd) return;
	AAHasSentEnd = true;
	pushAA_flightSearchEnd(subtabName);
};

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'mhe-flight-search-tab',
});

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}
	
	const newSubTab = query.subtab ?? '';
	if (newSubTab) {
		activeSubTab.value = newSubTab;
		emit('update:active-sub-tab', newSubTab);
	}
});

</script>

<template>
<div
	ref="rootEl"
	v-focus-within
	class="MHEFlightSearch booking-widget-subcomponent"
	tabindex="-1"
	:class="{
		'isEditorMode': isEditorMode,
	}"
	@focus-within="handleRootFocusWithin"
	@blur-within="handleRootBlurWithin"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: MHE Flight Search</span>
		</h5>
		
		<VueTabs
			ref="vueTabsComponent"
			v-model="activeSubTab"
			:dormantMode="isEditorMode"
			renderPanelsWithVShow
			@update:modelValue="$emit('update:active-sub-tab', $event)"
		>
			<template v-if="!props.bookFlight?.general?.isHideInDesktop && props.labels?.flightType?.bookFlightLabel" #tab-book-flight>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.labels?.flightType?.bookFlightLabel }}</span>
				</div>
			</template>
			<template v-if="!props.labels?.flightType?.hideInDesktop &&	props.labels?.flightType?.flysiswa" #link-flysiswa>
				<AppHyperlink
					v-if="isFlysiswaUser"
					v-aa="[
						{
							clickName: props.labels?.flightType?.flysiswa,
							clickComponentType: 'URL',
							componentName: 'MHEFlightSearch',
							componentID: props.componentIdAA,
						},
						{
							name: props.labels?.flightType?.flysiswa,
							type: isUrlMatchCurrentHostname(props.url) ? 'other' : 'exit',
						},
					]"
					:href="props.labels?.flightType?.flysiswaUrl"
					class="font-semibold text-$text-color rounded-xl px-4 hover:bg-neutral-grey-extralight h-full leading-none"
					:target="props.labels?.flightType?.openInNewTab ? '_blank' : '_self'"
					:autoExternalLinkIcon="true"
				>{{ props.labels?.flightType?.flysiswa }}</AppHyperlink>
			</template>
			<div
				v-if="!props.bookFlight?.general?.isHideInDesktop"
				identifier="book-flight"
			>
				<SubTabBookFlight
					ref="subTabBookFlightEl"
					class="pt-6"
					:payingByEnrichLabel="props.labels?.paymentType?.payingByEnrichLabel"
					:hideTodayDateIndicator="props.labels?.paymentType?.hideTodayDateIndicator"
					:subHeaderTabDescription="props.bookFlight?.general?.subHeaderTabDescription"
					:subHeaderTabTooltipBody="props.bookFlight?.general?.subHeaderTabTooltipBody"
					:subHeaderTabTooltipLabel="props.bookFlight?.general?.subHeaderTabTooltipLabel"
					:note="props.bookFlight?.general?.note"
					:searchFlightCTAText="props.bookFlight?.general?.searchFlightCTAText"
					:ondSelection="props.bookFlight?.ondSelection"
					:datePicker="props.bookFlight?.datePicker"
					:promoCodeLabel="props.bookFlight?.promoCode?.promoCodeLabel"
					:promoCodeErrorMessage="props.bookFlight?.promoCode?.promoCodeErrorMessage"
					:supersedePromoCode="props.bookFlight?.promoCode?.supersedePromoCode"
					:passengerSelection="props.bookFlight?.passengerSelection"
					:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
					:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
					:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
					:flightSearchApiUrl="props.flightSearchApiUrl"
					:cabinClassCashList="props.cabinClassCashList"
					:cabinClassMilesList="props.cabinClassMilesList"
					:togglePointsApiURL="props.togglePointsApiURL"
					:enableMattaToggleButton="props.labels?.mattaFair?.enableMattaToggleButton"
					:mattaToggleButtonLabel="props.labels?.mattaFair?.mattaToggleButtonLabel"
					:hideEnrichToggle="props.labels?.paymentType?.hideEnrichToggle"
					:hidePromoCodeField="props.bookFlight?.promoCode?.hidePromoCodeField"
					:enrichAlwaysOn="props.bookFlight?.enrichAlwaysOn?.enableFunctionality"
					:loggedInMessageNote="props.bookFlight?.enrichAlwaysOn?.loggedInMessageNote"
					:enrichAlwaysOnCountriesData="props.enrichAlwaysOnCountriesData"
					:disableGeolocation="props.labels?.nearYou?.disableGeolocation"
					:enableONDLocaleTranslation="props.labels?.ondLocale?.isEnableLocale"
					:ondTranslationLanguage="props.labels?.ondTranslation?.languageCode"
					:customCUGSearchWidget="props.bookFlight?.customCUGSearchWidget"
					@save-search-data="handleSaveSearchData({ isBSTTab: false, ...$event })"
					@form-submission="sendFormCompleteAA('book-flight')"
				></SubTabBookFlight>
			</div>
		</VueTabs>
		<ContinueYourSearch
			v-if="!props.isHideContinueYourSearchSection"
			ref="continueYourSearchComponent"
			class="continue-your-search-container"
			:continueYourSearchLabel="props.bookFlight?.continueYourSearch?.continueYourSearchLabel"
			:economyLabel="props.bookFlight?.passengerSelection?.economyLabel"
			:businessLabel="props.bookFlight?.passengerSelection?.businessLabel"
			:businessSuiteLabel="props.bookFlight?.passengerSelection?.businessSuiteLabel"
			@restore-past-search="restorePastSearch"
		></ContinueYourSearch>
	</div>
</div>


<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event"
>
	
	<template #banner-title>
		<h1 class="text-center">MHE Flight Search</h1>
	</template>
	
	<template #default>
		<div class="generic-container mb-20">
			<VueTabs
				ref="vueTabsComponent"
				v-model="activeSubTab"
				renderPanelsWithVShow
				@update:modelValue="$emit('update:active-sub-tab', $event)"
			>
				<template v-if="!props.bookFlight?.general?.isHideInMobile && props.labels?.flightType?.bookFlightLabel" #tab-book-flight>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.labels?.flightType?.bookFlightLabel }}</span>
					</div>
				</template>

				<template v-if="!props.labels?.flightType?.hideInDesktop && props.labels?.flightType?.flysiswa" #link-flysiswa>
					<AppHyperlink
						v-if="isFlysiswaUser"
						v-aa="[
							{
								clickName: props.labels?.flightType?.flysiswa,
								clickComponentType: 'URL',
								componentName: 'MHEFlightSearch',
								componentID: props.componentIdAA,
							},
							{
								name: props.labels?.flightType?.flysiswa,
								type: isUrlMatchCurrentHostname(props.url) ? 'other' : 'exit',
							},
						]"
						:href="props.labels?.flightType?.flysiswaUrl"
						class="font-semibold text-$text-color rounded-xl px-4 hover:bg-neutral-grey-extralight h-full leading-none"
						:target="props.labels?.flightType?.openInNewTab ? '_blank' : '_self'"
						:autoExternalLinkIcon="true"
					>{{ props.labels?.flightType?.flysiswa }}</AppHyperlink>
				</template>
				
				<div
					v-if="!props.bookFlight?.general?.isHideInMobile"
					identifier="book-flight"
				>
					<SubTabBookFlight
						ref="subTabBookFlightEl"
						class="pt-6"
						:payingByEnrichLabel="props.labels?.paymentType?.payingByEnrichLabel"
						:hideTodayDateIndicator="props.labels?.paymentType?.hideTodayDateIndicator"
						:subHeaderTabDescription="props.bookFlight?.general?.subHeaderTabDescription"
						:subHeaderTabTooltipBody="props.bookFlight?.general?.subHeaderTabTooltipBody"
						:subHeaderTabTooltipLabel="props.bookFlight?.general?.subHeaderTabTooltipLabel"
						:note="props.bookFlight?.general?.note"
						:searchFlightCTAText="props.bookFlight?.general?.searchFlightCTAText"
						:ondSelection="props.bookFlight?.ondSelection"
						:datePicker="props.bookFlight?.datePicker"
						:promoCodeLabel="props.bookFlight?.promoCode?.promoCodeLabel"
						:promoCodeErrorMessage="props.bookFlight?.promoCode?.promoCodeErrorMessage"
						:supersedePromoCode="props.bookFlight?.promoCode?.supersedePromoCode"
						:passengerSelection="props.bookFlight?.passengerSelection"
						:bstNoteTop="props.bonusSideTrip?.bstPage?.bstNoteTop"
						:bstNoteCTAText="props.bonusSideTrip?.bstPage?.bstNoteCTAText"
						:bstNoteCTAURL="props.bonusSideTrip?.bstPage?.bstNoteCTAURL"
						:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
						:fromQuestionMobile="props.mobileAddOns?.fromQuestionMobile"
						:toQuestionMobile="props.mobileAddOns?.toQuestionMobile"
						:flightSearchApiUrl="props.flightSearchApiUrl"
						:cabinClassCashList="props.cabinClassCashList"
						:cabinClassMilesList="props.cabinClassMilesList"
						:togglePointsApiURL="props.togglePointsApiURL"
						:enableMattaToggleButton="props.labels?.mattaFair?.enableMattaToggleButton"
						:mattaToggleButtonLabel="props.labels?.mattaFair?.mattaToggleButtonLabel"
						:hideEnrichToggle="props.labels?.paymentType?.hideEnrichToggle"
						:hidePromoCodeField="props.bookFlight?.promoCode?.hidePromoCodeField"
						:enrichAlwaysOn="props.bookFlight?.enrichAlwaysOn?.enableFunctionality"
						:loggedInMessageNote="props.bookFlight?.enrichAlwaysOn?.loggedInMessageNote"
						:enrichAlwaysOnCountriesData="props.enrichAlwaysOnCountriesData"
						:disableGeolocation="props.labels?.nearYou?.disableGeolocation"
						:enableONDLocaleTranslation="props.labels?.ondLocale?.isEnableLocale"
						:ondTranslationLanguage="props.labels?.ondTranslation?.languageCode"
						:customCUGSearchWidget="props.bookFlight?.customCUGSearchWidget"
						@save-search-data="handleSaveSearchData({ isBSTTab: false, ...$event })"
						@form-submission="sendFormCompleteAA('book-flight')"
					></SubTabBookFlight>
				</div>
			</VueTabs>
		</div>
	</template>
	
</MobileSubpage>

</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MHEFlightSearch {
	
}

</style>