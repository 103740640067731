import DOMPurify from '~/../custom-modules/dompurify/dompurify.js';
import { useFocusWithin } from '~/logic/composables/useFocusWithin.js';
import { installDirectiveAA } from '~/logic/adobe-analytic.js';


export default {
	install (app, options) {
		app.directive('focus-within', {
			mounted (el, binding, vnode, prevVnode) {
				const { focused, activeElement } = useFocusWithin(el);
				watch(focused, (newValue) => {
					if (newValue) {
						// is focused within
						el.dispatchEvent(new CustomEvent('focus-within', { detail: { activeElement } }));
					} else {
						// is not focused within
						el.dispatchEvent(new CustomEvent('blur-within', { detail: { activeElement } }));
					}
				});
			},
		});
		
		app.directive('button-press', {
			mounted (el, binding, vnode, prevVnode) {
				const CLASSNAME = 'is-mouse-down';
				const isMouseDown = ref(false);
				
				const setIsMouseDownTrue = () => { isMouseDown.value = true; };
				const setIsMouseDownFalse = () => { isMouseDown.value = false; };
				
				el.addEventListener('mousedown', setIsMouseDownTrue);
				el.addEventListener('mouseup', setIsMouseDownFalse);

				el.addEventListener('keydown', (event) => {
					if (['Enter', 'Space'].includes(event.code)) {
						setIsMouseDownTrue();
					}
				});
				el.addEventListener('keyup', (event) => {
					if (['Enter', 'Space'].includes(event.code)) {
						setIsMouseDownFalse();
					}
				});
				
				const unwatch = watch(isMouseDown, (newValue) => {
					if (newValue) {
						el.classList.add(CLASSNAME);
					} else {
						el.classList.remove(CLASSNAME);
					}
				});
				
				// for clean up
				el.__button_setIsMouseDownTrue = setIsMouseDownTrue;
				el.__button_setIsMouseDownFalse = setIsMouseDownFalse;
				el.__button_unwatch = unwatch;
			},
			
			unmounted (el) {
				el.removeEventListener('mousedown', el.__button_setIsMouseDownTrue);
				el.removeEventListener('mouseup', el.__button_setIsMouseDownFalse);
				el.removeEventListener('keydown', el.__button_setIsMouseDownTrue);
				el.removeEventListener('keyup', el.__button_setIsMouseDownFalse);
				el.__button_unwatch();
			},
		});
		
		app.directive('visible', (el, binding, vnode, prevVnode) => {
			const value = binding.value;
			
			if (value) {
				el.style.visibility = 'visible';
			} else {
				el.style.visibility = 'hidden';
			}
		});
		
		app.directive('html-sanitize', (el, binding, vnode, prevVnode) => {
			const FORBID_TAGS = binding.modifiers.allowStyle ? [] : ['style'];
			
			if (binding.value !== binding.oldValue) {
				el.innerHTML = DOMPurify.sanitize(binding.value, {
					FORBID_TAGS,
				});
			}
		});
		
		app.directive('alphabets-strict', {
			mounted (el, binding, vnode, prevVnode) {
				const restrictToAlphabets = (e) => {
					if (!/[a-zA-Z\s\b]/.test(e.key)) {
						e.preventDefault();
					}
				};
				el.addEventListener('keydown', restrictToAlphabets);
				el.__restrictToAlphabets = restrictToAlphabets;
			},
			
			unmounted (el) {
				el.removeEventListener('keydown', el.__restrictToAlphabets);
			},
		});

		installDirectiveAA(app);
	},
};
