<script>
export default {
	name: 'FireflyArticleLayout',
	expose: [],
	inheritAttrs: false,
};
</script>


<script setup>
import dayjs from 'dayjs';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import Breadcrumb from '~/components/Breadcrumb.vue';
import FireflyHiddenSeamlessBookingWidget from '~/components/firefly/BookingWidget/FireflyHiddenSeamlessBookingWidget.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import BtnBackToTop from '~/components/BtnBackToTop.vue';


const { pageProperties } = useGlobalAEMState();

const articleTitle = computed(() => {
	if (!pageProperties.value?.articlePageProperties?.articlePageTitle) return null;
	return pageProperties.value.articlePageProperties.articlePageTitle;
});
const articleDate = computed(() => {
	if (!pageProperties.value?.articlePageProperties?.articleDate) return null;
	return dayjs(pageProperties.value.articlePageProperties.articleDate).format('DD MMM YYYY');
});
const articleTags = computed(() => {
	if (!pageProperties.value?.articlePageProperties?.categoryTags) return '';
	return pageProperties.value.articlePageProperties.categoryTags.map((tag) => tag.title).join(', ');
});

</script>


<template>
<div class="FireflyArticleLayout flex flex-col min-h-screen" data-layout="Article">
	<ExperienceFragment
		class="global-mobile-download-app-notification"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/mobile-download-app-notification/master"
	/>
	<div class="sticky-spacer h-$mobileDlAppNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-mobile-download-app-notification"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/mobile-download-app-notification/master"
	/>
	<div class="sticky-spacer h-$mobileDlAppNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header-notification"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/header-notification/master"
	/>
	<div class="sticky-spacer h-$headerNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/header/master"
		:disableTransparency="true"
	/>
	
	<!-- Only for this layout, header spacer -->
	<div class="sticky-spacer h-$headerHeight"></div>
	
	<FireflyHiddenSeamlessBookingWidget />

	<div id="content"></div>
	<div id="booking-subpage-container" class="article-booking-subpage-container"></div>
	
	<main>
		<div class="flex items-center z-20 bg-neutral-grey-ultralight py-5">
			<div class="generic-container">
				<Breadcrumb />
			</div>
		</div>
		
		<div class="generic-container my-12">
			<h1 v-if="articleTitle" class="heading-2 mb-5">
				{{ articleTitle }}
			</h1>
			<div class="text-primary-blue-base font-semibold uppercase">
				<time v-if="articleDate" class="article-date" dir="ltr">
					{{ articleDate }}
				</time>
				<template v-if="articleTags">
					<span class="px-2">|</span>
					<span
						class="article-category"
					>
						{{ articleTags }}
					</span>
				</template>
			</div>
		</div>
		
		<slot></slot>
	</main>
	
	<ExperienceFragment
		class="global-cookies-notification"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/cookies-notification/master"
	/>
	<ExperienceFragment
		class="global-footer"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/footer/master"
	/>
</div>
</template>


<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

// Minus margin-top header spacer on subpage container
.article-booking-subpage-container {
	margin-top: calc(var(--headerHeight) * -1);
}

</style>
