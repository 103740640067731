import { useRouter } from 'vue-router';
import { scrollToElement as ste } from '~/logic/helpers/dom.js';

class AnchorClass {
	constructor ({ scrollToElement }) {
		
		this._scrollToElement = scrollToElement;
		
		const anchorMap = new Map();
		this._anchorMap = anchorMap;
		
		this._onFunc = function _onFunc (eventID, callback) {
			switch (eventID) {
				case 'activate': {
					const mapVal = anchorMap.get(this.id);
					if (mapVal) {
						mapVal.onActivate.push(callback);
					}
					break;
				}
				// case 'scrollComplete': {
				// 	var mapVal2 = anchorMap.get(this.id);
				// 	if (mapVal2) {
				// 		mapVal2.onScrollComplete.push(callback);
				// 	}
				// 	break;
				// }

				default: {
					console.warn(`Anchor register callback: Event "${ eventID }" not recognized. Make sure it is spelled correctly.`);
					break;
				}
			}
		};
	}
	
	register (id, node, options = {}) {
		// check for existing id
		if (this._anchorMap.get(id)) {
			// if (!options.turnOffDuplicateWarning) console.warn(`Duplicated registration Anchor of id "${id}". If this is intended, use the option { turnOffDuplicateWarning: true } to surpress this warning.`);
			// new logic, now duplicate registration would just overwrite
			// this is changed because in SPA, it is common for component setup to run multiple times (for e.g. when user navigates the site)
		}
		
		this._anchorMap.set(id, {
			node,
			options,
			onActivate: [],
		});
		
		const obj = {
			id,
			node,
			options,
		};
		
		return {
			on: this._onFunc.bind(obj),
			...obj,
		};
	}
	
	unregister (id) {
		this._anchorMap.delete(id);
	}
	
	activate (id, _options = {}) {
		const mapVal = this._anchorMap.get(id);
		
		if (!mapVal) return false;
		
		const options = {
			performScroll: true,
			supressCallback: false,
			scrollOptions: {},
			routerVerb: 'replace',
			...(mapVal.options || null),
			..._options,
		};
		
		if (!options.supressCallback) {
			mapVal.onActivate.forEach((callback) => callback(mapVal.node));
		}
		
		if (options.updateHash) {
			if (window.router) {
				// by default, uses window.router.replace();
				window.router[options.routerVerb]?.({ hash: `#${id}` });
			} else {
				window.location.hash = id;
			}
		}
		
		if (options.performScroll) {
			this._scrollToElement(unref(mapVal.node), options.scrollOptions);
		}
	}
	
	get mappings () {
		return this._anchorMap;
	}
	
}


const Anchor = new AnchorClass({
	scrollToElement: ste,
});

Anchor.register('body', document.body);

export default Anchor;
