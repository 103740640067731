<script>
export default {
	name: 'PassengerAndCabinClass',
};
</script>

<script setup>
import { unref } from 'vue';
import Dropdown from '~/components/form/Dropdown.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import NumberPlusMinus from '~/components/form/NumberPlusMinus.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import { usePassengers } from '~composables/booking-widget/usePassengers.js';
import TextField from '~/components/form/TextField.vue';
import { i18nGlobal } from '~/logic/i18n.js';
import { useEventBus } from '@vueuse/core';


const props = defineProps({
	passengerAdultsName: { type: String, default: 'passengerAdults' },
	passengerTeensName: { type: String, default: 'passengerTeens' },
	passengerChildrenName: { type: String, default: 'passengerChildren' },
	passengerInfantsName: { type: String, default: 'passengerInfants' },
	cabinClassName: { type: String, default: 'cabinClass' },
	
	labelText: { type: String, default: i18nGlobal.t('Passengers and cabin class') },
	isUKVariant: { type: Boolean, default: false },
	defaultValues: { type: Object, default: () => ({}) },
	
	isShowEconomyClass: { type: Boolean, default: true },
	isShowBusinessClass: { type: Boolean, default: true },
	isShowBusinessSuiteClass: { type: Boolean, default: true },

	isShowChildren: { type: Boolean, default: true },

	passengerLabel: { type: String, default: i18nGlobal.t('Passengers') },
	adultLabel: { type: String, default: i18nGlobal.t('Adults') },
	teenagerLabel: { type: String, default: i18nGlobal.t('Teenagers') },
	childrenLabel: { type: String, default: i18nGlobal.t('Children') },
	infantLabel: { type: String, default: i18nGlobal.t('Infants') },
	cabinClassLabel: { type: String, default: i18nGlobal.t('Cabin Class') },
	economyLabel: { type: String, default: i18nGlobal.t('Economy') },
	businessLabel: { type: String, default: i18nGlobal.t('Business') },
	businessSuiteLabel: { type: String, default: i18nGlobal.t('Business Suite') },

	maxInfantsCount: { type: Number, default: null },

});
const emit = defineEmits([
	// 'update:modelValue',
]);

const dropdownEl = ref(null);

const computedCabinClassOptions = computed(() => {
	return [
		...(props.isShowEconomyClass ? [{
			label: props.economyLabel,
			value: '1',
		}] : []),
		...(props.isShowBusinessClass ? [{
			label: props.businessLabel,
			value: '2',
		}] : []),
		...(props.isShowBusinessSuiteClass ? [{
			label: props.businessSuiteLabel,
			value: '3',
		}] : []),
	];
});

const cabinClassValue = ref(props.defaultValues.cabinClassValue ?? computedCabinClassOptions.value[0] ?? {});

const handleSelectCabinClass = (item) => {
	cabinClassValue.value = item;
};

const {
	adults,
	teens,
	children,
	infants,
	totalPassengers,
	totalPassengersIncludedInfantCount,
	hasReachedMaxPassengersCount,
	showInfantErrorMsg,
} = usePassengers({
	maxPassengers: 9,
	
	...(props.maxInfantsCount ?
		{ maxInfantsCount: props.maxInfantsCount }
		: null
	),
	
	...(
		props.defaultValues.adultsCount ?
			{ defaultAdultsCount: props.defaultValues.adultsCount }
			: null
	),
	...(
		props.defaultValues.teensCount ?
			{ defaultTeensCount: props.defaultValues.teensCount }
			: null
	),
	...(
		props.defaultValues.childrenCount ?
			{ defaultChildrenCount: props.defaultValues.childrenCount }
			: null
	),
	...(
		props.defaultValues.infantsCount ?
			{ defaultInfantsCount: props.defaultValues.infantsCount }
			: null
	),
});

const globalBookFlightBus = useEventBus('booking-widget:passenger-detail');

watch(adults.count, (newVal) => {
	globalBookFlightBus.emit('update-passenger-count', { adults: newVal });
});

watch(children.count, (newVal) => {
	globalBookFlightBus.emit('update-passenger-count', { children: newVal });
});

watch(infants.count, (newVal) => {
	globalBookFlightBus.emit('update-passenger-count', { infants: newVal });
});

watch(cabinClassValue, (newVal) => {
	globalBookFlightBus.emit('update-passenger-count', { cabinClass: newVal.value });
});


const setValue = (key, value) => {
	switch (key) {
		case 'adults': {
			adults.count.value = value;
			break;
		}
		case 'teens': {
			teens.count.value = value;
			break;
		}
		case 'children': {
			children.count.value = value;
			break;
		}
		case 'infants': {
			infants.count.value = value;
			break;
		}
		case 'cabinClass': {
			if (typeof value !== 'object') {
				cabinClassValue.value = computedCabinClassOptions?.value.find((opt) => opt.value === value.toString()) ?? {};
			} else {
				cabinClassValue.value = value;
			}
			
			break;
		}
		default: {
			break;
		}
	}
};
const setValues = (obj) => {
	for (const [key, value] of Object.entries(obj)) {
		setValue(key, value);
	}
};


defineExpose({
	setValue,
	setValues,
	
	cabinClassValue,
	adults,
	teens,
	children,
	infants,
});

</script>

<template>
<div class="PassengerAndCabinClass">
	<Dropdown
		ref="dropdownEl"
		:labelText="props.labelText"
		:ariaLabel="props.labelText"
		desktopVariant="custom-html booking-widget"
		mobileVariant="no-padding custom-html"
	>
		<template #mobile-title>
			<slot name="mobile-title"></slot>
		</template>
		<template #placeholder>
			<div class="value-wrapper relative flex-grow mr-2 leading-none">
				<span class="text-label absolute font-semibold">
					{{ props.labelText }}
				</span>
				<span class="text-value relative xs:text-sm xs:leading-none">
					{{ $tc('{n} Passenger', {
						n: totalPassengersIncludedInfantCount,
					}) }}<span v-if="computedCabinClassOptions.length">, </span>
					{{ cabinClassValue.label }}
				</span>
			</div>
		</template>
		
		<template #nooptions="{ hideMobileOverlay }">
			<div class="multiselect-options styled-scrollbar pt-4 cursor-default mr-0 md:mr-2">
				<div class="px-4 typo-category-title mb-4">
					<p class="uppercase">{{ props.passengerLabel }}</p>
				</div>
				
				<div class="px-4 flex flex-col gap-4 ">
					<slot name="before-adults"></slot>
					<NumberPlusMinus
						v-model="adults.count.value"
						:name="props.passengerAdultsName"
						:ariaLabel="props.adultLabel"
						:ariaDescription="$t(props.isUKVariant ? 'Ages 16 and above' : 'Ages 12 and above')"
						:min="unref(adults.min)"
						:max="hasReachedMaxPassengersCount ? adults.count.value : unref(adults.max)"
					>
						<template v-if="$slots['adult-description-label']" #description>
							<slot name="adult-description-label"></slot>
						</template>
					</NumberPlusMinus>
					
					<slot v-if="props.isUKVariant" name="before-teens"></slot>
					<NumberPlusMinus
						v-if="props.isUKVariant"
						v-model="teens.count.value"
						:name="props.passengerTeensName"
						:ariaLabel="props.teenagerLabel"
						:ariaDescription="$t('Ages 12 to 15')"
						:min="unref(teens.min)"
						:max="hasReachedMaxPassengersCount ? teens.count.value : unref(teens.max)"
					>
						<template v-if="$slots['teen-description-label']" #description>
							<slot name="teen-description-label"></slot>
						</template>
					</NumberPlusMinus>
					<template v-if="props.isShowChildren">
						<slot name="before-children"></slot>
						<NumberPlusMinus
							v-model="children.count.value"
							:name="props.passengerChildrenName"
							:ariaLabel="props.childrenLabel"
							:ariaDescription="$t('Ages 2 to 11')"
							:min="unref(children.min)"
							:max="hasReachedMaxPassengersCount ? children.count.value : unref(children.max)"
						>
							<template v-if="$slots['children-description-label']" #description>
								<slot name="children-description-label"></slot>
							</template>
						</NumberPlusMinus>
					</template>
					<slot name="before-infants"></slot>
					<NumberPlusMinus
						v-model="infants.count.value"
						:name="props.passengerInfantsName"
						:ariaLabel="props.infantLabel"
						:ariaDescription="$t('Ages below 2 for the entire journey')"
						:min="unref(infants.min)"
						:max="unref(infants.max)"
					>
						<template v-if="$slots['infant-description-label']" #description>
							<slot name="infant-description-label"></slot>
						</template>
					</NumberPlusMinus>
				</div>
				
				<div
					v-show="showInfantErrorMsg"
					class="error-msg-container px-4 leading-tight flex text-semantic-red-base text-sm md:flex-col mt-2"
					:role="showInfantErrorMsg ? 'alert' : null"
				>
					<p>{{ $t('Each infant should be accompanied by one adult') }}</p>
				</div>
				
				<div v-if="$slots['after-passengers']" class="px-4 mt-4 mb-4">
					<slot name="after-passengers"></slot>
				</div>
				
				<div v-if="computedCabinClassOptions.length" class="mb-4">
					<hr class="bg-neutral-grey" />
				</div>
				
				<div v-if="computedCabinClassOptions.length" class="px-4 typo-category-title mb-4">
					<p class="uppercase">{{ props.cabinClassLabel }}</p>
				</div>
				
				
				<div v-if="computedCabinClassOptions.length" class="px-4 flex flex-wrap gap-4 mb-6 md:flex-col md:gap-2">
					<slot name="before-cabin-class-options"></slot>
					
					<CTAButton
						v-for="item in computedCabinClassOptions"
						:key="item.value"
						:styleType="cabinClassValue.value === item.value ? 'primary' : 'secondary'"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						@click="handleSelectCabinClass(item)"
					>
						<span class="font-semibold">{{ item.label }}</span>
					</CTAButton>
					
					<div class="sr-only" role="alert">
						{{ $t('Currently selected cabin class is {selectedCabinLabel}', { selectedCabinLabel: cabinClassValue.label }) }}
					</div>
				</div>
			</div>
			<div class="sticky bottom-0 bg-white py-4 px-4 border-t-2 border-neutral-grey-base >md:hidden">
				<CTAButton
					alignmentMobile="right"
					@click="hideMobileOverlay"
				>{{ $t('Done') }}</CTAButton>
			</div>
		</template>
	</Dropdown>
	
	<TextField
		variant="input-hidden"
		:name="props.cabinClassName"
		:ariaLabel="$t('Selected cabin class')"
		:modelValue="cabinClassValue.value"
	/>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.PassengerAndCabinClass {
	
}

</style>
