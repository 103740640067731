<script>
export default {
	name: 'FontAwesomeLazy',
	inheritAttrs: false,
};
</script>

<script setup>
import { useFontAwesomeLazyStore } from '~/logic/aem/font-awesome-lazy-store.js';


const props = defineProps({
	iconId: { type: String, default: '@@_EMPTY_VALUE' },
	iconStyle: { type: String, default: 'regular' },
	useImgTag: { type: Boolean, default: false },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

// import.meta.env.VITE_CLIENTLIB_SITE_PATH

const svgContent = ref(null);

const svgPath = computed(() => {
	const ICON_ID_PREFIX = `${import.meta.env.VITE_CLIENTLIB_SITE_PATH}/resources/font-awesome-svg`;
	return `${ICON_ID_PREFIX}/${props.iconStyle}/${props.iconId}.svg`;
});

watch(() => [props.iconId, props.iconStyle], () => {
	if (!props.iconId) return;
	const fontAwesomeLazyStore = useFontAwesomeLazyStore();
	
	if (!fontAwesomeLazyStore[svgPath.value]) {
		fontAwesomeLazyStore[svgPath.value] = axios.get(svgPath.value);
	}
	
	fontAwesomeLazyStore[svgPath.value].then(({ data }) => {
		svgContent.value = data;
	}).catch((reason) => {
		console.log('Unable to fetch font-awesome svg. iconId = ', props.iconId);
		console.log(reason);
		svgContent.value = '<!-- Fetch svg error -->';
	});
}, { immediate: true });


</script>

<template>
<img
	v-if="useImgTag"
	class="FontAwesomeLazy"
	:src="svgPath"
	v-bind="$attrs"
/>
<div
	v-else
	v-html-sanitize="svgContent"
	class="FontAwesomeLazy"
	v-bind="$attrs"
></div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FontAwesomeLazy {
	
}

</style>
