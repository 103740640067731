<script>
export default {
	name: 'MobileDownloadAppNotification',
	inheritAttrs: false,
	editConfig: {
		emptyLabel: 'Mobile Download App Notification',
		resourceType: 'mh/components/mobile-download-app-notification',
		isEmpty (props) {
			return true;
		},
	},
};
</script>

<script setup>
import { getCookie, setCookie } from '~/logic/helpers/cookies.js';
import { useGlobalCookieSettings } from '~/logic/global-state/cookie-settings.js';

const props = defineProps({
	downloadLogoPath: { type: String, default: '' },
	downloadTitle: { type: String, default: '' },
	downloadText: { type: String, default: '' },
	ctaText: { type: String, default: '' },
	appleCtaURL: { type: String, default: '' },
	androidCtaURL: { type: String, default: '' },
	huaweiCtaURL: { type: String, default: '' },
	fallbackURL: { type: String, default: '' },
	disableBox: { type: Boolean, default: false },
});

const isVisible = ref(true);
const COOKIE_ID = 'DL_APP_NOTIFICATION_VISIBLE';

const { isEnabledCookiePreferences, registerPreferenceCookie } = useGlobalCookieSettings();
registerPreferenceCookie(COOKIE_ID);

const dismissBanner = () => {
	isVisible.value = false;
	setCSSCustomPropertyToZero();

	const d = new Date();
	d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
	const expires = d.toUTCString();
	if (isEnabledCookiePreferences.value) setCookie(COOKIE_ID, 'hidden', expires);
};

const getAppStoreUrl = computed(() => {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;
	if (/huawei/i.test(userAgent)) {
		return props.huaweiCtaURL;
	}
	if (/android/i.test(userAgent)) {
		return props.androidCtaURL;
	}
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
		return props.appleCtaURL;
	}
	return props.fallbackURL;
});

const setCSSCustomPropertyToZero = () => {
	document.documentElement.style.setProperty('--mobileDlAppNotificationHeight', '0px');
};

if (getCookie(COOKIE_ID) || props.disableBox) {
	isVisible.value = false;
	setCSSCustomPropertyToZero();
}

const siteName = window.siteName;
</script>



<template>
<div
	v-if="isVisible && !props.disableBox"
	class="MobileDownloadAppNotification bg-neutral-grey-extralight"
	data-is-sticky
	data-height-var="--mobileDlAppNotificationHeight"
	:data-use-theme="siteName"
>
	<div class="flex items-center justify-between generic-container h-full items-center">
		<div class="flex flex-grow">
			<div class="mr-3 h-8 w-8 flex-shrink-0 flex justify-content items-center rtl:mr-0 rtl:ml-3">
				<img class="logo-img" :src="props.downloadLogoPath" :alt="$t('Malaysia Airlines mobile app icon')" />
			</div>
			<div class="flex-grow" :class="{ 'flex items-center': !props.downloadText }">
				<h4 class="notification-title font-semibold text-xs mb-1 line-clamp-1" :class="{ 'mb-0': !props.downloadText }">{{ props.downloadTitle }}</h4>
				<p v-if="props.downloadText" class="MobileDownloadAppNotificationText">{{ props.downloadText }}</p>
			</div>
		</div>
		<div class="flex flex-shrink-0">
			<AppHyperlink
				:href="getAppStoreUrl"
				class="MobileDownloadAppNotificationCtaButton"
				:showExternalLinkIcon="false"
			>
				<span class="text-center line-clamp-2 break-words">
					{{ props.ctaText }}
				</span>
			</AppHyperlink>
			<button
				class="ml-5 p-0 rtl:ml-0 rtl:mr-5"
				type="button"
				:aria-label="$t('Dismiss mobile download app banner')"
				@click="dismissBanner"
			>
				<div class="w-6 h-6 flex items-center justify-center">
					<icon-fas-xmark class="w-2.5 fill-primary-black-base" />
				</div>
			</button>
		</div>
	</div>
</div>
</template>


<style lang="scss">
@use '~/styles/partials/_var.scss';

html {
	--mobileDlAppNotificationHeight: 0px;
	--mobileDlAppNotificationTranslateY: 0%;
	
	@media #{var.$query-max-md} {
		--mobileDlAppNotificationHeight: 48px;
	}
}
.global-mobile-download-app-notification {
	/* position: sticky; */
	position: fixed;
	top: 0;
	z-index: 8010;
	height: var(--mobileDlAppNotificationHeight);
	overflow: hidden;
	transform: translateY(var(--mobileDlAppNotificationTranslateY));
	transition: transform var(--pinnedElementTransitionDuration);
	
	&.is-slow-transition {
		transition: transform 0.45s;
	}
	
	> .aem-GridColumn {
		float: none !important;
	}
}
.aem-AuthorLayer-Edit, .aem-AuthorLayer-structure {
	.global-mobile-download-app-notification {
		position: static;
	}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MobileDownloadAppNotification {
	width: 100%;
	max-width: 100vw;
	overflow-x: hidden;
	/* position: relative;
	z-index: 8000; */
	height: var(--mobileDlAppNotificationHeight);
}
.MobileDownloadAppNotificationText {
	font-size: 10px;
	@apply line-clamp-1;
}
.MobileDownloadAppNotificationCtaButton {
	display: flex;
	align-items: center;
	color: var(--primary-blue-base);

	@apply font-semibold text-xs max-w-15.5 ml-5;

	&:hover {
		color: var(--primary-blue-light);
		text-decoration: underline;
	}

	
	[dir="rtl"] & {
		@apply ml-0 mr-5;
	}
}

.MobileDownloadAppNotification[data-use-theme="firefly"] {
	background-color: #fde6d2; // hardcoding this color, because in design, it is "secondary-firefly-orange-base" in 20% opacity
	
	.notification-title {
		@apply font-light;
	}
	
	.MobileDownloadAppNotificationCtaButton {
		color: var(--primary-black-base);
	
		&:hover {
			color: var(--secondary-firefly-orange-medium);
		}
	}
}

</style>
