import { createGlobalState } from '@vueuse/core';

import planeUp from '~/../fa-svg/regular/plane-up.svg?raw';
import plane from '~/../fa-svg/regular/plane.svg?raw';
import hotel from '~/../fa-svg/regular/hotel.svg?raw';
import ticketAirline from '~/../fa-svg/regular/ticket-airline.svg?raw';
import passport from '~/../fa-svg/regular/passport.svg?raw';
import calendar from '~/../fa-svg/regular/calendar.svg?raw';
import ticket from '~/../fa-svg/regular/ticket.svg?raw';
import fileCircleCheck from '~/../fa-svg/regular/file-circle-check.svg?raw';
import bagShopping from '~/../fa-svg/regular/bag-shopping.svg?raw';
import circleUser from '~/../fa-svg/regular/circle-user.svg?raw';
import circlePlus from '~/../fa-svg/solid/circle-plus.svg?raw';
import tiktok from '~/../fa-svg/regular/tiktok.svg?raw';
import youtube from '~/../fa-svg/regular/youtube.svg?raw';
import facebook from '~/../fa-svg/regular/facebook.svg?raw';
import twitter from '~/../fa-svg/regular/twitter.svg?raw';
import weibo from '~/../fa-svg/regular/weibo.svg?raw';
import instagram from '~/../fa-svg/regular/instagram.svg?raw';
import shieldCheck from '~/../fa-svg/regular/shield-check.svg?raw';
import suitcase from '~/../fa-svg/regular/suitcase.svg?raw';

const ICON_ID_PREFIX = `${import.meta.env.VITE_CLIENTLIB_SITE_PATH}/resources/font-awesome-svg`;

export const useFontAwesomeLazyStore = createGlobalState(
	() => {
		return {
			[`${ICON_ID_PREFIX}/regular/@@_EMPTY_VALUE.svg`]: Promise.resolve({ data: '<!-- Empty iconId -->' }),
			// [`${ICON_ID_PREFIX}/regular/file_name.svg`]: Promise.resolve({ data: 'file_content' }),
			[`${ICON_ID_PREFIX}/regular/plane-up.svg`]: Promise.resolve({ data: planeUp }),
			[`${ICON_ID_PREFIX}/regular/plane.svg`]: Promise.resolve({ data: plane }),
			[`${ICON_ID_PREFIX}/regular/hotel.svg`]: Promise.resolve({ data: hotel }),
			[`${ICON_ID_PREFIX}/regular/ticket-airline.svg`]: Promise.resolve({ data: ticketAirline }),
			[`${ICON_ID_PREFIX}/regular/passport.svg`]: Promise.resolve({ data: passport }),
			[`${ICON_ID_PREFIX}/regular/calendar.svg`]: Promise.resolve({ data: calendar }),
			[`${ICON_ID_PREFIX}/regular/ticket.svg`]: Promise.resolve({ data: ticket }),
			[`${ICON_ID_PREFIX}/regular/file-circle-check.svg`]: Promise.resolve({ data: fileCircleCheck }),
			[`${ICON_ID_PREFIX}/regular/bag-shopping.svg`]: Promise.resolve({ data: bagShopping }),
			[`${ICON_ID_PREFIX}/regular/circle-user.svg`]: Promise.resolve({ data: circleUser }),
			[`${ICON_ID_PREFIX}/solid/circle-plus.svg`]: Promise.resolve({ data: circlePlus }),
			[`${ICON_ID_PREFIX}/regular/tiktok.svg`]: Promise.resolve({ data: tiktok }),
			[`${ICON_ID_PREFIX}/regular/youtube.svg`]: Promise.resolve({ data: youtube }),
			[`${ICON_ID_PREFIX}/regular/facebook.svg`]: Promise.resolve({ data: facebook }),
			[`${ICON_ID_PREFIX}/regular/twitter.svg`]: Promise.resolve({ data: twitter }),
			[`${ICON_ID_PREFIX}/regular/weibo.svg`]: Promise.resolve({ data: weibo }),
			[`${ICON_ID_PREFIX}/regular/instagram.svg`]: Promise.resolve({ data: instagram }),
			[`${ICON_ID_PREFIX}/regular/shield-check.svg`]: Promise.resolve({ data: shieldCheck }),
			[`${ICON_ID_PREFIX}/regular/suitcase.svg`]: Promise.resolve({ data: suitcase }),
		};
	},
);
