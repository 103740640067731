<script>
export default {
	name: 'FlightHotelSearch',
	inheritAttrs: false,
};
</script>

<script setup>
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import VueTabs from '~/components/VueTabs.vue';
import SubTabFlightAndHotel from '~/components/BookingWidget/FlightHotelSearch/SubTabFlightAndHotel.vue';
import SubTabHotel from '~/components/BookingWidget/FlightHotelSearch/SubTabHotel.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';
import { pushAA_flightPlusHotelStart, pushAA_flightPlusHotelEnd, pushAA_click } from '~/logic/adobe-analytic.js';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	flightHotelSearch: { type: Object, default: null },
	hotelTab: { type: Object, default: null },
	mhHolidays: { type: Object, default: null },
	flightHotelSearchApiURL: { type: String, default: '' },
	hotelSearchApiURL: { type: String, default: '' },
	isInBookingWidget: { type: Boolean, default: false },
	dropDownHotelListApiUrl: { type: String, default: '' },
	cultureCode: { type: String, default: '' },
});

const emit = defineEmits([
	'update:active-sub-tab',
]);

const rootEl = ref(null);

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['flight-hotel-search', 'flight-hotel-search-tab'],
	rootEl,
});

const activeSubTab = ref('flight-hotel');
const { isEditorMode } = useGlobalAEMState();

//  AA for sub tab click tracking
watch(activeSubTab, (newTabName) => {
	pushAA_click([
		{
			clickName: newTabName,
			clickComponentType: 'URL',
			componentName: 'FlightHotelSearch',
			componentID: props.componentIdAA,
		},
		{
			name: newTabName,
			type: 'other',
		},
	]);
});


let AAHasSentStart = false;
let AAHasSentEnd = false;

onMounted(() => {
	const aaFormStartHandler = (event) => {
		if (AAHasSentStart) return;
		if (event.target.tagName === 'FORM') return;
		
		AAHasSentStart = true;
		pushAA_flightPlusHotelStart(activeSubTab.value);
		rootEl.value.removeEventListener('focus', aaFormStartHandler, { capture: true });
	};
	rootEl.value.addEventListener('focus', aaFormStartHandler, { capture: true });
});

const sendFormCompleteAA = (subtabName) => {
	if (AAHasSentEnd) return;
	AAHasSentEnd = true;
	pushAA_flightPlusHotelEnd(subtabName);
};

</script>



<template>
<div
	ref="rootEl"
	class="FlightHotelSearch booking-widget-subcomponent"
	:class="{
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Flight Hotel + Search</span>
		</h5>
		
		<VueTabs
			v-model="activeSubTab"
			:dormantMode="isEditorMode"
			renderPanelsWithVShow
			@update:modelValue="$emit('update:active-sub-tab', $event)"
		>
			<template v-if="!props.flightHotelSearch?.isHideInDesktop && props.flightHotelSearch?.label" #tab-flight-hotel>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.flightHotelSearch?.label }}</span>
				</div>
			</template>
			<template v-if="!props.hotelTab?.isHideInDesktop && props.hotelTab?.label" #tab-hotel>
				<div class="flex items-center justify-center p-4">
					<span class="inline-block ml-1">{{ props.hotelTab?.label }}</span>
				</div>
			</template>
			<template v-if="!props.mhHolidays?.isHideInDesktop && props.mhHolidays?.subHeaderTab3Label" #link-mhholidays>
				<AppHyperlink
					v-aa="[
						{
							clickName: props.mhHolidays?.subHeaderTab3Label,
							clickComponentType: 'URL',
							componentName: 'FlightHotelSearch',
							componentID: props.componentIdAA,
						},
						{
							name: props.mhHolidays?.subHeaderTab3Label,
							type: isUrlMatchCurrentHostname(props.mhHolidays?.ctaPath) ? 'other' : 'exit',
						},
					]"
					:href="props.mhHolidays?.ctaPath"
					class="font-semibold text-$text-color rounded-xl px-4 hover:bg-neutral-grey-extralight h-full leading-none"
					:target="props.mhHolidays.openInNewTab ? '_blank' : '_self'"
					:autoExternalLinkIcon="true"
				>{{ props.mhHolidays?.subHeaderTab3Label }}</AppHyperlink>
			</template>
			
			<div
				v-if="!props.flightHotelSearch?.isHideInDesktop && props.flightHotelSearch?.label"
				identifier="flight-hotel"
				:class="{
					'py-10': isEditorMode,
				}"
			>
				<SubTabFlightAndHotel
					class="pt-6"
					:datePicker="props.flightHotelSearch.datePicker"
					:description="props.flightHotelSearch.description"
					:tooltipLabel="props.flightHotelSearch.tooltipLabel"
					:tooltipBody="props.flightHotelSearch.tooltipBody"
					:ondSelection="props.flightHotelSearch.ondSelection"
					:travellerSelection="props.flightHotelSearch.travellerSelection"
					:cabinClass="props.flightHotelSearch.cabinClass"
					:note="props.flightHotelSearch.note"
					:productID="props.flightHotelSearch.productID"
					:endpoint="props.flightHotelSearchApiURL"
					:cultureCode="props.cultureCode"
					@form-submission="sendFormCompleteAA('flight-hotel', $event)"
				></SubTabFlightAndHotel>
			</div>
			<div
				v-if="!props.hotelTab?.isHideInDesktop && props.hotelTab?.label"
				identifier="hotel"
				:class="{
					'py-10': isEditorMode,
				}"
			>
				<SubTabHotel
					class="pt-6"
					:datePicker="props.hotelTab.datePicker"
					:description="props.hotelTab.description"
					:tooltipLabel="props.hotelTab.tooltipLabel"
					:tooltipBody="props.hotelTab.tooltipBody"
					:hotelSelection="props.hotelTab.hotelSelection"
					:travellerSelection="props.hotelTab.travellerSelection"
					:note="props.hotelTab.note"
					:productID="props.hotelTab.productID"
					:endpoint="props.hotelSearchApiURL"
					:hotelSearchPropertyUrl="props.hotelTab.hotelSearchPropertyUrl"
					:dropDownHotelListApiUrl="props.dropDownHotelListApiUrl"
					:cultureCode="props.cultureCode"
					@form-submission="sendFormCompleteAA('hotel', $event)"
				></SubTabHotel>
			</div>
		</VueTabs>
	</div>
</div>

<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event"
>
	
	<template #banner-title>
		<h1 class="text-center">Flight & Hotel Search</h1>
	</template>
	
	<template #default>
		<div class="generic-container">
			<VueTabs
				v-model="activeSubTab"
				:dormantMode="isEditorMode"
				renderPanelsWithVShow
				@update:modelValue="$emit('update:active-sub-tab', $event)"
			>
				<template v-if="!props.flightHotelSearch?.isHideInMobile" #tab-flight-hotel>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.flightHotelSearch?.label }}</span>
					</div>
				</template>
				<template v-if="!props.hotelTab?.isHideInMobile" #tab-hotel>
					<div class="flex items-center justify-center p-4">
						<span class="inline-block ml-1">{{ props.hotelTab?.label }}</span>
					</div>
				</template>
				<template v-if="!props.mhHolidays?.isHideInMobile && props.mhHolidays?.subHeaderTab3Label" #link-mhholidays>
					<AppHyperlink
						v-aa="[
							{
								clickName: props.mhHolidays?.subHeaderTab3Label,
								clickComponentType: 'URL',
								componentName: 'FlightHotelSearch',
								componentID: props.componentIdAA,
							},
							{
								name: props.mhHolidays?.subHeaderTab3Label,
								type: isUrlMatchCurrentHostname(props.mhHolidays?.ctaPath) ? 'other' : 'exit',
							},
						]"
						:href="props.mhHolidays?.ctaPath"
						class="font-semibold text-$text-color rounded-xl px-4 hover:bg-neutral-grey-extralight h-full leading-none"
						:target="props.mhHolidays.openInNewTab ? '_blank' : '_self'"
						:autoExternalLinkIcon="true"
					>{{ props.mhHolidays?.subHeaderTab3Label }}</AppHyperlink>
				</template>
				
				<div v-if="!props.flightHotelSearch?.isHideInMobile" identifier="flight-hotel">
					<SubTabFlightAndHotel
						class="pt-6"
						:datePicker="props.flightHotelSearch.datePicker"
						:description="props.flightHotelSearch.description"
						:tooltipLabel="props.flightHotelSearch.tooltipLabel"
						:tooltipBody="props.flightHotelSearch.tooltipBody"
						:ondSelection="props.flightHotelSearch.ondSelection"
						:travellerSelection="props.flightHotelSearch.travellerSelection"
						:cabinClass="props.flightHotelSearch.cabinClass"
						:note="props.flightHotelSearch.note"
						:productID="props.flightHotelSearch.productID"
						:endpoint="props.flightHotelSearchApiURL"
						:cultureCode="props.cultureCode"
						@form-submission="sendFormCompleteAA('flight-hotel', $event)"
					></SubTabFlightAndHotel>
				</div>
				
				<div v-if="!props.hotelTab?.isHideInMobile" identifier="hotel">
					<SubTabHotel
						class="pt-6"
						:datePicker="props.hotelTab.datePicker"
						:description="props.hotelTab.description"
						:tooltipLabel="props.hotelTab.tooltipLabel"
						:tooltipBody="props.hotelTab.tooltipBody"
						:hotelSelection="props.hotelTab.hotelSelection"
						:travellerSelection="props.hotelTab.travellerSelection"
						:note="props.hotelTab.note"
						:productID="props.hotelTab.productID"
						:endpoint="props.hotelSearchApiURL"
						:hotelSearchPropertyUrl="props.hotelTab.hotelSearchPropertyUrl"
						:dropDownHotelListApiUrl="props.dropDownHotelListApiUrl"
						:cultureCode="props.cultureCode"
						@form-submission="sendFormCompleteAA('hotel', $event)"
					></SubTabHotel>
				</div>
			</VueTabs>
		</div>
	</template>
	
</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FlightHotelSearch {
	
}

</style>
