import { h } from 'vue';


const WrapVNodes = (props) => {
	const nodesArray = Array.isArray(props.nodes) ? props.nodes : [props.nodes];
	
	return nodesArray.map((vnode) => {
		return h(vnode, { ...props.nodesProps });
	});
};

WrapVNodes.props = {
	tag: { type: String, default: 'div' },
	nodesProps: { type: Object, default: () => ({}) },
	nodes: { type: [Array, Function, Object], default: null },
};

export default WrapVNodes;
