<script>
export default {
	name: 'TextComponent',
	editConfig: {
		emptyLabel: 'Text Component',
		isEmpty (props) {
			return !props.text;
		},
	},
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
// import DOMPurify from 'dompurify';
import DOMPurify from '~/../custom-modules/dompurify/dompurify.js';

const props = defineProps({
	text: { type: String, default: '' },
	mobileText: { type: String, default: '' },
	
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
});

const emit = defineEmits([
	// 'update:modelValue',
]);


const textDesktopSanitized = computed(() => {
	return DOMPurify.sanitize(props.text);
});
const textMobileSanitized = computed(() => {
	return DOMPurify.sanitize(props.mobileText);
});

</script>



<!-- Here we maintain v-html as it was already sanitized by using computed in the above logic -->
<!-- eslint-disable vue/no-v-html -->
<template>
<div
	v-if="!props.isHideInDesktop && textDesktopSanitized"
	class="TextComponent overflow-x-clip author-html-container is-desktop"
	:class="textMobileSanitized ? 'md:hidden' : ''"
	data-rte-editelement
	v-html="textDesktopSanitized"
></div>
<div
	v-if="!props.isHideInMobile && textMobileSanitized"
	class="TextComponent overflow-x-clip author-html-container is-mobile >md:hidden"
	data-rte-editelement
	v-html="textMobileSanitized"
></div>
</template>


<!-- For CSS: -->
<!-- See '.author-html-container' css in main.scss -->
