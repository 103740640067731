import { h, defineComponent, getCurrentInstance, computed } from 'vue';
import { setMapping, getMappedComponent } from '../ComponentMapping.jsx';
import { AllowedComponentsPlaceholderList } from './allowed-components/AllowedComponentsPlaceholderList.jsx';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';

import { Constants } from '../Constants.js';
import Utils from '../Utils.js';
import { modelProvider } from './composables/model-provider.js';
import { editableProvider } from './composables/editable-provider.js';


import { refreshAEMEditableOverlays } from '~/logic/aem/refresh-aem-editable-overlays.js';


const ResponsiveGrid = defineComponent({
	name: 'ResponsiveGrid',
	editConfig: {
		resourceType: 'wcm/foundation/components/responsivegrid',
		isEmpty (props) {
			return props.cqItemsOrder && (props.cqItemsOrder.length > 0);
		},
	},
	components: {
		AllowedComponentsPlaceholderList,
	},
	inheritAttrs: false,
	props: {
		columnClassNames: { type: Object, default: () => ({}) },
		gridClassNames: { type: String, default: '' },
		
		// eslint-disable-next-line
		_AllowedComponentsPlaceholderListEmptyLabel: { type: String, default: 'No allowed components' },
		allowedComponents: { type: Object, default: () => ({}) },
		title: { type: String, default: '' },
		
		cqForceReload: { type: Boolean, default: false },
		cqPath: { type: String, default: '' },
		cqItems: { type: Object, default: () => ({}) },
		cqItemsOrder: { type: Array, default: () => [] },
		
		columnCount: { type: Number, default: 12 },
		cqType: { type: String, default: '' },
		appliedCssClassNames: { type: String, default: '' },
		
		containerClass: { type: String, default: '' },
		
		pagePath: { type: String, default: '' },
		itemPath: { type: String, default: '' },
		hasEditableCapability: { type: Boolean, default: true },
	},
	setup (props, context) {
		const instance = getCurrentInstance();
		const instanceEditConfig = instance.type.editConfig;
		
		const {
			aemProperties,
			componentId,
			computedCqPath,
		} = modelProvider({ props, context });
		
		const {
			wrapperHtmlAttributes,
			wrapperHtmlClass,
			emptyPlaceholderDiv,
		} = editableProvider({ aemProperties, computedCqPath, props, context, editConfig: instanceEditConfig });
		
		
		const getItemComponent = (ItemComponent, itemProps, itemKey) => {
			const { cqPath, columnClassNames } = aemProperties.value;
			
			const itemPath = cqPath ? (`${cqPath}/${itemKey}`) : itemKey;
			const containerClass = `${itemProps?.className ?? ''} ${columnClassNames?.[itemKey] ?? ''}`;
			
			return (
				<ItemComponent
					{...itemProps}
					cqPath={itemPath}
					containerClass={containerClass}
					key={itemKey}
				/>
			);
		};
		const childComponents = computed(() => {
			const { cqItems, cqItemsOrder } = aemProperties.value;
			if (!cqItems || !cqItemsOrder) return [];
			
			return cqItemsOrder.map((itemKey) => {
				const itemProps = Utils.modelToProps(cqItems[itemKey]);
				if (!itemProps) return null;
				
				const ItemComponent = getMappedComponent(itemProps.cqType);
				if (!ItemComponent) return null;
				return getItemComponent(ItemComponent, itemProps, itemKey);
			});
		});
		
		
		const placeholderProps = computed(() => {
			const { cqPath } = aemProperties.value;
			return {
				'class': `${Constants.NEW_SECTION_CLASS_NAMES} ${Constants._RESPONSIVE_GRID_PLACEHOLDER_CLASS_NAMES}`,
				'data-cq-data-path': `${computedCqPath.value}/*`,
			};
		});
		const placeholderComponent = computed(() => {
			if (!AuthoringUtils.isInEditor()) return null;
			
			return (
				<div
					{...placeholderProps.value}
				></div>
			);
		});
		
		const mergedWrapperClass = computed(() => {
			return `${wrapperHtmlClass.value} ${aemProperties.value.appliedCssClassNames}`;
		});
		
		
		onMounted(() => {
			setTimeout(() => {
				if (AuthoringUtils.isInEditor()) refreshAEMEditableOverlays();
			}, 2000);
		});
		
		return {
			aemProperties,
			componentId,
			computedCqPath,

			wrapperHtmlAttributes,
			wrapperHtmlClass,
			mergedWrapperClass,
			emptyPlaceholderDiv,
			
			childComponents,
			
			placeholderProps,
			placeholderComponent,
		};
	},
	render () {
		const { _AllowedComponentsPlaceholderListEmptyLabel: emptyLabel } = this;
		const shouldRenderPlaceholderList = AuthoringUtils.isInEditor() && this.allowedComponents?.applicable && emptyLabel;
		
		if (shouldRenderPlaceholderList) {
			return (
				<div
					{...this.wrapperHtmlAttributes}
					class={this.mergedWrapperClass}
					key={this.componentId}
					data-component="ResponsiveGrid"
				>
					<AllowedComponentsPlaceholderList
						title={this.title}
						emptyLabel={emptyLabel}
						components={this.allowedComponents.components}
						placeholderClass={this.placeholderProps.class}
						cqPath={this.cqPath}
					/>
				</div>
			);
		}
		
		const wrapperAttrs = {
			class: `${Constants._CONTAINER_CLASS_NAMES} ${this.aemProperties.gridClassNames}`,
			...(AuthoringUtils.isInEditor() ? { 'data-cq-data-path': this.computedCqPath } : null),
		};
		
		return (
			<div
				{...this.wrapperHtmlAttributes}
				class={this.mergedWrapperClass}
				key={this.componentId}
				data-component="ResponsiveGrid"
			>
				<div
					{...wrapperAttrs}
				>
					{this.childComponents}
					{this.placeholderComponent}
				</div>
				
				{
					AuthoringUtils.isInEditor() ?
						<div class={Constants._PLACEHOLDER_CLASS_NAMES}></div>
						: null
				}
			</div>
		);
	},
});

// used to be withComponentMappingContext(ResponsiveGrid)
// MapTo('wcm/foundation/components/responsivegrid', { skipWrapping: true })(ResponsiveGrid, {
// 	isEmpty (props) {
// 		return props.cqItemsOrder && (props.cqItemsOrder.length > 0);
// 	},
// });

setMapping('wcm/foundation/components/responsivegrid', ResponsiveGrid);


export {
	ResponsiveGrid,
};
