import { debounce as _debounce } from 'lodash';


const _refreshAEMEditableOverlays = () => {
	// below is a way I painstakingly found to manually refresh the edit overlays.
	// it is undocumented, but works as of AEM 6.5.13
	
	// this works too
	/* window.parent.Granite.author.ContentFrame.loadEditablesAsync(true).then((editables) => {
		window.parent.Granite.author.overlayManager.recreateAll({ editables });
	}); */
	
	const editables = window.parent.Granite.author.ContentFrame.loadEditables();
	window.parent.Granite.author.overlayManager.recreateAll({ editables });
};


const refreshAEMEditableOverlays = _debounce(_refreshAEMEditableOverlays, 500);

export {
	refreshAEMEditableOverlays,
};

