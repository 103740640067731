import DefaultLayout from '~/layout/DefaultLayout.vue';
import HomepageLayout from '~/layout/HomepageLayout.vue';
import EmptyPageLayout from '~/layout/EmptyPageLayout.vue';
import DestinationDetailsLayout from '~/layout/DestinationDetailsLayout.vue';
import ArticleLayout from '~/layout/ArticleLayout.vue';

import MAGHomepageLayout from '~/layout/MAGHomepageLayout.vue';
import MAGDefaultLayout from '~/layout/MAGDefaultLayout.vue';
import MAGArticleLayout from '~/layout/MAGArticleLayout.vue';

import MHHDefaultLayout from '~/layout/mhh/MHHDefaultLayout.vue';
import MHHHomepageLayout from '~/layout/mhh/MHHHomepageLayout.vue';

import MABAHomepageLayout from '~/layout/maba/MABAHomepageLayout.vue';
import MABADefaultLayout from '~/layout/maba/MABADefaultLayout.vue';
import MABAArticleLayout from '~/layout/maba/MABAArticleLayout.vue';

import AmalHomepageLayout from '~/layout/amal/AmalHomepageLayout.vue';
import AmalDefaultLayout from '~/layout/amal/AmalDefaultLayout.vue';
import AmalArticleLayout from '~/layout/amal/AmalArticleLayout.vue';

import FireflyHomepageLayout from '~/layout/firefly/FireflyHomepageLayout.vue';
import FireflyDefaultLayout from '~/layout/firefly/FireflyDefaultLayout.vue';
import FireflyArticleLayout from '~/layout/firefly/FireflyArticleLayout.vue';

const TEMPLATE_NAME_TO_LAYOUT_MAP = {
	'spa-root-page-template': DefaultLayout,
	'spa-content-page-template': DefaultLayout,
	'spa-homepage-template': HomepageLayout,
	'spa-empty-page-template': EmptyPageLayout,
	'spa-404-page-template': DefaultLayout,
	'spa-destination-details-page-template': DestinationDetailsLayout,
	'spa-article-page-template': ArticleLayout,
	'xf-page-template': EmptyPageLayout,
	
	// MAG
	'mag-spa-root-page-template': MAGDefaultLayout,
	'mag-spa-homepage-template': MAGHomepageLayout,
	'mag-spa-content-page-template': MAGDefaultLayout,
	'mag-spa-404-page-template': MAGDefaultLayout,
	'mag-spa-article-page-template': MAGArticleLayout,

	// MHH
	'mhh-spa-root-page-template': MHHDefaultLayout,
	'mhh-spa-homepage-template': MHHHomepageLayout,
	'mhh-spa-content-page-template': MHHDefaultLayout,
	'mhh-spa-404-page-template': MHHDefaultLayout,

	// MABA
	'maba-spa-root-page-template': MABADefaultLayout,
	'maba-spa-homepage-template': MABAHomepageLayout,
	'maba-spa-content-page-template': MABADefaultLayout,
	'maba-spa-404-page-template': MABADefaultLayout,
	'maba-spa-article-page-template': MABAArticleLayout,

	// amal
	'amal-spa-root-page-template': AmalDefaultLayout,
	'amal-spa-homepage-template': AmalHomepageLayout,
	'amal-spa-content-page-template': AmalDefaultLayout,
	'amal-spa-404-page-template': AmalDefaultLayout,
	'amal-spa-article-page-template': AmalArticleLayout,
	
	
	// Firefly
	'firefly-spa-homepage-template': FireflyHomepageLayout,
	'firefly-spa-root-page-template': FireflyDefaultLayout,
	'firefly-spa-content-page-template': FireflyDefaultLayout,
	'firefly-spa-404-page-template': FireflyDefaultLayout,
	'firefly-spa-article-page-template': FireflyArticleLayout,
};

export default TEMPLATE_NAME_TO_LAYOUT_MAP;
