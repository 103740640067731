
/**
 * Get displacement between 2 GPS coordinates. Displacement is different from distance.
 * 
 * @param {object} positionA { latitude: number, longitude: number }
 * @param {object} positionB { latitude: number, longitude: number }
 * @returns {number} displacement in km
 */
export const getDisplacementInKilometer = (positionA, positionB) => {
	const lat1 = positionA.latitude;
	const lon1 = positionA.longitude;
	
	const lat2 = positionB.latitude;
	const lon2 = positionB.longitude;
	
	if (
		Number.isNaN(lat1) ||
		Number.isNaN(lon1) ||
		Number.isNaN(lat2) ||
		Number.isNaN(lon2)
	) return -1;
	
	const R = 6371; // km
	const dLat = (lat2 - lat1) * Math.PI / 180;
	const dLon = (lon2 - lon1) * Math.PI / 180;
	const a =
		(
			Math.sin(dLat / 2) * Math.sin(dLat / 2)
		) +
		(
			Math.cos(lat1 * Math.PI / 180) *
			Math.cos(lat2 * Math.PI / 180) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2)
		);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	const d = R * c;
	return d;
};



