import { merge as _merge } from 'lodash';
import { createI18n } from 'vue-i18n';
import LOCALE_DEFAULT_MESSAGE from '~/data/locale-default-message.js';

const getAEMI18nObject = () => {
	let parsedi18nString = null;

	if (!('i18n_strings' in window)) {
		console.warn(`Global variable "i18n_strings" is not available`);
	} else if (window.i18n_strings === '') {
		console.warn(`Global variable "i18n_strings" is an empty string`);
	} else if (!window.i18n_strings) {
		console.warn(`Global variable "i18n_strings" is ${window.i18n_strings}, which is not expected.`);
	} else {
		try {
			parsedi18nString = JSON.parse(window.i18n_strings);
		} catch {
			console.warn(`Unable to parse "i18n_strings" as JSON.`);
		}
	}

	const localeMessagesWithDefault = _merge({}, LOCALE_DEFAULT_MESSAGE, parsedi18nString);
	
	/* 
		Find any key with _, make a cloned copy of it under new key which has all its _ replaced with -
		For e.g. object with { 'en': {}, 'zh_TW': {} }
		will be converted to
		{ 'en': {}, 'zh_TW': {}, 'zh-TW': {} }
		
		!!! Ideally this should be done from BE !!!
	*/
	const withKeysCloned = Object.fromEntries(Object.entries(localeMessagesWithDefault).reduce((acc, entry) => {
		acc.push(entry);
		
		const entryKey = entry.at(0);
		const entryValue = entry.at(1);
		if (entryKey.includes('_')) {
			const newEntry = [
				entryKey.replaceAll('_', '-'),
				entryValue,
			];
			acc.push(newEntry);
		}
		
		return acc;
	}, []));
	
	return withKeysCloned;
};


const i18nMessages = getAEMI18nObject();
console.log('i18nMessages = ', i18nMessages);

const i18n = createI18n({
	locale: document?.documentElement?.lang ?? 'en',
	fallbackLocale: 'en',
	messages: i18nMessages,
	silentFallbackWarn: true,
	// postTranslation (translated) {
	// 	console.log('translated = ', translated);
	// 	return translated.trim();
	// },
});

window.i18n = i18n;

export const i18nGlobal = i18n.global;

export default i18n;

