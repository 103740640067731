
export const noop = () => { /* Does nothing */ };
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const waitFor = (conditionFunc, pollRate = 400, maxAttempt = Infinity) => {
	let timeoutId = -99;
	let attemptCount = 0;
	
	const poll = (resolve, reject) => {
		if (attemptCount >= maxAttempt) {
			reject('waitFor(): Maximum attempt reached');
			return;
		}
		if (conditionFunc()) {
			resolve();
		} else {
			timeoutId = setTimeout(() => poll(resolve, reject), pollRate);
		}
		attemptCount++;
	};
	
	const start = () => {
		return new Promise(poll);
	};
	const cancel = () => {
		clearTimeout(timeoutId);
	};
	
	return {
		start,
		cancel,
	};
};


export const immutableSplice = (arr, start, deleteCount = 1, ...items) => {
	return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
};

export const popupWindow = ({ url, title, win = window, w = 800, h = 450 }) => {
	const y = (win.top.outerHeight / 2) + win.top.screenY - (h / 2);
	const x = (win.top.outerWidth / 2) + win.top.screenX - (w / 2);
	const newWindow = win.open(
		url,
		title,
		`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
	);
	if (window.focus) newWindow.focus();
	return newWindow;
};

export const getRandomInt = (_min, _max) => {
	const min = Math.ceil(_min);
	const max = Math.floor(_max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
};


export const getHashId = (function () {
	var HashID = {};

	/**
	 * The default alphabet is 25 numbers and lowercase letters.
	 * Any numbers that look like letters and vice versa are removed:
	 * 1 l, 0 o.
	 * Also the following letters are not present, to prevent any
	 * expletives: cfhistu
	 */
	var ALPHABET = '23456789abdegjkmnpqrvwxyz';

	var ALPHABET_LENGTH = ALPHABET.length;

	// Governs the length of the ID.
	// With an alphabet of 25 chars,
	// a length of 9 gives us 25^9 or
	// 3,814,697,265,625 possibilities.
	// Should be enough...
	var ID_LENGTH = 9;

	/**
	 * Returns a randomly-generated friendly ID.
	 * Note that the friendly ID is not guaranteed to be
	 * unique to any other ID generated by this same method,
	 * so it is up to you to check for uniqueness.
	 * @return {String} friendly ID.
	 */
	HashID.generate = function () {
		var rtn = '';
		for (var i = 0; i < ID_LENGTH; i++) {
			rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET_LENGTH));
		}
		return rtn;
	};

	var previous = [];

	/**
	 * Tries to generate a unique ID that is not defined in the
	 * `previous` array.
	 * @return {String} A unique ID, or `null` if one could not be generated.
	 */
	var UNIQUE_RETRIES = 9999;
	HashID.generateUnique = function () {
		var retries = 0;
		var id;

		// Try to generate a unique ID, one that isn't in the previous.
		while (!id && retries < UNIQUE_RETRIES) {
			id = HashID.generate();
			if (previous.indexOf(id) !== -1) {
				id = null;
				retries++;
			}
		}
		previous.push(id);
		return id;
	};

	return HashID.generateUnique;
})();

export function toKebabCase (text) {
	/**
	* Converts a given string to kebab case while ensuring that special charatcters 
	* are not surrounded by hyphens and spaces
	*
	* @param {string} text - The string to be converted to kebab case.
	* @return {string} A string in kebab case format.
	*/
	if (!text) return '';
	let str = text.toLowerCase();
	str = str.replace(/\s*([^\w\s])\s*/g, '$1');
	let words = str.match(/[\w]+|[^\w\s]/g);
	let kebabCaseArray = [];
	for (let i = 0; i < words.length; i++) {
		if (/^[^\w\s]$/.test(words[i])) {
			kebabCaseArray.push(words[i]);
		} else {
			if (i > 0 && !/^[^\w\s]$/.test(words[i - 1])) {
				kebabCaseArray.push('-');
			}
			kebabCaseArray.push(words[i]);
		}
	}
 
	return kebabCaseArray.join('');
}