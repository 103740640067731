<!--
	The name 'Header' clashes with html <header> component,
	hence we have to prefix it with 'App'.
-->
<script>
export default {
	name: 'AppHeader',
	editConfig: {
		emptyLabel: 'Header',
		resourceType: 'mh/components/header',
		isEmpty (props) {
			return true;
		},
	},
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Search from '~/components/AppHeader/Search.vue';
import MobileMainMenu from '~/components/AppHeader/MobileMainMenu.vue';
import MobileBtmBar from '~/components/AppHeader/MobileBtmBar.vue';
import DesktopLevelTwo from '~/components/AppHeader/DesktopLevelTwo.vue';
import DesktopCountrySelector from '~/components/AppHeader/DesktopCountrySelector.vue';
import LoginMenu from '~/components/AppHeader/LoginMenu.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useEventBus } from '@vueuse/core';
import { sleep } from '~/logic/helpers/utils';
import { useHeaderScroll } from '~/logic/composables/useHeaderScroll';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { getCookie, deleteCookie } from '~/logic/helpers/cookies.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { useHeaderFooterStore } from '~/logic/header-footer/header-footer-store.js';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';
import { isEmpty as _isEmpty } from 'lodash';

const props = defineProps({
	/* Header search suggestion URL endpoint */
	headerSearchSuggestionUrl: { type: String, default: '' },
	
	label: { type: String, default: '' },
	searchResultPagePath: { type: String, default: '' },
	disableMobileBottomNavigation: { type: Boolean, default: false },

	enrichBizConnectLabel: { type: String, default: '' },
	enrichBizConnectUrl: { type: String, default: '' },
	enrichBizConnectExternalIcon: { type: Boolean, default: false },
	enrichBizConnectOpenInNewTab: { type: Boolean, default: false },

	hamburgerL1Menu: { type: Array, default: () => [] },
	headerCampaignNavigation: { type: Array, default: () => [] },
	headerL1Navigation: { type: Array, default: () => [] },
	hideInDesktop: { type: Boolean, default: false },
	hideInDesktopSearch: { type: Boolean, default: false },
	hideInMobile: { type: Boolean, default: false },
	hideInMobileSearch: { type: Boolean, default: false },
	headerBehavior: { type: String, default: 'header-blend-into-banner' },

	/* Logo 1 props */
	logo1DarkAtlText: { type: String, default: '' },
	logo1Dark: { type: String, default: '' },
	logo1Light: { type: String, default: '' },
	logo1DarkUrl: { type: String, default: '' },
	logo1LightAtlText: { type: String, default: '' },
	logo1LightUrl: { type: String, default: '' },
	hideInDesktopLogo1: { type: Boolean, default: false },
	hideInMobileLogo1: { type: Boolean, default: false },
	openInNewTabLogo1: { type: Boolean, default: false },
	openInNewTabLogo1Dark: { type: Boolean, default: false },

	/* Logo 2 props */
	logo2Dark: { type: String, default: '' },
	logo2DarkAtlText: { type: String, default: '' },
	logo2DarkUrl: { type: String, default: '' },
	logo2Light: { type: String, default: '' },
	logo2LightAtlText: { type: String, default: '' },
	logo2LightUrl: { type: String, default: '' },
	hideInDesktopLogo2: { type: Boolean, default: false },
	hideInMobileLogo2: { type: Boolean, default: false },
	openInNewTabLogo2: { type: Boolean, default: false },
	openInNewTabLogo2Dark: { type: Boolean, default: false },

	/* For country selector */
	selectedCountryLabel: { type: String, default: '' },
	allCountriesLabel: { type: String, default: '' },

	mhExplorerLabel: { type: String, default: '' },
	mhExplorerPreloginUrl: { type: String, default: '' },
	mhExplorerPostloginUrl: { type: String, default: '' },
	mhExplorerExternalIcon: { type: Boolean, default: false },
	mhExplorerOpenInNewTab: { type: Boolean, default: false },

	mhbizProLabel: { type: String, default: '' },
	mhbizProUrl: { type: String, default: '' },
	mhbizProExternalIcon: { type: Boolean, default: false },
	mhbizProOpenInNewTab: { type: Boolean, default: false },

	mobileBottomNavigation: { type: Array, default: () => [] },
	myAccountLabel: { type: String, default: 'MY ACCOUNT' },
	myAccount: { type: Array, default: () => [] },
	prelogin: { type: Array, default: () => [] },
	preLoginMenuLabel: { type: String, default: '' },

	/* Quicklinks props */
	quickLinksLabel: { type: String, default: '' },
	quickLinks: { type: Array, default: () => [] },

	/* AskMH props */
	askMhUrl: { type: String, default: '' },
	openInNewTabAskMH: { type: Boolean, default: false },
	hideInDesktopAskMH: { type: Boolean, default: false },
	hideInMobileAskMH: { type: Boolean, default: false },

	/* Search props */
	searchButtonLabel: { type: String, default: '' },
	searchBarPlaceholderText: { type: String, default: '' },
	suggestedSearchesLabel: { type: String, default: '' },

	/* Login props */
	headerPostLoginUrl: { type: String, default: '' },

	/* Country languages props */
	countryLanguage: { type: Object, default: () => ({}) },

	// misc.
	disableTransparency: { type: Boolean, default: false },

	// Footer props
	footerModel: { type: Object, default: () => ({}) },

	logOutLabel: { type: String, default: 'Logout' },
	exploreMoreLabel: { type: String, default: 'EXPLORE MORE' },
	componentIdAA: { type: String, default: '' },
	headerCountryLanguageUrl: { type: String, default: '' },
});

const rootEl = ref(null);
gsap.registerPlugin(ScrollTrigger);

// const authStore = useAuthStore();

const {
	isLoggedIn,
	userInfo,
	login,
	isSapphireCookiePresent,
	isAuthStateReady,
} = useAuthStore();

onMounted(() => {
	ScrollTrigger.create({
		id: 'AppHeader',
		trigger: document.body,
		start: `200px top`,
		end: `+=90000% top`,
		toggleClass: {
			targets: rootEl.value,
			className: 'sticked',
		},
		onToggle: ({ isActive }) => {
			// Access the parent element of AppHeader if headerBehavior is selected as header-sit-outside-banner
			const globalHeader = rootEl.value.parentElement?.parentElement;
			if (props.headerBehavior === 'header-sit-outside-banner') {
				// Add or remove the class based on isActive and when any menu item is cliked.
				// this prevent removing of Fixed class while stop scrolling.
				if (isActive || (
					levelTwoActive.value === '' &&
					showHeaderSearch.value === false &&
					showCountrySelector.value === false &&
					showHeaderLogin.value === false
				)) {
					globalHeader.classList.toggle('fixed', isActive);
				}
			}
		},
		endTrigger: document.body,
		pin: false,
		// markers: true,
	});
	if (props.headerBehavior !== 'header-sit-outside-banner' ) {
		const globalHeader = rootEl.value.parentElement?.parentElement;
		globalHeader.classList.add('fixed');
	}
	checkIfLoggedIn();
});

const checkIfLoggedIn = async () => {
	if (isSapphireCookiePresent()) {
		try {
			await triggerPostLoginUrl();
		} catch {
			// do nothing
		}
	}
	isAuthStateReady.value = true;
};

const triggerPostLoginUrl = async () => {
	let apiResponse = null;
	try {
		apiResponse = await axios.get(props.headerPostLoginUrl);
		login(apiResponse.data);
	} catch (e) {
		// If API fails means sapphire cookie is not present and user is not logged in
	}
};


const showHeaderSearch = ref(false);
const showCountrySelector = ref(false);
const showHeaderLogin = ref(false);
const showMobileMainMenu = ref(false);

const { isEditorMode } = useGlobalAEMState();
const { pageProperties } = useGlobalAEMState();

const globalHeaderBus = useEventBus('main-global-header');

const listener = (event, payload) => {
	switch (event) {
		case 'trigger-search': {
			showHeaderSearch.value = (payload ?? true);
			break;
		}
		case 'trigger-login': {
			showHeaderLogin.value = (payload ?? true);
			break;
		}
		case 'trigger-country-selector': {
			showCountrySelector.value = (payload ?? true);
			break;
		}
		default: {
			console.log(`Unknown event: "${event}". Ignoring it.`);
			break;
		}
	}
};

globalHeaderBus.on(listener);


const disabledInteractions = ref(new Set());

// because HeaderSearch's visibility is tied to its "focus",
// we need this weird hack to fix header search to "blink" when clicking on the search icon.
const disableInteractionBriefly = async (id) => {
	disabledInteractions.value.add(id);
	await sleep(500);
	disabledInteractions.value.delete(id);
};


const shouldHeaderBeOpaque = computed(() => {
	return (
		props.disableTransparency ||
		isEditorMode.value ||
		showHeaderSearch.value ||
		showHeaderLogin.value ||
		showCountrySelector.value ||
		levelTwoActive.value ||
		showMobileMainMenu.value ||
		props.headerBehavior === 'header-sit-outside-banner'
	);
});

const levelTwoActive = ref('');

const setLevelTwoMenu = (menuName) => {
	levelTwoActive.value = menuName;
};


const { paused: pausedHeaderScroll } = useHeaderScroll();

watch([showHeaderSearch, showHeaderLogin, showCountrySelector, levelTwoActive, showMobileMainMenu], () => {
	/*
		Problem & Explanations:
		----------------------------------
		All overlays have the 'useDisableScroll', it will set the scrollTop of the page to 0.
		When this happens, 'useHeaderScroll' watch function will trigger, causing it to "remember" the scrollTop.

		As a result, when overlay is hidden, scrollTop is restored to the page, causing again the 'useHeaderScroll' watch function to trigger.
		This time, it thought we have scrolled the page, and will hide the header & other sticky components.

		This is not the behaviour we want.

		Solution:
		----------------------------------
		If any of these value is true, we need to pause the header scroling mechanism, so that any changes to scrollTop won't be recorded.
		We resume it once all these values are false (meaning none of these overlays are visible).
	*/
	if (showHeaderSearch.value || showHeaderLogin.value || showCountrySelector.value || levelTwoActive.value || showMobileMainMenu.value) {
		pausedHeaderScroll.value = true;
	} else {
		pausedHeaderScroll.value = false;
	}
});

/*
*	Transform all countries and language into array based on country keys
*
*	Filter out selected country and language object keys
* 	Return an array of countries and languages
*
*/
const allCountriesAndLanguagesTransform = computed(() => {
	const countryKeys = Object.keys(props.countryLanguage.allCountriesAndLanguages).filter((country) => country !== props.countryLanguage.currentCountry);

	return countryKeys.map((country) => {
		return props.countryLanguage.allCountriesAndLanguages[country];
	});
});

const selectedCountryAndLanguageTransform = computed(() => {
	const currentCountry = props.countryLanguage.currentCountry;

	return {
		country: props.countryLanguage.allCountriesAndLanguages[currentCountry].pageTitle,
		languages: props.countryLanguage.allCountriesAndLanguages[currentCountry].languages,
	};
});

const calcCampaignGrid = (level2List) => {
	if (Array.isArray(level2List)) {
		return Math.abs((level2List.length % 4) - 3);
	}

	return 0;
};


const { header: headerStore } = useHeaderFooterStore();

/* eslint-disable vue/no-setup-props-destructure */
// it's okay, reactivity on these props are not needed anyway
headerStore.logo1Dark = props.logo1Dark;
headerStore.logo1DarkUrl = props.logo1DarkUrl;
headerStore.logo1DarkAtlText = props.logo1DarkAtlText;
headerStore.openInNewTabLogo1Dark = props.openInNewTabLogo1Dark;
headerStore.hideInMobileLogo1 = props.hideInMobileLogo1;

headerStore.logo2Dark = props.logo2Dark;
headerStore.logo2DarkUrl = props.logo2DarkUrl;
headerStore.logo2DarkAtlText = props.logo2DarkAtlText;
headerStore.openInNewTabLogo2Dark = props.openInNewTabLogo2Dark;
headerStore.hideInMobileLogo2 = props.hideInMobileLogo2;
/* eslint-enable */

const closeHeaderDesktopLevelTwo = (event) => {
	levelTwoActive.value = '';
};
</script>



<template>
<!-- Have to use "data-is-opaque" flag here,
	rather than class due to class being used by ScrollTrigger already.
	Using class will mess with Vue's DOM patching
-->
<header
	v-if="(isMobileViewport && !props.hideInMobile) || (!isMobileViewport && !props.hideInDesktop)"
	ref="rootEl"
	class="AppHeader"
	:data-is-opaque="shouldHeaderBeOpaque ? 'true' : 'false'"
	data-is-sticky
	data-height-var="--headerHeight"
>
	<div class="generic-container flex items-center h-full">
		<div class="logo-container flex items-center xl:gap-4 gap-8 py-2">
			<div
				v-if="(isMobileViewport && !props.hideInMobileLogo1) || (!isMobileViewport && !props.hideInDesktopLogo1)"
			>
				<AppHyperlink
					v-aa="[
						{
							clickName: props.logo1DarkAtlText,
							clickComponentType: 'URL',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: props.logo1DarkAtlText,
							type: 'other',
						},
					]"
					:href="props.logo1DarkUrl"
					:target="props.openInNewTabLogo1Dark ? '_blank' : '_self'"
					:showExternalLinkIcon="false"
					rel="noopener noreferrer"
					class="is-dark-variant rounded-md"
				>
					<img
						class="logo-primary-img"
						:src="props.logo1Dark"
						:alt="props.logo1DarkAtlText"
					/>
				</AppHyperlink>
				<AppHyperlink
					v-aa="[
						{
							clickName: props.logo1LightAtlText,
							clickComponentType: 'URL',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: props.logo1LightAtlText,
							type: 'other',
						},
					]"
					:href="props.logo1LightUrl"
					:target="props.openInNewTabLogo1 ? '_blank' : '_self'"
					:showExternalLinkIcon="false"
					rel="noopener noreferrer"
					class="is-light-variant rounded-md"
				>
					<img
						class="logo-primary-img"
						:src="props.logo1Light"
						:alt="props.logo1LightAtlText"
					/>
				</AppHyperlink>
			</div>
			<!-- <a href="http://" target="_blank" rel="noopener noreferrer">
			</a> -->

			<div
				v-if="(isMobileViewport && !props.hideInMobileLogo2) || (!isMobileViewport && !props.hideInDesktopLogo2)"
			>
				<AppHyperlink
					v-aa="[
						{
							clickName: props.logo2DarkAtlText,
							clickComponentType: 'URL',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: props.logo2DarkAtlText,
							type: 'other',
						},
					]"
					:href="props.logo2DarkUrl"
					:target="props.openInNewTabLogo2Dark ? '_blank' : '_self'"
					:showExternalLinkIcon="false"
					rel="noopener noreferrer"
					class="is-dark-variant lg:hidden rounded-md"
				>
					<img
						class="logo-secondary-img"
						:src="props.logo2Dark"
						:alt="props.logo2DarkAtlText"
					/>
				</AppHyperlink>
				<AppHyperlink
					v-aa="[
						{
							clickName: props.logo2LightAtlText,
							clickComponentType: 'URL',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: props.logo2LightAtlText,
							type: 'other',
						},
					]"
					:href="props.logo2LightUrl"
					:target="props.openInNewTabLogo2 ? '_blank' : '_self'"
					:showExternalLinkIcon="false"
					rel="noopener noreferrer"
					class="is-light-variant lg:hidden rounded-md"
				>
					<img
						class="logo-secondary-img"
						:src="props.logo2Light"
						:alt="props.logo2LightAtlText"
					/>
				</AppHyperlink>
			</div>
		</div>

		<div class="mx-auto lg:hidden h-full" :style="{ '--newLabel': $t('New') }">
			<nav class="flex gap-x-1.5 font-semibold text-base h-full">
				<template
					v-for="(headerL1Item, index) in props.headerL1Navigation"
					:key="index"
				>

					<div
						class="lvl-1-nav flex flex-col"
						:class="{ 'is-active': levelTwoActive === headerL1Item.l1TitleLabel }"
						:data-menu-name="headerL1Item.l1TitleLabel"
					>
						<div
							class="level1-badge mt-2.5 ml-2 rtl:mr-2 rounded border border-current font-bold py-1 px-2 uppercase"
							:class="[ headerL1Item.newLabel ? 'visible' : 'invisible' ]"
						>{{ $t('New') }}</div>
						<button
							v-if="headerL1Item.headerL2Navigation"
							v-aa="[
								{
									clickName: headerL1Item.l1TitleLabel,
									clickComponentType: 'Button',
									componentName: 'AppHeader',
									componentID: props.componentIdAA,
								},
								{
									name: headerL1Item.l1TitleLabel,
									type: 'other',
								},
							]"
							class="relative rounded-md"
							type="button"
							style="--focus-visible-outline-offset: -2px;"
							@click="setLevelTwoMenu(headerL1Item.l1TitleLabel)"
							@keydown.esc="setLevelTwoMenu('')"
						>
							{{ headerL1Item.l1TitleLabel }}
						</button>
						<!-- If level 2 empty -->
						<AppHyperlink
							v-else-if="headerL1Item.l1TitleLabel"
							v-aa="[
								{
									clickName: headerL1Item.l1TitleLabel,
									clickComponentType: 'URL',
									componentName: 'AppHeader',
									componentID: props.componentIdAA,
								},
								{
									name: headerL1Item.l1TitleLabel,
									type: 'other',
								},
							]"
							class="relative text-current px-2 py-1 hover:text-current hover:no-underline rounded-md"
							style="--focus-visible-outline-offset: -2px;"
							:href="headerL1Item.l1TitlePagePath"
							:target="headerL1Item.openInNewTab ? '_blank' : '_self'"
							:showExternalLinkIcon="false"
						>
							{{ headerL1Item.l1TitleLabel }}
						</AppHyperlink>
					</div>
				</template>
			</nav>
			<template
				v-for="(headerL1TeleportItem, headerL1TeleportItemIndex) in props.headerL1Navigation"
				:key="headerL1TeleportItemIndex"
			>
				<Teleport v-if="levelTwoActive === headerL1TeleportItem.l1TitleLabel" :to="`.AppHeader [data-menu-name='${levelTwoActive}']`">
					<DesktopLevelTwo
						:isVisible="true"
						@update:is-visible="$event === false ? ( setLevelTwoMenu(''), disableInteractionBriefly('level-2') ) : null;"
					>
						<div class="">
							<AppHyperlink
								v-aa="[
									{
										clickName: headerL1TeleportItem.l1TitleCtaLabel,
										clickComponentType: 'URL',
										componentName: 'AppHeader',
										componentID: props.componentIdAA,
									},
									{
										name: headerL1TeleportItem.l1TitleCtaLabel,
										type: 'other',
									},
								]"
								class="inline-flex items-center text-primary-blue-base hover:text-primary-blue-light text-base font-semibold group"
								:href="headerL1TeleportItem.l1TitlePagePath"
								:showExternalLinkIcon="false"
								@click="closeHeaderDesktopLevelTwo"
							>
								{{ headerL1TeleportItem.l1TitleCtaLabel }} <icon-far-arrow-right class="ml-2 rtl:mr-2 fill-current hover:fill-current transition-transform ease-in-out transform rtl:-rotate-180 group-hover:-translate-x-0.5" />
							</AppHyperlink>
						</div>

						<div class="grid grid-cols-4 gap-x-4 gap-y-8 mt-8 font-light leading-none">
							<nav
								v-for="(headerL2NavigationItem, headerL2NavigationItemIndex) in headerL1TeleportItem.headerL2Navigation"
								:key="headerL2NavigationItemIndex"
							>
								<ul
									v-if="headerL1TeleportItem.headerL2Navigation"
									class="flex flex-col gap-4"
								>
									<!-- Level 2 navigation item -->
									<li>
										<AppHyperlink
											v-aa="[
												{
													clickName: headerL2NavigationItem.l2TitleLabel,
													clickComponentType: 'URL',
													componentName: 'AppHeader',
													componentID: props.componentIdAA,
												},
												{
													name: headerL2NavigationItem.l2TitleLabel,
													type: headerL2NavigationItem.externalLink ? 'exit' : 'other',
												},
											]"
											:href="headerL2NavigationItem.l2TitlePagePath"
											:target="headerL2NavigationItem.openInNewTab ? '_blank' : '_self'"
											class="text-base text-primary-blue-base hover:text-primary-blue-light font-semibold block"
											:showExternalLinkIcon="false"
											@click="closeHeaderDesktopLevelTwo"
										>
											{{ headerL2NavigationItem.l2TitleLabel }}

											<icon-far-arrow-up-right-from-square
												v-if="headerL2NavigationItem.externalLink"
												class="fill-current inline-block ml-3 rtl:mr-3 text-base"
											/>
										</AppHyperlink>
									</li>
									<!-- Level 3 navigation item -->
									<template
										v-for="(headerL3NavigationItem, headerL3NavigationItemIndex) in headerL2NavigationItem.headerL3Navigation"
										:key="headerL3NavigationItemIndex"
									>
										<li class="level3-navigation-list-item">
											<AppHyperlink
												v-aa="[
													{
														clickName: headerL3NavigationItem.l3TitleLabel,
														clickComponentType: 'URL',
														componentName: 'AppHeader',
														componentID: props.componentIdAA,
													},
													{
														name: headerL3NavigationItem.l3TitleLabel,
														type: headerL3NavigationItem.externalLink ? 'exit' : 'other',
													},
												]"
												:href="headerL3NavigationItem.l3TitlePagePath"
												:target="headerL3NavigationItem.openInNewTab ? '_blank' : '_self'"
												class="text-primary-black-base hover:text-primary-black-base"
												@click="closeHeaderDesktopLevelTwo"
											>
												<div class="level3-navigation-item">
													<span>{{ headerL3NavigationItem.l3TitleLabel }}</span>
													<span
														v-if="headerL3NavigationItem.l3TagTitle"
														class="level3-badge rounded border border-primary-blue-dark text-primary-blue-dark font-bold py-1 px-2 w-auto ml-2 rtl:mr-2"
													>{{ headerL3NavigationItem.l3TagTitle }}</span>
												</div>
												<icon-far-arrow-up-right-from-square
													v-if="headerL3NavigationItem.externalLink"
													class="fill-current inline-block ml-3 rtl:mr-3 text-base"
												/>
											</AppHyperlink>
										</li>
										<li
											v-if="headerL3NavigationItem.more"
										>
											<AppHyperlink
												v-aa="[
													{
														clickName: $t('More'),
														clickComponentType: 'URL',
														componentName: 'AppHeader',
														componentID: props.componentIdAA,
													},
													{
														name: $t('More'),
														type: 'other',
													},
												]"
												class="text-primary-black-base hover:text-primary-black-base"
												:href="headerL2NavigationItem.l2TitlePagePath"
											>{{ $t('More') }}
											</AppHyperlink>
										</li>
									</template>
								</ul>
							</nav>

							<!-- Blank grid -->
							<template v-if="props.headerCampaignNavigation[headerL1TeleportItemIndex]">
								<nav
									v-for="(gridChildBlank, gridChildBlankIndex) in calcCampaignGrid(headerL1TeleportItem.headerL2Navigation)"
									:key="gridChildBlankIndex"
								>
								</nav>
							</template>
							
							<!-- Campaign per L1 menu -->
							<nav v-if="props.headerCampaignNavigation[headerL1TeleportItemIndex]">
								<div class="flex flex-col">
									<AppHyperlink
										v-aa="[
											{
												clickName: 'campaign',
												clickComponentType: 'URL',
												componentName: 'AppHeader',
												componentID: props.componentIdAA,
											},
											{
												name: 'campaign',
												type: 'other',
											},
										]"
										:href="props.headerCampaignNavigation[headerL1TeleportItemIndex].imagePagePath"
										:target="props.headerCampaignNavigation[headerL1TeleportItemIndex].openInNewTabImage ? '_blank' : null"
										class="campaign-thumbnail rounded-lg"
									>
										<img :src="props.headerCampaignNavigation[headerL1TeleportItemIndex].image" alt="campaign" class="campaign-thumbnail-image" />
									</AppHyperlink>

									<AppHyperlink
										v-aa="[
											{
												clickName: props.headerCampaignNavigation[headerL1TeleportItemIndex].ctaLabel,
												clickComponentType: 'URL',
												componentName: 'AppHeader',
												componentID: props.componentIdAA,
											},
											{
												name: props.headerCampaignNavigation[headerL1TeleportItemIndex].ctaLabel,
												type: isUrlMatchCurrentHostname(props.headerCampaignNavigation[headerL1TeleportItemIndex].ctaPagePath) ? 'other' : 'exit',
											},
										]"
										:href="props.headerCampaignNavigation[headerL1TeleportItemIndex].ctaPagePath"
										:target="props.headerCampaignNavigation[headerL1TeleportItemIndex].openInNewTabCta ? '_blank' : null"
										class="mt-3"
									>
										<span class="font-semibold">{{ props.headerCampaignNavigation[headerL1TeleportItemIndex].ctaLabel }}</span>
									</AppHyperlink>
								</div>
							</nav>
						</div>
					</DesktopLevelTwo>
				</Teleport>
			</template>
		</div>

		<div class="ml-auto md:rtl:ml-0 md:rtl:mr-auto h-full">
			<nav class="flex gap-x-4 lg:gap-x-3 h-full">
				<button
					v-if="props.countryLanguage.allCountriesAndLanguages"
					v-aa="[
						{
							clickName: countryLanguage.currentLanguage,
							clickComponentType: 'Button',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: countryLanguage.currentLanguage,
							type: 'other',
						},
					]"
					class="btn-side-interaction flex items-center gap-2 lg:hidden"
					:class="{ 'is-active': showCountrySelector }"
					type="button"
					style="--focus-visible-outline-offset: -2px;"
					:aria-label="`${countryLanguage.currentCountryTitle} ${countryLanguage.currentLanguageTitle}`"
					@keydown.esc="showCountrySelector = false"
					@click="disabledInteractions.has('country') ? null : (showCountrySelector = true)"
				>
					<img
						:src="pageProperties?.countryFlag"
						width="24"
						height="24"
						:alt="$t('Flag of {country}', {
							country: countryLanguage.currentCountryTitle,
						})"
					/>
					<span class="text-sm font-semibold uppercase">{{ countryLanguage.currentLanguage }}</span>
				</button>
				<DesktopCountrySelector
					v-if="showCountrySelector"
					:isVisible="showCountrySelector"
					:selectedCountryLabelDesktop="props.selectedCountryLabel"
					:allCountriesLabelDesktop="props.allCountriesLabel"
					:countryLanguageItems="props.countryLanguage"
					:allCountriesAndLanguages="allCountriesAndLanguagesTransform"
					:selectedCountryAndLanguage="selectedCountryAndLanguageTransform"
					:countryLanguageRedirectBaseUrl="props.headerCountryLanguageUrl"
					@update:is-visible="showCountrySelector = $event; $event === false ? disableInteractionBriefly('country') : null;"
				/>

				<AppHyperlink
					v-if="(isMobileViewport && !props.hideInMobileAskMH) || (!isMobileViewport && !props.hideInDesktopAskMH)"
					v-aa="[
						{
							clickName: $t('AskMH'),
							clickComponentType: 'Button',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: $t('AskMH'),
							type: 'other',
						},
					]"
					:href="props.askMhUrl"
					:target="props.openInNewTabAskMH ? '_blank' : '_self'"
					class="btn-side-interaction text-current hover:text-current lg:hidden"
					style="--focus-visible-outline-offset: -2px;"
					:aria-label="$t('AskMH')"
					:showExternalLinkIcon="false"
				>
					<div class="flex items-center">
						<icon-far-circle-question class="fill-current" />
						<span class="text-sm ml-1">{{ $t('AskMH') }}</span>
					</div>
				</AppHyperlink>

				<button
					v-if="(isMobileViewport && !props.hideInMobileSearch) || (!isMobileViewport && !props.hideInDesktopSearch)"
					v-aa="[
						{
							clickName: $t('Search'),
							clickComponentType: 'Button',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: $t('Search'),
							type: 'other',
						},
					]"
					class="btn-side-interaction"
					:class="{ 'is-active': showHeaderSearch }"
					type="button"
					style="--focus-visible-outline-offset: -2px;"
					:aria-label="$t('Search')"
					@click="disabledInteractions.has('search') ? null : (showHeaderSearch = true)"
				>
					<div class="flex items-center">
						<icon-far-magnifying-glass class="fill-current" />
						<span class="text-sm ml-1 lg:hidden">{{ $t('Search') }}</span>
					</div>
				</button>
				<Search
					v-if="showHeaderSearch"
					:isVisible="showHeaderSearch"
					:searchPageUrl="props.searchResultPagePath"
					:searchInputPlaceholderText="props.searchBarPlaceholderText"
					:searchButtonLabelText="props.searchButtonLabel"
					:quickLinksLabelText="props.quickLinksLabel"
					:quickLinks="props.quickLinks"
					:suggestedSearchesLabel="props.suggestedSearchesLabel"
					:searchSuggestionUrl="props.headerSearchSuggestionUrl"
					@update:is-visible="showHeaderSearch = $event; $event === false ? disableInteractionBriefly('search') : null;"
				/>

				<button
					v-aa="[
						{
							clickName: $t('Account'),
							clickComponentType: 'Button',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: $t('Account'),
							type: 'other',
						},
					]"
					class="btn-side-interaction"
					:class="{ 'is-active': showHeaderLogin }"
					style="--focus-visible-outline-offset: -2px;"
					type="button"
					:aria-label="$t('Account')"
					@click="disabledInteractions.has('login') ? null : (showHeaderLogin = true)"
					@keydown.esc="showHeaderLogin = false"
				>
					<icon-far-user
						v-if="!isLoggedIn"
						class="fill-current"
					/>
					<div
						v-else
						class="login-avatar bg-secondary-blue-ultralight text-primary-blue-dark"
					>
						{{ userInfo.lastName?.charAt(0) }}{{ userInfo.firstName?.charAt(0) }}
					</div>
				</button>
				<LoginMenu
					v-if="showHeaderLogin"
					:isVisible="showHeaderLogin"
					:preLoginItems="props.prelogin"
					:preLoginMenuLabel="props.preLoginMenuLabel"
					:myAccountLabel="props.myAccountLabel"
					:myAccount="props.myAccount"
					:enrichBizConnectLabel="props.enrichBizConnectLabel"
					:enrichBizConnectUrl="props.enrichBizConnectUrl"
					:enrichBizConnectExternalIcon="props.enrichBizConnectExternalIcon"
					:enrichBizConnectOpenInNewTab="props.enrichBizConnectOpenInNewTab"
					:mhExplorerLabel="props.mhExplorerLabel"
					:mhExplorerPreloginUrl="props.mhExplorerPreloginUrl"
					:mhExplorerPostloginUrl="props.mhExplorerPostloginUrl"
					:mhExplorerExternalIcon="props.mhExplorerExternalIcon"
					:mhExplorerOpenInNewTab="props.mhExplorerOpenInNewTab"
					:mhbizProLabel="props.mhbizProLabel"
					:mhbizProUrl="props.mhbizProUrl"
					:mhbizProExternalIcon="props.mhbizProExternalIcon"
					:mhbizProOpenInNewTab="props.mhbizProOpenInNewTab"
					:logOutLabel="props.logOutLabel"
					:exploreMoreLabel="props.exploreMoreLabel"
					:componentIdAA="props.componentIdAA"
					@update:is-visible="showHeaderLogin = $event; $event === false ? disableInteractionBriefly('login') : null;"
				/>

				<button
					v-aa="[
						{
							clickName: $t('Menu'),
							clickComponentType: 'Button',
							componentName: 'AppHeader',
							componentID: props.componentIdAA,
						},
						{
							name: $t('Menu'),
							type: 'other',
						},
					]"
					class="btn-side-interaction >lg:hidden"
					style="--focus-visible-outline-offset: -2px;"
					type="button"
					:aria-label="$t('Menu')"
					@click="showMobileMainMenu = !showMobileMainMenu"
				>
					<icon-far-bars class="fill-current" />
				</button>
			</nav>
		</div>
	</div>
</header>


<MobileMainMenu
	:isVisible="showMobileMainMenu"
	:mobileNavigationList="props.hamburgerL1Menu"
	:headerNavigationList="props.headerL1Navigation"
	:mobileSelectedCountryLabel="props.selectedCountryLabel"
	:mobileAllCountriesLabel="props.allCountriesLabel"
	:mobileLogo1LightSrc="props.logo1Light"
	:mobileIsOpenInNewTabLogo1="props.openInNewTabLogo1"
	:mobileIsHideLogo1="props.hideInMobileLogo1"
	:mobileCountryLanguageItems="props.countryLanguage"
	:mobileFooter="props.footerModel"
	:isHideMobileSearch="props.hideInMobileSearch"
	:componentIdAA="props.componentIdAA"
	:countryLanguageRedirectBaseUrl="props.headerCountryLanguageUrl"
	@update:is-visible="showMobileMainMenu = $event"
/>

<MobileBtmBar
	v-if="isMobileViewport && !props.disableMobileBottomNavigation"
	:mobileBottomNavigationItems="props.mobileBottomNavigation"
/>
</template>



<style lang="scss">
@use '~/styles/partials/_var.scss';

html {
	--headerHeight: 80px;
	--headerTranslateY: 0%;

	@media #{var.$query-max-lg} {
		--headerHeight: 56px;
	}
}
.global-header {
	top: calc(var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight));
	z-index: 8000;
	height: var(--headerHeight);
	transform: translateY(var(--headerTranslateY));
	transition: transform var(--pinnedElementTransitionDuration);

	> .aem-GridColumn {
		float: none !important;
	}
}
.aem-AuthorLayer-Edit, .aem-AuthorLayer-structure {
	.global-header {
		margin-bottom: 0 !important;
		position: static;
	}
}

html.no-scroll [data-component="AppHeader"] .AppHeader {
	// ------ this chunk of stylings is a copy of the code below ------
	border-bottom-color: var(--neutral-grey-base);
	background-color: white;
	color: var(--text-color);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);

	.logo-container {
		.is-dark-variant { display: block; }
		.is-light-variant { display: none; }
	}
	.tagged-as-new::before { color: var(--primary-blue-base); }
	.level1-badge { @apply border-primary-blue-dark text-primary-blue-dark; }
	// ------ End ------
}

</style>


<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

.AppHeader {
	width: 100%;
	max-width: 100vw;
	overflow-x: hidden;
	background-color: transparent;
	height: var(--headerHeight);
	display: flex;
	flex-direction: column;
	color: white;
	transition: color, background-color, box-shadow, border-bottom;
	transition-duration: 0.3s;
	border-bottom-width: 1px;
	border-bottom-color: transparent;
	z-index: 8000;
	position: relative;

	@media #{var.$query-min-md} {
		overflow-x: unset;
	}

	&.sticked, &[data-is-opaque="true"] {
		// ------ this chunk of stylings are copied to the global selector above ------
		// I tried using mixin or placeholder selector, with Vue's :global() selector. Does not work.
		border-bottom-color: var(--neutral-grey-base);
		background-color: white;
		color: var(--text-color);
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);

		.logo-container {
			.is-dark-variant { display: block; }
			.is-light-variant { display: none; }
		}
		.tagged-as-new::before { color: var(--primary-blue-base); }
		.level1-badge { @apply border-primary-blue-dark text-primary-blue-dark; }
		// ------ End ------
	}
}


.logo-container {
	z-index: 100;
	display: flex;

	.is-dark-variant { display: none; }
	.is-light-variant { display: block; }
}
.logo-primary-img {
	width: 144px;

	@media #{var.$query-max-lg} {
		width: 98px;
	}
}
.logo-secondary-img {
	width: 40px;

	@media #{var.$query-max-lg} {
		display: none !important;
	}
}


.lvl-1-nav {
	border-radius: 0;
	border-bottom: 3px solid transparent;
	transition: border-bottom-color 0.3s;

	&.tagged-as-new {
		position: relative;

		&::before {
			content: var(--newLabel);
			display: inline-block;
			position: absolute;
			left: 6px;
			top: -65%;
			font-size: 0.6rem; // 10px
			@apply py-1 px-2;
			border: 1px solid currentColor;
			border-radius: 4px;
			line-height: 1;
			text-transform: uppercase;
			font-weight: 700;
		}
	}

	&.is-active {
		border-bottom-color: var(--primary-black-base);
	}
}

.btn-side-interaction {
	border-radius: 0;
	border-bottom: 3px solid transparent;
	transition: border-bottom-color 0.3s;

	&.is-active {
		border-bottom-color: var(--primary-black-base);
	}
}

.level1-badge {
	font-size: 10px;
	line-height: 1;
	width: min-content;
}

.level3-badge {
	font-size: 10px;
	line-height: 1;
	width: auto;
	display: inline-block;
}

.level3-navigation-item {
	line-height: 20px;
}

.login-avatar {
	@apply items-center justify-center self-start row-span-3 rounded-full w-8 h-8 font-bold text-xs;

	display: flex;
	margin-top: 5px;
}

.campaign-thumbnail {
	.campaign-thumbnail-image {
		@apply rounded-lg;
		width: 260px;
		object-fit: cover;
		aspect-ratio: 16 / 9;
	}
}
</style>
