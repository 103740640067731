<script>
export default {
	name: 'OptionBST',
};
</script>

<script setup>
import dayjs from 'dayjs';
import DatePicker from '~/components/form/DatePicker.vue';
import AirportPicker from '~/components/BookingWidget/AirportPicker.vue';

const props = defineProps({
	formNamePrefix: { type: String, required: true },
	isFocused: { type: Boolean, default: false },
	isOneWay: { type: Boolean, default: false },
	
	locationA: { type: Object, required: true },
	locationB: { type: Object, required: true },
	dateToMalaysia: { type: String, required: true },
	dateLeaveMalaysia: { type: String, default: '' },

	visitMalaysiaLabel: { type: String, default: '' },
	yourDateOfChoiceLabel: { type: String, default: '' },
	question1Text: { type: String, default: '' },
	question2Text: { type: String, default: '' },
	toLabel: { type: String, default: '' },
	departLabel: { type: String, default: '' },

	domesticAirportList: { type: Array, default: () => [] },
	disableAutoCompleteForAmal: { type: Boolean, default: false },
});

const emit = defineEmits([
	'focus-within',
	'blur-within',
]);


const rootEl = ref(null);
const airportPickerEl = ref(null);
const datePickerEl = ref(null);

const airportValue = ref(null);
const departureDate = ref(null);

watch(() => props.isFocused, async (newValue, oldValue) => {
	// 👇 is focused / is blurred
	await nextTick();
	
	if (airportPickerEl.value?.inputEl?.meta.touched) {
		airportPickerEl.value?.inputEl?.validate();
	}
	if (datePickerEl.value?.inputEl?.meta.touched) {
		datePickerEl.value?.inputEl?.validate();
	}
});


const resetForm = () => {
	airportValue.value = null;
	departureDate.value = null;
	
	airportPickerEl.value?.inputEl?.setTouched(false);
	datePickerEl.value?.inputEl?.setTouched(false);
};

defineExpose({
	resetForm,
	departureDate,
	airportValue,
});

</script>

<template>
<div
	ref="rootEl"
	v-focus-within
	class="OptionBST"
	:class="{
		'is-focused': props.isFocused,
	}"
	tabindex="-1"
	@focus-within="$emit('focus-within')"
	@blur-within="$emit('blur-within')"
>
	<div
		v-if="props.isFocused"
		class="sr-only"
		role="alert"
	>
		<slot name="title"></slot> is currently selected
	</div>
	<div class="flex">
		<div class="">
			<h5 class="font-bold"><slot name="title"></slot></h5>
		</div>
		<div class="ml-auto rtl:ml-0 rtl:mr-auto">
			<icon-far-circle class="checkmark checkmark-unchecked fill-neutral-grey-dark w-6 h-6" />
			<icon-fas-circle-check class="checkmark checkmark-checked fill-primary-blue-base w-6 h-6" />
		</div>
	</div>
	
	<!-- Mobile only -->
	<div class=">md:hidden mt-3">
		<div class="flex items-center font-semibold">
			<span class="">{{ props.locationA.displayValue }} ({{ props.locationA.code }})</span>
			<icon-far-right-long v-if="props.isOneWay" class="mx-4" aria-hidden="true" />
			<icon-far-arrow-right-arrow-left v-else class="mx-4" aria-hidden="true" />
			<span class="">{{ props.locationB.displayValue }} ({{ props.locationB.code }})</span>
		</div>
	</div>
	
	<div class="mt-3 text-sm leading-tight">
		<!-- Desktop Journey Display -->
		<div class="journey-display-wrapper md:hidden flex items-end gap-2 rounded-xl p-4">
			<div class="basis-0 flex-grow">
				<p class="font-semibold text-center">{{ props.locationA.displayValue }} ({{ props.locationA.code }})</p>
			</div>
			<div class="basis-0 flex-grow flex flex-col">
				<div class="mx-auto mb-3 text-center">
					{{ dayjs(props.dateToMalaysia).format('DD MMM YYYY') }}
				</div>
				<div class="airplane-line w-full mt-auto mb-2" aria-hidden="true">
					<icon-fas-plane class="icon-plane transform rtl:-rotate-180" />
				</div>
			</div>
			<div class="basis-0 flex-grow">
				<p class="font-semibold text-center">
					{{ airportValue ? `${airportValue.label} (${airportValue.code})` : props.visitMalaysiaLabel }}
				</p>
			</div>
			<div class="basis-0 flex-grow flex flex-col">
				<div class="mx-auto mb-3 text-center">
					{{ departureDate ? dayjs(departureDate).format('DD MMM YYYY') : props.yourDateOfChoiceLabel }}
				</div>
				<div class="airplane-line w-full mt-auto mb-2" aria-hidden="true">
					<icon-fas-plane class="icon-plane transform rtl:-rotate-180" />
				</div>
			</div>
			<div class="basis-0 flex-grow">
				<p class="font-semibold text-center">{{ props.locationB.displayValue }} ({{ props.locationB.code }})</p>
			</div>
		</div>
		
		<!-- Mobile Journey Display -->
		<div class="journey-display-wrapper >md:hidden is-mobile rounded-xl p-4 gap-5 flex flex-col">
			<div class="relative pl-4">
				<div class="dot" aria-hidden="true"></div>
				<div class="font-semibold">{{ props.locationA.displayValue }} ({{ props.locationA.code }})</div>
			</div>
			<div class="relative pl-4">
				<div class="">{{ dayjs(props.dateToMalaysia).format('DD MMM YYYY') }}</div>
			</div>
			<div class="relative pl-4">
				<div class="dot" aria-hidden="true"></div>
				<div class="font-semibold">
					{{ airportValue?.label ?? props.visitMalaysiaLabel }}
				</div>
			</div>
			<div class="relative pl-4">
				<div class="">
					{{ departureDate ? dayjs(departureDate).format('DD MMM YYYY') : props.yourDateOfChoiceLabel }}
				</div>
			</div>
			<div class="relative pl-4">
				<div class="dot" aria-hidden="true"></div>
				<div class="font-semibold">{{ props.locationB.displayValue }} ({{ props.locationB.code }})</div>
			</div>
		</div>
	</div>
	
	<div class="mt-6">
		<div class="flex justify-center gap-18 lg:gap-10 md:gap-6 md:flex-col">
			<div class="basis-0 flex-grow max-w-[320px] flex flex-col">
				<p class="font-semibold pb-2 leading-tight">{{ props.question1Text }}</p>
				<AirportPicker
					ref="airportPickerEl"
					v-model="airportValue"
					:options="props.domesticAirportList"
					:labelText="props.toLabel"
					:placeholderText="$t('Select destination')"
					:name="`${formNamePrefix}-locationInMalaysia`"
					:attrs="{
						'aria-label': props.question1Text,
					}"
					variant="bst-widget"
					:required="props.isFocused"
				>
					<template #mobile-title>
						<slot name="mobile-question-1-title">
							{{ props.question1Text }}
						</slot>
					</template>
				</AirportPicker>
			</div>
			<div class="basis-0 flex-grow max-w-[320px] flex flex-col">
				<p class="font-semibold pb-2 leading-tight">{{ props.question2Text }}</p>
				<DatePicker
					ref="datePickerEl"
					v-model="departureDate"
					:name="`${formNamePrefix}-departDate`"
					:ariaLabel="props.question2Text"
					:minDate="props.dateToMalaysia"
					:maxDate="props.dateLeaveMalaysia"
					:disablePastDates="true"
					:displayYear="['t', 't+1']"
					:required="props.isFocused"
					:disableAutoCompleteForAmal="props.disableAutoCompleteForAmal"
					variant="bst-widget"
				>
					<template #default>
						{{ props.departLabel }}
					</template>
					
					<template #mobile-title>
						<slot name="mobile-question-2-title">
							{{ props.question2Text }}
						</slot>
					</template>
				</DatePicker>
			</div>
		</div>
	</div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.OptionBST {
	border: 2px solid var(--neutral-grey-light);
	cursor: pointer;
	transition: border-color 0.2s, outline 0.2s;
	@apply rounded-xl px-5 pt-4 pb-6;
	
	&:hover {
		border-color: var(--neutral-grey-base);
	}
	&:focus-visible {
		outline: 2px solid var(--semantic-blue-base);
	}
	
	&.is-selected, &.is-focused {
		border-color: var(--primary-blue-base);
		@apply shadow-type-a;
		
		.checkmark-unchecked {
			display: none;
		}
		.checkmark-checked {
			display: block;
		}
		.journey-display-wrapper {
			background-color: var(--secondary-teal-extralight);
		}
	}
}

.journey-display-wrapper {
	background-color: var(--neutral-grey-extralight);
	position: relative;
	
	&.is-mobile {
		&::before {
			content: '';
			width: 1px;
			height: calc(100% - 3rem);
			position: absolute;
			left: 18px;
			margin-top: 4px;
			background-color: var(--primary-blue-base);
		}
	}
	
	.dot {
		width: 7px;
		height: 7px;
		background-color: var(--primary-blue-base);
		border-radius: 100%;
		position: absolute;
		left: 0;
		top: 3.5px;
	}
}

.checkmark-checked {
	display: none;
}
.checkmark-unchecked {
	display: block;
}

.airplane-line {
	height: 1.5px;
	color: var(--primary-blue-base);
	background-color: currentColor;
	position: relative;
	display: flex;
	justify-content: center;
	
	&::before {
		content: '';
		width: 7px;
		height: 7px;
		display: block;
		border-radius: 100%;
		background-color: currentColor;
		position: absolute;
		bottom: -3.5px;
		left: 0;
	}
	&::after {
		content: '';
		width: 7px;
		height: 7px;
		display: block;
		border-radius: 100%;
		background-color: currentColor;
		position: absolute;
		bottom: -3.5px;
		right: 0;
	}
}
.icon-plane {
	position: relative;
	top: -7.5px;
}

</style>
