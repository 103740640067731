<script>
export default {
	name: 'SkipToMainContent',
};
</script>

<script setup>
const props = defineProps({
	// modelValue: { type: String, default: '' },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

</script>

<template>
<a
	class="SkipToMainContent typo-body-text-3"
	href="#content"
>{{ $t('Skip to main content') }}</a>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SkipToMainContent {
	position: fixed;
	top: calc(var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight) + 12px);
	left: 10vw;
	z-index: -999;
	text-align: center;
	transform: translate(-9999px, -9999px);
	display: inline-block;
	width: auto;
	color: var(--primary-blue-base);
	background-color: #FFF;
	line-height: 1;
	--focus-visible-outline-offset: 1px;
	font-weight: 600;
	@apply py-2.5 px-4 rounded-5xl;
}

.SkipToMainContent {
	
	&:focus-visible, &:active {
		z-index: 9000;
		transform: translate(0, 0);
	}
}

</style>
