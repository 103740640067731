<script>
export default {
	name: 'EmptyGap',
	editConfig: {
		emptyLabel: 'Empty Gap',
		isEmpty (props) {
			const desktopGapInt = parseInt(props.desktopVerticalGap);

			// only remove the placeholder if desktopGapInt (the height) is 24 or more
			return (!props.desktopVerticalGap && !props.mobileVerticalGap) || (desktopGapInt <= 23);
		},
	},
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';

const props = defineProps({
	desktopVerticalGap: { type: String, default: '0' },
	mobileVerticalGap: { type: String, default: '0' },
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
});


const { isEditorMode } = useGlobalAEMState();

</script>



<template>
<div
	v-if="!props.isHideInDesktop"
	class="md:hidden"
	:style="{
		height: `${props.desktopVerticalGap}px`,
	}"
>
	<div v-if="isEditorMode && parseInt(props.desktopVerticalGap) > 0" class="line-vertical arrow-up arrow-down">
		<div class="author-label">Empty Gap: {{ props.desktopVerticalGap }}px</div>
	</div>
</div>

<div
	v-if="!props.isHideInMobile"
	class=">md:hidden"
	:style="{
		height: `${props.mobileVerticalGap}px`,
	}"
>
	<div v-if="isEditorMode && parseInt(props.mobileVerticalGap) > 0" class="line-vertical arrow-up arrow-down">
		<div class="author-label">Empty Gap: {{ props.mobileVerticalGap }}px</div>
	</div>
</div>

<div v-if="isEditorMode && props.isHideInDesktop" class="generic-container py-10 text-center">
	<p>Hidden in: <span v-if="props.isHideInDesktop">Desktop</span><span v-if="props.isHideInMobile">, Mobile</span></p>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

.line-vertical {
	width: 2px;
	height: 100%;
	background-color: currentColor;
	position: relative;
	color: var(--primary-blue-base);
	left: 1rem;
}

.author-label {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0.5rem;
	border-radius: 6px;
	margin: auto;
	padding: 0.1rem 0.5rem;
	background-color: var(--secondary-teal-dark);
	color: white;
	width: max-content;
	height: max-content;
	font-size: 0.75rem;
	line-height: 1;
}

.arrow-up::after,
.arrow-down::before,
.arrow-left::after,
.arrow-right::before {
	content: '';
	position: absolute;
	width: 0px;
	height: 0px;
	border: 5px solid transparent;
}

.arrow-down::before {
	top: -7px;
	left: -4px;
	border-bottom: 10px solid currentColor;
}
.arrow-up::after {
	bottom: -7px;
	left: -4px;
	border-top: 10px solid currentColor;
}

</style>
