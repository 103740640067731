
import { h, defineComponent, onMounted, markRaw, computed, getCurrentInstance, nextTick } from 'vue';
import { modelProvider } from './composables/model-provider.js';
import { editableProvider } from './composables/editable-provider.js';
import { Constants } from '../Constants.js';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { refreshAEMEditableOverlays } from '~/logic/aem/refresh-aem-editable-overlays.js';


const WithModelAndEditable = (ChildComponent, _editConfig = {}, modelConfig = {}) => {
	
	const finalModelConfig = {
		...({
			injectPropsOnInit: true,
			forceReload: false,
		}),
		...modelConfig,
	};
	
	return defineComponent({
		name: 'WithModelAndEditable',
		inheritAttrs: false,
		props: {
			cqPath: { type: String, default: '' },
			pagePath: { type: String, default: '' },
			itemPath: { type: String, default: '' },
			cqForceReload: { type: Boolean, default: () => finalModelConfig.forceReload ?? false },
			injectPropsOnInit: { type: Boolean, default: () => finalModelConfig.injectPropsOnInit ?? true },
			
			containerClass: { type: String, default: '' },
			cqType: { type: String, default: '' },
			appliedCssClassNames: { type: String, default: '' },
			
			cqItems: { type: Object, default: null },
			cqItemsOrder: { type: Array, default: null },
			hasEditableCapability: { type: Boolean, default: true },
		},
		setup (props, context) {
			const instance = getCurrentInstance();
			const instanceEditConfig = instance.type?.editConfig;
			
			// console.log('ChildComponent = ', ChildComponent);
			
			const finalEditConfig = {
				..._editConfig,
				...instanceEditConfig,
			};
			
			const {
				componentId,
				aemProperties,
				childProperties,
			} = modelProvider({ props, context });
			
			const {
				wrapperHtmlAttributes,
				wrapperHtmlClass,
				emptyPlaceholderDiv,
				editConfig,
			} = editableProvider({ props, context, editConfig: finalEditConfig, childProperties });
			
			
			const mergedWrapperClass = computed(() => {
				if (editConfig.value.isApplyStyleSystemClassesOnWrapperDiv) {
					return `${wrapperHtmlClass.value} ${aemProperties.value[Constants.APPLIED_CLASS_NAMES]}`;
				} else {
					return `${wrapperHtmlClass.value}`;
				}
			});
			
			const onChildMounted = () => {
				if (AuthoringUtils.isInEditor()) refreshAEMEditableOverlays();
			};
			
			
			return {
				componentId,
				aemProperties,
				childProperties,
				
				wrapperHtmlAttributes,
				emptyPlaceholderDiv,
				
				mergedWrapperClass,
				ChildComponent: markRaw(ChildComponent),
				
				onChildMounted,
			};
		},
		render () {
			const ChildComponent = this.ChildComponent;
			
			return (
				<div
					{...this.wrapperHtmlAttributes}
					class={this.mergedWrapperClass}
					data-component={ChildComponent?.name ?? ''}
					key={this.componentId}
				>
					<ChildComponent
						aemProperties={this.aemProperties}
						{...this.childProperties}
						onVnodeMounted={this.onChildMounted}
					/>

					{ this.emptyPlaceholderDiv }
				</div>
			);
		},
	});
};

export {
	WithModelAndEditable,
};
