<script>
export default {
	name: 'EmptyPageLayout',
	expose: [],
	inheritAttrs: false,
};
</script>


<script setup>
import BtnBackToTop from '~/components/BtnBackToTop.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';

const { pageProperties } = useGlobalAEMState();


const showBackToTop = computed(() => {
	return !!(pageProperties.value?.backToTop);
});

onMounted(() => {
	const {
		isAuthStateReady,
	} = useAuthStore();
	
	// also have to set isAuthStateReady to true due to not having a header in this layout
	isAuthStateReady.value = true;
});

</script>


<template>
<div class="EmptyPageLayout" data-layout="EmptyPage">
	<main id="main" tabindex="-1" :aria-label="$t('Main content')">
		<slot></slot>
	</main>
	<div v-if="showBackToTop" class="floating-bottom-right-container">
		<BtnBackToTop />
	</div>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

</style>
