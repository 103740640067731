<script>
export default {
	name: 'ImageTileBanner',
	inheritAttrs: false,
};
</script>

<script setup>
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';
import AppHyperlink from '~/components/AppHyperlink.vue';


const props = defineProps({
	desktopImgSrc: { type: String, default: '' },
	mobileImgSrc: { type: String, default: '' },
	desktopImageAltText: { type: String, default: '' },
	mobileImageAltText: { type: String, default: '' },
	desktopBannerHeight: { type: String, default: 'small' }, // <--- 'small' only
	mobileBannerHeight: { type: String, default: 'large' }, // <--- 'small' | 'large'
	keyVisualSrc: { type: String, default: '' },
	keyVisualAlignment: { type: String, default: 'left' }, // <--- 'left' | 'right'
	title: { type: String, default: '' },
	description: { type: String, default: '' },
	url: { type: String, default: '' },
	openNewTab: { type: Boolean, default: false },
});

const computedGradientValue = computed(() => {
	if (isMobileViewport.value) {
		return 'to bottom, rgba(31, 64, 116, 0) 0%, rgba(31, 64, 116, 90) 40%, rgba(31, 64, 116, 100) 100%';
	}
	return `to ${props.keyVisualAlignment === 'left' ? 'right' : 'left'}, rgba(31, 64, 116, 0) 0%, rgba(31, 64, 116, 90) 40%, rgba(31, 64, 116, 100) 100%`;
});

const bannerSizeDesktopMap = {
	small: '250',
};

const bannerSizeMobileMap = {
	small: '382',
	large: '569',
};

const computedImgSrcBg = computed(() => {
	if (isMobileViewport.value && props.mobileImgSrc) return props.mobileImgSrc;
	return props.desktopImgSrc;
});


const aaFlag = computed(() => {
	return props.url ? 'enabled' : 'disabled';
});

const wrapperAttrs = computed(() => {
	if (props.url) {
		// <AppHyperlink> tag
		return {
			is: AppHyperlink,
			href: props.url,
			target: props.openNewTab ? '_blank' : '_self',
		};
	} else {
		// <div> tag
		return {
			is: 'div',
		};
	}
});


</script>



<template>
<component
	:is="props.url ? AppHyperlink : 'div'"
	v-bind="wrapperAttrs"
	class="ImageTileBanner"
	:style="{
		'--BannerSizeDesktop': `${bannerSizeDesktopMap[props.desktopBannerHeight]}px`,
		'--BannerSizeMobile': `${bannerSizeMobileMap[props.mobileBannerHeight]}px`,
	}"
>
	<div
		v-aa:[aaFlag]="[
			{
				clickName: isMobileViewport ? mobileImageAltText : desktopImageAltText,
				clickComponentType: 'URL',
				componentName: 'Banner',
				componentID: props.componentIdAA,
			},
			{
				name: isMobileViewport ? mobileImageAltText : desktopImageAltText,
				type: isUrlMatchCurrentHostname(props.url) ? 'other' : 'exit',
			},
		]"
		class="ImageTileBanner-inner"
		:class="[
			props.keyVisualAlignment === 'right' ? 'flex-row-reverse' : 'flex-row',
		]"
	>
		<div class="ImageTileBanner-image">
			<img
				class="object-cover object-center"
				:src="computedImgSrcBg"
				:alt="isMobileViewport ? mobileImageAltText : desktopImageAltText"
			/>
		</div>
		<div
			class="ImageTileBanner-text-placeholder"
		>
			<!-- <h6 class="title >md:line-clamp-3">{{ title }}</h6>
			<p class="line-clamp-4">{{ description }}</p>
			<a class="cta-btn" :href="url" :target="openNewTab ? '_blank' : '_self'">
				<span>{{ $t('Explore more') }}</span>
				<icon-far-arrow-right class="cta-btn-arrow text-[16px] inline-block fill-white" />
			</a> -->

			<slot></slot>

		</div>
	</div>
</component>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.ImageTileBanner {
	--mobileBgKeyVisualSize: 220px;
	text-decoration: none;
}

.ImageTileBanner-inner {
	height: var(--BannerSizeDesktop);
	position: relative;
	display: flex;
	align-items: center;
	overflow: hidden;
	border: 1px solid var(--neutral-grey-base);
	@apply rounded-3xl bg-neutral-white-base;
	
	@media #{var.$query-max-md} {
		height: auto;
		min-height: var(--BannerSizeMobile);
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
	}
}

.title {
	font-size: 32px;
	@apply leading-10 mb-4;
}

.cta-btn {
	transition: all ease 200ms;
	display: inline-block;
	@apply mt-6;
	
	.cta-btn-arrow {
		transition: all ease 200ms;
		@apply ml-2;
	}
	
	&:hover {
		color: var(--primary-blue-extralight);
		
		.cta-btn-arrow {
			fill: var(--primary-blue-extralight);
			@apply ml-3;
		}
	}
}

.ImageTileBanner-text-placeholder {
	width: calc(100% - 376px);
	position: relative;
	z-index: 30;
	color: var(--primary-black-base);
	@apply m-10;
	
	@media #{var.$query-max-md} {
		width: 100%;
		@apply mx-5 mt-5 mb-6;
	}
}

.ImageTileBanner-image {
	width: 376px;
	height: 100%;
	flex: 0 0 376px;
	overflow: hidden;
	@media #{var.$query-max-md} {
		flex: auto;
		height: auto;
	}
}

</style>
