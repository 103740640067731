import { h, ref, computed, onMounted, onUnmounted, readonly } from 'vue';
import { Model, ModelManager, PathUtils, AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import Utils from '../../Utils.js';
import { Constants } from '../../Constants.js';
import DefaultEditConfig from '../../DefaultEditConfig.js';


const editableProvider = ({ aemProperties, computedCqPath, props, context, childProperties, editConfig = {} }) => {
	
	const finalEditConfig = computed(() => {
		return {
			...DefaultEditConfig,
			...editConfig,
		};
	});
	
	const wrapperHtmlAttributes = computed(() => {
		if (AuthoringUtils.isInEditor() === false || props.hasEditableCapability === false) return {};
		
		return {
			'data-cq-data-path': props?.cqPath || computedCqPath.value || null,
			'data-cq-resource-type': finalEditConfig.value?.resourceType ?? props.cqType ?? '',
		};
	});
	
	const wrapperHtmlClass = computed(() => {
		const cssClassNames = props?.cssClassNames ?? '';
		const containerClass = props?.containerClass ?? '';
		// const containerClass = '';
		
		return `${cssClassNames} ${containerClass}`;
	});
	
	const shouldRenderEmptyPlaceholder = computed(() => {
		return (
			AuthoringUtils.isInEditor() &&
			finalEditConfig.value?.isEmpty({ ...props, ...context.attrs, ...childProperties?.value })
		);
	});
	const emptyPlaceholderDiv = computed(() => {
		if (shouldRenderEmptyPlaceholder.value === false) return null;
		
		return h(
			'div',
			{
				'class': Constants._PLACEHOLDER_CLASS_NAMES,
				'data-emptytext': finalEditConfig.value.emptyLabel,
			},
		);
	});
	
	return {
		wrapperHtmlAttributes,
		wrapperHtmlClass,
		
		emptyPlaceholderDiv,
		editConfig: finalEditConfig,
	};
};

export {
	editableProvider,
};

