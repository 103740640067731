import { h, defineComponent } from 'vue';
import { AllowedComponentsPlaceholder } from './AllowedComponentsPlaceholder.jsx';


/**
 * List of placeholder of the Allowed Component Container.
 */
const AllowedComponentsPlaceholderList = defineComponent({
	name: 'AllowedComponentsPlaceholderList',
	props: {
		emptyLabel: { type: String, default: 'No allowed components' },
		placeholderClass: { type: String, default: '' },
		components: { type: Array, default: () => ([]) },
		title: { type: String, default: '' },
		cqPath: { type: String, default: '' },
	},
	render () {
		const { components, placeholderClass, title, emptyLabel } = this;
		const listLabel = (components && (components.length > 0)) ? title : emptyLabel;
		
		return (
			<div class={`aem-AllowedComponent--list ${placeholderClass ?? ''}`}>
				<div class="aem-AllowedComponent--title" data-text={listLabel}></div>
				{
					components.map((component) => {
						return (
							<AllowedComponentsPlaceholder
								path={component.path}
								emptyLabel={component.title}
							/>
						);
					})
				}
			</div>
		);
	},
});

export {
	AllowedComponentsPlaceholderList,
};

