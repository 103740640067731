import { sanitizeUrl } from './helpers/sanitize-url.js';

/**
 * Helper functions for interacting with the AEM environment.
 */
const Utils = {
	/**
	 * Transforms the item data to component properties.
	 * It will replace ':' with 'cq' and convert the name to CameCase.
	 *
	 * @param item - the item data
	 * @returns the transformed data
	 */
	modelToProps (item) {
		/**
		 * Transformation of internal properties namespaced with [:] to [cq]
		 * :myProperty => cqMyProperty
		 * @param propKey
		 */
		function transformToCQ (propKey) {
			const tempKey = propKey.substr(1);
			return `cq${ tempKey.substr(0, 1).toUpperCase() }${tempKey.substr(1)}`;
		}
		
		const keys = Object.getOwnPropertyNames(item);
		const props = {};
		
		keys.forEach((key) => {
			let propKey = key;
			
			if (propKey.startsWith(':')) {
				propKey = transformToCQ(propKey);
			}
			
			props[propKey] = item[key];
		});
		
		// Issue when passing itemProps to JSX Element.
		// Circular Object
		// const cqItems = props.cqItems;
		// const cqItemsOrder = props.cqItemsOrder;
		// props.cqItems = {}
		// props.__ob__ = {}
		
		return {
			...props,
			// cqItems: cqItems,
			// cqItemsOrder: cqItemsOrder
			// cqHierarchyType: props.cqHierarchyType,
			// cqPath: props.cqPath,
			// cqType: props.cqType,
			// cssClassNames: props.cssClassNames,
			// language: props.language,
			// designPath: props.designPath,
			// lastModifiedDate: props.lastModifiedDate,
			// templateName: props.templateName,
			// title: props.title,
			// className: props.className
		};
	},
	
	/**
	 * Determines the cqPath of a component given its props
	 *
	 * @returns cqPath of the component
	 */
	getCQPath (componentProps) {
		const { pagePath = '', itemPath = '', cqPath = '' } = componentProps;
		if (pagePath && !cqPath) {
			const path = sanitizeUrl(itemPath ? `${pagePath}/jcr:content/${itemPath}` : pagePath);
			return path.replace(/\/+/g, '/').replace(/\/$/, '');
		}
		return cqPath;
	},
};

export default Utils;
