// Note: the source code of this file was adapted from the source code of vue-router 4.1.6

const HASH_RE = /#/g; // %23
const AMPERSAND_RE = /&/g; // %26
const SLASH_RE = /\//g; // %2F
const EQUAL_RE = /[=]/g; // %3D
const IM_RE = /\?/g; // %3F
export const PLUS_RE = /\+/g; // %2B

const ENC_BRACKET_OPEN_RE = /%5B/g; // [
const ENC_BRACKET_CLOSE_RE = /%5D/g; // ]
const ENC_CARET_RE = /%5E/g; // ^
const ENC_BACKTICK_RE = /%60/g; // `
const ENC_CURLY_OPEN_RE = /%7B/g; // {
const ENC_PIPE_RE = /%7C/g; // |
const ENC_CURLY_CLOSE_RE = /%7D/g; // }
const ENC_SPACE_RE = /%20/g; // }

function commonEncode (text) {
	return encodeURI(`${text}`)
		.replace(ENC_PIPE_RE, '|')
		.replace(ENC_BRACKET_OPEN_RE, '[')
		.replace(ENC_BRACKET_CLOSE_RE, ']');
}

export function encodeQueryValue (text) {
	return (
		commonEncode(text)
			// Encode the space as +, encode the + to differentiate it from the space
			.replace(PLUS_RE, '%2B')
			.replace(ENC_SPACE_RE, '+')
			.replace(HASH_RE, '%23')
			.replace(AMPERSAND_RE, '%26')
			.replace(ENC_BACKTICK_RE, '`')
			.replace(ENC_CURLY_OPEN_RE, '{')
			.replace(ENC_CURLY_CLOSE_RE, '}')
			.replace(ENC_CARET_RE, '^')
	);
}

export function encodeQueryKey (text) {
	return encodeQueryValue(text).replace(EQUAL_RE, '%3D');
}

export function decode (str) {
	try {
		return window.decodeURIComponent(str);
	} catch (err) {
		console.warn(`Error decoding "${str}". Leaving it intact.`);
	}
	return str;
}

export function parseQuery (query, applyDecoding = false) {
	const res = {};
	let _query = query.trim().replace(/^(\?|#|&)/, '');
	
	if (!_query) {
		return res;
	}
	
	_query.split('&').forEach((param) => {
		const parts = param.replace(/\+/g, ' ').split('=');
		
		const key = applyDecoding ? decode(parts.at(0)) : parts.at(0);
		
		parts.shift(); // remove the first element
		
		const val = parts.length > 0 ? (applyDecoding ? decode(parts.join('=')) : parts.join('=')) : null;
		
		if (res[key] === undefined) {
			res[key] = val;
		} else if (Array.isArray(res[key])) {
			res[key].push(val);
		} else {
			res[key] = [res[key], val];
		}
	});
	
	return res;
}

export function stringifyQuery (query, applyEncoding = false) {
	let search = '';
	for (let key in query) {
		const value = query[key];
		
		// Change to DO NOT ENCODE
		if (applyEncoding) key = encodeQueryKey(key);
		
		if (value === null) {
			// only null adds the value
			if (value !== undefined) {
				search += (search.length ? '&' : '') + key;
			}
			continue;
		}
		// keep null values (ORIGINAL CODE FROM VUE-ROUTER)
		// const values = Array.isArray(value) ? value.map((v) => v && encodeQueryValue(v)) : [value && encodeQueryValue(value)];
		
		const values = applyEncoding ? (Array.isArray(value) ? value.map((v) => v && encodeQueryValue(v)) : [value && encodeQueryValue(value)]) : [value];
		
		/* eslint-disable no-loop-func */
		values.forEach((value) => {
			// skip undefined values in arrays as if they were not present
			// smaller code than using filter
			if (value !== undefined) {
				// only append & with non-empty search
				search += (search.length ? '&' : '') + key;
				if (value !== null) search += `=${ value}`;
			}
		});
	}

	return search;
}

