<script>
export default {
	name: 'HeroBanner',
	inheritAttrs: false,
};
</script>

<script setup>
import CTAButton from '~/aem-components/CTAButton.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import AppHyperlink from '~/components/AppHyperlink.vue';

const props = defineProps({
	desktopImgSrc: { type: String, default: '' },
	mobileImgSrc: { type: String, default: '' },
	desktopImageAltText: { type: String, default: '' },
	mobileImageAltText: { type: String, default: '' },
	bgColor: { type: String, default: '' }, // <--- color code from color picker
	bgStyle: { type: String, default: '' }, // <--- 'mag-midnightgreen-dark' | 'blue' | 'teal'
	desktopImgHeight: { type: String, default: 'medium' }, // <--- 'small' | 'medium' | 'large'
	mobileImgHeight: { type: String, default: 'medium' }, // <--- 'small' | 'medium' | 'large'
	placeholderHorizontalAlignment: { type: String, default: 'center' }, // <--- 'left' | 'center' | 'right'
	placeholderVerticalAlignment: { type: String, default: 'middle' }, // <--- 'top' | 'middle' | 'bottom'
	useOverlay: { type: String, default: '' }, // <--- 'full' | 'top-bottom-only'
	overlayOpacityLevel: { type: String, default: '70' }, // <--- '30' | '50' | '70'
	useTagTheme: { type: String, default: 'white-text-blue-background' }, // <--- 'white-text-blue-background' | 'blue-text-white-background' | 'mhh-white-text-pink-background'
	bottomCurve: { type: Boolean, default: true },
	title: { type: String, default: '' },
	description: { type: String, default: '' },
	tagList: { type: Array, default: () => [] },
	url: { type: String, default: '' },
	openNewTab: { type: Boolean, default: false },
	imgSize: { type: String, default: '' }, // <--- 'cover' | 'contain'
	
	// used when props.useTagTheme === 'mhh-white-text-pink-background'
	mhhTagMultiList: { type: Array, default: () => [] }, // <-- { ghTagName: '' }
	mhhTagPlaceholderPosition: { type: String, default: '' }, // <-- 'above-component-placeholder' | 'below-component-placeholder'
});

const bannerSizeDesktopMap = {
	small: '408',
	medium: '550',
	large: '768',
};

const bannerSizeMobileMap = {
	small: '274',
	medium: '338',
	large: '736',
};

const placeholderHorizontalAlignmentMap = {
	left: 'w-10/12 >md:justify-start',
	center: 'placeholder-wide >md:justify-center',
	right: 'w-10/12 >md:justify-end',
};

const placeholderVerticalAlignmentMap = {
	top: '>md:items-start',
	middle: '>md:items-center',
	bottom: '>md:items-end',
};

const computedImgSrcBg = computed(() => {
	if (isMobileViewport.value && props.mobileImgSrc) return props.mobileImgSrc;
	return props.desktopImgSrc;
});

const computedBgStyle = computed(() => {
	if (props.bgStyle === 'mag-midnightgreen-dark') return 'var(--primary-midnightgreen-dark)';
	if (props.bgStyle === 'blue') return 'var(--primary-blue-base)';
	if (props.bgStyle === 'teal') return 'var(--secondary-teal-base)';
	return 'transparent';
});

const computedTagList = computed(() => {
	return props.tagList.filter( (obj) => obj.ghTagName !== null );
});

const wrapperAttrs = computed(() => {
	if (props.url) {
		// <AppHyperlink> tag
		return {
			is: AppHyperlink,
			href: props.url,
			target: props.openNewTab ? '_blank' : '_self',
		};
	} else {
		// <div> tag
		return {
			is: 'div',
		};
	}
});

</script>


<template>
<component
	:is="props.url ? AppHyperlink : 'div'"
	v-bind="wrapperAttrs"
	class="HeroBanner w-full"
	:class="{
		'use-overlay': useOverlay,
		'overlay-opacity-50': overlayOpacityLevel === '50',
		'overlay-opacity-30': overlayOpacityLevel === '30',
		'overlay-type-full': useOverlay === 'full',
		'overlay-type-top-bottom-only': useOverlay === 'top-bottom-only',
		'use-theme-light': useTagTheme === 'white-text-blue-background',
		'use-theme-dark': useTagTheme === 'blue-text-white-background',
		'use-bottom-curve': bottomCurve,
		'use-batik-pattern': bgStyle,
		'use-brighter-batik-songket-blue-mag': props.bgStyle === 'mag-midnightgreen-dark',
	}"
	:style="{
		'--batik-bg-colour': bgStyle ? computedBgStyle : 'var(--primary-midnightgreen-dark)',
		'background-color': bgColor,
	}"
	:title="isMobileViewport ? mobileImageAltText : desktopImageAltText"
>
	<div
		class="HeroBanner-inner bg-center bg-cover relative"
		:style="{
			'background-image': props.imgSize === 'contain' ? 'none' : `url(${computedImgSrcBg})`,
			'--BannerSizeDesktop': `${bannerSizeDesktopMap[desktopImgHeight]}px`,
			'--BannerSizeMobile': `${bannerSizeMobileMap[mobileImgHeight]}px`,
		}"
		:class="{
			'bg-size-contain': props.imgSize === 'contain',
		}"
	>
		<img
			v-if="props.imgSize === 'contain'"
			:src="computedImgSrcBg"
			class="w-full"
			:alt="isMobileViewport ? mobileImageAltText : desktopImageAltText"
		/>
		<div
			class="generic-container"
			:style="{
				...(props.imgSize === 'contain' ? { 'left': '50%' } : null),
				...(props.imgSize === 'contain' ? { 'transform': 'translateX(-50%)' } : null),
			}"
		>
			<div
				class="mx-auto h-full flex justify-center items-center"
				:class="`${placeholderHorizontalAlignmentMap[placeholderHorizontalAlignment]} ${placeholderVerticalAlignmentMap[placeholderVerticalAlignment]}`"
			>
				<span v-if="desktopImageAltText || mobileImageAltText" class="sr-only">
					{{ $t('Image of banner') }}: <span v-if="desktopImageAltText" class="md:hidden">{{ desktopImageAltText }}</span> <span v-if="mobileImageAltText" class=">md:hidden">{{ mobileImageAltText }}</span>
				</span>
				
				<div
					class="banner-content-placeholder text-center"
					:style="{ 'text-align': !isMobileViewport && placeholderHorizontalAlignment }"
				>
					<div v-if="computedTagList?.length" class="tag-group">
						<span v-for="(tag, index) in computedTagList" :key="`tagList-${index}`" class="tag">{{ tag.ghTagName }}</span>
					</div>
					
					<!-- <h1 class="mb-4">{{ title }}</h1>
					<p class="mb-6">{{ description }}</p>
					<CTAButton
						:styleType="useTagTheme === 'dark' ? 'secondary' : 'primary'"
						ctaText="Button text"
						ctaSizing="auto"
						:alignmentDesktop="placeholderHorizontalAlignment"
					/> -->
					
					<div
						v-if="props.useTagTheme === 'mhh-white-text-pink-background' && props.mhhTagPlaceholderPosition === 'above-component-placeholder'"
						class="mhh-pink-tag-wrapper"
					>
						<div
							v-for="(tag, index) in props.mhhTagMultiList"
							:key="index"
							v-html-sanitize="tag.ghTagName"
							class="mhh-pink-tag"
						></div>
					</div>
					
					<slot></slot>
					
					<div
						v-if="props.useTagTheme === 'mhh-white-text-pink-background' && props.mhhTagPlaceholderPosition === 'below-component-placeholder'"
						class="mhh-pink-tag-wrapper"
					>
						<div
							v-for="(tag, index) in props.mhhTagMultiList"
							:key="index"
							v-html-sanitize="tag.ghTagName"
							class="mhh-pink-tag"
						></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="bottomCurve" class="curvy-section-breaker flex items-center z-20">
		<div class="generic-container"></div>
	</div>
</component>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.HeroBanner {
	--breakerHeight: 40px;
	position: relative;
	background-color: var(--batik-bg-colour);

	&:hover {
		text-decoration: none;
	}
}

.HeroBanner-inner {
	display: flex;
	height: calc(var(--BannerSizeDesktop) + var(--breakerHeight) + var(--headerHeight));
	background-repeat: no-repeat;
	width: 100%;
	@apply px-4;
	
	@media #{var.$query-max-md} {
		height: calc(var(--BannerSizeMobile) + var(--breakerHeight) + var(--headerHeight));
	}
}
.HeroBanner-inner.bg-size-contain {
	background: none;
	height: auto;
	@apply px-0;

	.generic-container {
		position: absolute;
		@apply px-8 h-full;
	}
}

.use-theme-light {
	@apply text-base;
	
	.tag {
		background-color: var(--primary-blue-base);
		color: white;
	}
}

.use-theme-dark {
	color: white;
	
	.tag {
		background-color: white;
		color: var(--primary-blue-base);
	}
}

.banner-content-placeholder {
	position: relative;
	max-width: 588px;
	padding-top: calc(64px + var(--headerHeight));
	padding-bottom: calc(64px + var(--breakerHeight));
	z-index: 20;
	
	@media #{var.$query-max-md} {
		padding-top: calc(40px + var(--headerHeight));
		padding-bottom: calc(40px + var(--breakerHeight));
	}
}

.use-overlay {
	&.overlay-type-full::before {
		content: '';
		background-image: linear-gradient(to bottom, color.change(#333, $alpha: 0.7), color.change(#333, $alpha: 0));
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		opacity: 0.7;
	}

	&.overlay-type-top-bottom-only {
		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			z-index: 10;
			opacity: 0.7;
			height: 15%;
		}

		&::before {
			background-image: linear-gradient(to bottom, color.change(#333, $alpha: 0.7), color.change(#333, $alpha: 0));
			top: 0;
		}

		&::after {
			background-image: linear-gradient(to bottom, color.change(#333, $alpha: 0), color.change(#333, $alpha: 0.7));
			bottom: 0;
		}
	}

	&.overlay-opacity-30 {
		&::before,
		&::after {
			opacity: 0.3;
		}
	}

	&.overlay-opacity-50 {
		&::before,
		&::after {
			opacity: 0.5;
		}
	}
}

.use-bottom-curve.overlay-type-top-bottom-only {
	&::after {
		height: calc(15% + var(--headerHeight));
	}
}

.tag-group {
	@apply mb-5;
	
	@media #{var.$query-max-md} {
		@apply mb-4;
	}
}

.tag {
	text-transform: uppercase;
	display: inline-block;
	font-weight: 700;
	background-color: var(--primary-blue-base);
	@apply rounded px-3 py-1 m-1 text-xs;
}

.use-batik-pattern {
	--batik-pattern-img: url('~/assets/batik-pattern.svg');
	background-color: var(--batik-bg-colour);
	background-image: var(--batik-pattern-img);
	background-repeat: repeat;
	position: relative;
	
	&.use-brighter-batik {
		--batik-pattern-img: url('~/assets/batik-pattern-brighter.svg');
	}

	&.use-brighter-batik-songket-blue-mag {
		--batik-pattern-img: url('~/assets/batik-pattern-songket-blue-mag-brighter.svg');
	}

}

.mhh-pink-tag-wrapper {
	display: flex;
	@apply gap-2;
	
	@media #{var.$query-max-md} {
		flex-direction: column;
	}
}

.mhh-pink-tag {
	border-radius: 15px;
	background-color: var(--secondary-mhh-pink-base);
	color: white;
	line-height: 1.25;
	@apply pt-1.5 px-4.5 pb-1.5;
}

[data-use-theme="MAG"] {
	.use-theme-light .tag {
		background-color: var(--primary-midnightgreen-base);
	}

	.use-theme-dark .tag {
		color: var(--primary-midnightgreen-base);
	}

}

[data-use-theme="MHH"] {
	.placeholder-wide .banner-content-placeholder {
		max-width: 90%;
	}
}

[data-use-theme="MABA"] {
	.HeroBanner {
		--breakerHeight: 0px;
	}
}

</style>
