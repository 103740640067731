const invalidProtocolRegex = /^([^\w]*)(javascript|data|vbscript)/im;
const htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
const ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
const urlSchemeRegex = /^([^:]+):/gm;
const relativeFirstCharacters = ['.', '/'];

function _isRelativeUrlWithoutProtocol (url) {
	return relativeFirstCharacters.indexOf(url[0]) > -1;
}

// adapted from https://stackoverflow.com/a/29824550/2601552
function _decodeHtmlCharacters (str) {
	return str.replace(htmlEntitiesRegex, (match, dec) => {
		return String.fromCharCode(dec);
	});
}

export function sanitizeUrl (url) {
	const sanitizedUrl = _decodeHtmlCharacters(url || '').replace(ctrlCharactersRegex, '').trim();

	if (!sanitizedUrl) {
		return 'about:blank';
	}

	if (_isRelativeUrlWithoutProtocol(sanitizedUrl)) {
		return sanitizedUrl;
	}

	const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);

	if (!urlSchemeParseResults) {
		return sanitizedUrl;
	}

	const urlScheme = urlSchemeParseResults[0];

	if (invalidProtocolRegex.test(urlScheme)) {
		return 'about:blank';
	}

	return sanitizedUrl;
}

