<script>
export default {
	name: 'Divider',
	editConfig: {
		emptyLabel: 'Divider',
		isEmpty (props) {
			return true;
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>

import { isMobileViewport } from '~/logic/composables/breakpoints';

const props = defineProps({
	colour: { type: String, default: '' },
	length: { type: String, default: '' },
	desktopTopSpacing: { type: String, default: '0px' },
	desktopBottomSpacing: { type: String, default: '0px' },
	mobileTopSpacing: { type: String, default: '0px' },
	mobileBottomSpacing: { type: String, default: '0px' },
});
const emit = defineEmits([
]);

</script>



<template>
<hr
	:style="{
		'margin-top': isMobileViewport ? `${ props.mobileTopSpacing }` : `${ props.desktopTopSpacing }`,
		'margin-bottom': isMobileViewport ? `${ props.mobileBottomSpacing }` : `${ props.desktopBottomSpacing }`,
		'background': `${ props.colour }`,
		'width': `${ props.length }`,
	}"
	class="Divider"
/>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.Divider {
	border: 0;
	height: 1px;
	width: 100%;
	margin: auto;
}

</style>
