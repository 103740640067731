/* eslint-disable no-template-curly-in-string */

export default {
	en: {
		// -------- General --------
		'Main content': 'Main content',
		'New': 'New',
		'Search': 'Search',
		'Account': 'Account',
		'AskMH': 'AskMH',
		'Menu': 'Menu',
		'Image of banner': 'Image of banner',
		'Carousel pause button': 'Carousel pause button',
		'Carousel play button': 'Carousel play button',
		'Previous slide': 'Previous slide',
		'Next slide': 'Next slide',
		'Skip to main content': 'Skip to main content',
		'Last updated': 'Last updated',
		'Description of video': 'Description of video',
		'Audio toggle button': 'Audio toggle button',
		'Image of thumbnail': 'Image of thumbnail',
		'Back': 'Back',
		'Increase': 'Increase',
		'Decrease': 'Decrease',
		'Clear field': 'Clear field',
		'More info': 'More info',
		'Back to top': 'Back to top',
		'Value is {value}': 'Value is {value}',
		'More': 'More',
		'Done': 'Done',
		'Got it': 'Got it',
		'Remove': 'Remove',
		'Add another flight': 'Add another flight',
		'Search flight': 'Search flight',
		'Select destination': 'Select destination',
		'Save': 'Save',
		'Show more': 'Show more',
		'Completed': 'Completed',
		'Not Completed': 'Not Completed',
		'Optional': 'Optional',
		'Submit': 'Submit',
		'Load more': 'Load more',
		'Flag of {country}': 'Flag of {country}',
		'Dismiss notification': 'Dismiss notification',
		
		// -------- Mobile DL App Notification --------
		'Malaysia Airlines mobile app icon': 'Malaysia Airlines mobile app icon',
		'Dismiss mobile download app banner': 'Dismiss mobile download app banner',

		// -------- Search Results --------
		'No results found': 'No results found',
		'Showing {start} to {end} of {total} results': 'Showing {start} to {end} of {total} results',
		
		// -------- FileUpload --------
		'Up to': 'Up to',
		'each': 'each',
		
		// -------- Video component --------
		'Thumbnail of video': 'Thumbnail of video',
		'Decription of video': 'Decription of video',
		
		// -------- calendar --------
		'Change month or year': 'Change month or year',
		'Change year': 'Change year',
		'Year': 'Year',
		'Reset': 'Reset',
		'One-way': 'One-way',
		'Select a date': 'Select a date',
		
		// -------- Form general --------
		'Changes you made may not be saved': 'Changes you made may not be saved',
		
		// -------- form validation & restriction --------
		'disabled': 'disabled',
		'This field is invalid': 'This field is invalid',
		'This field is required': 'This field is required',
		
		// -------- Booking Widget general --------
		'Bonus Side Trip': 'Bonus Side Trip',
		'From': 'From',
		'To': 'To',
		'Continue your search': 'Continue your search',
		'Edit Search': 'Edit Search',
		'Redemption': 'Redemption',
		
		// -------- OND --------
		'Swap locations': 'Swap locations',
		'Selected departure airport is {airport}': 'Selected departure airport is {airport}',
		'Selected arrival airport is {airport}': 'Selected arrival airport is {airport}',
		'Near You': 'Near You',
		'With Malaysia Airlines': 'With Malaysia Airlines',
		'With Codeshare and Partner Network': 'With Codeshare and Partner Network',
		'Depart': 'Depart',
		'Return': 'Return',
		'Popular Destinations': 'Popular Destinations',
		'Type the airport name or code': 'Type the airport name or code',
		'Airport of departure': 'Airport of departure',
		'Airport of arrival': 'Airport of arrival',
		'With Amal': 'With Amal',
		
		// -------- DatePicker --------
		'Use arrow keys to navigate and enter key to select date': 'Use arrow keys to navigate and enter key to select date',
		'Currently displaying {monthFrom} {yearFrom} to {monthTo} {yearTo}': 'Currently displaying {monthFrom} {yearFrom} to {monthTo} {yearTo}',
		'Current selection is empty': 'Current selection is empty',
		'Current selection: {dateFrom}': 'Current selection: {dateFrom}',
		'Current selection: from {dateFrom} to {dateTo}. Navigate to the done button once you are done.': 'Current selection: from {dateFrom} to {dateTo}. Navigate to the done button once you are done.',
		'Now selecting one-way departure date': 'Now selecting one-way departure date',
		'Now selecting departure date': 'Now selecting departure date',
		'Now selecting return date': 'Now selecting return date',
		'{dateFrom} to {dateTo}': '{dateFrom} to {dateTo}',
		'Fare based on <strong>{currency}</strong>': 'Fare based on <strong>{currency}</strong>',
		'Trip from <strong>{sumPriceWithCurrency}</strong> per adult': 'Trip from <strong>{sumPriceWithCurrency}</strong> per adult',
		'Date selected should be the same or after the date of the previous configured row': 'Date selected should be the same or after the date of the previous configured row',
		'{date} is disabled': '{date} is disabled',
		'The selected dates are no longer valid. Please reselect.': 'The selected dates are no longer valid. Please reselect.',
		
		// -------- Passengers & cabin class --------
		'Passengers and cabin class': 'Passengers and cabin class',
		'{n} Passenger': '{n} Passengers | {n} Passenger | {n} Passengers',
		'Selected cabin class': 'Selected cabin class',
		'Currently selected cabin class is {selectedCabinLabel}': 'Currently selected cabin class is {selectedCabinLabel}',
		'Economy': 'Economy',
		'Business': 'Business',
		'Business Suite': 'Business Suite',
		'Passengers': 'Passengers',
		'Adults': 'Adults',
		'Teenagers': 'Teenagers',
		'Children': 'Children',
		'Infants': 'Infants',
		'More than 9 passengers?': 'More than 9 passengers?',
		'Cabin Class': 'Cabin Class',

		'Ages 12 and above': 'Ages 12 and above',
		'Ages 13 to 26': 'Ages 13 to 26',
		'Ages 2 to 11': 'Ages 2 to 11',
		'Ages below 2 for the entire journey': 'Ages below 2 for the entire journey',
		// -------- UK variant --------
		'Ages 16 and above': 'Ages 16 and above',
		'Ages 12 to 15': 'Ages 12 to 15',
		
		// -------- Travellers & rooms --------
		'Travellers and rooms': 'Travellers and rooms',
		'{n} Traveller': '{n} Travellers | {n} Traveller | {n} Travellers',
		'{n} Room': '{n} Rooms | {n} Room | {n} Rooms',
		'Room {n}: number of adults': 'Room {n}: number of adults',
		'Room {n}: number of children': 'Room {n}: number of children',
		'Room {n}: number of infants': 'Room {n}: number of infants',
		'Room {n}: child {m} age': 'Room {n}: child {m} age',
		'Each infant should be accompanied by one adult': 'Each infant should be accompanied by one adult',
		'Ages 18 and above': 'Ages 18 and above',
		'Ages 2 to 17': 'Ages 2 to 17',
		'Child {m} age': 'Child {m} age',
		'Room {n}': 'Room {n}',
		'Add room': 'Add room',
		
		// -------- Movie Reels and Search --------
		'Jump to': 'Jump to',

		// -------- Promo Code --------
		'Promo code not found': 'Promo code not found',
		'Conditions not met for promo code to be applied: trip type': 'Conditions not met for promo code to be applied: trip type',
		'Conditions not met for promo code to be applied: origin flight': 'Conditions not met for promo code to be applied: origin flight',
		'Conditions not met for promo code to be applied: destination flight': 'Conditions not met for promo code to be applied: destination flight',
		'Conditions not met for promo code to be applied: selected dates': 'Conditions not met for promo code to be applied: selected dates',
		'Conditions not met for promo code to be applied: cabin class': 'Conditions not met for promo code to be applied: cabin class',
		'Conditions not met for promo code to be applied: passenger count not met': 'Conditions not met for promo code to be applied: passenger count not met',
		'Conditions not met for promo code to be applied: office id': 'Conditions not met for promo code to be applied: office id',
		'Conditions not met for promo code to be applied': 'Conditions not met for promo code to be applied',

		// -------- Travel regulations and advisory --------
		'Travel regulations and advisory': 'Travel regulations and advisory',

		// -------- Destination window --------
		'Book before': 'Book before',
		'Recommended For You': 'Recommended For You',
		
		// -------- MHH Tripspiration card --------
		'All': 'All',
		'Destination': 'Destination',
		'Guides': 'Guides',
		'Where do you want to travel': 'Where do you want to travel',
		
		// -------- MHH Airport Transfer --------
		'Pick-up time': 'Pick-up time',
		'Select date': 'Select date',

		// -------- MHH Sticky CTA --------
		'Book a holiday now!': 'Book a holiday now!',
		
		// -------- AMAL Flight Search --------
		'Umrah Plus': 'Umrah Plus',
	},
};
