<script>
export default {
	name: 'TransitionHeight',
};
</script>

<script setup>
import gsap from 'gsap';

const props = defineProps({
	height: { type: [Number, String], default: null },
	transitionDuration: { type: Number, default: 0.3 },
});
const emit = defineEmits([
	'transition-start',
	'transition-end',
]);


const rootEl = ref(null);

watch(() => props.height, (newValue, oldValue) => {
	gsap.to(rootEl.value, {
		duration: props.transitionDuration,
		height: newValue,
		onStart: () => {
			emit('transition-start');
		},
		onComplete: () => {
			emit('transition-end');
		},
	});
});

onMounted(() => {
	gsap.set(rootEl.value, {
		height: props.height,
	});
});

</script>

<template>
<div
	ref="rootEl"
	class="TransitionHeight overflow-hidden"
>
	<slot></slot>
</div>
</template>

