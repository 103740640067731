<script>
export default {
	name: 'FireflyHomepageLayout',
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';
import { gsap } from 'gsap';
import { useEventBus } from '@vueuse/core';

import Carousel from '~/aem-components/Carousel.vue';



const rootEl = ref(null);

const { isEditorMode } = useGlobalAEMState();

</script>


<template>
<div ref="rootEl" class="FireflyHomepageLayout flex flex-col min-h-screen" data-layout="FireflyHomepage">
	<ExperienceFragment
		class="global-mobile-download-app-notification"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/mobile-download-app-notification/master"
	/>
	<div class="sticky-spacer h-$mobileDlAppNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header-notification"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/header-notification/master"
	/>
	<div class="sticky-spacer h-$headerNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/header/master"
	/>
	
	<div id="content"></div>
	<div id="booking-subpage-container"></div>
	
	<main>
		<slot></slot>
	</main>
	
	<div class="floating-bottom-right-container">
		<BtnBackToTop />
	</div>
	
	<ExperienceFragment
		class="global-cookies-notification"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/cookies-notification/master"
	/>
	<ExperienceFragment
		class="global-footer"
		xfPath="/content/experience-fragments/firefly/$country/$lang/site/footer/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

</style>
