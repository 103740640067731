<script>
export default {
	name: 'FireflyFlightSearch',
	inheritAttrs: false,
};
</script>

<script setup>
import BookFlight from '~/components/firefly/BookingWidget/FlightSearch/BookFlight.vue';
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import ContinueYourSearch from '~/components/firefly/BookingWidget/FlightSearch/ContinueYourSearch.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { onClickOutside, useEventBus } from '@vueuse/core';
import { gsap } from 'gsap';
import { getAncestorBySelector } from '~/logic/helpers/dom.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { pushAA_flightSearchStart, pushAA_flightSearchEnd, pushAA_click } from '~/logic/adobe-analytic.js';
import { useHeaderScroll } from '~/logic/composables/useHeaderScroll.js';
import { sleep } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';

const props = defineProps({
	bookFlight: { type: Object, default: null },
	bookFlightLabel: { type: String, default: '' },
	flightSearchApiUrl: { type: Object, default: null },
	mobileLabelsTab: { type: Object, default: null },
	currencySection: { type: Object, default: null },

	// Props to hide on other page than homepage.
	isHideContinueYourSearchSection: { type: Boolean, default: false },
	
	componentIdAA: { type: String, default: '' },
});

const emit = defineEmits([
	// 'update:active-sub-tab',
]);

const rootEl = ref(null);
const bookFlightEl = ref(null);
const isSticked = ref(false);

const { isEditorMode } = useGlobalAEMState();
const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['flight-search', 'flight-search-tab'],
	rootEl,
});


onMounted(() => {
	const switchTabContent = getAncestorBySelector(rootEl.value, '.switch-content');
	onClickOutside(switchTabContent?.[0], (event) => {
		if (isSticked.value) stickyCollapse();
	});
});

const handleRootFocusWithin = () => {
	if (isSticked.value) stickyExpand();
};

const handleRootBlurWithin = () => {
	//
};

const bookingWidgetBus = useEventBus('booking-widget');

bookingWidgetBus.on((event, payload) => {
	switch (event) {
		case 'sticky:sticked': {
			isSticked.value = true;
			handleOnStick(payload);
			break;
		}
		case 'sticky:unsticked': {
			isSticked.value = false;
			handleOnUnstick(payload);
			break;
		}
		default: {
			// console.log(`Unknown event: "${event}". Ignoring it.`);
			break;
		}
	}
});

const handleOnStick = async (payload) => {
	payload.resizeObserver.unobserve(payload.triggerEl);
	
	stickyCollapse({ skipAnimation: true });
	
	const { paused: headerScrollPaused } = useHeaderScroll();
	
	headerScrollPaused.value = true;
	
	// debugger;
	await sleep(200);
	payload.triggerEl.classList.remove('pre-stick');
	
	gsap.fromTo(payload.triggerEl, {
		y: '-100%',
	}, {
		y: 0,
		duration: 0.3,
		overwrite: true,
		onComplete: () => {
			headerScrollPaused.value = false;
		},
	});
};
const handleOnUnstick = async (payload) => {
	stickyExpand({ skipAnimation: true });

	await nextTick();
	await sleep(200);
	
	payload.resizeObserver.observe(payload.triggerEl);
};
const stickyExpand = ({ skipAnimation = false } = {}) => {
	const expandableWrapperEL = rootEl.value.querySelector('.BookFlight .TransitionHeight');
	const continueYourSearchEl = rootEl.value.querySelector('.continue-your-search-container');
	
	gsap.to(expandableWrapperEL, {
		duration: skipAnimation ? 0 : 0.2,
		height: 'auto',
		onComplete: () => {
			gsap.set(expandableWrapperEL, {
				overflow: 'visible',
			});
		},
		overwrite: true,
	});
	
	if (continueYourSearchEl) {
		gsap.to(continueYourSearchEl, {
			duration: skipAnimation ? 0 : 0.2,
			height: 'auto',
			overwrite: true,
		});
	}
};
const stickyCollapse = async ({ skipAnimation = false } = {}) => {
	const expandableWrapperEL = rootEl.value.querySelector('.TransitionHeight');
	const continueYourSearchEl = rootEl.value.querySelector('.continue-your-search-container');
	
	gsap.set(expandableWrapperEL, {
		overflow: 'hidden',
	});
	gsap.to(expandableWrapperEL, {
		duration: skipAnimation ? 0 : 0.2,
		height: 0,
		overwrite: true,
	});
	if (continueYourSearchEl) {
		gsap.to(continueYourSearchEl, {
			duration: skipAnimation ? 0 : 0.2,
			height: 0,
			overwrite: true,
		});
	}
};


const continueYourSearchComponent = ref(null);

const handleSaveSearchData = (data) => {
	continueYourSearchComponent.value.saveSearch(data);
};

const restorePastSearch = async (searchData) => {
	if (isMobileViewport.value) {
		// activate the tab...
		isMobileSubpageVisible.value = true;
		await nextTick();
	}
	bookFlightEl.value.restorePastSearch(searchData);
};

const handleFillUpForm = async (payload) => {
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
		await nextTick();
	}

	await nextTick();
	bookFlightEl.value.updateLocationFromTo(payload);
};


const globalBookFlightBus = useEventBus('booking-widget:book-flight');

globalBookFlightBus.on(async (event, payload) => {
	switch (event) {
		case 'active-tab-and-fill-up-form':
			handleFillUpForm(payload);
			await nextTick();
			break;
		default:
			console.log(`Unknown event: ${event}. Ignore it.`);
			break;
	}
});


let AAHasSentStart = false;
let AAHasSentEnd = false;

const sendFormCompleteAA = (tabName) => {
	if (AAHasSentEnd) return;
	AAHasSentEnd = true;
	pushAA_flightSearchEnd(tabName);
};

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
});

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}

});

const flightSearchApiUrlEndpoint = computed(() => {
	return !isMobileViewport.value ? props.flightSearchApiUrl?.endpointDesktop : props.flightSearchApiUrl?.endpointMobile;
});

onMounted(() => {
	const aaFormStartHandler = (event) => {
		if (AAHasSentStart) return;
		if (event.target.tagName === 'FORM') return;
		
		AAHasSentStart = true;
		pushAA_flightSearchStart('flight-search-tab');
		rootEl.value.removeEventListener('focus', aaFormStartHandler, { capture: true });
	};

	rootEl.value.addEventListener('focus', aaFormStartHandler, { capture: true });
});

</script>



<template>
<div
	ref="rootEl"
	v-focus-within
	class="FireflyFlightSearch  booking-widget-subcomponent"
	tabindex="-1"
	:class="{
		'>md:hidden': props.bookFlight?.general.hideInDesktop,
		'hidden >md:block': props.bookFlight?.general.hideInMobile,
		'isEditorMode': isEditorMode,
	}"
	@focus-within="handleRootFocusWithin"
	@blur-within="handleRootBlurWithin"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Firefly Flight Search</span>
		</h5>

		<BookFlight
			ref="bookFlightEl"
			:hideTodayDateIndicator="!props.bookFlight?.datePicker?.todayIndicator"
			:subHeaderTabDescription="props.bookFlight?.general?.subHeaderTabDescription"
			:subHeaderTabTooltipBody="props.bookFlight?.general?.subHeaderTabTooltipBody"
			:subHeaderTabTooltipLabel="props.bookFlight?.general?.subHeaderTabTooltipLabel"
			:note="props.bookFlight?.general?.note"
			:searchFlightCTAText="props.bookFlight?.general?.searchFlightCtaText"

			:ondSelection="props.bookFlight?.ondSelection"
			:datePicker="props.bookFlight?.datePicker"
			:promoCodeLabel="props.bookFlight?.promoCode?.promoCodeLabel"
			:promoCodeErrorMessage="props.bookFlight?.promoCode?.promoCodeErrorMessage"

			:passengerSelection="props.bookFlight?.passengerSelection"
			:subHeaderTooltipCTATextMobileLabel="props.mobileLabelsTab?.subHeaderTooltipCTATextMobileLabel"
			:fromQuestionMobile="props.mobileLabelsTab?.fromQuestionMobile"
			:toQuestionMobile="props.mobileLabelsTab?.toQuestionMobile"
			:flightSearchApiUrl="flightSearchApiUrlEndpoint"

			:currencyLabel="props.bookFlight?.currencySection?.currencyLabel"
			:defaultCurrency="props.bookFlight?.currencySection?.defaultCurrency"

			:componentIdAA="props.componentIdAA"
			@save-search-data="handleSaveSearchData($event)"
			@form-submission="sendFormCompleteAA('book-flight')"
		/>

		<ContinueYourSearch
			v-if="!props.isHideContinueYourSearchSection"
			ref="continueYourSearchComponent"
			class="continue-your-search-container"
			:continueYourSearchLabel="props.bookFlight?.continueYourSearch?.continueYourSearchLabel"
			@restore-past-search="restorePastSearch"
		></ContinueYourSearch>
	</div>
</div>

<!-- Mobile subpage -->
<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--primary-firefly-orange-base);"
	@update:is-visible="isMobileSubpageVisible = $event"
>
	
	<template #banner-title>
		<h1 class="text-center">{{ props.mobileLabelsTab?.bannerTextMobile }}</h1>
	</template>
	
	<template #default>
		<div class="generic-container mb-20">
			<BookFlight
				ref="bookFlightEl"
				:hideTodayDateIndicator="!props.bookFlight?.datePicker?.todayIndicator"
				:subHeaderTabDescription="props.bookFlight?.general?.subHeaderTabDescription"
				:subHeaderTabTooltipBody="props.bookFlight?.general?.subHeaderTabTooltipBody"
				:subHeaderTabTooltipLabel="props.bookFlight?.general?.subHeaderTabTooltipLabel"
				:note="props.bookFlight?.general?.note"
				:searchFlightCTAText="props.bookFlight?.general?.searchFlightCtaText"

				:ondSelection="props.bookFlight?.ondSelection"
				:datePicker="props.bookFlight?.datePicker"
				:promoCodeLabel="props.bookFlight?.promoCode?.promoCodeLabel"
				:promoCodeErrorMessage="props.bookFlight?.promoCode?.promoCodeErrorMessage"

				:passengerSelection="props.bookFlight?.passengerSelection"
				:subHeaderTooltipCTATextMobileLabel="props.mobileAddOns?.subHeaderTooltipCTATextMobileLabel"
				:fromQuestionMobile="props.mobileLabelsTab?.fromQuestionMobile"
				:toQuestionMobile="props.mobileLabelsTab?.toQuestionMobile"
				:flightSearchApiUrl="flightSearchApiUrlEndpoint"

				:currencyLabel="props.bookFlight?.currencySection?.currencyLabel"
				:defaultCurrency="props.bookFlight?.currencySection?.defaultCurrency"

				:componentIdAA="props.componentIdAA"
				@save-search-data="handleSaveSearchData($event)"
				@form-submission="sendFormCompleteAA('book-flight')"
			/>
		</div>
	</template>
	
</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FireflyFlightSearch {
	
}

</style>
