import {
	object,
	string,
	number,
	array,
	boolean,
	
	BaseSchema,
} from 'yup';

/* 
	https://github.com/jquense/yup
	
	Why do we make a separate module?
	----------------------
	So that we only include a subset of the original 'yup' library in our bundle,
	yet we could still group everything yup into its own "namespace". For e.g. `yup.string()` instead of `string()`.
*/

export default {
	object,
	string,
	number,
	array,
	boolean,
	
	BaseSchema,
};
