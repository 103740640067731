import DOMPurify from 'dompurify';

// https://github.com/cure53/DOMPurify/issues/317
// the following code allows 'target' attribute on <a> tag, and automatically add `ref="noopener noreferrer"` on target _blank node

const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';

DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
	if (node.tagName === 'A') {
		if (node.hasAttribute('target')) {
			node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target'));
		}
	}
});

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
	if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
		node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE));
		node.removeAttribute(TEMPORARY_ATTRIBUTE);
		if (node.getAttribute('target') === '_blank') {
			node.setAttribute('rel', 'noopener noreferrer');
		}
	}
});


export default DOMPurify;
