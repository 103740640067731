<script>
export default {
	name: 'Breadcrumb',
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import Dropdown from '~/components/form/Dropdown.vue';
// import { useBreadcrumb } from '~/logic/composables/useBreadcrumb.js';
import { i18nGlobal } from '~/logic/i18n.js';

const props = defineProps({
	isForBonusSideTrip: { type: Boolean, default: false },
	bonusSideTripLabel: { type: String, default: 'Bonus Side Trip' },
	alwaysShow: { type: Boolean, default: false }, // this will overwrite pageProperties.hideBreadcrumbs
});
const emit = defineEmits([
	'nav-link-click',
]);

const { pageProperties } = useGlobalAEMState();


const getSiblingsList = (breadcrumbEntry) => {
	if (!breadcrumbEntry.siblings) return [];
	
	return [
		{
			label: breadcrumbEntry.pageTitle,
			pageTitle: breadcrumbEntry.pageTitle,
			pageUrl: breadcrumbEntry.pageUrl,
			value: breadcrumbEntry.pageUrl,
		},
		...breadcrumbEntry.siblings,
	].map((item) => {
		return {
			label: item.pageTitle,
			pageTitle: item.pageTitle,
			pageUrl: item.pageUrl,
			value: item.pageUrl,
			...item,
		};
	});
};

const computedNavigations = computed(() => {
	const hierarchy = pageProperties.value.hierarchy;
	
	if (props.isForBonusSideTrip) {
		return [
			{
				pageTitle: hierarchy.at(0).pageTitle,
				pageUrl: hierarchy.at(0).pageUrl,
			},
			{
				pageTitle: i18nGlobal.t(props.bonusSideTripLabel),
				pageUrl: false,
			},
		];
	}
	
	if (hierarchy.length > 5) {
		return [
			{
				pageTitle: hierarchy.at(0).pageTitle,
				pageUrl: hierarchy.at(0).pageUrl,
			},
			{
				pageTitle: '...',
				pageUrl: '...',
				siblings: getSiblingsList({ pageTitle: '...', pageUrl: '...', disabled: true, siblings: hierarchy.slice(1, -1) }),
			},
			{
				pageTitle: hierarchy.at(-1).pageTitle,
				pageUrl: false,
				...( (hierarchy.at(-1).siblings) ? { siblings: getSiblingsList(hierarchy.at(-1)) } : null ),
			},
		];
	}
	
	return hierarchy.map((item) => {
		return {
			pageTitle: item.pageTitle,
			pageUrl: (hierarchy.at(-1) === item) ? false : item.pageUrl,
			...( (hierarchy.at(-1) === item && item.siblings) ? { siblings: getSiblingsList(item) } : null ),
		};
	});
});

const siteName = window.siteName;

</script>

<template>
<div
	v-if="!pageProperties?.hideBreadcrumbs || props.alwaysShow"
	:data-use-theme="siteName"
	class="Breadcrumb flex items-center text-sm rtl:pr-10"
>
	
	<!-- Desktop -->
	<template
		v-for="item in computedNavigations"
		:key="item.pageUrl"
	>
		<template v-if="!item.siblings">
			<AppHyperlink
				v-if="item.pageUrl"
				class="breadcrumb-link line-clamp-1 md:hidden"
				:class="computedNavigations.at(-1) === item ? 'font-semibold' : ''"
				:href="item.pageUrl"
				@click="$emit('nav-link-click', item.pageUrl, $event)"
			>
				<span class="line-clamp-1 flex-grow">{{ item.pageTitle }}</span>
			</AppHyperlink>
			
			<div
				v-else
				class="breadcrumb-link line-clamp-1 md:hidden"
				:class="computedNavigations.at(-1) === item ? 'font-semibold' : ''"
			>
				<span class="line-clamp-1 flex-grow">{{ item.pageTitle }}</span>
			</div>
		</template>
		
		<Dropdown
			v-else
			class="md:hidden"
			desktopVariant="breadcrumb-dropdown"
			:options="item.siblings"
			:defaultValue="item.siblings.at(0).value"
			:ariaLabel="item.siblings.at(0).pageTitle"
		>
			<template #option="{ option }">
				<AppHyperlink
					class="hover:no-underline hover:text-inherit text-inherit py-2 px-2 w-full"
					:href="option.pageUrl"
				>
					{{ option.pageTitle }}
				</AppHyperlink>
			</template>
			<template #caret><!-- do not remove, required to 'reset' the slotted caret --></template>
		</Dropdown>
		
		<icon-far-chevron-right
			v-if="computedNavigations.at(-1) !== item"
			class="mx-2 fill-neutral-grey-base w-4 h-4 md:hidden transform rtl:-rotate-180"
		/>
		
	</template>
	
	<!-- Mobile -->
	<AppHyperlink
		class="breadcrumb-link font-semibold >md:hidden text-primary-blue-base !flex-nowrap gap-2"
		:href="computedNavigations.at(-2)?.pageUrl"
	>
		<icon-far-chevron-left
			class="fill-current-base w-4 h-4 flex-none transform rtl:-rotate-180"
		/>
		<span class="line-clamp-1 flex-grow">{{ computedNavigations.at(-2)?.pageTitle }}</span>
	</AppHyperlink>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.Breadcrumb {
	
	:deep(.router-link-active) {
		font-weight: 600;
	}
}
.breadcrumb-link {
	--a-tag-color: var(--neutral-grey-ultradark);
	--a-tag-color-hover: var(--primary-blue-base);
	max-width: 260px;
	
	&:hover {
		text-decoration: none;
	}
}

.Breadcrumb[data-use-theme="firefly"] {
	.breadcrumb-link {
		--a-tag-color-hover: var(--primary-firefly-orange-base);
	}
	.text-primary-blue-base {
		color: var(--primary-firefly-orange-base);
	}
}

</style>
