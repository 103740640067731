<script>
export default {
	name: 'HtmlComponent',
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';

const props = defineProps({
	componentLabel: { type: String, default: `` },
	htmlAndCSS: { type: String, default: `` },
	javascript: { type: String, default: `` },
	externalScript: { type: [Array, String], default: `` },
	
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
});
const emit = defineEmits([
	
]);


const rootEl = ref(null);

onMounted(() => {
	if (!rootEl.value) {
		// rootEl does not have any value, unable to proceed. Fails silently.
		return;
	}
	
	const externalScriptArray =
		Array.isArray(props.externalScript) ?
			props.externalScript :
			props.externalScript.split(/[\r?\n,]/).filter(Boolean).map((s) => s.trim());
	
	externalScriptArray.forEach((link) => {
		const scriptEl = document.createElement('script');
		scriptEl.setAttribute('src', link.trim());
		rootEl.value.appendChild(scriptEl);
	});
	
	const scriptEl = document.createElement('script');
	scriptEl.innerHTML = props.javascript;
	rootEl.value.appendChild(scriptEl);
});


const { isEditorMode } = useGlobalAEMState();

</script>


<!-- Question: Should we sanitize (i.e. using v-html-sanitize instead of v-html) ??? -->
<!-- eslint-disable vue/no-v-html -->
<template>
<div v-if="isEditorMode" class="generic-container">
	<h4 v-if="props.componentLabel" class="p-2 rounded bg-secondary-teal-dark text-white">
		HTML Component: {{ props.componentLabel }}
	</h4>
	
	<div v-if="isEditorMode && props.isHideInDesktop" class="py-5 text-center">
		<p>Hidden in: <span v-if="props.isHideInDesktop">Desktop</span> <span v-if="props.isHideInMobile">, Mobile</span></p>
	</div>
</div>

<div
	v-if="(isMobileViewport && !props.isHideInMobile) || (!isMobileViewport && !props.isHideInDesktop)"
	ref="rootEl"
	class="HtmlComponent"
	v-bind="$attrs"
>
	<div class="inner-content" v-html="htmlAndCSS"></div>
</div>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.HtmlComponent {
	
}

</style>
