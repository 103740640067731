<script>
export default {
	name: 'OverlayBonusSideTrip',
	inheritAttrs: false,
};
</script>

<script setup>
import useDisableScroll from '~composables/useDisableScroll.js';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import HeaderBST from '~/components/BookingWidget/BonusSideTrip/HeaderBST.vue';
import FooterBST from '~/components/BookingWidget/BonusSideTrip/FooterBST.vue';
import EditSearchBST from '~/components/BookingWidget/BonusSideTrip/EditSearchBST.vue';
import OptionBST from '~/components/BookingWidget/BonusSideTrip/OptionBST.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import Breadcrumb from '~/components/Breadcrumb.vue';
import TextField from '~/components/form/TextField.vue';
import dayjs from 'dayjs';
import { useBSTOverlayInterimData } from '~composables/booking-widget/useBSTOverlayInterimData.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { getONDValueByAirportCode, getMalaysiaAirportForBST } from '~/logic/composables/booking-widget/useONDList.js';
import { isUrlMatchCurrentHostname } from '~/logic/helpers/string.js';
import Tooltip from '~/components/Tooltip.vue';
import { sortBy as _sortBy } from 'lodash';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { getCookie } from '~/logic/helpers/cookies.js';
import { useIsUkVariant } from '~/logic/composables/booking-widget/usePassengers.js';

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	bstNoteTop: { type: String, default: '' },
	bstNoteCTAText: { type: String, default: '' },
	bstNoteCTAURL: { type: String, default: '' },
	bstEditSearchCTAText: { type: String, default: '' },
	optionLabel: { type: String, default: '' },
	visitMalaysiaLabel: { type: String, default: '' },
	yourDateOfChoiceLabel: { type: String, default: '' },
	question1Text: { type: String, default: '' },
	question2Text: { type: String, default: '' },
	continueWithoutBSTCTAText: { type: String, default: '' },
	continueCTAText: { type: String, default: '' },
	toLabel: { type: String, default: '' },
	departLabel: { type: String, default: '' },
	bstQuestion1MobileLabel: { type: String, default: '' },
	bstQuestion2MobileLabel: { type: String, default: '' },
	bstDisabledContinueCtaLabel: { type: String, default: '' },

	ondSelection: { type: Object, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	passengerSelectionQuestion: { type: String, default: '' },

	flightSearchApiUrl: { type: String, default: '' },

	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },

	componentIdAA: { type: String, default: '' },

	extraONDFilterForFlightSearch: { type: Object, default: null },
});

const emit = defineEmits([
	'update:isVisible',
]);

const rootEl = ref(null);
const option1El = ref(null);
const option2El = ref(null);

const internalVisible = ref(props.isVisible);

useDisableScroll(internalVisible, {
	useOverflowHiddenMethod: true,
});

watch(() => props.isVisible, (newValue) => {
	internalVisible.value = newValue;
});

watch(internalVisible, (newValue) => {
	emit('update:isVisible', newValue);
});

const hideOverlay = () => {
	internalVisible.value = false;
};

const { activate: activeFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(rootEl);

const handleRootElOnMounted = async () => {
	rootEl.value?.focus();
	await nextTick(); // oddly enough, we have to use nextTick in order for focus trap to work 🤔
	activeFocusTrap();
};
const handleRootElBeforeUnmount = () => {
	deactivateFocusTrap();
};


const editSearchBSTEl = ref(null);
const formWrapper = ref(null);

const { pageProperties, isEditorMode } = useGlobalAEMState();
const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};


const handleSubmitValid = async (values, actions) => {
	console.log('✅ handleSubmitValid values = ', holdingData.value, values);
	const storedData = holdingData.value;

	/*
		The cabin class value from the passenger and cabin class dropdown is 1, 2, 3.
		Economy: 1,
		Business: 2,
		Business Suite: 3.
		While form submission, due to different form has different cabin class value, even though their label(Economy, Business, Business Suite) are the same. 
		require to map to the actual cabin class value, which come from the component config.
	*/
	const cabinClassIndex = parseInt(storedData.cabinClassValue.value) - 1;
	const cabinClassValue = storedData.payByEnrichPoints ? props.cabinClassMilesList[cabinClassIndex].value : props.cabinClassCashList[cabinClassIndex].value;
	let dataPayload = null;

	const sideTripAirportOpt1 = option1El.value?.airportValue ?? null;
	const sideTripAirportOpt2 = option2El.value?.airportValue ?? null;
	const sideTripDateOpt1 = option1El.value?.departureDate ?? null;
	const sideTripDateOpt2 = option2El.value?.departureDate ?? null;
	
	const isUKVariant = useIsUkVariant({ origin: storedData.ondValueFrom, destination: storedData.ondValueTo });

	if (values === 'bst') {
		switch (focusedOption.value) {
			case 'option-1':
				dataPayload = {
					'originCountry': storedData.ondValueFrom?._original?.countryName,
					'departDate1': dayjs(storedData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
					'returnDate1': dayjs(storedData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
					'originAirportCode1': storedData.ondValueFrom.code,
					'destAirportCode1': sideTripAirportOpt1?.code,
					
					'departDate2': dayjs(sideTripDateOpt1).format('YYYYMMDDHHmm'),
					'returnDate2': dayjs(sideTripDateOpt1).format('YYYYMMDDHHmm'),
					'originAirportCode2': sideTripAirportOpt1?.code,
					'destAirportCode2': storedData.ondValueTo.code,

					...(!isOneWay.value ? {
						'departDate3': dayjs(storedData.dateRangeValueTo).format('YYYYMMDDHHmm'),
						'returnDate3': dayjs(storedData.dateRangeValueTo).format('YYYYMMDDHHmm'),
						'originAirportCode3': storedData.ondValueTo.code,
						'destAirportCode3': storedData.ondValueFrom.code,
					} : null),

					'flightClass': cabinClassValue,
					'adultCount': storedData.adultsCount.toString(),
					...(isUKVariant ? { 'teenCount': storedData.teensCount.toString() } : null),
					'childCount': storedData.childrenCount.toString(),
					'infantCount': storedData.infantsCount.toString(),
					'paymentType': storedData.payByEnrichPoints ? 'miles' : 'cash',
					'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
					'promoCode': storedData.promoCodeValue ?? '',
					'amcvId': window._satellite?.getVar('ECID') ?? '',
					'teaserCategory': getCookie('TeaserCategory') ?? '',
				};
				break;

			case 'option-2':
				dataPayload = {
					'originCountry': storedData.ondValueFrom?._original?.countryName,
					'departDate1': dayjs(storedData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
					'returnDate1': dayjs(storedData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
					'originAirportCode1': storedData.ondValueFrom.code,
					'destAirportCode1': storedData.ondValueTo.code,
					
					'departDate2': dayjs(storedData.dateRangeValueTo).format('YYYYMMDDHHmm'),
					'returnDate2': dayjs(storedData.dateRangeValueTo).format('YYYYMMDDHHmm'),
					'originAirportCode2': storedData.ondValueTo?.code,
					'destAirportCode2': sideTripAirportOpt2?.code,

					'departDate3': dayjs(sideTripDateOpt2).format('YYYYMMDDHHmm'),
					'returnDate3': dayjs(sideTripDateOpt2).format('YYYYMMDDHHmm'),
					'originAirportCode3': sideTripAirportOpt2?.code,
					'destAirportCode3': storedData.ondValueFrom.code,
				
					'flightClass': cabinClassValue,
					'adultCount': storedData.adultsCount.toString(),
					...(isUKVariant ? { 'teenCount': storedData.teensCount.toString() } : null),
					'childCount': storedData.childrenCount.toString(),
					'infantCount': storedData.infantsCount.toString(),
					'paymentType': storedData.payByEnrichPoints ? 'miles' : 'cash',
					'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
					'promoCode': storedData.promoCodeValue ?? '',
					'amcvId': window._satellite?.getVar('ECID') ?? '',
					'teaserCategory': getCookie('TeaserCategory') ?? '',
				};
				break;
		
			default:
				console.log(`Unknown value: ${focusedOption.value}. Ignore it.`);
				break;
		}

		dataPayload['bst'] = 'TRUE';
		
	} else {
		dataPayload = {
			'departDate1': dayjs(storedData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
			'returnDate1': !storedData.isOneWay && storedData.dateRangeValueTo ? dayjs(storedData.dateRangeValueTo).format('YYYYMMDDHHmm') : '',
			'originAirportCode1': storedData.ondValueFrom.code,
			'originCountry': storedData.ondValueFrom._original.countryName,
			'destAirportCode1': storedData.ondValueTo.code,
			'flightClass': cabinClassValue,
			'adultCount': storedData.adultsCount.toString(),
			...(isUKVariant ? { 'teenCount': storedData.teensCount.toString() } : null),
			'childCount': storedData.childrenCount.toString(),
			'infantCount': storedData.infantsCount.toString(),
			'paymentType': storedData.payByEnrichPoints ? 'miles' : 'cash',
			'amal1': storedData.ondValueTo._original.isAmalFlag,
			'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
			'promoCode': storedData.promoCodeValue ?? '',
			'amcvId': window._satellite?.getVar('ECID') ?? '',
			'teaserCategory': getCookie('TeaserCategory') ?? '',
		};
	}

	try {
		// 1st api to get response with url and payload for 2nd api
		const apiResponse = await axios({
			method: 'post',
			url: props.flightSearchApiUrl,
			data: dataPayload,
		});
		
		const { url, payload } = apiResponse.data;
		
		// 'RAW_DATA' doesn't required, delete it. 
		delete payload['RAW_DATA'];

		// 2nd api need to post with form submission
		// add extra property to payload object
		const generatedData = {};
		const entriesPayload = Object.entries(payload);
		for (let [index, [key, value]] of entriesPayload.entries()) {
			generatedData[index] = key;
		}
		const massageData = { ...generatedData, ...payload };
		const entriesMassageData = Object.entries(massageData);

		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', url);
		
		for (let [index, [key, value]] of entriesMassageData.entries()) {
			// console.log(`apiResponse => ${index}: ${key}: ${value}`);
			const _key = parseInt(key);
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}
		document.body.appendChild(form);
		form.submit();

	} catch (e) {
		console.error('handleSubmitValid, ', 'Unable to fetch api', e);
	}
	
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const handleBreadcrumbNavClick = (href, event) => {
	// Assume BST overlay only appears in homepage, home link is the only clickable link,
	// we can be sure that '@nav-link-click' only fires for "Home" then.
	// Edit: Actually, we have to hide the BST overlay on any breadcrumb link click, not just home.
	hideOverlay();
};

const focusedOption = ref(null); // option-1 | option-2 | null
const {
	holdingData,
	commitDataFromBSTOverlay,
	registerNotifier,
	unregisterNotifier,
} = useBSTOverlayInterimData();


const domesticAirportForBST = ref(null);

const getDomesticAirportForBST = async () => {
	if (!ondValueFrom?.value?.code && !ondValueTo?.value?.code) return;
	domesticAirportForBST.value = await getMalaysiaAirportForBST({
		from: ondValueFrom.value.code,
		to: ondValueTo.value.code,
	}) ?? null;
	
	// Sort it by A-Z
	domesticAirportForBST.value.fromTo = _sortBy(domesticAirportForBST.value?.fromTo, 'label');
	domesticAirportForBST.value.toFrom = _sortBy(domesticAirportForBST.value?.toFrom, 'label');
};

const ondValueFrom = ref(null);
const ondValueTo = ref(null);
const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);
const isOneWay = ref(false);

/* This function is called when Subtab "Search Flight" CTA Button is clicked and form is submitted */
registerNotifier('bst-overlay', (newData) => {
	ondValueFrom.value = newData.ondValueFrom;
	ondValueTo.value = newData.ondValueTo;
	dateRangeValueFrom.value = newData.dateRangeValueFrom;
	dateRangeValueTo.value = newData.dateRangeValueTo;
	isOneWay.value = newData.isOneWay;

	getDomesticAirportForBST();
}, { immediate: true });

/* This function is called when "Save" CTA Button is clicked on <EditSearchBST> */
const handleEditSearchCommitData = (newData) => {
	// update local data
	ondValueFrom.value = newData.ondValueFrom;
	ondValueTo.value = newData.ondValueTo;
	dateRangeValueFrom.value = newData.dateRangeValueFrom;
	dateRangeValueTo.value = newData.dateRangeValueTo;
	isOneWay.value = newData.isOneWay;
	
	option1El.value?.resetForm();
	option2El.value?.resetForm();
	
	if (isOneWay.value) {
		focusedOption.value = 'option-1';
	}
	
	// date store
	commitDataFromBSTOverlay(newData);

	// get new domestic airport list with new ondValueFrom and new ondValueTo
	getDomesticAirportForBST();
};

const bstToMalaysiaValues = computed(() => {
	if (!formWrapper.value) return null;
	const formValues = formWrapper.value.values;
	
	let result = null;
	if (formValues.selectedOption === 'option-1') {
		result = {
			location: formValues['bst-option-1-locationInMalaysia'],
			departDate: formValues['bst-option-1-departDate'],
		};
	} else {
		result = {
			location: formValues['bst-option-2-locationInMalaysia'],
			departDate: formValues['bst-option-2-departDate'],
		};
	}
	
	return result;
});

const isBtnSubmitDisabled = computed(() => {
	if (!formWrapper.value) return true;
	const formMeta = formWrapper.value.meta;
	
	if (editSearchBSTEl.value?.isEditMode) return true;
	
	return (
		!formMeta.valid ||
		!(
			bstToMalaysiaValues.value !== null &&
			bstToMalaysiaValues.value.location &&
			bstToMalaysiaValues.value.departDate
		)
	);
});




onUnmounted(() => {
	unregisterNotifier('bst-overlay');
});

</script>

<template>
<Teleport to="body">
	<Transition
		name="overlay-scale-fade-in"
		appear
	>
		<div
			v-if="isVisible"
			ref="rootEl"
			tabindex="-1"
			class="OverlayBonusSideTrip generic-backdrop"
			v-bind="$attrs"
			@keydown.esc.capture="hideOverlay"
			@vue:mounted="handleRootElOnMounted"
			@vue:beforeUnmount="handleRootElBeforeUnmount"
			@click.self="handleBackdropClick"
		>
			<FormWrapper
				ref="formWrapper"
				v-slot="{ values, errors, meta }"
				class="inner-container"
				tabindex="-1"
				@submit-valid="handleSubmitValid('bst')"
				@submit-invalid="handleSubmitInvalid"
			>
				<div
					class="overlay-content overflow-y-auto styled-scrollbar h-full h-[100svh] flex flex-col"
					v-bind="props.overlayContentAttrs"
				>
					<header class="bst-header">
						<HeaderBST class="overlay-header"></HeaderBST>
						<div class="bg-neutral-grey-ultralight py-4">
							<div class="generic-container">
								<Breadcrumb :isForBonusSideTrip="true" @nav-link-click="handleBreadcrumbNavClick" />
							</div>
						</div>
					</header>
					
					<div class="bst-body generic-container flex-grow">
						<!-- <p>values: <code>{{ values }}</code></p> -->
						<!-- <div class="" style="height: 700px; flex-basis: 700px;"></div> -->
						
						<div class="max-w-[996px] mx-auto lg:w-full py-10">
							<div v-if="props.bstNoteTop" class="bst-banner rounded-xl bg-primary-blue-extralight flex-grow px-5 py-4 flex relative md:flex-col">
								<div class="flex gap-3">
									<div class="basis-6 flex-none top-1 relative">
										<icon-fas-circle-info class="fill-primary-blue-base" aria-hidden="true" />
									</div>
									<div v-html-sanitize="props.bstNoteTop"></div>
								</div>
								
								<div v-if="props.bstNoteCTAText" class="absolute bottom-4 right-6 md:static md:mt-2 md:pl-8 rtl:right-auto rtl:left-6 rtl:md:pl-0 rtl:md:pr-8">
									<AppHyperlink
										v-aa="[
											{
												clickName: props.bstNoteCTAText,
												clickComponentType: 'URL',
												componentName: 'OverlayBonusSideTrip',
												componentID: props.componentIdAA,
												BST: true,
											},
											{
												name: props.bstNoteCTAText,
												type: isUrlMatchCurrentHostname(props.bstNoteCTAURL) ? 'other' : 'exit',
											},
										]"
										:href="props.bstNoteCTAURL"
										target="_blank"
										:showExternalLinkIcon="false"
									>
										{{ props.bstNoteCTAText }}
									</AppHyperlink>
								</div>
							</div>
							
							<div class="bst-edit-search mt-7 mb-7">
								<EditSearchBST
									ref="editSearchBSTEl"
									:bstEditSearchCTAText="props.bstEditSearchCTAText"
									:ondSelection="props.ondSelection"
									:datePicker="props.datePicker"
									:passengerSelection="props.passengerSelection"
									:fromQuestionMobile="props.fromQuestionMobile"
									:toQuestionMobile="props.toQuestionMobile"
									:datePickerQuestionMobile="props.datePickerQuestionMobile"
									:passengerSelectionQuestion="props.passengerSelectionQuestion"
									:componentIdAA="props.componentIdAA"
									:extraONDFilterForFlightSearch="props.extraONDFilterForFlightSearch"
									@save-search-data="handleEditSearchCommitData"
								/>
							</div>
							
							<div class="flex flex-col gap-6 mb-10">
								<OptionBST
									ref="option1El"
									v-aa="[
										{
											clickName: `${props.optionLabel} 1`,
											clickComponentType: 'Button',
											componentName: 'OverlayBonusSideTrip',
											componentID: props.componentIdAA,
											BST: true,
										},
										{
											name: `${props.optionLabel} 1`,
											type: 'other',
										},
									]"
									class=""
									formNamePrefix="bst-option-1"
									:locationA="ondValueFrom"
									:locationB="ondValueTo"
									:domesticAirportList="domesticAirportForBST?.fromTo"
									:dateToMalaysia="dateRangeValueFrom"
									:dateLeaveMalaysia="dateRangeValueTo"
									:isFocused="focusedOption === 'option-1'"
									:isOneWay="isOneWay"
									:visitMalaysiaLabel="props.visitMalaysiaLabel"
									:yourDateOfChoiceLabel="props.yourDateOfChoiceLabel"
									:question1Text="props.question1Text"
									:question2Text="props.question2Text"
									:toLabel="props.toLabel"
									:departLabel="props.departLabel"
									@focus-within="focusedOption = 'option-1'"
								>
									<template #title>
										{{ props.optionLabel }} 1
									</template>
									<template #mobile-question-1-title>
										{{ props.bstQuestion1MobileLabel }}
									</template>
									<template #mobile-question-2-title>
										{{ props.bstQuestion2MobileLabel }}
									</template>
								</OptionBST>
								<OptionBST
									v-if="!isOneWay"
									ref="option2El"
									v-aa="[
										{
											clickName: `${props.optionLabel} 2`,
											clickComponentType: 'Button',
											componentName: 'OverlayBonusSideTrip',
											componentID: props.componentIdAA,
											BST: true,
										},
										{
											name: `${props.optionLabel} 2`,
											type: 'other',
										},
									]"
									formNamePrefix="bst-option-2"
									:locationA="ondValueTo"
									:locationB="ondValueFrom"
									:domesticAirportList="domesticAirportForBST?.toFrom"
									:dateToMalaysia="dateRangeValueTo"
									:isFocused="focusedOption === 'option-2'"
									:isOneWay="isOneWay"
									:visitMalaysiaLabel="props.visitMalaysiaLabel"
									:yourDateOfChoiceLabel="props.yourDateOfChoiceLabel"
									:question1Text="props.question1Text"
									:question2Text="props.question2Text"
									:toLabel="props.toLabel"
									:departLabel="props.departLabel"
									@focus-within="focusedOption = 'option-2'"
								>
									<template #title>
										{{ props.optionLabel }} 2
									</template>
									<template #mobile-question-1-title>
										{{ props.bstQuestion1MobileLabel }}
									</template>
									<template #mobile-question-2-title>
										{{ props.bstQuestion2MobileLabel }}
									</template>
								</OptionBST>
							</div>
							
							<!-- <div class="" style="height: 700px; flex-basis: 700px;"></div> -->
						</div>
					</div>
					
					<div class="bst-sticky-bar py-6 md:py-3">
						<div class="generic-container flex justify-end md:justify-between">
							<button
								v-if="props.continueWithoutBSTCTAText"
								v-aa="[
									{
										clickName: props.continueWithoutBSTCTAText,
										clickComponentType: 'Button',
										componentName: 'OverlayBonusSideTrip',
										componentID: props.componentIdAA,
										BST: false,
									},
									{
										name: props.continueWithoutBSTCTAText,
										type: 'exit',
									},
								]"
								v-button-press
								type="button"
								class="btn-generic-b px-4 mr-4 font-semibold md:text-left md:text-sm"
								@click="handleSubmitValid"
							>{{ props.continueWithoutBSTCTAText }}</button>
							
							<Tooltip :renderInvisibleTrigger="false" triangleAlignment="center" :isDisabled="isMobileViewport">
								<template #default>
									<CTAButton
										v-if="props.continueCTAText"
										v-aa="[
											{
												clickName: props.continueCTAText,
												clickComponentType: 'Button',
												componentName: 'OverlayBonusSideTrip',
												componentID: props.componentIdAA,
												BST: true,
											},
											{
												name: props.continueCTAText,
												type: 'exit',
											},
										]"
										:isSubmit="true"
										:disabled="isBtnSubmitDisabled"
									>
										{{ props.continueCTAText }}
									</CTAButton>
								</template>
								<template #mobile-title>
									<slot></slot>
								</template>
								<template v-if="editSearchBSTEl?.isEditMode && props.bstDisabledContinueCtaLabel" #tooltip-content>
									<div v-html-sanitize="props.bstDisabledContinueCtaLabel"></div>
								</template>
							</Tooltip>
							
						</div>
					</div>
						
					<FooterBST class="bst-footer md:hidden"></FooterBST>
				</div>
				
				<TextField
					variant="input-hidden"
					name="selectedOption"
					:modelValue="focusedOption"
				/>
			</FormWrapper>
		</div>
	</Transition>
</Teleport>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.OverlayBonusSideTrip {
	z-index: 9000; // must be more than header
	background-color: white;
}

.inner-container {
	width: 100%;
	height: 100vh;
	max-height: inherit;
}

.bst-sticky-bar {
	background-color: white;
	border-top: 2px solid var(--neutral-grey-light);
	position: sticky;
	bottom: 0;
	z-index: 50;
}

// animations
.overlay-bottom-enter-from {
	opacity: 0;
}

.overlay-bottom-enter-active,
.overlay-bottom-leave-active {
	transition: opacity 200ms;
}

.overlay-bottom-leave-to {
	opacity: 0;
}

.overlay-bottom-enter-from,
.overlay-bottom-leave-to {
	.overlay-container {
		transform: translateY(75%);
	}
}

.overlay-bottom-enter-active,
.overlay-bottom-leave-active {
	.overlay-container {
		transition: transform 200ms;
	}
}

.overlay-bottom-enter-to,
.overlay-bottom-leave-from {
	.overlay-container {
		transform: translateY(0%);
	}
}

</style>
