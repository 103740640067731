<script>
export default {
	name: 'MHCalendarSingle',
};
</script>

<script setup>
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { calendarDMY } from '~/logic/composables/calendarDMY.js';
import { useCalendarRestrictions } from '~/logic/composables/useCalendarRestrictions.js';
// import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const props = defineProps({
	modelValue: { type: Object, default: null }, // expects dayjs instance
	
	canSwitchView: { type: Boolean, default: true },
	canNavigateMonth: { type: Boolean, default: true },
	// enableFocusTrap: { type: Boolean, default: true },
	
	/* 
	displayYear prop:
		['from', 'to']
		['t-120', 't+1'] <-- relative. t = this year. This is the default.
		['1990' ,'2023'] <-- absolute
	*/
	displayYear: { type: Array, default: () => ['t-120', 't+1'] },
	
	
	// restrictions
	/* 
	minDate / maxDate prop:
		· Can support absolute date or relative date
		
		Absolute dates
		· Format is 'YYYY-MM-DD'
		E.g. '2022-11-26'
		
		Relative dates
		· t = today
		· Only support + / -
		· No whitespace allowed.
		· Must end with d / m / y
		E.g.
			't-10d' <-- today minus 10 days
			't-2m' <-- today minus 2 months
			't+1y' <-- today plus 1 year
	*/
	minDate: { type: String, default: null },
	maxDate: { type: String, default: null },
	
	// disabledDates iterates over the Array for every date. That is slow, avoid if possible.
	disabledDates: { type: Array, default: null },
	
	isHideTodayIndicator: { type: Boolean, default: false },
});
const emit = defineEmits([
	'select-date',
]);

const siteName = window.siteName;

const rootEl = ref(null);
const btnSwitchMonthYearEl = ref(null);


const viewType = ref('day');

const setViewType = async (newViewType) => {
	rootEl.value.focus();
	viewType.value = newViewType;
	await nextTick();
	switch (viewType.value) {
		case 'day': {
			btnSwitchMonthYearEl.value?.focus();
			break;
		}
		case 'month': {
			btnSwitchMonthYearEl.value?.focus();
			break;
		}
		case 'year': {
			const pointedYearEl = rootEl.value?.querySelector(`[data-date="${ pointedDate.value.format('YYYY-MM-DD') }"]`);
			if (pointedYearEl) {
				pointedYearEl.focus();
			} else {
				const currentYearEl = rootEl.value?.querySelector(`[data-date="${ dayjs(pointedDate.value).date(1).format('YYYY-MM-DD') }"]`);
				currentYearEl?.focus();
			}
			break;
		}
		default: { /* do nothing */ break; }
	}
};

const {
	computedMinDate,
	computedMaxDate,
	computedDisabledDates,
	checkIsDisabledFunc,
} = useCalendarRestrictions(ref({
	minDate: props.minDate,
	maxDate: props.maxDate,
	disabledDates: props.disabledDates,
}));


const getInitialViewAndPointedDate = () => {
	let results = {
		viewDate: null,
		pointedDate: null,
	};
	const modelDate = dayjs(props.modelValue).isValid() ? dayjs(props.modelValue) : null;
	const today = dayjs();
	const defaultDate = modelDate ?? today;
	
	if (computedMinDate.value && computedMaxDate.value) {
		// both min and max date
		
		// check is between
		if (
			defaultDate.isSameOrAfter(computedMinDate.value, 'month') &&
			defaultDate.isSameOrBefore(computedMaxDate.value, 'month')
		) {
			results.viewDate = defaultDate;
			results.pointedDate = defaultDate;
		} else {
			// ops
			results.viewDate = computedMinDate.value;
			results.pointedDate = computedMinDate.value;
		}
		
	} else if (computedMinDate.value) {
		// only min date
		if (defaultDate.isSameOrAfter(computedMinDate.value, 'month')) {
			results.viewDate = defaultDate;
			results.pointedDate = defaultDate;
		} else {
			// ops
			results.viewDate = computedMinDate.value;
			results.pointedDate = computedMinDate.value;
		}
	} else if (computedMaxDate.value) {
		// only max date
		if (defaultDate.isSameOrBefore(computedMaxDate.value, 'month')) {
			results.viewDate = defaultDate;
			results.pointedDate = defaultDate;
		} else {
			// ops
			results.viewDate = computedMaxDate.value;
			results.pointedDate = computedMaxDate.value;
		}
	} else {
		// neither min nor max
		results.viewDate = defaultDate;
		results.pointedDate = defaultDate;
	}
	
	return results;
};


const _initials = getInitialViewAndPointedDate();

const currentViewDate = ref(_initials.viewDate);
const pointedDate = ref(_initials.pointedDate);


const {
	currentViewMonthName,
	currentViewYearName,
	
	dayList,
	monthList,
	yearList,
} = calendarDMY(currentViewDate, {
	computedMinDate,
	computedMaxDate,
	computedDisabledDates,
	checkIsDisabledFunc,
	displayYear: ref(props.displayYear),
});


const goToPrevMonth = () => {
	if (isNavigationDisabledPrev.value) {
		showToast(`${currentViewDate.value.subtract(1, 'month').format('MMM YYYY')} is disabled`);
		return;
	}
	// need to replace the instance for reactivity to work
	currentViewDate.value = currentViewDate.value.subtract(1, 'month');
	pointedDate.value = dayjs(currentViewDate.value).date(1);
};
const goToNextMonth = () => {
	if (isNavigationDisabledNext.value) {
		showToast(`${currentViewDate.value.add(1, 'month').format('MMM YYYY')} is disabled`);
		return;
	}
	// need to replace the instance for reactivity to work
	currentViewDate.value = currentViewDate.value.add(1, 'month');
	pointedDate.value = dayjs(currentViewDate.value).date(1);
};


const getPointedDateOrAlternative = (dayjsInstance, direction) => {
	/* let list;
	if (viewType.value === 'year') {
		list = yearList;
	} else if (viewType.value === 'month') {
		list = monthList;
	} else {
		// day
		list = dayList;
	} */
	
	// check to make sure we are not limited by min / max date
	if (
		dayjsInstance.isBefore(computedMinDate.value, viewType.value) ||
		dayjsInstance.isAfter(computedMaxDate.value, viewType.value)
	) {
		// no point checking further because there will be no enabled dates
		return false;
	}
	
	if (computedDisabledDates.value.length > 0) {
		// has disabled dates, loop through each item
		// if EVERY item is not same as date, then PASS
		if (computedDisabledDates.value.every((d) => !d.isSame(dayjsInstance, 'day'))) return true;
	} else {
		// no disabled date and NO min / max restrictions, shall PASS
		return true;
	}
	
	// at this point, we have a disabled date that matches the date, so we need to find alternative
	
	let attemptCount = 0;
	const MAX_ATTEMPTS = 60;
	
	while (attemptCount < MAX_ATTEMPTS) {
		attemptCount++;
		let testDate;
		if (direction === 'left' || direction === 'up') {
			// going backwards
			testDate = dayjsInstance.subtract(attemptCount, viewType.value);
		} else {
			// going forward
			testDate = dayjsInstance.add(attemptCount, viewType.value);
		}
		if (testDate.isBefore(computedMinDate.value) || testDate.isAfter(computedMaxDate.value)) {
			// no hope of finding an enabled date
			return false;
		}
		if (computedDisabledDates.value.every((d) => !d.isSame(testDate, 'day'))) {
			// ok
			return testDate; // <-- exit the while() loop prematurely
		}
	}
};


const handleArrowNavigation = async (direction) => {
	let newPointedDate = null;
	
	switch (direction) {
		case 'left': {
			if (viewType.value === 'year') {
				newPointedDate = pointedDate.value.subtract(1, 'year').month(currentViewDate.value.month()).date(1);
			} else if (viewType.value === 'month') {
				newPointedDate = pointedDate.value.subtract(1, 'month').date(1);
			} else {
				// day
				newPointedDate = pointedDate.value.subtract(1, 'day');
			}
			break;
		}
		case 'right': {
			if (viewType.value === 'year') {
				newPointedDate = pointedDate.value.add(1, 'year').month(currentViewDate.value.month()).date(1);
			} else if (viewType.value === 'month') {
				newPointedDate = pointedDate.value.add(1, 'month').date(1);
			} else {
				// day
				newPointedDate = pointedDate.value.add(1, 'day');
			}
			break;
		}
		case 'up': {
			if (viewType.value === 'year') {
				newPointedDate = pointedDate.value.subtract(4, 'year').month(currentViewDate.value.month()).date(1);
			} else if (viewType.value === 'month') {
				newPointedDate = pointedDate.value.subtract(4, 'month').date(1);
			} else {
				// day
				newPointedDate = pointedDate.value.subtract(7, 'day');
			}
			break;
		}
		case 'down': {
			if (viewType.value === 'year') {
				newPointedDate = pointedDate.value.add(4, 'year').month(currentViewDate.value.month()).date(1);
			} else if (viewType.value === 'month') {
				newPointedDate = pointedDate.value.add(4, 'month').date(1);
			} else {
				// day
				newPointedDate = pointedDate.value.add(7, 'day');
			}
			break;
		}
		default: { /* do nothing */ break; }
	}
	
	// refocus to 
	const result = getPointedDateOrAlternative(newPointedDate, direction);
	if (result === true) {
		// new pointed date is ok
		pointedDate.value = newPointedDate;
		currentViewDate.value = dayjs(newPointedDate);
		await nextTick();
		rootEl.value?.querySelector(`[data-date="${ newPointedDate.format('YYYY-MM-DD') }"]`)?.focus();
	} else if (result instanceof dayjs) {
		// a new date is given, use that date instead
		pointedDate.value = result;
		currentViewDate.value = dayjs(result);
		await nextTick();
		rootEl.value?.querySelector(`[data-date="${ result.format('YYYY-MM-DD') }"]`)?.focus();
	} else if (result === false) {
		// new pointed date and alternatives cannot be found, do nothing
		showToast(`${newPointedDate.format(
			(viewType.value === 'day') ?
				'DD MMM YYYY'
				: (viewType.value === 'month') ?
					'MMM YYYY' : 'YYYY'
		)} is disabled`);
		return;
	}
};


watch(() => props.modelValue, () => {
	if (props.modelValue) {
		pointedDate.value = dayjs(props.modelValue);
		return;
	}
});



const getFirstValid = (selectType, year, month) => {
	let results = {
		viewDate: null,
		pointedDate: null,
	};
	
	// Refactor this code if possible, right now it is kinda a brute force method
	if (selectType === 'day') {
		// year and month should be provided
		let attemptDate = 1;
		while (attemptDate < 31) {
			// test index 1 to 31
			let date = dayjs().year(year).month(month).date(attemptDate);
			let result = checkIsDisabledFunc.value(date, 'day');
			if (!result) {
				// found it
				results.viewDate = date;
				results.pointedDate = date;
				break;
			}
			attemptDate++;
		}
	} else if (selectType === 'month') {
		// year should be provided
		let attemptMonthIndex = 0;
		while (attemptMonthIndex < 12) {
			// test index 0 to 11
			let date = dayjs().year(year).month(attemptMonthIndex).date(1);
			let result = checkIsDisabledFunc.value(date, 'month');
			if (!result) {
				// found it
				results.viewDate = date;
				results.pointedDate = date;
				break;
			}
			attemptMonthIndex++;
		}
	}
	
	return results;
};


const handleMonthClick = async (monthObj) => {
	rootEl.value.focus();
	setViewType('day');
	
	const dates = getFirstValid('day', pointedDate.value.year(), monthObj.monthNumber);
	pointedDate.value = dates.pointedDate;
	currentViewDate.value = dates.viewDate;
};
const handleYearClick = async (yearObj) => {
	rootEl.value.focus();
	setViewType('month');
	
	const dates = getFirstValid('month', yearObj.yearNumber);
	pointedDate.value = dates.pointedDate;
	currentViewDate.value = dates.viewDate;
};
const handleRootKeyDelete = (event) => {
	switch (viewType.value) {
		case 'day': {
			// delete the modelValue
			emit('select-date', null);
			break;
		}
		case 'month': {
			// delete the modelValue
			emit('select-date', null);
			
			// -- previous logic, not used for now --
			// setViewType('day');
			
			// const dates = getFirstValid('day', pointedDate.value.year(), pointedDate.value.month());
			// pointedDate.value = dates.pointedDate;
			// // currentViewDate.value = dates.viewDate;
			break;
		}
		case 'year': {
			// delete the modelValue
			emit('select-date', null);
			
			// -- previous logic, not used for now --
			// setViewType('month');
			
			// const dates = getFirstValid('month', pointedDate.value.year());
			// pointedDate.value = dates.pointedDate;
			// // currentViewDate.value = dates.viewDate;
			break;
		}
		default: { /* do nothing */ break; }
	}
};



const isNavigationDisabledPrev = computed(() => {
	return checkIsDisabledFunc.value(currentViewDate.value.subtract(1, 'month'), 'month');
});
const isNavigationDisabledNext = computed(() => {
	return checkIsDisabledFunc.value(currentViewDate.value.add(1, 'month'), 'month');
});


const toastMessage = ref('');

const showToast = (() => {
	let timeoutId = -1;
	return (msg, options) => {
		clearTimeout(timeoutId);
		toastMessage.value = msg;
		timeoutId = setTimeout(() => {
			toastMessage.value = '';
		}, 1500);
	};
})();



defineExpose({
	handleArrowNavigation,
});

</script>

<template>
<div
	ref="rootEl"
	class="MHCalendar flex flex-col py-4"
	:class="{
		'hide-today-indicator': props.isHideTodayIndicator,
	}"
	:data-use-theme="siteName"
	tabindex="-1"
	:aria-label="`Use arrow keys to navigate and select ${viewType === 'default' ? 'day' : viewType}`"
	@keydown.backspace.delete.prevent="handleRootKeyDelete"
	@keydown.down.prevent="handleArrowNavigation('down')"
	@keydown.right.prevent="handleArrowNavigation('right')"
	@keydown.up.prevent="handleArrowNavigation('up')"
	@keydown.left.prevent="handleArrowNavigation('left')"
>
	<!-- <p class="px-4">currentViewDate: {{ currentViewDate.format('YYYY-MM-DD') }}</p> -->
	<!-- <p class="px-4">pointedDate: {{ pointedDate.format('YYYY-MM-DD') }}</p> -->
	<!-- <p class="px-4">computedMinDate: {{ computedMinDate }}</p> -->
	<!-- <p class="px-4">computedMaxDate: {{ computedMaxDate }}</p> -->
	
	<header class="calendar-header flex px-4">
		<div class="switch-view-wrapper relative">
			<component
				:is="props.canSwitchView ? 'button' : 'div'"
				ref="btnSwitchMonthYearEl"
				v-button-press
				:type="props.canSwitchView ? 'button' : null"
				class="flex items-center text-primary-blue-base font-semibold !rounded-lg"
				:class="{
					'btn-generic-b': props.canSwitchView,
					'opacity-0 pointer-events-none': viewType === 'year',
				}"
				:aria-label="$t(viewType === 'day' ? 'Change month or year' : 'Change year')"
				:aria-hidden="viewType === 'year' ? 'true' : null"
				:tabindex="props.canSwitchView ? (viewType === 'year' ? -1 : 0) : null"
				@click="props.canSwitchView ? setViewType(viewType === 'day' ? 'month' : 'year') : null"
			>
				<span v-if="viewType === 'day'">{{ currentViewMonthName }} {{ currentViewYearName }}</span>
				<span v-else-if="viewType === 'month'">{{ currentViewYearName }}</span>
				<span v-else-if="viewType === 'year'">{{ $t('Year') }}</span>
				<icon-fas-caret-down v-if="props.canSwitchView" class="fill-current ml-2 rtl:(ml-0 mr-2) text-xs" aria-hidden="true" />
			</component>
			<span v-if="viewType === 'year'" class="absolute top-0 left-0 py-1 px-2 font-semibold">{{ $t('Year') }}</span>
		</div>
		
		<div v-show="viewType === 'day' && props.canNavigateMonth" class="flex ml-auto rtl:(mr-auto ml-0)">
			<button
				v-button-press
				type="button"
				class="btn-arrow arrow-left btn-generic-b transform rtl:-rotate-180"
				:class="{
					'is-disabled': isNavigationDisabledPrev,
				}"
				:aria-label="`Previous month${isNavigationDisabledPrev ? ` (${$t('disabled')})` : ''}`"
				@click="goToPrevMonth"
			>
				<icon-far-chevron-left class="btn-arrow-icon fill-current" aria-hidden="true" />
			</button>
			<button
				v-button-press
				type="button"
				class="btn-arrow arrow-right btn-generic-b transform rtl:-rotate-180"
				:class="{
					'is-disabled': isNavigationDisabledNext,
				}"
				:aria-label="`Next month${isNavigationDisabledNext ? ` (${$t('disabled')})` : ''}`"
				@click="goToNextMonth"
			>
				<icon-far-chevron-right class="btn-arrow-icon fill-current" aria-hidden="true" />
			</button>
		</div>
	</header>
	
	<div class="dmy-container flex mt-2 pl-4 pr-4">
		<div class="dmy-wrapper flex-grow overflow-y-auto styled-scrollbar flex items-center px-1 py-1">
			<div
				v-if="viewType === 'day'"
				class="w-full grid grid-cols-7 gap-y-1 self-start"
			>
				<div
					v-for="(dayName, index) in dayjs.weekdaysShort()"
					:key="dayName"
					class="day-of-the-week "
					:class="`day-${index}`"
				>{{ dayName }}</div>
				
				<template
					v-for="(day, index) in dayList"
					:key="index"
				>
					<template v-if="day.isVoid">
						<div
							class="day-void"
							aria-hidden="true"
						></div>
					</template>
					<template v-else>
						<div class="flex justify-center">
							<button
								type="button"
								class="day-single btn-generic-b p-1"
								:class="{
									'is-today': day.isToday,
									'is-selected': day.dayjsInstance.isSame(props.modelValue, 'day'),
									'is-pointed': day.dayjsInstance.isSame(pointedDate, 'day'),
								}"
								:tabindex="day.dayjsInstance.isSame(pointedDate, 'day') ? 0 : -1"
								:data-date="day.dataDate"
								:aria-label="day.dayjsInstance.format('YYYY-MM-DD')"
								:disabled="day.isDisabled"
								@click="$emit('select-date', day.dayjsInstance)"
							>{{ day.dateNumber }}</button>
						</div>
					</template>
				</template>
				
			</div>
			
			<div
				v-if="viewType === 'month'"
				class="w-full grid grid-cols-4 gap-x-1 gap-y-2 content-center py-2 my-auto"
			>
				<div
					v-for="(month, monthIndex) in monthList"
					:key="month"
					class="flex"
				>
					<button
						type="button"
						class="month-single btn-generic-b w-full"
						:class="{
							'is-today': month.isToday,
							'is-selected': month.dayjsInstance.isSame(props.modelValue, 'month'),
							'is-pointed': month.dayjsInstance.isSame(pointedDate, 'month'),
						}"
						:tabindex="month.dayjsInstance.isSame(pointedDate, 'month') ? 0 : -1"
						:data-date="month.dataDate"
						:aria-label="month.dayjsInstance.format('YYYY MMMM')"
						:disabled="month.isDisabled"
						@click="handleMonthClick(month)"
					>{{ month.month }}</button>
				</div>
			</div>

			<div
				v-if="viewType === 'year'"
				class="w-full grid grid-cols-4 gap-x-1 gap-y-1 content-center py-2 my-auto"
			>
				<div
					v-for="(year) in yearList"
					:key="year"
					class="flex"
				>
					<button
						type="button"
						class="year-single btn-generic-b w-full"
						:class="{
							'is-today': year.isToday,
							'is-selected': year.dayjsInstance.isSame(props.modelValue, 'year'),
							'is-pointed': year.dayjsInstance.isSame(pointedDate, 'year'),
						}"
						:tabindex="year.dayjsInstance.isSame(pointedDate, 'year') ? 0 : -1"
						:data-date="year.dataDate"
						:aria-label="year.dayjsInstance.format('YYYY')"
						:disabled="year.isDisabled"
						@click="handleYearClick(year)"
					>{{ year.year }}</button>
				</div>
			</div>
		</div>
	</div>
	
	<transition name="slide-in-from-bottom" appear>
		<aside v-if="toastMessage" class="toast-container">
			<span class="toast-message" aria-live="alert">{{ toastMessage }}</span>
		</aside>
	</transition>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

@mixin todayDot () {
	content: '';
	display: block;
	margin-top: 2px;
	width: 4px;
	height: 4px;
	border-radius: 100%;
	background-color: var(--primary-blue-base);
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 6px;
}

.MHCalendar {
	position: relative;
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;
	
	&:focus-visible {
		outline-width: var(--focus-visible-outline-width, 1px);
		outline-offset: var(--focus-visible-outline-offset, 0px);
		outline-color: var(--semantic-blue-base);
	}
	
	&.hide-today-indicator {
		.is-today::after {
			display: none !important;
		}
	}
}

.dmy-container {
	max-height: 300px;
}

.btn-arrow {
	z-index: 10;
	border-radius: 100%;
	color: var(--primary-blue-base);
	font-size: 12px;
	padding: 0;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	&.is-disabled {
		color: var(--neutral-grey-dark);
		
		&.is-selected {
			background-color: var(--primary-blue-extralight);
		}
	}
}
.dmy-wrapper {
	overscroll-behavior: none;
}

.day-of-the-week {
	text-transform: uppercase;
	font-weight: 600;
	color: var(--neutral-grey-extradark);
	display: flex;
	justify-content: center;
	@apply p-1 text-xs;
}

.day-single {
	border-radius: 100%;
	flex: 0 1 40px;
	color: var(--text-color);
	position: relative;

	&::before {
		content: '';
		padding-top: 100%;
	}
	
	&.is-today {
		&::after {
			@include todayDot;
		}
	}
	&.is-selected {
		background-color: var(--primary-blue-base);
		color: white;
		
		&.is-today {
			&::after {
				background-color: white;
			}
		}
	}
	&[disabled] {
		color: var(--neutral-grey-dark);
		
		&.is-selected {
			background-color: var(--primary-blue-extralight);
		}
	}
}

.month-single {
	color: var(--text-color);
	border-radius: 48px;
	position: relative;
	@apply py-3;
	
	&.is-today {
		&::after {
			@include todayDot;
			bottom: 8px;
		}
	}
	&.is-selected {
		background-color: var(--primary-blue-base);
		color: white;
		
		&.is-today {
			&::after {
				background-color: white;
			}
		}
	}
	&[disabled] {
		color: var(--neutral-grey-dark);
		
		&.is-selected {
			background-color: var(--primary-blue-extralight);
		}
	}
}

.year-single {
	color: var(--text-color);
	border-radius: 48px;
	position: relative;
	@apply py-3;
	
	&.is-today {
		&::after {
			@include todayDot;
		}
	}
	&.is-selected {
		background-color: var(--primary-blue-base);
		color: white;
		
		&.is-today {
			&::after {
				background-color: white;
			}
		}
	}
	&[disabled] {
		color: var(--neutral-grey-dark);
		
		&.is-selected {
			background-color: var(--primary-blue-extralight);
		}
	}
}

.toast-container {
	background-color: var(--primary-black-base);
	color: white;
	display: inline-flex;
	width: max-content;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 100;
	@apply rounded-5xl py-1 px-5;
}


.slide-in-from-bottom-enter-from,
.slide-in-from-bottom-leave-to {
	transform: translateY(50%);
	opacity: 0;
}

.slide-in-from-bottom-enter-active,
.slide-in-from-bottom-leave-active {
	transition: transform 0.2s, opacity 0.2s;
}

.slide-in-from-bottom-enter-to,
.slide-in-from-bottom-leave-from {
	transform: translateY(0%);
	opacity: 1;
}

.MHCalendar[data-use-theme="MHH"] {
	.btn-arrow {
		color: var(--primary-mhh-teal-base);
	}

	.day-single {
		color: var(--text-color);

		&.is-selected {
			color: white;
			background-color: var(--primary-mhh-teal-base);
		}

		&.is-today::after {
			background-color: var(--primary-mhh-teal-base);
		}
	}

}

.MHCalendar[data-use-theme="firefly"] {

	.btn-arrow {
		color: var(--secondary-firefly-orange-base);

		&:focus {
			outline: 2px solid var(--semantic-blue-base);
		}
	}

	.text-primary-blue-base {
		color: var(--primary-firefly-orange-base);
	}

	.day-single {
		
		&.is-disabled {
			color: var(--neutral-firefly-grey-medium);
		}
		
		&.is-between-selection {
			background-color: #FCF5E5;
		}

		&.is-selected {
			color: white;
			background-color: var(--secondary-firefly-orange-extradark);
		}

		&.is-today::after {
			background-color: var(--primary-firefly-orange-base);
		}
	}

	.footer-inner .text-blue-primary-base {
		color: var(--primary-firefly-orange-base);
	}
}

</style>
