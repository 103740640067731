

const _transformStringToCQPrefix = (s) => {
	if (!s.startsWith(':')) return s;
	const tempKey = s.substr(1);
	return `cq${ tempKey.substr(0, 1).toUpperCase() }${tempKey.substr(1)}`;
};

const transformObjKeyToCq = (obj) => {
	return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
		const transformedKey = _transformStringToCQPrefix(key);
		return [transformedKey, value];
	}));
};


export {
	transformObjKeyToCq,
};
