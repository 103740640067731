import { createGlobalState } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { ensureHtmlExtension } from '~/logic/helpers/string.js';
import { transformObjKeyToCq } from '~/logic/helpers/aem.js';


export const useGlobalAEMState = createGlobalState(
	() => {
		const rootPageModel = ref(null);
		const rootPath = computed(() => rootPageModel.value?.cqPath);
		const isEditorMode = computed(() => rootPageModel.value?.isEditorMode ?? false);
		
		const pageModel = ref(null);
		
		const pageProperties = computed(() => {
			if (!pageModel.value) return null;
			const result = pageModel.value?.cqItems?.root[':items']?.page_properties?.pageProperties;
			return result;
		});
		
		
		return {
			rootPageModel,
			rootPath,
			
			pageProperties,
			pageModel,
			
			isEditorMode,
		};
	},
);

window.useGlobalAEMState = useGlobalAEMState;
