<script>
export default {
	name: 'MAGDefaultLayout',
	expose: [],
	inheritAttrs: false,
};
</script>


<script setup>
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';


</script>


<template>
<div class="MAGDefaultLayout flex flex-col min-h-screen" data-layout="MAGDefault">
	<ExperienceFragment
		class="global-header-notification"
		xfPath="/content/experience-fragments/mag/$lang/site/header-notification/master"
	/>
	<div class="sticky-spacer h-$headerNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/mag/$lang/site/header/master"
	/>
	
	<div id="content"></div>
	<main>
		<slot></slot>
	</main>
	
	<div class="floating-bottom-right-container">
		<BtnBackToTop />
	</div>
	
	<ExperienceFragment
		class="global-cookies-notification"
		xfPath="/content/experience-fragments/mag/$lang/site/cookies-notification/master"
	/>
	<ExperienceFragment
		class="global-footer"
		xfPath="/content/experience-fragments/mag/$lang/site/footer/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

</style>
