<script>
export default {
	name: 'RecaptchaMT',
};

</script>

<script setup>
import { getRandomInt } from '~/logic/helpers/utils.js';

const props = defineProps({
	siteKey: { type: String, required: true },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const isReady = ref(false);
const id = getRandomInt(0, 9999);


onMounted(() => {
	loadLib();
	window.mtcaptchaConfig.renderQueue.push(`mtcaptcha-${id}`);
});

function mtcaptchaLoaded () {
	console.log('mtcaptcha is Loaded');
	isReady.value = true;
}

const getRecaptchaToken = () => {
	if (isReady.value) {
		return document.querySelector('[name="mtcaptcha-verifiedtoken"]')?.value ?? null;
	}
};

function loadLib () {
	if (!window.mtcaptchaConfig) {
		window.mtcaptchaConfig = {
			'sitekey': 'MTPublic-Boqth9xEn', // Get the site key from Sites page of MTCaptcha admin site 
			'widgetSize': 'mini',
			'theme': 'overcast',
			'render': 'explicit',
			'renderQueue': [],
			'jsloaded-callback': mtcaptchaLoaded,
		};

		const mt_service = document.createElement('script');
		mt_service.async = true;
		mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';
		(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service);
		const mt_service2 = document.createElement('script');
		mt_service2.async = true;
		mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';
		(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);

	}
}

defineExpose({
	isReady,
	getRecaptchaToken,
});

</script>

<template>
<div class="RecaptchaMT">
	<div :id="`mtcaptcha-${id}`"></div>
</div>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

</style>
