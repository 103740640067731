import { getCurrentInstance } from 'vue';

export default function useKeyInputRestriction (options) {
	const {
		allowedKeys,
		disallowedKeys,
	} = options;
	
	const allowedRegExp = computed(() => {
		if (allowedKeys.value === null) return null;
		if (allowedKeys.value instanceof RegExp) return allowedKeys.value;
		
		if ((typeof allowedKeys.value === 'string')) {
			return new RegExp(`[${allowedKeys.value}]`, 'i');
		} else if (Array.isArray(allowedKeys.value)) {
			return new RegExp(`[${allowedKeys.value.join('')}]`, 'i');
		}
	});
	const disallowedRegExp = computed(() => {
		if (disallowedKeys.value === null) return null;
		if (disallowedKeys.value instanceof RegExp) return disallowedKeys.value;
		
		if ((typeof disallowedKeys.value === 'string')) {
			return new RegExp(`[${disallowedKeys.value}]`, 'i');
		} else if (Array.isArray(disallowedKeys.value)) {
			return new RegExp(`[${disallowedKeys.value.join('')}]`, 'i');
		}
	});
	
	
	const checkIsKeyValid = (key) => {
		if (allowedRegExp.value !== null) {
			let isAllowed = allowedRegExp.value.test(key);
			return isAllowed;
		}
		if (disallowedRegExp.value !== null) {
			let isDisabllowed = disallowedRegExp.value.test(key);
			return !isDisabllowed;
		}
		return true;
	};
	
	const onKeypress = (event) => {
		const isValid = checkIsKeyValid(event.key);
		if (!isValid) event.preventDefault();
	};
	const onPaste = (event) => {
		const pasted = event.clipboardData.getData('text/plain');
		const isValid = checkIsKeyValid(pasted);
		if (!isValid) event.preventDefault();
	};
	
	
	return {
		checkIsKeyValid,
		onKeypress,
		onPaste,
	};
}
