<script>
export default {
	name: 'FooterBST',
};
</script>

<script setup>
import { useHeaderFooterStore } from '~/logic/header-footer/header-footer-store.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';

const props = defineProps({
	// modelValue: { type: String, default: '' },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const { footer: footerStore } = useHeaderFooterStore();

const isPropsDataEmpty = () => {
	return !(
		footerStore.subsidiaryTab &&
		footerStore.tncTab &&
		footerStore.copyrightTab
	);
};

</script>

<template>
<footer
	v-if="!isPropsDataEmpty()"
	class="FooterBST py-10"
>
	<div class="generic-container">
		<div class="footer-bottom">
			<div class=">lg:w-5/12 flex items-end">
				<div class="bottom-left-col">
					<div class="partner-logo-wrapper">
						<AppHyperlink
							v-for="item in footerStore.subsidiaryTab?.subsidiaryList"
							:key="item.imageAltText"
							:href="item.imageUrl"
							:target="item.isOpenNewTab ? '_blank' : '_self'"
							class="partner-logo inline-block"
							:class="{ 'hidden': (isMobileViewport && item.isHideInMobile) || (!isMobileViewport && item.isHideInDesktop) }"
							:showExternalLinkIcon="false"
						>
							<img
								:src="item.image"
								class="h-[40px] w-auto inline-block"
								:alt="item.imageAltText"
							/>
						</AppHyperlink>
					</div>
					<p class="copyright-text text-neutral-grey-extradark text-xs font-bold >lg:hidden >md:w-11/12 lg:w-full mx-auto mt-8">{{ footerStore.copyrightTab?.text }}</p>
				</div>
			</div>
			<div class=">lg:w-7/12 >lg:flex flex-col items-end justify-end">
				<div class="bottom-right-col">
					<div class="copyright-wrapper">
						<div class="copyright-list">
							<ul class="mb-4 lg:flex lg:flex-col">
								<li v-for="(item, index) in footerStore.tncTab?.tncList" :key="`${item.title}-${index}`" class="copyright-list inline-block">
									<AppHyperlink
										class="copyright-link text-neutral-white-base hover:text-primary-blue-extralight"
										:href="item.titleUrl"
										:target="item.isOpenNewTab ? '_blank' : '_self'"
										rel="noopener noreferrer"
										:showExternalLinkIcon="false"
									>
										<span class="copyright-link-text">{{ item.title }}</span>
									</AppHyperlink>
								</li>
							</ul>
							<p class="copyright-text text-neutral-grey-extradark text-xs font-bold lg:hidden">{{ footerStore.copyrightTab?.text }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FooterBST {
	border-top-left-radius: 72px;
	border-top-right-radius: 72px;
	background-color: var(--secondary-blue-extradark);
	color: white;
}

.footer-bottom {
	display: flex;
	flex-direction: column-reverse;

	@media #{var.$query-min-lg} {
		flex-direction: row;
	}

	.bottom-left-col {

		.partner-logo-wrapper {
			.partner-logo {
				&:not(:last-child) {
					@apply mr-10;
				}
			}
		}
	}


	.bottom-right-col {
		flex-direction: column;
		align-items: flex-end;
		justify-content: end;

		@media #{var.$query-min-lg} {
			display: flex;
		}

		.social-icons-list {

			@media #{var.$query-max-md} {
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				grid-column-gap: 40px;
				grid-row-gap: 24px;
			}
		}

		.social-icons-wrapper {
			margin-bottom: 42px;

			@media #{var.$query-max-md} {
				@apply mb-10;
				width: 100%;
			}

			.social-icons-list-item {

				&:not(:last-child) {
					@media #{var.$query-min-md} {
						@apply mr-8;
					}
				}

				.social-icons-image {
					display: inline-block;
				}
			}
		}

		.copyright-list {
			@apply font-semibold;

			&:not(:last-child) {
				
				@media #{var.$query-min-lg} {
					@apply mr-5;
				}
			}
		}
	}
}

.copyright-text {
	text-align: right;
	@apply break-words;

	@media #{var.$query-max-md} {
		font-size: 10px;
		text-align: center;
		@apply mt-8;
	}
}

.copyright-link-text {
	font-size: 14px;
}

</style>
