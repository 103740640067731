

export const isScrollbarVisible = (element) => {
	// note: when <html> element has 'data-overflow-y-value' attr, we consider it as no scrollbar too
	// see setOverflowHiddenStyling() and clearOverflowStyling() func
	return element.scrollHeight > element.clientHeight && !(element.getAttribute('data-overflow-y-value'));
};


// new implementation
export const disableScroll = () => {
	const rootEl = document.documentElement;
	if (!isScrollbarVisible(rootEl)) return;
	
	const scrollTop = rootEl.scrollTop;
	
	window.ignoreNextScrollEvent = true;
	rootEl.classList.add('no-scroll');
	rootEl.style.top = `-${scrollTop}px`;
	rootEl.setAttribute('data-scroll-top', scrollTop);
};
export const enableScroll = () => {
	const rootEl = document.documentElement;
	if (!rootEl.classList.contains('no-scroll')) return;
	
	const scrollTop = window.getComputedStyle(rootEl).getPropertyValue('top');
	
	rootEl.classList.remove('no-scroll');
	rootEl.style.top = 'auto';
	
	rootEl.scrollTop = parseFloat(rootEl.getAttribute('data-scroll-top')) || 0;
	rootEl.removeAttribute('data-scroll-top');
};


// previous implementation, this will truly hide the scrollbar, causing page to "wiggle"
// disable scroll
const clearOverflowStyling = () => {
	const rootEl = document.documentElement;
	rootEl.style.overflowY = rootEl.getAttribute('data-overflow-y-value');
	rootEl.removeAttribute('data-overflow-y-value');
};
// enable scroll
const setOverflowHiddenStyling = () => {
	const rootEl = document.documentElement;
	if (!rootEl.getAttribute('data-overflow-y-value')) {
		rootEl.setAttribute('data-overflow-y-value', window.getComputedStyle(rootEl).getPropertyValue('overflow-y'));
	}
	rootEl.style.overflowY = 'hidden';
};


export default function useDisableScroll (visibilityRef, { useOverflowHiddenMethod = false } = {}) {
	const instance = getCurrentInstance();
	/* useOverflowHiddenMethod when true, will use the "legacy" (more straight-forward) method of disabling the scrollbar */
	
	if (instance) {
		onUnmounted(() => {
			if (useOverflowHiddenMethod) {
				clearOverflowStyling();
			} else {
				enableScroll();
			}
		});
	}


	watch(visibilityRef, (newValue) => {
		if (newValue === false) {
			if (useOverflowHiddenMethod) {
				clearOverflowStyling();
			} else {
				enableScroll();
			}
		} else {
			if (useOverflowHiddenMethod) {
				setOverflowHiddenStyling();
			} else {
				disableScroll();
			}
		}
	}, { immediate: true });

}


