<script>
export default {
	name: 'MobileBtmBar',
	inheritAttrs: false,
};
</script>

<script setup>
import IconCustom from '~/components/IconCustom.vue';
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import { waitFor } from '~/logic/helpers/utils.js';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const props = defineProps({
	mobileBottomNavigationItems: { type: Array, default: () => [] },
});

const isMobileBottomHidden = ref(false);
const siteName = window.siteName;

const updateBookingWidgetInViewport = async () => {
	
	try {
		// Grabbing the booking-widget-element classname on current site.
		let bookingWidgetSelectorClass = '.BookingWidget';
		if (siteName === 'firefly') {
			bookingWidgetSelectorClass = '.FireflyBookingWidget .inner-container';
		}

		const wait = waitFor(() => document.querySelector(bookingWidgetSelectorClass) !== null, 400, 120);
		await wait.start();
	
		const bookingWidgetEl = document.querySelector(bookingWidgetSelectorClass);
		const subPageEl = document.querySelector('#booking-subpage-container');
	
		if (bookingWidgetEl !== null
			&& (ScrollTrigger.isInViewport(bookingWidgetEl)
				|| (subPageEl !== null
					&& ScrollTrigger.isInViewport(subPageEl))) ) {
			
			isMobileBottomHidden.value = true;
		} else {
			
			isMobileBottomHidden.value = false;
		}
		
		// Checking if booking widget behind mobile sub page on page load
		if (bookingWidgetEl !== null && bookingWidgetEl.getBoundingClientRect().y === 0) {
			isMobileBottomHidden.value = true;
		}
		
	} catch (error) {
		isMobileBottomHidden.value = false;
	}
	
};

onMounted(() => {
	updateBookingWidgetInViewport();
	
	document.addEventListener('scroll', updateBookingWidgetInViewport, { passive: true });
});

onUnmounted(() => {
	document.removeEventListener('scroll', updateBookingWidgetInViewport);
});

</script>

<template>
<Teleport to="body">
	<div
		class="mobile-btm-bar-spacer pt-6"
		:class="{
			'transform-y': isMobileBottomHidden,
		}"
	></div>
	
	<div
		class="MobileBtmBar"
		:class="{
			'transform-y': isMobileBottomHidden,
		}"
		v-bind="$attrs"
	>
		<div class="wrapper flex justify-around h-full">
			<AppHyperlink
				v-for="(bottomNavigationItems, bottomNavigationIndex) in props.mobileBottomNavigationItems"
				:key="bottomNavigationIndex"
				:href="bottomNavigationItems.urlPath"
				class="flex flex-col items-center justify-center typo-body-text-3 font-semibold text-primary-black-base hover:text-primary-black-base hover:no-underline"
			>
				<FontAwesomeLazy
					v-if="bottomNavigationItems.iconFontAwesomeId !== 'travel-info'"
					:iconId="bottomNavigationItems.iconFontAwesomeId"
					iconStyle="regular"
					class="mobile-bottom-bar-icon fill-current w-full h-6"
				/>
				<IconCustom
					v-else
					iconId="travel-info"
					:size="24"
				/>
				<span class="mobile-bottom-bar-label">{{ bottomNavigationItems.label }}</span>
			</AppHyperlink>
		</div>
	</div>
</Teleport>
</template>

<style lang="scss">
html {
	--mobileBtmBarHeight: 60px;
}
html.showing-subpages {
	
	.MobileBtmBar, .mobile-btm-bar-spacer {
		display: none;
	}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MobileBtmBar {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: var(--mobileBtmBarHeight);
	background-color: white;
	border-top-width: 1px;
	border-top-color: var(--neutral-grey-base);
	z-index: 8000;
	transition: 200ms ease-in-out;
}

.mobile-btm-bar-spacer {
	height: var(--mobileBtmBarHeight);
	box-sizing: content-box;
}

.mobile-bottom-bar-icon {
	&.active {
		color: var(--primary-blue-base);
	}
	:deep(svg) {
		width: 100%;
		height: 100%;
	}
}

.mobile-bottom-bar-label {
	margin-top: 5px;
	font-size: 10px;
}

.transform-y {
	transform: translateY(var(--mobileBtmBarHeight));
}
</style>
