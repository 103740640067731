<script>
export default {
	name: 'Dropdown',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import Multiselect from '@vueform/multiselect';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import OverlayBtmSticky from '~/components/OverlayBtmSticky.vue';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import TextField from '~/components/form/TextField.vue';
import { MHScrollToElement } from '~/logic/helpers/dom.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { wrapQueryWithHTML } from '~/logic/helpers/string.js';


const props = defineProps({
	name: { type: String, default: '' },
	
	options: { type: Array, default: () => [] },
	modelValue: { type: String, default: null },
	defaultValue: { type: [String, Object], default: null },
	desktopVariant: { type: String, default: '' },
	mobileVariant: { type: String, default: '' },
	placeholderText: { type: String, default: '‎ ' },
	labelText: { type: String, default: '' },
	
	overlayAttrs: { type: Object, default: () => ({}) },
	rootAttrs: { type: Object, default: () => ({}) },
	
	validation: {
		type: [Object, Function],
		default: null,
		// only accepts Yup object, or a function
		validator: (val) => ((val === null) || (val instanceof yup.BaseSchema) || (val instanceof Function)),
	},
	
	required: { type: Boolean, default: false },
	requiredErrorMsg: { type: String, default: i18nGlobal.t('This field is required') },
	
	isSearchable: { type: Boolean, default: false },
	isExcludeFromEmail: { type: Boolean, default: false },
});
const emit = defineEmits([
	'update:modelValue',
]);

const isPickerVisible = ref(false);

const handleOnOpen = (instance) => {
	if (isMobileViewport.value) {
		isPickerVisible.value = true;
	}
};

const handleMobileChange = (newValue) => {
	isPickerVisible.value = false;
};

const hideMobileOverlay = () => {
	isPickerVisible.value = false;
};

const internalValue = useInternalValue('modelValue', {
	defaultValue: props.defaultValue,
});

watch(internalValue, () => {
	/* 
		Looks like there is an issue where "singlelabel" does not show even when modelValue of multiselect has been updated.
		The solution is we manually call its select() method to "remind" it.
	*/
	if (internalValue.value) {
		multiselectMain.value?.select(internalValue.value);
	} else {
		multiselectMain.value?.clear();
	}
	multiselectInOverlay.value?.select(internalValue.value);
});

const rootEl = ref(null);
const focusDummy = ref(null);
const multiselectMain = ref(null);
const multiselectInOverlay = ref(null);

const hiddenTextField = ref(null);

const htmlValue = computed(() => {
	if (!internalValue.value) return '';
	if (typeof internalValue.value === 'string') return internalValue.value;
	// an Object
	return internalValue.value?.value ?? internalValue.value;
});


const validation = computed(() => {
	if (props.validation) return props.validation;
	if (!props.required) return null;
	return yup.string().required(props.requiredErrorMsg).nullable();
});


const hiddenTextFieldMounted = () => {
	hiddenTextField.value.inputEl._mh_invalidFocus = () => {
		MHScrollToElement(rootEl.value, { additionalTopOffset: 60 });
		multiselectMain.value.multiselect?.focus({ preventScroll: true });
	};
};


const isGroups = computed(() => {
	const firstData = props.options?.[0];
	if (!firstData) return false;
	return !!(firstData.label && firstData.options);
});


const handleOnInputBlur = () => {
	hiddenTextField.value?.setTouched(true);
};

const handleOverlayHidden = () => {
	focusDummy.value.focus();
};

defineExpose({
	hiddenTextField,
});

const siteName = window.siteName;

</script>

<template>

<div
	ref="rootEl"
	class="Dropdown"
	:class="{
		'has-validation-error': hiddenTextField?.hasValidationError && hiddenTextField?.meta.touched,
	}"
	v-bind="props.rootAttrs"
	:data-use-theme="siteName"
>
	<Multiselect
		ref="multiselectMain"
		v-model="internalValue"
		:options="props.options"
		:canClear="false"
		:canDeselect="false"
		:groups="isGroups"
		:classes="{
			'dropdown': 'multiselect-dropdown md:hidden shadow-type-a mt-4 py-2 pr-2',
			'options': 'multiselect-options flex flex-col py-0 px-2 m-0 list-none styled-scrollbar',
		}"
		:attrs="{
			'onBlur': handleOnInputBlur,
			'name': props.isSearchable ? '' : props.name,
			...$attrs,
		}"
		:data-variant="props.desktopVariant"
		v-bind="$attrs"
		class="multiselect-main"
		:class="[
			$attrs.class,
		]"
		:placeholder="props.labelText"
		:searchable="props.isSearchable"
		@open="handleOnOpen"
	>
		<template #placeholder>
			<div class="placeholder-wrapper relative flex-grow mr-2 leading-none">
				<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
				<span class="text-placeholder text-neutral-grey-ultradark relative">{{ props.placeholderText }}</span>
			</div>
		</template>
		<template #singlelabel="{ value }">
			<div class="value-wrapper multiselect-single-label flex-grow mr-2 rtl:ml-2 leading-normal">
				<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
				<span class="text-value relative flex items-center">{{ value.label }}</span>
			</div>
		</template>
		
		<template #option="{ option, search }">
			<span
				v-html-sanitize="wrapQueryWithHTML(option.label, { query: search, className: 'search-keyword-highlight' })"
				class=""
			></span>
		</template>
		
		<template #caret>
			<!--
				This .auxiliary-label-wrapper is sort of a "hack" to show a label while search is being inputted
				Because multiselect library hides placeholder AND value when search input has any value, our label would disappear during when user is typing.
				By creating an extra <div> in #caret, AND using the CSS trick to show and hide this <div>, we solved the problem.
			-->
			<div class="auxiliary-label-wrapper multiselect-single-label -order-1 flex-grow mr-2 leading-none">
				<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
			</div>
			
			<!-- 👇 This is the actual caret -->
			<span>
				<icon-far-chevron-down class="dropdown-caret mr-7 rtl:ml-7 md:mr-3 md:rtl:ml-3 z-50 relative transition-transform duration-200 fill-primary-blue-base" />
			</span>
		</template>
		<template v-for="(_, slotName) in $slots" #[slotName]="slotData">
			<slot :name="slotName" v-bind="{ ...slotData, hideMobileOverlay }"></slot>
		</template>
	</Multiselect>
	
	<TextField
		ref="hiddenTextField"
		class=""
		:modelValue="htmlValue"
		:name="props.name"
		:ariaLabel="props.labelText"
		:validation="validation"
		variant="input-hidden"
		:isExcludeFromEmail="props.isExcludeFromEmail"
		@vue:mounted="hiddenTextFieldMounted"
	></TextField>
	
	<div ref="focusDummy" class="focusable-dummy" tabindex="-1" aria-hidden="true"><!-- --></div>
</div>


<OverlayBtmSticky
	class="Dropdown-overlay"
	:isVisible="isPickerVisible && isMobileViewport"
	style="--overlayTitleHeight: 36px;"
	v-bind="props.overlayAttrs"
	:overlayContentAttrs="{
		class: '!mb-0',
	}"
	:class="{
		'Dropdown-overlay-non-searchable': !props.isSearchable,
	}"
	@update:is-visible="isPickerVisible = $event; handleOverlayHidden();"
>
	<div
		class="pl-5 sticky top-0 bg-white z-1000 pb-3 w-full h-$overlayTitleHeight"
	>
		<h6 class="font-bold line-clamp-1">
			<slot name="mobile-title">
				{{ props.labelText }}
			</slot>
		</h6>
	</div>
	
	<div
		ref="rootEl"
		class="Dropdown"
		:data-use-theme="siteName"
	>
		<Multiselect
			ref="multiselectInOverlay"
			v-model="internalValue"
			:options="props.options"
			:placeholder="props.placeholderText"
			:canClear="false"
			:canDeselect="false"
			:classes="{
				'dropdown': 'multiselect-dropdown md:hidden mt-0 !flex',
				'options': 'multiselect-options flex flex-col py-0 m-0 list-none styled-scrollbar empty:hidden',
			}"
			:data-variant="`in-mobile-overlay ${props.mobileVariant}`"
			:groups="isGroups"
			v-bind="$attrs"
			class="multiselect-main"
			:class="[
				$attrs.class,
				{
					'non-searchable': !props.isSearchable,
				},
			]"
			:searchable="props.isSearchable"
			@select="handleMobileChange"
		>
			<template #placeholder>
				<div class="placeholder-wrapper relative flex-grow mr-2 leading-none">
					<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
					<span class="text-placeholder text-neutral-grey-ultradark relative">{{ props.placeholderText }}</span>
				</div>
			</template>
			
			<template #option="{ option, search }">
				<span
					v-html-sanitize="wrapQueryWithHTML(option.label, { query: search, className: 'search-keyword-highlight' })"
					class=""
				></span>
			</template>
			
			<template #singlelabel="{ value }">
				<div class="value-wrapper multiselect-single-label flex-grow mr-2 leading-none">
					<span class="text-label absolute font-semibold">{{ props.labelText }}</span>
					<div class="text-value relative flex items-center">
						<div class="sr-only">Value is </div>
						<span class="pr-2">{{ value.displayValue ?? value.label }}</span>
					</div>
				</div>
			</template>
			
		
			<template v-for="(_, slotName) in $slots" #[slotName]="slotData">
				<slot :name="slotName" v-bind="{ ...slotData, hideMobileOverlay }"></slot>
			</template>
		</Multiselect>
	</div>
</OverlayBtmSticky>

</template>


<style lang="scss">
.Dropdown-overlay {
	.overlay-container {
		height: 80vh;
	}
	.overlay-content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		flex-grow: 1;
		@apply ml-2;
	}
	.Dropdown {
		--DropdownHeight: calc(100% - var(--overlayTitleHeight, 36px));
		height: var(--DropdownHeight);
		width: 100%;
	}
}

.Dropdown-overlay-non-searchable {
	.overlay-container {
		height: auto;
	}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.Dropdown {
	:deep(.multiselect-main) {
		height: 54px;
		--ms-max-height: 266px;
		--ms-bg: var(--neutral-grey-ultralight);
		--ms-line-height: 1;
		
		/* --ms-ring-width: 2px; */
		/* --ms-ring-color: var(--primary-blue-base); */
		
		--ms-ring-width: 0;
		--ms-ring-color: var(--primary-blue-base);
		
		--ms-radius: 12px;
		--ms-dropdown-bg: white;
		--ms-border-width: 2px;
		--ms-border-color: var(--neutral-grey-light);
		--ms-dropdown-border-color: var(--neutral-grey-light);
		--ms-dropdown-border-width: 2px;
		--ms-dropdown-radius: 12px;
		
		--ms-option-bg-pointed: var(--neutral-grey-extralight);
		--ms-option-color-pointed: var(--primary-blue-base);
		
		--ms-option-bg-selected: var(--primary-blue-base);
		--ms-option-bg-selected-pointed: var(--primary-blue-light);
		
		transition: border-color 0.2s;
		justify-content: flex-start;
		min-height: 0;
		
		&:hover {
			--ms-border-color: var(--neutral-grey-base);
		}
		&.is-open {
			--ms-bg: white;
			border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) var(--ms-radius, 4px) var(--ms-radius, 4px);
			
			.text-label {
				font-size: 12px;
				transform: translate(0, -0.558rem);
			}
			.text-placeholder {
				opacity: 1;
			}
			> .multiselect-search-wrapper .dropdown-caret {
				transform: rotate(180deg);
			}
		}
		&:focus, &:focus-within {
			--ms-border-color: var(--primary-blue-base);
			
			.text-placeholder {
				opacity: 1;
			}
		}
		
		&.is-disabled {
			--ms-border-color: var(--neutral-grey-light);
			
			.dropdown-caret {
				fill: var(--neutral-grey-base);
			}
			
			.text-label,
			.text-label ~ .text-value {
				color: var(--neutral-grey-base);
			}
		}
		
		.text-label {
			transition: all 0.25s;
			transform-origin: 0 0;
			color: var(--neutral-grey-ultradark);
		}
		
		.placeholder-wrapper {
			pointer-events: none;
			@apply px-5 pt-3.5 pb-3;
		}
		.text-placeholder {
			opacity: 0;
			top: 0.5rem;
		}
		.value-wrapper, .auxiliary-label-wrapper {
			@apply px-5 pt-4.5 pb-2;
			margin-top: 1px;
			
			.text-label {
				font-size: 12px;
				transform: translate(0, -0.6rem);
			}
		}
		
		// hide the auxiliary-label-wrapper when either 'placeholder' or 'value' is present
		.placeholder-wrapper ~ .auxiliary-label-wrapper,
		.value-wrapper ~ .auxiliary-label-wrapper {
			display: none;
		}
		
		.text-value {
			top: 5px;
		}
	}
	:deep(a) {
		--focus-visible-outline-offset: 0px;
		border-radius: inherit;
	}
	// :deep(.multiselect-search-wrapper) {
	// 	@apply px-5 py-4;
	// }
	:deep(.multiselect-single-label) {
		@apply line-clamp-2;
		position: static;
	}
	:deep(.multiselect-dropdown) {
		overflow-y: hidden;
		border-radius: 12px;
		transform: translateY(calc(100% + 0.37rem));
		z-index: 7900;
	}
	:deep(.multiselect-dropdown.is-no-options) {
		--ms-max-height: 480px;
		padding: 0;
		
		.multiselect-options {
			max-height: 480px;
			
			@media #{var.$query-max-md} {
				max-height: none;
			}
		}
	}
	:deep(.multiselect-options) {
		overflow-y: auto;
		max-height: 266px;
		overscroll-behavior: none;
	}
	:deep(.multiselect-option) {
		border-radius: 8px;
	}
	:deep(.multiselect-caret) {
		width: 10px;
		height: 15px;
		transition-duration: 200ms;
		margin-left: auto;
		@apply mr-6;
	}
	
	:deep(.multiselect-search) {
		@apply px-5 pt-3.5 pb-0;
		
		&:focus {
			~ .placeholder-wrapper .text-label {
				font-size: 12px;
				transform: translate(0, -0.558rem);
			}
		}
		&:focus-visible {
			outline: 0;
		}
	}
	// :deep(.multiselect-main .multiselect-dropdown) {
	// 	overflow-y: visible;
	// }
	
	:deep(.multiselect-group-label) {
		background-color: transparent;
		color: var(--neutral-grey-extradark);
		text-transform: uppercase;
		font-size: 12px;
		@apply border-t-2 pt-5 mt-4;
	}
	:deep(.multiselect-group:first-child .multiselect-group-label) {
		border-width: 0;
		margin-top: 0;
		@apply pt-2;
	}
}

.Dropdown :deep(.multiselect-main[data-variant*="breadcrumb-dropdown"]) {
	--ms-radius: 4px;
	--ms-border-width: 1px;
	--ms-font-size: 14px;
	--ms-option-px: 0;
	--ms-option-py: 0;
	height: auto;
	max-width: 260px;
	margin-top: 0;
	
	.multiselect-search-wrapper {
		height: auto;
		position: relative;
		@apply py-0.5 px-2;
	}
	.value-wrapper {
		.text-value {
			top: 0;
		}
	}
	.placeholder-wrapper {
		.text-label {
			@apply line-clamp-1;
		}
	}
	.value-wrapper {
		@apply p-0;
		
		.text-value {
			@apply line-clamp-1;
		}
	}
	// .multiselect-single-label {
	// 	@apply pl-0 pr-2 py-0 line-clamp-1;
	// }
	.multiselect-single-label-text {
		font-weight: 600;
		color: var(--neutral-grey-ultradark);
	}
	.multiselect-dropdown {
		width: 384px;
	}
	.multiselect-caret {
		@apply mr-1 ml-1;
	}
}

.Dropdown :deep(.multiselect-main[data-variant*="booking-widget"]) {
	
	/* Note: this is MIN query, not MAX */
	@media #{var.$query-min-md} {
		height: 64px;
		
		.multiselect-search {
			@apply pt-4.5 pb-0;
		}
		.text-placeholder {
			top: 8px;
		}
		.value-wrapper,
		.auxiliary-label-wrapper {
			@apply pt-5.7;
		}
		
		.value-wrapper {
			.text-label {
				transform: translate(0, -0.558rem);
			}
			
		}
	}
	
	.value-wrapper {
		
		.text-value {
			color: var(--primary-blue-base);
			font-weight: 600;
		}
	}
}

.Dropdown :deep(.multiselect-main[data-variant*="in-mobile-overlay"]) {
	--ms-border-width: 0;
	--ms-dropdown-border-width: 0;
	--ms-ring-width: 0;
	--ms-bg: transparent;
	flex-direction: column;
	max-width: 100%;
	height: 100%;
	/* overflow-y: hidden; */
	@apply pt-0;

	
	&.non-searchable {
		.multiselect-search-wrapper {
			display: none;
		}
	}
	
	.multiselect-search-wrapper {
		height: 54px;
		position: relative;
		width: calc(100% - 2.5rem); // 2.5rem = px-5
		flex: 0 0 54px;
		align-self: stretch;
		@apply mb-4 mx-auto;
		
		&::before {
			content: '';
			background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="%230D4689"><path d="M504.1 471l-134-134C399.1 301.5 415.1 256.8 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c48.79 0 93.55-16.91 129-45.04l134 134C475.7 509.7 481.9 512 488 512s12.28-2.344 16.97-7.031C514.3 495.6 514.3 480.4 504.1 471zM48 208c0-88.22 71.78-160 160-160s160 71.78 160 160s-71.78 160-160 160S48 296.2 48 208z"></path></svg>') center center/100% auto;
			display: block;
			width: 18px;
			height: 18px;
			color: var(--primary-blue-base);
			position: absolute;
			left: 16px;
			z-index: 100;
		}
	}
	.multiselect-search {
		--borderColor: var(--neutral-grey-light);
		--bgColor: var(--neutral-grey-ultralight);
		--labelColor: var(--neutral-grey-ultradark);
		--placeholderColor: var(--neutral-grey-ultradark);
		--inputValueColor: var(--text-color);
		--prefixColor: var(--primary-blue-base);
		outline: 0;
		height: 100%;
		border-radius: 12px;
		background-color: var(--bgColor);
		border: 2px solid var(--borderColor);
		transition-property: border-color;
		transition-duration: 0.2s;
		color: var(--inputValueColor);
		padding-left: 42px;
		@apply pt-0 pb-0;
		
		&:hover {
			--borderColor: var(--neutral-grey-base);
		}
		&:focus {
			--borderColor: var(--primary-blue-base);
		}
	}
	.text-placeholder {
		width: 100%;
		top: 0;
		opacity: 1;
	}
	.text-label {
		display: none;
	}
	.btn-clear {
		margin-left: auto;
	}
	.placeholder-wrapper {
		left: 24px;
		top: -1px;
	}
	.value-wrapper {
		height: 54px;
		left: 24px;
		position: relative;
		@apply pt-3.75;
	}
	.multiselect-caret {
		display: none;
	}
	.multiselect-dropdown {
		width: 100%;
		flex-grow: 1;
		top: 0;
		transform: none;
		max-height: 100%;
		z-index: 0;
		border-radius: 0;
		position: relative;
		@apply mb-2;
	}
	.multiselect-options {
		height: 100%;
		max-height: 100%;
		
		&:empty {
			display: none;
		}
	}
	.multiselect-option {
		@apply mr-2;
		padding: 10px 16px;
	}
	// .multiselect-group-label {
		/* @apply px-5; */
	// }
}

.Dropdown.has-validation-error :deep(.multiselect-main) {
	--ms-border-color: var(--semantic-red-light);
	
	.dropdown-caret {
		fill: var(--ms-border-color);
	}
	.text-label {
		color: var(--semantic-red-base);
	}
	&:hover {
		--ms-border-color: #{var.$semantic-red-base-50-opacity};
	}
	&:focus, &:focus-within {
		--ms-border-color: var(--semantic-red-base);
	}
}

.Dropdown :deep(.multiselect-main[data-variant*="in-mobile-overlay"][data-variant*="non-searchable"]) {
	--ms-border-width: 0;
	--ms-dropdown-border-width: 0;
	--ms-ring-width: 0;
	--ms-bg: transparent;
	max-width: 100%;
	height: 100%;
	align-items: flex-start;
	flex-direction: column;
	@apply pt-0;
	
	.multiselect-caret {
		display: none;
	}
	.multiselect-dropdown {
		width: 100%;
		flex-grow: 1;
		top: 0;
		transform: none;
		position: relative;
		max-height: 100%;
		z-index: 0;
		border-radius: 0;
		@apply mb-2;
	}
	.multiselect-options {
		height: 100%;
		max-height: 100%;
	}
	
	.multiselect-group-label {
		@apply px-5;
	}
	.multiselect-group-options {
		@apply px-5;
	}
	
	.multiselect-search-wrapper {
			display: none;
		}
}

.Dropdown :deep(.multiselect-main[data-variant*="custom-html"]) {
	.value-wrapper {
		.text-label {
			font-size: 12px;
			transform: translate(0, -0.85rem);
			
			@media #{var.$query-min-md} {
				transform: translate(0, -1rem);
			}
		}
		.text-value {
			top: 0rem;
		}
	}
	
	&[data-variant*="in-mobile-overlay"] {
		.multiselect-dropdown {
			margin-bottom: 0;
		}
	}
}

.Dropdown[data-use-theme="MHH"] {
	:deep(.multiselect-main) {
		--ms-option-color-pointed: var(--primary-mhh-teal-base);
		--ms-option-bg-selected-pointed: var(--secondary-mhh-teal-light);
		--ms-option-bg-selected: var(--primary-mhh-teal-base);

		.dropdown-caret {
			fill: var(--primary-mhh-teal-base);
		}

		&:focus, &:focus-within {
			--ms-border-color: var(--primary-mhh-teal-base);
		}

		&.is-disabled {
			--ms-border-color: var(--neutral-grey-light);
			
			.dropdown-caret {
				fill: var(--neutral-grey-base);
			}
		}

		.value-wrapper .text-value {
			color: var(--primary-mhh-teal-base);
			font-weight: 600;
		}
	}
}

.Dropdown[data-use-theme="firefly"] {
	:deep(.multiselect-main) {
		--ms-option-color-pointed: var(--primary-firefly-black-base);
		--ms-option-bg-pointed: var(--secondary-firefly-orange-ultralight);
		--ms-option-bg-selected-pointed: var(--primary-firefly-orange-base);
		--ms-option-bg-selected: var(--primary-firefly-orange-base);

		.dropdown-caret {
			fill: var(--primary-firefly-orange-base);
		}

		&:focus, &:focus-within {
			--ms-border-color: var(--primary-firefly-orange-base);
		}

		&.is-disabled {
			--ms-border-color: var(--neutral-grey-light);
			
			.dropdown-caret {
				fill: var(--neutral-grey-base);
			}

			.value-wrapper .text-value {
				color: var(--neutral-firefly-grey-medium);
			}
		}

		.value-wrapper .text-value {
			color: var(--secondary-firefly-orange-extradark);
			font-weight: 600;
		}
	}
}
</style>
