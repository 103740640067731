<script>
export default {
	name: 'AirCargoSearch',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import FormWrapper from '~/components/form/FormWrapper.vue';
import TextField from '~/components/form/TextField.vue';
import Tooltip from '~/components/Tooltip.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DatePicker from '~/components/form/DatePicker.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { i18nGlobal } from '~/logic/i18n.js';
import {
	pushAA_fireflyBookAirCargoSearchStart,
	pushAA_fireflyBookAirCargoSearchEnd,
	pushAA_fireflyBookAirCargoSearchError,
	pushAA_click,
} from '~/logic/adobe-analytic.js';

const props = defineProps({
	
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },
	
	ondSelection: { type: Object, default: null },

	datePicker: { type: Object, default: null },
	hideTodayDateIndicator: { type: Boolean, default: false },
	
	promoCodeLabel: { type: String, default: null },
	promoCodeErrorMessage: { type: String, default: i18nGlobal.t('Conditions not met for promo code to be applied') },
	isHidePromoCodeField: { type: Boolean, default: false },

	subHeaderTooltipCTATextMobileLabel: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	
	airCargoEndpointUrl: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
});


const rootEl = ref(null);
const formWrapper = ref(null);

const ondPickerEl = ref(null);
const ondValueFrom = ref(null);
const ondValueTo = ref(null);

const flightDatePickerEl = ref(null);
const dateFlightValue = ref(null);

const promoCodeEl = ref(null);
const promoCodeValue = ref(null);

const { pageProperties, isEditorMode } = useGlobalAEMState();

let AAHasSentStart = false;
let AAHasSentEnd = false;

const sendFormCompleteAA = (data) => {
	if (AAHasSentEnd) return;
	AAHasSentEnd = true;
	pushAA_fireflyBookAirCargoSearchEnd(data);
};

const handleSubmitValid = async (values, actions) => {
	console.log('handleSubmitValid', values);

	try {
		const searchData = {
			'origin': ondValueFrom.value.code,
			'destination': ondValueTo.value.code,
			'departure_date': dateFlightValue.value,
			'promotion_code': promoCodeValue.value || '',
		};
	
		sendFormCompleteAA(searchData);
	
		const entriesSearchData = Object.entries(searchData);
	
		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', props.airCargoEndpointUrl);
	
		for (let [, [key, value]] of entriesSearchData.entries()) {
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}
	
		document.body.appendChild(form);
		form.submit();

	} catch (error) {
		pushAA_fireflyBookAirCargoSearchError({
			errorName: error,
		});
	}
};

const triggerAA_searchError = ({ errorMsg }) => {
	const errorInfo = {
		errorName: errorMsg,
	};
	const webInteractions = {
		name: props.promoCodeLabel,
		type: 'other',
	};
	pushAA_fireflyBookAirCargoSearchError([errorInfo, webInteractions]);
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('handleSubmitInvalid', errors);
};

const focusToDatePicker = (newValue) => {
	if (newValue && !isMobileViewport.value) flightDatePickerEl.value?.inputEl?.focus();
};

const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;

	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.cargoFlightDate ||
		(Object.keys(form.errors).length !== 0)
	);
});

const promoFieldValidationFunc = (value) => {
	let yupSchema = yup.string().nullable().max(8, props.promoCodeErrorMessage);
	
	try {
		yupSchema.validateSync(value);
		return true;
	} catch (err) {
		return err.errors[0];
	}
};


const formWrapperPromoCodeError = computed(() => {
	if (formWrapper.value !== null) {
		const form = formWrapper.value;

		return form.errors['promoCode'];
	}

	return null;
});


watch(formWrapperPromoCodeError, (newValueErrorText) => {
	if (newValueErrorText) {
		triggerAA_searchError({
			errorMsg: newValueErrorText,
		});
	}
});



onMounted(() => {
	const aaFormStartHandler = (event) => {
		if (AAHasSentStart) return;
		if (event.target.tagName === 'FORM') return;
		
		AAHasSentStart = true;
		pushAA_fireflyBookAirCargoSearchStart('air-cargo-search-tab');
		rootEl.value.removeEventListener('focus', aaFormStartHandler, { capture: true });
	};

	rootEl.value.addEventListener('focus', aaFormStartHandler, { capture: true });
});
</script>


<template>
<div
	ref="rootEl"
	class="AirCargoSearch"
>
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		v-focus-within
		class="lg:mt-0"
		tabindex="-1"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>
		<div>
			<div v-if="props.subHeaderTabDescription" v-html-sanitize="props.subHeaderTabDescription" class="mb-5"></div>

			<Tooltip v-if="props.subHeaderTabTooltipLabel" class="inline-flex mb-5">
				<template #default>
					<div class="flex items-center">
						<icon-fas-circle-info class="fill-secondary-firefly-orange-extradark mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
						{{ props.subHeaderTabTooltipLabel }}
					</div>
				</template>
				<template #mobile-title>
					{{ props.subHeaderTabTooltipLabel }}
				</template>
				<template #tooltip-content>
					{{ props.subHeaderTabTooltipBody }}
				</template>
				<template #mobile-cta-label>
					{{ props.subHeaderTooltipCTATextMobileLabel }}
				</template>
			</Tooltip>

			<div class="grid grid-cols-1 >lg:grid-cols-10 gap-6">
				<div class="w-full >lg:col-spans-6">
					<ONDPicker
						ref="ondPickerEl"
						v-model:modelValueFrom="ondValueFrom"
						v-model:modelValueTo="ondValueTo"
						nameFrom="locationFrom"
						nameTo="locationTo"
						ondListIdentifier="FIREFLY_AIR_CARGO_SEARCH"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
						:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
						:labelTextFrom="props.ondSelection?.fromLabel"
						:labelTextTo="props.ondSelection?.toLabel"
						:noResultsText="props.ondSelection?.noResultMessage"
						@update:modelValueTo="focusToDatePicker"
					>
						<template #from-picker-mobile-title>
							<span>{{ props.fromQuestionMobile }}</span>
						</template>
						<template #to-picker-mobile-title>
							<span>{{ props.toQuestionMobile }}</span>
						</template>
					</ONDPicker>
				</div>
				<div class="w-full >lg:col-spans-4">
					<DatePicker
						ref="flightDatePickerEl"
						v-model="dateFlightValue"
						name="cargoFlightDate"
						:ariaLabel="props.datePicker?.departureDateLabel"
						:disablePastDates="true"
						:requried="true"
						:requriedErrorMessage="props.datePicker?.invalidDateErrorMessage"
						:isHideTodayIndicator="!props.datePicker?.todayIndicator"
						variant="booking-widget"
					/>
				</div>
			</div>
			<div class="grid grid-cols-1 >lg:grid-cols-10 gap-6 mt-6">
				<div
					v-if="!isMobileViewport || !props.isHidePromoCodeField"
					class="w-full >lg:col-spans-6"
				>
					<TextField
						v-if="!props.isHidePromoCodeField"
						ref="promoCodeEl"
						v-model="promoCodeValue"
						name="promoCode"
						variant="booking-widget"
						:ariaLabel="props.promoCodeLabel"
						placeholder="E.g. 8990"
						:allowedKeys="/[a-zA-Z0-9]/"
						:validation="promoFieldValidationFunc"
					>
					</TextField>
				</div>
				<div class="w-full >lg:col-spans-4 >lg:flex >lg:justify-end">
					<CTAButton

						v-aa="[
							{
								clickName: props.searchFlightCTAText,
								clickComponentType: 'Button',
								componentName: 'AirCargoSearch',
								componentID: props.componentIdAA,
							},
							{
								name: props.searchFlightCTAText,
								type: 'exit',
							},
						]"
						:sizeType="isMobileViewport ? 'small' : 'medium'"
						:isSubmit="true"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						:disabled="isBtnSubmitDisabled"
						class="mx-0 !py-5 !px-6"
					>
						{{ props.searchFlightCTAText }}
					</CTAButton>
				</div>
			</div>
		</div>
		<div
			v-if="props.note"
			class="bg-secondary-firefly-orange-ultralight text-primary-firefly-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
		>
			<icon-fas-circle-info class="fill-secondary-firefly-orange-extradark text-base" />
			<div
				v-html-sanitize="props.note"
				class="text-sm"
			>
			</div>
		</div>
	</FormWrapper>
</div>
</template>


<style lang="scss" scoped>

.AirCargoSearch {

}

</style>