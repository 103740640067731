<script>
export default {
	name: 'IconCustom',
	inheritAttrs: false,
};
</script>

<script setup>
const props = defineProps({
	iconId: { type: String, default: '', validator: (value) => ['youku', 'tiktok', 'travel-info'].includes(value) },
	size: { type: Number, default: 24 },
});


</script>

<template>
<svg
	v-if="props.iconId === 'youku'"
	xmlns="http://www.w3.org/2000/svg"
	:style="{ width: `${props.size}px`, height: `${props.size}px` }"
	viewBox="0 0 24 24"
	v-bind="$attrs"
>
	<g fill="currentColor">
		<path d="M10.5 9.002a.533.533 0 0 0-.571.523l.011 2.4a2.207 2.207 0 0 0 2.3 2.261 2.228 2.228 0 0 0 2.313-2.242l.011-2.416a.531.531 0 0 0-.578-.523.522.522 0 0 0-.573.512v2.424a1.074 1.074 0 0 1-1.168 1.117 1.125 1.125 0 0 1-1.182-1.141l.011-2.386a.54.54 0 0 0-.574-.526Zm-3.2.008h-.013a2.361 2.361 0 0 0-.609.087 2.267 2.267 0 0 0-1.035.6 2.336 2.336 0 0 0-.688 1.791 2.681 2.681 0 0 0 .346 1.576 2.4 2.4 0 0 0 1.852 1.123H7.45a2.4 2.4 0 0 0 1.852-1.121 2.71 2.71 0 0 0 .346-1.576 2.349 2.349 0 0 0-.688-1.791 2.267 2.267 0 0 0-1.035-.6 2.367 2.367 0 0 0-.612-.089h-.011Zm-6.7 0a.615.615 0 0 0-.567.383.628.628 0 0 0 .179.659c.552.561 1.126 1.106 1.67 1.674l.008 1.871a.583.583 0 0 0 .593.6.593.593 0 0 0 .6-.595l.011-1.924 1.686-1.676a.58.58 0 0 0-.791-.848c-.008.011-1.495 1.481-1.495 1.481L.913 9.117A.506.506 0 0 0 .6 9.01Zm6.7 1.151a1 1 0 0 1 .316.049 1.2 1.2 0 0 1 .726.61 2.956 2.956 0 0 1 .1 1.1 1.139 1.139 0 0 1-1.145 1.11 1.165 1.165 0 0 1-.437-.083 1.151 1.151 0 0 1-.707-1.025 3.073 3.073 0 0 1 .106-1.1 1.181 1.181 0 0 1 .723-.61 1.04 1.04 0 0 1 .319-.049ZM18.531 9.009a.469.469 0 0 0-.335.148c-.7.648-1.358 1.333-2.039 1.992a.85.85 0 0 0-.194.3V9.57a.611.611 0 0 0-1.217 0v4.117a.531.531 0 0 0 .6.5.539.539 0 0 0 .6-.5s.027-1.894.015-1.894a1.625 1.625 0 0 0 .346.394c.517.508 1.027 1.023 1.544 1.526a2.4 2.4 0 0 0 .464.413.577.577 0 0 0 .822-.572c0-.261-.228-.424-.4-.591-.441-.439-.894-.875-1.335-1.322v-.102q.753-.739 1.5-1.481a.561.561 0 0 0 .245-.464.584.584 0 0 0-.616-.587Zm1.4.011a.533.533 0 0 0-.571.523l.011 2.394a2.308 2.308 0 0 0 4.614.019L24 9.543a.53.53 0 0 0-.582-.519.522.522 0 0 0-.57.515v2.413a1.079 1.079 0 0 1-1.171 1.113 1.116 1.116 0 0 1-1.18-1.136l.01-2.386a.534.534 0 0 0-.574-.523Z" />
	</g>
</svg>


<svg
	v-if="props.iconId === 'tiktok'"
	xmlns="http://www.w3.org/2000/svg"
	:style="{ width: `${props.size}px`, height: `${props.size}px` }"
	viewBox="0 0 24 24"
	v-bind="$attrs"
>
	<defs>
		<clipPath id="a">
			<path fill="currentColor" d="M0 0h14.04v18H0z" />
		</clipPath>
	</defs>
	<g>
		<g clip-path="url(#a)" transform="translate(5 3)">
			<path fill="currentColor" d="M13.95 2.07a.169.169 0 0 0-.27 0 1.932 1.932 0 0 1-2.25-.54 1.571 1.571 0 0 1-.36-1.08.331.331 0 0 0-.09-.27C10.89 0 10.801 0 10.71 0H7.201a.239.239 0 0 0-.27.27v12.06a1.174 1.174 0 0 1-1.17 1.08 1.191 1.191 0 0 1-1.17-1.17 1.174 1.174 0 0 1 1.08-1.17c.18 0 .27-.18.27-.36V6.84c0-.18-.09-.36-.27-.36h-.18a5.75 5.75 0 0 0-5.49 6.03A5.665 5.665 0 0 0 5.94 18a5.724 5.724 0 0 0 5.49-5.22V6.12a6.906 6.906 0 0 0 2.34.09c.18 0 .27-.18.27-.36v-3.6c0-.09 0-.18-.09-.18" />
		</g>
	</g>
</svg>


<svg
	v-if="props.iconId === 'travel-info'"
	xmlns="http://www.w3.org/2000/svg"
	:style="{ width: `${props.size}px`, height: `${props.size}px` }"
	viewBox="0 0 24 24"
	v-bind="$attrs"
>
	<g fill="currentColor">
		<path d="M17.492 6.523h-1.376V3.091a2.06 2.06 0 0 0-2.059-2.06H9.942a2.06 2.06 0 0 0-2.06 2.06v3.433H6.508a2.746 2.746 0 0 0-2.746 2.745v9.612a2.743 2.743 0 0 0 2.743 2.75 1.373 1.373 0 0 0 2.747 0h5.49a1.373 1.373 0 1 0 2.746 0 2.748 2.748 0 0 0 2.754-2.75V9.269a2.747 2.747 0 0 0-2.75-2.746Zm-7.734-3.6h4.584v3.6h-4.58Zm8.744 16.183a.687.687 0 0 1-.687.687H6.198a.687.687 0 0 1-.687-.687V9.075a.687.687 0 0 1 .687-.687h11.618a.687.687 0 0 1 .687.687Z" />
		<path stroke="currentColor" stroke-width=".5" d="M13.123 16.338h-.449v-2.47a.674.674 0 0 0-.674-.674h-.9a.676.676 0 0 0-.674.674.676.676 0 0 0 .674.674h.225v1.8h-.449a.676.676 0 0 0-.674.674.676.676 0 0 0 .674.674h2.246a.674.674 0 0 0 .674-.674.673.673 0 0 0-.673-.678ZM12 12.296a.9.9 0 0 0 .9-.9.9.9 0 0 0-.9-.9.9.9 0 0 0-.9.9.9.9 0 0 0 .9.9Z" data-name="Path 126998" />
	</g>
</svg>



</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';


</style>
