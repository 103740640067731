<script>
export default {
	name: 'FireflyManageBooking',
	inheritAttrs: false,
};
</script>

<script setup>
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import FormWrapper from '~/components/form/FormWrapper.vue';
import TextField from '~/components/form/TextField.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import Tooltip from '~/components/Tooltip.vue';
import yup from 'mh-yup';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';


const props = defineProps({
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
	manageBooking: { type: Object, default: null },
	manageBookingApiUrl: { type: String, default: '' },
	mobileManageBookingApiUrl: { type: String, default: '' },
});

const { isEditorMode } = useGlobalAEMState();

const rootEl = ref(null);

const bookingRefValue = ref('');
const lastNameValue = ref('');

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['manage-booking', 'manage-booking-tab'],
	rootEl,
});

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'manage-booking-tab',
});
const emit = defineEmits([
	'update:active-tab',
]);

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}

	emit('update:active-tab', query.maintab);
});

const handleSubmitValid = async ({ bookingRef, lastName }, actions) => {
	const params = {
		PNR: bookingRef,
		LastName: lastName,
	};

	const url = new URL(isMobileViewport.value ? props.mobileManageBookingApiUrl : props.manageBookingApiUrl);

	Object.keys(params).forEach((key) => {
		url.searchParams.append(key, params[key]);
	});
	
	window.location.href = url.toString();
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);

	// perform side-effects, such as scrolling to the first error input
	// >>> Design-requirement: No side-effect
};

const bookingRefValidation = computed(() => {
	let yupSchema = yup.string().nullable().matches(/^[A-Za-z0-9]*$/, props.manageBooking.bookingReference?.alphanumericErrorMessage);
	const {
		isMandatory,
		errorMessage,
		maxLimit,
		maxLimitErrorMessage,
	} = props.manageBooking.bookingReference;

	if (isMandatory) yupSchema = yupSchema.required(errorMessage);

	if (maxLimit) yupSchema = yupSchema.max(maxLimit, maxLimitErrorMessage);

	return yupSchema;
});


</script>



<template>
<div
	ref="rootEl"
	class="FireflyManageBooking booking-widget-subcomponent"
	:class="{
		'>md:hidden': props.isHideInDesktop,
		'hidden >md:block': props.isHideInMobile,
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: Manage Booking</span>
		</h5>

		<div
			class="ManageBookingBody"
		>
			<FormWrapper
				v-slot="{ errors }"
				:focusFirstErrorElOnInvalidSubmit="true"
				@submit-valid="handleSubmitValid"
				@submit-invalid="handleSubmitInvalid"
			>
				<div v-if="props.manageBooking?.description" v-html-sanitize="props.manageBooking?.description" class="mb-5"></div>
		
				<Tooltip v-if="props.manageBooking?.tooltipLabel" class="inline-flex mb-5">
					<template #default>
						<div class="flex items-center">
							<icon-fas-circle-question class="fill-secondary-firefly-orange-extradark mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
							{{ props.manageBooking?.tooltipLabel }}
						</div>
					</template>
					<template #mobile-title>
						{{ props.manageBooking?.tooltipLabel }}
					</template>
					<template #tooltip-content>
						{{ props.manageBooking?.tooltipBody }}
					</template>
				</Tooltip>

				<div class="grid-container mb-6">
					<div v-if="props.manageBooking?.bookingReference" class="md:mb-3">
						<TextField
							v-model="bookingRefValue"
							variant="booking-widget"
							name="bookingRef"
							:ariaLabel="props.manageBooking?.bookingReference?.label"
							:placeholder="props.manageBooking?.bookingReference?.placeholderText"
							:validation="bookingRefValidation"
							:allowedKeys="/[A-Za-z0-9]/"
						>
							<template #short-description>
								<p v-if="props.manageBooking?.bookingReference?.shortDescription">{{ props.manageBooking?.bookingReference?.shortDescription }}</p>
							</template>
						</TextField>
					</div>
					<div class="md:mb-6">
						<TextField
							v-if="props.manageBooking?.lastName"
							v-model="lastNameValue"
							v-alphabets-strict
							:validation="yup.string()
								.min(1)
								.required()
								.max(40, 'Last Name should not be larger than 40 characters')"
							variant="booking-widget"
							name="lastName"
							:placeholder="props.manageBooking?.lastName?.placeholderText"
							:ariaLabel="props.manageBooking?.lastName?.label"
						>
							<template #short-description>
								<p v-if="props.manageBooking?.lastName?.shortDescription">{{ props.manageBooking?.lastName?.shortDescription }}</p>
							</template>
						</TextField>
					</div>
					<div>
						<CTAButton
							v-if="props.manageBooking?.buttonLabel"
							v-aa="[
								{
									clickName: props.manageBooking?.buttonLabel,
									clickComponentType: 'Button',
									componentName: 'FireflyManageBooking',
									componentID: props.manageBooking?.componentIdAA,
								},
								{
									name: props.manageBooking?.buttonLabel,
									type: 'exit',
								},
							]"
							sizeType="medium"
							:disabled="lastNameValue && bookingRefValue && Object.keys(errors).length !== 0"
							ctaSizing="fluid"
							isSubmit
						>{{ props.manageBooking?.buttonLabel }}</CTAButton>
					</div>
				</div>

				<div
					v-if="props.manageBooking?.note"
					class="bg-secondary-firefly-orange-ultralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl"
				>
					<icon-fas-circle-info class="fill-secondary-firefly-orange-extradark text-base flex-shrink-0" />
					<div v-html-sanitize="props.manageBooking?.note" class="text-sm"></div>
				</div>
			</FormWrapper>
		</div>
		
		<div
			v-if="isEditorMode"
			class="generic-container py-10"
		>
			<p class="font-bold">{{ props.manageBooking?.label }}</p>
			<p>Hidden in: <span v-if="props.manageBooking?.isHideInDesktop">Desktop</span> <span v-if="props.manageBooking?.isHideInMobile">, Mobile</span></p>
		</div>

	</div>
	
</div>
	
<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	:backButtonLabel="props.manageBooking?.backButtonMobile"
	class="text-white"
	@update:is-visible="isMobileSubpageVisible = $event;"
>
		
	<template #banner-title>
		<h1 class="text-center">Manage Booking</h1>
	</template>
		
	<template #default>
		<div class="generic-container mb-20">
	
			<div identifier="manage-booking">
				<FormWrapper
					v-slot="{ errors }"
					:focusFirstErrorElOnInvalidSubmit="true"
					@submit-valid="handleSubmitValid"
					@submit-invalid="handleSubmitInvalid"
				>
					<div v-if="props.manageBooking?.description" v-html-sanitize="props.manageBooking?.description" class="mb-5"></div>
		
					<Tooltip v-if="props.manageBooking?.tooltipLabel" class="inline-flex mb-5">
						<template #default>
							<div class="flex items-center">
								<icon-fas-circle-question class="mr-4 rtl:(mr-0 ml-4) fill-secondary-firefly-orange-extradark" aria-hidden="true" />
								{{ props.manageBooking?.tooltipLabel }}
							</div>
						</template>
						<template #mobile-title>
							{{ props.manageBooking?.tooltipLabel }}
						</template>
						<template #tooltip-content>
							{{ props.manageBooking?.tooltipBody }}
						</template>
					</Tooltip>

					<div class="grid-container mb-6">
						<div v-if="props.manageBooking?.bookingReference" class="md:mb-3">
							<TextField
								v-model="bookingRefValue"
								variant="booking-widget"
								name="bookingRef"
								:ariaLabel="props.manageBooking?.bookingReference?.label"
								:placeholder="props.manageBooking?.bookingReference?.placeholderText"
								:validation="bookingRefValidation"
								:allowedKeys="/[A-Za-z0-9]/"
							>
								<template #short-description>
									<p v-if="props.manageBooking?.bookingReference?.shortDescription">{{ props.manageBooking?.bookingReference?.shortDescription }}</p>
								</template>
							</TextField>
						</div>
						<div class="md:mb-6">
							<TextField
								v-if="props.manageBooking?.lastName"
								v-model="lastNameValue"
								v-alphabets-strict
								:validation="yup.string()
									.min(1)
									.required()
									.max(40, 'Last Name should not be larger than 40 characters')"
								variant="booking-widget"
								name="lastName"
								:placeholder="props.manageBooking?.lastName?.placeholderText"
								:ariaLabel="props.manageBooking?.lastName?.label"
							>
								<template #short-description>
									<p v-if="props.manageBooking?.lastName?.shortDescription">{{ props.manageBooking?.lastName?.shortDescription }}</p>
								</template>
							</TextField>
						</div>
						<div>
							<CTAButton
								v-if="props.manageBooking?.buttonLabel"
								v-aa="[
									{
										clickName: props.manageBooking?.buttonLabel,
										clickComponentType: 'Button',
										componentName: 'FireflyManageBooking',
										componentID: props.manageBooking?.componentIdAA,
									},
									{
										name: props.manageBooking?.buttonLabel,
										type: 'exit',
									},
								]"
								sizeType="medium"
								:disabled="lastNameValue && bookingRefValue && Object.keys(errors).length !== 0"
								ctaSizing="fluid"
								isSubmit
							>{{ props.manageBooking?.buttonLabel }}</CTAButton>
						</div>
					</div>

					<div
						v-if="props.manageBooking?.note"
						class="bg-secondary-firefly-orange-ultralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl"
					>
						<icon-fas-circle-info class="text-base flex-shrink-0 fill-secondary-firefly-orange-extradark" />
						<div v-html-sanitize="props.manageBooking?.note" class="text-sm"></div>
					</div>
				</FormWrapper>
			</div>
					
		</div>
	</template>
		
</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FireflyManageBooking{
	
	.ManageBookingBody{

		.grid-container {
			display: grid;
			grid-auto-flow: column;
			grid-template-columns: 1fr 1fr auto;
			gap: 1rem;

			@media #{var.$query-max-md} {
				display: block;
			}
		}
	}
}

</style>
