import { useEventBus } from '@vueuse/core';


export function usePassengers ({
	maxPassengers = 9,
	
	maxAdultsCount = 9,
	maxTeensCount = 9,
	maxChildrenCount = 9,
	maxInfantsCount = 9,
	
	defaultAdultsCount = 1,
	defaultTeensCount = 0,
	defaultChildrenCount = 0,
	defaultInfantsCount = 0,
} = {}) {
	
	const iAdultsCount = ref(defaultAdultsCount);
	const iTeensCount = ref(defaultTeensCount);
	const iChildrenCount = ref(defaultChildrenCount);
	const iInfantsCount = ref(defaultInfantsCount);
	
	const showInfantErrorMsg = ref(false);
	
	const computedAdultsCount = computed({
		get () {
			return iAdultsCount.value;
		},
		set (n) {
			iAdultsCount.value = n;
			
			if (n < iInfantsCount.value) {
				iInfantsCount.value = n;
				showInfantErrorMsg.value = true;
			} else {
				showInfantErrorMsg.value = false;
			}
		},
	});
	const computedTeensCount = computed({
		get () {
			return iTeensCount.value;
		},
		set (n) {
			iTeensCount.value = n;
			showInfantErrorMsg.value = false;
		},
	});
	const computedChildrenCount = computed({
		get () {
			return iChildrenCount.value;
		},
		set (n) {
			iChildrenCount.value = n;
			showInfantErrorMsg.value = false;
		},
	});
	const computedInfantsCount = computed({
		get () {
			return iInfantsCount.value;
		},
		set (n) {
			iInfantsCount.value = n;
			showInfantErrorMsg.value = false;
		},
	});
	const computedInfantsMax = computed(() => {
		return Math.min(iAdultsCount.value, maxInfantsCount);
	});
	
	const totalPassengersIncludedInfantCount = computed(() => (totalPassengers.value + iInfantsCount.value));
	const totalPassengers = computed(() => (iAdultsCount.value + iTeensCount.value + iChildrenCount.value));
	const hasReachedMaxPassengersCount = computed(() => (totalPassengers.value >= unref(maxPassengers)));
	
	return {
		adults: {
			count: computedAdultsCount,
			min: 1,
			max: maxAdultsCount,
		},
		teens: {
			count: computedTeensCount,
			min: 0,
			max: maxTeensCount,
		},
		children: {
			count: computedChildrenCount,
			min: 0,
			max: maxChildrenCount,
		},
		infants: {
			count: computedInfantsCount,
			min: 0,
			max: computedInfantsMax,
		},
		totalPassengers,
		totalPassengersIncludedInfantCount,
		hasReachedMaxPassengersCount,
		showInfantErrorMsg,
	};
}

export function useIsUkVariant ({ origin, destination } = {}) {
	const ukCountryName = 'United Kingdom';
	if (!origin && !destination) return false;
	return origin?.countryName === ukCountryName || destination?.countryName === ukCountryName;
}

export function useSyncPassengerDetails (passengerElRef) {
	const globalPassengerDetailBus = useEventBus('booking-widget:passenger-detail');

	globalPassengerDetailBus.on(async (event, payload) => {
		switch (event) {
			case 'update-passenger-count':
				passengerElRef.value?.setValues(payload);
				await nextTick();
				break;
			default:
				console.log(`Unknown event: ${event}. Ignore it.`);
				break;
		}
	});
}
