import { useRouteHash as vueUseUseRouteHash } from '@vueuse/router';
import { parseQuery } from 'vue-router';


export function useRouteHash () {
	const reactiveRouteHash = vueUseUseRouteHash();
	
	const hashSplit = computed(() => {
		/* 
			Remove the first # character, split the string by the first '?' it sees.
			This will produce an Array with the 3rd element being empty.
		*/
		return reactiveRouteHash.value.replace(/^#/, '').split(/\?(.*)/s);
	});
	
	const hash = computed(() => {
		return hashSplit.value.at(0); // <-- returns the first element
	});
	const hashQueryString = computed(() => {
		return hashSplit.value.at(1) ?? ''; // <-- returns the secopnd element
	});
	const hashQuery = computed(() => {
		return hashQueryString.value ? parseQuery(hashQueryString.value) : {};
	});
	
	
	return {
		rawValue: reactiveRouteHash,
		hash,
		hashQueryString,
		hashQuery,
	};
}
