// encryptionHelper.js
export const encryptionHelper = () => {
	// Generates a cryptographic key for AES-GCM encryption
	const _generateEncryptionKey = async () => {
		try {
			return await crypto.subtle.generateKey(
				{
					name: 'AES-GCM',
					length: 256,
				},
				true, // Make the key extractable to use it later for encryption and decryption
				['encrypt', 'decrypt']
			);
		} catch (error) {
			// do nothing
		}
	};

	// Converts an ArrayBuffer to a base64 string
	const _arrayBufferToBase64 = (buffer) => {
		const uint8Array = new Uint8Array(buffer);
		let binary = '';
		const len = uint8Array.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(uint8Array[i]);
		}
		return window.btoa(binary);
	};

	// Converts a base64 string to an ArrayBuffer
	const _b64ToArrayBuffer = (base64string) => {
		const binaryString = window.atob(base64string);
		const len = binaryString.length;
		const bytes = new Uint8Array(len);
		for (let i = 0; i < len; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes.buffer;
	};

	// Encrypts data using the AES-GCM algorithm
	const encrypt = async (data) => {
		const key = await _generateEncryptionKey();
		const iv = window.crypto.getRandomValues(new Uint8Array(12));
		const encodedData = new TextEncoder().encode(JSON.stringify(data));
		const encryptedData = await crypto.subtle.encrypt(
			{
				name: 'AES-GCM',
				iv: iv,
				tagLength: 128,
			},
			key,
			encodedData
		);
		const encryptedDataBase64 = _arrayBufferToBase64(encryptedData);
		const ivBase64 = _arrayBufferToBase64(iv);
		const exportedKey = await crypto.subtle.exportKey('raw', key);
		const base64Key = _arrayBufferToBase64(exportedKey);
		return `${encryptedDataBase64}.${ivBase64}.${base64Key}`;
	};

	// Decrypts data that was encrypted using the AES-GCM algorithm
	const decrypt = async (encryptedData) => {
		const [data, iv, key] = encryptedData.split('.');
		const initializationVector = _b64ToArrayBuffer(iv);
		const encryptedBuffer = _b64ToArrayBuffer(data);
		const secretKey = _b64ToArrayBuffer(key);
		secretKey.value = await crypto.subtle.importKey('raw', secretKey, { name: 'AES-GCM' }, true, ['encrypt', 'decrypt']);
		const decryptedData = await crypto.subtle.decrypt(
			{
				name: 'AES-GCM',
				iv: initializationVector,
				tagLength: 128,
			},
			secretKey.value,
			encryptedBuffer
		);
		return new TextDecoder().decode(decryptedData);
	};

	return {
		encrypt,
		decrypt,
	};
};
