import { getCurrentInstance, getCurrentScope } from 'vue';
// import { unrefElement, useActiveElement, computedAsync } from '@vueuse/core';


export default function useFormAccordionStatus (name, { required, value, error }) {

	let accordionEl = null;
	
	function updateStatus () {
		accordionEl.__vueParentComponent.exposed.setInputFieldStatus(name, getStatus());
	}

	function getAccordionEl () {
		const instance = getCurrentInstance();
		const element = instance.vnode.el instanceof Text ? instance.vnode.el.nextElementSibling : instance.vnode.el;
		return element?.closest?.('.FormAccordion');
	}

	function getStatus () {
		if (!unref(required)) {
			console.log('before', unref(required));
			return null;
		}
		if (!unref(value)) {
			return false;
		}

		if (unref(error)) {
			return false;
		}
		return true;
	}

	onMounted(() => {
		accordionEl = getAccordionEl();
		if (accordionEl) {
			watch([value, error], () => {
				updateStatus();
			});

			updateStatus();
		}
	});

	return {};
}
