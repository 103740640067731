<script>
export default {
	name: 'ExperienceFragment',
	editConfig: {
		emptyLabel: 'Experience Fragment',
		resourceType: 'mh/components/experience-fragment',
		isEmpty (props) {
			return true;
		},
	},
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import { AEMContainer, Utils } from '@custom/aem-vue-editable-components';
import AppSpinner from '~/components/AppSpinner.vue';
import axios from 'axios';
import { useExperienceFragmentContent } from '~/logic/aem/experience-fragment-store.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';


const props = defineProps({
	xfPath: { type: String, default: '' },
	showSpinner: { type: Boolean, default: false },
});

const fragmentContent = ref(null);
const errorMsg = ref('');

const { pageProperties, isEditorMode } = useGlobalAEMState();

const xfPathComputed = computed(() => {
	const country = pageProperties.value.rootCountry ?? 'my';
	const language = pageProperties.value.rootLanguage ?? 'en';

	return props.xfPath.includes('$country') || props.xfPath.includes('$lang')
		? props.xfPath.replaceAll('$country', country).replaceAll('$lang', language)
		: props.xfPath;
});

watch(() => props.xfPath, () => {
	if (!props.xfPath) return;
	errorMsg.value = '';
	
	const fragmentContentList = useExperienceFragmentContent();
	
	if (!fragmentContentList[xfPathComputed.value]) {
		fragmentContentList[xfPathComputed.value] = axios.get(`${xfPathComputed.value}/jcr:content/root/responsivegrid.model.json`);
	}
	
	fragmentContentList[xfPathComputed.value].then(({ data }) => {
		fragmentContent.value = data;
	}).catch((reason) => {
		console.log('Unable to fetch ExperienceFragment content. xfPathComputed = ', xfPathComputed.value);
		errorMsg.value = reason;
	});
}, { immediate: true });



const aemContainerProps = computed(() => {
	return fragmentContent.value ? Utils.modelToProps(fragmentContent.value) : null;
});

const isLoading = computed(() => {
	return props.xfPath && fragmentContent.value === null;
});

</script>

<template>
<div
	v-if="isEditorMode"
>
	<p v-if="!props.xfPath" class="text-red-500">❗ Please specify the Experience Fragment path</p>
	<p v-else-if="errorMsg" class="text-red-500">❗ Unable to retrieve Experience Fragment from "{{ xfPathComputed }}"</p>
</div>

<AppSpinner
	v-else-if="props.showSpinner && isLoading"
	class="mx-auto my-2"
/>

<AEMContainer
	v-if="!isLoading"
	class="experience-fragment"
	v-bind="{
		...$attrs,
		...aemContainerProps,
	}"
/>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';
.experience-fragment {
	
	:deep(.cq-placeholder) {
		display: none;
	}
	&.ul-align-center {
		flex-direction: column;
		display: flex;
		justify-content: center;
		:deep(ul) {
			display: inline-block !important;
		}
		:deep(.TextComponent) {
			text-align: left;
			padding: 0px 20px;
			height: calc(100vh - 80px);
			overflow-y: auto;
		}
	}
}
</style>
