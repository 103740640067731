<script>
export default {
	name: 'RecaptchaGoogle',
};
</script>

<!-- https://developers.google.com/recaptcha/docs/v3 -->
<script setup>
import { waitFor } from '~/logic/helpers/utils';

const props = defineProps({
	siteKey: { type: String, required: true },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const isReady = ref(false);

onMounted(() => {
	const recaptchaScript = document.createElement('script');
	recaptchaScript.setAttribute('src', `https://www.recaptcha.net/recaptcha/api.js?render=${props.siteKey}`);
	document.head.appendChild(recaptchaScript);
	checkRecaptchaLoaded();
});


const getRecaptchaToken = async () => {
	if (!isReady.value) {
		const waitForReady = waitFor(() => isReady.value === true);
		await waitForReady.start();
	}
	const token = await grecaptcha.execute(props.siteKey, { action: 'submit' });
	return token;
};

function checkRecaptchaLoaded () {
	if (typeof window.grecaptcha === 'undefined') {
		// reCAPTCHA library has not loaded yet
		setTimeout(checkRecaptchaLoaded, 100);
	} else {
		// reCAPTCHA library has loaded
		window.grecaptcha.ready(function () {
			isReady.value = true;
		});
	}
}


defineExpose({
	isReady,
	getRecaptchaToken,
});

</script>

<template>
<div class="RecaptchaGoogle"></div>
</template>
