<script>
export default {
	name: 'SubTabFlightAndHotel',
};
</script>

<script setup>
import DropdownTravellersAndRoom from '~/components/BookingWidget/FlightHotelSearch/DropdownTravellersAndRoom.vue';
import Dropdown from '~/components/form/Dropdown.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import TextField from '~/components/form/TextField.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import Tooltip from '~/components/Tooltip.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { useLowFarePriceList } from '~composables/booking-widget/useLowFarePriceList.js';
import { formatNumberWithAbbreviation } from '~/logic/helpers/string.js';


const props = defineProps({
	// modelValue: { type: String, default: '' },
	datePicker: { type: Object, default: null },
	description: { type: String, default: '' },
	tooltipLabel: { type: String, default: '' },
	tooltipBody: { type: String, default: '' },
	ondSelection: { type: Object, default: null },
	travellerSelection: { type: Object, default: null },
	cabinClass: { type: Object, default: null },
	productID: { type: String, default: '' },
	note: { type: String, default: '' },
	endpoint: { type: String, default: '' },
	cultureCode: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'form-submission',
]);

const formWrapper = ref(null);
const rootEl = ref(null);
const dateRangePickerEl = ref(null);
const { pageProperties } = useGlobalAEMState();
const siteName = window.siteName;

const handleSubmitValid = async (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);
	
	emit('form-submission');
	
	const roomData = values?.room.map(({ adults, children, childAge, infants }, index) => {
		let childAgeObject = {};
		if (children && childAge.length) {
			childAge.forEach((age, idx) => {
				childAgeObject[`PaxInfos[${index}].ChildAges[${idx}]`] = age;
			});
		}

		return {
			...(adults ? { [`PaxInfos[${index}].AdultCount`]: adults } : null),
			...(children ? { [`PaxInfos[${index}].ChildCount`]: children } : null),
			...(infants ? { [`PaxInfos[${index}].InfantCount`]: infants } : null),
			...(childAge?.length ? childAgeObject : null),
		};
	});

	const roomDataFlatten = roomData.reduce((acc, curr) => ({ ...acc, ...curr }));

	const params = {
		'From': ondValueFrom.value.value,
		'To': ondValueTo.value.value,
		'DepartureDate': dateRangeValueFrom.value,
		'ReturnDate': dateRangeValueTo.value,
		/*
			The cabin class value from the passenger and cabin class dropdown is 1, 2, 3.
			For Flight + Hotel search, the expected value is 
			Economy: 1,
			Business: 2,
			Business Suite: 3.
			No require to map new value to the options for this case.
		*/
		'CabinClass': activeCabinClass.value,
		'CultureCode': props.cultureCode,
		'Currency': pageProperties.value.baseCurrency,
		'JourneyType': 1,
		'ProductId': props.productID,
		'RoomCount': values.room.length,
		...(roomData.length ? roomDataFlatten : null),
	};

	const url = new URL(props.endpoint);

	Object.keys(params).forEach((key) => {
		url.searchParams.append(key, params[key]);
	});
	
	window.location.href = url.toString();

};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const ondValueFrom = ref(null);
const ondValueTo = ref(null);

// const lowFareApiUrl = pageProperties?.value?.lowFareApiUrl;
const isOneWay = ref(false);

// const dateRangePriceData = ref(useLowFarePriceList(ondValueFrom, ondValueTo, dateRangeValueFrom, null, lowFareApiUrl));
// const {
// 	priceData: dateRangePriceData,
// 	currency,
// 	returnTypeDepartureDateAndFare: departureDateAndFare,
// } = useLowFarePriceList({
// 	originRef: ondValueFrom,
// 	destinationRef: ondValueTo,
// 	departureDateRef: dateRangeValueFrom,
// 	paymentTypeRef: null,
// 	endpointUrl: lowFareApiUrl,
// 	isOneWayRef: isOneWay,
// });

const handleWatchIsOneWay = (flag) => {
	isOneWay.value = flag;
};

const cabinClassOptions = computed(() => {
	return [
		...(props?.cabinClass?.isShowEconomyClass ? [{
			label: i18nGlobal.t('Economy'),
			value: '1',
		}] : []),
		...(props?.cabinClass?.isShowBusinessClass ? [{
			label: i18nGlobal.t('Business'),
			value: '2',
		}] : []),
		...(props?.cabinClass?.isShowBusinessSuiteClass ? [{
			label: i18nGlobal.t('Business Suite'),
			value: '3',
		}] : []),
	];
});

const activeCabinClass = ref('');
activeCabinClass.value = cabinClassOptions.value.length ? cabinClassOptions.value[0].value : '';


const isBtnSubmitDisabled = computed(() => {
	// datepickerValue.value; // eslint-disable-line
	const form = formWrapper.value;
	if (!form) return true;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.dateDeparture ||
		!form.values.dateReturn ||
		!form.values.room.length ||
		!form.values.cabinClass ||
		Object.keys(form.errors).length !== 0
	);
});

const defaultMinDate = 0;
const defaultMaxDate = 360;

const computedMinDate = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.departureDateConfig || typeof datePickerData?.departureDateConfig !== 'number') return defaultMinDate;
	return datePickerData?.departureDateConfig;
});

const computedMaxDate = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.returnDateConfig || typeof datePickerData?.returnDateConfig !== 'number') return defaultMaxDate;
	return datePickerData?.returnDateConfig;
});

const focusToDatePicker = (newValue) => {
	if (newValue && !isMobileViewport.value) dateRangePickerEl.value?.inputElFrom?.focus();
};

// const sumPrice = ref(0);

// watch(dateRangeValueTo, (newValue) => {
// 	if (
// 		newValue &&
// 		ondValueFrom.value &&
// 		ondValueTo.value &&
// 		dateRangeValueTo.value &&
// 		dateRangePriceData.value &&
// 		dateRangePriceData.value[dateRangeValueTo.value] &&
// 		departureDateAndFare?.value?.totalFareAmount
// 	) {
// 		sumPrice.value = parseInt(departureDateAndFare.value.totalFareAmount) + parseInt(dateRangePriceData.value[dateRangeValueTo.value]);
// 		return;
// 	}

// 	sumPrice.value = 0;
// });

// const sumPriceWithCurrency = computed(() => {
// 	if (!currency.value && !sumPrice.value) return '';
// 	return `${currency.value} ${formatNumberWithAbbreviation(sumPrice.value)}`;
// });


</script>

<template>
<div ref="rootEl" class="SubTabFlightAndHotel" :data-use-theme="siteName">
	<FormWrapper
		ref="formWrapper"
		class=""
		:focusFirstErrorElOnInvalidSubmit="true"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>
		<div v-if="props.description" v-html-sanitize="props.description" class="mb-5"></div>

		<Tooltip v-if="props.tooltipLabel" class="inline-flex mb-5">
			<template #default>
				<div class="flex items-center">
					<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
					{{ props.tooltipLabel }}
				</div>
			</template>
			<template #mobile-title>
				{{ props.tooltipLabel }}
			</template>
			<template #tooltip-content>
				{{ props.tooltipBody }}
			</template>
		</Tooltip>

		<div class="flex gap-6 xl:gap-3 lg:flex-col">
			<div class="w-6/10 lg:w-full">
				<ONDPicker
					v-model:modelValueFrom="ondValueFrom"
					v-model:modelValueTo="ondValueTo"
					nameFrom="locationFrom"
					nameTo="locationTo"
					ondListIdentifier="FLIGHT_SEARCH_AND_HOTEL"
					:requiredFrom="true"
					:requiredTo="true"
					:requiredErrorMsgFrom="props.ondSelection?.originErrorMessage"
					:requiredErrorMsgTo="props.ondSelection?.destinationErrorMessage"
					:labelTextFrom="props.ondSelection?.originLabel"
					:labelTextTo="props.ondSelection?.destinationLabel"
					:noResultsText="props.ondSelection?.noResultMessage"
					@update:modelValueTo="focusToDatePicker"
				>
					<template #from-picker-mobile-title>
						<span>{{ props.ondSelection?.fromQuestionMobile }}</span>
					</template>
					<template #to-picker-mobile-title>
						<span>{{ props.ondSelection?.toQuestionMobile }}</span>
					</template>
				</ONDPicker>
			</div>
			<div class="w-4/10 lg:w-full">
				<DateRangePicker
					ref="dateRangePickerEl"
					v-model:modelValueFrom="dateRangeValueFrom"
					v-model:modelValueTo="dateRangeValueTo"
					:textFieldAttrsFrom="{
						ariaLabel: props.datePicker?.departureDateLabel,
						placeholder: i18nGlobal.t('Select a date'),
					}"
					:textFieldAttrsTo="{
						ariaLabel: props.datePicker?.returnDateLabel,
						placeholder: i18nGlobal.t('Select a date'),
					}"
					:minDate="`t+${computedMinDate}d`"
					maxDate="t+1y"
					:maxDateFromAfterSelectingTo="`t+${computedMaxDate}d`"
					:disablePastDates="true"
					nameFrom="dateDeparture"
					nameTo="dateReturn"
					:requiredFrom="true"
					:requiredTo="true"
					:requiredErrorMsgFrom="props.datePicker?.dateErrorMessage"
					:requiredErrorMsgTo="props.datePicker?.dateErrorMessage"
					:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
					:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
					:hideOneWay="true"
					@update:is-one-way="handleWatchIsOneWay"
				>
					<template #mobile-title>
						{{ props.datePicker?.datePickerQuestionMobile }}
					</template>

					<!-- <template v-if="currency" #before-cta-done>
						<span v-if="ondValueFrom && !sumPrice" v-html-sanitize="$t('Fare based on <strong>{currency}</strong>', { currency })"></span>

						<span v-if="ondValueFrom && ondValueTo && sumPrice" v-html-sanitize="$t('Trip from <strong>{sumPriceWithCurrency}</strong> per adult', { sumPriceWithCurrency })"></span>
					</template> -->
				</DateRangePicker>
			</div>
		</div>
		<div class="flex mt-6 gap-6 xl:gap-3 xl:mt-3 lg:flex-col">
			<div class="flex flex-grow gap-6 xl:gap-3 lg:flex-col">
				<div class="w-6/10 lg:w-full">
					<DropdownTravellersAndRoom
						:labelText="props.travellerSelection?.label"
						:adultLabel="props.travellerSelection?.adultLabel"
						:childrenLabel="props.travellerSelection?.childrenLabel"
						:infantLabel="props.travellerSelection?.infantLabel"
						:teenagerLabel="props.travellerSelection?.teenagerLabel"
						combinedAnyExceededMsg="Maximum {n} in all rooms"
						combinedAdultChildExceededMsg="Maximum {n} in all rooms excluding infants"
						combinedInfantExceededMsg="Maximum {n} infants"
					>
						<template #mobile-title>
							<span>{{ props.travellerSelection?.questionMobile }}</span>
						</template>
					</DropdownTravellersAndRoom>
				</div>
				<div class="w-4/10 lg:w-full">
					<Dropdown
						v-model="activeCabinClass"
						name="cabinClass"
						:options="cabinClassOptions"
						:labelText="props.cabinClass?.label"
						desktopVariant="booking-widget"
					>
						
					</Dropdown>
				</div>
			</div>
			<div class="ml-auto lg:ml-0">
				<CTAButton
					v-aa="[
						{
							clickName: props.cabinClass?.buttonLabel,
							clickComponentType: 'Button',
							componentName: 'SubTabFlightAndHotel',
							componentID: props.componentIdAA,
						},
						{
							name: props.cabinClass?.buttonLabel,
							type: 'exit',
						},
					]"
					:sizeType="isMobileViewport ? 'small' : 'large'"
					:isSubmit="true"
					:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
					:disabled="isBtnSubmitDisabled"
				>
					{{ props.cabinClass?.buttonLabel }}
				</CTAButton>
			</div>
		</div>
		
		<TextField
			variant="input-hidden"
			name="activeCabinClass"
			ariaLabel="Selected cabin class"
			:modelValue="activeCabinClass"
		/>

		<div
			v-if="props.note"
			class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
		>
			<icon-fas-circle-info class="fill-primary-blue-base text-base" />
			<div class="text-sm">
				<p>{{ props.note }}</p>
			</div>
		</div>
	</FormWrapper>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabFlightAndHotel {
	
}

[data-use-theme="MHH"] {
	.fill-primary-blue-base {
		fill: var(--primary-mhh-teal-base);
	}
}

</style>
