
export default function usePropPassThrough (props, propNameArr = []) {
	return Object.fromEntries(propNameArr.map((propName) => {
		if (!(propName in props)) {
			console.warn(`Prop "${propName}" does not exist.`);
		}
		return [
			propName,
			props[propName],
		];
	}));
}
