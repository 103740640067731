<script>
export default {
	name: 'MHHHomepageLayout',
	expose: [],
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import BtnBackToTop from '~/components/BtnBackToTop.vue';

const rootEl = ref(null);
const { isEditorMode } = useGlobalAEMState();

</script>


<template>
<div ref="rootEl" class="MHHHomepageLayout flex flex-col min-h-screen" data-layout="MHHHomepage">
	<ExperienceFragment
		class="global-header-notification"
		xfPath="/content/experience-fragments/mhh/$country/$lang/site/header-deals/master"
	/>
	<div class="sticky-spacer h-$headerNotificationHeight"></div>
	
	<ExperienceFragment
		class="global-header"
		xfPath="/content/experience-fragments/mhh/$country/$lang/site/header/master"
	/>
	
	<div id="content"></div>
	<div id="booking-subpage-container"></div>
	
	<main>
		<slot></slot>
	</main>
	
	<div class="floating-bottom-right-container">
		<ExperienceFragment
			class="global-floating-widget"
			xfPath="/content/experience-fragments/mhh/$country/$lang/site/floating-widget/master"
		/>
		<BtnBackToTop />
	</div>
	
	<ExperienceFragment
		class="global-cookies-notification"
		xfPath="/content/experience-fragments/mhh/$country/$lang/site/cookies-notification/master"
	/>
	<ExperienceFragment
		class="global-footer"
		xfPath="/content/experience-fragments/mhh/$country/$lang/site/footer/master"
	/>
</div>
</template>



<style scoped lang="scss">
@use '~/styles/partials/_var.scss';

:slotted(.page) {
	min-height: 400px;
}

</style>
