<script>
export default {
	name: 'HiddenSeamlessBookingWidget',
	inheritAttrs: false,
};
</script>

<script setup>
import CheckIn from '~/aem-components/booking/CheckIn.vue';
import FlightHotelSearch from '~/aem-components/booking/FlightHotelSearch.vue';
import FlightSchedule from '~/aem-components/booking/FlightSchedule.vue';
import FlightSearch from '~/aem-components/booking/FlightSearch.vue';
import FlightStatus from '~/aem-components/booking/FlightStatus.vue';
import ManageBooking from '~/aem-components/booking/ManageBooking.vue';
import MHupgrade from '~/aem-components/booking/MHupgrade.vue';
import axios from 'axios';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { waitFor } from '~/logic/helpers/utils.js';

const checkInPropsFromAPI = ref(null);
const flightSearchHotelPropsFromAPI = ref(null);
const flightSchedulePropsFromAPI = ref(null);
const flightSearchPropsFromAPI = ref(null);
const flightStatusPropsFromAPI = ref(null);
const manageBookingPropsFromAPI = ref(null);
const upgradePropsFromAPI = ref(null);

const bookingWidgetArrayList = [
	{
		componentName: 'check-in',
		dataRef: checkInPropsFromAPI,
	},
	{
		componentName: 'flight-hotel-search',
		dataRef: flightSearchHotelPropsFromAPI,
	},
	{
		componentName: 'flight-schedule',
		dataRef: flightSchedulePropsFromAPI,
	},
	{
		componentName: 'flight-search',
		dataRef: flightSearchPropsFromAPI,
	},
	{
		componentName: 'flight-status',
		dataRef: flightStatusPropsFromAPI,
	},
	{
		componentName: 'manage-booking',
		dataRef: manageBookingPropsFromAPI,
	},
	{
		componentName: 'mh-upgrade',
		dataRef: upgradePropsFromAPI,
	},
];

// Methods definitions

function transformObjectProps (keyProps = 'isHideInDesktop', dataProps = {}) {
	const dataPropsKey = Object.keys(dataProps);

	for (const key of dataPropsKey) {
	
		if (Object.hasOwn(dataProps, keyProps)) {
			dataProps[keyProps] = true;
		}
		
		if (typeof dataProps[key] === 'object') {
			transformObjectProps(keyProps, dataProps[key]);
		}
	}
	
	return dataProps;
}

const setupProps = async (subBookingWidgetArray = []) => {
	const { pageProperties } = useGlobalAEMState();
	const country = pageProperties.value.rootCountry;
	const language = pageProperties.value.rootLanguage;
	
	try {
		
		subBookingWidgetArray.forEach(async (item, index) => {
			const resp = await axios.get(`/content/mh/${country}/${language}/home/jcr:content/root/embedded_booking_widget/${item.componentName}.model.json`);
			item.dataRef.value = resp.data;
		});
		
		
	} catch (error) {
		console.error(error);
	}
};


// Computed properties

const checkInProps = computed(() => {
	
	if (checkInPropsFromAPI.value === null) return null;
	
	return {
		...checkInPropsFromAPI.value,
		isHideInDesktop: true,
	};
});

const flightHotelSearchProps = computed(() => {
	
	if (flightSearchHotelPropsFromAPI.value === null) return null;
	
	return {
		...transformObjectProps('isHideInDesktop', flightSearchHotelPropsFromAPI.value),
	};
});

const flightScheduleProps = computed(() => {
	
	if (flightSchedulePropsFromAPI.value === null) return null;
	
	return {
		...flightSchedulePropsFromAPI.value,
		isHideInDesktop: true,
	};
});

const flightSearchProps = computed(() => {
	
	if (flightSearchPropsFromAPI.value === null) return null;
	
	return {
		...transformObjectProps('isHideInDesktop', flightSearchPropsFromAPI.value),
		isHideContinueYourSearchSection: true,
	};
});

const flightStatusProps = computed(() => {
	
	if (flightStatusPropsFromAPI.value === null) return null;
	
	return {
		...transformObjectProps('isHideInDesktop', flightStatusPropsFromAPI.value),
	};
});

const manageBookingProps = computed(() => {
	
	if (manageBookingPropsFromAPI.value === null) return null;
	
	return {
		...transformObjectProps('isHideInDesktop', manageBookingPropsFromAPI.value),
	};
});

const upgradeProps = computed(() => {
	
	if (upgradePropsFromAPI.value === null) return null;
	
	return {
		...upgradePropsFromAPI.value,
		isHideInDesktop: true,
	};
});

const getElementIfExist = async (selector) => {
	// await nextTick();
	try {
		const wait = waitFor(() => document.querySelector(selector) !== null, 15, 6);
		await wait.start();
		
		const el = document.querySelector(selector);
		if (el) return true;
		
	} catch (error) {
		// if (error) return;
	}
	
	return false;
};

const isCheckInMounted = ref(true);
const isFlightHotelSearchMounted = ref(true);
const isFlightScheduleMounted = ref(true);
const isFlightSearchMounted = ref(true);
const isFlightStatusMounted = ref(true);
const isManageBookingMounted = ref(true);
const isUpgradeMounted = ref(true);

onMounted(async () => {
	setupProps(bookingWidgetArrayList);
	
	isCheckInMounted.value = await getElementIfExist('.CheckIn');
	isFlightHotelSearchMounted.value = await getElementIfExist('.FlightHotelSearch');
	isFlightScheduleMounted.value = await getElementIfExist('.FlightSchedule');
	isFlightSearchMounted.value = await getElementIfExist('.FlightSearch');
	isFlightStatusMounted.value = await getElementIfExist('.FlightStatus');
	isManageBookingMounted.value = await getElementIfExist('.ManageBooking');
	isUpgradeMounted.value = await getElementIfExist('.MHupgrade');
});

</script>

<template>
<div class="HiddenSeamlessBookingWidget">
	<template v-if="isMobileViewport">
		<CheckIn
			v-if="!isCheckInMounted && checkInProps !== null"
			v-bind="checkInProps"
		/>
		<FlightHotelSearch
			v-if="!isFlightHotelSearchMounted && flightHotelSearchProps !== null"
			v-bind="flightHotelSearchProps"
		/>
		<FlightSchedule
			v-if="!isFlightScheduleMounted && flightScheduleProps !== null"
			v-bind="flightScheduleProps"
		/>
		<FlightSearch
			v-if="!isFlightSearchMounted && flightSearchProps !== null"
			v-bind="flightSearchProps"
		/>
		<FlightStatus
			v-if="!isFlightStatusMounted && flightStatusProps !== null"
			v-bind="flightStatusProps"
		/>
		<ManageBooking
			v-if="!isManageBookingMounted && manageBookingProps !== null"
			v-bind="manageBookingProps"
		/>
		<MHupgrade
			v-if="!isUpgradeMounted && upgradeProps !== null"
			v-bind="upgradeProps"
		/>
	</template>
</div>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.HiddenSeamlessBookingWidget {
	
}

</style>