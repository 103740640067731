import { useBreakpoints } from '@vueuse/core';
import BREAKPOINTS from '~/constants/BREAKPOINTS.js';

/* 
{
	'xs': 480,
	'sm': 640,
	'md': 768,
	'lg': 1024,
	'xl': 1280,
	'2xl': 1536,
};
*/

let breakpoints = ref(null);
let isXS = ref(null);
let isSM = ref(null);
let isMD = ref(null);
let isLG = ref(null);
let isXL = ref(null);
let isXXL = ref(null);
let isXXXL = ref(null);


let isMobileViewport = ref(null);


const init = () => {
	breakpoints = useBreakpoints(BREAKPOINTS);
	
	isMobileViewport = breakpoints.smaller('md');
	isXS = breakpoints.smaller('xs');
	isSM = breakpoints.between('xs', 'sm');
	isMD = breakpoints.between('sm', 'md');
	isLG = breakpoints.between('md', 'lg');
	isXL = breakpoints.between('lg', 'xl');
	isXXL = breakpoints.between('xl', '2xl');
	isXXXL = breakpoints.greater('2xl');
};


export {
	init,
	
	breakpoints,
	isXS,
	isSM,
	isMD,
	isLG,
	isXL,
	isXXL,
	isXXXL,
	
	isMobileViewport,
};

