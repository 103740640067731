<script>
export default {
	name: 'FloatingWidget',
	inheritAttrs: false,
	editConfig: {
		emptyLabel: 'Floating Widget',
		resourceType: 'mh/components/floating-widget',
		isEmpty (props) {
			return true;
		},
	},
};
</script>

<script setup>
import useDisableScroll from '~composables/useDisableScroll.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';
import OverlayDrawer from '~/components/OverlayDrawer.vue';
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import { useRouter, useRoute } from 'vue-router';
import ChatBot from '~/components/ChatBot.vue';


const props = defineProps({
	newsletterXfPath: { type: String, default: '' },
	infoLabel: { type: String, default: '' },
	isHideInDesktop: { type: Boolean, default: false },
	isHideInMobile: { type: Boolean, default: false },
	widgetList: { type: Array, default: () => [] },
	componentIdAA: { type: String, default: '' },
});

const router = useRouter();
const route = useRoute();

const showOverlay = ref(
	window.location.hash == '#manage-subscription' ? true : false
);
const showFloatingActionList = ref(false);
const showMainLabel = ref(false);
const showNewsLetter = ref(false);
const showFreeComponent = ref(false);
const experienceFragmentPath = ref();

const onClickButton = () => {
	showFloatingActionList.value = !showFloatingActionList.value;
	showMainLabel.value = false;
};
const onHoverInMainBtn = () => {
	if (showFloatingActionList.value) showMainLabel.value = false;
	if (!showFloatingActionList.value) showMainLabel.value = true;
};
const onHoverOutMainBtn = () => { showMainLabel.value = false; };
const openNewsletterOverlay = () => {
	showNewsLetter.value = true;
	showOverlay.value = true;
	router.replace({ query: {
		...route.query,
		acemail: undefined,
		id: undefined,
	} });
};
const openFreeComponentOverlay = () => {
	showFreeComponent.value = true;
	showOverlay.value = true;
	router.replace({ query: {
		...route.query,
		acemail: undefined,
		id: undefined,
	} });
};
useDisableScroll(showFloatingActionList, {
	useOverflowHiddenMethod: true,
});
const handleFloatingWidgetVisiblity = (isVisible) => {
	showFloatingActionList.value = isVisible;
};
const resetOverlayValues = () => {
	showOverlay.value = false;
	showFloatingActionList.value = false;
	showNewsLetter.value = false;
	showFreeComponent.value = false;
};

const activateWidgetSelectedInUrl = () => {
	resetOverlayValues();
	const widgetList = props.widgetList;
	const url = window.location.href;
	const freeComponentWidget = widgetList.find((widget) => widget.function === 'free-component' && widget.componentIdAA && url.includes(widget.componentIdAA));
	if (freeComponentWidget) {
		showOverlay.value = true;
		showFreeComponent.value = true;
	} else if (window.location.hash == '#newsletter-sign-up') {
		showOverlay.value = true;
		showNewsLetter.value = true;
	}
};

const getExperienceFragmentPathForFreeComponent = () => {
	if (props.widgetList.length === 0) {
		return;
	}
	const freeComponentWidget = props.widgetList.find((widget) => widget.function === 'free-component');
	if (freeComponentWidget) {
		const { experienceFragmentPath: widgetExperienceFragmentPath } = freeComponentWidget;
		experienceFragmentPath.value = widgetExperienceFragmentPath;
	}
};

onMounted(() => {
	activateWidgetSelectedInUrl();
	getExperienceFragmentPathForFreeComponent();
});
</script>


<template>
<div
	v-if="(!props.isHideInDesktop && !isMobileViewport) ||
		(!props.isHideInMobile && isMobileViewport)"
	class="FloatingWidget"
	:class="{ 'listActive': showFloatingActionList }"
>
	<transition name="floating-widget" appear>
		<div
			v-if="showFloatingActionList"
			class="FloatingActionList"
		>
			<div
				v-for="(item, index) in props.widgetList"
				:key="index"
				class="FloatingActionButton item"
			>
				<p
					v-if="item.buttonLabel"
					class="FloatingActionLabel"
					aria-hidden="true"
				>
					{{ item.buttonLabel }}
				</p>
				<div>
					<button
						v-if="item.function === 'free-component'"
						v-aa="[
							{
								clickName: item.buttonLabel,
								clickComponentType: 'Button',
								componentName: 'FloatingWidget',
								componentID: props.componentIdAA,
							},
							{
								name: item.buttonLabel,
								type: 'other',
							},
						]"
						v-button-press
						class="btn-generic icon-wrapper"
						type="button"
						:aria-label="item.buttonLabel"
						@click="openFreeComponentOverlay"
					>
						<FontAwesomeLazy
							v-if="item.iconType === 'icon-font-awesome-id'"
							:iconId="item.iconId"
							class="icon"
							aria-hidden="true"
						/>
						<img
							v-else
							:src="item.iconImage"
							class="social-icons-image w-4.5 h-4.5"
							aria-hidden="true"
						/>
					</button>
				</div>
				<button
					v-if="item.function === 'newsletter'"
					v-aa="[
						{
							clickName: item.buttonLabel,
							clickComponentType: 'Button',
							componentName: 'FloatingWidget',
							componentID: props.componentIdAA,
						},
						{
							name: item.buttonLabel,
							type: 'other',
						},
					]"
					v-button-press
					class="btn-generic icon-wrapper"
					type="button"
					:aria-label="item.buttonLabel"
					@click="openNewsletterOverlay"
				>
					<FontAwesomeLazy
						v-if="item.iconType === 'icon-font-awesome-id'"
						:iconId="item.iconId"
						class="icon"
						aria-hidden="true"
					/>
					<img
						v-else
						:src="item.iconImage"
						class="social-icons-image w-4.5 h-4.5"
						aria-hidden="true"
					/>
				</button>
				<ChatBot
					v-else-if="item.function === 'chatbot'"
					:urlJs="item.urlJs"
					:urlCss="item.urlCss"
					@on-chatbot-close="handleFloatingWidgetVisiblity"
				/>
				<AppHyperlink
					v-else-if="item.function === 'link-to-url'"
					:href="item.url"
					:target="item.openInNewTab === 'true' ? '_blank' : '_self'"
					:class="{ 'disable-widget': item.url === undefined }"
					rel="noopener noreferrer"
					class="btn-generic icon-wrapper"
					:aria-label="item.buttonLabel"
					:showExternalIcon="false"
				>
					<FontAwesomeLazy
						v-if="item.iconType === 'icon-font-awesome-id'"
						:iconId="item.iconId"
						class="icon"
						aria-hidden="true"
					/>
					<img
						v-else
						:src="item.iconImage"
						class="social-icons-image w-4.5 h-4.5"
						aria-hidden="true"
					/>
				</AppHyperlink>

				
			</div>
		</div>
	</transition>
	<div
		class="FloatingActionButton main flex"
	>
		<transition name="floating-widget" appear>
			<p
				v-if="showMainLabel && !isMobileViewport && props.infoLabel"
				class="FloatingActionLabel"
				aria-hidden="true"
			>
				{{ props.infoLabel }}
			</p>
		</transition>
		
		<button
			v-aa="[
				{
					clickName: props.infoLabel,
					clickComponentType: 'Button',
					componentName: 'FloatingWidget',
					componentID: props.componentIdAA,
				},
				{
					name: props.infoLabel,
					type: 'other',
				},
			]"
			type="button"
			class="icon-wrapper"
			:aria-label="props.infoLabel"
			@click="onClickButton"
			@mouseenter="onHoverInMainBtn"
			@mouseleave="onHoverOutMainBtn"
		>
			<icon-fas-circle-xmark
				v-if="showFloatingActionList"
				class="icon"
				aria-hidden="true"
			/>
			<icon-fas-circle-info
				v-else
				class="icon"
				aria-hidden="true"
			/>
		</button>
		
	</div>
</div>

<div
	v-if="showFloatingActionList"
	class="floating-widget-backdrop fixed inset-0 bg-neutral-white-base bg-opacity-90"
>
</div>
<OverlayDrawer
	:isVisible="showOverlay"
	@update:is-visible="showOverlay = $event ; showFloatingActionList = $event"
>
	<div class="h-full">
		<div class="drawer-header">
			<img src="@/assets/logo-mh.svg" class="h-6 >md:hidden" />
			<button
				type="button"
				class="w-8 h-8 my-6 p-0 md:h-6 md:w-6 md:my-4 flex items-center justify-center"
				@click="resetOverlayValues"
			>
				<icon-far-xmark class="w-2.5" />
			</button>
		</div>
		<ExperienceFragment v-if="showNewsLetter" class="flex-grow" :xfPath="props.newsletterXfPath" />
		<ExperienceFragment v-if="showFreeComponent" class="flex-grow ul-align-center" :xfPath="experienceFragmentPath" />
	</div>
</OverlayDrawer>

</template>



<style lang="scss">
.aem-AuthorLayer-structure {

	.FloatingWidget {
		position: static !important;
	}
}

html.showing-subpages {

	.FloatingWidget {
		display: none;
	}
}

</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FloatingWidget {
	--floatingMainBtnSize: 56px;
	--floatingItemBtnSize: 48px;
	--floatingLabelMaxWidth: 460px;
	--labelGap: 12px;
	z-index: 1;
	display: flex;
	justify-content: flex-end;
	max-width: calc(
		var(--floatingLabelMaxWidth) +
		var(--labelGap) +
		var(--floatingMainBtnSize) +
		15px
	);
	position: relative;
	

	@media #{var.$query-max-md} {
		--floatingMainBtnSize: 48px;
		--labelGap: 16px;
		--floatingItemBtnSize: 40px;
	}

	&.listActive {
		width: calc(
			var(--floatingLabelMaxWidth) +
			var(--labelGap) +
			var(--floatingMainBtnSize) +
			15px
		);
		max-width: calc(100vw - 24px - 15px);
	}
}
.icon-wrapper {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	border-radius: 100%;

	&.disable-widget {
		pointer-events: none;
	}
}
.icon {
	flex-shrink: 0;
	@apply text-xl m-auto;
}
.FloatingActionLabel {
	font-weight: bold;
	background-color: var(--neutral-white-base);
	border-radius: 4px;
	color: var(--primary-black-base);
	text-align: right;
	@apply text-sm py-0.5 px-1;
}
.FloatingActionButton {
	position: relative;
	display: inline-flex;
	align-items: center;
	gap: var(--labelGap);
	@apply p-0;
}
.FloatingActionList {
	bottom: var(--floatingMainBtnSize);
	right: 0;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	@apply absolute mb-4 gap-4 pl-4;

	@media #{var.$query-max-md} {
		bottom: 50px;
	}

	[dir="rtl"] & {
		right: auto;
		left: 0;
		@apply pl-0 pr-4;
	}
}
.FloatingActionButton.main {

	.icon-wrapper {
		width: var(--floatingMainBtnSize);
		height: var(--floatingMainBtnSize);
		border: 2px solid var(--secondary-teal-base);
		background-color: var(--secondary-teal-extralight);
		box-shadow: rgba(var.$secondary-teal-base, 0.24) 0px 3px 6px;
	}

	.icon {
		fill: var(--secondary-teal-base);
	}
}
.FloatingActionButton.item {
	padding-right: calc((var(--floatingMainBtnSize) - var(--floatingItemBtnSize)) / 2);

	[dir="rtl"] & {
		padding-right: 0;
		padding-left: calc((var(--floatingMainBtnSize) - var(--floatingItemBtnSize)) / 2);
	}
	
	.icon-wrapper {
		width: var(--floatingItemBtnSize);
		height: var(--floatingItemBtnSize);
	}

	.icon {
		fill: var(--neutral-white-base);
		width: 18px;
	}
}
.social-icons-image {
	display: inline-block;
}

.drawer-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	@apply px-6;

	@media #{var.$query-max-md} {
		justify-content: space-between;
		background-color: var(--neutral-white-base);
		border-bottom-width: 1px;
		border-bottom-color: var(--neutral-grey-base);
		@apply px-4;
	}
}

//animation
.floating-widget-enter-active,
.floating-widget-leave-active {
	transition: opacity 200ms;
}

.floating-widget-enter-from,
.floating-widget-leave-to {
	opacity: 0;
}

</style>
