<script>
export default {
	name: 'RadioButton',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import useFieldValidation from '~/logic/composables/useFieldValidation.js';
import useGenerateIdentifier from '~/logic/composables/useGenerateIdentifier.js';
import { i18nGlobal } from '~/logic/i18n.js';
import useFormAccordionStatus from '~/logic/composables/useFormAccordionStatus.js';

const props = defineProps({
	name: { type: String, default: '' }, // Note: if name is not provided, then validation would not work
	modelValue: { type: String, default: null },
	defaultValue: { type: String, default: null },
	ariaLabel: { type: String, default: '' },
	required: { type: Boolean, default: false },
	requiredErrorMsg: {
		type: String,
		default: i18nGlobal.t('This field is required'),
	},
	items: { type: Array, default: () => ([]) },

	disabled: { type: Boolean, default: false },

	rootAttrs: { type: Object, default: () => ({}) },
	layoutAttrs: { type: Object, default: () => ({}) },
	inputAttrs: { type: Object, default: () => ({}) },
});

const emit = defineEmits([
	'update:modelValue',
]);

const siteName = window.siteName;

const inputEl = ref(null);

const validation = computed(() => {
	if (!props.required) return null;
	return yup.string().required(props.requiredErrorMsg);
});

const focus = () => {
	inputEl.value?.focus();
};

const {
	onInput,
	meta,
	errors,
	hasValidationError,
	internalValue,
} = useFieldValidation({
	name: toRef(props, 'name'),
	validation: validation,
	modelValue: toRef(props, 'modelValue'),
}, {
	initialValue: props.modelValue ?? props.defaultValue ?? '',
});

const componentIdentifier = props.name ?? Math.random() * 10;
const { errorMsgId } = useGenerateIdentifier(componentIdentifier);

const radioItems = props.items.map((item) => {
	return {
		...item,
		isPressed: ref(false),
	};

});
useFormAccordionStatus(props.name, {
	required: props.required,
	value: computed(() => internalValue.value),
	error: computed(() => hasValidationError.value),
});

defineExpose({
	focus,
});

</script>

<template>
<div
	class="RadioButton"
	:class="[
		{
			'is-disabled': props.disabled,
			'has-validation-error': hasValidationError && meta.touched,
		},
	]"
	:data-use-theme="siteName"
	v-bind="props.rootAttrs"
>
	<div class="grid gap-4" v-bind="props.layoutAttrs">
		<template v-for="(item, index) in radioItems" :key="index">
			<label
				class="inline-flex cursor-pointer relative"
				v-bind="$attrs"
				:class="{
					'is-mouse-down': item.isPressed.value && !props.disabled,
				}"
				@mousedown="item.isPressed.value = true"
				@keydown.space="item.isPressed.value = true"
				@mouseup="item.isPressed.value = false"
				@keyup.space="item.isPressed.value = false"
				@mouseout="item.isPressed.value = false"
			>
				<input
					ref="inputEl"
					class="input"
					type="radio"
					:required="props.required"
					:name="props.name"
					:disabled="props.disabled"
					:checked="internalValue === item.value"
					:aria-label="props.ariaLabel"
					v-bind="props.inputAttrs"
					@change="() => onInput({ target: { value: item.value } })"
				/>
				<div class="indicator-circle" aria-hidden="true"></div>

				<span class="icon-uncheck" aria-hidden="true"><icon-far-circle /></span>
				<span class="icon-check" aria-hidden="true"><icon-far-circle-dot /></span>

				<div class="label ml-2 rtl:(ml-0 mr-2) empty:ml-0 select-none">
					<slot>
						<span v-html-sanitize="item.text"></span>
					</slot>
				</div>
			</label>
		</template>
	</div>
	<div
		class="short-description-wrapper w-full text-sm empty:hidden"
	>
		<slot name="short-description"></slot>
	</div>
	<div
		:id="errorMsgId"
		class="error-msg-container leading-tight text-semantic-red-base text-sm empty:hidden"
	>
		<slot
			v-if="hasValidationError && meta.touched"
			name="error-messages"
			v-bind="{ meta, errors, internalValue }"
		><span class="">{{ errors[0] }}</span></slot>
	</div>
</div>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.RadioButton {

	.short-description-wrapper {
		color: var(--neutral-grey-ultradark);
	}

	&.has-validation-error {
		.short-description-wrapper {
			color: var(--semantic-red-base);
		}
	}

	label:hover {
		.indicator-circle {
			opacity: 1;
			transform: scale(1);
		}
	}

	.is-mouse-down {
		.indicator-circle {
			opacity: 1;
			@apply bg-opacity-60;
			transform: scale(1);
		}
	}
}

.icon-check,
.icon-uncheck {
	/* align-self: center; */
	transition: all 0.2s cubic-bezier(0.54, 1.6, 0.5, 1);
	transform-origin: center center;
	/* aspect-ratio: 1 / 1;
	width: 1.2em; */
	display: flex;
	position: relative;
	top: 2px;
}

.input {
	opacity: 0;
	position: absolute;
	left: -9999px;

	html[dir="rtl"] & {
		left: 0px;
	}

	~ .icon-check {
		fill: var(--primary-blue-base);
		opacity: 0;
		transform: scale(0.1);
		position: absolute;
	}
	~ .icon-uncheck {
		opacity: 1;
		transform: scale(1);
		fill: var(--neutral-grey-extradark);
	}
	&:checked ~ .icon-check {
		opacity: 1;
		transform: scale(1);
	}
	&:checked ~ .icon-uncheck {
		opacity: 0;
	}

	&:disabled {
		~ .icon-uncheck {
			fill: var(--neutral-grey-base);
		}
		&:checked ~ .icon-check {
			opacity: 0.4;
		}

		~ .label {
			color: var(--neutral-grey-dark);
		}
	}

	/* &[aria-invalid="true"] ~ .icon-uncheck {
		fill: var(--semantic-red-base);
	} */
}

.indicator-circle {
	position: absolute;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	top: -4px;
	left: -6.5px;
	opacity: 0;
	@apply bg-neutral-grey-base bg-opacity-20;
	transform: scale(0.4);
	transform-origin: center center;
	transition: all 0.3s;
	
	html[dir="rtl"] & {
		left: auto;
		right: -6.5px;
	}
}

@supports not selector(.parent:has(.child)) {
	.RadioButton {
		&:focus-within {
			.input:focus-visible ~ .indicator-circle {
				opacity: 1;
				transform: scale(1);
				outline-width: var(--focus-visible-outline-width, 2px);
				outline-offset: var(--focus-visible-outline-offset, 0px);
				outline-color: var(--semantic-blue-base);
				outline-style: solid;
			}
		}
	}
}

@supports selector(.parent:has(.child)) {
	.RadioButton {
		border-radius: 4px;
		outline-width: var(--focus-visible-outline-width, 2.5px);
		outline-offset: calc(var(--focus-visible-outline-offset, 4px) + 2px);
		outline-color: transparent;
		outline-style: solid;
		transition-property: outline-color, outline-offset;
		transition-duration: 0.2s;

		&:has(:focus-visible) {
			outline-offset: var(--focus-visible-outline-offset, 4px);
			outline-color: var(--semantic-blue-base);
		}
	}
}

[data-use-theme="MHH"] {
	.input ~ .icon-check {
		fill: var(--primary-mhh-teal-base);
	}
}

.RadioButton[data-use-theme="firefly"] {
	.input ~ .icon-check {
		fill: var(--primary-firefly-orange-base);
	}
}
</style>
