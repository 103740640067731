import { getCurrentInstance } from 'vue';

export default function useOptionalProp (propsKey = 'modelValue', _options = {}) {
	const instance = getCurrentInstance();
	const options = {
		eventName: `update:${propsKey}`,
		defaultValue: undefined,
		..._options,
	};
	const emit = instance.emit;
	const props = instance.props;
	
	if (!(propsKey in props)) {
		throw new Error(`useOptionalProp: Prop '${propsKey}' does not exist`);
	}
	
	const internalValue = ref(options.defaultValue);
	const exportValue = computed({
		get () {
			return props[propsKey] ?? internalValue.value;
		},
		set (newValue) {
			if (props[propsKey] === undefined || props[propsKey] === null) {
				internalValue.value = newValue;
			} else {
				emit(options.eventName, newValue);
			}
		},
	});
	
	return exportValue;
}
