<script>
export default {
	name: 'Carousel',
	editConfig: {
		emptyLabel: 'Carousel',
		isEmpty (props) {
			switch (props.carouselType) {
				case 'carousel-banner':
					if (props.totalNumOfPhBanner === 0) {
						return true;
					}
					return false;

				case 'standard-carousel':
					if (props.totalNumOfPhStandard === 0) {
						return true;
					}
					return false;
			}
			return true;
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>
import { getCompositeComponent } from '@custom/aem-vue-editable-components';
import Parsys from '~/components/Parsys.vue';
import VueSwiper from '~/components/VueSwiper.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { orderBy as _orderBy } from 'lodash';

/**
 * Props
 *
 * @carouseType has 2 types
 * banner-carousel is Banner Carousel
 * standard-carousel is Standard Carousel
 */

const props = defineProps({
	carouselType: { type: String, default: '' },
	isHideInMobile: { type: Boolean, default: false },
	isHideInDesktop: { type: Boolean, default: false },
	totalNumOfPhBanner: { type: Number, default: 0 },
	totalNumOfPhStandard: { type: Number, default: 0 },
	numberOfPhStandard: { type: Number, default: 0 },
	carouselTimer: { type: Number, default: 1 },
	enableAutoplay: { type: Boolean, default: false },
	enablePauseButton: { type: Boolean, default: false },
});

const { isEditorMode } = useGlobalAEMState();

const swiperBtnPrevEl = ref(null);
const swiperBtnNextEl = ref(null);

const siteName = window.siteName;

const showPlayPauseButton = ref(false);
const carouselBanner = ref(null);
const carouselStandard = ref(null);
const autoplay = ref(false);
if (props.enableAutoplay) {
	autoplay.value = {
		delay: props.carouselTimer * 1000,
		disableOnInteraction: false,
	};
}

const pagination = ref(
	{
		clickable: true,
		renderBullet: function (index, className) {
			return `<span class="${ className }"></span>`;
		},
		bulletClass: `swiper-pagination-bullet ${props.carouselType !== 'carousel-banner' ? 'standard-pagination-carousel' : 'banner-paginaton-carousel'}`,
		clickableClass: `swiper-pagination-clickable ${props.carouselType !== 'carousel-banner' ? 'standard-swiper-pagination-wrapper' : 'banner-swiper-pagination-wrapper'}`,
	}
);

const bannerParsys = computed(() => {
	if (!isEditorMode.value) {
		return _orderBy((Array(props.totalNumOfPhBanner).fill().map((item, index) => {
			return {
				component: getCompositeComponent({
					type: 'mh/components/banner',
					componentKey: `banner-parsys-${index}`,
				}),
			};
		})).filter((item) => !item.component.vNodeProps.isDisableBanner), (item) => item.component.vNodeProps.sortingOrder, ['asc']);
	}
	return _orderBy(Array(props.totalNumOfPhBanner).fill().map((item, index) => {
		return {
			component: getCompositeComponent({
				type: 'mh/components/banner',
				componentKey: `banner-parsys-${index}`,
			}),
		};
	}), (item) => item.component.vNodeProps.sortingOrder, ['asc']);
});

const standardParsys = computed(() => {
	return Array(props.totalNumOfPhStandard).fill().map((item, index) => {
		return {
			component: getCompositeComponent({
				type: 'mh/components/mh-layout-container',
				componentKey: `mh-layout-container-parsys-${index}`,
			}),
		};
	});
});



const handleAutoplayEvent = (showPlayButton) => {
	showPlayPauseButton.value = showPlayButton;
	autoplay.value = {
		delay: props.carouselTimer * 1000,
		disableOnInteraction: false,
	};
	if (showPlayButton) {
		if (props.carouselType === 'carousel-banner') {
			carouselBanner.value?.swiperInstance?.autoplay?.start();
		} else if (props.carouselType === 'standard-carousel') {
			carouselStandard.value?.swiperInstance?.autoplay?.start();
		}
	} else {
		if (props.carouselType === 'carousel-banner') {
			carouselBanner.value?.swiperInstance?.autoplay?.stop();
		} else if (props.carouselType === 'standard-carousel') {
			carouselStandard.value?.swiperInstance?.autoplay?.stop();
		}
	}
};

/*
*	It is required to prevent cqItems and cqPath undefined.
*	Unknown reason why both objects undefined
*/
// eslint-disable-next-line
bannerParsys.value;
// eslint-disable-next-line
standardParsys.value;

onMounted(() => {
	if (props.enableAutoplay && props.enablePauseButton) {
		showPlayPauseButton.value = true;
	} else if (!props.enableAutoplay && props.enablePauseButton) {
		showPlayPauseButton.value = false;
	} else if (!props.enableAutoplay && !props.enablePauseButton) {
		showPlayPauseButton.value = false;
	}
});

</script>

<template>
<template v-if="isEditorMode && props.isHideInDesktop">
	<div class="generic-container py-10 text-center">
		<p>Hidden in: <span v-if="props.isHideInDesktop">Desktop</span> <span v-if="props.isHideInMobile">, Mobile</span></p>
	</div>
</template>

<template v-else>
	<div
		v-if="(isMobileViewport && !props.isHideInMobile) || (!isMobileViewport && !props.isHideInDesktop)"
		class="Carousel relative"
		:data-use-theme="siteName"
	>
		<div v-if="props.enablePauseButton">
			<button
				v-if="showPlayPauseButton"
				type="button"
				class="button icon-wrapper"
				:aria-label="$t('Carousel pause button')"
				@click="handleAutoplayEvent(false)"
			>
				<icon-fas-circle-pause
					class="fill-white"
					aria-hidden="true"
					title="Pause"
				/>
			</button>
			<button
				v-if="!showPlayPauseButton"
				type="button"
				class="button icon-wrapper"
				:aria-label="$t('Carousel play button')"
				@click="handleAutoplayEvent(true)"
			>
				<icon-fas-circle-play
					class="fill-white"
					aria-hidden="true"
					title="Play"
				/>
			</button>
		</div>
		<!-- Type 1 - Banner Carousel -->
		<VueSwiper
			v-if="props.carouselType === 'carousel-banner'"
			ref="carouselBanner"
			:dormantMode="isEditorMode || bannerParsys.length == 1"
			:slidesPerView="1"
			:pagination="pagination"
			:navigation="{
				nextEl: swiperBtnNextEl,
				prevEl: swiperBtnPrevEl,
			}"
			:loop="true"
			:autoplay="autoplay"
			class="banner-carousel-swiper"
		>
			<div
				v-for="(bannerCarousel, index) in bannerParsys"
				:key="`banner-carousel-${index}`"
				class="banner-carousel-parsys-wrapper"
			>
				<Parsys
					:key="index"
					:index="index"
					:component="bannerCarousel.component"
					:label="`Carousel Content ${index}`"
				/>
			</div>
		</VueSwiper>

		<!-- Type 2 - Standard Carousel -->
		<VueSwiper
			v-else
			ref="carouselStandard"
			:dormantMode="isEditorMode || standardParsys.length === 1"
			:slidesPerView="1.1"
			:spaceBetween="16"
			:pagination="pagination"
			:navigation="!isMobileViewport"
			:loop="true"
			:breakpoints="{
				1024: {
					slidesPerView: props.numberOfPhStandard === 1 ? 1 : props.numberOfPhStandard + 0.2,
					slidesPerGroup: props.numberOfPhStandard === 3 ? 3 : props.numberOfPhStandard === 2 ? 2 : 1,
				},
			}"
			:autoplay="autoplay"
			class="standard-carousel-swiper"
		>
			<div
				v-for="(standardCarousel, index) in standardParsys"
				:key="`standard-carousel-${index}`"
				class="standard-carousel-parsys-wrapper"
			>
				<Parsys
					:key="index"
					:index="index"
					:component="standardCarousel.component"
					:label="`Carousel Content ${index}`"
				/>
			</div>
		</VueSwiper>

		<div
			v-if="props.carouselType === 'carousel-banner' && bannerParsys.length > 1"
			class="custom-navigation-container generic-container"
		>
			<button
				ref="swiperBtnPrevEl"
				v-button-press
				type="button"
				class="swiper-button-prev"
				:aria-label="$t('Previous slide')"
			></button>
			<button
				ref="swiperBtnNextEl"
				v-button-press
				type="button"
				class="swiper-button-next"
				:aria-label="$t('Next slide')"
			></button>
		</div>
	</div>
</template>
</template>


<style lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.Carousel {

	.swiper-horizontal {
		.swiper-pagination {
			display: flex;
			justify-content: center;
			align-items: center;
			bottom: 100px;

			@media #{var.$query-min-xl} {
				bottom: 120px;
			}

			&.standard-swiper-pagination-wrapper {
				bottom: 0px;
			}
		}
	}

	.banner-carousel-swiper {
		&.swiper-horizontal {
			.swiper-pagination {

				@media #{var.$query-max-sm} {
					bottom: 30px !important;
				}

				@media #{var.$query-max-md} {
					bottom: 50px !important;
				}
			}
		}
	}

	.swiper-pagination-bullet {
		@apply bg-neutral-white-base;
		margin: 0 14px !important;
		opacity: 40%;
		height: 8px;
		width: 8px;

		&.standard-pagination-carousel {
			@apply bg-primary-blue-base;
			opacity: 40%;
			height: 8px;
			width: 8px;
		}
	}

	.swiper-pagination-bullet-active {
		--mask-url: url('~/assets/carousel/plane.svg');
		display: inline-block;
		width: 16px;
		height: 16px;
		-webkit-mask: var(--mask-url) no-repeat 50% 50%;
		mask: var(--mask-url) no-repeat 50% 50%;
		background-color: var.$neutral-white-base;
		opacity: 100%;
		
		html[dir="rtl"] & {
			--mask-url: url('~/assets/carousel/plane-rtl.svg');
		}

		&.standard-pagination-carousel {
			width: 16px;
			height: 16px;
			-webkit-mask: var(--mask-url) no-repeat 50% 50%;
			mask: var(--mask-url) no-repeat 50% 50%;
			background-color: var.$primary-blue-base;
			opacity: 100%;
		}
	}
	.icon-wrapper {
		position: absolute;
		width: 40px;
		height: 35px;
		z-index: 999;
		bottom: 100px;
		left: 10px;

		@media #{var.$query-max-md} {
			bottom: 30px;
			left: 10px;
		}
	}

	// .swiper-button-prev {
	// 	height: 56px;
	// 	width: 56px;
	// 	border: 1px solid var.$neutral-grey-light;
	// 	border-radius: 28px;
	// 	@apply bg-primary-black-base/20;
	// 	transition: opacity .25s ease-in-out;
	// 	left: 83px;
	// 	opacity: 0;

	// 	&:hover {
	// 		@apply @apply bg-primary-black-base;
	// 	}

	// 	&:focus {
	// 		@apply outline-semantic-blue-base outline-2 outline outline-offset-2;
	// 	}

	// 	&::after {
	// 		content: "";
	// 		background-image: url('~/assets/carousel/chevron-left.svg');
	// 		background-size: cover;
	// 		background-position: center;
	// 		background-color: transparent;
	// 		height: 26px;
	// 		width: 13px;
	// 	}
	// }

	// .swiper-button-next {
	// 	height: 56px;
	// 	width: 56px;
	// 	border: 1px solid var.$neutral-grey-light;
	// 	border-radius: 28px;
	// 	@apply bg-primary-black-base/20;
	// 	transition: opacity .25s ease-in-out;
	// 	opacity: 0;
	// 	right: 83px;

	// 	&:hover {
	// 		@apply @apply bg-primary-black-base;
	// 	}

	// 	&:focus {
	// 		@apply outline-semantic-blue-base outline-2 outline outline-offset-2;
	// 	}

	// 	&::after {
	// 		content: "";
	// 		background-image: url('~/assets/carousel/chevron-left.svg');
	// 		background-size: cover;
	// 		background-position: center;
	// 		background-color: transparent;
	// 		height: 26px;
	// 		width: 13px;
	// 		transform: rotate(180deg);
	// 	}
	// }

	// &:hover {
	// 	.swiper-button-prev, .swiper-button-next {
	// 		opacity: 1;
	// 	}
	// }
	&[data-use-theme="MABA"] {
		.swiper-horizontal {
			.swiper-pagination {
				display: inline-flex;;
				justify-content: center;
				align-items: center;
				width: auto;
				left: 50%;
				transform: translate(-50%, 0);
				border-radius: 100px;
				bottom: 28px;

				@apply py-1.5;
			}
		}

		.swiper-pagination-bullet {
			margin: 0 !important;
			background-color: transparent;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 20px;
			width: 12px;
			overflow: hidden;
			border-radius: 0;
			opacity: 100%;

			&:first-child {
				border-radius: 100px 0 0 100px;
				width: 20px;
			}

			&:last-child {
				border-radius: 0 100px 100px 0;
				width: 20px;
			}

			&::before {
				content: '';
				width: 6px;
				height: 6px;
				border-radius: 100%;
				box-shadow: 0 0 0 500px color.change(var.$neutral-white-base, $alpha: 0.21);
			}
		}

		.swiper-pagination-bullet-active {
			--mask-url: '';
			background-color: transparent;
			opacity: 100%;

			&::before {
				width: 10px;
				height: 10px;
				border: 2px solid var(--neutral-white-base);
			}
		}
	
}
}
</style>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.Carousel {

	:deep(.swiper-button-prev) {
		height: 56px;
		width: 56px;
		border: 1px solid var.$neutral-grey-light;
		border-radius: 28px;
		@apply bg-primary-black-base/20;
		transition: .25s ease-in-out;
		left: 83px;
		opacity: 0;
		
		html[dir="rtl"] & {
			right: 83px;
			left: revert;
			transform: rotate(-180deg);
		}

		&:hover {
			@apply @apply bg-primary-black-base;
		}

		&:focus {
			@apply outline-semantic-blue-base outline-2 outline outline-offset-2;
		}

		&:hover::after {
			transform: translateX(-2px);

		}
		&::after {
			content: "";
			background-image: url('~/assets/carousel/chevron-left.svg');
			background-size: cover;
			background-position: center;
			background-color: transparent;
			height: 26px;
			width: 13px;
			transition: 200ms ease-in-out;
		}
		// .swiper-button-prev {
		// }
	}

	:deep(.swiper-button-next) {
		height: 56px;
		width: 56px;
		border: 1px solid var.$neutral-grey-light;
		border-radius: 28px;
		@apply bg-primary-black-base/20;
		transition: .25s ease-in-out;
		opacity: 0;
		right: 83px;
		
		html[dir="rtl"] & {
			left: 83px;
			right: revert;
			transform: rotate(-180deg);
		}

		&:hover {
			@apply @apply bg-primary-black-base;
		}

		&:focus {
			@apply outline-semantic-blue-base outline-2 outline outline-offset-2;
		}

		&:hover::after {
			transform: rotate(180deg) translateX(-2px);
		}
		&::after {
			content: "";
			background-image: url('~/assets/carousel/chevron-left.svg');
			background-size: cover;
			background-position: center;
			background-color: transparent;
			height: 26px;
			width: 13px;
			transform: rotate(180deg);
			transition: 200ms ease-in-out;
		}

		// .swiper-button-next {
		// }
	}

	&:hover {
		:deep(.swiper-button-prev) {
			opacity: 1;
		}

		:deep(.swiper-button-next) {
			opacity: 1;
		}

		// :deep(.swiper-button-lock) {
		// 	display: inline-block;
		// }
	}

	.standard-carousel-swiper {
		padding-bottom: 48px;

		@media #{var.$query-max-md} {
			padding-bottom: 40px;
		}

		&.standard-carousel-swiper-centered {

			:deep(.swiper-button-prev) {
				left: 21rem;
			}

			:deep(.swiper-button-next) {
				right: 21rem;
			}
		}
	}

	// .banner-carousel-swiper {
	// }
	:deep(.swiper-button-prev.swiper-button-disabled.swiper-button-lock) {
		display: flex;
		pointer-events: all;
		cursor: pointer;
	}

	:deep(.swiper-button-next.swiper-button-disabled.swiper-button-lock) {
		display: flex;
		pointer-events: all;
		cursor: pointer;
	}

	.custom-navigation-container {
		position: absolute;
		z-index: 5;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		@media #{var.$query-max-md} {
			display: none;
		}

		:deep(.swiper-button-prev) {
			left: 1rem;
		
			html[dir="rtl"] & {
				left: 1rem;
			}
		}

		:deep(.swiper-button-next) {
			right: 1rem;
		
			html[dir="rtl"] & {
				right: 1rem;
			}
		}
	}
}

// MAG airplane icon pagination color
[data-use-theme="MAG"] {
	:deep(.standard-pagination-carousel) {
		background-color: var(--primary-midnightgreen-base);
	}
	
}

[data-use-theme="MHH"] {
	:deep(.banner-swiper-pagination-wrapper) {
		.swiper-pagination-bullet {
			background-color: var(--primary-mhh-teal-base);
		}
	}
}

[data-use-theme="MABA"] {
	:deep(.swiper-button-prev) {
		height: 42px;
		width: 42px;
		border: none;
		@apply bg-neutral-white-base/21;

		&::after {
			content: '';
			background-image: url('~/assets/carousel/arrow-left.svg');
			background-size: 15px 13px;
			background-color: transparent;
			background-repeat: no-repeat;
		}
	}

	:deep(.swiper-button-next) {
		height: 42px;
		width: 42px;
		border: none;
		@apply bg-neutral-white-base/21;

		&::after {
			content: '';
			background-image: url('~/assets/carousel/arrow-left.svg');
			background-size: 15px 13px;
			background-color: transparent;
			background-repeat: no-repeat;
		}
	}
}

</style>
