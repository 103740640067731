<script>
export default {
	name: 'ONDPicker',
};
</script>

<script setup>
import AirportPicker from '~/components/BookingWidget/AirportPicker.vue';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useMHEONDList } from '~/logic/composables/booking-widget/useMHEONDList.js';
import AppSpinner from '~/components/AppSpinner.vue';
import { useGeolocation } from '@vueuse/core';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';

const props = defineProps({
	nameFrom: { type: String, required: true },
	nameTo: { type: String, required: true },
	
	modelValueFrom: { type: [String, Object], default: null },
	modelValueTo: { type: [String, Object], default: null },
	
	labelTextFrom: { type: String, default: i18nGlobal.t('From') },
	labelTextTo: { type: String, default: i18nGlobal.t('To') },

	requiredFrom: { type: Boolean, default: false },
	requiredTo: { type: Boolean, default: false },
	
	requiredErrorMsgFrom: { type: String, default: i18nGlobal.t('This field is required') },
	requiredErrorMsgTo: { type: String, default: i18nGlobal.t('This field is required') },
	
	/* 
		----------------------
		When props.useOptionsFromProp true, all the following props will be in effect.
	*/
	useOptionsFromProp: { type: Boolean, default: false },
	optionsFrom: { type: Object, default: null },
	optionsTo: { type: Object, default: null },
	isLoadingFrom: { type: Boolean, default: false },
	isLoadingTo: { type: Boolean, default: false },
	// ----------------------
	
	noResultsText: { type: String, default: null },
	requestGeolocation: { type: Boolean, default: true },
	
	ondListIdentifier: { type: String, default: '' },
	
	// emitOptionChange: { type: Boolean, default: false },

	/* Overwrite group label options for From/To options */
	groupLabelsOverwrite: { type: Object, default: null },
	
	/*
		~Specific Origin for CUG Enhancement~
        props.specificOriginList is a long list string with 3 characters airportCode separated by comma.
        E.g.: "SZB,KUL,ABZ"
        This is configured by author in AEM, to show just the specific "FROM airport list" (origin). All other exluded airportCode will be hidden.
        Plus, this origin list will also auto filter the "TO airport list (destination).
    */
	specificOriginList: { type: String, default: '' },
	specificDestinationList: { type: String, default: '' },
	restrictedDestinationList: { type: String, default: '' },

	enableONDLocaleTranslation: { type: Boolean, default: false },
	ondTranslationLanguage: { type: String, default: '' },

	normalRevenueONDList: { type: String, default: '' },
	multicityONDList: { type: String, default: '' },
	redemptionRevenueONDList: { type: String, default: '' },
});

const emit = defineEmits([
	'update:modelValueFrom',
	'update:modelValueTo',
	// 'update:optionsFrom',
	// 'update:optionsTo',
]);


const internalValueFrom = useInternalValue('modelValueFrom');
const internalValueTo = useInternalValue('modelValueTo');
const siteName = window.siteName;

const handleSwapValues = () => {
	swapLocations();

};

const airportPickerFrom = ref(null);
const airportPickerTo = ref(null);

const handleFromUpdateValue = (newValue) => {
	if (newValue && !isMobileViewport.value) airportPickerTo.value?.focus();
};


const slots = useSlots();

const fromPickerSlots = computed(() => {
	return Object.fromEntries(Object.keys(slots).filter((slotName) => {
		if (slotName.startsWith('from-picker-')) return true;
		return false;
	}).map((slotName) => {
		const newSlotName = slotName.replace('from-picker-', '');
		return [newSlotName, slots[slotName]];
	}));
});
const toPickerSlots = computed(() => {
	return Object.fromEntries(Object.keys(slots).filter((slotName) => {
		if (slotName.startsWith('to-picker-')) return true;
		return false;
	}).map((slotName) => {
		const newSlotName = slotName.replace('to-picker-', '');
		return [newSlotName, slots[slotName]];
	}));
});

const getNormalRevenueONDListComputed = computed(() => {
	return props.normalRevenueONDList === 'none' ? null : props.normalRevenueONDList;
});
const getRedemptionRevenueONDListComputed = computed(() => {
	return props.redemptionRevenueONDList === 'none' ? null : props.redemptionRevenueONDList;
});

const {
	optionsFrom,
	optionsTo,
	
	isLoadingFrom,
	isLoadingTo,
	
	setUserGeolocation,
	swapLocations,
	
	updateFromValue,
	updateToValue,
} = props.useOptionsFromProp ? {} : useMHEONDList({
	fromValueRef: internalValueFrom,
	toValueRef: internalValueTo,
	listIdentifier: toRef(props, 'ondListIdentifier'),
	...(props.ondTranslationLanguage ? { translationLocale: props.ondTranslationLanguage } : null),
	normalRevenueONDList: getNormalRevenueONDListComputed.value,
	redemptionRevenueONDList: getRedemptionRevenueONDListComputed.value,
});



const computedGroupLabelsFromOptions = computed(() => {
	/* 
		Refer preserved structure map to groupId in useONDList.js, 
		GROUP_ID and GROUP_ID_TO_LABEL_MAP contains default i18n group labels
	*/
	const newGroupLabelsOptions = optionsFrom.value.map((fromItem) => {
		return {
			groupId: fromItem.groupId,
			label: props.groupLabelsOverwrite[fromItem.groupId] || fromItem.label,
			options: fromItem.options,
		};
	});

	return newGroupLabelsOptions;
});

const computedGroupLabelsToOptions = computed(() => {
	/* 
		Refer preserved structure map to groupId in useONDList.js, 
		GROUP_ID and GROUP_ID_TO_LABEL_MAP contains default i18n group labels
	*/
	const newGroupLabelsOptions = optionsTo.value.map((toItem) => {
		return {
			groupId: toItem.groupId,
			label: props.groupLabelsOverwrite[toItem.groupId] || toItem.label,
			options: toItem.options,
		};
	});

	return newGroupLabelsOptions;
});

const computedIsLoadingFrom = computed(() => {
	props.isLoadingFrom; // eslint-disable-line
	isLoadingFrom?.value; // eslint-disable-line
	if (props.useOptionsFromProp) return props.isLoadingFrom;
	return isLoadingFrom.value;
});
const computedIsLoadingTo = computed(() => {
	props.isLoadingTo; // eslint-disable-line
	isLoadingTo?.value; // eslint-disable-line
	if (props.useOptionsFromProp) return props.isLoadingTo;
	return isLoadingTo.value;
});

/*
	~Specific Origin for CUG Enhancement~
	This part is used to initialize the airport destination based on
	the passed props.specificOriginList.
*/
const specificOriginOptionsTo = ref(null);


const generateOptionListing = ref(false);
const computedOptionsFrom = computed(() => {
	props.useOptionsFromProp; // eslint-disable-line
	props.optionsFrom; // eslint-disable-line
	optionsFrom?.value; // eslint-disable-line
	if (!generateOptionListing.value && siteName === 'MH') return null;
	if (computedIsLoadingFrom.value) return null;
	if (props.useOptionsFromProp) return props.optionsFrom;

	let finalListFrom = optionsFrom.value;

	// If prop groupLabelsOverwrite not null or configured, return the options with overwritten group labels
	if (props.groupLabelsOverwrite !== null) {
		finalListFrom = computedGroupLabelsFromOptions.value;
	}

	/*
		~Specific Origin for CUG Enhancement~
		Apply when specific airport from list(origin) is configured.
		Below Code is also handling the restricted origin list configued for MHH flight hotel search.
	*/
	if (props.specificOriginList) {
		const specificOriginList = props.specificOriginList.split(',');

		finalListFrom = finalListFrom.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					// return true;
					return specificOriginList.some((airportCode) => {
						return option.airportCode === airportCode;
					});
				}),
			};
		});
	}
	
	// Removing options-grouping-label if the groupList is 0
	finalListFrom = finalListFrom.filter((groupList) => {
		return groupList.options.length;
	});

	return finalListFrom;
});

const computedOptionsTo = computed(() => {
	props.useOptionsFromProp; // eslint-disable-line
	props.optionsTo; // eslint-disable-line
	optionsTo?.value; // eslint-disable-line
	if (!generateOptionListing.value && siteName === 'MH') return null;
	if (computedIsLoadingTo.value) return null;
	if (props.useOptionsFromProp) return props.optionsTo;

	let finalListTo = optionsTo.value;

	// If prop groupLabelsOverwrite not null or configured, return the options with overwritten group labels
	if (props.groupLabelsOverwrite !== null) {
		finalListTo = computedGroupLabelsToOptions.value;
	}

	if (props.specificDestinationList) {
		const specificDestinationList = props.specificDestinationList.split(',');

		finalListTo = finalListTo.map((item) => {
			return {
				...item,
				options: item.options.filter((option) => {
					// return true;
					return specificDestinationList.some((airportCode) => {
						return option.airportCode === airportCode;
					});
				}),
			};
		});
	}

	// Removing options-grouping-label if the groupList is 0
	finalListTo = finalListTo.filter((groupList) => {
		return groupList.options.length;
	});

	return finalListTo;
});

const disableSwapAndDest = computed(( ) => {
	return !internalValueFrom.value;
});

let userGeolocation = null;

const handleOnOpen = () => {
	// prompt to generate OND-option-list
	generateOptionListing.value = true;

	if (userGeolocation !== null) {
		console.log('Disabling geolocation. Reason: geolocation-data has been shared');
		return;
	}

	if (props.requestGeolocation && userGeolocation === null) {
		userGeolocation = useGeolocation();
		setUserGeolocation(userGeolocation);
		console.log('Enabling geolocation');
		return;
	}

	console.log('Disabling geolocation. Reason: disabled in author configuration');
};


// TODO: ❗❗❗ REMOVE AFTER DONE OND ❗❗❗
(() => {
	const currentRouteQuery = window.router.currentRoute.value.query;
	if (('debug' in currentRouteQuery) && !document.querySelector('#DEBUG_STYLES')) {
		const styleTag = window.document.createElement('style');
		styleTag.id = 'DEBUG_STYLES';
		styleTag.innerHTML = `
			.hidden-debug-info { display: block !important; }
			.ONDPicker .AirportPicker .multiselect-main { --ms-max-height: 500px !important; }
			.ONDPicker .AirportPicker .multiselect-options { max-height: 500px !important; }
		`;
		window.document.body.appendChild(styleTag);
	}
})();


/* ❗ Note:
------------
	Any time modelValueXXX is updated externally (i.e. change of value not coming from this component's child <AirportPicker>),
	the corresponding 'updateXXXValue' must be called. This is not done automatically. See <EditSearchBST> for example.
	Hence, we are exposing the function call here.
*/
defineExpose({
	updateFromValue,
	updateToValue,
});

const updateModelValueHandler = (newValue) => {
	handleFromUpdateValue(newValue);
	updateFromValue();
};
</script>


<template>
<div class="ONDPicker" :data-use-theme="siteName">
	<p class="hidden-debug-info">🐞 {{ props.ondListIdentifier }}</p>
	<div class="inner-wrapper">
		<div class="picker-wrapper picker-wrapper-from">
			<AirportPicker
				ref="airportPickerFrom"
				v-model="internalValueFrom"
				class="AirportPicker-from"
				:name="props.nameFrom"
				:options="computedOptionsFrom"
				:labelText="props.labelTextFrom"
				:canClear="true"
				:showOptionLocationIcon="true"
				:required="props.requiredFrom"
				:requiredErrorMsg="props.requiredErrorMsgFrom"
				:noResultsText="props.noResultsText"
				:attrs="{
					'readOnly': isLoadingFrom,
				}"
				:aria-label="$t('Airport of departure')"
				variant="booking-widget"
				@update:modelValue="updateModelValueHandler"
				@open="handleOnOpen"
			>
				<template v-for="(_, slotName) in fromPickerSlots" #[slotName]="slotData">
					<slot :name="`from-picker-${slotName}`" v-bind="slotData"></slot>
				</template>
				
				<template v-if="computedIsLoadingFrom" #nooptions>
					<div class="multiselect-options cursor-default">
						<div class="py-4">
							<AppSpinner class="mx-auto" />
						</div>
					</div>
				</template>
			</AirportPicker>
		</div>
		<div class="sr-only" role="alert">
			{{ $t('Selected departure airport is {airport}', { airport: internalValueFrom?.displayValue ?? internalValueFrom?.value ?? internalValueFrom }) }}
		</div>
		<button
			v-button-press
			:disabled="disableSwapAndDest"
			class="btn-switch btn-generic mx-2 flex"
			type="button"
			:aria-label="$t('Swap locations')"
			@click="handleSwapValues"
		>
			<icon-far-arrow-right-arrow-left class="fill-white m-auto" />
		</button>
		<div class="picker-wrapper picker-wrapper-to">
			<AirportPicker
				ref="airportPickerTo"
				v-model="internalValueTo"
				:name="props.nameTo"
				:options="computedOptionsTo"
				:labelText="props.labelTextTo"
				:canClear="true"
				:showOptionLocationIcon="true"
				:required="props.requiredTo"
				:requiredErrorMsg="props.requiredErrorMsgTo"
				:noResultsText="props.noResultsText"
				:disabled="disableSwapAndDest"
				:attrs="{
					'readOnly': isLoadingTo,
				}"
				variant="booking-widget"
				:aria-label="$t('Airport of arrival')"
				@open="handleOnOpen"
				@update:modelValue="updateToValue();"
			>
				<template v-for="(_, slotName) in toPickerSlots" #[slotName]="slotData">
					<slot :name="`to-picker-${slotName}`" v-bind="slotData"></slot>
				</template>
				
				<template v-if="computedIsLoadingTo" #nooptions>
					<div class="multiselect-options cursor-default">
						<div class="py-4">
							<AppSpinner class="mx-auto" />
						</div>
					</div>
				</template>
			</AirportPicker>
		</div>
		<div class="sr-only" role="alert">
			{{ $t('Selected arrival airport is {airport}', { airport: internalValueTo?.displayValue ?? internalValueTo?.value ?? internalValueTo }) }}
		</div>
	</div>
	
	<div class="error-msg-container leading-tight flex text-semantic-red-base text-sm md:flex-col">
		<div class="flex-1 mt-1 w-[min-content] empty:mt-0 md:w-full">
			<span
				v-if="(
					airportPickerFrom?.hiddenTextField?.hasValidationError &&
					airportPickerFrom?.hiddenTextField?.meta.touched
				)"
				v-html-sanitize="airportPickerFrom?.hiddenTextField.errors[0]"
			>
			</span>
		</div>
		<div class="w-$gapWidth flex-none md:hidden"></div>
		<div class="flex-1 mt-1 w-[min-content] empty:mt-0 md:w-full">
			<span
				v-if="(
					airportPickerTo?.hiddenTextField?.hasValidationError &&
					airportPickerTo?.hiddenTextField?.meta.touched
				)"
				v-html-sanitize="airportPickerTo?.hiddenTextField.errors[0]"
			>
			</span>
		</div>
	</div>
	
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.ONDPicker {
	--gapWidth: 56px;
	
	:deep(.AirportPicker) {
		.multiselect-main {
			--ms-border-color: transparent;
			--ms-bg: transparent;
			
			&:hover {
				--ms-border-color: var(--neutral-grey-base);
				--ms-bg: #{color.change(white, $alpha: 0.35)};
			}
			&:focus, &:focus-within {
				--ms-border-color: var(--primary-blue-base);
				--ms-bg: white;
			}
		}
		
		&.has-validation-error {
			.multiselect-main {
				--ms-border-color: var(--semantic-red-light);
				
				&:hover {
					--ms-border-color: #{var.$semantic-red-base-50-opacity};
				}
				&:focus, &:focus-within {
					--ms-border-color: var(--semantic-red-base);
				}
			}
		}
		.multiselect-dropdown {
			width: calc(200% + var(--gapWidth));
		}
		.dropdown-caret {
			display: none;
		}
		.error-msg-container {
			display: none;
		}
	}
	
	@media #{var.$query-max-md} {
		:deep(.AirportPicker) {
			.multiselect-main {
				--ms-border-color: var(--neutral-grey-light);
				--ms-bg: var(--neutral-grey-ultralight);
				
				&:focus, &:focus-within {
					--ms-border-color: var(--primary-blue-base);
					--ms-bg: white;
				}
			}
		}
	}
}

.inner-wrapper {
	border-radius: 12px;
	background-color: var(--neutral-grey-ultralight);
	box-shadow: 0 0 0 1.5px var(--neutral-grey-light) inset;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	
	@media #{var.$query-max-md} {
		background-color: transparent;
		box-shadow: none;
	}
}

.picker-wrapper {
	flex: 1 1 0;
	
	@media #{var.$query-max-md} {
		flex: 0 0 100%;
	}
}

.picker-wrapper-from {
	@media #{var.$query-max-md} {
		@apply mb-4;
	}
}

.picker-wrapper-to {
	--dropdownTranslateX: calc(-50% - (var(--gapWidth) / 2) - 4px);

	html[dir="rtl"] & {
		--dropdownTranslateX: calc(50% + (var(--gapWidth) / 2) + 4px);
	}
}

.btn-switch {
	width: 40px;
	height: 40px;
	flex: 0 0 40px;
	border-radius: 100%;
	align-self: center;
	
	@media #{var.$query-max-md} {
		position: absolute;
		z-index: 10;
		right: 28px;
		transform: rotate(90deg);
	}
}

[data-use-theme="MHH"] {
	&.ONDPicker :deep(.AirportPicker) .multiselect-main {
		&:focus,
		&:focus-within {
			--ms-border-color: var(--primary-mhh-teal-base);
		}

		.multiselect-option {

			&.is-pointed {
				color: var(--primary-mhh-teal-base);
			}
		}
	}
}

.ONDPicker[data-use-theme="firefly"] {
	
	:deep(.AirportPicker) {
		.multiselect-main {
			&:focus, &:focus-within {
				--ms-border-color: var(--primary-firefly-orange-base);
				--ms-bg: white;
			}
		}
	}
	.btn-switch {
		background-color: var(--primary-firefly-orange-base);
	}
}

</style>
