import { Constants as PMConstants } from '@adobe/aem-spa-page-model-manager';


/**
 * Constants for interacting with AEM components.
 */
const Constants = {
	/**
	 * Name of the data-cq-data-path data attribute.
	 */
	'DATA_PATH_ATTR': 'data-cq-data-path',

	/**
	 * Name of the data-resource-type data attribute.
	 */
	'DATA_CQ_RESOURCE_TYPE_ATTR': 'data-cq-resource-type',

	/**
	 * Class names associated with a new section component.
	 */
	'NEW_SECTION_CLASS_NAMES': 'new section',
	
	/**
	 * Default CSS Class names associated with a component.
	 */
	'APPLIED_CLASS_NAMES': 'appliedCssClassNames',
	
	/**
	 * Class name used to denote aem-container root element.
	 */
	'_CONTAINER_CLASS_NAMES': 'aem-container',
	/**
	 * Class name used to identify the placeholder used to represent an empty component.
	 */
	'_PLACEHOLDER_CLASS_NAMES': 'cq-placeholder',
	'_PAGE_CLASS_NAMES': 'aem-page',
	'_RESPONSIVE_GRID_PLACEHOLDER_CLASS_NAMES': 'aem-Grid-newComponent',

	/**
	 * Event which indicates that content of remote component has been fetched and loaded in the app
	 */
	'ASYNC_CONTENT_LOADED_EVENT': 'cq-async-content-loaded',
	
	
	'PAGE_MODEL_INIT': 'cq-pagemodel-init',
	'PAGE_MODEL_LOADED': 'cq-pagemodel-loaded',
	'PAGE_MODEL_UPDATE': 'cq-pagemodel-update',

	/**
	 * Selector for WCM mode state meta property.
	 */
	'_WCM_MODE_META_SELECTOR': 'meta[property="cq:wcmmode"]',

	'TYPE_PROP': PMConstants.TYPE_PROP,
	'ITEMS_PROP': PMConstants.ITEMS_PROP,
	'ITEMS_ORDER_PROP': PMConstants.ITEMS_ORDER_PROP,
	'PATH_PROP': PMConstants.PATH_PROP,
	'CHILDREN_PROP': PMConstants.CHILDREN_PROP,
	'HIERARCHY_TYPE_PROP': PMConstants.HIERARCHY_TYPE_PROP,
	'JCR_CONTENT': PMConstants.JCR_CONTENT,
};

export {
	Constants,
};
