import { usePassengers } from '~composables/booking-widget/usePassengers.js';
import { i18nGlobal } from '~/logic/i18n.js';

const MAX_TOTAL_ADULT_COMBINED_CHILD = 9;
const MAX_TOTAL_INFANT = 3;

const MAX_ADULT_CHILD_COMBINED_COUNT = 9;
const MAX_ADULT_COUNT = 9;
const MAX_CHILD_COUNT = 6;
const MAX_INFANT_COUNT = 3;


/**
 * 
 * @param {object} errorMessages
 * combinedAnyExceeded, combinedAdultChildExceeded, combinedInfantExceeded
 */
export function useTravellerAndRoom ({ errorMessages = {} } = {}) {
	const rooms = ref([]);
	
	const createRoom = () => {
		rooms.value.push({
			...toRefs(reactive(usePassengers({
				maxAdultsCount: 9,
				maxChildrenCount: 6,
				maxInfantsCount: 3,
			}))),
		});
	};
	
	const deleteRoom = (index) => {
		rooms.value.splice(index, 1);
	};
	
	createRoom();
	
	const totalAdultChildCombinedCount = computed(() => {
		return rooms.value.reduce((acc, room) => {
			return acc + unref(room.adults.count) + unref(room.children.count);
		}, 0);
	});
	const totalInfantCount = computed(() => {
		return rooms.value.reduce((acc, room) => {
			return acc + unref(room.infants.count);
		}, 0);
	});
	
	const hasCombinedAdultChildExceeded = computed(() => {
		return totalAdultChildCombinedCount.value > MAX_TOTAL_ADULT_COMBINED_CHILD;
	});
	const hasCombinedInfantExceeded = computed(() => {
		return totalInfantCount.value > MAX_TOTAL_INFANT;
	});
	const hasCombinedAnyExceeded = computed(() => {
		return hasCombinedAdultChildExceeded.value || hasCombinedInfantExceeded.value;
	});
	
	const errorMessagesInterpolated = Object.fromEntries(Object.entries(errorMessages).map(([key, value]) => {
		let nValue = '';
		switch (key) {
			case 'combinedAnyExceeded': {
				nValue = MAX_TOTAL_ADULT_COMBINED_CHILD + MAX_TOTAL_INFANT;
				break;
			}
			case 'combinedAdultChildExceeded': {
				nValue = MAX_TOTAL_ADULT_COMBINED_CHILD;
				break;
			}
			case 'combinedInfantExceeded': {
				nValue = MAX_TOTAL_INFANT;
				break;
			}
			default: {
				break;
			}
		}
		return [
			key,
			value.replaceAll('{n}', nValue),
		];
	}));
	
	const dropdownValidationFunc = () => {
		if (hasCombinedAdultChildExceeded.value && hasCombinedInfantExceeded.value) {
			return errorMessagesInterpolated.combinedAnyExceeded || '';
		}
		if (hasCombinedAdultChildExceeded.value) {
			return errorMessagesInterpolated.combinedAdultChildExceeded || '';
		}
		if (hasCombinedInfantExceeded.value) {
			return errorMessagesInterpolated.combinedInfantExceeded || '';
		}
		return true;
	};
	
	return {
		rooms,
		createRoom,
		deleteRoom,
		
		totalAdultChildCombinedCount,
		totalInfantCount,
		
		hasCombinedAdultChildExceeded,
		hasCombinedInfantExceeded,
		hasCombinedAnyExceeded,
		
		dropdownValidationFunc,
	};
}
