import { getHashId } from '~/logic/helpers/utils.js';

export default function useGenerateIdentifier (base, options = {}) {
	
	const finalOptions = {
		appendRandomHash: true,
		...options,
	};
	
	const randomHash = getHashId();
	
	return {
		errorMsgId: computed(() => `${unref(base)}-err-msg-container-${finalOptions.appendRandomHash ? randomHash : ''}`),
		labelledbyId: computed(() => `${unref(base)}-labelledby-${finalOptions.appendRandomHash ? randomHash : ''}`),
		describedbyId: computed(() => `${unref(base)}-describedby-${finalOptions.appendRandomHash ? randomHash : ''}`),
		detailsId: computed(() => `${unref(base)}-details-${finalOptions.appendRandomHash ? randomHash : ''}`),
	};
}
