import { createGlobalState } from '@vueuse/core';
import usePersistencyWithExpiry from '~/logic/composables/usePersistencyWithExpiry.js';
import { deleteCookie } from '~/logic/helpers/cookies.js';
import { useRouter, useRoute } from 'vue-router';
import { sendPageView } from '~/logic/adobe-analytic.js';

export const useGlobalCookieSettings = createGlobalState(
	() => {
		const _isEnabledCookiePreferences = ref(null);
		const _isEnabledCookieStatistics = ref(null);
		const _isEnabledCookieMarketing = ref(null);
		
		const _preferencesCookieNames = new Set();
		
		const adobeAnalyticJsUrl = ref('');
		let _hasAAInitiated = false;
		
		const injectAdobeAnalyticScript = (_scriptUrl) => {
			const scriptUrl = _scriptUrl || adobeAnalyticJsUrl.value;
			const scriptEl = document.createElement('script');
			scriptEl.setAttribute('data-is-aa-script', 'true');
			scriptEl.setAttribute('src', scriptUrl);
			document.body.appendChild(scriptEl);
		};
		const initAdobeAnalytic = () => {
			if (_hasAAInitiated) return;
			_hasAAInitiated = true;
			
			injectAdobeAnalyticScript();
			
			window.router.afterEach((to, from) => {
				sendPageView();
			});
		};
		
		const isEnabledCookiePreferences = computed({
			get () { return _isEnabledCookiePreferences.value; },
			set (newValue) {
				const prevValue = _isEnabledCookiePreferences.value;
				if (!newValue && prevValue === null) {
					// null -> false
					for (const key of _preferencesCookieNames) {
						deleteCookie(key);
					}
				} else if (newValue && prevValue === null) {
					// null -> true
					// do nothing for now
				}
				_isEnabledCookiePreferences.value = newValue;
			},
		});
		const isEnabledCookieStatistics = computed({
			get () { return _isEnabledCookieStatistics.value; },
			set (newValue) {
				const prevValue = _isEnabledCookieStatistics.value;
				if (!newValue && prevValue) {
					// true -> false
					
				} else if (newValue && !prevValue) {
					// false -> true
					if (!adobeAnalyticJsUrl.value) {
						// console.warn('adobeAnalyticJsUrl not set');
					} else {
						initAdobeAnalytic();
					}
				}
				_isEnabledCookieStatistics.value = newValue;
			},
		});
		const isEnabledCookieMarketing = computed({
			get () { return _isEnabledCookieMarketing.value; },
			set (newValue) {
				const prevValue = _isEnabledCookieMarketing.value;
				if (!newValue && prevValue) {
					// true -> false
					// do nothing for now
				} else if (newValue && !prevValue) {
					// false -> true
					// do nothing for now
				}
				_isEnabledCookieMarketing.value = newValue;
			},
		});
		
		
		const persistency = usePersistencyWithExpiry('cookies-notification:cookie-settings', {
			expiryDuration: 7.776e+9, // <-- 90 days
			autoWrite: false,
			refs: {
				isEnabledCookiePreferences: isEnabledCookiePreferences,
				isEnabledCookieStatistics: isEnabledCookieStatistics,
				isEnabledCookieMarketing: isEnabledCookieMarketing,
			},
		});
		
		
		const registerPreferenceCookie = (cookieName) => {
			_preferencesCookieNames.add(cookieName);
		};
		
		return {
			adobeAnalyticJsUrl,
			persistency,
			isEnabledCookiePreferences,
			isEnabledCookieStatistics,
			isEnabledCookieMarketing,
			registerPreferenceCookie,
			injectAdobeAnalyticScript,
			initAdobeAnalytic,
		};
	},
);

window.useGlobalCookieSettings = useGlobalCookieSettings;
