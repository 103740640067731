<script>
export default {
	name: 'ContainerBatikPattern',
};
</script>

<script setup>
const props = defineProps({
	topEmptySpaceBgColor: { type: String, default: '#FFF' },
	btmEmptySpaceBgColor: { type: String, default: '#FFF' },
	containerBgColor: { type: String, default: '#1F4074' },
	contentBgColor: { type: String, default: 'transparent' },
	
	useBatikPattern: { type: Boolean, default: true },
	useBatikBlueMag: { type: Boolean, default: false },
	useBrighterBatikBlueMag: { type: Boolean, default: false },
	useBatikTealMag: { type: Boolean, default: false },
	useBrighterBatikTealMag: { type: Boolean, default: false },

	useBottomRoundedCorner: { type: Boolean, default: true },
	useCornerBand: { type: Boolean, default: false },
	useBrighterBatik: { type: Boolean, default: false },
	useFloralPattern: { type: Boolean, default: false },
	applyShadow: { type: Boolean, default: false },
	topCurveStyle: { type: String, default: '' },
	bottomCurveStyle: { type: String, default: '' },
	
	useGreyHibiscusPattern: { type: Boolean, default: false },
	useOrangeHibiscusPattern: { type: Boolean, default: false },
	
	useBlueIslamicPattern: { type: Boolean, default: false },
	useTealIslamicPattern: { type: Boolean, default: false },
});
const emit = defineEmits([
	// 'update:modelValue',
]);


const computedTopEmptySpaceBgColor = computed(() => {
	return props.topEmptySpaceBgColor;
});
const computedBtmEmptySpaceBgColor = computed(() => {
	return props.btmEmptySpaceBgColor;
});
const computedContainerBgColor = computed(() => {
	return props.containerBgColor;
});
const computedContentBgColor = computed(() => {
	return props.contentBgColor;
});

</script>

<template>
<div
	class="ContainerBatikPattern"
	:class="{
		'pb-8': props.applyShadow,
	}"
	:style="{
		'--btmEmptySpaceColor': computedBtmEmptySpaceBgColor,
	}"
>
	<div
		class="container-inner z-30"
		:class="[
			{
				'use-batik-pattern': props.useBatikPattern,
				'use-batik-songket-blue-mag': props.useBatikBlueMag,
				'use-brighter-batik-songket-blue-mag': props.useBrighterBatikBlueMag,
				'use-batik-songket-teal-mag': props.useBatikTealMag,
				'use-brighter-batik-songket-teal-mag': props.useBrighterBatikTealMag,
				'use-corner-band': props.useCornerBand,
				'use-brighter-batik': props.useBrighterBatik,
				'use-floral-pattern': props.useFloralPattern,
				'use-hibiscus-pattern use-grey-hibiscus-pattern': props.useGreyHibiscusPattern,
				'use-hibiscus-pattern use-orange-hibiscus-pattern': props.useOrangeHibiscusPattern,
				'use-blue-islamic-pattern': props.useBlueIslamicPattern,
				'use-teal-islamic-pattern': props.useTealIslamicPattern,
			},
			`use-curve-style-top--${props.topCurveStyle}`,
			`use-curve-style-bottom--${props.bottomCurveStyle}`,
		]"
		:style="{
			'background-color': computedContainerBgColor,
			'--topEmptySpaceColor': computedTopEmptySpaceBgColor,
		}"
	>

		<div class="section-breaker pointer-events-none" aria-hidden="true">
			<div class="left"></div>
			<div class="right"></div>
		</div>

		<div
			class="container-content z-30 h-full"
			:style="{
				'background-color': computedContentBgColor,
			}"
		>
			<div class="generic-container">
				<slot></slot>
			</div>
		</div>
		
		<div
			v-if="props.useCornerBand"
			class="corner-band pointer-events-none"
			aria-hidden="true"
		></div>
	</div>
	<div v-if="props.applyShadow" class="shadow-layer z-10"></div>
	<div v-if="props.applyShadow" class="shadow-masking-layer z-20"></div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.ContainerBatikPattern {
	--breakerHeight: 72px;
	position: relative;
	background-color: var(--btmEmptySpaceColor);
	// overflow-x: hidden; <-- Disabled this to prevent components inside container cut off such datepicker floating panel

	@media #{var.$query-max-md} {
		--breakerHeight: 42px;
	}
}

.container-inner {
	// overflow: hidden; <-- Disabled this to prevent component inside cantainer cut off such datepicker floating panel
	padding-top: 15px;
	padding-bottom: 15px;

	position: relative;
	/* --topEmptySpaceColor: 'transparent';
	--btmEmptySpaceColor: 'transparent'; */
	
	&.use-batik-pattern {
		--batik-pattern-img: url('~/assets/batik-pattern.svg');
		background-image: var(--batik-pattern-img);
		background-repeat: repeat;
		position: relative;

		&.use-batik-songket-blue-mag {
			--batik-pattern-img: url('~/assets/batik-pattern-songket-blue-mag.svg');
		}

		&.use-brighter-batik-songket-blue-mag {
			--batik-pattern-img: url('~/assets/batik-pattern-songket-blue-mag-brighter.svg');
		}

		&.use-batik-songket-teal-mag {
			--batik-pattern-img: url('~/assets/batik-pattern-songket-teal-mag.svg');
		}
		
		&.use-brighter-batik-songket-teal-mag {
			--batik-pattern-img: url('~/assets/batik-pattern-songket-teal-mag-brighter.svg');
		}

		&.use-floral-pattern {
			--batik-pattern--floral-img: url('~/assets/batik-pattern-floral-2.svg');
			padding-top: calc(var(--breakerHeight) * 2.5);
			padding-bottom: calc(var(--breakerHeight) * 1.5);
			overflow-y: hidden;
			
			&::before,
			&::after {
				content: '';
				position: absolute;
				background-image: url('~/assets/batik-pattern-floral-2.svg');
				mix-blend-mode: luminosity;
				opacity: 0.12;
				z-index: -1;
				background-size: 350px;
				background-repeat: repeat;
			}
			&::before {
				top: 40px;
				width: 100%;
				height: 80%;
			}
			&::after {
				bottom: -70px;
				width: 98%;
				height: 52%;
			}
		}
		
		&.use-brighter-batik {
			--batik-pattern-img: url('~/assets/batik-pattern-brighter.svg');
		}
	}

	&.use-corner-band {
		border-bottom-right-radius: 0;
	}
	
		
	// MHH Hibiscus pattern
	&.use-hibiscus-pattern {
		background-size: contain;
		background-position: bottom;
		background-repeat: no-repeat;
		
		&.use-grey-hibiscus-pattern {
			--hibiscus-pattern-img: url('~/assets/layout-container/grey-hibiscus-dsk.svg');
			background-image: var(--hibiscus-pattern-img);
			
			@media #{var.$query-max-md} {
				--hibiscus-pattern-img: url('~/assets/layout-container/grey-hibiscus-mob.svg');
			}
		}
		
		&.use-orange-hibiscus-pattern {
			--hibiscus-pattern-img: url('~/assets/layout-container/orange-hibiscus-dsk.svg');
			background-image: var(--hibiscus-pattern-img);
			
			@media #{var.$query-max-md} {
				--hibiscus-pattern-img: url('~/assets/layout-container/orange-hibiscus-mob.svg');
			}
		}
	}
	
	// AMAL Islamic pattern
	&.use-blue-islamic-pattern {
		--islamic-pattern-img: url('~/assets/layout-container/amal/bg-islamic-blue-pattern.svg');
		background: linear-gradient(to top, rgb(3, 1, 93, 0.9) 10%, rgba(10, 7, 136, 0.1) 100%, rgb(15,91,161) 90%);
		overflow: hidden;
		
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			background: var(--islamic-pattern-img);
			background-repeat: no-repeat no-repeat;
			background-position: 0% 100%;
			background-size: 1200px;
			width: 100%;
			height: 1200px;
			overflow: hidden;
			border-radius: inherit;
			z-index: -1;
			bottom: -455px;
			left: -740px;
			
			@media #{var.$query-max-xl} {
				bottom: -400px;
				left: -640px;
				height: 1024px;
				background-size: 1024px;
			}

			@media #{var.$query-max-lg} {
				bottom: -304px;
				left: -327px;
				height: 780px;
				background-size: 768px;
			}

			@media #{var.$query-max-sm} {
				background-size: cover;
				background-position: -421px 301px;
				left: 0;
				bottom: 0;
			}
		}
	}
	
	&.use-teal-islamic-pattern {
		--islamic-pattern-img: url('~/assets/layout-container/amal/bg-islamic-teal-pattern-dsk.svg');
		background: linear-gradient(to bottom, rgba(234, 248, 248, 0.9) 10%, rgba(234, 248, 248, 0.1) 100%), var(--islamic-pattern-img);
		background-repeat: repeat no-repeat;
		background-position: bottom;
		background-size: contain;
		position: relative;
		
		@media #{var.$query-max-md} {
			--islamic-pattern-img: url('~/assets/layout-container/amal/bg-islamic-teal-pattern-mob.svg');
		}
	}
}


.corner-band {
	position: absolute;
	width: 164px;
	height: 108px;
	right: 0;
	bottom: -1px;
	overflow: hidden;

	@media #{var.$query-max-md} {
		width: 100px;
		height: 65px;
		bottom: -2px;
	}
	
	&::before {
		content: '';
		background-color: var(--btmEmptySpaceColor);
		width: 164px;
		height: 108px;
		position: absolute;
		left: 0;
		top: 0;
		clip-path: polygon(3% 100%, 100% 100%, 100% 9%, 50% 65%);
		@media #{var.$query-max-md} {
			height: 63px;
			clip-path: polygon(5% 103%, 92% 100%, 100% -53%, 51% 55%);
		}
	}
	
	&::after {
		content: '';
		background: url('~/assets/batik-corner-band.png') no-repeat 100% 100%/164px auto;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		@media #{var.$query-max-md} {
			left: 5px;
			background: url('~/assets/batik-corner-band.png') no-repeat 100% 100%/100px auto;
		}
	}
}



.shadow-layer {
	width: 100%;
	height: calc(100% - (var(--breakerHeight) * 1.5));
	min-height: var(--breakerHeight);
	border-bottom-left-radius: var(--breakerHeight);
	border-bottom-right-radius: var(--breakerHeight);
	border-top-right-radius: var(--breakerHeight);
	position: absolute;
	background-color: #FFF;
	filter: drop-shadow(0 4px 12px color.change(#000, $alpha: 0.21)); // <-- shadow by spec
	/* filter: drop-shadow(0 4px 6px color.change(#000, $alpha: 0.9)); */ // <-- shadow made obvious
	bottom: 2rem;
	left: 0;
	
	&::after {
		content: '';
		position: absolute;
		width: 144px;
		height: 144px;
		left: 0;
		top: -144px;
		background-color: #FFF;
		clip-path: path('M 0 72 A 90,72 0,0,0 90 144 L 0 144 z');
	}
}

.shadow-masking-layer {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, 120%);
	width: var(--breakerHeight);
	height: var(--breakerHeight);
	border-radius: 50%;
	background-color: #FFF;
	filter: blur(1rem);
}
.use-curve-style-top--bothRoundedUp {
	border-top-left-radius: var(--breakerHeight);
    border-top-right-radius: var(--breakerHeight);

	.section-breaker {
		width: 100%;
		height: var(--breakerHeight);
		display: flex;
		align-items: flex-end;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		
		@media #{var.$query-max-md} {
			// resolve unintended gap
			margin-top: -1px;
		}
		
		.left,
		.right {
			position: absolute;
			right: 0;
			top: 0px;
			display: block;
			width: 144px;
			height: 144px;
			background-color: var(--topEmptySpaceColor);
			clip-path: path('M 72 0 A 72,72 0,0,1 144 72 L 145 0 z');
			overflow: hidden;
			
			// resolve unintended gap
			margin-top: -0.5px;
			
			@media #{var.$query-max-md} {
				width: 42px;
				height: 42px;
				clip-path: path('M0,0c23.2,0,42,18.8,42,42V0H0z');
				// resolve unintended gap
				overflow: hidden;
				margin-top: 1px; // <-- Get rid of line gap on mobile
			}
			
			&::before {
				content: '';
				width: 100%;
				height: 4px;
				background-color: var(--topEmptySpaceColor);
				position: absolute;
				top: -2px;
				left: 0;
				z-index: 10;
			}
			&::after {
				content: '';
				width: 2px;
				height: 100%;
				background-color: var(--topEmptySpaceColor);
				position: absolute;
				top: 0;
				right: -1px;
				z-index: 10;
			}
		}

		.left {
			transform: scaleX(-1);
			left: 0;
			right: auto;
		}
	}

	&.container-inner {
		padding-top: var(--breakerHeight);
	}
}

.use-curve-style-bottom--bothRoundedDown {
	border-bottom-left-radius: var(--breakerHeight);
	border-bottom-right-radius: var(--breakerHeight);
	&.container-inner {
		padding-bottom: var(--breakerHeight);
	}
}

.use-curve-style-top--movingForward {
	.section-breaker {
		width: 100%;
		height: var(--breakerHeight);
		display: flex;
		align-items: flex-end;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		
		@media #{var.$query-max-md} {
			// resolve unintended gap
			margin-top: -1px;
		}
		
		
		.left {
			position: relative;
			display: block;
			width: 100%;
			height: var(--breakerHeight);
			
			&::after {
				content: '';
				width: 100%;
				height: 100%;
				background-color: var(--topEmptySpaceColor);
				position: absolute;
				right: 0;
				bottom: 0;
				border-bottom-left-radius: var(--breakerHeight);
			}
		}

		.right {
			position: absolute;
			right: 0;
			top: 100%;
			display: block;
			width: 144px;
			height: 144px;
			background-color: var(--topEmptySpaceColor);
			clip-path: path('M 72 0 A 72,72 0,0,1 144 72 L 145 0 z');
			overflow: hidden;
			
			// resolve unintended gap
			margin-top: -0.5px;
			
			@media #{var.$query-max-md} {
				width: 42px;
				height: 42px;
				clip-path: path('M0,0c23.2,0,42,18.8,42,42V0H0z');
				// resolve unintended gap
				overflow: hidden;
				margin-top: -1px; // <-- Get rid of line gap on mobile
			}
			
			&::before {
				content: '';
				width: 100%;
				height: 4px;
				background-color: var(--topEmptySpaceColor);
				position: absolute;
				top: -2px;
				left: 0;
				z-index: 10;
			}
			&::after {
				content: '';
				width: 2px;
				height: 100%;
				background-color: var(--topEmptySpaceColor);
				position: absolute;
				top: 0;
				right: -1px;
				z-index: 10;
			}
		}
	}

	&.container-inner {
		padding-top: calc(var(--breakerHeight) * 2);
	}

}



</style>
