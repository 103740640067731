<script>
export default {
	name: 'VueTabs',
};
</script>

<script setup>
import { Comment, Text } from 'vue';
import useSyncURLQuery from '~/logic/composables/useSyncURLQuery.js';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import { useRoute } from 'vue-router';
import VueSwiper from '~/components/VueSwiper.vue';

/* Usage:
	Template name that starts with "tab-" is a proper tab.
	Template name that starts with "link-" is html that just get rendered to the tab area.
*/
const props = defineProps({
	identifier: { type: String, default: '' }, // needed for 'syncURLQuery' props to know how to update the URL
	modelValue: { type: String, default: null }, // controls which panel to show
	renderPanelsWithVShow: { type: Boolean, default: false }, // control whether to render all panels and use display: none, or use v-if
	syncURLQuery: { type: Boolean, default: false }, // control whether any changes to 'modelValue' will reflect to URL query
	urlQueryAsDefaultValue: { type: Boolean, default: true }, // control whether to respect or ignore URL query on setup
	disabledTabs: { type: Array, default: () => [] },
	dormantMode: { type: Boolean, default: false }, // when true, tabs functionality won't turn on
	hideTabs: { type: Boolean, default: false },
});

const route = useRoute();
const slots = useSlots();
const btnTabs = ref(new Map());
const internalValue = useInternalValue();
const siteName = window.siteName;

const swiperComponent = ref(null);

if (props.urlQueryAsDefaultValue && route.query[props.identifier]) {
	internalValue.value = route.query[props.identifier];
}


if (props.syncURLQuery) {
	if (!props.identifier) {
		console.warn(`<VueTabs>: Prop 'syncURLQuery' is true, but prop 'identifier' is not provided. URL query will not be updated.`);
	} else {
		useSyncURLQuery(props.identifier, internalValue, {
			toggleRef: toRef(reactive(props), 'syncURLQuery'),
		});
	}
}


const getTabTypeByKey = (key) => {
	if (key.startsWith('tab-')) return 'TAB';
	if (key.startsWith('link-')) return 'LINK';
	return 'UNKNOWN';
};


const tabList = computed(() => {
	const keys = Object.keys(slots);
	return keys.filter((key) => key !== 'default').map((key) => {
		
		return {
			key,
			tabType: getTabTypeByKey(key),
			tabName: key.split(/-(.*)/s).at(-2),
		};
	});
	// return slots.default?.().map((vNode) => vNode.props.identifier) ?? [];
});
const currentActiveIndex = computed(() => {
	return tabList.value.findIndex((tabName) => tabName === internalValue.value);
});
const defaultSlotsFiltered = computed(() => {
	const defaultSlots = slots.default?.();
	if (!defaultSlots) return [];
	return defaultSlots.filter((vNode) => {
		// filter away comment nodes
		if (typeof vNode.type === 'symbol' && vNode.type === Comment) {
			return false;
		}
		// ignore text nodes
		if (typeof vNode.type === 'symbol' && vNode.type === Text) {
			console.warn(`VueTabs.vue: Encountered a text node as slot, ignored. Make sure all children of <VueSwiper> are wrapped with a HTML element.`);
			return false;
		}
		return true;
	});
});
const panelList = computed(() => {
	internalValue.value; // eslint-disable-line
	
	if (props.renderPanelsWithVShow) {
		// give all panels
		return defaultSlotsFiltered.value;
	} else {
		// give only the panel of the 'internalValue'
		const correspondingSlot = defaultSlotsFiltered.value.find((vNode) => {
			return vNode.props.identifier === internalValue.value;
		});
		return correspondingSlot ? [correspondingSlot] : [];
	}
});



const activatePrev = (event) => {
	const newIndex = (
		currentActiveIndex.value === 0 ?
			(tabList.value.length - 1) :
			(currentActiveIndex.value - 1)
	);
	const newTabName = tabList.value[newIndex];
	btnTabs.value.get(newTabName).focus();
	internalValue.value = newTabName;
};
const activateNext = (event) => {
	const newIndex = (
		(currentActiveIndex.value === (tabList.value.length - 1)) ?
			0 :
			(currentActiveIndex.value + 1)
	);
	const newTabName = tabList.value[newIndex];
	btnTabs.value.get(newTabName).focus();
	internalValue.value = newTabName;
};


const handleTabClick = (tabName, event) => {
	internalValue.value = tabName;
};

if (props.modelValue === null) {
	// since this is an uncontrolled <VueTabs>, here we set active panel to first panel
	internalValue.value = defaultSlotsFiltered.value[0]?.props.identifier ?? null;
}

const scrollToTab = (tabName) => {
	const swiperInstance = swiperComponent.value.swiperInstance;
	if (!swiperInstance) return;
	const slideIndex = tabList.value.findIndex((tab) => tab.tabName === tabName);
	swiperInstance.slideTo(slideIndex);
};

onMounted(() => {
	const swiperWrappers = document.querySelectorAll('.VueTabs .swiper-wrapper');

	swiperWrappers.forEach((swiper) => {
		swiper.setAttribute('role', 'tablist');
	});
});

defineExpose({
	scrollToTab,
});

</script>

<template>
<div
	class="VueTabs"
	:data-use-theme="siteName"
>
	<VueSwiper
		v-if="!props.hideTabs"
		ref="swiperComponent"
		class="relative z-10 !inline-flex !md:flex"
		variant="slides-auto-width"
		slidesPerView="auto"
		:breakpoints="{
			'767.1': {
				allowTouchMove: false,
			},
		}"
		:isTabRole="true"
	>
		<template
			v-for="{ key, tabType, tabName } in tabList"
			:key="tabName"
		>
			<button
				v-if="tabType === 'TAB'"
				:ref="el => { if (el) btnTabs.set(tabName, el) }"
				class="tab-button p-0"
				:class="{
					'is-active': internalValue === tabName,
				}"
				type="button"
				:ariaSelectedVal="internalValue === tabName"
				:ariaControlsVal="tabName"
				:disabled="props.disabledTabs.includes(tabName)"
				@click="handleTabClick(tabName, $event)"
				@keydown.self.left.prevent="activatePrev"
				@keydown.self.up.prevent="activatePrev"
				@keydown.self.right.prevent="activateNext"
				@keydown.self.down.prevent="activateNext"
			>
				<slot :name="key" :tabName="tabName">
					<span class="tab-button-text">{{ tabName }}</span>
				</slot>
			</button>
			
			<slot
				v-if="tabType === 'LINK'"
				:name="key"
				:tabName="tabName"
			></slot>
		</template>
	</VueSwiper>
	<div class="tab-panels-container relative">
		<template v-if="props.dormantMode">
			<template
				v-for="(tabVNode, index) in defaultSlotsFiltered"
				:key="tabVNode.props.identifier"
			>
				<div
					class="parsys-section-indicator mt-4"
					:data-index="index"
				>
					<span class="label flex items-center">
						<icon-far-arrow-down class="mr-1.5 fill-current" />
						<span>{{ tabVNode.props.identifier }}</span>
						<icon-far-arrow-down class="ml-1.5 fill-current" />
					</span>
				</div>
				<component
					:is="tabVNode"
					:id="tabVNode.props.identifier"
					role="tabpanel"
					:aria-labelledby="tabVNode.props.identifier"
				/>
				<div
					class="parsys-section-indicator mb-4"
					:data-index="index"
				>
					<span class="label flex items-center">
						<icon-far-arrow-up class="mr-1.5 fill-current" />
						<span>{{ tabVNode.props.identifier }}</span>
						<icon-far-arrow-up class="ml-1.5 fill-current" />
					</span>
				</div>
			</template>
		</template>
		
		<transition
			v-else-if="panelList.length === 1"
			name="__ANIMATION_OFF"
			tag="span"
			appear
		>
			<component
				:is="panelList[0]"
				:id="panelList[0].props.identifier"
				:key="panelList[0].props.identifier"
				role="tabpanel"
				:aria-labelledby="panelList[0].props.identifier"
			/>
		</transition>
		
		<template v-else-if="panelList.length > 1">
			<transition
				v-for="tabVNode in panelList"
				:key="tabVNode.props.identifier"
				name="__ANIMATION_OFF"
			>
				<component
					:is="tabVNode"
					v-show="tabVNode.props.identifier === internalValue"
					:id="tabVNode.props.identifier"
					
					role="tabpanel"
					:aria-labelledby="tabVNode.props.identifier"
				/>
			</transition>
		</template>
	</div>
</div>
</template>

<style lang="scss">
[data-use-theme="MHH"] {
	.VueTabs {
		.tab-button,
		.AppHyperlink {
			border-radius: 2rem;

			&:hover {
				color: var(--primary-mhh-teal-base);
			}
		}

		.tab-button {
			&.is-active {
				background-color: var(--primary-mhh-teal-base);
				color: white;

				&:hover {
					background-color: var(--primary-mhh-teal-base);
					color: white !important;
				}
			}

			&:not(:disabled):hover {
				color: var(--primary-mhh-teal-base);
			}
		}
	}

}

[data-use-theme="firefly"] {
	.VueTabs {
		.tab-button,
		.AppHyperlink {
			border-radius: 1rem;

			&:hover {
				color: var(--primary-firefly-orange-base);
			}
		}

		.tab-button {
			&.is-active {
				background-color: var(--primary-firefly-orange-base);
				color: var(--secondary-firefly-black-base);

				&:hover {
					background-color: var(--primary-firefly-orange-base);
					color: var(--neutral-white-base) !important;
				}
			}

			&:not(:disabled):hover {
				color: var(primary-firefly-orange-base);
			}
		}
	}

}

</style>


<style scoped lang="scss">
@use '~/styles/partials/_var.scss';
@use 'sass:color';

.VueTabs {
	/* overflow: hidden; */
	
	:deep(.swiper-wrapper) {
		.swiper-slide {
			height: auto;
			margin-right: 0;
			padding: 2px;
		}
		
		.AppHyperlink {
			--focus-visible-outline-offset: 0px;
		}
	}
}

.tab-button {
	min-width: 80px;
	font-weight: 600;
	line-height: 1;
	border-radius: 0.75rem;
	transition: all 0.3s;
	--focus-visible-outline-offset: 0px;
	
	&:not(:disabled):hover {
		background-color: var(--neutral-grey-extralight);
	}
	&.is-active {
		background-color: var(--primary-blue-extralight);
		color: var(--primary-blue-base);
		
		&:hover {
			background-color: var(--primary-blue-extralight);
		}
	}
	&:disabled {
		cursor: default;
	}
}



.slide-fade-enter-active {
	transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.2s ease-out;
	position: absolute;
	top: 0;
	width: 100%;
	transform: translateX(0px);
}

.slide-fade-enter-from {
	transform: translateX(60px);
	opacity: 0;
}

.slide-fade-leave-to {
	transform: translateX(-60px);
	opacity: 0;
}



</style>
