<script>
export default {
	name: 'HotelPicker',
};
</script>

<script setup>
import Dropdown from '~/components/form/Dropdown.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import { i18nGlobal } from '~/logic/i18n.js';
import AirportPicker from '~/components/BookingWidget/AirportPicker.vue';
import useInternalValue from '~/logic/composables/useInternalValue.js';
import { debounce as _debounce } from 'lodash';
import AppSpinner from '~/components/AppSpinner.vue';

const props = defineProps({
	apiUrl: { type: String, required: true },
	modelValue: { type: [String, Object], default: null },
	hotelSelection: { type: Object, default: null },
	
	isDisabled: { type: Boolean, default: false },
	
	// Props passed by parent component; MHHAirportTransfer
	currentAirportCode: { type: String, default: '' },
	clearOnBlur: { type: Boolean, default: false },

	useTUIAPI: { type: Boolean, default: false },
});
const emit = defineEmits([
	'update:modelValue',
]);

const internalValue = useInternalValue('modelValue');

// base list is the data without any keyword
const baseList = ref(null);
const searchList = ref(null);
const keyword = ref('');
const isLoading = ref(true);
const isLoadingFurtherPages = ref(false);
const basePageIndex = ref(0);
const searchPageIndex = ref(0);
const isBaseLastPage = ref(false);
const isSearchLastPage = ref(false);

const airportPickerEl = ref(null);

// Store previous airport code to compare with current once updated.
let prevAirportCode = '';

const mappedHotelInfoObject = (list = []) => {
	return list.map((obj) => {
		if (props.useTUIAPI) {
			const valueId = obj.type === 'hotel' ? obj.cityCode : obj.id;
			return {
				value: `${valueId}|${obj.latitude}|${obj.longitude}`,
				label: obj.name.trim(),
				searchString: obj.name.trim(),
				description: obj.countryName,
				regionName: obj.regionName,
				name: obj.name,
				countryName: obj.countryName,
				cityCode: obj.cityCode,
				cityId: obj.id,
				type: obj.type,
				id: obj.id,
			};
		}
		
		return {
			value: `${obj.airportCode}|${obj.latitude},${obj.longitude}`,
			label: obj.hotelName.split(',')[0].trim(),
			searchString: obj.hotelName,
			description: obj.countryName,
			cityId: obj.hotelId,
			isPopularFlag: obj.isPopularFlag,
			isSuggestedFlag: obj.isSuggestedFlag,
		};
	});
};

const setPreselectHotel = async ({ airportCode, hotelId }) => {
	let result = null;
	isLoading.value = true;
	try {
		result = await getHotelList({ startIndex: 0, endIndex: 1, airportCode, hotelId });
	} catch (err) {
		//
	} finally {
		isLoading.value = false;
	}

	if (result.length) {
		internalValue.value = result[0];
	}
};

const getHotelList = async ({ searchKeyword, airportCode, hotelId, startIndex = 0, endIndex = 100 } = {}) => {
	let apiResponse = null;
	const _airportCode = airportCode || props.currentAirportCode;
	try {
		// TODO: New Hotel API
		apiResponse = await axios.get(props.apiUrl, {
			params: {
				...(!props.useTUIAPI ? { start: startIndex } : null),
				...(!props.useTUIAPI ? { end: startIndex } : null),
				...(searchKeyword ? (props.useTUIAPI ? { term: searchKeyword } : { key: searchKeyword }) : null),
				...(_airportCode && !searchKeyword ? { aCode: _airportCode } : null),
				...(hotelId && !searchKeyword ? { hId: hotelId } : null),
			},
		});
		// apiResponse = await axios.get('https://run.mocky.io/v3/66ae9891-d078-42a6-b496-254e5a181643'); // <-- enable for empty content
	} catch (err) {
		console.error('getHotelList, ', 'Unable to fetch api', JSON.stringify(err));
		return [];
	}
	const responseData = props.useTUIAPI ? JSON.parse(apiResponse.data) : apiResponse.data.hotelList;
	return mappedHotelInfoObject(responseData);
};

const debouncedGetSearchHotelList = _debounce(async ({ startIndex, endIndex, searchKeyword } = {}) => {
	let result = null;
	try {
		result = await getHotelList({ startIndex, endIndex, searchKeyword });
	} catch (err) {
		//
	} finally {
		isLoading.value = false;
		isLoadingFurtherPages.value = false;
	}
	if (result.length < 100) isSearchLastPage.value = true;
	if (!searchList.value) searchList.value = [];
	searchList.value.push(...result);
}, 500);

const debouncedGetBaseHotelList = _debounce(async ({ startIndex, endIndex } = {}) => {
	let result = null;
	try {
		result = await getHotelList({ startIndex, endIndex });
	} catch (err) {
		//
	} finally {
		isLoading.value = false;
		isLoadingFurtherPages.value = false;
	}
	if (result.length < 100) isBaseLastPage.value = true;
	if (!baseList.value) baseList.value = [];
	baseList.value.push(...result);
}, 500);

const resetBaseList = () => {
	baseList.value = baseList.value ? baseList.value.slice(0, 100) : null;
	basePageIndex.value = 0;
	isBaseLastPage.value = false;
};
const resetSearchList = () => {
	searchList.value = null;
	searchPageIndex.value = 0;
	isSearchLastPage.value = false;
};

const handleSearchChange = (searchKeyword) => {
	keyword.value = searchKeyword;
	
	if (searchKeyword && (props.useTUIAPI ? searchKeyword.length >= 3 : searchKeyword.length >= 1) ) {
		resetSearchList();
		isLoading.value = true;
		debouncedGetSearchHotelList({ startIndex: 0, endIndex: 100, searchKeyword });
	} else {
		resetBaseList();
	}
};

const locationOptionsComputed = computed(() => {
	if (isLoading.value) return [];
	
	const popularHotels = [];
	const regularHotels = [];
	const properties = [];
	const destinations = [];
	const airports = [];

	const listing = (keyword.value || props.useTUIAPI) ? searchList.value : baseList.value;
	if (!listing || listing.length === 0) return [];
	
	listing.forEach((item) => {
		if (item.isPopularFlag) {
			popularHotels.push(item);
		} else if (item.type && item.type === 'airport') {
			airports.push(item);
		} else if (item.type && item.type === 'hotel' ) {
			properties.push(item);
		} else if (item.type && item.type === 'region' ) {
			destinations.push(item);
		} else {
			regularHotels.push(item);
		}
		
	});
	
	if (props.useTUIAPI) {
		return [
			{
				label: 'DESTINATION/CITY',
				options: destinations,
			},
			{
				label: 'PROPERTIES',
				options: properties,
			},
			{
				label: 'AIRPORT/STATION',
				options: airports,
			},
		];
	}

	if (popularHotels.length > 0) {
		return [
			{
				label: props.hotelSelection?.popularDestinationsLabelHotel,
				options: popularHotels,
			},
			{
				label: ' ',
				options: regularHotels,
			},
		];
	}
	
	return [
		{
			label: ' ',
			options: regularHotels,
		},
	];
});

const handleOpen = async (instance) => {
	if (props.useTUIAPI) {
		isLoading.value = false; // this is required to avoid baselist spinner loading 
	}
	/* 	
		Compare if previous prevAirportCode equal to current airportCode prop passed 
		by MHHAirportTransfer to filter based on airport code
	*/
	if (prevAirportCode !== props.currentAirportCode) {
		baseList.value = null;
		isLoading.value = true;
		prevAirportCode = props.currentAirportCode;
	}
	
	if (!baseList.value && !props.useTUIAPI) {
		debouncedGetBaseHotelList();
	}
};

const handleClickLoadMore = (event) => {
	if (isLoadingFurtherPages.value) return;
	isLoadingFurtherPages.value = true;
	
	if (keyword.value) {
		// load more search list
		searchPageIndex.value++;
		debouncedGetSearchHotelList({
			startIndex: (100 * searchPageIndex.value) + 1,
			endIndex: (100 * searchPageIndex.value) + 100,
			searchKeyword: keyword.value,
		});
	} else {
		// load more base list
		basePageIndex.value++;
		debouncedGetBaseHotelList({
			startIndex: (100 * basePageIndex.value) + 1,
			endIndex: (100 * basePageIndex.value) + 100,
			searchKeyword: keyword.value,
		});
	}
};

defineExpose({
	airportPickerEl,
	setPreselectHotel,
});

</script>

<template>
<div class="HotelPicker">
	<AirportPicker
		ref="airportPickerEl"
		v-model="internalValue"
		:options="locationOptionsComputed"
		:labelText="props.hotelSelection?.hotelLabel"
		:placeholderText="props.hotelSelection?.hotelPlaceholderText"
		name="location"
		variant="booking-widget"
		:showOptionLocationIcon="true"
		:isGroups="true"
		:noResultsText="props.hotelSelection?.noResultMessageHotel"
		required
		:clearOnBlur="props.clearOnBlur"
		:filterResults="false"
		:disabled="props.isDisabled"
		@open="handleOpen"
		@search-change="handleSearchChange"
	>
		<template #mobile-title>
			<slot name="mobile-title"></slot>
		</template>
		<template v-if="isLoading" #nooptions>
			<div class="multiselect-options cursor-default">
				<div class="py-4">
					<AppSpinner class="mx-auto" />
				</div>
			</div>
		</template>
		<template v-if="!isLoading && locationOptionsComputed.length > 0 && !props.useTUIAPI" #appendToList>
			<div v-if="isLoadingFurtherPages" class="py-4">
				<AppSpinner class="mx-auto" />
			</div>
			<CTAButton
				containerClass="mt-6 mb-4 cursor-default"
				styleType="secondary"
				sizeType="extra-small"
				:disabled="keyword ? isSearchLastPage : isBaseLastPage"
				@click="handleClickLoadMore"
			>
				{{ $t('Load more') }}
			</CTAButton>
		</template>
	</AirportPicker>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.HotelPicker {
	:deep(.AirportPicker){
		.dropdown-caret{
			// display: none;
		}
	}
}
</style>
