
<script>
export default {
	name: 'AppSpinner',
	expose: [],
};
</script>

<script setup>
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
gsap.registerPlugin(MotionPathPlugin);

const props = defineProps({
	size: { type: Number, default: 80 },
});


const rootEl = ref(null);
const rootSvgEl = ref(null);
let animationStorage = [];

const siteName = window.siteName;

const resetContainerDOM = () => {
	rootSvgEl.value.innerHTML = `
		<circle
			class="mainCircle"
			fill="none"
			stroke="none"
			stroke-width="2"
			stroke-miterlimit="10"
			cx="88"
			cy="88"
			r="70"
		/>
		
		<!--
		<circle
			class="planeCirclePath"
			fill="none"
			stroke="none"
			stroke-width="2"
			stroke-miterlimit="10"
			cx="88"
			cy="88"
			r="70"
		/>
		-->
		
		<path
			class="planeCirclePath"
			stroke-miterlimit="10"
			stroke-width="2"
			stroke="none"
			fill="none"
			d="M158,88 C158,126.65993 126.65993,158 88,158 49.34007,158 18,126.65993 18,88 18,49.34007 49.34007,18 88,18 126.65993,18 158,49.34007 158,88 z"
		/>
		
		<g class="mainContainer">
			<g class="plane">
				<svg width="2.5em" height="2.5em" viewBox="0 0 576 512" class="" fill="currentColor"><path d="M482.3 192C516.5 192 576 221 576 256C576 292 516.5 320 482.3 320H365.7L265.2 495.9C259.5 505.8 248.9 512 237.4 512H181.2C170.6 512 162.9 501.8 165.8 491.6L214.9 320H112L68.8 377.6C65.78 381.6 61.04 384 56 384H14.03C6.284 384 0 377.7 0 369.1C0 368.7 .1818 367.4 .5398 366.1L32 256L.5398 145.9C.1818 144.6 0 143.3 0 142C0 134.3 6.284 128 14.03 128H56C61.04 128 65.78 130.4 68.8 134.4L112 192H214.9L165.8 20.4C162.9 10.17 170.6 0 181.2 0H237.4C248.9 0 259.5 6.153 265.2 16.12L365.7 192H482.3z" /></svg>
			</g>
			<circle class="dot" cx="158" cy="88" r="5" fill="currentColor" />
			<circle class="dot" cx="156.875071" cy="100.498983" r="5" fill="currentColor" />
			<circle class="dot" cx="153.536441" cy="112.596238" r="5" fill="currentColor" />
			<circle class="dot" cx="148.091416" cy="123.902949" r="5" fill="currentColor" />
			<circle class="dot" cx="140.715003" cy="134.055711" r="5" fill="currentColor" />
			<circle class="dot" cx="131.644286" cy="142.728204" r="5" fill="currentColor" />
			<circle class="dot" cx="121.170806" cy="149.641687" r="5" fill="currentColor" />
			<circle class="dot" cx="109.63119" cy="154.573956" r="5" fill="currentColor" />
			<circle class="dot" cx="97.396329" cy="157.366483" r="5" fill="currentColor" />
			<circle class="dot" cx="84.859462" cy="157.929515" r="5" fill="currentColor" />
			<circle class="dot" cx="72.423535" cy="156.244954" r="5" fill="currentColor" />
			<circle class="dot" cx="60.488248" cy="152.366944" r="5" fill="currentColor" />
			<circle class="dot" cx="49.437211" cy="146.420128" r="5" fill="currentColor" />
			<circle class="dot" cx="39.625615" cy="138.59564" r="5" fill="currentColor" />
			<circle class="dot" cx="31.36881" cy="129.144968" r="5" fill="currentColor" />
			<circle class="dot" cx="24.932179" cy="118.371862" r="5" fill="currentColor" />
			<circle class="dot" cx="20.5226" cy="106.622579" r="5" fill="currentColor" />
			<circle class="dot" cx="18.281799" cy="94.274752" r="5" fill="currentColor" />
			<circle class="dot" cx="18.281799" cy="81.725248" r="5" fill="currentColor" />
			<circle class="dot" cx="20.5226" cy="69.377421" r="5" fill="currentColor" />
			<circle class="dot" cx="24.932179" cy="57.628138" r="5" fill="currentColor" />
			<circle class="dot" cx="31.36881" cy="46.855032" r="5" fill="currentColor" />
			<circle class="dot" cx="39.625615" cy="37.40436" r="5" fill="currentColor" />
			<circle class="dot" cx="49.437211" cy="29.579872" r="5" fill="currentColor" />
			<circle class="dot" cx="60.488248" cy="23.633056" r="5" fill="currentColor" />
			<circle class="dot" cx="72.423535" cy="19.755046" r="5" fill="currentColor" />
			<circle class="dot" cx="84.859462" cy="18.070485" r="5" fill="currentColor" />
			<circle class="dot" cx="97.396329" cy="18.633517" r="5" fill="currentColor" />
			<circle class="dot" cx="109.63119" cy="21.426044" r="5" fill="currentColor" />
			<circle class="dot" cx="121.170806" cy="26.358313" r="5" fill="currentColor" />
			<circle class="dot" cx="131.644286" cy="33.271796" r="5" fill="currentColor" />
			<circle class="dot" cx="140.715003" cy="41.944289" r="5" fill="currentColor" />
			<circle class="dot" cx="148.091416" cy="52.097051" r="5" fill="currentColor" />
			<circle class="dot" cx="153.536441" cy="63.403762" r="5" fill="currentColor" />
			<circle class="dot" cx="156.875071" cy="75.501017" r="5" fill="currentColor" />
		</g>
	`;
};

const startAnimation = () => {
	const mainTl = gsap.timeline();
	const rootSvg = rootSvgEl.value;
	
	// ---- dots ----
	const dotsTl = [];
	const dotEls = rootSvg.querySelectorAll('.dot');
	const numDots = dotEls.length;
	
	dotEls.forEach((node, index) => {
		const dotTl = gsap.timeline({ repeat: -1 });
		
		dotTl.from(node, {
			duration: 0.2,
			attr: { r: 0 },
			ease: 'power2.easeIn',
		}).to(node, {
			duration: 1.8,
			attr: { r: 0 },
			ease: 'power2.easeOut',
		});
		
		mainTl.add(dotTl, index / (numDots / dotTl.duration()));
		dotsTl.push(dotTl);
	});
	
	
	// ---- plane ----
	const plane = rootSvg.querySelector('.plane');
	const planeCirclePath = rootSvg.querySelector('.planeCirclePath');
	const planeTl = gsap.timeline({ repeat: -1 });
	
	planeTl.to(plane, {
		duration: 2,
		motionPath: {
			type: 'cubic',
			path: planeCirclePath,
			align: planeCirclePath,
			autoRotate: true,
			alignOrigin: [0.5, 0.5],
		},
		ease: 'none',
	});
	
	mainTl.add(planeTl, 0.05);
	
	
	
	// ---- adjust main timeline ----
	mainTl.time(20);
	mainTl.timeScale(1.2);
	
	
	
	// ---- mainContainer ----
	const mainContainer = rootSvg.querySelector('.mainContainer');
	
	const mainContainerTween = gsap.to(mainContainer, {
		duration: 30,
		rotation: -360,
		transformOrigin: '50% 50%',
		repeat: -1,
		ease: 'none',
	});
	
	
	return [
		...dotsTl,
		planeTl,
		mainTl,
		mainContainerTween,
	];
};

const destroyAnimation = () => {
	animationStorage.forEach((t) => t.revert?.());
	animationStorage = [];
	if (rootSvgEl.value) rootSvgEl.value.innerHTML = '';
};

/* 
	setupVisibilityObserver() is the *only* efficient and non-hacky way to properly know
	when an element is rendered by the browser or not
	via 'display: none' (it could be from multiple sources, e.g. css, inherited from ancestor, etc.).
	
	Why we need this in <AppSpinner>?
	Apparantly, GSAP's motionPath plugin's "align" property requires el to be rendered for it to work.
	So, we need to refresh animation when visiblity change is detected.
	
	planeTl.to(plane, {
		duration: 2,
		motionPath: {
			type: 'cubic',
			path: planeCirclePath,
			align: planeCirclePath, // <-- ⬅ THIS LINE
			autoRotate: true,
			alignOrigin: [0.5, 0.5],
		},
		ease: 'none',
	});
*/
const setupVisibilityObserver = () => {
	const resizewatcher = new ResizeObserver((entries) => {
		const entry = entries.at(0);
		if (entry.contentRect.width === 0) {
			// become hidden
			destroyAnimation();
		} else {
			// become visible
			refreshAnimation();
		}
	});
	
	resizewatcher.observe(rootEl.value);
};

onMounted(async () => {
	resetContainerDOM();
	setupVisibilityObserver();
	await nextTick();
	animationStorage = startAnimation();
});

onBeforeUnmount(destroyAnimation);

const refreshAnimation = async () => {
	destroyAnimation();
	await nextTick();
	resetContainerDOM();
	await nextTick();
	animationStorage = startAnimation();
};

defineExpose({
	refreshAnimation,
});

</script>


<template>
<div
	ref="rootEl"
	class="AppSpinner select-none text-primary-blue-base"
	:style="{ width: `${props.size}px`, height: `${props.size}px` }"
	aria-busy="true"
	aria-live="polite"
	aria-label="Loading"
	:data-use-theme="siteName"
>
	<svg
		ref="rootSvgEl"
		viewBox="0 0 200 200"
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
	>
		<!-- This is empty because resetContainerDOM() method will append the DOM manually -->
	</svg>
</div>
</template>


<style scoped lang="scss">


[data-use-theme="MHH"] {
	.text-primary-blue-base {
		color: var(--primary-mhh-teal-base);
	}
}

[data-use-theme="firefly"] {
	.text-primary-blue-base {
		color: var(--primary-firefly-orange-base);
	}
}
</style>
