import { ref, computed, onMounted, onUnmounted, readonly, getCurrentInstance } from 'vue';
import { Model, ModelManager, PathUtils, AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import Utils from '../../Utils.js';
import { Constants } from '../../Constants.js';

const KNOWN_KEYS_OF_AEM_PROPERTIES = [
	'appliedCssClassNames',
	'containerClass',
	'cqForceReload',
	'cqItems',
	'cqItemsOrder',
	'cqPath',
	'cqType',
	'hasEditableCapability',
	'htmlId',
	'injectPropsOnInit',
	'itemPath',
	'pagePath',
];

const modelProvider = ({ props, context }) => {
	// const instance = getCurrentInstance();
	
	// console.log('context.attrs.aemProperties = ', context.attrs.aemProperties);
	const mergedProperties = ref({
		...props,
		...context.attrs,
		...context.attrs.aemProperties,
	});
	
	const computedCqPath = computed(() => {
		const { cqPath, pagePath, itemPath, injectPropsOnInit } = mergedProperties.value;
		// console.log('cqPath = ', cqPath);
		return Utils.getCQPath({ cqPath, pagePath, itemPath, injectPropsOnInit });
	});
	
	
	const updateMergedProperties = (newProps) => {
		mergedProperties.value = {
			...mergedProperties.value,
			...newProps,
		};
	};
	
	
	const updateData = (cqPath) => {
		const { pagePath, itemPath, injectPropsOnInit, cqForceReload } = mergedProperties.value;
		const path = cqPath || mergedProperties.value.cqPath || (pagePath && Utils.getCQPath({ pagePath, itemPath, injectPropsOnInit }));
		if (!path) return;
		
		// console.log(`${computedCqPath.value} | updateData`);

		ModelManager.getData({ path, forceReload: cqForceReload }).then((data) => {
			if ((!data) || Object.keys(data).length === 0) return;
			
			console.log(`${computedCqPath.value} | data = `, data);
			updateMergedProperties(Utils.modelToProps(data));

			// Fire event once component model has been fetched and rendered to enable editing on AEM
			if (injectPropsOnInit && AuthoringUtils.isInEditor()) {
				PathUtils.dispatchGlobalCustomEvent(Constants.ASYNC_CONTENT_LOADED_EVENT, {});
			}
		}).catch((error) => {
			console.log('An error with ModelManager.getData(). Unable to update component with new props, please refresh the page manually.');
			console.error(error);
		});
	};
	
	const addModelListener = () => {
		// console.log(`${computedCqPath.value} | addModelListener`);
		ModelManager.addListener(computedCqPath.value, updateData);
	};
	const removeModelListener = () => {
		// console.log(`${computedCqPath.value} | removeModelListener`);
		ModelManager.removeListener(computedCqPath.value, updateData);
	};
	
	onUnmounted(removeModelListener);
	
	updateMergedProperties(Utils.modelToProps(props)); // <-- is this important?
	
	// if (mergedProperties.value.injectPropsOnInit && props.hasEditableCapability) {
	// 	updateData(computedCqPath.value);
	// }
	
	if (props.hasEditableCapability) addModelListener();
	
	const componentId = computed(() => {
		return `${mergedProperties.value.cqPath}`;
	});
	
	
	// previous logic, has bug, now implements KNOWN_KEYS_OF_AEM_PROPERTIES instead
	/* const aemProperties = computed(() => {
		const resultKeys = Object.keys(mergedProperties.value);
		for (const [key] of Object.entries(context.attrs)) {
			resultKeys[resultKeys.indexOf(key)] = null;
		}
		const resultObj = Object.fromEntries(resultKeys.filter(Boolean).map((key) => {
			return [key, mergedProperties.value[key]];
		}));
		
		resultObj.htmlId = resultObj.cqPath.split('jcr:content/')[1];
		
		return resultObj;
	}); */
	const aemProperties = computed(() => {
		const resultKeys = [...KNOWN_KEYS_OF_AEM_PROPERTIES];
		const resultObj = Object.fromEntries(resultKeys.filter(Boolean).map((key) => {
			return [key, mergedProperties.value[key]];
		}));
		
		resultObj.htmlId = resultObj.cqPath.split('jcr:content/')[1];
		
		return resultObj;
	});
	const childProperties = computed(() => {
		const resultKeys = Object.keys(mergedProperties.value);
		for (const [key] of Object.entries(aemProperties.value)) {
			resultKeys[resultKeys.indexOf(key)] = null;
		}
		return Object.fromEntries(resultKeys.filter(Boolean).map((key) => {
			return [key, mergedProperties.value[key]];
		}));
	});
	
	return {
		computedCqPath: readonly(computedCqPath),
		childProperties: readonly(childProperties),
		mergedProperties: readonly(mergedProperties),
		aemProperties: readonly(aemProperties),
		componentId: readonly(componentId),
	};
};

export {
	modelProvider,
};
