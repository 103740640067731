import { createGlobalState } from '@vueuse/core';


export const useBSTOverlayInterimData = createGlobalState(() => {
	
	const interimActiveSubtab = ref('book-flight');
	
	const holdingData = ref({
		ondValueFrom: '',
		ondValueTo: '',
		dateRangeValueFrom: '',
		dateRangeValueTo: '',
		isOneWay: false,
		adultsCount: 0,
		teensCount: 0,
		childrenCount: 0,
		infantsCount: 0,
		cabinClassValue: '',
		payByEnrichPoints: false,
	});
	
	const commitDataToBSTOverlay = (activeSubtab, data) => {
		// subtab ▶ overlay
		interimActiveSubtab.value = activeSubtab;
		
		// update 'holdingData'
		holdingData.value = data;
		
		// notify 'BST Overlay'
		_notify('bst-overlay');
	};
	const commitDataFromBSTOverlay = (data) => {
		// overlay ▶ subtab
		
		// update 'holdingData'
		holdingData.value = data;
		
		// notify the current 'interimActiveSubtab'
		_notify(interimActiveSubtab.value);
	};
	
	const callbackMap = new Map([
		['book-flight', []],
		['bonus-side-trip', []],
		['bst-overlay', []],
	]);
	
	const registerNotifier = (identifier, callback, options = {}) => {
		// identifier = 'book-flight' | 'bonus-side-trip' | 'bst-overlay'
		const callbacks = callbackMap.get(identifier);
		if (!callbacks) return false;
		callbacks.push(callback);
		if (options.immediate) _notify(identifier);
		return true;
	};
	const unregisterNotifier = (identifier) => {
		// identifier = 'book-flight' | 'bonus-side-trip' | 'bst-overlay'
		const callbacks = callbackMap.get(identifier);
		if (!callbacks) return false;
		callbackMap.set(identifier, []);
		return true;
	};
	
	const _notify = (identifier) => {
		const callbacks = callbackMap.get(identifier);
		callbacks.forEach((cb) => {
			cb(holdingData.value);
		});
	};

	return {
		holdingData: readonly(holdingData),
		
		commitDataToBSTOverlay,
		commitDataFromBSTOverlay,
		
		registerNotifier,
		unregisterNotifier,
	};
});

