import dayjs from 'dayjs';
import { merge as _merge } from 'lodash';

const _retrieveLocalStorageByKey = (key) => {
	
	return () => {
		const retrieved = window.localStorage.getItem(key);
		if (!retrieved) return null;
		
		let parsed = null;
		try {
			parsed = JSON.parse(retrieved);
		} catch {
			window.localStorage.removeItem(key);
			return null;
		}
		
		// check for expiry
		if (!parsed.expiresOn) return null;
		
		const expiry = dayjs(parseInt(parsed.expiresOn, 10));
		
		if (!expiry.isValid()) {
			// invalid expiresOn
			window.localStorage.removeItem(key);
			return null;
		}
		if (dayjs().isAfter(expiry)) {
			// sudah expired
			window.localStorage.removeItem(key);
			return null;
		}
		
		// expiry ok, return the data
		return parsed.data;
	};
};
const _writeLocalStorage = (key, options) => {
	return (data, mode = 'merge') => {
		const expiryDuration = options.expiryDuration;
		const now = dayjs();
		const expiryTime = dayjs(now.add(expiryDuration, 'ms'));
		
		const existingData = _retrieveLocalStorageByKey(key);
		let contentData = null;
		switch (mode) {
			case 'replace': {
				contentData = data;
				break;
			}
			default: // <-- intentional fall-through
			case 'merge': {
				contentData = _merge({}, existingData, data);
				break;
			}
		}
		
		window.localStorage.setItem(key, JSON.stringify({
			expiresOn: +expiryTime, // Unix Timestamp in milliseconds
			data: contentData,
		}));
	};
};

const _deleteLocalStorage = (key, options) => {
	return () => {
		window.localStorage.removeItem(key);
	};
};

export default function usePersistencyWithExpiry (key, options) {
	const LOCAL_STORAGE_KEY = key;
	const optionsFinal = {
		expiryDuration: 6.048e+8, // <-- defaults to 7 days
		autoRestore: true,
		autoWrite: true,
		refs: {},
		...options,
	};
	
	const retrieveLocalStorage = _retrieveLocalStorageByKey(LOCAL_STORAGE_KEY, optionsFinal.expiryDuration);
	const writeLocalStorage = _writeLocalStorage(LOCAL_STORAGE_KEY, optionsFinal);
	const deleteLocalStorage = _deleteLocalStorage(LOCAL_STORAGE_KEY, optionsFinal);
	
	if (optionsFinal.autoRestore) {
		const existingData = retrieveLocalStorage() ?? {};
		Object.entries(optionsFinal.refs).forEach(([refName, ref]) => {
			if (existingData[refName]) ref.value = existingData[refName];
		});
	}
	
	if (optionsFinal.autoWrite) {
		watch(() => {
			return Object.fromEntries(
				Object
					.entries(optionsFinal.refs)
					.map(([refName, ref]) => [refName, ref.value])
			);
		}, (newValue) => {
			writeLocalStorage(newValue);
		});
	}
	
	return {
		retrieveLocalStorage,
		writeLocalStorage,
		deleteLocalStorage,
	};
}
