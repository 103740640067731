<!--
	Swiper 8.4.2
	API: https://swiperjs.com/swiper-api
	How to use with Vue: https://swiperjs.com/vue
	Sample of various use cases: https://swiperjs.com/demos
-->
<script setup>
import { Comment, Fragment, Text } from 'vue';
import { Autoplay, Navigation, Pagination, Mousewheel, Lazy, Keyboard, A11y, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import WrapVNodes from '~/logic/WrapVNodes.js';
// import { waitFor } from '~/logic/helpers/utils';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/keyboard';
import 'swiper/css/a11y';
import 'swiper/css/scrollbar';



const props = defineProps({
	variant: { type: String, default: '' },
	enabled: { type: Boolean, default: true },
	dormantMode: { type: Boolean, default: false },
	dormantModeEnableGridLayout: { type: Boolean, default: false },
	handleTabIndex: { type: Boolean, default: false },
	isTabRole: { type: Boolean, default: false },
});

// eslint-disable-next-line
const isDormantMode = props.dormantMode; // this is made non-reactive deliberately


const modules = [
	Autoplay,
	Navigation,
	Pagination,
	Mousewheel,
	Lazy,
	Keyboard,
	A11y,
	Scrollbar,
];

const normalizeSlot = (vNodes = []) => {
	return vNodes
		.filter((child) => {
			// filter away comment nodes
			if (typeof child.type === 'symbol' && child.type === Comment) {
				return false;
			}
			// ignore text nodes
			if (typeof child.type === 'symbol' && child.type === Text) {
				console.warn(`VueSwiper.vue: Encountered a text node as slot, ignored. Make sure all children of <VueSwiper> are wrapped with a HTML element.`);
				return false;
			}
			return true;
		})
		.map((child, index) => {
			if (typeof child.type === 'symbol' && child.type === Fragment) {
				return child.children.map((subChild) => {
					return subChild;
					/* return h(
						SwiperSlide,
						{
							key: `slide-${index}`,
						},
						() => subChild,
					); */
				});
			}

			return child;
			/* return h(
				SwiperSlide,
				{
					key: `slide-${index}`,
				},
				() => child,
			); */
		})
		.flat();
};

const swiperInstance = ref(null);
let enabledUnwatcher = null;

onMounted(() => {
	enabledUnwatcher = watch(() => props.enabled, (newValue) => {
		if (newValue) {
			swiperInstance.value?.enable();
		} else {
			swiperInstance.value?.disable();
		}
	}, { immediate: true });
});

onBeforeUnmount(() => {
	enabledUnwatcher();
});

const handleInit = (swiper) => {
	swiperInstance.value = swiper;
	swiperInstance.value.keyboard?.disable();
};

const handleRootFocusedWithin = () => {
	swiperInstance.value?.keyboard?.enable();
};
const handleRootBlurredWithin = () => {
	swiperInstance.value?.keyboard?.disable();
};

defineExpose({
	swiperInstance,
});

</script>

<template>
<component
	:is="isDormantMode ? 'div' : Swiper"
	v-focus-within
	class="VueSwiper"
	:modules="isDormantMode ? null : modules"
	:data-variant="props.variant"
	:keyboard="isDormantMode ? null : true"
	:a11y="isDormantMode ? null : {
		prevSlideMessage: $t('Previous slide'),
		nextSlideMessage: $t('Next slide'),
		slideRole: isTabRole ? 'tab' : 'group',
	}"
	:tabindex="isDormantMode ? null : '-1'"
	:data-is-dormant="isDormantMode ? true : null"
	:data-grid-layout="isDormantMode ? (props.dormantModeEnableGridLayout ? true : null) : null"
	@after-init="handleInit"
	@focus-within="handleRootFocusedWithin"
	@blur-within="handleRootBlurredWithin"
>
	<SwiperSlide
		v-for="(slot, index) in normalizeSlot($slots.default())"
		:key="index"
		v-slot="{ isActive }"
		:aria-selected="isTabRole ? slot.children[0]?.props?.ariaSelectedVal : null"
		:aria-controls="isTabRole ? slot.children[0]?.props?.ariaControlsVal : null"
	>
		<WrapVNodes
			:nodes="slot"
			:nodesProps="{
				'tabindex': props.handleTabIndex ? ( isActive ? 0 : -1 ) : null,
			}"
		/>
	</SwiperSlide>
	
	<!-- Does not work, for now -->
	<!-- <template v-for="(_, name) in $slots" #[name]="slotData">
		<slot :name="name" v-bind="slotData"></slot>
	</template> -->
</component>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.VueSwiper {
	display: flex;
	flex-direction: column;
	--swiper-navigation-color: #AAA;

	:deep(.swiper-slide) {
		overflow: hidden;
	}

	&[data-variant*="slides-auto-width"] {
		:deep(.swiper-slide) {
			width: auto !important;
		}
	}
	&[data-variant*="slides-auto-height"] {
		:deep(.swiper-slide) {
			height: auto !important;
		}
	}

	// hide all disabled navigation button
	:deep(.swiper-button-disabled) {
		display: none;
	}
}

</style>
