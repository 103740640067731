<script>
export default {
	name: 'FormWrapper',
};
</script>

<script setup>
import { useForm } from 'vee-validate';
import { MHScrollToElement } from '~/logic/helpers/dom.js';


const props = defineProps({
	focusFirstErrorElOnInvalidSubmit: { type: Boolean, default: false },
});
const emit = defineEmits([
	'submit-valid',
	'submit-invalid',
]);

const form = useForm();

const {
	errors,
	isSubmitting,
	submitCount,
	meta,
	values,
	setFieldError,
	setErrors,
	setFieldValue,
	setValues,
	validate,
	validateField,
	handleSubmit,
	submitForm,
	handleReset,
	resetForm,
} = form;

const rootEl = ref(null);

const onSubmit = handleSubmit((...args) => {
	emit('submit-valid', ...args);
}, (...args) => {
	if (props.focusFirstErrorElOnInvalidSubmit) {
		const firstErrorEl = rootEl.value.querySelector(`[aria-invalid="true"]`);
		
		if (firstErrorEl) {
			if (firstErrorEl._mh_invalidFocus) {
				// A special "_mh_invalidFocus" property is attached to the node, call that instead
				firstErrorEl._mh_invalidFocus(firstErrorEl);
			} else {
				// perform the usual scroll and focus
				
				// firstErrorEl.scrollIntoView({
				// 	behavior: 'smooth',
				// 	block: 'center',
				// 	inline: 'nearest',
				// });
				MHScrollToElement(firstErrorEl, { additionalTopOffset: 60 });
				firstErrorEl.focus({ preventScroll: true, focusVisible: true });
			}
		}
	}
	emit('submit-invalid', ...args);
});


defineExpose({
	errors,
	isSubmitting,
	submitCount,
	meta,
	/* 
	interface FormMeta {
		touched: boolean; // if at least one field is touched (was blurred)
		dirty: boolean; // if at least one field is dirty (manipulated)
		valid: boolean; // if the form doesn't have any error messages
		pending: boolean; // if at least one field is pending validation
		initialValues?: Record<string, any>; // a map of the form's initial values
	}
	*/
	values,
	setFieldError,
	setErrors,
	setFieldValue,
	setValues,
	validate,
	validateField,
	handleSubmit,
	submitForm,
	handleReset,
	resetForm,
	rootEl,
});


</script>

<template>
<form
	ref="rootEl"
	class="FormWrapper"
	novalidate
	@submit="onSubmit"
>
	<slot
		v-bind="{
			errors,
			isSubmitting,
			submitCount,
			meta,
			values,
			setFieldError,
			setErrors,
			setFieldValue,
			setValues,
			validate,
			validateField,
			handleSubmit,
			submitForm,
			handleReset,
			resetForm,
			rootEl,
		}"
	></slot>
</form>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FormWrapper {
	
}

</style>
