import Anchor from '~/logic/helpers/Anchor.js';
import { useRouteHash } from '~/logic/composables/useRouteHash.js';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useRouter } from 'vue-router';



const subpageToVisibilityMap = new Map();
// const registeredSubpages = computed(() => {
// 	return [ ...subpageToVisibilityMap.keys() ];
// });
const getRegisteredSubpages = () => {
	return [...subpageToVisibilityMap.keys()];
};
let unwatchMobileViewport = null;
let savedScrollY = null;

const _handleHashChange = (newValue, oldValue) => {
	if (getRegisteredSubpages().includes(oldValue)) {
		const stored = subpageToVisibilityMap.get(oldValue);
		if (!stored) /* This shouldn't be happening??? */ return;
		
		if (stored.isMobileSubpageVisible) {
			stored.hideMobileSubpage({ updateHash: false });
		}
		if (!getRegisteredSubpages().includes(newValue)) {
			return;
		}
		// new hash in the url is also a new sub page, so no return statement needed, let this function continue
	}
	if (!getRegisteredSubpages().includes(newValue)) return;
	
	if (isMobileViewport.value) {
		// mobile viewport
		savedScrollY = document.documentElement.scrollTop;
		Anchor.activate('body');
		Anchor.activate(newValue, { performScroll: false });
	} else {
		// desktop viewport
		Anchor.activate(newValue, { performScroll: true });
	}
};

let unwatchHash = null;


export default function useBookingWidgetSubpage ({ hashKey, rootEl }) {
	// hashKey can be array or string

	const { isEditorMode } = useGlobalAEMState();
	
	// skip this anchor logic in editor mode and in desktop view as well, due to subpage component did not use in desktop view, the anchor register here will conflict and replace with the SwitchTab component anchor register, which cause the SwitchTab hash id anchor activation fail.
	if (isEditorMode.value || !isMobileViewport.value) return { isMobileSubpageVisible: ref(false) };
	
	const isMobileSubpageVisible = ref(false);
	const { hash } = useRouteHash();
	
	const router = useRouter();
	
	if (!unwatchMobileViewport) {
		// automatically clear hash from url when go to mobile view, if hash matches one of the registered subpages
		unwatchMobileViewport = watch(isMobileViewport, (newValue) => {
			if (newValue && getRegisteredSubpages().includes(hash.value)) {
				// desktop -> mobile view
				router.replace({ hash: '' });
			}
		});
	}
	
	function onHashActivate () {
		if (isMobileViewport.value) {
			// mobile
			showMobileSubpage();
		}
	}

	if (Array.isArray(hashKey)) {
		// hashKey is array
		for (const hashSingle of hashKey) {
			Anchor.register(hashSingle, rootEl).on('activate', onHashActivate);
		}
	} else if ((typeof hashKey === 'string')) {
		// hashKey is string
		Anchor.register(hashKey, rootEl).on('activate', onHashActivate);
	}
	
	const showMobileSubpage = () => {
		isMobileSubpageVisible.value = true;
		document.documentElement.classList.add('showing-subpages');
	};
	const hideMobileSubpage = async ({ updateHash = true } = {}) => {
		isMobileSubpageVisible.value = false;
		if (updateHash) router.replace({ hash: '' });
		document.documentElement.classList.remove('showing-subpages');
		
		if (savedScrollY) {
			await nextTick();
			document.documentElement.scrollTop = savedScrollY;
		}
	};
	
	if (!unwatchHash) {
		unwatchHash = watch(hash, _handleHashChange);
	}
	
	// check for hash query NOW and perform its side-effect if hash query matches
	if (
		// hashKey is string
		(((typeof hashKey === 'string') && hash.value === hashKey))
		||
		// hashKey is array
		(Array.isArray(hashKey) && hashKey.includes(hash.value))
	) {
		if (isMobileViewport.value) {
			// mobile viewport
			savedScrollY = document.documentElement.scrollTop;
			Anchor.activate('body');
			Anchor.activate(hash.value, { performScroll: false });
		} else {
			// desktop viewport
			setTimeout(() => {
				Anchor.activate(hash.value, { performScroll: true });
			}, 500);
		}
	}
	
	if (Array.isArray(hashKey)) {
		// hashKey is array
		for (const hashSingle of hashKey) {
			subpageToVisibilityMap.set(hashSingle, {
				rootEl,
				isMobileSubpageVisible,
				hideMobileSubpage,
				showMobileSubpage,
			});
		}
	} else if ((typeof hashKey === 'string')) {
		// hashKey is string
		subpageToVisibilityMap.set(hashKey, {
			rootEl,
			isMobileSubpageVisible,
			hideMobileSubpage,
			showMobileSubpage,
		});
	}
	
	onUnmounted(() => {
		// clean up
		if (Array.isArray(hashKey)) {
			// hashKey is array
			for (const hashSingle of hashKey) {
				Anchor.unregister(hashSingle);
				subpageToVisibilityMap.delete(hashSingle);
			}
		} else if ((typeof hashKey === 'string')) {
			// hashKey is string
			Anchor.unregister(hashKey);
			subpageToVisibilityMap.delete(hashKey);
		}
		unwatchHash?.();
	});
	
	return {
		isMobileSubpageVisible: computed({
			get () {
				return isMobileSubpageVisible.value;
			},
			set (value) {
				if (value) {
					showMobileSubpage();
				} else {
					hideMobileSubpage();
				}
			},
		}),
	};
}

