<script>
export default {
	name: 'SubTabHotel',
};
</script>

<script setup>
import DropdownTravellersAndRoom from '~/components/BookingWidget/FlightHotelSearch/DropdownTravellersAndRoom.vue';
import HotelPicker from '~/components/BookingWidget/FlightHotelSearch/HotelPicker.vue';
import Dropdown from '~/components/form/Dropdown.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import TextField from '~/components/form/TextField.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import Tooltip from '~/components/Tooltip.vue';
import { i18nGlobal } from '~/logic/i18n.js';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	datePicker: { type: Object, default: null },
	description: { type: String, default: '' },
	tooltipLabel: { type: String, default: '' },
	tooltipBody: { type: String, default: '' },
	hotelSelection: { type: Object, default: null },
	travellerSelection: { type: Object, default: null },
	productID: { type: String, default: '' },
	note: { type: String, default: '' },
	endpoint: { type: String, default: '' },
	hotelSearchPropertyUrl: { type: String, default: '' },
	dropDownHotelListApiUrl: { type: String, default: '' },
	cultureCode: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'form-submission',
]);

const formWrapper = ref(null);
const rootEl = ref(null);
const { pageProperties } = useGlobalAEMState();


const handleSubmitValid = async (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);
	emit('form-submission');
	
	const roomData = values?.room.map(({ adults, children, childAge, infants }, index) => {
		let childAgeObject = {};
		if (children && childAge.length) {
			childAge.forEach((age, idx) => {
				childAgeObject[`PaxInfos[${index}].ChildAges[${idx}]`] = age;
			});
		}

		return {
			...(adults ? { [`PaxInfos[${index}].AdultCount`]: adults } : null),
			...(children ? { [`PaxInfos[${index}].ChildCount`]: children } : null),
			...(infants ? { [`PaxInfos[${index}].InfantCount`]: infants } : null),
			...(childAge?.length ? childAgeObject : null),
		};
	});

	const roomDataFlatten = roomData.reduce((acc, curr) => ({ ...acc, ...curr }));
	const { countryName, regionName, cityCode, name, type, id } = locationValue.value;
	const params = {
		'To': locationValue.value.value.split('|')[0],
		'CultureCode': props.cultureCode,
		'DepartureDate': dateRangeValueFrom.value,
		'ReturnDate': dateRangeValueTo.value,
		'Currency': pageProperties.value.baseCurrency,
		'RoomCount': values.room.length,
		'ProductId': props.productID,
		...(locationValue.value.cityId !== '0' ? { 'HotelCode': locationValue.value.cityId } : null ),
		...(roomData.length ? roomDataFlatten : null),
	};
	
	const urlPrefix = (type === 'hotel') ? `${props.hotelSearchPropertyUrl}/${countryName}/${getFormattedName(regionName)}/${cityCode}/${getFormattedName(name)}/${id}` : props.endpoint;

	const url = new URL(urlPrefix);

	Object.keys(params).forEach((key) => {
		url.searchParams.append(key, params[key]);
	});
	
	window.location.href = url.toString();

};

const getFormattedName = ( name ) => {
	if (name) {
		return	name.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replace(/\s+/g, '-')
			.toLowerCase();
	}
	return '';
};

const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const locationValue = ref(null);

const defaultMinDate = 2;
const defaultMaxDate = 14;

const computedMinDate = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.departureDateConfig || typeof datePickerData?.departureDateConfig !== 'number') return defaultMinDate;
	return datePickerData?.departureDateConfig;
});

const computedMaxDate = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.returnDateConfig || typeof datePickerData?.returnDateConfig !== 'number') return defaultMaxDate;
	return datePickerData?.returnDateConfig;
});

const isBtnSubmitDisabled = computed(() => {
	// datepickerValue.value; // eslint-disable-line
	const form = formWrapper.value;
	if (!form) return true;
	return (
		!form.values.location ||
		!form.values.dateDeparture ||
		!form.values.dateReturn ||
		!form.values.room.length ||
		Object.keys(form.errors).length !== 0
	);
});

</script>

<template>
<div ref="rootEl" class="SubTabHotel">
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		:focusFirstErrorElOnInvalidSubmit="true"
		class=""
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
	>
		<div v-if="props.description" v-html-sanitize="props.description" class="mb-5"></div>

		<Tooltip v-if="props.tooltipLabel" class="inline-flex mb-5">
			<template #default>
				<div class="flex items-center">
					<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
					{{ props.tooltipLabel }}
				</div>
			</template>
			<template #mobile-title>
				{{ props.tooltipLabel }}
			</template>
			<template #tooltip-content>
				{{ props.tooltipBody }}
			</template>
		</Tooltip>

		<div class="">
			<HotelPicker
				v-model="locationValue"
				:apiUrl="props.dropDownHotelListApiUrl"
				:hotelSelection="props.hotelSelection"
				:useTUIAPI="true"
			>
				<template #mobile-title>
					<span>{{ props.hotelSelection?.toQuestionMobileHotel }}</span>
				</template>
			</HotelPicker>
		</div>
		<div class="flex mt-6 gap-6 xl:gap-3 xl:mt-3 lg:flex-col">
			<div class="flex flex-grow gap-6 xl:gap-3 lg:flex-col">
				<div class="w-1/2 lg:w-full">
					<DateRangePicker
						v-model:modelValueFrom="dateRangeValueFrom"
						v-model:modelValueTo="dateRangeValueTo"
						:textFieldAttrsFrom="{
							ariaLabel: props.datePicker?.checkInLabel,
							placeholder: i18nGlobal.t('Select a date'),
						}"
						:textFieldAttrsTo="{
							ariaLabel: props.datePicker?.checkOutLabel,
							placeholder: i18nGlobal.t('Select a date'),
						}"
						:minDate="`t+${computedMinDate}d`"
						maxDate="t+1y"
						:maxDateFromAfterSelectingTo="`t+${computedMaxDate}d`"
						:disablePastDates="true"
						nameFrom="dateDeparture"
						nameTo="dateReturn"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.datePicker?.dateErrorMessageHotel"
						:requiredErrorMsgTo="props.datePicker?.dateErrorMessageHotel"
						:labelReset="props.datePicker?.resetButtonCTATextHotel ?? $t('Reset')"
						:labelDone="props.datePicker?.doneCTATextHotel ?? $t('Done')"
						:hideOneWay="true"
					>
						<template #mobile-title>
							<span>{{ props.datePicker?.datePickerQuestionMobileHotel }}</span>
						</template>
					</DateRangePicker>
				</div>
				<div class="w-1/2 lg:w-full">
					<DropdownTravellersAndRoom
						:labelText="props.travellerSelection?.label"
						:adultLabel="props.travellerSelection?.adultLabel"
						:childrenLabel="props.travellerSelection?.childrenLabel"
						:infantLabel="props.travellerSelection?.infantLabel"
						:teenagerLabel="props.travellerSelection?.teenagerLabel"
						combinedAnyExceededMsg="Maximum {n} in all rooms"
						combinedAdultChildExceededMsg="Maximum {n} in all rooms excluding infants"
						combinedInfantExceededMsg="Maximum {n} infants"
					>
						<template #mobile-title>
							<span>{{ props.travellerSelection?.questionMobile }}</span>
						</template>
					</DropdownTravellersAndRoom>
				</div>
			</div>
			<div class="ml-auto lg:ml-0">
				<CTAButton
					v-aa="[
						{
							clickName: props.travellerSelection?.buttonLabel,
							clickComponentType: 'Button',
							componentName: 'SubTabHotel',
							componentID: props.componentIdAA,
						},
						{
							name: props.travellerSelection?.buttonLabel,
							type: 'exit',
						},
					]"
					:sizeType="isMobileViewport ? 'small' : 'large'"
					:isSubmit="true"
					:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
					:disabled="isBtnSubmitDisabled"
				>
					{{ props.travellerSelection?.buttonLabel }}
				</CTAButton>
			</div>
		</div>

		<div
			v-if="props.note"
			class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
		>
			<icon-fas-circle-info class="fill-primary-blue-base text-base" />
			<div class="text-sm">
				<p>{{ props.note }}</p>
			</div>
		</div>
	</FormWrapper>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabHotel {
	
}

</style>
