/* 
	Use with meta.patchCleanPath = true.
	https://github.com/vuejs/router/issues/1638
*/
export const patchRouter = (router) => {
	const cleanPath = (route) => [ ['%2F', '/'], ['//', '/'] ].reduce((path, rule) => path.replaceAll(rule[0], rule[1]), route || '');

	const bindResolve = router.resolve.bind(router);
	router.resolve = (raw, currentLocation) => {
		const resolved = bindResolve(raw, currentLocation);
		if (resolved.meta?.patchCleanPath !== true) return resolved;

		return {
			...resolved,
			href: cleanPath(resolved.href),
			path: cleanPath(resolved.path),
			fullPath: cleanPath(resolved.fullPath),
		};
	};

	const routerMethodFactory = (method) => (to) => {
		const resolved = router.resolve(to);
		if (resolved.meta?.patchCleanPath !== true) return method(to);

		return method({
			path: cleanPath(resolved.fullPath),
			query: resolved.query,
			hash: resolved.hash,
		});
	};

	router.push = routerMethodFactory(router.push.bind(router));
	router.replace = routerMethodFactory(router.replace.bind(router));

	return router;
};
