import { createGlobalState } from '@vueuse/core';


export const useHeaderFooterStore = createGlobalState(
	() => {
		return reactive({
			header: {
				logo1Dark: null,
				logo1DarkUrl: null,
				openInNewTabLogo1Dark: null,
				hideInMobileLogo1: null,
				logo1DarkAtlText: null,

				logo2Dark: null,
				logo2DarkUrl: null,
				openInNewTabLogo2Dark: null,
				hideInMobileLogo2: null,
				logo2DarkAtlText: null,
			},
			footer: {
				subsidiaryTab: null,
				tncTab: null,
				copyrightTab: null,
			},
		});
	},
);
