<script>
export default {
	name: 'Tooltip',
};
</script>

<script setup>
import { isElOutOfViewport } from '~/logic/helpers/dom.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import OverlayBtmSticky from '~/components/OverlayBtmSticky.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';


const props = defineProps({
	ariaLabel: { type: String, default: null },
	renderInvisibleTrigger: { type: Boolean, default: true },
	triangleAlignment: { type: String, default: 'left' },
	isDisabled: { type: Boolean, default: false },
});
const emit = defineEmits([
	
]);

const siteName = window.siteName;

const isTooltipVisible = ref(false);
const tooltipEl = ref(null);
const tooltipPositionX = ref('0');
const tooltipPositionY = ref('0');

const showTooltip = async () => {
	isTooltipVisible.value = true;
	tooltipPositionX.value = '0';
	
	await nextTick();
	const isOut = isElOutOfViewport(tooltipEl.value);
	if (isOut.right) {
		tooltipPositionX.value = 'calc(-100% + 30px)';
	}
};
const hideTooltip = () => {
	isTooltipVisible.value = false;
};


const isMobileOverlayVisible = ref(false);

const handleTriggerClick = (event) => {
	if (isMobileViewport.value) isMobileOverlayVisible.value = true;
};

const handleEscKey = (event) => {
	hideTooltip();
};


defineExpose({
	showTooltip,
	hideTooltip,
	tooltipPositionX,
	tooltipPositionY,
});

</script>

<template>
<div
	class="Tooltip"
	:class="{
		'render-invisible-trigger': props.renderInvisibleTrigger,
		[`triangle-alignment-${props.triangleAlignment || 'left'}`]: true,
	}"
	:data-use-theme="siteName"
>
	<button
		class="btn-tooltip-trigger"
		type="button"
		:aria-label="props.ariaLabel || $t('More info')"
		@mouseenter="(isMobileViewport || props.isDisabled) ? null : showTooltip()"
		@mouseleave="(isMobileViewport || props.isDisabled) ? null : hideTooltip()"
		@click="props.isDisabled ? null : handleTriggerClick()"
		@keydown.esc.prevent="props.isDisabled ? null : handleEscKey"
	>
		<slot name="default"></slot>
	</button>
	
	<div
		v-show="isTooltipVisible"
		ref="tooltipEl"
		class="floating-tooltip px-3 py-2 typo-body-text-2"
		:style="{
			transform: `translate(${tooltipPositionX}, ${tooltipPositionY})`,
		}"
		role="tooltip"
	>
		<slot name="tooltip-content"></slot>
	</div>
	
	<span v-show="isTooltipVisible" class="tooltip-triangle"></span>
	
	<OverlayBtmSticky
		:isVisible="isMobileOverlayVisible && isMobileViewport"
		@update:is-visible="isMobileOverlayVisible = $event"
	>
		<header class="px-5 pb-4 sticky top-0 bg-white z-1000">
			<h5 class="font-bold">
				<slot name="mobile-title"></slot>
			</h5>
		</header>
		<div class="px-5 mr-2" role="tooltip">
			<slot name="tooltip-content"></slot>
		</div>
		<template #footer>
			<div class="px-5 pt-4 pb-4 sticky bottom-0 bg-white z-1000">
				<CTAButton
					ctaSizing="fluid"
					@click="isMobileOverlayVisible = false;"
				>
					<slot name="mobile-cta-label">{{ $t('Got it') }}</slot>
				</CTAButton>
			</div>
		</template>
	</OverlayBtmSticky>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';
@use '~/styles/partials/_mixins.scss';

.Tooltip {
	position: relative;
	
	&.render-invisible-trigger {
		.btn-tooltip-trigger::after {
			display: block;
		}
	}
	
	&.triangle-alignment-center {
		.tooltip-triangle {
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
		}
	}
}

.floating-tooltip {
	color: white;
	background-color: var(--primary-black-base);
	border-radius: 4px;
	width: max-content;
	max-width: 300px;
	position: absolute;
	z-index: 9000;
	bottom: calc(100% + 8px);
	left: -8px;

	&:empty {
		display: none;

		& ~ .tooltip-triangle {
			display: none;
		}
	}

	html[dir="rtl"] & {
		left: auto;
		right: -8px;
	}
}

.btn-tooltip-trigger {
	position: relative;
	cursor: default;
	
	&::after {
		content: '';
		position: absolute;
		width: 110%;
		height: 110%;
		top: -5%;
		left: -5%;
		display: none;
	}
	
	&:focus-visible {
		outline-width: var(--focus-visible-outline-width, 2px);
		outline-offset: var(--focus-visible-outline-offset, 0);
		outline-color: var(--semantic-blue-base);
		
		~ .floating-tooltip, ~ .tooltip-triangle {
			display: block !important;
		}
	}
}

.tooltip-triangle {
	@include mixins.triangle(15px, var(--primary-black-base), down);
	position: absolute;
	bottom: calc(100% + 1px);
	left: 8px;
	
	html[dir="rtl"] & {
		left: auto;
		right: 8px;
	}
}


[data-use-theme="MHH"] {
	:deep(.fill-primary-blue-base) {
		fill: var(--primary-mhh-teal-base);
	}

}

</style>
