<script>
export default {
	name: 'DropdownTravellersAndRoom',
};
</script>

<script setup>
import { unref } from 'vue';
import Dropdown from '~/components/form/Dropdown.vue';
import { useTravellerAndRoom } from '~composables/booking-widget/useTravellerAndRoom.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import NumberPlusMinus from '~/components/form/NumberPlusMinus.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import { i18nGlobal } from '~/logic/i18n.js';

const props = defineProps({
	labelText: { type: String, default: i18nGlobal.t('Travellers and rooms') },
	adultLabel: { type: String, default: i18nGlobal.t('Adults') },
	childrenLabel: { type: String, default: i18nGlobal.t('Children') },
	infantLabel: { type: String, default: i18nGlobal.t('Infants') },
	teenagerLabel: { type: String, default: i18nGlobal.t('Teenagers') },
	combinedAnyExceededMsg: { type: String, default: 'Maximum {n} in all rooms' },
	combinedAdultChildExceededMsg: { type: String, default: 'Maximum {n} in all rooms excluding infants' },
	combinedInfantExceededMsg: { type: String, default: 'Maximum {n} infants' },
	isRequired: { type: Boolean, default: false },
	
	// Configuration prop for passenger label, default to Traveller.
	travellerLabel: { type: String, default: 'Traveller' },
	
	// Configuration props for enable or disable create room CTA and infant count
	enableCreateRoomCta: { type: Boolean, default: true },
	enableInfantCount: { type: Boolean, default: true },
});
const emit = defineEmits([
	'update:roomsData',
]);

const siteName = window.siteName;
const noOptionsEl = ref(null);
const dropdownEl = ref(null);

const {
	rooms,
	createRoom,
	deleteRoom,
	totalAdultChildCombinedCount,
	totalInfantCount,
	hasCombinedAnyExceeded,
	dropdownValidationFunc,
} = useTravellerAndRoom({
	errorMessages: {
		...(props.combinedAnyExceededMsg ? { combinedAnyExceeded: props.combinedAnyExceededMsg } : null),
		...(props.combinedAdultChildExceededMsg ? { combinedAdultChildExceeded: props.combinedAdultChildExceededMsg } : null),
		...(props.combinedInfantExceededMsg ? { combinedInfantExceeded: props.combinedInfantExceededMsg } : null),
	},
});

watch(hasCombinedAnyExceeded, () => {
	dropdownEl.value?.hiddenTextField.setTouched(true);
	dropdownEl.value?.hiddenTextField.validate();
}, { deep: true });


watch(rooms, (val) => {
	emit('update:roomsData', val);
}, { deep: true });

emit('update:roomsData', rooms);

</script>

<template>
<div class="DropdownTravellersAndRoom" :data-use-theme="siteName">
	<Dropdown
		ref="dropdownEl"
		:labelText="props.labelText"
		desktopVariant="custom-html booking-widget"
		mobileVariant="no-padding custom-html"
		:validation="dropdownValidationFunc"
		name="travellersAndRoom"
	>
		<template #mobile-title="slotData">
			<slot name="mobile-title" v-bind="slotData"></slot>
		</template>

		<template #placeholder>
			<div class="value-wrapper relative flex-grow mr-2 leading-none">
				<span class="text-label absolute font-semibold">
					{{ props.labelText }}<span v-if="(props.isRequired && siteName === 'MHH')" class="text-primary-mhh-teal-base required-asterisk">*</span>
				</span>
				<span class="text-value relative">
					{{ $tc(`{n} ${props.travellerLabel}`, {
						n: totalAdultChildCombinedCount + totalInfantCount,
					}) }}<span v-if="props.enableCreateRoomCta">, </span>
					<template v-if="props.enableCreateRoomCta">
						{{ $tc('{n} Room', {
							n: rooms.length,
						}) }}
					</template>
				</span>
			</div>
		</template>
		
		<template #nooptions="{ hideMobileOverlay }">
			<div
				ref="noOptionsEl"
				class="multiselect-options styled-scrollbar pt-4 pb-4 cursor-default mr-0 md:mr-2"
				tabindex="-1"
			>
				<template
					v-for="(room, index) in rooms"
					:key="index"
				>
					<div class="flex items-center mb-4 px-4">
						<p v-if="props.enableCreateRoomCta" class="typo-category-title uppercase">{{ $t('Room {n}', { n: index + 1 }) }}</p>
						
						<button
							v-if="index >= 1"
							v-button-press
							type="button"
							class="btn-generic-b ml-auto text-primary-blue-base font-semibold text-sm px-4"
							@click="deleteRoom(index); noOptionsEl?.focus();"
						>
							<icon-fas-trash-can class="text-xs fill-current mr-2 rtl:mr-0 rtl:ml-2" />
							{{ $t('Remove') }}
						</button>
					</div>
					
					<div class="flex flex-col gap-4">
						<NumberPlusMinus
							v-model="room.adults.count"
							class="px-4"
							:name="`room[${index}].adults`"
							:ariaLabel="$t('Room {n}: number of adults', { n: index })"
							:ariaDescription="$t('Ages 18 and above')"
							:min="unref(room.adults.min)"
							:max="room.hasReachedMaxPassengersCount ? room.adults.count : unref(room.adults.max)"
						>
							{{ props.adultLabel }}
						</NumberPlusMinus>
						<NumberPlusMinus
							v-model="room.children.count"
							class="px-4"
							:name="`room[${index}].children`"
							:ariaLabel="$t('Room {n}: number of children', { n: index })"
							:ariaDescription="$t('Ages 2 to 17')"
							:min="unref(room.children.min)"
							:max="room.hasReachedMaxPassengersCount ? room.children.count : unref(room.children.max)"
						>
							{{ props.childrenLabel }}
						</NumberPlusMinus>
						
						<div class="px-4 grid grid-cols-2 gap-x-4 gap-y-4 pt-4 pb-5 bg-neutral-grey-extralight md:grid-cols-1 empty:hidden">
							<!-- Note: m is 1-based index -->
							<NumberPlusMinus
								v-for="m in room.children.count"
								:key="m"
								:name="`room[${index}].childAge[${ m - 1 }]`"
								:ariaLabel="$t('Room {n}: child {m} age', { n: index, m })"
								:disableInput="false"
								:min="2"
								:max="17"
								:variant="isMobileViewport ? '' : (m % 2 === 0 ? 'justify-end' : '')"
							>
								{{ $t('Child {m} age', { m }) }}
							</NumberPlusMinus>
						</div>
						
						<NumberPlusMinus
							v-if="props.enableInfantCount"
							v-model="room.infants.count"
							class="px-4"
							:name="`room[${index}].infants`"
							:ariaLabel="$t('Room {n}: number of infants', { n: index })"
							:ariaDescription="$t('Ages below 2 for the entire journey')"
							:min="unref(room.infants.min)"
							:max="unref(room.infants.max)"
						>
							{{ props.infantLabel }}
						</NumberPlusMinus>
					</div>
					
					<div
						v-show="room.showInfantErrorMsg"
						class="error-msg-container px-4 leading-tight flex text-semantic-red-base text-sm md:flex-col mt-2"
						:role="room.showInfantErrorMsg ? 'alert' : null"
					>
						<p>{{ $t('Each infant should be accompanied by one adult') }}</p>
					</div>
					
					<div v-if="rooms.at(-1) !== room" class="mt-4 mb-4">
						<hr class="bg-neutral-grey" />
					</div>
				</template>
				
				<div
					v-if="props.enableCreateRoomCta"
					class="flex justify-start px-4 mt-6"
				>
					<CTAButton
						class="btn-add-flight"
						sizeType="small"
						styleType="secondary"
						iconStyleLeft="solid"
						iconIdLeft="circle-plus"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						:class="{
							'is-disabled': rooms.length >= 3,
						}"
						@click="createRoom"
					>
						<span class="font-semibold">{{ $t('Add room') }}</span>
					</CTAButton>
				</div>
			</div>
			<div class="sticky bottom-0 bg-white py-4 px-4 border-t-2 border-neutral-grey-base >md:hidden">
				<CTAButton
					alignmentMobile="right"
					@click="hideMobileOverlay"
				>{{ $t('Done') }}</CTAButton>
			</div>
		</template>
	</Dropdown>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.DropdownTravellersAndRoom {
	
}

[data-use-theme="MHH"] {
	.Dropdown .multiselect-main .value-wrapper .text-value {
		color: var(--primary-mhh-teal-base);
	}
}

</style>
