<script>
export default {
	name: 'StickyCTA',
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useWindowScroll, useEventBus } from '@vueuse/core';
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import { MHScrollToElement } from '~/logic/helpers/dom.js';

const props = defineProps({
	
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const { pageProperties } = useGlobalAEMState();

const globalMHHBookingBus = useEventBus('mhh-booking-widget:booking');

const isVisible = ref(false);

const { y: scrollY } = useWindowScroll();

const SCROLL_Y_THRESHOLD = window.innerHeight * 0.8;

const handleClick = (event) => {
	if (document.querySelector('.MHHBookingWidget')) {
		MHScrollToElement(document.querySelector('.MHHBookingWidget'));
		globalMHHBookingBus.emit('activate-first-tab');
	} else {
		// if booking widget does not exist then do nothing
	}
};

watch(scrollY, (newScrollY) => {
	if (newScrollY >= SCROLL_Y_THRESHOLD) {
		if (!isVisible.value) isVisible.value = true;
	} else {
		if (isVisible.value) isVisible.value = false;
	}
}, { immediate: true });

</script>

<template>
<div
	v-if="!isMobileViewport"
	class="StickyCTA"
	:style="{
		'--btn-size': isVisible ? undefined : '0px',
	}"
	:aria-label="$t('Book a holiday now!')"
	@click="handleClick"
>
	<div
		:style="{
			'background-color': isVisible ? 'var(--secondary-mhh-pink-base)' : 'transparent',
		}"
		class="sticky-cta-container flex flex-row items-center text-white"
	>
		<FontAwesomeLazy
			v-if="pageProperties.stickyCTAIconType === 'fontAwesome'"
			:iconId="pageProperties.stickyCTAFontAwesomeId ? pageProperties.stickyCTAFontAwesomeId : null"
			iconStyle="light"
			class="sticky-cta-icon"
		/>
		<img
			v-else
			:src="pageProperties.stickyIconImage"
			class="sticky-cta-icon"
		/>
		<div class="sticky-cta-text text-base mr-6 flex-shrink-0 truncate">
			<span>{{ pageProperties.stickyCTAText }}</span>
		</div>
	</div>
</div>
<div v-else>
	<Teleport to="body">
		<div
			class="sticky-cta-mobile-bottom-bar"
			:class="{
				'transform': !isVisible,
			}"
		>
			<CTAButton
				v-aa="[
					
				]"
				containerClass="pt-4 pb-4"
				containerStyle="--focus-visible-outline-offset: -1px;"
				sizeType="small"
				styleType="primary"
				:ctaText="pageProperties.stickyCTAText"
				style="background-color: var(--secondary-mhh-pink-base); border-color: var(--secondary-mhh-pink-base)"
				@click="handleClick"
			/>
		</div>
	</Teleport>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.StickyCTA {
	--btn-size: 64px;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	transition: height 0.2s;
	height: var(--btn-size);
	filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.1));
	
	:hover {
		cursor: pointer;
		.sticky-cta-text {
			max-width: 150px;
		}
	}
}

.sticky-cta-container {
	border-radius: var(--btn-size) 0 0 var(--btn-size);
}
.sticky-cta-text {
	max-width: 0px;
	transition: all 0.25s ease-in;
}
.sticky-cta-icon {
	width: 32px;
	height: 32px;
	fill: white;
	margin-left: calc(var(--btn-size)/2);
	margin-right: 16px;
	flex-shrink: 0;

	:deep(svg) {
		width: 100%;
		height: 100%;
	}
}

.sticky-cta-mobile-bottom-bar {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: auto;
	background-color: white;
	border-top-width: 1px;
	border-top-color: var(--neutral-grey-base);
	z-index: 8000;
	transition: 200ms ease-in-out;
}

.transform {
	transform: translateY(83px);
}
</style>
