import { createGlobalState } from '@vueuse/core';
import { getCookie, deleteCookie } from '~/logic/helpers/cookies.js';

const SAPPHIRE_COOKIE = 'sapphire';

// disable this because we are not using the shadow cookies anymore
// const SHADOW_M_COOKIE = 'shadow_m';
// const SHADOW_N_COOKIE = 'shadow_n';
// const SHADOW_T_COOKIE = 'shadow_t';

export const useAuthStore = createGlobalState(
	() => {
		const userInfo = ref(null);
		const _getIsLoggedInStatus = () => {
			return getCookie(SAPPHIRE_COOKIE) && userInfo.value;
		};
		const isLoggedIn = ref(_getIsLoggedInStatus());
		const isAuthStateReady = ref(false);
		
		const isSapphireCookiePresent = () => {
			return getCookie(SAPPHIRE_COOKIE);
		};
		
		const login = (_userInfo) => {
			// something...
			userInfo.value = _userInfo;
			refreshAuthStatus();
		};
		const logout = () => {
			deleteCookie(SAPPHIRE_COOKIE);
			// deleteCookie(SHADOW_M_COOKIE);
			// deleteCookie(SHADOW_N_COOKIE);
			// deleteCookie(SHADOW_T_COOKIE);
			userInfo.value = null;
			refreshAuthStatus();
		};
		const refreshAuthStatus = () => {
			isLoggedIn.value = _getIsLoggedInStatus();
		};
		
		
		return {
			// data
			isLoggedIn,
			userInfo,
			isAuthStateReady,
			
			// method
			login,
			logout,
			refreshAuthStatus,
			isSapphireCookiePresent,
		};
	},
);
