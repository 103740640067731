import { h, defineComponent, getCurrentInstance, computed } from 'vue';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { getMappedComponent } from '../ComponentMapping.jsx';

import { Constants } from '../Constants.js';
import Utils from '../Utils.js';




const AEMContainer = defineComponent({
	name: 'AEMContainer',
	props: {
		allowedComponents: { type: Object, default: () => ({}) },
		cqType: { type: String, default: '' },
		
		cqItems: { type: Object, default: () => ({}) },
		cqItemsOrder: { type: Array, default: () => [] },
		
		columnCount: { type: Number, default: 12 },
		gridClassNames: { type: String, default: '' },
		
		columnClassNames: { type: Object, default: () => ({}) },
	},
	setup (props, context) {
		const instance = getCurrentInstance();
		
		const getItemComponent = (ItemComponent, itemProps, itemKey) => {
			const { cqPath, columnClassNames } = props;

			const itemPath = cqPath ? (`${cqPath}/${itemKey}`) : itemKey;
			const containerClass = `${itemProps?.className ?? ''} ${columnClassNames?.[itemKey] ?? ''}`;

			return (
				<ItemComponent
					{...itemProps}
					cqPath={itemPath}
					containerClass={containerClass}
					key={itemKey}
					hasEditableCapability={false}
					{...context.attrs}
				/>
			);
		};
		const childComponents = computed(() => {
			const { cqItems, cqItemsOrder } = props;
			if (!cqItems || !cqItemsOrder) return [];

			return cqItemsOrder.map((itemKey) => {
				const itemProps = Utils.modelToProps(cqItems[itemKey]);
				if (!itemProps) return null;

				const ItemComponent = getMappedComponent(itemProps.cqType);
				if (!ItemComponent) return null;
				return getItemComponent(ItemComponent, itemProps, itemKey);
			});
		});
		
		
		
		return {
			childComponents,
		};
	},
	render () {
		
		return (
			<div
				class={`AEMContainer ${this.gridClassNames}`}
				data-component="AEMContainer"
			>
				{this.childComponents}
			</div>
		);
	},
});



export {
	AEMContainer,
};
