
import { h, defineComponent } from 'vue';
import { Constants } from '../../Constants.js';


/**
 * Placeholder for one Allowed Component.
 */
const AllowedComponentsPlaceholder = defineComponent({
	name: 'AllowedComponentsPlaceholder',
	props: {
		emptyLabel: { type: String, default: '' },
		path: { type: String, default: '' },
	},
	render () {
		return (
			<div
				class={`aem-AllowedComponent--component ${Constants._PLACEHOLDER_CLASS_NAMES} placeholder`}
				data-cq-data-path={this.path}
				data-emptytext={this.emptyLabel}
			></div>
		);
	},
});

export {
	AllowedComponentsPlaceholder,
};
