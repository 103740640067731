<script>
export default {
	name: 'SwitchTab',
	inheritAttrs: false,
};
</script>

<script setup>
import { getCompositeComponent } from '@custom/aem-vue-editable-components';
import Parsys from '~/components/Parsys.vue';
import VueSwiper from '~/components/VueSwiper.vue';
import Anchor from '~/logic/helpers/Anchor.js';
import { useRouteHash } from '~/logic/composables/useRouteHash.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';

const props = defineProps({
	/* Expected data format:
		[
			{
				tabTitle: 'Tab 1',
				anchorTag: 'tab-1',
				selectedDefault: false,
			},
		]
	*/
	tabList: { type: Array, default: () => [] },
	
	tabSwiperOptions: { type: Object, default: () => ({}) },
	useSlotAsContent: { type: Boolean, default: false },
	updateHashOnSwitch: { type: Boolean, default: true },
	contentContainerClass: { type: String, default: '' },
	desktopAllowTouchMove: { type: Boolean, default: false },
	componentIdAA: { type: String, default: '' },
	componentNameAA: { type: String, default: 'SwitchTab' },
	hideTabs: { type: Boolean, default: false },
});

const emit = defineEmits([
	'update:active-tab',
]);

const siteName = window.siteName;

const { isRTL } = useIsRTL();

const activeTab = ref(props.tabList.find((tab) => tab.selectedDefault)?.anchorTag ?? props.tabList[0]?.anchorTag ?? '');
const rootEl = ref(null);
const swiperEl = ref(null);

let skipNextHashChange = false;

const switchTab = (anchorTag) => {
	activeTab.value = anchorTag;
	emit('update:active-tab', activeTab.value);
};

const handleTabClick = async (anchorTag) => {
	if (anchorTag === activeTab.value) return;
	
	skipNextHashChange = true;
	Anchor.activate(anchorTag, { updateHash: props.updateHashOnSwitch });
};


const switchTabParsys = computed(() => {
	return props.tabList.map((item) => {
		return {
			component: getCompositeComponent({
				type: 'mh/components/mh-layout-container',
				componentKey: `switch-tab-parsys-${item.anchorTag}`,
			}),
			...item,
		};
	});
});


const allAnchors = computed(() => {
	return props.tabList.map((item) => item.anchorTag);
});

const { isEditorMode } = useGlobalAEMState();


const handleHashChange = (newValue) => {
	if (skipNextHashChange) {
		skipNextHashChange = false;
		return;
	}
	if (!allAnchors.value.includes(newValue)) return; // only react to hash changes relevant to our component
	Anchor.activate(newValue, { updateHash: false, performScroll: true });
};

// -------- Anchor logic --------
onMounted(() => {
	// skip this anchor logic in editor mode
	if (isEditorMode.value) return;
	
	// loop through each item, and register anchor tag
	props.tabList.forEach((item) => {
		if (!item.anchorTag) return; // skip for child without any anchorTag
		
		// register the id of anchor with root element, then on activate, we switch to that tab
		Anchor.register(item.anchorTag, rootEl.value, { updateHash: true, performScroll: false }).on('activate', () => {
			switchTab(item.anchorTag);
		});
	});
	
	/* 
		Hardcode a setTimeout value of 500ms for now.
		The issue is, we don't know how long does it take for page to finish layouting,
		as this is due to we implement lazy loading for component a lot,
		so there are quite a few API calls upon initial page load.
	*/
	setTimeout(() => {
		handleHashChange(hash.value);
	}, 500);
});

const { hash } = useRouteHash();
watch(hash, handleHashChange);

const switchTabArrowNext = ref(null);
const switchTabArrowPrev = ref(null);


defineExpose({
	rootEl,
	switchTab,
	swiperEl,
});

</script>



<template>
<div ref="rootEl" class="SwitchTab" :class="$attrs.class" :style="$attrs.style" :data-use-theme="siteName">
	<div v-if="!props.hideTabs" class="SwitchTabHeader generic-container">
		<div class="SwitchTabHeader-inner-wrapper flex justify-center relative">
			<VueSwiper
				v-if="switchTabArrowNext && switchTabArrowPrev"
				ref="swiperEl"
				class="swiper-header"
				variant="slides-auto-width"
				slidesPerView="auto"
				:breakpoints="{
					'767.1': {
						navigation: {
							nextEl: isRTL ? switchTabArrowPrev : switchTabArrowNext,
							prevEl: isRTL ? switchTabArrowNext : switchTabArrowPrev,
						},
						allowTouchMove: props.desktopAllowTouchMove,
					},
				}"
				v-bind="props.tabSwiperOptions"
				:dir="isRTL ? 'rtl' : 'ltr'"
			>
				<button
					v-for="(item, index) in props.tabList"
					:key="item.anchorTag"
					v-aa="[
						{
							clickName: item.tabTitle,
							clickComponentType: 'Button',
							componentName: props.componentNameAA,
							componentID: props.componentIdAA,
						},
						{
							name: item.tabTitle,
							type: 'other',
						},
					]"
					class="SwitchTabHeaderItem px-0 py-0"
					:class="{ 'text-neutral-white-base selected': activeTab == item.anchorTag }"
					:style="{
						'--focus-visible-outline-offset': `-2px`,
						'--focus-visible-outline-width': `0`,
					}"
					type="button"
					:aria-label="item.tabTitle"
					@click="handleTabClick(item.anchorTag)"
				>
					<div class="SwitchTabHeaderItem-focus-ring"></div>
					
					<div class="SwitchTabHeaderItemLeft bg-neutral-grey-extralight w-9 md:w-7.5 h-full transform" :class="{ 'bg-transparent': index === 0 }">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 36 48"
							class="h-full relative right-[-1px] transform rtl:rotate-y-180 rtl:right-auto rtl:left-[-1px]"
							:class="[activeTab == item.anchorTag ? 'fill-primary-blue-base h-full' : 'fill-neutral-grey-extralight']"
						>
							<g transform="translate(-366 -200)">
								<path
									d="M266.225,0H218.887c-11.415,0-16.023,12-16.023,12L184.9,48H230l17.966-36S254.4-.172,266.225,0Z"
									transform="translate(182 200.002)"
								/>
							</g>
						</svg>
					</div>
					<div
						class="SwitchTabHeaderItemCenter relative flex-grow h-full flex items-center justify-center"
						:class="activeTab === item.anchorTag ? 'bg-primary-blue-base' : 'bg-neutral-grey-extralight'"
					>
						{{ item.tabTitle }}
					</div>
					<div class="SwitchTabHeaderItemRight bg-neutral-grey-extralight w-8 md:w-7 h-full transform rtl:rotate-y-180" :class="{ 'bg-transparent': index === props.tabList.length - 1 }">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 32 48"
							class="h-full relative left-[-1px]"
							:class="[activeTab == item.anchorTag ? 'fill-primary-blue-base h-full' : 'fill-neutral-grey-extralight']"
						>
							<g transform="translate(-354 -200)">
								<path
									d="M36.225,0H190.887c18.595,0,11.977,12,11.977,12L184.9,48H0L17.966,12S24.4-.172,36.225,0Z"
									transform="translate(182 200.002)"
								/>
							</g>
						</svg>
					</div>
				</button>
			</VueSwiper>
			<div class="SwitchTabArrow-wrapper justify-end right-0">
				<button
					ref="switchTabArrowNext"
					class="SwitchTabArrow next ml-1"
					type="button"
				>
					<icon-far-angle-right
						class="text-lg fill-primary-blue-base"
					/>
				</button>
			</div>
			<div class=" SwitchTabArrow-wrapper justify-start left-0">
				<button
					ref="switchTabArrowPrev"
					type="button"
					class="SwitchTabArrow prev mr-1"
				>
					<icon-far-angle-left class="text-lg fill-primary-blue-base" />
				</button>
			</div>
		</div>
	</div>
	<div class="SwitchTabContent relative z-10" :class="contentContainerClass">
		<template v-if="props.useSlotAsContent">
			<div
				v-for="(item) in props.tabList"
				v-show="isEditorMode || activeTab === item.anchorTag"
				:key="item.anchorTag"
				class="switch-tab-content-wrapper"
				:data-tab-content-name="item.anchorTag"
			>
				<slot :name="`content-${item.anchorTag}`"></slot>
			</div>
		</template>
		
		<template v-else>
			<Parsys
				v-for="(item, index) in switchTabParsys"
				:key="item.anchorTag"
				:visible="activeTab === item.anchorTag"
				:component="item.component"
				:label="`#${item.anchorTag}`"
				:index="index"
				:data-tab-id="item.anchorTag"
			/>
		</template>
	</div>
</div>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SwitchTab {
	.swiper-button-disabled {
		display: none;
	}
}
.SwitchTabHeaderItem {
	min-width: 146px;
	@apply text-center font-semibold flex items-center justify-center cursor-pointer h-10 overflow-hidden;
	@apply hover:text-primary-blue-base;
	max-width: calc(0.8 * (100vw - 2rem));
	border-radius: 0;
	/* margin-left: -1px; */ // can maybe fix the 1px rendering issue?
	
	@media #{var.$query-min-xs} { max-width: calc(0.8 * #{var.$breakpoint-xs}px - 2rem) }
	@media #{var.$query-min-sm} { max-width: calc(0.8 * #{var.$breakpoint-sm}px - 2rem) }
	@media #{var.$query-min-md} {
		min-width: 200px;
		max-width: calc(0.6 * #{var.$breakpoint-md}px - 2rem);
		@apply h-12;
	}
	@media #{var.$query-min-lg} { max-width: calc(0.6 * #{var.$breakpoint-lg}px - 2rem) }
	@media #{var.$query-min-xl} { max-width: calc(0.6 * #{var.$breakpoint-xl}px - 2rem) }
	@media #{var.$query-min-2xl} { max-width: calc(0.6 * #{var.$breakpoint-2xl}px - 2rem) }
	
	&:focus-visible {
		/* background-color: var(--neutral-grey-light); */
		/* background-color: var(--primary-blue-extralight); */
		
		.SwitchTabHeaderItem-focus-ring {
			opacity: 1;
			transform: scale(1);
		}
	}
	
}
.SwitchTabHeaderItem-focus-ring {
	position: absolute;
	transition: all 0.3s;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	top: 0;
	left: 1px;
	border-radius: 6px;
	z-index: 30;
	pointer-events: none;
	border: 3px solid var(--semantic-blue-base);
	transform-origin: center center;
	opacity: 0;
	transform: scale(1.2);
}
.SwitchTabHeaderItem.selected {
	@apply hover:text-neutral-white-base;
}
.SwitchTabArrow {
	@apply h-9 w-9 inline-flex items-center justify-center p-0 hover:(bg-neutral-grey-base rounded-full bg-opacity-20) active:bg-opacity-50;
}
.SwitchTabArrow-wrapper {
	@apply bg-neutral-white-base z-10 h-full flex items-center flex-shrink-0  absolute bottom-0 md:hidden;
}


// make the gap of each tab lesser (to almost 0), but only in browsers that support :has() (parent selector)
@supports selector(.parent:has(.child)) {
	.SwitchTab:deep(.SwitchTabHeader .swiper-slide) {
		margin-right: -20px; // <-- ❗ probably need to follow the width of the left side SVG. Hardcoding 20px for now.

		&:has(.selected) {
			z-index: 10;
		}

		&:last-child {
			margin-right: 0;
		}
	}
	.SwitchTab:deep(.swiper-slide) {
		&:has(.SwitchTabHeaderItem:focus-visible) {
			z-index: 20;
		}
	}
	
	.SwitchTab[data-use-theme="firefly"] {
		:deep(.SwitchTabHeader .swiper-slide) {
			margin-right: 0;
		}
	}

}


// Firefly
.SwitchTab[data-use-theme="firefly"] {
	.SwitchTabHeader-inner-wrapper {
		align-items: center;
	}
	
	.swiper-header {
		background-color: white;
		@apply p-1 rounded-2xl;
		@apply rounded-br-none;
	}
	
	.SwitchTabHeaderItemLeft,
	.SwitchTabHeaderItemRight {
		display: none;
	}
	
	.SwitchTabHeaderItem {
		&:hover {
			color: var(--secondary-firefly-orange-extradark);
		}
	}
	
	.SwitchTabArrow-wrapper {
		position: relative;
		background-color: transparent;
		
		&.left-0 {
			order: -1;
		}
	}
	
	.SwitchTabHeaderItemCenter {
		@apply rounded-2xl;
		@apply rounded-br-none;
		
		&.bg-neutral-grey-extralight {
			background-color: transparent;
		}
		&.bg-primary-blue-base {
			color: var(--text-color);
			background-color: var(--primary-firefly-orange-base);
		}
	}
}

</style>
