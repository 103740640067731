<script>
export default {
	name: 'DesktopCountrySelector',
	inheritAttrs: false,
};
</script>

<script setup>
import useDisableScroll from '~composables/useDisableScroll.js';
import { onClickOutside } from '@vueuse/core';
import { setCountryAndLocaleCookie } from '~/logic/aem/set-country-and-locale-cookie.js';

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	selectedCountryLabelDesktop: { type: String, default: '' },
	allCountriesLabelDesktop: { type: String, default: '' },
	// countryLanguageItems: { type: Object, default: () => ({}) },
	allCountriesAndLanguages: { type: Array, default: () => [] },
	selectedCountryAndLanguage: { type: Object, default: () => ({}) },
	countryLanguageRedirectBaseUrl: { type: String, default: '' },
});
const emit = defineEmits([
	'update:isVisible',
]);

const internalVisible = ref(props.isVisible);

watch(() => props.isVisible, (newValue) => {
	internalVisible.value = newValue;
});
watch(internalVisible, (newValue) => {
	emit('update:isVisible', newValue);
});

useDisableScroll(internalVisible);

const hideOverlay = () => {
	internalVisible.value = false;
};


const rootEl = ref(null);

const handleRootBlurredWithin = (event) => {
	internalVisible.value = false;
};


const handleLanguageLinkClick = (country, language) => {
	// console.log(`handleLanguageLinkClick: ${country}, ${language}`);
	setCountryAndLocaleCookie({ country, language });
};



onClickOutside(rootEl, (event) => {
	internalVisible.value = false;
});

const getRedirectLink = ({ country, lang } = {}) => {
	const path = window.location.pathname;
	return `${props.countryLanguageRedirectBaseUrl}?country=${country}&lang=${lang}&path=${path}`;
};

const siteName = window.siteName;


</script>

<template>
<div class="DesktopCountrySelector">

</div>
<Teleport to="body">
	<div class="generic-backdrop"></div>
</Teleport>
<!-- 'transform' class is for Safari fix -->
<div
	v-if="isVisible"
	ref="rootEl"
	v-focus-within
	class="DesktopCountrySelector bg-white overflow-hidden transform"
	v-bind="$attrs"
	tabindex="-1"
	:data-use-theme="siteName"
	@keydown.esc.capture="hideOverlay"
	@blur-within="handleRootBlurredWithin"
>
	<div class="generic-container py-8">
		<div class="grid grid-cols-5 auto-rows-min items-start gap-x-4 xl:gap-x-1 gap-y-8 font-semibold leading-5">
			<div class="grid items-start row-span-2 gap-y-6 rows-subgrid">
				<div class="typo-category-title">{{ props.selectedCountryLabelDesktop }}</div>
				<div class="grid grid-rows-2 gap-3 font-normal">
					<div class="text-base leading-5">{{ props.selectedCountryAndLanguage.country }}</div>
					<div class="flex items-start">
						<template
							v-for="(selectedCountryLanguage, selectedCountryLanguageIndex) in props.selectedCountryAndLanguage.languages"
							:key="selectedCountryLanguageIndex"
						>
							<AppHyperlink
								:href="getRedirectLink({ country: selectedCountryLanguage.countryCode, lang: selectedCountryLanguage.language })"
								class="link"
								:aria-label="`${props.selectedCountryAndLanguage.country} ${selectedCountryLanguage.languageTitle}`"
								:forceATag="true"
								:addHtmlExtension="false"
								@click="handleLanguageLinkClick(selectedCountryLanguage.countryCode, selectedCountryLanguage.language)"
							>
								{{ selectedCountryLanguage.languageTitle }}
							</AppHyperlink>
							<span
								v-if="(props.selectedCountryAndLanguage.languages.length - 1) !== selectedCountryLanguageIndex"
								class="dot mx-2"
							>•</span>
						</template>
					</div>
				</div>
			</div>
			<div class="col-span-4 grid items-start row-span-2 gap-y-6 rows-subgrid">
				<div class="typo-category-title">{{ props.allCountriesLabelDesktop }}</div>
				<div class="grid grid-cols-4 gap-x-8 lg:gap-x-4 font-normal">
					<div
						v-for="(allCountriesLanguages, allCountriesLanguagesIndex) in props.allCountriesAndLanguages"
						:key="allCountriesLanguagesIndex"
						class="grid row-span-2 rows-subgrid pb-8"
					>
						<div class="text-base leading-5 pb-3" style="">{{ allCountriesLanguages.pageTitle }}</div>
						<div class="flex items-start">
							<template
								v-for="(countryLanguage, countryLanguageIndex) in allCountriesLanguages.languages"
								:key="countryLanguageIndex"
							>
								<AppHyperlink
									:href="countryLanguage.path"
									class="link"
									:aria-label="`${allCountriesLanguages.pageTitle} ${countryLanguage.languageTitle}`"
									:forceATag="true"
									:addHtmlExtension="false"
									@click="handleLanguageLinkClick(countryLanguage.countryCode, countryLanguage.language)"
								>
									{{ countryLanguage.languageTitle }}
								</AppHyperlink>
								<span
									v-if="(allCountriesLanguages.languages.length - 1) !== countryLanguageIndex"
									class="dot mx-2"
								>•</span>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.DesktopCountrySelector {
	position: fixed;
	z-index: 7000;
	left: 0;
	width: 100%;
	// after applying transform to header, this 'top' need to change
	/* top: calc( var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight) + var(--headerHeight) ); */
	top: var(--headerHeight);
	transition: opacity 0.3s ease;
	min-height: 500px;
	/* box-shadow: 0 4px 12px color.change(var.$primary-black-base, $alpha: 0.12); */
	@apply shadow-type-a;
}

.rows-subgrid {
	grid-template-rows: subgrid;
}

.link {
	color: var(--primary-blue-base);

	&:hover {
		text-decoration: underline;
		color: var(--primary-blue-light);
	}
}

.DesktopCountrySelector[data-use-theme="firefly"] {
	.link {
		color: var(--primary-firefly-orange-base);

		&:hover {
			text-decoration: underline;
			color: var(--primary-firefly-orange-base);
		}
	}
}


</style>
