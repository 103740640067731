import dayjs from 'dayjs';


const _getDateOperationArgs = (str) => {
	let unit = 'day';
	if (str.endsWith('d')) unit = 'day';
	if (str.endsWith('m')) unit = 'month';
	if (str.endsWith('y')) unit = 'year';
	const amount = parseInt(str.replace(/[dmy]$/, ''), 10);
	return [amount, unit];
};

const _getDisableDateChecker = (computedRestrictions) => {
	let restrictionMinDate = computedRestrictions.computedMinDate;
	let restrictionMaxDate = computedRestrictions.computedMaxDate;
	let restrictionDisabledDates = computedRestrictions.computedDisabledDates;
	
	if (restrictionMinDate && restrictionMaxDate && restrictionMinDate.isAfter(restrictionMaxDate, 'day')) {
		console.error(`minDate cannot be later than maxDate. Forcing minDate to be null.`);
		restrictionMinDate = null;
	}
	
	return (dayjsInstance, checkFor = 'day') => {
		if (!restrictionMinDate && !restrictionMaxDate && restrictionDisabledDates.length === 0) {
			return false;
		}
		
		let result = false;
		
		if (restrictionMinDate) {
			result = dayjsInstance.isBefore(restrictionMinDate, checkFor);
		}
		if (!result && restrictionMaxDate) {
			result = dayjsInstance.isAfter(restrictionMaxDate, checkFor);
		}
		if (!result && restrictionDisabledDates.length > 0) {
			result = restrictionDisabledDates.some((d) => d.isSame(dayjsInstance, 'day'));
		}
		
		return result;
	};
};

const _returnFalseFunc = () => false;

export const parseSpecialDateSyntaxToDayjs = (refDate, str, fallback) => {
	if (str === null) return null;
	let result = Number.NaN;
	
	if (str.startsWith('t')) {
		// relative date
		if (str.startsWith('t+')) {
			result = refDate.add(..._getDateOperationArgs(str.replace(/^t\+/, '')));
		} else if (str.startsWith('t-')) {
			result = refDate.subtract(..._getDateOperationArgs(str.replace(/^t-/, '')));
		} else if (str === 't') {
			result = refDate;
		}
		
		// ---- safety net for broken syntax ----
		if (Number.isNaN(result)) {
			if (fallback) {
				console.warn(`Cannot understand the syntax "${str}". Fallback to "${fallback}".`);
				result = parseSpecialDateSyntaxToDayjs(refDate, fallback);
			} else {
				console.warn(`Cannot understand the syntax "${str}". No fallback given, defaults to null.`);
				result = null;
			}
		}
	} else {
		// is absolute date, needs to convert to dayjs
		result = dayjs(str);
		
		// -- safety net for invalid date string --
		if (!result.isValid()) {
			if (fallback) {
				console.warn(`"${str}" is not a valid date that dayjs recognizes. Fallback to "${fallback}"`);
				result = parseSpecialDateSyntaxToDayjs(refDate, fallback);
			} else {
				console.warn(`"${str}" is not a valid date that dayjs recognizes. No fallback given, defaults to null.`);
				result = null;
			}
		}
	}
	
	return result;
};

export function useCalendarRestrictions (restrictions) {
	const computedMinDate = computed(() => {
		return parseSpecialDateSyntaxToDayjs(dayjs(), restrictions.value.minDate ?? null);
	});
	const computedMaxDate = computed(() => {
		return parseSpecialDateSyntaxToDayjs(dayjs(), restrictions.value.maxDate ?? null);
	});
	
	const computedDisabledDates = computed(() => {
		return restrictions.value?.disabledDates?.map((d) => dayjs(d)).filter((d) => {
			return d.isValid();
		}) || [];
	});
	
	const checkIsDisabledFunc = computed(() => {
		if (!restrictions.value) return _returnFalseFunc;
		const func = _getDisableDateChecker({
			computedMinDate: computedMinDate.value,
			computedMaxDate: computedMaxDate.value,
			computedDisabledDates: computedDisabledDates.value,
		});
		return func;
	});
	
	
	return {
		computedMinDate,
		computedMaxDate,
		computedDisabledDates,
		checkIsDisabledFunc,
	};
}
