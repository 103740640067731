import { h, defineAsyncComponent } from 'vue';

import { WithModelAndEditable } from './components/WithModelAndEditable.jsx';


/* 
ComponentMapping.map = function map ({ resourceTypes, Component, editConfig, modelConfig }, mapToConfig) {
	const finalModelConfig = {
		...({
			injectPropsOnInit: true,
			forceReload: false,
		}),
		...modelConfig,
	};
	
	let innerComponent = Component;

	if (mapToConfig.skipWrapping) {
		innerComponent = Component;
	} else {
		// innerComponent = withModel(withEditable(Component, editConfig), finalModelConfig);
		innerComponent = WithModelAndEditable(Component, editConfig, finalModelConfig);
	}
	
	return innerComponent;
};



const MapTo = (resourceTypes, mapToConfig = { skipWrapping: false }) => {
	return (Component, editConfig) => {
		const mainConfig = { resourceTypes, Component, editConfig };
		return ComponentMapping.map(mainConfig, mapToConfig);
	};
};
 */




const mapped = new Map();


const setMapping = (resourceType, component) => {
	mapped.set(resourceType, { component });
};

const getMappedComponent = (resourceType) => {
	const result = mapped.get(resourceType);
	return result?.component;
};



export {
	setMapping,
	getMappedComponent,
};
