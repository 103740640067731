import { useWindowScroll } from '@vueuse/core';
import { debounce as _debounce } from 'lodash';

const paused = ref(false);
let hasInit = false;
const MIN_TRIGGER_THRESHOLD = 100;

const setupScrollMechanism = () => {
	const { y: scrollY } = useWindowScroll();
	
	const updateTranslateY = (direction) => {
		// console.log('updateTranslateY');
		if (direction === 'up') {
			document.documentElement.style.setProperty('--headerTranslateY', '0%');
			document.documentElement.style.setProperty('--headerNotificationTranslateY', '0%');
			document.documentElement.style.setProperty('--mobileDlAppNotificationTranslateY', '0%');
			document.documentElement.classList.remove('scroll-dir-down');
			document.documentElement.classList.add('scroll-dir-up');
		} else {
			document.documentElement.style.setProperty('--headerTranslateY', 'calc( (100% + (var(--mobileDlAppNotificationHeight) + var(--headerNotificationHeight)) ) * -1 )');
			document.documentElement.style.setProperty('--headerNotificationTranslateY', 'calc( (100% + var(--mobileDlAppNotificationHeight) ) * -1 )');
			document.documentElement.style.setProperty('--mobileDlAppNotificationTranslateY', '-100%');
			document.documentElement.classList.remove('scroll-dir-up');
			document.documentElement.classList.add('scroll-dir-down');
		}
	};

	const debouncedUpdateTranslateY = _debounce(updateTranslateY, 100, { leading: true });
	
	let prevScrollDir = '';
	let prevScrollY = 0;
	
	watch(scrollY, (newScrollY) => {
		/* See useDisableScroll.js
			This flag 'ignoreNextScrollEvent' prevents header from appearing abruptly when overlay is triggered 
		*/
		if (window.ignoreNextScrollEvent) {
			window.ignoreNextScrollEvent = false;
			return;
		}
		if (paused.value) return;
		if (prevScrollY === newScrollY) return;
		const newDirection = (prevScrollY > newScrollY) ? 'up' : 'down';
		if (newDirection === 'down' && scrollY.value <= MIN_TRIGGER_THRESHOLD) {
			return;
		}
		if (prevScrollDir !== newDirection) debouncedUpdateTranslateY(newDirection);
		prevScrollY = newScrollY;
		prevScrollDir = newDirection;
	});
};

export function useHeaderScroll () {
	if (!hasInit) {
		setupScrollMechanism();
		hasInit = true;
	}
	
	return {
		paused,
	};
}

