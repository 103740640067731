<script>
import { getMappedComponent, Constants, AEMPage, Utils } from '@custom/aem-vue-editable-components';

import WrapVNodes from '~/logic/WrapVNodes.js';
import { useRoute } from 'vue-router';
import DefaultLayout from '~/layout/DefaultLayout.vue';
import TEMPLATE_NAME_TO_LAYOUT_MAP from '~/components-mapping/mapping-data-layout.js';
import SkipToMainContent from '~/components/SkipToMainContent.vue';
import { useAuthStore } from './logic/auth/auth-store';
import { useGlobalAEMState } from './logic/aem/global-aem-state';

export default {
	name: 'App',
	expose: [],
	components: {
		WrapVNodes,
		DefaultLayout,
		SkipToMainContent,
	},
	props: {
		cqChildren: { type: Object, default: () => ({}) },
		cqItems: { type: Object, default: () => ({}) },
		cqItemsOrder: { type: Array, default: () => [] },
		cqPath: { type: String, default: '' },
		
		injectPropsOnInit: { type: Boolean, default: true },
		cqForceReload: { type: Boolean, default: false },
		
		templateName: { type: String, default: '' },
		language: { type: String, default: '' },
		title: { type: String, default: '' },
	},
	setup (props, context) {
		
		const getItemComponent = (ChildComponent, itemProps, itemKey) => {
			const { cqItems, cqItemsOrder, cqPath } = props;
			const itemPath = cqPath ? `${cqPath}/${Constants.JCR_CONTENT}/${itemKey}` : itemKey;
			
			return h(
				ChildComponent,
				{
					...itemProps,
					cqPath: itemPath,
				}
			);
		};
		const childComponents = computed(() => {
			const { cqItems, cqItemsOrder } = props;
			if (!cqItems || !cqItemsOrder) return [];
			
			return cqItemsOrder.map((itemKey) => {
				const itemProps = Utils.modelToProps(cqItems[itemKey]);
				if (!itemProps) return null;

				const ItemComponent = getMappedComponent(itemProps.cqType);
				if (!ItemComponent) return null;
				
				return getItemComponent(ItemComponent, itemProps, itemKey);
			});
		});
		
		
		const route = useRoute();
		
		const layoutComponent = computed(() => {
			const result = route.meta?.layout || TEMPLATE_NAME_TO_LAYOUT_MAP[props.templateName] || DefaultLayout;
			return result;
		});
		
		// 👇 DO NOT DELETE the line below 👇 it is required to prevent a runtime error (from Vue) in non-en pages.
		// I also do not know why.
		// eslint-disable-next-line
		childComponents.value;
		
		return {
			route,
			Constants,
			childComponents,
			layoutComponent,
		};
	},
	beforeMount () {
		//	👇this is a temporay fix for the mata cug campaign👇
		//	here we will chec the path contain CUG page
		//	alternatively we can modify router.beforeEach in main.js to create protected routes
		const { pageProperties } = useGlobalAEMState();
		const { mhloginRequired, mattaFairLoginRedirectUrl } = pageProperties.value;
		const { isSapphireCookiePresent } = useAuthStore();
		if (mhloginRequired && !isSapphireCookiePresent()) {
			if (!mattaFairLoginRedirectUrl) {
				throw new Error('Invalid mattaFairLoginRedirectUrl');
			}

			window.location.href = mattaFairLoginRedirectUrl;
		}
	},
};
</script>


<!-- Commented this, seems fine -->
<!-- <WrapVNodes
	v-if="childComponents.length > 0"
	:nodes="childComponents"
/> -->
<template>
<div
	:class="`${Constants._PAGE_CLASS_NAMES} app-root`"
	:data-cq-data-path="cqPath"
>
	<SkipToMainContent />
	<component
		:is="layoutComponent"
		:aemProperties="route.matched?.[0]?.props.default"
	>
		<router-view />
	</component>
</div>
</template>
