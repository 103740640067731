import { defineAsyncComponent } from 'vue';
import mappingData from '~/components-mapping/mapping-data-component.js';
import { AEMPage, DefaultEditConfig, setMapping, getMappedComponent, WithModelAndEditable } from '@custom/aem-vue-editable-components';
import AppSpinner from '~/components/AppSpinner.vue';

import MHLayoutContainer from '~/aem-components/MHLayoutContainer.jsx';
import ExperienceFragment from '~/aem-components/ExperienceFragment.vue';

import PageProperties from '~/aem-components/PageProperties.vue';
import MobileDownloadAppNotification from '~/aem-components/MobileDownloadAppNotification.vue';
import HeaderNotification from '~/aem-components/HeaderNotification.vue';
import AppHeader from '~/aem-components/AppHeader.vue';
import AppFooter from '~/aem-components/AppFooter.vue';
import FloatingWidget from '~/aem-components/FloatingWidget.vue';
import Carousel from '~/aem-components/Carousel.vue';
import Banner from '~/aem-components/Banner.vue';
import BookingWidget from '~/aem-components/booking/BookingWidget.vue';
import TextComponent from '~/aem-components/TextComponent.vue';
import EmptyGap from '~/aem-components/EmptyGap.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import CookiesNotification from '~/aem-components/CookiesNotification.vue';
import ColumnControl from '~/aem-components/ColumnControl.vue';
import Divider from '~/aem-components/Divider.vue';
import ImageComponent from '~/aem-components/ImageComponent.vue';


const SYNC_COMPONENTS = [
	'/src/aem-components/PageProperties.vue',
	'/src/aem-components/MobileDownloadAppNotification.vue',
	'/src/aem-components/HeaderNotification.vue',
	'/src/aem-components/AppHeader.vue',
	'/src/aem-components/ExperienceFragment.vue',
	'/src/aem-components/AppFooter.vue',
	'/src/aem-components/FloatingWidget.vue',
	'/src/aem-components/Carousel.vue',
	'/src/aem-components/Banner.vue',
	'/src/aem-components/booking/BookingWidget.vue',
	'/src/aem-components/TextComponent.vue',
	'/src/aem-components/EmptyGap.vue',
	'/src/aem-components/CTAButton.vue',
	'/src/aem-components/CookiesNotification.vue',
	'/src/aem-components/ColumnControl.vue',
	'/src/aem-components/Divider.vue',
	'/src/aem-components/ImageComponent.vue',
];

// async version
const doMappingLogic_async = () => {
	const modules = import.meta.glob('~/aem-components/**/*.vue');
	
	for (const [path, modAsyncFunc] of Object.entries(modules)) {
		const transformedPath = path.replace(/^\.\./, '~');
		const mappingInfo = mappingData.find((item) => transformedPath.endsWith(item.componentPath));
		
		if (SYNC_COMPONENTS.includes(path)) {
			// Hardcoding that we would skip these components, due to it being made not lazy
			continue;
		}
		
		if (!mappingInfo) {
			console.warn(`Cannot find corresponding component for "${path}". This is a no-op.`);
			continue;
		}
		
		setMapping(mappingInfo.resourceType, WithModelAndEditable(defineAsyncComponent({
			loader: modAsyncFunc,
			// loader: async () => {}, // <-- enable this to see AppSpinner
			delay: 7000,
			loadingComponent: h(AppSpinner, { class: 'mx-auto' }),
		}), mappingInfo?.editConfig));
	}
	setMapping('mh/components/page', AEMPage);
	setMapping('mh/components/page-structure/homepage', AEMPage);
	setMapping('mh/components/page-structure/root-page', AEMPage);
	setMapping('mh/components/page-structure/destination-details-page', AEMPage);
	setMapping('mh/components/page-structure/article-page', AEMPage);
	setMapping('mh/components/page-structure/root-page', AEMPage);
	setMapping('mh/components/page-structure/content-page', AEMPage);

	setMapping('mh/components/page-structure/mag-root-page', AEMPage);
	setMapping('mh/components/page-structure/mag-homepage', AEMPage);
	setMapping('mh/components/page-structure/mag-content-page', AEMPage);
	setMapping('mh/components/page-structure/mag-article-page', AEMPage);

	setMapping('mh/components/page-structure/mhh-root-page', AEMPage);
	setMapping('mh/components/page-structure/mhh-homepage', AEMPage);
	setMapping('mh/components/page-structure/mhh-content-page', AEMPage);

	setMapping('mh/components/page-structure/maba-root-page', AEMPage);
	setMapping('mh/components/page-structure/maba-homepage', AEMPage);
	setMapping('mh/components/page-structure/maba-content-page', AEMPage);
	setMapping('mh/components/page-structure/maba-article-page', AEMPage);

	setMapping('mh/components/page-structure/amal/amal-root-page', AEMPage);
	setMapping('mh/components/page-structure/amal/amal-homepage', AEMPage);
	setMapping('mh/components/page-structure/amal/amal-content-page', AEMPage);
	setMapping('mh/components/page-structure/amal/amal-article-page', AEMPage);

	setMapping('mh/components/page-structure/firefly-root-page', AEMPage);
	setMapping('mh/components/page-structure/firefly-homepage', AEMPage);
	setMapping('mh/components/page-structure/firefly-content-page', AEMPage);
	setMapping('mh/components/page-structure/firefly-article-page', AEMPage);

	// TODO: Enhance these logic to use 'overwriteMappingWithSyncComponent()' below. Need to first organize data in 'mapping-data-component.js'.
	setMapping('mh/components/page-properties', PageProperties);
	setMapping('mh/components/mh-layout-container', MHLayoutContainer);
	setMapping('mh/components/experience-fragment', WithModelAndEditable(ExperienceFragment, ExperienceFragment.editConfig));
	setMapping('mh/components/mobile-download-app-notification', WithModelAndEditable(MobileDownloadAppNotification, MobileDownloadAppNotification.editConfig));
	setMapping('mh/components/header-notification', WithModelAndEditable(HeaderNotification, HeaderNotification.editConfig));
	setMapping('mh/components/header', WithModelAndEditable(AppHeader, AppHeader.editConfig));
	setMapping('mh/components/footer', WithModelAndEditable(AppFooter, AppFooter.editConfig));
	setMapping('mh/components/floating-widget', WithModelAndEditable(FloatingWidget, FloatingWidget.editConfig));
	setMapping('mh/components/carousel', WithModelAndEditable(Carousel, Carousel.editConfig));
	setMapping('mh/components/banner', WithModelAndEditable(Banner, Banner.editConfig));
	setMapping('mh/components/booking/booking-widget', WithModelAndEditable(BookingWidget, BookingWidget.editConfig));
	setMapping('mh/components/text', WithModelAndEditable(TextComponent, TextComponent.editConfig));
	setMapping('mh/components/empty-gap', WithModelAndEditable(EmptyGap, EmptyGap.editConfig));
	setMapping('mh/components/cta-button', WithModelAndEditable(CTAButton, CTAButton.editConfig));
	setMapping('mh/components/cookies-notification', WithModelAndEditable(CookiesNotification, CookiesNotification.editConfig));
	setMapping('mh/components/column-control', WithModelAndEditable(ColumnControl, ColumnControl.editConfig));
	setMapping('mh/components/divider', WithModelAndEditable(Divider, Divider.editConfig));
	setMapping('mh/components/image', WithModelAndEditable(ImageComponent, ImageComponent.editConfig));
};


// sync version
/* const doMappingLogic_sync = () => {
	const modules = import.meta.globEager('~/aem-components/*.vue');
	for (const [path, mod] of Object.entries(modules)) {
		const transformedPath = path.replace(/^\.\./, '~');
		const mappingInfo = mappingData.find((item) => item.componentPath === transformedPath);
		setMapping(mappingInfo.resourceType, WithModelAndEditable(mod.default));
	}
	setMapping('mh/components/page', AEMPage);
};
 */

const doMappingLogic = doMappingLogic_async;


const getEditConfigByResourceType = (resourceType) => {
	return mappingData.find((obj) => obj.resourceType === resourceType);
};

const overwriteMappingWithSyncComponent = (resourceType, Component, isEditable = true) => {
	const componentDefinition = ( isEditable ?
		WithModelAndEditable(Component, getEditConfigByResourceType(resourceType)?.editConfig)
		:
		Component
	);
	
	setMapping(resourceType, componentDefinition);
};

export {
	doMappingLogic,
	getEditConfigByResourceType,
	overwriteMappingWithSyncComponent,
};



