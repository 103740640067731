import { getCurrentInstance } from 'vue';

export default function useInternalValue (propsKey = 'modelValue', _options = {}) {
	const instance = getCurrentInstance();
	const options = {
		eventName: `update:${propsKey}`,
		defaultValue: null,
		
		valueWatchCallback: null,
		propValueWatchCallback: null,
		
		beforeEmitTransform: (v) => v,
		propReceivedTransform: (v) => v,
		
		..._options,
	};
	const emit = instance.emit;
	const props = instance.props;
	
	if (!(propsKey in props)) {
		throw new Error(`useInternalValue: Prop '${propsKey}' does not exist`);
	}
	
	const value = ref(options.defaultValue);
	
	const setValue = (newValue) => {
		// only set the value if it is different
		if (value.value !== newValue) value.value = newValue;
		
		const newValueTransformed = options.beforeEmitTransform(newValue);
		// only emit if prop is out of date
		if (newValueTransformed !== props[propsKey]) emit(options.eventName, newValueTransformed);
	};
	
	const unwatch_value = watch(value, (newValue, oldValue) => {
		// internal value changed, intend to emit changes to parent
		setValue(newValue, oldValue);
		options.valueWatchCallback?.(newValue, oldValue);
	});
	
	const unwatch_prop = watch(() => props[propsKey], (newValue, oldValue) => {
		// prop changed, intend to update internal value
		const newValueTransformed = options.propReceivedTransform(newValue);
		if (value.value === newValueTransformed) return;
		value.value = newValueTransformed;
		options.propValueWatchCallback?.(newValueTransformed, oldValue);
	});
	
	// manually update the value on init, DO NOT USE { immediate: true }
	if (props[propsKey] !== undefined && props[propsKey] !== null) {
		const newValueTransformed = options.propReceivedTransform(props[propsKey]);
		if (value.value === newValueTransformed) return;
		const oldValue = value.value;
		value.value = newValueTransformed;
		options.propValueWatchCallback?.(newValueTransformed, oldValue);
	}
	
	const unwatchAll = () => {
		unwatch_value();
		unwatch_prop();
	};
	
	onUnmounted(() => {
		unwatchAll();
	});
	
	
	return value;
}
