import { useRouteHash } from '~/logic/composables/useRouteHash.js';
import { useRouteQuery } from '@vueuse/router';
import { useRoute } from 'vue-router';

/* 
Hash format, useRouteHash()
------------------------------------------
Valid Example 1: https://www.malaysiaairlines.com/my/en/home.html#flight-search-tab?subtab=book-flight&locationFrom=ADL&locationTo=BKK&dateDeparture=2023-02-02&dateReturn=2023-02-16&adultsCount=2&childrenCount=1&teensCount=1&infantsCount=1&cabinClass=3&promoCode=ABCDE123A

Valid Example 2: https://www.malaysiaairlines.com/my/en/home.html#?maintab=flight-search-tab&subtab=book-flight&locationFrom=ADL&locationTo=BKK&dateDeparture=2023-02-02&dateReturn=2023-02-16&adultsCount=2&childrenCount=1&teensCount=1&infantsCount=1&cabinClass=3&promoCode=ABCDE123A


Query format, useRouteQuery()
------------------------------------------
Valid Example 1: https://www.malaysiaairlines.com/my/en/home.html?maintab=flight-search-tab&subtab=book-flight&locationFrom=ADL&locationTo=BKK&dateDeparture=2023-02-02&dateReturn=2023-02-16&adultsCount=2&childrenCount=1&teensCount=1&infantsCount=1&cabinClass=3&promoCode=ABCDE123A
*/


export function useBookingWidgetUrlQuery ({ mainTabName, subTabName } = {}) {
	const { hash, hashQuery } = useRouteHash();
	const route = useRoute();
	
	const queryObject = computed(() => {
		const isQueryMode = unref(route.query).maintab === unref(mainTabName);
		let isSubTabMatch;
		
		// subTabName can be falsy, in that case, it would treat as match for any
		if (!unref(subTabName)) {
			isSubTabMatch = true;
		} else {
			isSubTabMatch = isQueryMode ? (unref(route.query).subtab === unref(subTabName)) : (unref(hashQuery).subtab === unref(subTabName));
		}
		
		if (
			// First, make sure main tab matches, by finding match in default hash value #match?... , OR #?maintab=match&...
			(
				unref(hash) === unref(mainTabName) ||
				unref(hashQuery).maintab === unref(mainTabName)
			) &&
			// Second, make sure subtab matches
			isSubTabMatch
		) {
			// using the hash format
			return {
				maintab: mainTabName,
				...unref(hashQuery),
			};
		}

		if (
			// only 1 way to match main tab in query mode, which is ?maintab=match
			unref(route.query).maintab === unref(mainTabName) &&
			isSubTabMatch
		) {
			// using the query format
			return route.query;
		}
		
		return null;
	});
	
	const callbacks = new Set();
	
	watch(queryObject, (newValue, oldValue) => {
		for (const item of callbacks) {
			if (typeof item.callback === 'function') {
				item.callback(newValue, oldValue);
				if (item.options?.once) unregisterQueryChange(item.callback);
			}
		}
	});
	
	const registerQueryChange = (_callback, _options = {}) => {
		const finalOptions = {
			immediate: true,
			..._options,
		};
		callbacks.add({
			callback: _callback,
			options: finalOptions,
		});
		if (finalOptions.immediate) {
			_callback(queryObject.value);
		}
		return unregisterQueryChange.bind(null, _callback);
	};
	const unregisterQueryChange = (_callback) => {
		for (const item of callbacks) {
			if (item.callback === _callback) {
				callbacks.delete(item);
				break;
			}
		}
	};
	
	
	return {
		registerQueryChange,
		unregisterQueryChange,
	};
}
