<script>
export default {
	name: 'MHupgrade',
	inheritAttrs: false,
};
</script>

<script setup>
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import SubTabUpgrade from '~/components/BookingWidget/ManageBooking/SubTabUpgrade.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useEventBus } from '@vueuse/core';
import { isMobileViewport } from '~/logic/composables/breakpoints';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	upgrade: { type: Object, default: () => ({}) },
	isInBookingWidget: { type: Boolean, default: false },
	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const rootEl = ref(null);

const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['mh-upgrade', 'mh-upgrade-tab'],
	rootEl,
});

const { isEditorMode } = useGlobalAEMState();



const globalBookFlightBus = useEventBus('booking-widget:book-flight');

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'mh-upgrade-tab',
});

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}
	globalBookFlightBus.emit('active-tab-and-fill-up-form', { mainTab: query.maintab ?? '' });
});

</script>

<template>
<div
	v-if="!props.isHideInDesktop && !isMobileViewport"
	ref="rootEl"
	class="MHupgrade booking-widget-subcomponent"
	:class="{
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Booking Widget: MHupgrade</span>
		</h5>
		
		<SubTabUpgrade
			v-if="!props.upgrade?.isHideInDesktop"
			:class="{ 'pt-16': props.upgrade.isToggle }"
			:apiUrl="props.upgrade.apiUrl"
			:apiPointsUrl="props.upgrade.apiPointsUrl"
			:bookingReference="props.upgrade.bookingReference"
			:lastName="props.upgrade.lastName"
			:description="props.upgrade.description"
			:tooltipLabel="props.upgrade.tooltipLabel"
			:tooltipBody="props.upgrade.tooltipBody"
			:buttonLabel="props.upgrade.buttonLabel"
			:note="props.upgrade.note"
			:isToggle="props.upgrade.isToggle"
			:toggleButtonLabel="props.upgrade.toggleButtonLabel"
			:togglePointsApiURL="props.togglePointsApiURL"
			:isMainTab="true"
		></SubTabUpgrade>
	</div>
</div>

<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--secondary-blue-extradark);"
	@update:is-visible="isMobileSubpageVisible = $event"
>

	<template #banner-title>
		<h1 class="text-center">MHupgrade</h1>
	</template>

	<template #default>
		<div class="generic-container my-4">
			<div v-if="!props.upgrade?.isHideInMobile" identifier="upgrade">
				<SubTabUpgrade
					v-if="!props.upgrade?.isHideInMobile"
					:apiUrl="props.upgrade.apiUrl"
					:apiPointsUrl="props.upgrade.apiPointsUrl"
					:bookingReference="props.upgrade.bookingReference"
					:lastName="props.upgrade.lastName"
					:description="props.upgrade.description"
					:tooltipLabel="props.upgrade.tooltipLabel"
					:tooltipBody="props.upgrade.tooltipBody"
					:buttonLabel="props.upgrade.buttonLabel"
					:note="props.upgrade.note"
					:isToggle="props.upgrade.isToggle"
					:toggleButtonLabel="props.upgrade.toggleButtonLabel"
					:togglePointsApiURL="props.togglePointsApiURL"
					:isMainTab="true"
				></SubTabUpgrade>
			</div>
		</div>
	</template>

</MobileSubpage>
</template>

<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MHupgrade {

}

</style>
