
export default function useSyncURLQuery (key, valueRef, _options = {}) {
	
	const options = {
		toggleRef: null,
		..._options,
	};
	
	const router = useRouter();
	const route = useRoute();
	
	const updateQuery_boolean = (newValue) => {
		let newQuery = { ...route.query };
		if (newValue) {
			newQuery[key] = null;
		} else {
			delete newQuery[key];
		}
		router.replace({ query: newQuery });
	};
	
	const updateQuery_string = (newValue) => {
		const newQuery = {
			...route.query,
			[key]: newValue,
		};
		router.replace({ query: newQuery });
	};
	
	// TODO: Maybe update Vue Router to use qr parser? https://next.router.vuejs.org/api/#parsequery
	// Or write own simplier parser and stingifier
	const updateQuery_array = (newValue) => {
		const newQuery = {
			...route.query,
			// [key]: `[${newValue.join(',')}]`,
			[key]: newValue,
		};
		router.replace({ query: newQuery });
	};
	// TODO: Maybe update Vue Router to use qr stingifier? https://next.router.vuejs.org/api/#stringifyquery
	// Or write own simplier parser and stingifier
	const updateQuery_object = (newValue) => {
		const newQuery = {
			...route.query,
			// [key]: `{${ Object.entries(newValue).map(([key, value]) => `${key}:${value}`).join(',') }}`,
			[key]: newValue,
		};
		router.replace({ query: newQuery });
	};
	
	let unwatchValueRef = null;
	
	const watchValueRef = () => {
		unwatchValueRef = watch(valueRef, (newValue) => {
			switch (typeof newValue) {
				case 'boolean': {
					updateQuery_boolean(newValue);
					break;
				}
				case 'string': {
					updateQuery_string(newValue);
					break;
				}
				case 'object': {
					if (newValue === null) {
						// null
						updateQuery_boolean(null);
					} else if (Array.isArray(newValue)) {
						// array
						updateQuery_array(newValue);
					} else {
						// true object
						updateQuery_object(newValue);
					}
					break;
				}
			}
		});
	};
	
	if (options.toggleRef) {
		watch(options.toggleRef, (newValue) => {
			if (newValue) {
				watchValueRef();
			} else {
				if (unwatchValueRef) unwatchValueRef();
			}
		}, { immediate: true });
	} else {
		watchValueRef();
	}
	
	return {
		
	};
	
}
