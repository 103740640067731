<script>
/* eslint-disable no-template-curly-in-string */
export default {
	name: 'NumberPlusMinus',
};
</script>

<script setup>
import yup from 'mh-yup';
import useFieldValidation from '~/logic/composables/useFieldValidation.js';
import useGenerateIdentifier from '~/logic/composables/useGenerateIdentifier.js';
import { i18nGlobal } from '~/logic/i18n.js';
import useFormAccordionStatus from '~/logic/composables/useFormAccordionStatus.js';

const props = defineProps({
	name: { type: String, required: true },
	ariaLabel: { type: String, required: true },
	
	ariaDescription: { type: String, default: null },
	modelValue: { type: [String, Number], default: null },
	defaultValue: { type: [String, Number], default: null },
	variant: { type: String, default: null },
	
	min: { type: Number, default: 0 },
	max: { type: Number, default: Infinity },
	step: { type: Number, default: 1 },
	
	allowDecimal: { type: Boolean, default: (props) => (props.min % 1 !== 0 || props.max % 1 !== 0 || props.step % 1 !== 0) },
	allowNegative: { type: Boolean, default: (props) => (props.min < 0) },
	
	disableInput: { type: Boolean, default: true },
	
	required: { type: Boolean, default: false },
	requiredErrorMsg: { type: String, default: i18nGlobal.t('This field is required') },
	minErrorMsg: { type: String, default: 'Minimum value is ${min}' },
	maxErrorMsg: { type: String, default: 'Maximum value is ${max}' },
	
	inputAttrs: { type: Object, default: () => ({}) },
});
const emit = defineEmits([
	'update:modelValue',
]);

const within = (min, max, n) => Math.min(Math.max(min, n), max);

const inputEl = ref(null);
const btnMinusEl = ref(null);
const btnPlusEl = ref(null);

onMounted(() => {
	// this is for FormWrapper component to call this, instead of native focus() function
	inputEl.value._mh_focus = (options) => {
		if (!isMinusDisabled.value) {
			btnMinusEl.value.focus(options);
		} else if (!isPlusDisabled.value) {
			btnPlusEl.value.focus(options);
		}
	};
});


// reactive validation object CANNOT BE USED. As an alternative, we use a function
const validationFunc = (value) => {
	let yupSchema = yup
		.number()
		.typeError('Invalid number')
		.min(props.min, props.minErrorMsg)
		.max(props.max, props.maxErrorMsg);

	if (props.required) yupSchema = yupSchema.transform((value) => (isNaN(value) ? undefined : value)).required(props.requiredErrorMsg);
	
	try {
		yupSchema.validateSync(value);
		return true;
	} catch (err) {
		return err.errors[0];
	}
};


const {
	meta,
	errors,
	
	hasValidationError,
	setTouched,
	internalValue,
} = useFieldValidation({
	name: toRef(props, 'name'),
	validation: validationFunc,
	modelValue: toRef(props, 'modelValue'),
}, {
	initialValue: parseFloat(props.modelValue ?? props.defaultValue ?? props.min, 10) || 0,
});


const setValue = (newValue) => {
	const parsed = parseFloat(newValue, 10);
	internalValue.value = Number.isNaN(parsed) ? newValue : parsed;
};

const handlePlusClick = (event) => {
	if (isPlusDisabled.value) return;
	const currentValue = parseFloat(internalValue.value, 10);
	const newValue = Number.isNaN(currentValue) ? (props.min + props.step) : (currentValue + props.step);
	setValue(within(props.min, props.max, newValue));
	setTouched(true);
};
const handleMinusClick = (event) => {
	if (isMinusDisabled.value) return;
	const currentValue = parseFloat(internalValue.value, 10);
	const newValue = Number.isNaN(currentValue) ? (props.max - props.step) : (currentValue - props.step);
	setValue(within(props.min, props.max, newValue));
	setTouched(true);
};

const isMinusDisabled = computed(() => {
	const _value = parseFloat(props.modelValue ?? internalValue.value);
	return _value <= props.min;
});
const isPlusDisabled = computed(() => {
	const _value = parseFloat(props.modelValue ?? internalValue.value);
	return _value >= props.max;
});

if (internalValue.value === undefined || internalValue.value === null) {
	setValue(props.min);
}

const isMinusPressed = ref(false);
const isPlusPressed = ref(false);



const allowedRegExp = new RegExp(`[0-9${props.allowNegative ? '-' : ''}${props.allowDecimal ? '.' : ''}]`, 'i');

const isKeyValid = (key) => {
	return allowedRegExp.test(key);
};


// input related handlers
const onInput = (event) => {
	const newValue = event.target.value;
	setValue(newValue);
	setTouched(true);
};
const onFocus = (event) => {
	event.target.select();
};
const onBlur = (event) => {
	setTouched(true);
};
const onKeypress = (event) => {
	const isValid = isKeyValid(event.key);
	if (!isValid) event.preventDefault();
};
const onPaste = (event) => {
	const pasted = event.clipboardData.getData('text/plain');
	const isValid = isKeyValid(pasted);
	if (!isValid) event.preventDefault();
};


const {
	errorMsgId,
} = useGenerateIdentifier(props.name);


useFormAccordionStatus(props.name, {
	required: props.required,
	value: computed(() => internalValue.value),
	error: computed(() => hasValidationError.value),
});

</script>

<template>
<div
	class="NumberPlusMinus"
	:class="{
		'has-validation-error': hasValidationError && meta.touched,
	}"
	:data-variant="`${props.disableInput ? '' : 'inputable'} ${props.variant}`"
>
	<div class="inner-wrapper flex">
		<div class="label-wrapper flex-grow self-center">
			<div class="label font-semibold">
				<slot>{{ props.ariaLabel }}</slot>
			</div>
			<div class="description text-neutral-grey-extradark text-sm leading-tight">
				<slot name="description">{{ props.ariaDescription }}</slot>
			</div>
		</div>
		
		<div class="flex control-wrapper">
			<button
				ref="btnMinusEl"
				class="btn-minus btn-generic w-12 h-12"
				:class="{
					'is-mouse-down': isMinusPressed,
					'is-disabled': isMinusDisabled,
				}"
				type="button"
				:aria-label="`${$t('Decrease')} ${props.ariaLabel}`"
				@click="handleMinusClick"
				@mousedown="isMinusPressed = true"
				@keydown.enter.space="isMinusPressed = true"
				@mouseup="isMinusPressed = false"
				@keyup.enter.space="isMinusPressed = false"
				@mouseout="isMinusPressed = false"
			><icon-fas-minus class="fill-current" /></button>
			<input
				ref="inputEl"
				v-model="internalValue"
				class="input mx-2"
				type="number"
				inputmode="numeric"
				autocomplete="nope"
				autofill="off"
				
				:name="props.name"
				:min="props.min"
				:max="props.max"
				:readonly="props.disableInput"
				:disabled="props.disableInput"
				
				:aria-label="props.ariaLabel"
				:aria-description="props.ariaDescription"
				:aria-errormessage="errorMsgId"
				:aria-invalid="hasValidationError ? 'true' : null"
				
				v-bind="props.inputAttrs"
				
				@input="onInput"
				@focus="onFocus"
				@blur="onBlur"
				@keypress="onKeypress"
				@paste="onPaste"
			/>
			<button
				ref="btnPlusEl"
				class="btn-plus btn-generic w-12 h-12"
				:class="{
					'is-mouse-down': isPlusPressed,
					'is-disabled': isPlusDisabled,
				}"
				type="button"
				:aria-label="`${$t('Increase')} ${props.ariaLabel}`"
				@click="handlePlusClick"
				@mousedown="isPlusPressed = true"
				@keydown.enter.space="isPlusPressed = true"
				@mouseup="isPlusPressed = false"
				@keyup.enter.space="isPlusPressed = false"
				@mouseout="isPlusPressed = false"
			><icon-fas-plus class="fill-current" /></button>
		</div>
	</div>
	
	<div class="sr-only" role="alert">{{ internalValue }} {{ props.ariaLabel }}</div>
	
	<div
		:id="errorMsgId"
		class="error-msg-container text-semantic-red-base mt-2 text-sm empty:hidden"
	>
		<slot
			v-if="hasValidationError && meta.touched"
			name="error-messages"
			v-bind="{ meta, errors, internalValue }"
		><span class="">{{ errors[0] }}</span></slot>
	</div>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.NumberPlusMinus {
	
}

.label-wrapper {
	@apply mr-4;
	
	html[dir="rtl"] & {
		@apply mr-0 ml-4;
	}
}

.input {
	-moz-appearance: textfield; // <-- remove arrows in Firefox
	appearance: textfield;
	border: 1px solid transparent;
	background-color: transparent;
	width: 48px;
	padding: 0;
	text-align: center;
	font-weight: 600;
	
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none; // <-- remove arrows in Chrome / Safari
		appearance: none;
		margin: 0;
	}
	
	&:disabled {
		// fix Safari dim the text color when input is disabled
		opacity: 1;
		-webkit-opacity: 1;
		-webkit-text-fill-color: var(--text-color);
	}
	
	/* &:focus, &:focus-visible {
		outline-color: transparent;
	} */
}

.control-wrapper {
	height: 48px; // follow button height
}
.btn-plus, .btn-minus {
	flex: 0 0 48px;
	transition: all 0.2s;
}


.NumberPlusMinus[data-variant*="inputable"] {
	
	.inner-wrapper {
		flex-wrap: wrap;
	}
	.label-wrapper {
		margin-right: 0;
	}
	.label {
		color: var(--neutral-grey-ultradark);
	}
	.control-wrapper {
		width: 100%;
		@apply mt-2;
	}
	.input {
		background-color: white;
		border: 2px solid;
		border-color: var(--neutral-grey-base);
		flex: 1 1 auto;
		width: 100%;
		max-width: 120px;
		outline: 0;
	}
	.btn-plus, .btn-minus {
		
	}
	
	&.has-validation-error {
		.input {
			border-color: var(--semantic-red-light);
			
			&:hover {
				border-color: var.$semantic-red-base-50-opacity;
			}
			&:focus {
				border-color: var(--semantic-red-base);
			}
		}
	}
}

// temporary
.NumberPlusMinus[data-variant*="justify-end"] {
	
	.control-wrapper {
		justify-content: flex-end;
	}
}

</style>
