<script>
export default {
	name: 'Checkbox',
	inheritAttrs: false,
};
</script>

<script setup>
import yup from 'mh-yup';
import useFieldValidation from '~/logic/composables/useFieldValidation.js';
import useGenerateIdentifier from '~/logic/composables/useGenerateIdentifier.js';
import { i18nGlobal } from '~/logic/i18n.js';

const props = defineProps({
	name: { type: String, default: '' }, // Note: if name is not provided, then validation would not work
	ariaLabel: { type: String, required: true },
	
	modelValue: { type: Boolean, default: null },
	defaultChecked: { type: Boolean, default: false },
	
	required: { type: Boolean, default: false },
	requiredErrorMsg: { type: String, default: i18nGlobal.t('This field is required') },
	
	highlightField: { type: Boolean, default: false },
	disabled: { type: Boolean, default: false },
	
	rootAttrs: { type: Object, default: () => ({}) },
	
	inputAttrs: { type: Object, default: () => ({}) },
});
const emit = defineEmits([
	'update:modelValue',
]);

const isPressed = ref(false);
const inputEl = ref(null);

const siteName = window.siteName;

const focus = () => {
	inputEl.value?.focus();
};


const validation = computed(() => {
	if (!props.required) return null;
	return yup.boolean().equals([true], props.requiredErrorMsg);
});


const {
	onCheckboxChange,
	meta,
	errors,
	hasValidationError,
	internalValue,
} = useFieldValidation({
	name: toRef(props, 'name'),
	validation: validation,
	modelValue: toRef(props, 'modelValue'),
}, {
	initialValue: props.modelValue ?? props.defaultChecked ?? null,
});


const componentIdentifier = props.name ?? (Math.random() * 10);
const {
	errorMsgId,
} = useGenerateIdentifier(componentIdentifier);


defineExpose({
	focus,
});

</script>

<template>
<div
	class="Checkbox"
	:class="[
		{
			'is-mouse-down': isPressed && !props.disabled,
			'is-disabled': props.disabled,
			'has-validation-error': hasValidationError && meta.touched,
			'highlight-field': props.highlightField,
		},
	]"
	v-bind="props.rootAttrs"
	:data-use-theme="siteName"
>
	<label
		class="inline-flex cursor-pointer relative"
		v-bind="$attrs"
		@mousedown="isPressed = true"
		@keydown.space="isPressed = true"
		@mouseup="isPressed = false"
		@keyup.space="isPressed = false"
		@mouseout="isPressed = false"
	>
		<input
			ref="inputEl"
			class="input"
			type="checkbox"
			
			:required="props.required"
			:checked="internalValue"
			:name="props.name || null"
			:disabled="props.disabled"
			
			:aria-label="props.ariaLabel"
			:aria-errormessage="errorMsgId"
			:aria-invalid="hasValidationError ? 'true' : null"
			
			v-bind="props.inputAttrs"
			
			@change="onCheckboxChange"
		/>
	
		<div class="indicator-circle" aria-hidden="true"></div>
	
		<span class="icon-uncheck" aria-hidden="true"><icon-far-square /></span>
		<span class="icon-check" aria-hidden="true"><icon-fas-square-check /></span>
	
		<div class="label ml-2 rtl:(ml-0 mr-2) empty:ml-0 select-none">
			<slot>
				<span v-html-sanitize="props.ariaLabel" class="author-html-container"></span>
			</slot>
		</div>
	</label>
	<div
		class="short-description-wrapper w-full text-sm empty:hidden"
	>
		<slot name="short-description"></slot>
	</div>
	<div
		:id="errorMsgId"
		class="error-msg-container leading-tight text-semantic-red-base text-sm empty:hidden"
	>
		<slot
			v-if="hasValidationError && meta.touched"
			name="error-messages"
			v-bind="{ meta, errors, internalValue }"
		>
			<span v-html-sanitize="errors[0]"></span>
		</slot>
	</div>
</div>

</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.Checkbox {
	
	.short-description-wrapper {
		color: var(--neutral-grey-ultradark);
	}

	&.has-validation-error {
		.short-description-wrapper {
			color: var(--semantic-red-base);
		}
	}

	.highlight-field {
		.inner-container {
			--borderColor: var(--semantic-red-base);
		}
	}
	
	&:hover {
		.indicator-circle {
			opacity: 1;
			transform: scale(1);
		}
	}
	
	&.is-mouse-down {
		.indicator-circle {
			opacity: 1;
			@apply bg-opacity-60;
			transform: scale(1);
		}
	}

	&.highlight-field {
		.icon-uncheck {
			fill: var(--semantic-red-base);
		}
	}
}

.icon-check, .icon-uncheck {
	/* align-self: center; */
	transition: all 0.2s cubic-bezier(.54, 1.60, 0.5, 1);
	transform-origin: center center;
	/* aspect-ratio: 1 / 1;
	width: 1.2em; */
	display: flex;
	position: relative;
	top: 2px;
}

.input {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	// left: -9999px; <-- Disable this due to RTL bug causing whole site horizontal scrolling
	
	~ .icon-check {
		fill: var(--primary-blue-base);
		opacity: 0;
		transform: scale(0.1);
		position: absolute;
	}
	~ .icon-uncheck {
		opacity: 1;
		transform: scale(1);
		fill: var(--neutral-grey-extradark);
	}
	&:checked ~ .icon-check {
		opacity: 1;
		transform: scale(1);
	}
	&:checked ~ .icon-uncheck {
		opacity: 0;
	}
	
	&:disabled {
		~ .icon-uncheck {
			fill: var(--neutral-grey-base);
		}
		&:checked ~ .icon-check {
			opacity: 0.4;
		}
		
		~ .label {
			color: var(--neutral-grey-dark);
		}
	}
	
	/* &[aria-invalid="true"] ~ .icon-uncheck {
		fill: var(--semantic-red-base);
	} */
}

.indicator-circle {
	position: absolute;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	top: -4px;
	left: -6.5px;
	opacity: 0;
	@apply bg-neutral-grey-base bg-opacity-20;
	transform: scale(0.4);
	transform-origin: center center;
	transition: all 0.3s;

	html[dir="rtl"] & {
		left: auto;
		right: -6.5px;
	}
}

@supports not selector(.parent:has(.child)) {
	.Checkbox {
		&:focus-within {
			.input:focus-visible ~ .indicator-circle {
				opacity: 1;
				transform: scale(1);
				outline-width: var(--focus-visible-outline-width, 2px);
				outline-offset: var(--focus-visible-outline-offset, 0px);
				outline-color: var(--semantic-blue-base);
				outline-style: solid;
			}
		}
	}
}

@supports selector(.parent:has(.child)) {
	.Checkbox {
		border-radius: 4px;
		outline-width: var(--focus-visible-outline-width, 2.5px);
		outline-offset: calc(var(--focus-visible-outline-offset, 4px) + 2px);
		outline-color: transparent;
		outline-style: solid;
		transition-property: outline-color, outline-offset;
		transition-duration: 0.2s;
		
		&:has(:focus-visible) {
			outline-offset: var(--focus-visible-outline-offset, 4px);
			outline-color: var(--semantic-blue-base);
		}
	}
}

.Checkbox[data-use-theme="firefly"] {
	
	.indicator-circle {
		background-color: var(--neutral-firefly-grey-extralight);
	}

	.icon-check {
		fill: var(--primary-firefly-orange-base);
	}
}

</style>
