<script>
export default {
	name: 'BookingWidget',
	editConfig: {
		emptyLabel: 'Booking Widget',
		isEmpty (props) {
			return props.desktopProperties.length === 0;
		},
	},
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import SwitchTab from '~/aem-components/SwitchTab.vue';
import FontAwesomeLazy from '~/components/FontAwesomeLazy.vue';
import VueSwiper from '~/components/VueSwiper.vue';
import { getCompositeComponent } from '@custom/aem-vue-editable-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { sleep } from '~/logic/helpers/utils.js';
import { useEventBus } from '@vueuse/core';
import { pushAA_click } from '~/logic/adobe-analytic.js';

import CheckIn from '~/aem-components/booking/CheckIn.vue';
import FlightHotelSearch from '~/aem-components/booking/FlightHotelSearch.vue';
import FlightSchedule from '~/aem-components/booking/FlightSchedule.vue';
import FlightSearch from '~/aem-components/booking/FlightSearch.vue';
import FlightStatus from '~/aem-components/booking/FlightStatus.vue';
import ManageBooking from '~/aem-components/booking/ManageBooking.vue';
import MHupgrade from '~/aem-components/booking/MHupgrade.vue';
import MHEFlightSearch from '~/aem-components/mhe/BookingWidget/MHEFlightSearch.vue';

const props = defineProps({
	desktopProperties: { type: Array, default: () => [] },
	mobileProperties: { type: Array, default: () => [] },
	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const rootEl = ref(null);
const switchTabEl = ref(null);

const FLIGHT_SEARCH_ID = 'flight-search-tab';

const TAB_CONTENT_MAPPING = {
	'flight-search': {
		component: FlightSearch,
		tabTitle: 'Flight Search',
		anchorTag: FLIGHT_SEARCH_ID,
		selectedDefault: false,
		parsys: {
			type: 'mh/components/booking/flight-search',
			componentKey: 'flight-search',
		},
	},
	'flight-hotel': {
		component: FlightHotelSearch,
		tabTitle: 'Flight + Hotel',
		anchorTag: 'flight-hotel-search-tab',
		selectedDefault: false,
		parsys: {
			type: 'mh/components/booking/flight-hotel-search',
			componentKey: 'flight-hotel-search',
		},
	},
	'manage-booking': {
		component: ManageBooking,
		tabTitle: 'Manage Booking',
		anchorTag: 'manage-booking-tab',
		selectedDefault: false,
		parsys: {
			type: 'mh/components/booking/manage-booking',
			componentKey: 'manage-booking',
		},
	},
	'check-in': {
		component: CheckIn,
		tabTitle: 'Check In',
		anchorTag: 'check-in-tab',
		selectedDefault: false,
		parsys: {
			type: 'mh/components/booking/check-in',
			componentKey: 'check-in',
		},
	},
	'flight-status': {
		component: FlightStatus,
		tabTitle: 'Flight Status',
		anchorTag: 'flight-status-tab',
		selectedDefault: false,
		parsys: {
			type: 'mh/components/booking/flight-status',
			componentKey: 'flight-status',
		},
	},
	'flight-schedule': {
		component: FlightSchedule,
		tabTitle: 'Flight Schedule',
		anchorTag: 'flight-schedule-tab',
		selectedDefault: false,
		parsys: {
			type: 'mh/components/booking/flight-schedule',
			componentKey: 'flight-schedule',
		},
	},
	'mh-upgrade': {
		component: MHupgrade,
		tabTitle: 'MHupgrade',
		anchorTag: 'mh-upgrade-tab',
		selectedDefault: false,
		parsys: {
			type: 'mh/components/booking/mh-upgrade',
			componentKey: 'mh-upgrade',
		},
	},
	'mhe-flight-search': {
		component: MHEFlightSearch,
		tabTitle: 'MHE Flight Search',
		anchorTag: 'mhe-flight-search-tab',
		selectedDefault: false,
		parsys: {
			type: 'mh/components/mhexplorer/booking/mhe-flight-search',
			componentKey: 'mhe-flight-search',
		},
	},
};


const tabList = computed(() => {
	return props.desktopProperties.map((item) => {
		if (item.isHideInDesktop) return null;
		const mapped = TAB_CONTENT_MAPPING[item.function];
		if (!mapped) {
			console.warn(`Couldn't find correct mapping data for `, item);
			return null;
		}
		return {
			...mapped,
			tabTitle: item.ctaText,
			composite: getCompositeComponent(mapped.parsys),
		};
	}).filter(Boolean);
});

const mobileListItem = computed(() => {
	return props.mobileProperties.map((item) => {
		if (item.isHideInMobile) return null;
		return {
			...item,
		};
	}).filter(Boolean);
});

const activeTabName = ref(tabList.value?.[0].anchorTag);
const activeSubTabName = ref('book-flight');

// we need a flag so that we can set class, to apply custom styling when swiper is not engaged (i.e. locked)
// basically, we want to center the slides
const isMobileSwiperLocked = ref(false);


const { isEditorMode } = useGlobalAEMState();

const hasFlightSearchAsChild = computed(() => {
	return !!tabList.value.find((item) => item.anchorTag === FLIGHT_SEARCH_ID);
});


let scrollTriggerInstance = null;

const bookingWidgetBus = useEventBus('booking-widget');

bookingWidgetBus.on((event, payload) => {
	switch (event) {
		case 'refresh-scroll-trigger': {
			scrollTriggerInstance?.refresh();
			break;
		}
		default: { break; }
	}
});

const handleStickyElMounted = async () => {
	// return;
	if (isEditorMode.value) return;
	if (!hasFlightSearchAsChild.value) return;
	
	gsap.registerPlugin(ScrollTrigger);
	await sleep(750);
	
	const triggerEl = switchTabEl.value.rootEl.querySelector('.SwitchTabContent');
	let lastFocusedEl = document.activeElement;
	
	const resizeObserver = new ResizeObserver((entries) => {
		lastFocusedEl = document.activeElement;
		
		for (const entry of entries) {
			const borderBoxSize = entry.borderBoxSize[0];
			const height = borderBoxSize.blockSize;
			scrollTriggerInstance?.refresh();
			st2?.refresh();
			rootEl.value?.style.setProperty('--pinSpacingHeight', `${triggerEl.offsetHeight}px`);
			// console.log('resizeObserver - triggerEl size changed, height = ', height);
		}
		
		nextTick().then(() => {
			if (lastFocusedEl && (lastFocusedEl !== document.body)) lastFocusedEl.focus();
		});
	});

	resizeObserver.observe(triggerEl);
	
	scrollTriggerInstance = ScrollTrigger.create({
		trigger: triggerEl,
		// start: `+=94 top`, // 94 = pixel from top of '.SwitchTabContent' that it should start sticking
		start: `bottom top`, // 94 = pixel from top of '.SwitchTabContent' that it should start sticking
		toggleClass: 'sticked',
		endTrigger: document.body,
		pin: true,
		pinSpacing: false,
		onToggle: (instance) => {
			if (instance.isActive) {
				bookingWidgetBus.emit('sticky:sticked', { rootEl, triggerEl, scrollTriggerInstance, resizeObserver });
			} else {
				bookingWidgetBus.emit('sticky:unsticked', { rootEl, triggerEl, scrollTriggerInstance, resizeObserver });
			}
		},
		// markers: { startColor: 'green', endColor: 'green' },
	});
	
	// we need this to add "pre-stick" class to triggerEl, to hide it to prevent flash of expanded pinned booking widget
	let st2 = ScrollTrigger.create({
		trigger: triggerEl,
		start: `92.5% top`,
		endTrigger: document.body,
		onToggle: (instance) => {
			if (instance.isActive) {
				triggerEl.classList.add('pre-stick');
			} else {
				triggerEl.classList.remove('pre-stick');
			}
		},
		// markers: { startColor: 'purple', endColor: 'purple' },
	});
	
	// window.scrollTriggerInstance = scrollTriggerInstance;
	
	const homepageTransitionInBus = useEventBus('homepage-transition-in');
	homepageTransitionInBus.on((event, payload) => {
		switch (event) {
			case 'animation-complete': {
				rootEl.value.style.setProperty('--pinSpacingHeight', `${triggerEl.offsetHeight}px`);
				st2?.refresh();
				scrollTriggerInstance.refresh();
				break;
			}
			default: {
				// console.log(`Unknown event: "${event}". Ignoring it.`);
				break;
			}
		}
	});
	
	
	if (isMobileViewport.value) {
		scrollTriggerInstance.disable();
	} else {
		// temporary HACK to make sure sticky is correct
		/* let updateCount = 0;
		const MAX_UPDATE_COUNT = 3;
		let intervalId = setInterval(() => {
			scrollTriggerInstance.refresh();
			updateCount++;
			if (updateCount >= MAX_UPDATE_COUNT) clearInterval(intervalId);
		}, 700); */
	}
};

const handleStickyElBeforeUnmount = async () => {
	scrollTriggerInstance.kill();
};

watch(isMobileViewport, (newValue, oldValue) => {
	if (newValue) {
		scrollTriggerInstance?.disable();
	} else {
		scrollTriggerInstance?.enable();
	}
});

const sendTabUpdateAA = (newTabName) => {
	pushAA_click([
		{
			clickName: newTabName,
			clickComponentType: 'URL',
			componentName: 'BookingWidget',
			componentID: props.componentIdAA,
		},
		{
			name: newTabName,
			type: 'other',
		},
	]);
};

const handleSwitchTabUpdate = (newTabName) => {
	sendTabUpdateAA(newTabName);
};
const handleSubTabUpdate = (newTabName) => {
	activeSubTabName.value = newTabName;
	sendTabUpdateAA(newTabName);
};

const globalBookFlightBus = useEventBus('booking-widget:book-flight');

globalBookFlightBus.on(async (event, payload) => {
	switch (event) {
		case 'active-tab-and-fill-up-form':
			if (tabList.value.length > 1) {
				switchTabEl.value.switchTab(payload.mainTab);
			}
			break;
		default:
			console.log(`Unknown event: ${event}. Ignore it.`);
			break;
	}
});

</script>



<template>
<div
	ref="rootEl"
	class="BookingWidget relative z-50"
	:class="{
		'mt-0': tabList.length === 1,
		'-mt-22 md:-mt-3 mb-20': tabList.length !== 1,
	}"
	:style="{
		'--tab-count': tabList.length,
	}"
>
	<SwitchTab
		ref="switchTabEl"
		class="tab-swiper md:hidden"
		:tabList="tabList"
		:hideTabs="tabList.length === 1"
		:useSlotAsContent="true"
		:updateHashOnSwitch="false"
		:contentContainerClass="`rounded-6xl lg:rounded-2xl pt-8 pb-14 bg-white ${tabList.length === 1 ? '' : 'shadow-type-a'}`"
		:desktopAllowTouchMove="true"
		componentNameAA="BookingWidget"
		@vue:mounted="handleStickyElMounted"
		@vue:before-unmount="handleStickyElBeforeUnmount"
		@update:active-tab="handleSwitchTabUpdate"
	>
		<template
			v-for="tabItem in tabList"
			:key="tabItem.anchorTag"
			#[`content-${tabItem.anchorTag}`]
		>
			<div class="generic-container">
				<!-- <component :is="tabItem.component" /> -->
				<component
					:is="tabItem.composite.vNode"
					v-bind="tabItem.composite.vNodeProps"
					:isInBookingWidget="true"
					:togglePointsApiURL="props.togglePointsApiURL"
					@update:active-sub-tab="handleSubTabUpdate"
				></component>
			</div>
		</template>
	</SwitchTab>
	
	<!-- Mobile -->
	<div class=">md:hidden rounded-2xl pt-6 pb-4 shadow-type-a bg-white">
		<div class="">
			<VueSwiper
				class="mobile-swiper"
				:class="isMobileSwiperLocked ? 'is-locked' : ''"
				slidesPerView="auto"
				:spaceBetween="16"
				variant="slides-auto-width"
				:scrollbar="{
					draggable: true,
					hide: false,
				}"
				:observer="true"
				:observeParents="true"
				@lock="isMobileSwiperLocked = true"
				@unlock="isMobileSwiperLocked = false"
			>
				<AppHyperlink
					v-for="item in mobileListItem"
					:key="item.urlPath"
					class="mobile-item flex-col w-22"
					:href="item.urlPath"
				>
					<div class="rounded-full bg-primary-blue-base w-12 h-12 text-white">
						<FontAwesomeLazy
							v-if="item.iconType === 'icon-font-awesome-id'"
							class="mx-3 fill-current flex items-center h-full"
							:iconId="item.iconId"
							role="navigation"
							:aria-label="item.ctaText"
						/>
					</div>
					<span
						class="font-semibold typo-body-text-3 !leading-tight mt-2 text-$text-color text-center"
					>{{ item.ctaText }}</span>
				</AppHyperlink>
			</VueSwiper>
		</div>
		
	</div>
	
	<!-- This is a teleport target -->
	<div id="mobile-past-searches-container" class=">md:hidden"></div>
	
</div>
</template>


<style>
/* temporary only */
.gsap-marker-end {
	z-index: 10000 !important;
}
.gsap-marker-start {
	z-index: 10000 !important;
}
.gsap-marker-scroller-end {
	z-index: 10000 !important;
}
.gsap-marker-scroller-start {
	z-index: 10000 !important;
}
</style>


<style lang="scss">
@use '~/styles/partials/_var.scss';
.aem-AuthorLayer-Edit, .aem-AuthorLayer-structure {
	.BookingWidget {
		margin-top: 1.5rem !important;
	}
}

html.scroll-dir-down {
	.BookingWidget .SwitchTabContent {
		&.sticked {
			top: 0 !important;
		}
	}
}
</style>


<style scoped lang="scss">
@use 'sass:color';
@use 'sass:meta';
@use '~/styles/partials/_var.scss';
@use '~/styles/partials/_mixins.scss';

.BookingWidget {
	--tab-width: 244px;
	--tab-count: 6; // will be overwritten by 'style' attr
	--bluryBgElementWidthReduction: 0.985;
	
	:deep(.SwitchTabHeader .swiper-wrapper) {
		position: relative;
		
		&::before {
			content: '';
			backdrop-filter: blur(20px);
			@apply bg-neutral-grey-extralight bg-opacity-40;
			position: absolute;
			// 205px * (6 tabs) = 1230px
			// - 10% from it. This is arbitrary, it comes from trial & error.
			/* width: calc((var(--tab-width) * var(--tab-count)) * 0.9); */
			width: calc((var(--tab-width) * var(--tab-count)) * var(--bluryBgElementWidthReduction));
			// left 25px is arbitrary, it comes from trial & error.
			left: 25px;
			height: 100%;
			transform-origin: top left;
			transform: skew(-0.075turn);
			border-top-left-radius: 14px;
			border-top-right-radius: 14px;

			html[dir="rtl"] & {
				transform: skew(0.075turn);
				left: auto;
				right: 25px;
			}
		}
	}
	:deep(.SwitchTabHeader .swiper) {
		width: calc(100% - 100px);
		max-width: calc((var(--tab-width) * var(--tab-count)) * 0.92);
		position: relative;
	}
	
	:deep(.SwitchTabHeader .SwitchTabArrow-wrapper) {
		background-color: transparent;
	}
	:deep(.SwitchTabHeader .SwitchTabArrow) {
		@apply bg-neutral-grey-extralight rounded-full;
	}

	:deep(.SwitchTabHeader) {
		.swiper-slide {

			&:first-child {
				html[dir="rtl"] & {
					margin-right: 0;
				}
			}

			&:last-child {
				html[dir="rtl"] & {
					margin-right: -20px;
				}
			}
		}
		
	}
	
	
	:deep(.SwitchTabHeaderItem) {
		width: var(--tab-width);
	}
	:deep(.SwitchTabHeaderItemLeft),
	:deep(.SwitchTabHeaderItemCenter),
	:deep(.SwitchTabHeaderItemRight) {
		background-color: transparent;
	}
	:deep(.SwitchTabHeaderItemLeft), :deep(.SwitchTabHeaderItemRight) {
		background-color: transparent;
		
		> svg {
			fill: transparent;
		}
	}
	
	:deep(.SwitchTabHeaderItem.selected) {
		.SwitchTabHeaderItemCenter {
			background-color: white;
			color: var(--primary-blue-base);
		}
		.SwitchTabHeaderItemLeft, .SwitchTabHeaderItemCenter, .SwitchTabHeaderItemRight {
			--tw-bg-opacity: 1;
		}
		.SwitchTabHeaderItemLeft, .SwitchTabHeaderItemRight {
			background-color: transparent;
			
			> svg {
				fill: white;
			}
		}
	}
	
	:deep(.SwitchTabContent) {
		margin-top: -2px;
		z-index: 10000;
	}

}

@supports selector(.parent:has(.child)) {
	// see <SwitchTab> style section
	.BookingWidget {
		--tab-width: 205px;
		--bluryBgElementWidthReduction: 0.9;
	}
}

.mobile-swiper {
	@apply pb-6;
	
	&.is-locked {
		:deep(.swiper-wrapper) {
			justify-content: center;
		}
	}
	
	:deep(.swiper-slide:first-child) {
		@apply pl-4;
		
		html[dir="rtl"] & {
			@apply pl-0 pr-4;
		}
	}
	:deep(.swiper-slide:last-child) {
		@apply pr-4;
		
		html[dir="rtl"] & {
			@apply pr-0 pl-4;
		}
	}
	:deep(.swiper-scrollbar) {
		width: 80px;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		
		.swiper-scrollbar-drag {
			background-color: var(--primary-blue-base);
		}
	}
}

.mobile-item {
	:deep(svg) {
		width: 100%;
	}
}

:deep(.pin-spacer) {
	max-height: none !important;
	// height: auto !important;
	height: var(--pinSpacingHeight, auto) !important;
}
.tab-swiper :deep(.SwitchTabContent) {
	transition: top 0.2s;
	max-height: none !important;
	height: auto !important;
	
	&.sticked {
		max-height: none !important;
		height: auto !important;
		border-radius: 0;
		border-bottom: 2px solid var(--neutral-grey-light);
		top: calc(
			var(--mobileDlAppNotificationHeight) +
			var(--headerNotificationHeight) +
			var(--headerHeight)
		) !important;
		padding-bottom: 24px !important; // <-- pb-6
		box-shadow: none;
		
		.switch-tab-content-wrapper {
			display: none !important;
			/* max-height: calc(80vh - var(--headerNotificationHeight) - var(--headerHeight));
			overflow-y: auto; */
		}
		.slide-fade-enter-active,
		.slide-fade-leave-active {
			transition: none !important;
		}
		[data-tab-content-name="flight-search-tab"] {
			display: block !important;
		}
		
		.FlightSearch .tab-panels-container {
			
			> [identifier="multi-city"] {
				max-height: 70vh !important;
				overflow-y: auto !important;
				overscroll-behavior: none;
				@include mixins.styled-scrollbar();
				@apply pr-4;
			}
		}
	}
	&.pre-stick {
		visibility: hidden;
	}
}

</style>
