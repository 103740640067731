<script>
export default {
	name: 'Parsys',
	inheritAttrs: false,
};
</script>

<script setup>
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';

const props = defineProps({
	component: { type: Object, required: true },
	label: { type: String, default: '' },
	visible: { type: Boolean, default: true },
	forceVisibleInEditor: { type: Boolean, default: true },
	index: { type: Number, default: null },
	themeColor: { type: String, default: null },
	renderIndicator: { type: Boolean, default: true },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

const { isEditorMode } = useGlobalAEMState();


</script>

<template>
<div
	v-if="isEditorMode && props.label && props.renderIndicator"
	v-show="props.visible || (props.forceVisibleInEditor ? isEditorMode : false)"
	class="parsys-section-indicator mt-4"
	:data-index="index"
	:data-theme-color="themeColor"
>
	<span class="label flex items-center">
		<icon-far-arrow-down class="mr-1.5 fill-current" />
		<span>{{ props.label }}</span>
		<icon-far-arrow-down class="ml-1.5 fill-current" />
	</span>
</div>
<component
	:is="props.component.vNode"
	v-show="props.visible || (props.forceVisibleInEditor ? isEditorMode : false)"
	v-bind="{
		...props.component.vNodeProps,
		...$attrs,
	}"
/>
<div
	v-if="isEditorMode && props.label && props.renderIndicator"
	v-show="props.visible || (props.forceVisibleInEditor ? isEditorMode : false)"
	class="parsys-section-indicator mb-4"
	:data-index="index"
	:data-theme-color="themeColor"
>
	<span class="label flex items-center">
		<icon-far-arrow-up class="mr-1.5 fill-current" />
		<span>{{ props.label }}</span>
		<icon-far-arrow-up class="ml-1.5 fill-current" />
	</span>
</div>
</template>
