<script>
export default {
	name: 'MobileSubpage',
	inheritAttrs: false,
};
</script>

<script setup>
import Anchor from '~/logic/helpers/Anchor.js';

const props = defineProps({
	isVisible: { type: Boolean, default: false },
	backButtonLabel: { type: String, default: null },
});
const emit = defineEmits([
	'update:isVisible',
]);

const onRootMounted = () => {
	Anchor.activate('body');
};

const siteName = window.siteName;
</script>

<template>
<Teleport
	v-if="props.isVisible"
	to="#booking-subpage-container"
	class="MobileSubpage text-white"
	v-bind="$attrs"
>
	<section
		class="section-banner"
		:data-use-theme="siteName"
		@vue:mounted="onRootMounted"
	>
		<div class="generic-container">
			<button
				class="btn-back flex items-center text-white gap-2"
				type="button"
				@click="emit('update:isVisible', false)"
			>
				<icon-far-chevron-left class="fill-current transform rtl:-rotate-180" />
				<span class="">{{ backButtonLabel ?? $t('Back') }}</span>
			</button>
			
			<div class="my-10 text-white">
				<slot name="banner-title"></slot>
			</div>
		</div>
	</section>
	<div class="top-rounded-section-breaker"></div>
	
	<slot></slot>
</Teleport>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.MobileSubpage {
}

.section-banner {
	--batik-bg-colour: var(--primary-blue-base);
	--batik-pattern-img: url('~/assets/batik-pattern.svg');
	/* --batik-pattern-img: url('~/assets/batik-pattern-brighter.svg'); */
	background-color: var(--batik-bg-colour);
	background-image: var(--batik-pattern-img);
	background-size: 200px auto;
	background-repeat: repeat;
	height: 317px;
	overflow: hidden;
}

.btn-back {
	margin-top: var(--headerHeight);
}

.top-rounded-section-breaker {
	border-top-left-radius: 24px;
	border-top-right-radius: 24px;
	--bgColor: white;
	background-color: var(--bgColor);
	height: 45px;
	margin-top: -44px;
}

.section-banner[data-use-theme="MHH"] {
	--batik-bg-colour: var(--primary-mhh-teal-base);
	--batik-pattern-img: url('~/assets/floral-pattern-short-banner.svg');
	background-size: cover;
	background-position-y: 45px;
	background-repeat: no-repeat;
}

.section-banner[data-use-theme="firefly"] {
	--batik-bg-colour: var(--primary-firefly-orange-base);
	--batik-pattern-img: '';

	& + .top-rounded-section-breaker {
		height: 56px;
		margin-top: -55px;
	}
}

</style>
