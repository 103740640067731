
export const isRTL = () => {
	return document.dir === 'rtl';
};

export const useIsRTL = () => {
	return {
		isRTL: computed(() => isRTL()),
	};
};

