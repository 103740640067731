<script>
export default {
	name: 'BtnBackToTop',
};
</script>

<script setup>
import Anchor from '~/logic/helpers/Anchor.js';
import { useWindowScroll, useEventBus } from '@vueuse/core';

const props = defineProps({
	// modelValue: { type: String, default: '' },
});
const emit = defineEmits([
	// 'update:modelValue',
]);

// don't really need this
// const bookingWidgetBus = useEventBus('booking-widget');


const handleClick = (event) => {
	Anchor.activate('body');
};

const isVisible = ref(false);

const { y: scrollY } = useWindowScroll();

const SCROLL_Y_THRESHOLD = window.innerHeight * 0.8;

watch(scrollY, (newScrollY) => {
	if (newScrollY >= SCROLL_Y_THRESHOLD) {
		if (!isVisible.value) isVisible.value = true;
	} else {
		if (isVisible.value) isVisible.value = false;
	}
}, { immediate: true });

</script>

<template>
<div
	class="BtnBackToTop"
	:style="{
		'--btn-size': isVisible ? undefined : '0px',
	}"
>
	<button
		v-button-press
		type="button"
		class="button"
		:aria-label="$t('Back to top')"
		@click="handleClick"
	>
		<icon-far-arrow-up-to-line class="fill-white w-6 h-6" aria-hidden="true" />
	</button>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.BtnBackToTop {
	--btn-size: 56px;
	overflow: hidden;
	transition: height 0.2s;
	width: var(--btn-size);
	height: var(--btn-size);
	
	@media #{var.$query-max-md} {
		--btn-size: 48px;
	}
}

.button {
	border-radius: 100%;
	width: var(--btn-size);
	height: var(--btn-size);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.2s;
	@apply bg-black bg-opacity-20;
	
	&:hover {
		@apply bg-opacity-50;
	}
	
	&.is-mouse-down {
		@apply bg-opacity-80;
	}
}

</style>
