import { h, defineComponent, getCurrentInstance, computed, watch } from 'vue';
import { AuthoringUtils } from '@adobe/aem-spa-page-model-manager';
import { Constants } from '../Constants.js';
import Utils from '../Utils.js';
import { getMappedComponent } from '../ComponentMapping.jsx';
import { modelProvider } from './composables/model-provider.js';
import { editableProvider } from './composables/editable-provider.js';
import DefaultEditConfig from '../DefaultEditConfig.js';


const AEMPage = defineComponent({
	name: 'AEMPage',
	editConfig: {
		resourceType: 'mh/components/page',
		isEmpty (props) {
			return false;
		},
	},
	inheritAttrs: false,
	props: {
		cqChildren: { type: Object, default: () => ({}) },
		cqForceReload: { type: Boolean, default: false },
		cqPath: { type: String, default: '' },
		cqItems: { type: Object, default: () => ({}) },
		cqItemsOrder: { type: Array, default: () => [] },
		cqType: { type: String, default: '' },
		cqHierarchyType: { type: String, default: '' },
		
		title: { type: String, default: '' },
		language: { type: String, default: '' },
		cssClassNames: { type: String, default: '' },
		designPath: { type: String, default: '' },
		lastModifiedDate: { type: Number, default: 0 },
		templateName: { type: String, default: '' },
		injectPropsOnInit: { type: Boolean, default: true },
		
		containerClass: { type: String, default: '' },
		hasEditableCapability: { type: Boolean, default: true },
	},
	setup (props, context) {
		const instance = getCurrentInstance();
		const instanceEditConfig = instance.type.editConfig;
		
		const {
			aemProperties,
			componentId,
		} = modelProvider({ props, context });
		
		const {
			wrapperHtmlAttributes,
			wrapperHtmlClass,
		} = editableProvider({ props, context, editConfig: instanceEditConfig });
		
		
		
		const getItemComponent = (ItemComponent, itemProps, itemKey) => {
			const { cqPath, cqItems } = aemProperties.value;
			const itemPath = cqPath ? `${cqPath}/${Constants.JCR_CONTENT}/${itemKey}` : itemKey;
			
			return (
				<ItemComponent
					{...itemProps}
					cqPath={itemPath}
					key={itemKey}
				/>
			);
		};
		const childComponents = computed(() => {
			const { cqItems, cqItemsOrder } = aemProperties.value;
			if (!cqItems || !cqItemsOrder) return [];

			return cqItemsOrder.map((itemKey) => {
				const itemProps = Utils.modelToProps(cqItems[itemKey]);
				if (!itemProps) return null;
				
				const ItemComponent = getMappedComponent(itemProps.cqType);
				if (!ItemComponent) return null;
				return getItemComponent(ItemComponent, itemProps, itemKey);
			});
		});
		
		const childPages = computed(() => {
			const { cqItems, cqItemsOrder, cqChildren } = aemProperties.value;
			if (!cqChildren || Object.keys(cqChildren).length === 0) return [];
			
			return Object.keys(cqChildren).map((itemKey) => {
				const itemProps = Utils.modelToProps(cqChildren[itemKey]);
				const ItemComponent = getMappedComponent(itemProps.cqType);
				
				if (!ItemComponent) return null;
				
				return (
					<ItemComponent
						cqPath={itemProps.cqPath}
						isInEditor={AuthoringUtils.isInEditor()}
						props={itemProps}
					/>
				);
			});
		});
		
		// eslint-disable-next-line  vue/no-setup-props-destructure
		document.title = props.title ?? document.title;
		
		console.log(
			'Model data for this page: ',
			JSON.parse(
				JSON.stringify(
					toRaw(aemProperties.value),
					// preserve undefined value by converting it to null
					(k, v) => (v === undefined ? null : v),
				)
			)
		);
		
		return {
			aemProperties,
			componentId,
			
			wrapperHtmlAttributes,
			wrapperHtmlClass,
			
			childComponents,
			childPages,
		};
	},
	render () {
		// console.log('AEMPage.jsx: this.childComponents = ', this.childComponents);
		// console.log('AEMPage.jsx: this.childPages = ', this.childPages);
		
		const wrapperAttrs = {
			class: Constants._PAGE_CLASS_NAMES,
			...(AuthoringUtils.isInEditor() ? { 'data-cq-data-path': this.aemProperties.cqPath } : null),
		};
		
		return (
			<div
				{...this.wrapperHtmlAttributes}
				class={this.wrapperHtmlClass}
				key={this.componentId}
				data-component="AEMPage"
			>
				<div
					{...wrapperAttrs}
				>
					{this.childComponents}
					{this.childPages}
				</div>
			</div>
		);
	},
});

export {
	AEMPage,
};
