<script>
export default {
	name: 'ContinueYourSearch',
};
</script>

<script setup>
import dayjs from 'dayjs';
import VueSwiper from '~/components/VueSwiper.vue';
import usePersistencyWithExpiry from '~/logic/composables/usePersistencyWithExpiry.js';
import { isEqual as _isEqual } from 'lodash';
import { i18nGlobal } from '~/logic/i18n.js';
import { sleep } from '~/logic/helpers/utils';

const props = defineProps({
	// modelValue: { type: String, default: '' },
	economyLabel: { type: String, default: i18nGlobal.t('Economy') },
	businessLabel: { type: String, default: i18nGlobal.t('Business') },
	businessSuiteLabel: { type: String, default: i18nGlobal.t('Business Suite') },
	continueYourSearchLabel: { type: String, default: 'Continue your search' },
	componentIdAA: { type: String, default: '' },
});
const emit = defineEmits([
	'restore-past-search',
]);


const pastSearches = ref([]);

usePersistencyWithExpiry('mhe-flight-search:past-searches', {
	refs: {
		pastSearches,
	},
});

const saveSearch = (data) => {
	const savingDataEntries = Object.entries(data);
	let currentData = pastSearches.value;
	
	const existingMatchIndex = pastSearches.value.findIndex((existingData) => {
		return savingDataEntries.every(([savingKey, savingValue]) => {
			return _isEqual(unref(savingValue), unref(existingData[savingKey]));
		});
	});
	
	if (existingMatchIndex !== -1) {
		currentData.splice(existingMatchIndex, 1);
	}
	
	pastSearches.value = [ data, ...currentData ].slice(0, 3);
};

const computedDisableTeleport = computed(() => {
	const teleportTarget = document.querySelector('#mobile-past-searches-container');
	if (teleportTarget) return false;
	return true;
});

const getCabinClassLabelByValue = (val) => {
	switch (parseInt(val, 10)) {
		case 1: {
			return props.economyLabel;
		}
		case 2: {
			return props.businessLabel;
		}
		case 3: {
			return props.businessSuiteLabel;
		}
		default: {
			break;
		}
	}
};

onMounted(async () => {
	await sleep(1000);
	pastSearches.value = [...pastSearches.value];
});

defineExpose({
	saveSearch,
});


</script>

<template>
<div
	v-if="pastSearches && pastSearches.length > 0"
	class="ContinueYourSearch overflow-hidden"
>

	<div class="md:hidden">
		<p class="typo-category-title mb-4 md:px-4 pt-8">{{ props.continueYourSearchLabel }}</p>
		<div class="continue-your-search-wrapper gap-6 lg:gap-3 w-full">
			<button
				v-for="(item, index) in pastSearches"
				:key="index"
				v-aa="[
					{
						clickName: `From: ${item.ondValueFrom.displayValue ?? '-'} | To : ${item.ondValueTo.displayValue ?? '-'} | Departure date: ${dayjs(item.dateRangeValueFrom).format('DD MMM YYYY') ?? '-'} | Return date: ${dayjs(item.dateRangeValueTo).format('DD MMM YYYY') ?? '-'} | Passenger: ${ item.adultsCount + item.infantsCount }`,
						clickComponentType: 'Button',
						componentName: 'ContinueYourSearch',
						componentID: props.componentIdAA,
					},
					{
						name: `From: ${item.ondValueFrom.displayValue ?? '-'} | To : ${item.ondValueTo.displayValue ?? '-'} | Departure date: ${dayjs(item.dateRangeValueFrom).format('DD MMM YYYY') ?? '-'} | Return date: ${dayjs(item.dateRangeValueTo).format('DD MMM YYYY') ?? '-'} | Passenger: ${ item.adultsCount + item.infantsCount }`,
						type: 'other',
					},
				]"
				type="button"
				class="continue-your-search-tile"
				@click="$emit('restore-past-search', item)"
			>
				<icon-far-magnifying-glass class="icon fill-neutral-grey-dark mr-3 rtl:(mr-0 ml-3) mt-0.5" />
				
				<div class="flex flex-col">
					<div class="flex items-center flex-wrap">
						<span class="font-semibold">{{ item.ondValueFrom.displayValue }}</span>
						<icon-fas-plane class="fill-neutral-grey-dark mx-3 transform rtl:-rotate-180" />
						<span class="font-semibold">{{ item.ondValueTo.displayValue }}</span>
					</div>
					<time class="mt-2">
						<template v-if="item.dateRangeValueTo && dayjs(item.dateRangeValueTo).isValid()">
							{{ $t('{dateFrom} to {dateTo}', {
								dateFrom: dayjs(item.dateRangeValueFrom).format('DD MMM YYYY'),
								dateTo: dayjs(item.dateRangeValueTo).format('DD MMM YYYY'),
							}) }}
						</template>
						<template v-else>
							<span>{{ dayjs(item.dateRangeValueFrom).format('DD MMM YYYY') }}</span>
						</template>
					</time>
					
					<div v-if="item.isBSTTab" class="mt-2">
						<div class="tag-label">{{ $t('Bonus Side Trip') }}</div>
					</div>
					<div v-else-if="item.payByEnrichPoints" class="mt-2">
						<div class="tag-label">{{ $t('Redemption') }}</div>
					</div>
					
					<div class="mt-auto pt-3 flex items-center">
						<span class="inline-flex items-center mr-4 rtl:mr-0 rtl:ml-4">
							{{ item.adultsCount + item.teensCount + item.childrenCount + item.infantsCount }}
							<icon-fas-user class="fill-neutral-grey-ultradark w-2.5 mx-2" />
						</span>
						<span>{{ getCabinClassLabelByValue(item.cabinClassValue.value) }}</span>
					</div>
				</div>
			</button>
		</div>
	</div>

	<div class=">md:hidden -mx-4">
		<Teleport to="#mobile-past-searches-container" :disabled="computedDisableTeleport">
			<p class="typo-category-title continue-search-label text mb-4 md:px-4 pt-8">{{ props.continueYourSearchLabel }}</p>
			<VueSwiper
				class="mobile-swiper"
				slidesPerView="auto"
				:spaceBetween="16"
				variant="slides-auto-width slides-auto-height"
				:scrollbar="{
					draggable: true,
					hide: true,
				}"
				style="--tile-max-width: 260px;"
			>
				<button
					v-for="(item, index) in pastSearches"
					:key="index"
					v-aa="[
						{
							clickName: `From: ${item.ondValueFrom.displayValue ?? '-'} | To : ${item.ondValueTo.displayValue ?? '-'} | Departure date: ${dayjs(item.dateRangeValueFrom).format('DD MMM YYYY') ?? '-'} | Return date: ${dayjs(item.dateRangeValueTo).format('DD MMM YYYY') ?? '-'} | Passenger: ${ item.adultsCount + item.infantsCount }`,
							clickComponentType: 'Button',
							componentName: 'ContinueYourSearch',
							componentID: props.componentIdAA,
						},
						{
							name: `From: ${item.ondValueFrom.displayValue ?? '-'} | To : ${item.ondValueTo.displayValue ?? '-'} | Departure date: ${dayjs(item.dateRangeValueFrom).format('DD MMM YYYY') ?? '-'} | Return date: ${dayjs(item.dateRangeValueTo).format('DD MMM YYYY') ?? '-'} | Passenger: ${ item.adultsCount + item.infantsCount }`,
							type: 'other',
						},
					]"
					type="button"
					class="continue-your-search-tile"
					@click="$emit('restore-past-search', item)"
				>
					<icon-far-magnifying-glass class="icon fill-neutral-grey-dark mr-3 rtl:(mr-0 ml-3) mt-0.5" />
					
					<div class="flex flex-col">
						<div class="flex items-center flex-wrap">
							<span class="font-semibold">{{ item.ondValueFrom.displayValue }}</span>
							<icon-fas-plane class="fill-neutral-grey-dark mx-3 transform rtl:-rotate-180" />
							<span class="font-semibold">{{ item.ondValueTo.displayValue }}</span>
						</div>
						<time class="mt-2">
							<template v-if="item.dateRangeValueTo && dayjs(item.dateRangeValueTo).isValid()">
								{{ $t('{dateFrom} to {dateTo}', {
									dateFrom: dayjs(item.dateRangeValueFrom).format('DD MMM YYYY'),
									dateTo: dayjs(item.dateRangeValueTo).format('DD MMM YYYY'),
								}) }}
							</template>
							<template v-else>
								<span>{{ dayjs(item.dateRangeValueFrom).format('DD MMM YYYY') }}</span>
							</template>
						</time>
						
						<div v-if="item.isBSTTab" class="mt-2">
							<div class="tag-label">{{ $t('Bonus Side Trip') }}</div>
						</div>
						<div v-else-if="item.payByEnrichPoints" class="mt-2">
							<div class="tag-label">{{ $t('Redemption') }}</div>
						</div>
						
						<div class="mt-auto pt-3 flex items-center">
							<span class="inline-flex items-center mr-4 rtl:mr-0 rtl:ml-4">
								{{ item.adultsCount + item.infantsCount }}
								<icon-fas-user class="fill-neutral-grey-ultradark w-2.5 mx-2" />
							</span>
						</div>
					</div>
				</button>
			</VueSwiper>
		</Teleport>
	</div>
	
</div>


</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';


.ContinueYourSearch {
	
}

.continue-your-search-wrapper {
	--tile-max-width: 300px;
	display: grid;
	grid-auto-columns: minmax(0, var(--tile-max-width));
	grid-auto-flow: column;
}

.continue-your-search-tile {
	border-radius: 12px;
	border: 1.5px solid var(--neutral-grey-light);
	background-color: var(--neutral-grey-ultralight);
	color: var(--neutral-grey-ultradark);
	display: flex;
	align-items: stretch;
	text-align: left;
	flex-basis: var(--tile-max-width);
	min-height: 140px;
	transition: border-color 250ms, background-color 250ms;
	@apply p-4 text-sm;

	html[dir="rtl"] & {
		text-align: right;
	}
	
	&:hover {
		border-color: var(--neutral-grey-dark);
		background-color: var(--neutral-grey-extralight);
	}
	.icon {
		flex: 0 0 16px;
	}
	.tag-label {
		text-transform: uppercase;
		border-radius: 4px;
		color: white;
		display: inline-flex;
		background-color: var(--primary-blue-base);
		font-weight: 600;
		@apply px-3 py-1 text-xs;
	}
	
	@media #{var.$query-max-md} {
		height: 100%;
		width: var(--tile-max-width);
	}
}

.mobile-swiper {
	@apply pb-6;
	
	:deep(.swiper-slide:first-child) {
		@apply pl-4;
		
		html[dir="rtl"] & {
			@apply pl-0 pr-4;
		}
	}
	:deep(.swiper-slide:last-child) {
		@apply pr-4;
		
		html[dir="rtl"] & {
			@apply pr-0 pl-4;
		}
	}
	:deep(.swiper-scrollbar) {
		width: 80px;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		
		.swiper-scrollbar-drag {
			background-color: var(--primary-blue-base);
		}
	}
}

</style>
