<script>
export default {
	name: 'MHCalendarDouble',
};
</script>

<script setup>
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { calendarDMY } from '~/logic/composables/calendarDMY.js';
import { useCalendarRestrictions } from '~/logic/composables/useCalendarRestrictions.js';
import CTAButton from '~/aem-components/CTAButton.vue';
import Checkbox from '~/components/form/Checkbox.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { times as _times } from 'lodash';
import { i18nGlobal } from '~/logic/i18n.js';
import { formatNumberWithAbbreviation } from '~/logic/helpers/string.js';
import { getAncestorBySelector } from '~/logic/helpers/dom.js';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const props = defineProps({
	modelValueFrom: { type: Object, default: null, validator: dayjs.isDayjs }, // expects dayjs instance
	modelValueTo: { type: Object, default: null, validator: dayjs.isDayjs }, // expects dayjs instance
	
	isOneWay: { type: Boolean, default: false },
	canNavigateMonth: { type: Boolean, default: true },
	
	// restrictions
	/* 
	minDate / maxDate prop:
		· Can support absolute date or relative date
		
		Absolute dates
		· Format is 'YYYY-MM-DD'
		E.g. '2022-11-26'
		
		Relative dates
		· t = today
		· Only support + / -
		· No whitespace allowed.
		· Must end with d / m / y
		E.g.
			't-10d' <-- today minus 10 days
			't-2m' <-- today minus 2 months
			't+1y' <-- today plus 1 year
	*/
	minDate: { type: String, default: null },
	maxDate: { type: String, default: null },
	
	// disabledDates iterates over the Array for every date. That is slow, avoid if possible.
	disabledDates: { type: Array, default: null },
	
	priceData: { type: Object, default: null },
	
	defaultViewDate: { type: Object, default: null, validator: dayjs.isDayjs }, // expects dayjs instance
	// defaultPointedDate: { type: Object, default: null, validator: dayjs.isDayjs }, // expects dayjs instance
	
	isHideTodayIndicator: { type: Boolean, default: false },
	forceOneWay: { type: Boolean, default: false },

	labelReset: { type: String, default: i18nGlobal.t('Reset') },
	labelDone: { type: String, default: i18nGlobal.t('Done') },
	labelOneWay: { type: String, default: i18nGlobal.t('One-way') },
	hideOneWay: { type: Boolean, default: false },
	
});
const emit = defineEmits([
	'select-date',
	'reset-dates',
	'close-panel',
	'set-is-one-way',
	'view-date-changed',
	// 'pointed-date-changed',
]);

const siteName = window.siteName;

const rootEl = ref(null);

const restrictions = computed(() => {
	return {
		minDate: props.minDate,
		maxDate: props.maxDate,
		disabledDates: props.disabledDates,
	};
});

const {
	computedMinDate,
	computedMaxDate,
	computedDisabledDates,
	checkIsDisabledFunc,
} = useCalendarRestrictions(restrictions);

const getInitialViewAndPointedDate = () => {
	let results = {
		viewDate: props.defaultViewDate,
		pointedDate: props.defaultPointedDate,
	};
	const modelDate = dayjs(props.modelValueFrom).isValid() ? dayjs(props.modelValueFrom) : null;
	const today = dayjs();
	const defaultDate = modelDate ?? today;
	
	if (computedMinDate.value && computedMaxDate.value) {
		// both min and max date
		
		// check is between
		if (
			defaultDate.isSameOrAfter(computedMinDate.value, 'month') &&
			defaultDate.isSameOrBefore(computedMaxDate.value, 'month')
		) {
			if (!results.viewDate) results.viewDate = defaultDate;
			if (!results.pointedDate) results.pointedDate = defaultDate;
		} else {
			// ops
			if (!results.viewDate) results.viewDate = computedMinDate.value;
			if (!results.pointedDate) results.pointedDate = computedMinDate.value;
		}
		
	} else if (computedMinDate.value) {
		// only min date
		if (defaultDate.isSameOrAfter(computedMinDate.value, 'month')) {
			if (!results.viewDate) results.viewDate = defaultDate;
			if (!results.pointedDate) results.pointedDate = defaultDate;
		} else {
			// ops
			if (!results.viewDate) results.viewDate = computedMinDate.value;
			if (!results.pointedDate) results.pointedDate = computedMinDate.value;
		}
	} else if (computedMaxDate.value) {
		// only max date
		if (defaultDate.isSameOrBefore(computedMaxDate.value, 'month')) {
			if (!results.viewDate) results.viewDate = defaultDate;
			if (!results.pointedDate) results.pointedDate = defaultDate;
		} else {
			// ops
			if (!results.viewDate) results.viewDate = computedMaxDate.value;
			if (!results.pointedDate) results.pointedDate = computedMaxDate.value;
		}
	} else {
		// neither min nor max
		if (!results.viewDate) results.viewDate = defaultDate;
		if (!results.pointedDate) results.pointedDate = defaultDate;
	}
	
	return results;
};

const setCurrentView = (dayjsInstance) => {
	currentViewDate.value = dayjsInstance;
	emit('view-date-changed', dayjsInstance);
};


const _initials = getInitialViewAndPointedDate();

const currentViewDate = ref(null);
const pointedDate = ref(_initials.pointedDate);

setCurrentView(_initials.viewDate);

const {
	currentViewMonthName: monthNameFrom,
	currentViewYearName: yearNameFrom,
	
	dayList: dayListFrom,
} = calendarDMY(currentViewDate, {
	computedMinDate,
	computedMaxDate,
	computedDisabledDates,
	checkIsDisabledFunc,
});

const currentViewDateNextMonth = computed(() => {
	return currentViewDate.value.add(1, 'month').date(1);
});

const {
	currentViewMonthName: monthNameTo,
	currentViewYearName: yearNameTo,
	
	dayList: dayListTo,
} = calendarDMY(currentViewDateNextMonth, {
	computedMinDate,
	computedMaxDate,
	computedDisabledDates,
	checkIsDisabledFunc,
});

/* 
!!! this is an anti-pattern in Vue.
----------------------------------
Here we apply a 'cheat' to get the initial value of min date, but because computedMinDate
is reactive, any user selection will cause prependedMonthsInMobile to recalculate.
BUT, we are not interested in that, for the purpose of displaying prior months in the UI, we only need _initialMinDate.
So, we store the initial value of computedMinDate to a const, that is NOT reactive...
*/
const _initialMinDate = computedMinDate.value;

const prependedMonthsInMobile = computed(() => {
	// like additionalMonthsInMobile, these are the months to be added at the start
	const monthsArr = [];
	let numOfMonthsDiff = 0;
	
	// because we are going to compute the difference in month for these 2 dates, set the date and time to the same
	const initialMinDateFirstOfTheMonth = _initialMinDate.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
	const currentViewFirstOfTheMonth = currentViewDate.value.set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
	
	if (_initialMinDate) {
		numOfMonthsDiff = Math.ceil(Math.abs(initialMinDateFirstOfTheMonth.diff(currentViewFirstOfTheMonth, 'month', true)));
	}
	
	_times(numOfMonthsDiff, (n) => {
		monthsArr.unshift(currentViewFirstOfTheMonth.subtract(n + 1, 'month'));
	});
	
	return monthsArr.map((date) => {
		const {
			currentViewMonthName: monthName,
			currentViewYearName: yearName,
			dayList,
		} = calendarDMY(date, {
			computedMinDate,
			computedMaxDate,
			computedDisabledDates,
			checkIsDisabledFunc,
		});
		
		return {
			monthName: monthName.value,
			yearName: yearName.value,
			dayList: dayList.value,
		};
	});
});

const additionalMonthsInMobile = computed(() => {
	const monthsArr = [];
	let diffMonth = 12;
	
	if (computedMaxDate.value) {
		// console.log('additionalMonthsInMobile: computedMaxDate = ', computedMaxDate.value.format('YYYY-MM-DD'));
		// console.log('additionalMonthsInMobile: currentViewDateNextMonth = ', currentViewDateNextMonth.value.format('YYYY-MM-DD'));
		
		// true as 3rd argument makes dayjs returns float
		let r = computedMaxDate.value.diff(currentViewDateNextMonth.value, 'month', true);
		// console.log('r = ', r);
		if (r > 0) {
			// very important to ceiling it
			// minus 1 because we are using "next month" as comparison,
			// so we should deduct it because by default it is already showing "next month" in the UI
			// This value is hardcoded to be max 12, worry that it might have performance issue
			diffMonth = Math.min(Math.ceil(r), diffMonth) - 1;
		}
		// console.log('diffMonth = ', diffMonth);
	}
	
	_times(diffMonth, (n) => {
		monthsArr.push(currentViewDateNextMonth.value.add(n + 1, 'month'));
	});
	
	return monthsArr.map((date) => {
		const {
			currentViewMonthName: monthName,
			currentViewYearName: yearName,
			dayList,
		} = calendarDMY(date, {
			computedMinDate,
			computedMaxDate,
			computedDisabledDates,
			checkIsDisabledFunc,
		});
		
		return {
			monthName: monthName.value,
			yearName: yearName.value,
			dayList: dayList.value,
		};
	});
});

const getClosestEnabledDateFrom = (fromDateDayjs) => {
	if (checkIsDisabledFunc.value(fromDateDayjs) === false) return fromDateDayjs;
	
	let attemptCount = 0;
	const MAX_ATTEMPTS = 62;
	let found = null;
	
	while (attemptCount < MAX_ATTEMPTS && !found) {
		attemptCount++;
		let d = fromDateDayjs.add(attemptCount, 'day');
		if (checkIsDisabledFunc.value(d) === false) {
			found = d;
		}
	}
	
	return found;
};

const goToPrevMonth = () => {
	if (isNavigationDisabledPrev.value) {
		// showToast(`${currentViewDate.value.subtract(1, 'month').format('MMM YYYY')} is disabled`);
		return;
	}
	
	setCurrentView(currentViewDate.value.subtract(1, 'month'));
	
	// need to replace the instance for reactivity to work
	pointedDate.value = getClosestEnabledDateFrom(dayjs(currentViewDate.value).date(1));
};
const goToNextMonth = () => {
	if (isNavigationDisabledNext.value) {
		// showToast(`${currentViewDate.value.add(1, 'month').format('MMM YYYY')} is disabled`);
		return;
	}
	
	setCurrentView(currentViewDate.value.add(1, 'month'));
	
	// need to replace the instance for reactivity to work
	pointedDate.value = getClosestEnabledDateFrom(dayjs(currentViewDate.value).date(1));
};


const getPointedDateOrAlternative = (dayjsInstance, direction) => {
	// check to make sure we are not limited by min / max date
	if (
		dayjsInstance.isBefore(computedMinDate.value, 'day') ||
		dayjsInstance.isAfter(computedMaxDate.value, 'day')
	) {
		// no point checking further because there will be no enabled dates
		if (direction === 'left' || direction === 'up') {
			// backwards
			// completely block navigation
			return false;
		} else {
			// forwards
			// jump to min date
			return computedMinDate.value;
		}
	}
	
	if (computedDisabledDates.value.length > 0) {
		// has disabled dates, loop through each item
		// if EVERY item is not same as date, then PASS
		if (computedDisabledDates.value.every((d) => !d.isSame(dayjsInstance, 'day'))) return true;
	} else {
		// no disabled date and NO min / max restrictions, shall PASS
		return true;
	}
	
	// at this point, we have a disabled date that matches the date, so we need to find alternative
	
	let attemptCount = 0;
	const MAX_ATTEMPTS = 60;
	
	while (attemptCount < MAX_ATTEMPTS) {
		attemptCount++;
		let testDate;
		if (direction === 'left' || direction === 'up') {
			// going backwards
			testDate = dayjsInstance.subtract(attemptCount, 'day');
		} else {
			// going forward
			testDate = dayjsInstance.add(attemptCount, 'day');
		}
		if (testDate.isBefore(computedMinDate.value) || testDate.isAfter(computedMaxDate.value)) {
			// no hope of finding an enabled date
			return false;
		}
		if (computedDisabledDates.value.every((d) => !d.isSame(testDate, 'day'))) {
			// ok
			return testDate; // <-- exit the while() loop prematurely
		}
	}
};

const getNewViewBasedOnPointed = (dayjsInstance) => {
	if (isMobileViewport.value) {
		/* 
			Because of the way the UI works in mobile, we don't ever need a new view, due to
			that the months are all displayed in a scrollable vertical stack.
		*/
		return false;
	}
	const currentViewFirstDate = currentViewDate.value.date(1); // we only care about current month, ignore the date
	
	if (
		dayjsInstance.isBefore(currentViewFirstDate, 'month')
	) {
		return dayjsInstance.date(1);
	}
	
	if (
		dayjsInstance.isAfter(currentViewFirstDate, 'month') &&
		dayjsInstance.diff(currentViewFirstDate, 'month') >= 2
	) {
		// difference is 2 months or more
		return dayjsInstance.subtract(1, 'month').date(1);
	}
	
	return false;
};

const handleArrowNavigation = async (direction) => {
	let newPointedDate = null;
	
	switch (direction) {
		case 'left': {
			newPointedDate = pointedDate.value.subtract(1, 'day');
			break;
		}
		case 'right': {
			newPointedDate = pointedDate.value.add(1, 'day');
			break;
		}
		case 'up': {
			newPointedDate = pointedDate.value.subtract(7, 'day');
			break;
		}
		case 'down': {
			newPointedDate = pointedDate.value.add(7, 'day');
			break;
		}
		default: { /* do nothing */ break; }
	}
	
	// refocus to 
	const result = getPointedDateOrAlternative(newPointedDate, direction);
	if (result === true) {
		// new pointed date is ok
		pointedDate.value = newPointedDate;
		// because this is double calendar, evaluate if an update to the view is needed
		const newViewDate = getNewViewBasedOnPointed(pointedDate.value);
		if (newViewDate) {
			// currentViewDate.value = newViewDate;
			setCurrentView(newViewDate);
		}
		await nextTick();
		const singleItemEl = rootEl.value?.querySelector(`[data-date="${ newPointedDate.format('YYYY-MM-DD') }"]`);
		if (singleItemEl) {
			singleItemEl.focus();
			if (isMobileViewport.value) singleItemEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	} else if (result instanceof dayjs) {
		// a new date is given, use that date instead
		pointedDate.value = result;
		// because this is double calendar, evaluate if an update to the view is needed
		// currentViewDate.value = dayjs(result);
		const newViewDate = getNewViewBasedOnPointed(pointedDate.value);
		if (newViewDate) {
			// currentViewDate.value = newViewDate;
			setCurrentView(newViewDate);
		}
		if (pointedDate.value.isAfter(currentViewDate.value.add(2, 'month'), 'month')) {
			// currentViewDate.value = dayjs(newPointedDate);
			setCurrentView(dayjs(newPointedDate));
		}
		
		await nextTick();
		const singleItemEl = rootEl.value?.querySelector(`[data-date="${ result.format('YYYY-MM-DD') }"]`);
		if (singleItemEl) {
			singleItemEl.focus();
			if (isMobileViewport.value) singleItemEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
		
	} else if (result === false) {
		// new pointed date and alternatives cannot be found, do nothing
		const toastString = i18nGlobal.t('{date} is disabled', {
			date: newPointedDate.format('DD MMM YYYY'),
		});
		showToast(toastString);
		return;
	}
};


const isNavigationDisabledPrev = computed(() => {
	return checkIsDisabledFunc.value(currentViewDate.value.subtract(1, 'month'), 'month');
});
const isNavigationDisabledNext = computed(() => {
	return checkIsDisabledFunc.value(currentViewDate.value.add(2, 'month'), 'month');
});


const handleRootKeyDelete = (event) => {
	handleSelectDate(null);
};


const toastMessage = ref('');

const showToast = (() => {
	let timeoutId = -1;
	return (msg, options) => {
		clearTimeout(timeoutId);
		toastMessage.value = msg;
		timeoutId = setTimeout(() => {
			toastMessage.value = '';
		}, 1500);
	};
})();


const handleSelectDate = (date) => {
	if (date === null) {
		emit('select-date', null);
	} else if (typeof date === 'string') {
		const dayjsInstance = dayjs(date);
		emit('select-date', dayjsInstance);
		pointedDate.value = dayjsInstance;
	} else if (date instanceof dayjs) {
		emit('select-date', date);
		pointedDate.value = date;
	}
};

const isDisabledDoneCTA = computed(() => {
	if (!props.isOneWay && (!props.modelValueFrom || !props.modelValueTo)) {
		return true;
	}
	if (props.isOneWay && !props.modelValueFrom) {
		return true;
	}
	return false;
});

onMounted(async () => {
	if (isMobileViewport.value && currentViewDate.value && rootEl.value) {
		// Only mobile and a date selection has been made
		const firstSelectedDayEl = rootEl.value.querySelector('.day-single.is-selected');
		const containerEl = getAncestorBySelector(firstSelectedDayEl, '.dmy-container');
		if (containerEl[0]) {
			// scroll to the user selected month
			await nextTick();
			containerEl[0].scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'instant' });
		}
	}
});


defineExpose({
	handleArrowNavigation,
});

</script>

<template>
<div
	ref="rootEl"
	class="MHCalendarDouble"
	:class="{
		'hide-today-indicator': props.isHideTodayIndicator,
	}"
	:data-use-theme="siteName"
	tabindex="-1"
	:aria-label="$t('Use arrow keys to navigate and enter key to select date')"
	@keydown.backspace.delete.prevent="handleRootKeyDelete"
	@keydown.down.prevent="handleArrowNavigation('down')"
	@keydown.right.prevent="handleArrowNavigation('right')"
	@keydown.up.prevent="handleArrowNavigation('up')"
	@keydown.left.prevent="handleArrowNavigation('left')"
>
	<header class="header">
		<div class="flex-grow relative basis-0 flex">
			<button
				v-button-press
				type="button"
				class="btn-arrow arrow-left btn-generic-b"
				:class="{
					'is-disabled': isNavigationDisabledPrev,
				}"
				:aria-label="`Previous month${isNavigationDisabledPrev ? ` (${$t('disabled')})` : ''}`"
				@click="goToPrevMonth"
			>
				<icon-far-chevron-left class="btn-arrow-icon fill-current transform rtl:-rotate-180" aria-hidden="true" />
			</button>
			
			<div class="mx-auto font-semibold text-primary-blue-base leading-none">
				{{ monthNameFrom }}
				{{ yearNameFrom }}
			</div>
		</div>
		
		<div class="flex-grow relative basis-0 flex">
			<div class="mx-auto font-semibold text-primary-blue-base leading-none">
				{{ monthNameTo }}
				{{ yearNameTo }}
			</div>
			
			<button
				v-button-press
				type="button"
				class="btn-arrow arrow-right btn-generic-b"
				:class="{
					'is-disabled': isNavigationDisabledNext,
				}"
				:aria-label="`Next month${isNavigationDisabledNext ? ` (${$t('disabled')})` : ''}`"
				@click="goToNextMonth"
			>
				<icon-far-chevron-right class="btn-arrow-icon fill-current transform rtl:-rotate-180" aria-hidden="true" />
			</button>
		</div>
	</header>
	
	<header class="header-mobile >md:hidden">
		<div class="header-mobile-inner">
			<div class="flex mb-3 items-center">
				<Checkbox
					v-if="!props.hideOneWay"
					:modelValue="props.forceOneWay ? true : isOneWay"
					name="isOneWay"
					:aria-label="props.labelOneWay"
					:disabled="props.forceOneWay"
				>
					<div class="flex items-center">{{ props.labelOneWay }}</div>
				</Checkbox>
				
				<button
					v-button-press
					type="button"
					class="btn-generic-b text-blue-primary-base"
					:class="{ 'ml-auto': !props.hideOneWay }"
					@click="$emit('reset-dates')"
				>
					<span class="flex items-center mx-2">
						<icon-far-rotate-right class="mr-2 rtl:(mr-0 ml-2) fill-current" />
						{{ props.labelReset }}
					</span>
				</button>
			</div>
			<div class="w-full grid grid-cols-7 gap-y-1 self-start">
				<div
					v-for="(dayName, index) in dayjs.weekdaysShort()"
					:key="dayName"
					class="day-of-the-week"
					:class="`day-${index}`"
				>{{ dayName }}</div>
			</div>
		</div>
	</header>
	
	<div class="body">
		<!-- MOBILE ONLY -->
		<template v-if="isMobileViewport">
			<div
				v-for="month in prependedMonthsInMobile"
				:key="month"
				class="dmy-container"
			>
				<div class=">md:hidden font-semibold text-primary-blue-base leading-none w-full text-center mb-4">
					{{ month.monthName }}
					{{ month.yearName }}
				</div>
				
				<div class="dmy-wrapper flex-grow overflow-y-auto styled-scrollbar flex items-center px-1 py-1">
					<div
						class="w-full grid grid-cols-7 gap-y-1 self-start py-0.5"
					>
						<div
							v-for="(dayName, index) in dayjs.weekdaysShort()"
							:key="dayName"
							class="day-of-the-week"
							:class="`day-${index}`"
						>{{ dayName }}</div>
						
						<template
							v-for="(day, index) in month.dayList"
							:key="index"
						>
							<template v-if="day.isVoid">
								<div
									class="day-void"
									aria-hidden="true"
								></div>
							</template>
							<template v-else>
								<div class="single-wrapper flex justify-center">
									<button
										type="button"
										class="day-single btn-generic-b p-1"
										:class="{
											'is-today': day.isToday,
											'is-selected': day.dayjsInstance.isSame(props.modelValueFrom, 'day') || day.dayjsInstance.isSame(props.modelValueTo, 'day'),
											'is-selected-from': day.dayjsInstance.isSame(props.modelValueFrom, 'day'),
											'is-selected-to': day.dayjsInstance.isSame(props.modelValueTo, 'day'),
											'is-between-selection': (props.modelValueFrom && props.modelValueTo) ? day.dayjsInstance.isBetween(props.modelValueFrom, props.modelValueTo, 'day', '[]') : false,
											'is-pointed': day.dayjsInstance.isSame(pointedDate, 'day'),
										}"
										:tabindex="day.dayjsInstance.isSame(pointedDate, 'day') ? 0 : -1"
										:data-date="day.dataDate"
										:aria-label="day.dayjsInstance.format('YYYY-MM-DD')"
										:disabled="day.isDisabled"
										@click="handleSelectDate(day.dayjsInstance)"
									>
										<span class="date-number">{{ day.dayjsInstance.format('DD') }}</span>
										<span class="price-info w-full">
											{{ formatNumberWithAbbreviation(priceData?.[day.dayjsInstance.format('YYYY-MM-DD')]) ?? null }}
										</span>
									</button>
								</div>
							</template>
						</template>
						
					</div>
				</div>
			</div>
		</template>
		
		<div class="dmy-container">
			<div class=">md:hidden font-semibold text-primary-blue-base leading-none w-full text-center mb-4">
				{{ monthNameFrom }}
				{{ yearNameFrom }}
			</div>
			
			<div class="dmy-wrapper flex-grow overflow-y-auto styled-scrollbar flex items-center px-1 py-1">
				<div
					class="w-full grid grid-cols-7 gap-y-1 self-start py-0.5"
				>
					<div
						v-for="(dayName, index) in dayjs.weekdaysShort()"
						:key="dayName"
						class="day-of-the-week "
						:class="`day-${index}`"
					>{{ dayName }}</div>
					
					<template
						v-for="(day, index) in dayListFrom"
						:key="index"
					>
						<template v-if="day.isVoid">
							<div
								class="day-void"
								aria-hidden="true"
							></div>
						</template>
						<template v-else>
							<div class="single-wrapper flex justify-center">
								<button
									type="button"
									class="day-single btn-generic-b p-1"
									:class="{
										'is-today': day.isToday,
										'is-selected': day.dayjsInstance.isSame(props.modelValueFrom, 'day') || day.dayjsInstance.isSame(props.modelValueTo, 'day'),
										'is-selected-from': day.dayjsInstance.isSame(props.modelValueFrom, 'day'),
										'is-selected-to': day.dayjsInstance.isSame(props.modelValueTo, 'day'),
										'is-between-selection': (props.modelValueFrom && props.modelValueTo) ? day.dayjsInstance.isBetween(props.modelValueFrom, props.modelValueTo, 'day', '[]') : false,
										'is-pointed': day.dayjsInstance.isSame(pointedDate, 'day'),
										'is-disabled': day.isDisabled,
									}"
									:tabindex="day.dayjsInstance.isSame(pointedDate, 'day') ? 0 : -1"
									:data-date="day.dataDate"
									:aria-label="day.dayjsInstance.format('YYYY-MM-DD')"
									@click="day.isDisabled ? null : handleSelectDate(day.dayjsInstance)"
								>
									<span class="date-number">{{ day.dayjsInstance.format('DD') }}</span>
									<span class="price-info w-full">
										{{ formatNumberWithAbbreviation(priceData?.[day.dayjsInstance.format('YYYY-MM-DD')]) ?? null }}
									</span>
								</button>
							</div>
						</template>
					</template>
					
				</div>
			</div>
		</div>
		
		<div class="dmy-container">
			<div class=">md:hidden font-semibold text-primary-blue-base leading-none w-full text-center mb-4">
				{{ monthNameTo }}
				{{ yearNameTo }}
			</div>
			
			<div class="dmy-wrapper flex-grow overflow-y-auto styled-scrollbar flex items-center px-1 py-1">
				<div
					class="w-full grid grid-cols-7 gap-y-1 self-start py-0.5"
				>
					<div
						v-for="(dayName, index) in dayjs.weekdaysShort()"
						:key="dayName"
						class="day-of-the-week"
						:class="`day-${index}`"
					>{{ dayName }}</div>
					
					<template
						v-for="(day, index) in dayListTo"
						:key="index"
					>
						<template v-if="day.isVoid">
							<div
								class="day-void"
								aria-hidden="true"
							></div>
						</template>
						<template v-else>
							<div class="single-wrapper flex justify-center">
								<button
									type="button"
									class="day-single btn-generic-b p-1"
									:class="{
										'is-today': day.isToday,
										'is-selected': day.dayjsInstance.isSame(props.modelValueFrom, 'day') || day.dayjsInstance.isSame(props.modelValueTo, 'day'),
										'is-selected-from': day.dayjsInstance.isSame(props.modelValueFrom, 'day'),
										'is-selected-to': day.dayjsInstance.isSame(props.modelValueTo, 'day'),
										'is-between-selection': (props.modelValueFrom && props.modelValueTo) ? day.dayjsInstance.isBetween(props.modelValueFrom, props.modelValueTo, 'day', '[]') : false,
										'is-pointed': day.dayjsInstance.isSame(pointedDate, 'day'),
										'is-disabled': day.isDisabled,
									}"
									:tabindex="day.dayjsInstance.isSame(pointedDate, 'day') ? 0 : -1"
									:data-date="day.dataDate"
									:aria-label="day.dayjsInstance.format('YYYY-MM-DD')"
									@click="day.isDisabled ? null : handleSelectDate(day.dayjsInstance)"
								>
									<span class="date-number">{{ day.dayjsInstance.format('DD') }}</span>
									<span class="price-info w-full">
										{{ formatNumberWithAbbreviation(priceData?.[day.dayjsInstance.format('YYYY-MM-DD')]) ?? null }}
									</span>
								</button>
							</div>
						</template>
					</template>
					
				</div>
			</div>
		</div>
		
		<!-- MOBILE ONLY -->
		<template v-if="isMobileViewport">
			<!-- <pre><code>{{ additionalMonthsInMobile }}</code></pre> -->
			<div
				v-for="month in additionalMonthsInMobile"
				:key="month"
				class="dmy-container"
			>
				<div class=">md:hidden font-semibold text-primary-blue-base leading-none w-full text-center mb-4">
					{{ month.monthName }}
					{{ month.yearName }}
				</div>
				
				<div class="dmy-wrapper flex-grow overflow-y-auto styled-scrollbar flex items-center px-1 py-1">
					<div
						class="w-full grid grid-cols-7 gap-y-1 self-start py-0.5"
					>
						<div
							v-for="(dayName, index) in dayjs.weekdaysShort()"
							:key="dayName"
							class="day-of-the-week"
							:class="`day-${index}`"
						>{{ dayName }}</div>
						
						<template
							v-for="(day, index) in month.dayList"
							:key="index"
						>
							<template v-if="day.isVoid">
								<div
									class="day-void"
									aria-hidden="true"
								></div>
							</template>
							<template v-else>
								<div class="single-wrapper flex justify-center">
									<button
										type="button"
										class="day-single btn-generic-b p-1"
										:class="{
											'is-today': day.isToday,
											'is-selected': day.dayjsInstance.isSame(props.modelValueFrom, 'day') || day.dayjsInstance.isSame(props.modelValueTo, 'day'),
											'is-selected-from': day.dayjsInstance.isSame(props.modelValueFrom, 'day'),
											'is-selected-to': day.dayjsInstance.isSame(props.modelValueTo, 'day'),
											'is-between-selection': (props.modelValueFrom && props.modelValueTo) ? day.dayjsInstance.isBetween(props.modelValueFrom, props.modelValueTo, 'day', '[]') : false,
											'is-pointed': day.dayjsInstance.isSame(pointedDate, 'day'),
										}"
										:tabindex="day.dayjsInstance.isSame(pointedDate, 'day') ? 0 : -1"
										:data-date="day.dataDate"
										:aria-label="day.dayjsInstance.format('YYYY-MM-DD')"
										:disabled="day.isDisabled"
										@click="handleSelectDate(day.dayjsInstance)"
									>
										<span class="date-number">{{ day.dayjsInstance.format('DD') }}</span>
										<span class="price-info w-full">
											{{ formatNumberWithAbbreviation(priceData?.[day.dayjsInstance.format('YYYY-MM-DD')]) ?? null }}
										</span>
									</button>
								</div>
							</template>
						</template>
						
					</div>
				</div>
			</div>
		</template>
	</div>
	
	<footer class="footer">
		<div class="footer-inner">
			<button
				v-button-press
				type="button"
				class="btn-generic-b text-blue-primary-base mr-6 rtl:(mr-0 ml-6) !md:hidden"
				@click="$emit('reset-dates')"
			>
				<span class="flex items-center mx-2">
					<icon-far-rotate-right class="mr-2 rtl:(mr-0 ml-2) fill-current" />
					{{ props.labelReset }}
				</span>
			</button>
			
			<Checkbox
				v-if="!props.hideOneWay"
				:modelValue="props.forceOneWay ? true : isOneWay"
				class="md:hidden"
				name="isOneWay"
				:aria-label="props.labelOneWay"
				:disabled="props.forceOneWay"
				@update:modelValue="$emit('set-is-one-way', $event)"
			>
				<div class="flex items-center">{{ props.labelOneWay }}</div>
			</Checkbox>
			
			<div class="ml-auto rtl:(mr-auto ml-0) flex items-center md:ml-0 md:w-full">
				<div v-if="$slots['before-cta-done']" class="mr-6">
					<slot name="before-cta-done"></slot>
				</div>
			
				<CTAButton
					containerClass="md:ml-auto"
					styleType="primary"
					:disabled="isDisabledDoneCTA"
					@click="emit('close-panel'); $event.stopPropagation();"
				>
					{{ props.labelDone }}
				</CTAButton>
			</div>
		</div>
	</footer>
	
	<transition name="slide-in-from-bottom" appear>
		<aside v-if="toastMessage" class="toast-container">
			<span class="toast-message" role="alert">{{ toastMessage }}</span>
		</aside>
	</transition>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

@mixin todayDot () {
	content: '';
	display: block;
	margin-top: 2px;
	width: 4px;
	height: 4px;
	border-radius: 100%;
	background-color: var(--primary-blue-base);
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	bottom: 4px;
}

.MHCalendarDouble {
	width: 100%;
	display: flex;
	flex-direction: column;
	/* max-width: 892px;
	margin-left: auto;
	margin-right: auto; */
	
	@media #{var.$query-max-md} {
		--max-width: 375px;
	}
	
	&.hide-today-indicator {
		.is-today::after {
			display: none !important;
		}
	}
}


// ======================== HEADER ========================
.header {
	display: flex;
	align-items: center;
	@apply px-6 py-6 gap-6;
	
	@media #{var.$query-max-md} {
		flex-direction: column;
		width: 100%;
		max-width: var(--max-width);
		margin-left: auto;
		margin-right: auto;
		display: none;
		@apply px-4;
	}
}
.btn-arrow {
	z-index: 10;
	border-radius: 100%;
	color: var(--primary-blue-base);
	font-size: 12px;
	padding: 0;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -6.5px;
	
	&.arrow-left {
		left: 0;
		
		html[dir="rtl"] & {
			left: auto;
			right: 0;
		}
	}
	&.arrow-right {
		right: 0;
		
		html[dir="rtl"] & {
			right: auto;
			left: 0;
		}
	}
	/* &.is-disabled {
		color: var(--neutral-grey-dark);
		
		&.is-selected {
			background-color: var(--primary-blue-extralight);
		}
	} */
}
.header-mobile {
	position: sticky;
	top: 0;
	background-color: white;
	width: 100%;
	border-bottom: 2px solid var(--neutral-grey-base);
	z-index: 200;
}
.header-mobile-inner {
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
	@apply px-4 py-2;
	
	.day-of-the-week {
		display: block;
		text-align: center;
	}
}


// ======================== BODY ========================
.body {
	display: flex;
	@apply px-6 gap-6;
	
	@media #{var.$query-max-md} {
		display: grid;
		grid-template-columns: 1fr;
		width: 100%;
		max-width: var(--max-width);
		margin-left: auto;
		margin-right: auto;
		@apply px-4 mt-4;
	}
}

.dmy-container {
	flex-grow: 1;
	display: flex;
	
	@media #{var.$query-max-md} {
		flex-wrap: wrap;
	}
}

.dmy-wrapper {
	overscroll-behavior: none;
}

.day-of-the-week {
	text-transform: uppercase;
	font-weight: 600;
	color: var(--neutral-grey-extradark);
	display: flex;
	justify-content: center;
	@apply p-1 text-xs;
	
	@media #{var.$query-max-md} {
		display: none;
	}
}

.single-wrapper {
	
	&::before {
		content: '';
		padding-top: 100%;
	}
}

.day-single {
	border-radius: 8px;
	flex: 0 1 58px;
	width: 58px;
	color: var(--text-color);
	align-items: flex-start;
	flex-wrap: wrap;
	line-height: 1;
	position: relative;
	@apply pt-2.5;
	
	&.is-today {
		&::after {
			@include todayDot;
		}
	}
	&.is-between-selection {
		background-color: var(--primary-blue-extralight);
		
		&:not(.is-selected) {
			border-radius: 0;
		}
	}
	&.is-selected {
		background-color: var(--primary-blue-base);
		color: white;
		
		.price-info {
			color: white;
		}
		&.is-today {
			&::after {
				background-color: white;
			}
		}
	}
	&.is-pointed {
		z-index: 100;
	}
	&.is-disabled {
		pointer-events: none;
		color: var(--neutral-grey-dark);
		
		&.is-selected {
			background-color: var(--primary-blue-extralight);
		}
		
		.price-info {
			color: var(--neutral-grey-dark);
		}
	}
}
.date-number {
	display: block;
}
.price-info {
	color: var(--neutral-grey-extradark);
	white-space: nowrap;
	font-size: 12px;
	display: block;
}

@supports selector(.parent:has(.child)) {
	.single-wrapper:has(.day-single.is-between-selection.is-selected) {
		background-color: var(--primary-blue-extralight);

		&:has(.is-selected-from) {
			border-top-left-radius: 12px;
			border-bottom-left-radius: 12px;

			html[dir="rtl"] & {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
			}
		}
		&:has(.is-selected-to) {
			border-top-right-radius: 12px;
			border-bottom-right-radius: 12px;

			html[dir="rtl"] & {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 12px;
				border-bottom-left-radius: 12px;
			}
		}
	}
}


// ======================== FOOTER ========================
.footer {
	border-bottom: 2px solid var(--neutral-grey-base);
	background-color: white;
	order: -1;
	
	@media #{var.$query-max-md} {
		position: sticky;
		bottom: 0;
		z-index: 100;
		order: 0;
		border-bottom: 0;
		border-top: 2px solid var(--neutral-grey-base);
	}
}
.footer-inner {
	display: flex;
	align-items: center;
	@apply px-6 py-4;
	
	@media #{var.$query-max-md} {
		width: 100%;
		max-width: var(--max-width);
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		@apply px-4;
	}
}




// ======================== TOAST ========================
.toast-container {
	background-color: var(--primary-black-base);
	color: white;
	display: inline-flex;
	width: max-content;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 56px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 100;
	@apply rounded-5xl py-1 px-5;
}


.slide-in-from-bottom-enter-from,
.slide-in-from-bottom-leave-to {
	transform: translateY(50%);
	opacity: 0;
}

.slide-in-from-bottom-enter-active,
.slide-in-from-bottom-leave-active {
	transition: transform 0.2s, opacity 0.2s;
}

.slide-in-from-bottom-enter-to,
.slide-in-from-bottom-leave-from {
	transform: translateY(0%);
	opacity: 1;
}

.MHCalendarDouble[data-use-theme="MHH"] {
	.btn-arrow {
		color: var(--primary-mhh-teal-base);
	}

	.text-primary-blue-base {
		color: var(--primary-mhh-teal-base);
	}

	.day-single {
		color: var(--text-color);
		&.is-between-selection {
			background-color: var(--secondary-mhh-teal-ultralight);
		}

		&.is-selected {
			color: white;
			background-color: var(--primary-mhh-teal-base);
		}

		&.is-today::after {
			background-color: var(--primary-mhh-teal-base);
		}

		&.is-disabled {
			color: var(--neutral-grey-dark);
		}
		
	}

	.footer-inner .text-blue-primary-base {
		color: var(--primary-mhh-teal-base);
	}

	
}

.MHCalendarDouble[data-use-theme="firefly"] {

	.btn-arrow {
		color: var(--secondary-firefly-orange-base);

		&:focus {
			outline: 2px solid var(--semantic-blue-base);
		}
	}

	.text-primary-blue-base {
		color: var(--primary-firefly-orange-base);
	}

	.day-single {
		
		&.is-disabled {
			color: var(--neutral-firefly-grey-medium);
		}
		
		&.is-between-selection {
			background-color: #FCF5E5;
		}

		&.is-selected {
			color: white;
			background-color: var(--secondary-firefly-orange-extradark);
		}

		&.is-today::after {
			background-color: var(--primary-firefly-orange-base);
		}
	}

	.footer-inner .text-blue-primary-base {
		color: var(--primary-firefly-orange-base);
	}
}
</style>
          