import { getCookie, setCookie } from '~/logic/helpers/cookies.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';


function _getOneYearFromNow () {
	let d = new Date();
	let time = d.getTime();
	time += 365 * 24 * 3600 * 1000;
	d.setTime(time);
	return d;
}

const setCountryAndLocaleCookie = ({ country, language } = {}) => {
	const { pageProperties } = useGlobalAEMState();
	const finalCountry = country ?? unref(pageProperties).rootCountry;
	const finalLanguage = language ?? unref(pageProperties).rootLanguage;
	
	const oneYearLater = _getOneYearFromNow();
	
	setCookie('session-selected-country', finalCountry, oneYearLater.toUTCString());
	setCookie('session-selected-language', finalLanguage, oneYearLater.toUTCString());

	// for GlobalLink Web usage
	if (window.siteName === 'firefly') {
		setCookie('langPref', finalLanguage, oneYearLater.toUTCString());
	}
};


export {
	setCountryAndLocaleCookie,
};

