<script>
export default {
	name: 'VideoBanner',
	inheritAttrs: false,
};
</script>

<script setup>
import CTAButton from '~/aem-components/CTAButton.vue';
import { isMobileViewport } from '~/logic/composables/breakpoints';

const props = defineProps({
	desktopVideoSrc: { type: String, default: '' },
	mobileVideoSrc: { type: String, default: '' },
	desktopImgSrc: { type: String, default: '' },
	mobileImgSrc: { type: String, default: '' },
	desktopAltText: { type: String, default: '' },
	mobileAltText: { type: String, default: '' },
	desktopBannerHeight: { type: String, default: 'medium' }, // <--- 'small' | 'medium' | 'large'
	mobileBannerHeight: { type: String, default: 'medium' }, // <--- 'small' | 'medium' | 'large' | 'extraLarge'
	placeholderHorizontalAlignment: { type: String, default: 'center' }, // <--- 'left' | 'center' | 'right'
	placeholderVerticalAlignment: { type: String, default: 'middle' }, // <--- 'top' | 'middle' | 'bottom'
	useOverlay: { type: String, default: '' }, // <--- 'full' | 'top-bottom-only'
	overlayOpacityLevel: { type: String, default: '70' }, // <--- '30' | '50' | '70'
	useTagTheme: { type: String, default: 'white-text-blue-background' }, // <--- 'white-text-blue-background' | 'blue-text-white-background'
	bottomCurve: { type: Boolean, default: true },
	tagList: { type: Array, default: () => [] },
	disableAudio: { type: Boolean, default: false },
});

const isVideoMuted = ref(true);
const videoControls = ref(null);

const bannerSizeDesktopMap = {
	small: '408',
	medium: '550',
	large: '768',
};

const bannerSizeMobileMap = {
	small: '274',
	medium: '338',
	large: '688',
	extraLarge: '736',
};

const placeholderHorizontalAlignmentMap = {
	left: '>md:justify-start',
	center: '>md:justify-center',
	right: '>md:justify-end',
};

const placeholderVerticalAlignmentMap = {
	top: '>md:items-start',
	middle: '>md:items-center',
	bottom: '>md:items-end',
};

const computedImgSrcBg = computed(() => {
	if (isMobileViewport.value && props.mobileImgSrc) return props.mobileImgSrc;
	return props.desktopImgSrc;
});

const computedTagList = computed(() => {
	return props.tagList.filter( (obj) => obj.vTagName !== null );
});

const computedVideoSrc = computed(() => {
	return isMobileViewport.value && props.mobileVideoSrc ? props.mobileVideoSrc : props.desktopVideoSrc;
});

const computedVideoMimeType = computed(() => {
	const extension = computedVideoSrc.value.split('.').at(-1);
	switch (extension) {
		case 'mp4':
			return 'video/mp4';
		case 'webm':
			return 'video/webm';
		case 'm4v':
			return 'video/x-m4v';
		case 'qt':
		case 'mov':
			return 'video/quicktime';
		default:
			return '';
	}
});

const computedAltText = computed(() => {
	return isMobileViewport.value && props.mobileAltText ? props.mobileAltText : props.desktopAltText;
});

const handleAutoplayEvent = () => {
	const player = videoControls.value;
	isVideoMuted.value = !isVideoMuted.value;
	if (player) {
		player.muted = isVideoMuted.value;
	}
};

</script>


<template>
<div
	class="VideoBanner w-full"
	:class="{
		'use-overlay': useOverlay,
		'overlay-opacity-50': overlayOpacityLevel === '50',
		'overlay-opacity-30': overlayOpacityLevel === '30',
		'overlay-type-full': useOverlay === 'full',
		'overlay-type-top-bottom-only': useOverlay === 'top-bottom-only',
		'use-theme-light': useTagTheme === 'white-text-blue-background',
		'use-theme-dark': useTagTheme === 'blue-text-white-background',
		'use-bottom-curve': bottomCurve,
	}"
	:title="isMobileViewport ? mobileAltText : desktopAltText"
>
	<div
		class="VideoBanner-inner bg-center bg-cover"
		:style="{
			'background-image': `url(${computedImgSrcBg})`,
			'--BannerSizeDesktop': `${bannerSizeDesktopMap[desktopBannerHeight]}px`,
			'--BannerSizeMobile': `${bannerSizeMobileMap[mobileBannerHeight]}px`,
		}"
	>
		<div class="generic-container">
			<div
				class="mx-auto w-10/12 h-full flex justify-center items-center"
				:class="`${placeholderHorizontalAlignmentMap[placeholderHorizontalAlignment]} ${placeholderVerticalAlignmentMap[placeholderVerticalAlignment]}`"
			>
				<span v-if="desktopAltText || mobileAltText" class="sr-only">
					{{ $t('Image of banner') }}: <span v-if="desktopAltText" class="md:hidden">{{ desktopAltText }}</span> <span v-if="mobileAltText" class=">md:hidden">{{ mobileAltText }}</span>
				</span>
				
				<div
					class="banner-content-placeholder text-center"
					:style="{ 'text-align': !isMobileViewport && placeholderHorizontalAlignment }"
				>
					<div v-if="computedTagList?.length" class="tag-group">
						<span v-for="(tag, index) in computedTagList" :key="`tagList-${index}`" class="tag">{{ tag.vTagName }}</span>
					</div>
					
					<!-- <h1 class="mb-4">{{ title }}</h1>
					<p class="mb-6">{{ description }}</p>
					<CTAButton
						:styleType="useTagTheme === 'dark' ? 'secondary' : 'primary'"
						ctaText="Button text"
						ctaSizing="auto"
						:alignmentDesktop="placeholderHorizontalAlignment"
					/> -->
					<slot></slot>
				</div>
			</div>
		</div>

		<video
			ref="videoControls"
			class="bg-video"
			loop
			muted
			autoplay
			playsinline
		>
			<source :src="computedVideoSrc" :type="computedVideoMimeType" />
			<img
				:src="computedImgSrcBg"
				:alt="computedAltText"
			/>
		</video>
	</div>
	<div v-if="bottomCurve" class="curvy-section-breaker flex items-center z-20">
		<div class="generic-container"></div>
	</div>
</div>
<div v-if="!disableAudio">
	<button type="button" class="button audio-icon-wrapper" :aria-label="$t('Audio toggle button')" @click="handleAutoplayEvent">
		<icon-fas-volume
			v-if="!isVideoMuted"
			class="fill-white"
			aria-hidden="true"
			title="unmute"
		/>
		<icon-fas-volume-slash
			v-else
			class="fill-white"
			aria-hidden="true"
			title="mute"
		/>
	</button>
</div>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.VideoBanner {
	--breakerHeight: 40px;
	position: relative;
	background-color: var(--batik-bg-colour);
	overflow: hidden;
}

.VideoBanner-inner {
	display: flex;
	height: calc(var(--BannerSizeDesktop) + var(--breakerHeight) + var(--headerHeight));
	@apply px-4;
	
	@media #{var.$query-max-md} {
		height: calc(var(--BannerSizeMobile) + var(--breakerHeight) + var(--headerHeight));
	}
}

.use-theme-light {
	@apply text-base;
	
	.tag {
		background-color: var(--primary-blue-base);
		color: white;
	}
}

.use-theme-dark {
	color: white;
	
	.tag {
		background-color: white;
		color: var(--primary-blue-base);
	}
}

.banner-content-placeholder {
	position: relative;
	max-width: 588px;
	padding-top: calc(64px + var(--headerHeight));
	padding-bottom: calc(64px + var(--breakerHeight));
	z-index: 20;
	
	@media #{var.$query-max-md} {
		padding-top: calc(40px + var(--headerHeight));
		padding-bottom: calc(40px + var(--breakerHeight));
	}
}

.use-overlay {
	&.overlay-type-full::before {
		content: '';
		background-image: linear-gradient(to bottom, color.change(#333, $alpha: 0.7), color.change(#333, $alpha: 0));
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 10;
		opacity: 0.7;
	}

	&.overlay-type-top-bottom-only {
		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			z-index: 10;
			opacity: 0.7;
			height: 15%;
		}

		&::before {
			background-image: linear-gradient(to bottom, color.change(#333, $alpha: 0.7), color.change(#333, $alpha: 0));
			top: 0;
		}

		&::after {
			background-image: linear-gradient(to bottom, color.change(#333, $alpha: 0), color.change(#333, $alpha: 0.7));
			bottom: 0;
		}
	}

	&.overlay-opacity-30 {
		&::before,
		&::after {
			opacity: 0.3;
		}
	}

	&.overlay-opacity-50 {
		&::before,
		&::after {
			opacity: 0.5;
		}
	}
}

.use-bottom-curve.overlay-type-top-bottom-only {
	&::after {
		height: calc(15% + var(--headerHeight));
	}
}

.tag-group {
	@apply mb-5;
	
	@media #{var.$query-max-md} {
		@apply mb-4;
	}
}

.tag {
	text-transform: uppercase;
	display: inline-block;
	font-weight: 700;
	background-color: var(--primary-blue-base);
	@apply rounded px-3 py-1 m-1 text-xs;
}

.use-batik-pattern {
	--batik-pattern-img: url('~/assets/batik-pattern.svg');
	background-color: var(--batik-bg-colour);
	background-image: var(--batik-pattern-img);
	background-repeat: repeat;
	position: relative;
	
	&.use-brighter-batik {
		--batik-pattern-img: url('~/assets/batik-pattern-brighter.svg');
	}
}

.bg-video {
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scale(1.2);
}

[data-use-theme="MAG"] {
	.use-theme-light .tag {
		background-color: var(--primary-midnightgreen-base);
	}

	.use-theme-dark .tag {
		color: var(--primary-midnightgreen-base);
	}

}

[data-use-theme="MABA"] {
	.VideoBanner {
		--breakerHeight: 0px;
	}
}
.audio-icon-wrapper {
	position: absolute;
	width: 40px;
	height: 35px;
	z-index: 999;
	bottom: 100px;
	left: 60px;

	@media #{var.$query-max-md} {
		bottom: 30px;
		left: 60px;
	}
}
</style>
