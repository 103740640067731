<script>
export default {
	name: 'SubTabBookFlight',
};
</script>

<script setup>
import ToggleSwitch from '~/components/form/ToggleSwitch.vue';
import Tooltip from '~/components/Tooltip.vue';
import CTAButton from '~/aem-components/CTAButton.vue';
import ONDPicker from '~/components/BookingWidget/ONDPicker.vue';
import DateRangePicker from '~/components/BookingWidget/DateRangePicker.vue';
import TextField from '~/components/form/TextField.vue';
import PassengerAndCabinClass from '~/components/BookingWidget/PassengerAndCabinClass.vue';
import FormWrapper from '~/components/form/FormWrapper.vue';
import yup from 'mh-yup';
import dayjs from 'dayjs';
import { isMobileViewport, isXXXL } from '~/logic/composables/breakpoints.js';
import TransitionHeight from '~/components/transition/TransitionHeight.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { useRouteHash } from '~/logic/composables/useRouteHash.js';
import { useEventBus } from '@vueuse/core';
import { useBSTOverlayInterimData } from '~composables/booking-widget/useBSTOverlayInterimData.js';
import { i18nGlobal } from '~/logic/i18n.js';
import { useIsUkVariant, useSyncPassengerDetails } from '~/logic/composables/booking-widget/usePassengers.js';
import { useAuthStore } from '~/logic/auth/auth-store.js';
import { getCookie } from '~/logic/helpers/cookies.js';
import { useLowFarePriceList } from '~composables/booking-widget/useLowFarePriceList.js';
import { isUrlMatchCurrentHostname, formatNumberWithAbbreviation } from '~/logic/helpers/string.js';
import { waitFor } from '~/logic/helpers/utils.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { pushAA_searchError } from '~/logic/adobe-analytic.js';
import { useIsRTL } from '~/logic/helpers/is-rtl.js';
import AppSpinner from '~/components/AppSpinner.vue';

const props = defineProps({
	payingByEnrichLabel: { type: String, default: '' },
	hideTodayDateIndicator: { type: Boolean, default: false },
	subHeaderTabDescription: { type: String, default: '' },
	subHeaderTabTooltipBody: { type: String, default: '' },
	subHeaderTabTooltipLabel: { type: String, default: '' },
	note: { type: String, default: '' },
	searchFlightCTAText: { type: String, default: i18nGlobal.t('Search flight') },
	disableGeolocation: { type: Boolean, default: false },

	ondSelection: { type: Object, default: null },
	additionalONDFilter: { type: String, default: null },
	datePicker: { type: Object, default: null },
	passengerSelection: { type: Object, default: null },
	promoCodeLabel: { type: String, default: null },
	promoCodeErrorMessage: { type: String, default: i18nGlobal.t('Conditions not met for promo code to be applied') },
	supersedePromoCode: { type: String, default: null },

	bstNoteTop: { type: String, default: '' },
	bstNoteCTAText: { type: String, default: '' },
	bstNoteCTAURL: { type: String, default: '' },

	subHeaderTooltipCTATextMobileLabel: { type: String, default: '' },
	fromQuestionMobile: { type: String, default: '' },
	toQuestionMobile: { type: String, default: '' },
	datePickerQuestionMobile: { type: String, default: '' },
	disableCalendarPickerAnimation: { type: Boolean, default: false },
	passengerSelectionQuestion: { type: String, default: '' },
	flightSearchApiUrl: { type: String, default: '' },
	cabinClassCashList: { type: Array, default: () => [] },
	cabinClassMilesList: { type: Array, default: () => [] },

	componentIdAA: { type: String, default: '' },
	togglePointsApiURL: { type: String, default: '' },

	enableMattaToggleButton: { type: Boolean, default: false },
	mattaToggleButtonLabel: { type: String, default: '' },

	hideEnrichToggle: { type: Boolean, default: false },
	hidePromoCodeField: { type: Boolean, default: false },
	
	enrichAlwaysOn: { type: Boolean, default: false },
	enablePostMattaCampaign: { type: Boolean, default: false },

	enrichAlwaysOnCountriesData: { type: Array, default: () => [] },

	loggedInMessageNote: { type: String, default: '' },
	nonLoggedInMessageNote: { type: String, default: '' },
	enableONDLocaleTranslation: { type: Boolean, default: false },
});

const emit = defineEmits([
	'save-search-data',
	'show-bst-overlay',
	'form-submission',
]);

const { isRTL } = useIsRTL();

const formWrapper = ref(null);
const rootEl = ref(null);
const passengerAndCabinClassEl = ref(null);
const dateRangePickerEl = ref(null);
const ondPickerEl = ref(null);
const isLoading = ref(null);

const dateRangeValueFrom = ref(null);
const dateRangeValueTo = ref(null);

const ondValueFrom = ref(null);
const ondValueTo = ref(null);

const promoCodeValue = ref(null);
const payByEnrichPoints = ref(false);
const eligibleForBonusSideTrip = ref(false);
const optInBonusSideTrip = ref(false);
const token = ref('');

const isCampaignToggleON = ref(false);

const { pageProperties, isEditorMode } = useGlobalAEMState();

const isExpanded = ref(true);

const enrichAlwaysOnEligibility = ref(false);
const optEnrichAlwaysOnOffer = ref(props.enablePostMattaCampaign);

const isJDTPortal = ref(false);
const isJDTNormalBooking = ref(false);

const country = pageProperties.value.rootCountry;
const language = pageProperties.value.rootLanguage;

const { isSapphireCookiePresent } = useAuthStore();

const capitalizeString = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const showAlwaysOnEnrichNonLoginMsg = computed(() => {
	return props.enrichAlwaysOn && !isSapphireCookiePresent() && props.nonLoggedInMessageNote;
});

const isEligibleForErichAlwaysOn = computed(() => {
	if (
		enrichAlwaysOnEligibility.value &&
		optEnrichAlwaysOnOffer.value
	) {
		return 'true';
	}

	return 'false';
});

const computedCabinClassValue = computed(() => {
	if (!passengerAndCabinClassEl.value) return;
	/*
		The cabin class value from the passenger and cabin class dropdown is 1, 2, 3.
		Economy: 1,
		Business: 2,
		Business Suite: 3.
		While form submission, due to different form has different cabin class value, even though their label(Economy, Business, Business Suite) are the same. 
		require to map to the actual cabin class value, which come from the component config.
	*/
	const index = parseInt(passengerAndCabinClassEl.value.cabinClassValue?.value) - 1;
	
	if (payByEnrichPoints.value) {
		return props.cabinClassMilesList[index].value;
	}
	return props.cabinClassCashList[index].value;
});

const lowFareApiUrl = pageProperties?.value?.lowFareApiUrl;
const isOneWay = ref(false);

// const dateRangePriceData = ref(useLowFarePriceList(ondValueFrom, ondValueTo, dateRangeValueFrom, payByEnrichPoints, lowFareApiUrl));
const {
	priceData: dateRangePriceData,
	currency,
	returnTypeDepartureDateAndFare: departureDateAndFare,
} = useLowFarePriceList({
	originRef: ondValueFrom,
	destinationRef: ondValueTo,
	departureDateRef: dateRangeValueFrom,
	paymentTypeRef: payByEnrichPoints,
	endpointUrl: lowFareApiUrl,
	isOneWayRef: isOneWay,
});

const handleWatchIsOneWay = (flag) => {
	isOneWay.value = flag;
};

const {
	isLoggedIn,
	isAuthStateReady,
} = useAuthStore();

const { hash, hashQuery } = useRouteHash();
const SAPPHIRE_COOKIE = 'sapphire';
token.value = getCookie(SAPPHIRE_COOKIE) ?? null;

watch( payByEnrichPoints, (newValue) => {
	// to avoid redirect to login page again. 
	if (!isLoggedIn.value && newValue) {
		handleRedirectToLogin();
	}

	// every time toggle switch need to reset the promo code once, if it has been filled.
	if (promoCodeValue.value) promoCodeValue.value = null;
});


const handleRedirectToLogin = async () => {
	if (!props.togglePointsApiURL) {
		throw new Error('Failed to get endpoint: togglePointsApiURL');
	}
	
	try {
		const apiResponse = await axios.get(props.togglePointsApiURL, {
			params: {
				type: 'points',
				maintab: 'flight-search-tab',
				subtab: 'book-flight',
				locationFrom: ondValueFrom.value?.code,
				locationTo: ondValueTo.value?.code,
				dateDeparture: dateRangeValueFrom.value,
				dateReturn: dateRangeValueTo.value,
				
				cabinClass: passengerAndCabinClassEl.value.cabinClassValue?.value,
				adultsCount: passengerAndCabinClassEl.value.adults.count.value,
				teensCount: passengerAndCabinClassEl.value.teens.count.value,
				childrenCount: passengerAndCabinClassEl.value.children.count.value,
				infantsCount: passengerAndCabinClassEl.value.infants.count.value,
				promoCode: props.supersedePromoCode ?? promoCodeValue.value,

				isPoints: true,
			},
		});
		const url = apiResponse.data;

		payByEnrichPoints.value = false;

		window.location.href = url;
	} catch (e) {
		console.error('handleRedirectToLogin, ', 'Unable to fetch api', e);
	}
};

const promoCodeTextField = ref(null);

const handleSubmitValid = async (values, actions) => {
	console.log('✅ handleSubmitValid values = ', values);

	isLoading.value = true;

	const searchData = {
		isBSTTab: false,
		
		payByEnrichPoints: payByEnrichPoints.value,
		optInBonusSideTrip: optInBonusSideTrip.value,
		dateRangeValueFrom: dateRangeValueFrom.value,
		dateRangeValueTo: dateRangeValueTo.value,
		isOneWay: !dateRangeValueTo.value,
		ondValueFrom: ondValueFrom.value,
		ondValueTo: ondValueTo.value,
		promoCodeValue: props.supersedePromoCode ?? promoCodeValue.value,
		cabinClassValue: passengerAndCabinClassEl.value.cabinClassValue,
		adultsCount: passengerAndCabinClassEl.value.adults.count.value,
		teensCount: passengerAndCabinClassEl.value.teens.count.value,
		childrenCount: passengerAndCabinClassEl.value.children.count.value,
		infantsCount: passengerAndCabinClassEl.value.infants.count.value,
		userInputPromoCode: promoCodeValue.value,
		...(props.enableMattaToggleButton ? { isCUGCampaignPage: props.enableMattaToggleButton, isCampaignToggleON: isCampaignToggleON.value } : null),
	};
	
	emit('save-search-data', searchData);
	emit('form-submission', searchData);
	
	if (optInBonusSideTrip.value) {
		emit('show-bst-overlay');

		return commitDataToBSTOverlay('book-flight', {
			ondValueFrom: searchData.ondValueFrom,
			ondValueTo: searchData.ondValueTo,
			dateRangeValueFrom: searchData.dateRangeValueFrom,
			dateRangeValueTo: searchData.dateRangeValueTo,
			isOneWay: searchData.isOneWay,
			
			adultsCount: searchData.adultsCount,
			teensCount: searchData.teensCount,
			childrenCount: searchData.childrenCount,
			infantsCount: searchData.infantsCount,
			
			cabinClassValue: searchData.cabinClassValue,
			// payByEnrichPoints: searchData.payByEnrichPoints,
			payByEnrichPoints: false, /* BST not allow for paying with point */
			promoCodeValue: props.supersedePromoCode ?? promoCodeValue.value,
		});

	}

	try {
		
		// 1st api to get response with url and payload for 2nd api
		const apiResponse = await axios({
			method: 'post',
			url: props.flightSearchApiUrl,
			data: {
				'departDate1': dayjs(searchData.dateRangeValueFrom).format('YYYYMMDDHHmm'),
				'returnDate1': !searchData.isOneWay && searchData.dateRangeValueTo ? dayjs(searchData.dateRangeValueTo).format('YYYYMMDDHHmm') : '',
				'originCountry': searchData.ondValueFrom.countryName,
				'originAirportCode1': searchData.ondValueFrom.code,
				'destAirportCode1': searchData.ondValueTo.code,
				'flightClass': computedCabinClassValue.value,
				'adultCount': searchData.adultsCount.toString(),
				...(isUKVariant.value ? { 'teenCount': searchData.teensCount.toString() } : null),
				'childCount': searchData.childrenCount.toString(),
				'infantCount': searchData.infantsCount.toString(),
				'paymentType': searchData.payByEnrichPoints ? 'miles' : 'cash',
				'amal1': searchData.ondValueTo._original.isAmalFlag,
				'promoCode': searchData.promoCodeValue ?? '',
				'regionLanguage': country && language ? `${country}-${capitalizeString(language)}` : '',
				'amcvId': window._satellite?.getVar('ECID') ?? '',
				'teaserCategory': getCookie('TeaserCategory') ?? '',
				'isJdtPortal': isJDTPortal.value,
				'isJdtNormalBooking': isJDTNormalBooking.value,
				...(props.enableMattaToggleButton ? { 'isCUGCampaignPage': searchData.isCUGCampaignPage, 'isCampaignToggleON': searchData.isCampaignToggleON } : null),
				...(props.enrichAlwaysOn ? { 'enrichAlwaysOnEligibility': isEligibleForErichAlwaysOn.value } : null ),
			},
		});
		
		const { url, payload } = apiResponse.data;
		
		// 'RAW_DATA' doesn't required, delete it. 
		delete payload['RAW_DATA'];

		// 2nd api need to post with form submission
		// add extra property to payload object
		const generatedData = {};
		const entriesPayload = Object.entries(payload);
		for (let [index, [key, value]] of entriesPayload.entries()) {
			generatedData[index] = key;
		}
		const massageData = { ...generatedData, ...payload };
		const entriesMassageData = Object.entries(massageData);

		// create hidden form and submit
		const method = 'post';
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', url);
		
		for (let [index, [key, value]] of entriesMassageData.entries()) {
			// console.log(`apiResponse => ${index}: ${key}: ${value}`);
			const _key = parseInt(key);
			let hiddenField = document.createElement('input');
			hiddenField.setAttribute('type', 'hidden');
			hiddenField.setAttribute('name', key);
			hiddenField.setAttribute('value', value);
			form.appendChild(hiddenField);
		}
		document.body.appendChild(form);
		isLoading.value = false;
		form.submit();

	} catch (err) {
		console.error('handleSubmitValid, ', 'Unable to fetch api', JSON.stringify(err));

		isLoading.value = false;

		// from BE if status code === 400, it means promo code issue
		const { statusCode, message } = err.response.data;
		if (statusCode === 400) {
			// promo code error
			promoCodeTextField.value.setErrors(i18nGlobal.t(message));
			triggerAA_searchError({
				errorMsg: i18nGlobal.t(message),
			});
		}
	}
};
const handleSubmitInvalid = ({ values, errors, results, evt }) => {
	console.log('❌ handleSubmitInvalid errors = ', errors);
};





const handleFormFocusWithin = () => {
	if (isSticked.value) return; // do not mess with this when in sticky mode
	isExpanded.value = true;
};
const handleFormBlurWithin = () => {
	//
};

const isBtnSubmitDisabled = computed(() => {
	const form = formWrapper.value;
	if (!form) return true;

	const isOneWay = dateRangePickerEl.value?.isOneWay;
	return (
		!form.values.locationFrom ||
		!form.values.locationTo ||
		!form.values.dateDeparture ||
		(!isOneWay && !form.values.dateReturn) ||
		Object.keys(form.errors).length !== 0
	);
});


const bookingWidgetBus = useEventBus('booking-widget');
const isSticked = ref(false);

bookingWidgetBus.on((event, payload) => {
	switch (event) {
		case 'sticky:sticked': {
			isSticked.value = true;
			break;
		}
		case 'sticky:unsticked': {
			isSticked.value = false;
			break;
		}
		default: {
			// console.log(`Unknown event: "${event}". Ignoring it.`);
			break;
		}
	}
});

const handleExpandTransitionEnd = () => {
	// no need to call this anymore
	// bookingWidgetBus.emit('refresh-scroll-trigger');
};
/* const handleBonusSideTripBannerChanged = () => {
	bookingWidgetBus.emit('refresh-scroll-trigger');
}; */


const areDatesValid = (from, to = null) => {
	const today = dayjs();
	const dayJsMaxAllowedDaysAhead = today.add(datePickerMaxAllowedFutureDays.value, 'day');
	const dayJsFrom = dayjs(from);
	const dayJsTo = dayjs(to);
	
	if (dayJsFrom.isValid() === false || (to && dayJsTo.isValid() === false)) return false;
	
	if (dayJsFrom.isBefore(today, 'day')) {
		return false;
	}
	if (to && dayJsTo.isAfter(dayJsMaxAllowedDaysAhead, 'day')) {
		return false;
	}
	return true;
};

const validateAndRestoreDates = ({ from, to = null, isOneWay = false } = {}) => {
	if (areDatesValid(from, (isOneWay ? null : to))) {
		// valid date
		dateRangeValueFrom.value = dayjs(from).format('YYYY-MM-DD');
		dateRangeValueTo.value = (to && dayjs(to).format('YYYY-MM-DD')) ?? null;
	} else {
		// invalid date
		dateRangeValueFrom.value = null;
		dateRangeValueTo.value = null;
		
		// have to reset the validation here, so that the 'setTouched()' and 'setErrors()' inside the nextTick() will work
		dateRangePickerEl.value?.inputElFrom?.resetField();
		
		nextTick().then(() => {
			try {
				dateRangePickerEl.value?.inputElFrom?.setTouched(true);
				dateRangePickerEl.value?.inputElFrom?.setErrors(i18nGlobal.t('The selected dates are no longer valid. Please reselect.'));
			} catch {
				// do nothing
			}
		});
	}
};

const restorePastSearch = (searchData) => {
	ondValueFrom.value = searchData.ondValueFrom;
	ondValueTo.value = searchData.ondValueTo;
	
	validateAndRestoreDates({
		from: searchData.dateRangeValueFrom,
		to: searchData.dateRangeValueTo || null,
		isOneWay: !!searchData.isOneWay,
	});
	
	promoCodeValue.value = searchData.userInputPromoCode;
	optInBonusSideTrip.value = searchData.optInBonusSideTrip;
	payByEnrichPoints.value = searchData.payByEnrichPoints;
	
	passengerAndCabinClassEl.value?.setValues({
		adults: searchData.adultsCount,
		teens: searchData.teensCount,
		children: searchData.childrenCount,
		infants: searchData.infantsCount,
		cabinClass: searchData.cabinClassValue,
	});
	
	// set one-way
	dateRangePickerEl.value.handleSetIsOneWay(!!searchData.isOneWay);
};

const { commitDataToBSTOverlay, registerNotifier } = useBSTOverlayInterimData();

registerNotifier('book-flight', async (newData) => {
	ondValueFrom.value = newData.ondValueFrom;
	ondValueTo.value = newData.ondValueTo;
	
	dateRangeValueFrom.value = newData.dateRangeValueFrom;
	dateRangeValueTo.value = newData.dateRangeValueTo;
	
	// const wait = waitFor(() => !!passengerAndCabinClassEl.value, 400, 30); // <-- 400ms * 30 = 12 seconds
	// await wait.start();
	
	passengerAndCabinClassEl.value?.setValues({
		adults: newData.adultsCount,
		teens: newData.teensCount,
		children: newData.childrenCount,
		infants: newData.infantsCount,
		cabinClass: newData.cabinClassValue,
	});
	
	dateRangePickerEl.value.handleSetIsOneWay(!!newData.isOneWay);
});


const defaultMinDate = 0;
const defaultMaxDate = 361;
const datePickerMinAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.departureDateConfig || typeof datePickerData?.departureDateConfig !== 'number') return defaultMinDate;
	return datePickerData?.departureDateConfig;
});

const datePickerMaxAllowedFutureDays = computed(() => {
	const datePickerData = props.datePicker;
	if (!datePickerData?.returnDateConfig || typeof datePickerData?.returnDateConfig !== 'number') return defaultMaxDate;
	return datePickerData?.returnDateConfig;
});

const formattedDisabledDateList = computed(() => {
	let disabledDates = null;
	const disableSpecificDateList = props.datePicker?.disableSpecificDateList;

	if (disableSpecificDateList.length) {
		disabledDates = [];

		disableSpecificDateList.forEach((date) => {
			if (!date.disableDate) return;
			disabledDates.push(date.disableDate);
		});
	}
	return disabledDates;
});

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'flight-search-tab',
	subTabName: 'book-flight',
});

registerQueryChange(async (query) => {
	if (!query) return;
	
	// ondValueFrom.value = query.locationFrom ? await getONDValueByAirportCode(query.locationFrom, { direction: 'from' }) : null;
	// ondValueTo.value = query.locationTo ? await getONDValueByAirportCode(query.locationTo, { direction: 'to' }) : null;
	
	ondValueFrom.value = query.locationFrom;
	ondValueTo.value = query.locationTo;
	
	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
	
	validateAndRestoreDates({
		from: query.dateDeparture,
		to: query.dateReturn || null,
		isOneWay: (query.isOneWay === 'true'),
	});
	
	promoCodeValue.value = query.promoCode ?? null;
	
	await nextTick();

	passengerAndCabinClassEl.value?.setValues({
		...(query.adultsCount ? { adults: parseInt(query.adultsCount) } : null),
		...(query.teensCount ? { teens: parseInt(query.teensCount) } : null),
		...(query.childrenCount ? { children: parseInt(query.childrenCount) } : null),
		...(query.infantsCount ? { infants: parseInt(query.infantsCount) } : null),
		...(query.cabinClass ? { cabinClass: parseInt(query.cabinClass) } : null),
	});

	optInBonusSideTrip.value = query.bst ?? false;

	if (query.isPoints === 'true') {
		setEnrichPointStatus(true);
	}

	if (query.isOneWay === 'true') {
		dateRangePickerEl.value.handleSetIsOneWay(!!query.isOneWay);
	}
});

const updateLocationFromTo = async (payload) => {
	const { locationFrom, locationTo } = payload;
	// if (locationFrom) ondValueFrom.value = await getONDValueByAirportCode(locationFrom, { direction: 'from' });
	// if (locationTo) ondValueTo.value = await getONDValueByAirportCode(locationTo, { direction: 'to' });

	if (locationFrom) ondValueFrom.value = locationFrom;
	if (locationTo) ondValueTo.value = locationTo;

	nextTick().then(async () => {
		await ondPickerEl.value?.updateFromValue();
		ondPickerEl.value?.updateToValue();
	});
};


const sumPrice = ref(0);

watch(dateRangeValueTo, (newValue) => {
	if (
		newValue &&
		ondValueFrom.value &&
		ondValueTo.value &&
		dateRangeValueTo.value &&
		dateRangePriceData.value &&
		dateRangePriceData.value[dateRangeValueTo.value] &&
		departureDateAndFare?.value?.totalFareAmount
	) {
		sumPrice.value = parseInt(departureDateAndFare.value.totalFareAmount) + parseInt(dateRangePriceData.value[dateRangeValueTo.value]);
		return;
	}

	sumPrice.value = 0;
});

const sumPriceWithCurrency = computed(() => {
	if (!currency.value && !sumPrice.value) return '';
	return `${currency.value} ${formatNumberWithAbbreviation(sumPrice.value)}`;
});

const isUKVariant = ref(false);


const getIsBstEligible = async () => {
	const cabinValAfterDelimiter = computedCabinClassValue.value?.split('|')?.at(-1);
	
	if (
		ondValueFrom.value?.value &&
		ondValueTo.value?.value &&
		dateRangeValueFrom.value &&
		dateRangeValueTo.value &&
		cabinValAfterDelimiter
	) {
		const cFrom = ondValueFrom.value?.value;
		const cTo = ondValueTo.value?.value;
		
		// check eligibility for BST
		return axios.get('/bin/mh/revamp/ondLists?type=bst_validate', { params: {
			ori: cFrom,
			des: cTo,
			cabin: cabinValAfterDelimiter,
			dateFrom: dateRangeValueFrom.value,
			dateTo: dateRangeValueTo.value,
		} }).then((resp) => {
			// check if current pairing is still valid. It is possible that user changes value before API is completed.
			if (ondValueFrom.value?.value === cFrom && ondValueTo.value?.value === cTo) {
				// pairing matches
				if (resp.status === 200 && resp.data && Object.keys(resp.data).length > 0) {
					return true;
				}
			}
		}).catch((reason) => {
			console.log('bst_validate api failed reason = ', reason);
			return false;
		});
	} else {
		return false;
	}
};

watch([ondValueFrom, ondValueTo, dateRangeValueFrom, dateRangeValueTo, computedCabinClassValue], async (newValue) => {
	// side effect for BST banner visibility
	// console.log('watch getIsBstEligible triggered');
	// console.log('ondValueFrom.value?.value = ', ondValueFrom.value?.value);
	// console.log('ondValueTo.value?.value = ', ondValueTo.value?.value);
	// console.log('dateRangeValueFrom.value = ', dateRangeValueFrom.value);
	// console.log('dateRangeValueTo.value = ', dateRangeValueTo.value);
	// console.log('------------');
	
	getIsBstEligible().then((isBstEligible) => {
		eligibleForBonusSideTrip.value = isBstEligible;
	});
});

const getIsEnrichAlwaysEligible = async () => {
	if (
		ondValueFrom.value &&
		ondValueTo.value
	) {
		const cFromCountry = ondValueFrom.value?.countryName;
		const cFromIsRedemption = ondValueFrom.value?.isRedemption;
		const cToIsRedemption = ondValueTo.value?.isRedemption;
		const iscFromInAvalailableList = props.enrichAlwaysOnCountriesData.includes( cFromCountry );
		if (
			cFromIsRedemption &&
			cToIsRedemption &&
			iscFromInAvalailableList
		) {
			return true;
		}
	}

	return false;
};

watch([ondValueFrom, ondValueTo, payByEnrichPoints], async (newValue) => {

	if (
		props.enrichAlwaysOn &&
		isSapphireCookiePresent() &&
		props.enrichAlwaysOnCountriesData.length > 0 &&
		!payByEnrichPoints.value
	) {
		enrichAlwaysOnEligibility.value = await getIsEnrichAlwaysEligible();
		return;
	}

	enrichAlwaysOnEligibility.value = false;
});

watch([ondValueFrom, ondValueTo], async (newValue) => {
	// reset teen passenger count, once user select non-uk airport
	isUKVariant.value = useIsUkVariant({ origin: ondValueFrom.value, destination: ondValueTo.value });
	if (isUKVariant.value) return;
	passengerAndCabinClassEl.value?.setValues({
		teens: 0,
	});
});

async function setEnrichPointStatus (flag) {
	if (!flag || !token.value) {
		payByEnrichPoints.value = false;
		return;
	}
	if (!isAuthStateReady.value) {
		const waitForAuthReady = waitFor(() => !!isAuthStateReady.value);
		await waitForAuthReady.start();
	}
	payByEnrichPoints.value = true;
}

const triggerAA_searchError = ({ errorMsg }) => {
	const errorInfo = {
		errorName: errorMsg,
	};
	const webInteractions = {
		name: props.searchFlightCTAText,
		type: 'exit',
	};
	pushAA_searchError([errorInfo, webInteractions]);
};

useSyncPassengerDetails(passengerAndCabinClassEl);

const globalBookFlightBus = useEventBus('booking-widget:enrich-points-toggle-switch');
globalBookFlightBus.on(async (event, status) => {
	switch (event) {
		case 'update-toggle-switch': {
			if (status === 'logout') {
				setEnrichPointStatus(false);
			}
			await nextTick();
			break;
		}
			
		default: {
			console.log(`Unknown event: ${event}. Ignore it.`);
			break;
		}
	}
});

defineExpose({
	restorePastSearch,
	updateLocationFromTo,
	isExpanded,
});

const focusToDatePicker = (newValue) => {
	if (newValue && !isMobileViewport.value) dateRangePickerEl.value?.inputElFrom?.focus();
};

const groupLabelsOverwrite = computed(() => {
	if (!props.ondSelection) return null;

	return {
		'NEAR_YOU': props.ondSelection?.nearYouLabel,
		'POPULAR_DEST': props.ondSelection?.popularDestinationsLabel,
		'WITH_MH': props.ondSelection?.withMalaysiaAirlinesLabel,
		'CODESHARE': props.ondSelection?.withCodeShareAndPartnerNetworkLabel,
	};
});

onMounted(() => {
	const pathName = window.location.pathname;
	isJDTPortal.value = pathName.endsWith('jdt-portal.html');
	isJDTNormalBooking.value = pathName.endsWith('normal-booking.html');
});

</script>

<template>
<div ref="rootEl" class="SubTabBookFlight">
	<FormWrapper
		ref="formWrapper"
		v-slot="{ errors }"
		v-focus-within
		class="-mt-16 lg:mt-0"
		tabindex="-1"
		@submit-valid="handleSubmitValid"
		@submit-invalid="handleSubmitInvalid"
		@focus-within="handleFormFocusWithin"
		@blur-within="handleFormBlurWithin"
	>
		<div>
			<div class="flex">
				<div
					:class="{
						'mr-auto lg:mr-0': isRTL,
						'ml-auto lg:ml-0': !isRTL,
						'pt-15.5': props.hideEnrichToggle && !isMobileViewport,
					}"
				>
					<ToggleSwitch
						v-if="!props.hideEnrichToggle"
						v-model="payByEnrichPoints"
						:rootAttrs="{
							class: 'mb-8',
						}"
						:ariaLabel="props.payingByEnrichLabel"
						name="usingEnrichPoints"
					>
					</ToggleSwitch>
					<ToggleSwitch
						v-if="props.enableMattaToggleButton"
						v-model="isCampaignToggleON"
						:rootAttrs="{
							class: 'mb-8',
						}"
						:ariaLabel="props.mattaToggleButtonLabel"
						name="usingCugPromoCode"
					>
					</ToggleSwitch>
				</div>
			</div>

			<div v-if="props.subHeaderTabDescription" v-html-sanitize="props.subHeaderTabDescription" class="mb-5"></div>

			<Tooltip v-if="props.subHeaderTabTooltipLabel" class="inline-flex mb-5">
				<template #default>
					<div class="flex items-center">
						<icon-fas-circle-question class="fill-primary-blue-base mr-4 rtl:(mr-0 ml-4)" aria-hidden="true" />
						{{ props.subHeaderTabTooltipLabel }}
					</div>
				</template>
				<template #mobile-title>
					{{ props.subHeaderTabTooltipLabel }}
				</template>
				<template #tooltip-content>
					<div v-html-sanitize="props.subHeaderTabTooltipBody"></div>
				</template>
				<template #mobile-cta-label>
					{{ props.subHeaderTooltipCTATextMobileLabel }}
				</template>
			</Tooltip>

			<div class="flex gap-6 xl:gap-3 lg:flex-col">
				<div class="w-6/10 lg:w-full">
					<ONDPicker
						ref="ondPickerEl"
						v-model:modelValueFrom="ondValueFrom"
						v-model:modelValueTo="ondValueTo"
						nameFrom="locationFrom"
						nameTo="locationTo"
						:ondListIdentifier="payByEnrichPoints ? 'FLIGHT_SEARCH_ENRICH' : 'FLIGHT_SEARCH_CASH'"
						:requiredFrom="true"
						:requiredTo="true"
						:specificOriginList="props.ondSelection?.showSpecificOrigin"
						:specificDestinationList="props.ondSelection?.showSpecificDestination"
						:hideSpecificOriginList="props.ondSelection?.hideSpecificOrigin"
						:hideSpecificDestinationList="props.ondSelection?.hideSpecificDestination"
						:requiredErrorMsgFrom="props.ondSelection?.fromSearchErrorMessage"
						:requiredErrorMsgTo="props.ondSelection?.toSearchErrorMessage"
						:labelTextFrom="props.ondSelection?.fromLabel"
						:labelTextTo="props.ondSelection?.toLabel"
						:noResultsText="props.ondSelection?.noResultMessage"
						:requestGeolocation="!props.disableGeolocation"
						:groupLabelsOverwrite="groupLabelsOverwrite"
						:enableONDLocaleTranslation="props.enableONDLocaleTranslation"
						:additionalONDFilter="props.additionalONDFilter"
						@update:modelValueTo="focusToDatePicker"
					>
						<template #from-picker-mobile-title>
							<span>{{ props.fromQuestionMobile }}</span>
						</template>
						<template #to-picker-mobile-title>
							<span>{{ props.toQuestionMobile }}</span>
						</template>
					</ONDPicker>
				</div>
				<div class="w-4/10 lg:w-full">
					<DateRangePicker
						ref="dateRangePickerEl"
						v-model:modelValueFrom="dateRangeValueFrom"
						v-model:modelValueTo="dateRangeValueTo"
						:priceData="dateRangePriceData"
						:textFieldAttrsFrom="{
							ariaLabel: props.datePicker?.departureDateLabel ?? $t('Depart'),
							placeholder: $t('Select a date'),
						}"
						:textFieldAttrsTo="{
							ariaLabel: props.datePicker?.returnDateLabel ?? $t('Return'),
							placeholder: $t('Select a date'),
						}"
						:minDate="`t+${datePickerMinAllowedFutureDays}d`"
						:maxDate="`t+${datePickerMaxAllowedFutureDays}d`"
						:disabledDates="formattedDisabledDateList"
						minDateFromAfterSelectingTo="t"
						:disablePastDates="true"
						nameFrom="dateDeparture"
						nameTo="dateReturn"
						:requiredFrom="true"
						:requiredTo="true"
						:requiredErrorMsgFrom="props.datePicker?.invalidDateErrorMessage"
						:requiredErrorMsgTo="props.datePicker?.invalidDateErrorMessage"
						:labelReset="props.datePicker?.resetButtonCTAText ?? $t('Reset')"
						:labelDone="props.datePicker?.doneCTAText ?? $t('Done')"
						:labelOneWay="props.datePicker?.oneWayCTAText ?? $t('One-way')"
						:isHideTodayIndicator="props.hideTodayDateIndicator"
						:disableCalendarPickerAnimation="props.disableCalendarPickerAnimation"
						@update:is-one-way="handleWatchIsOneWay"
					>
						<template #mobile-title>
							{{ props.datePickerQuestionMobile }}
						</template>
						
						<template v-if="currency" #before-cta-done>
							<span v-if="ondValueFrom && !sumPrice" v-html-sanitize="$t('Fare based on <strong>{currency}</strong>', { currency })"></span>

							<span v-if="ondValueFrom && ondValueTo && sumPrice" v-html-sanitize="$t('Trip from <strong>{sumPriceWithCurrency}</strong> per adult', { sumPriceWithCurrency })"></span>
						</template>
					</DateRangePicker>
				</div>
			</div>
		</div>
		<TransitionHeight
			:height="((isExpanded || isEditorMode || isMobileViewport) ? 'auto' : 0)"
			:class="((isExpanded || isEditorMode || isMobileViewport) ? 'overflow-visible' : '')"
			:transitionDuration="0.175"
			@transition-end="handleExpandTransitionEnd"
		>
			<div class="flex mt-6 gap-6 xl:gap-3 lg:flex-col">
				<div class="flex flex-grow gap-6 xl:gap-3 lg:flex-col">
					<div class="w-6/10 lg:w-full">
						<PassengerAndCabinClass
							ref="passengerAndCabinClassEl"
							:labelText="props.passengerSelection?.passengerAndCabinClassLabel"
							:passengerLabel="props.passengerSelection?.passengerLabel"
							:adultLabel="props.passengerSelection?.adultLabel"
							:teenagerLabel="props.passengerSelection?.teenagerLabel"
							:childrenLabel="props.passengerSelection?.childrenLabel"
							:infantLabel="props.passengerSelection?.infantLabel"
							:cabinClassLabel="props.passengerSelection?.cabinClassLabel"
							:economyLabel="props.passengerSelection?.economyLabel"
							:businessLabel="props.passengerSelection?.businessLabel"
							:businessSuiteLabel="props.passengerSelection?.businessSuiteLabel"
							:isShowEconomyClass="!props.passengerSelection?.isHideEconomy"
							:isShowBusinessClass="!props.passengerSelection?.isHideBusiness"
							:isShowBusinessSuiteClass="!props.passengerSelection?.isHideBusinessSuite"
							:isUKVariant="useIsUkVariant({ origin: ondValueFrom, destination: ondValueTo })"
						>
							<template #after-passengers>
								<AppHyperlink
									class="font-semibold"
									:href="props.passengerSelection?.groupBookingPageURL"
									:showExternalLinkIcon="true"
									target="_blank"
								>
									{{ props.passengerSelection?.moreThan9PassengerDescription || $t('More than 9 passengers?') }}
								</AppHyperlink>
							</template>

							<template #mobile-title>
								{{ props.passengerSelectionQuestion }}
							</template>
						</PassengerAndCabinClass>
					</div>
					<div v-if="!payByEnrichPoints && !props.hidePromoCodeField" class="w-4/10 lg:w-full">
						<TextField
							ref="promoCodeTextField"
							v-model="promoCodeValue"
							name="promoCode"
							variant="booking-widget"
							:ariaLabel="props.promoCodeLabel"
							placeholder="E.g. 8990"
							:allowedKeys="/[a-zA-Z0-9]/"
						>
						</TextField>
					</div>
				</div>
				<div class="ml-auto lg:ml-0">
					<CTAButton
						v-aa="[
							{
								clickName: props.searchFlightCTAText,
								clickComponentType: 'Button',
								componentName: 'SubTabBookFlight',
								componentID: props.componentIdAA,
							},
							{
								name: props.searchFlightCTAText,
								type: 'exit',
							},
						]"
						:sizeType="isMobileViewport ? 'small' : 'large'"
						:isSubmit="true"
						:ctaSizing="isMobileViewport ? 'fluid' : 'auto'"
						:disabled="isBtnSubmitDisabled"
					>
						{{ props.searchFlightCTAText }}
					</CTAButton>
				</div>
			</div>

			<div v-if="eligibleForBonusSideTrip && props.bstNoteTop" class="flex mt-6">
				<div class="rounded-xl bg-secondary-teal-extralight flex-grow px-5 py-4">
					<ToggleSwitch
						v-model="optInBonusSideTrip"
						:ariaLabel="props.bstNoteTop"
						name="optInBonusSideTrip"
					>
						<div v-html-sanitize="props.bstNoteTop"></div>
					</ToggleSwitch>
					<div class="text-right">
						<AppHyperlink
							v-if="props.bstNoteCTAText"
							v-aa="[
								{
									clickName: props.bstNoteCTAText,
									clickComponentType: 'URL',
									componentName: 'SubTabBookFlight',
									componentID: props.componentIdAA,
									BST: true,
								},
								{
									name: props.bstNoteCTAText,
									type: isUrlMatchCurrentHostname(props.bstNoteCTAURL) ? 'other' : 'exit',
								},
							]"
							:href="props.bstNoteCTAURL"
							target="_blank"
							:showExternalLinkIcon="false"
						>
							{{ props.bstNoteCTAText }}
						</AppHyperlink>
					</div>
				</div>
			</div>

			<div
				v-if="props.note"
				class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
			>
				<icon-fas-circle-info class="fill-primary-blue-base text-base" />
				<div
					v-html-sanitize="props.note"
					class="text-sm"
				>
				</div>
			</div>

			<div
				v-if="showAlwaysOnEnrichNonLoginMsg"
				class="bg-primary-blue-extralight text-primary-black-base flex gap-3 justify-start px-4 py-4 rounded-xl mt-6"
			>
				<icon-fas-circle-info class="fill-primary-blue-base text-base" />
				<div
					v-html-sanitize="props.nonLoggedInMessageNote"
					class="text-sm"
				>
				</div>
			</div>

			<div
				v-if="enrichAlwaysOnEligibility && props.loggedInMessageNote"
				class="flex mt-6"
			>
				<div class="rounded-xl bg-secondary-teal-extralight flex-grow px-5 py-4">
					<ToggleSwitch
						v-model="optEnrichAlwaysOnOffer"
						:ariaLabel="props.loggedInMessageNote"
						name="optEnrichAlwaysOnOffer"
					>
						<div v-html-sanitize="props.loggedInMessageNote"></div>
					</ToggleSwitch>
				</div>
			</div>
		</TransitionHeight>
		
	</FormWrapper>

	<Teleport v-if="isLoading" to="body">
		<div class="generic-backdrop !bg-white/80">
			<div class="flex items-center justify-center h-full w-full">
				<AppSpinner />
			</div>
		</div>
	</Teleport>
</div>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.SubTabBookFlight {
	
}

</style>
