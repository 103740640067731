<script>
export default {
	name: 'FireflyAirCargo',
	inheritAttrs: false,
};
</script>

<script setup>
import MobileSubpage from '~/components/BookingWidget/MobileSubpage.vue';
import AirCargoSearch from '~/components/firefly/BookingWidget/AirCargo/AirCargoSearch.vue';
import useBookingWidgetSubpage from '~/logic/composables/useBookingWidgetSubpage.js';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';
import { isMobileViewport } from '~/logic/composables/breakpoints.js';
import { useBookingWidgetUrlQuery } from '~/logic/composables/booking-widget/useBookingWidgetUrlQuery.js';
import { pushAA_flightSearchStart, pushAA_flightSearchEnd, pushAA_click } from '~/logic/adobe-analytic.js';

const props = defineProps({
	generalTab: { type: Object, default: null },
	mobileLabelsTab: { type: Object, default: null },
	airCargoEndpointUrl: { type: String, default: '' },

	componentIdAA: { type: String, default: '' },
});

const emit = defineEmits([
	'form-submission',
]);

const rootEl = ref(null);

const { isEditorMode } = useGlobalAEMState();
const { isMobileSubpageVisible } = useBookingWidgetSubpage({
	hashKey: ['air-cargo-search', 'air-cargo-search-tab'],
	rootEl,
});

const { registerQueryChange } = useBookingWidgetUrlQuery({
	mainTabName: 'air-cargo-search-tab',
});

registerQueryChange(async (query) => {
	if (!query) return;
	if (isMobileViewport.value) {
		isMobileSubpageVisible.value = true;
	}
});

</script>



<template>
<div
	v-if="!props.generalTab?.airCargoSection.hideInDesktop && !isMobileViewport"
	ref="rootEl"
	v-focus-within
	class="FireflyAirCargo booking-widget-subcomponent"
	tabindex="-1"
	:class="{
		'isEditorMode': isEditorMode,
	}"
>
	<div class="generic-container">
		<h5 v-if="isEditorMode" class="title-editor-mode">
			<icon-fas-plane class="mr-2 fill-current inline-block" />
			<span>Firefly Booking Widget: Air Cargo</span>
		</h5>

		<AirCargoSearch
			:subHeaderTabDescription="props.generalTab?.airCargoSection?.subHeaderTabDescription"
			:subHeaderTabTooltipBody="props.generalTab?.airCargoSection?.subHeaderTooltipBody"
			:subHeaderTabTooltipLabel="props.generalTab?.airCargoSection?.subHeaderTabTooltipLabel"
			:note="props.generalTab?.airCargoSection?.note"
			:searchFlightCTAText="props.generalTab?.airCargoSection?.searchFlightCtaText"
		
			:ondSelection="props.generalTab?.ondSelectionSection"

			:datePicker="props.generalTab?.datePickerSection"
			:hideTodayDateIndicator="!props.generalTab?.datePickerSection?.todayIndicator"

			:promoCodeLabel="props.generalTab?.promoCodeSection?.promoCodeLabel"
			:promoCodeErrorMessage="props.generalTab?.promoCodeSection?.promoCodeErrorMessage"
			:isHidePromoCodeField="props.generalTab?.promoCodeSection?.hidePromoCodeField"

			:subHeaderTooltipCTATextMobileLabel="props.mobileLabelsTab?.subHeaderTooltipCTATextMobileLabel"
			:fromQuestionMobile="props.mobileLabelsTab?.fromQuestionMobile"
			:toQuestionMobile="props.mobileLabelsTab?.toQuestionMobile"

			:airCargoEndpointUrl="props.airCargoEndpointUrl"

			:componentIdAA="props.componentIdAA"
		/>
	</div>
</div>

<!-- Mobile subpage -->
<MobileSubpage
	:isVisible="isMobileSubpageVisible"
	class="text-white"
	style="--overlay-container-bg-color: var(--primary-firefly-orange-base);"
	@update:is-visible="isMobileSubpageVisible = $event"
>
	<template #banner-title>
		<h1 class="text-center">{{ props.mobileLabelsTab?.bannerTextMobile }}</h1>
	</template>

	<template #default>
		<div class="generic-container mb-20">
			<AirCargoSearch
				:subHeaderTabDescription="props.generalTab?.airCargoSection?.subHeaderTabDescription"
				:subHeaderTabTooltipBody="props.generalTab?.airCargoSection?.subHeaderTooltipBody"
				:subHeaderTabTooltipLabel="props.generalTab?.airCargoSection?.subHeaderTabTooltipLabel"
				:note="props.generalTab?.airCargoSection?.note"
				:searchFlightCTAText="props.generalTab?.airCargoSection?.searchFlightCtaText"
			
				:ondSelection="props.generalTab?.ondSelectionSection"

				:datePicker="props.generalTab?.datePickerSection"
				:hideTodayDateIndicator="!props.generalTab?.datePickerSection?.todayIndicator"

				:promoCodeLabel="props.generalTab?.promoCodeSection?.promoCodeLabel"
				:promoCodeErrorMessage="props.generalTab?.promoCodeSection?.promoCodeErrorMessage"
				:isHidePromoCodeField="props.generalTab?.promoCodeSection?.hidePromoCodeField"

				:subHeaderTooltipCTATextMobileLabel="props.mobileLabelsTab?.subHeaderTooltipCTATextMobileLabel"
				:fromQuestionMobile="props.mobileLabelsTab?.fromQuestionMobile"
				:toQuestionMobile="props.mobileLabelsTab?.toQuestionMobile"

				:airCargoEndpointUrl="props.airCargoEndpointUrl"

				:componentIdAA="props.componentIdAA"
			/>
		</div>
	</template>
</MobileSubpage>
</template>



<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.FireflyAirCargo {
	
}

</style>
