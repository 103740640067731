<script>
export default {
	name: 'MHBreadcrumb',
	inheritAttrs: false,
};
</script>

<script setup>
import Breadcrumb from '~/components/Breadcrumb.vue';
import { useGlobalAEMState } from '~/logic/aem/global-aem-state.js';

const props = defineProps({
	showSectionBreaker: { type: Boolean, default: true },
});

const { isEditorMode } = useGlobalAEMState();

const siteName = window.siteName;

</script>


<template>
<div
	v-if="props.showSectionBreaker"
	class="curvy-section-breaker flex items-center"
	:class="{
		'!mt-0': isEditorMode,
	}"
	:data-use-theme="siteName"
>
	<div class="generic-container">
		<Breadcrumb />
	</div>
</div>

<div v-else class="generic-container" :data-use-theme="siteName">
	<Breadcrumb />
</div>

</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

</style>
