<script>
export default {
	name: 'TransitionSimpleSlideDown',
};
</script>

<script setup>
const props = defineProps({
	// modelValue: { type: String, default: '' },
});
const emit = defineEmits([
	// 'update:modelValue',
]);


const handleNestedImgsLoad = (el) => {
	/* 
		This method is required at beforeEnter because image might not be loaded,
		if so, 'el.scrollHeight' won't be accurate hence this method is to listen to the 'load' event of all nested <img>
	*/
	const allImgChildren = Array.from(el.querySelectorAll('img') || []);
	const imgChildrenMap = new Map();

	allImgChildren.forEach((img) => {
		imgChildrenMap.set(img.src, img);
	});

	if (imgChildrenMap.size !== 0) {
		el.addEventListener(
			'load',
			(event) => {
				const target = event.target;
				if (target.tagName == 'IMG') {
					imgChildrenMap.delete(target.src);
				}

				if (imgChildrenMap.size === 0) {
					enter(el);
				}
			},
			true // <-- useCapture
		);
	}
};
const beforeEnter = (el) => {
	handleNestedImgsLoad(el);
	el.style.height = '0';
};
const enter = (el) => {
	el.style.height = `${el.scrollHeight}px`;
};
const leave = (el) => {
	el.style.height = '0';
};

</script>

<template>
<transition
	name="simple-slide-down"
	@before-enter="beforeEnter"
	@enter="enter"
	@leave="leave"
>
	<slot></slot>
</transition>
</template>


<style scoped lang="scss">
@use 'sass:color';
@use '~/styles/partials/_var.scss';

.TransitionSimpleSlideDown {
	
}

.simple-slide-down-enter-active,
.simple-slide-down-leave-active {
	transition: all 0.2s;
}

</style>
